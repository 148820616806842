const basePath = "https://materials.chineseforall.org"
export default {
  directions1: [
    {
      title: 'Where is the restroom?',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/directions1/0',
      video: {
        src: basePath + '/assets/videos/module1/restroom/restroom.mp4',
        captionSrc: basePath + '/assets/videos/module1/restroom/restroom_s.vtt',
        captionSrcT: basePath + '/assets/videos/module1/restroom/restroom_t.vtt',
      },
      tasks: [
        {
          url: '/modules/directions1/0/0',
          type: 'multiple-choice',
        },
      ],
    },
    {
      title: 'How do I get to the movie theatre?',
      img: basePath + '/assets/shutterstock_644371840.jpg',
      startUrl: '/modules/directions1/1',
      video: {
        src: basePath + '/assets/videos/module1/theater/theater.mp4',
        captionSrc: basePath + '/assets/videos/module1/theater/theater_s.vtt',
        captionSrcT: basePath + '/assets/videos/module1/theater/theater_t.vtt',
      },
      tasks: [
        {
          url: '/modules/directions1/1/0',
        },
      ],
    },
    {
      title: 'I am lost',
      img: basePath + '/assets/shutterstock_644785699.jpg',
      startUrl: '/modules/directions1/2',
      video: {
        src: basePath + '/assets/videos/module1/iamlost/iamlost_all.mp4',
        captionSrc: basePath + '/assets/videos/module1/iamlost/iamlost_s.vtt',
        captionSrcT: basePath + '/assets/videos/module1/iamlost/iamlost_t.vtt',
      },
      tasks: [
        {
          url: '/modules/directions1/2/0',
        },
      ],
    },
    {
      title: 'How do I get to the Beijing Museum?',
      img: basePath + '/assets/shutterstock_1170562750.jpg',
      startUrl: '/modules/directions1/3',
      video: {
        src: basePath + '/assets/videos/module1/museum/museum_all.mp4',
        captionSrc: basePath + '/assets/videos/module1/museum/museum_s.vtt',
        captionSrcT: basePath + '/assets/videos/module1/museum/museum_t.vtt',
      },
      tasks: [
        {
          url: '/modules/directions1/3/0',
        },
      ],
    },
  ],
  food: [
    {
      title: 'Vendor-1',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/food/0',
      video: {
        src: basePath + '/assets/videos/module2/vendor/vendor1.mp4',
        captionSrc: basePath + '/assets/videos/module2/vendor/vendor1_s.vtt',
        captionSrcT: basePath + '/assets/videos/module2/vendor/vendor1_t.vtt',
      },
      tasks: [
        {
          url: '/modules/food/0/0',
          type: 'multiple-choice',
        },
      ],
    },
    {
      title: 'Vendor-2',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/food/1',
      video: {
        src: basePath + '/assets/videos/module2/vendor/vendor2.mp4',
        captionSrc: basePath + '/assets/videos/module2/vendor/vendor2_s.vtt',
        captionSrcT: basePath + '/assets/videos/module2/vendor/vendor2_t.vtt',
        
      },
      tasks: [
        {
          url: '/modules/food/1/0',
          type: 'multiple-choice',
        },
      ],
    },
    {
      title: 'Bubble Tea-1',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/food/2',
      video: {
        src: basePath + '/assets/videos/module2/bubbletea/BB1.mp4',
        captionSrc: basePath + '/assets/videos/module2/bubbletea/bubbletea1_s.vtt',
        captionSrcT: basePath + '/assets/videos/module2/bubbletea/bubbletea1_t.vtt',
      },
      tasks: [
        {
          url: '/modules/food/2/0',
          type: 'multiple-choice',
        },
      ],
    },
    {
      title: 'Bubble Tea-2',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/food/3',
      video: {
        src: basePath + '/assets/videos/module2/bubbletea/BB2.mp4',
        captionSrc: basePath + '/assets/videos/module2/bubbletea/bubbletea2_s.vtt',
        captionSrcT: basePath + '/assets/videos/module2/bubbletea/bubbletea2_t.vtt',
      },
      tasks: [
        {
          url: '/modules/food/3/0',
          type: 'multiple-choice',
        },
      ],
    },
    {
      title: 'Bubble Tea-3',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/food/4',
      video: {
        src: basePath + '/assets/videos/module2/bubbletea/BB3.mp4',
        captionSrc: basePath + '/assets/videos/module2/bubbletea/bubbletea3_s.vtt',
        captionSrcT: basePath + '/assets/videos/module2/bubbletea/bubbletea4_t.vtt',
      },
      tasks: [
        {
          url: '/modules/food/4/0',
          type: 'multiple-choice',
        },
      ],
    },
    {
      title: 'Restaurant-1',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/food/5',
      video: {
        src: basePath + '/assets/videos/module2/restaurant/R1.mp4',
        captionSrc: basePath + '/assets/videos/module2/restaurant/restaurant1_s.vtt',
        captionSrcT: basePath + '/assets/videos/module2/restaurant/restaurant1_t.vtt',

      },
      tasks: [
        {
          url: '/modules/food/5/0',
          type: 'multiple-choice',
        },
      ],
    },
    {
      title: 'Restaurant-2',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/food/6',
      video: {
        src: basePath + '/assets/videos/module2/restaurant/R2.mp4',
        captionSrc: basePath + '/assets/videos/module2/restaurant/restaurant2_s.vtt',
        captionSrcT: basePath + '/assets/videos/module2/restaurant/restaurant2_t.vtt',
      },
      tasks: [
        {
          url: '/modules/food/6/0',
          type: 'multiple-choice',
        },
      ],
    },
    {
      title: 'Restaurant-3',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/food/7',
      video: {
        src: basePath + '/assets/videos/module2/restaurant/R3.mp4',
        captionSrc: basePath + '/assets/videos/module2/restaurant/restaurant3_s.vtt',
        captionSrcT: basePath + '/assets/videos/module2/restaurant/restaurant3_t.vtt',
      },
      tasks: [
        {
          url: '/modules/food/7/0',
          type: 'multiple-choice',
        },
      ],
    },
  ],
  directions2: [
    {
      title: 'Giving Directions 1',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/directions2/0',
      video: {
        src: basePath + '/assets/videos/module3/1/giving1.mp4',
        captionSrc: basePath + '/assets/videos/module3/1/giving1_s.vtt',
        captionSrcT: basePath + '/assets/videos/module3/1/giving1_t.vtt',
      },
      tasks: [
        {
          url: '/modules/directions2/0/0',
          type: 'multiple-choice',
        },
      ],
    },
    {
      title: 'Giving Directions 2',
      img: basePath + '/assets/shutterstock_644371840.jpg',
      startUrl: '/modules/directions2/1',
      video: {
        src: basePath + '/assets/videos/module3/2/giving2.mp4',
        captionSrc: basePath + '/assets/videos/module3/2/giving2_s.vtt',
        captionSrcT: basePath + '/assets/videos/module3/2/giving2_t.vtt',
      },
      tasks: [
        {
          url: '/modules/directions2/1/0',
        },
      ],
    },
    {
      title: 'Giving Directions - Travel',
      img: basePath + '/assets/shutterstock_644785699.jpg',
      startUrl: '/modules/directions2/2',
      video: {
        src: basePath + '/assets/videos/module3/travel/travel.mp4',
        captionSrc: basePath + '/assets/videos/module3/travel_s.vtt',
        captionSrcT: basePath + '/assets/videos/module3/travel_t.vtt',
      },
      tasks: [
        {
          url: '/modules/directions2/2/0',
        },
      ],
    },
    {
      title: 'Giving Directions - Car',
      img: basePath + '/assets/shutterstock_1170562750.jpg',
      startUrl: '/modules/directions2/3',
      video: {
        src: basePath + '/assets/videos/module3/car/car.mp4',
        captionSrc: basePath + '/assets/videos/module3/car/car_s.vtt',
        captionSrcT: basePath + '/assets/videos/module3/car/car_t.vtt',

      },
      tasks: [
        {
          url: '/modules/directions2/3/0',
        },
      ],
    },
  ],
  shopping: [
    {
      title: 'Grocery Shopping',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/shopping/0',
      video: {
        src: basePath + '/assets/videos/module4/grocery/grocery.mp4',
        captionSrc: basePath + '/assets/videos/module4/grocery/grocery_s.vtt',
        captionSrcT: basePath + '/assets/videos/module4/grocery/grocery_t.vtt',

      },
      tasks: [
        {
          url: '/modules/shopping/0/0',
          type: 'multiple-choice',
        },
      ],
    },
    {
      title: 'Cell Phone 1',
      img: basePath + '/assets/shutterstock_644371840.jpg',
      startUrl: '/modules/shopping/1',
      video: {
        src: basePath + '/assets/videos/module4/phone1/phone1.mp4',
        captionSrc: basePath + '/assets/videos/module4/phone1/phone1_s.vtt',
        captionSrcT: basePath + '/assets/videos/module4/phone1/phone1_t.vtt',

      },
      tasks: [
        {
          url: '/modules/shopping/1/0',
        },
      ],
    },
    {
      title: 'Cell Phone 2',
      img: basePath + '/assets/shutterstock_644785699.jpg',
      startUrl: '/modules/shopping/2',
      video: {
        src: basePath + '/assets/videos/module4/phone2/phone2.mp4',
        captionSrc: basePath + '/assets/videos/module4/phone2/phone2_s.vtt',
        captionSrcT: basePath + '/assets/videos/module4/phone2/phone2_t.vtt',

      },
      tasks: [
        {
          url: '/modules/shopping/2/0',
        },
      ],
    },
    {
      title: 'Buy A Gift',
      img: basePath + '/assets/shutterstock_1170562750.jpg',
      startUrl: '/modules/shopping/3',
      video: {
        src: basePath + '/assets/videos/module4/gift/gift.mp4',
        captionSrc: basePath + '/assets/videos/module4/gift/gift_s.vtt',
        captionSrcT: basePath + '/assets/videos/module4/gift/gift_t.vtt',

      },
      tasks: [
        {
          url: '/modules/shopping/3/0',
        },
      ],
    },
  ],
  travel: [
    {
      title: 'Buying Flight Ticket With Travel Agency',
      img: basePath + '/assets/shutterstock_321083687.jpg',
      startUrl: '/modules/travel/0',
      video: {
        src: basePath + '/assets/videos/module5/1/ticket.mp4',
        captionSrc: basePath + '/assets/videos/module5/1/ticket_s.vtt',
        captionSrcT: basePath + '/assets/videos/module5/1/ticket_t.vtt',

      },
      tasks: [
        {
          url: '/modules/travel/0/0',
          type: 'multiple-choice',
        },
      ],
    },
    {
      title: 'Plan Itinerary For A Trip',
      img: basePath + '/assets/shutterstock_644371840.jpg',
      startUrl: '/modules/travel/1',
      video: {
        src: basePath + '/assets/videos/module5/2/agency.mp4',
        captionSrc: basePath + '/assets/videos/module5/2/agency_s.vtt',
        captionSrcT: basePath + '/assets/videos/module5/2/agency_t.vtt',
      },
      tasks: [
        {
          url: '/modules/travel/1/0',
        },
      ],
    },
    {
      title: 'Hotel Booking',
      img: basePath + '/assets/shutterstock_644785699.jpg',
      startUrl: '/modules/travel/2',
      video: {
        src: basePath + '/assets/videos/module5/3/booking.mp4',
        captionSrc: basePath + '/assets/videos/module5/3/booking_s.vtt',
        captionSrcT: basePath + '/assets/videos/module5/3/booking_t.vtt',
      },
      tasks: [
        {
          url: '/modules/travel/2/0',
        },
      ],
    },
    {
      title: 'Hotel Room Changing',
      img: basePath + '/assets/shutterstock_1170562750.jpg',
      startUrl: '/modules/travel/3',
      video: {
        src: basePath + '/assets/videos/module5/4/roomchange.mp4',
        captionSrc: basePath + '/assets/videos/module5/4/roomchange_s.vtt',
        captionSrcT: basePath + '/assets/videos/module5/4/roomchange_t.vtt',
      },
      tasks: [
        {
          url: '/modules/travel/3/0',
        },
      ],
    },
    {
      title: 'Hotel Check In And Out',
      img: basePath + '/assets/shutterstock_1170562750.jpg',
      startUrl: '/modules/travel/4',
      video: {
        src: basePath + '/assets/videos/module5/5/checkinout.mp4',
        captionSrc: basePath + '/assets/videos/module5/5/checkinout_s.vtt',
        captionSrcT: basePath + '/assets/videos/module5/5/checkinout_t.vtt',
      },
      tasks: [
        {
          url: '/modules/travel/4/0',
        },
      ],
    },
  ],
};
