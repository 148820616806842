import chengyu3 from "./chengyu3.js";
import chengyu2 from "./chengyu2.js";
import chengyu1 from "./";
/**
 *
 * @param {[]} data array of objects with data
 * @param {[]} newKeys the new key names you want for the object
 * @param {[]} previousKeys the original key names that corresponds with newKeys
 * you want to replace with
 */
const restructureData = (data, newKeys, previousKeys) => {
  let res = {};
  Object.keys(data).forEach((group) => {
    res[group] = data[group].map((word) => {
      let newObject = {};
      newKeys.forEach((key, i) => {
        newObject[key] = word[previousKeys[i]];
      });
      return newObject;
    });
  });
  return res;
};
// Auto generates all the practce 1-5 data, if you pass in the original json
const generateData = (sourceData) => {
  const chengyuWordDataSimplified = restructureData(
    sourceData,
    ["word", "englishDefinition", "literalDefinition", "pinyin", "sentences"],
    [
      "simplified",
      "englishDefinition",
      "literalDefinition",
      "pinyin",
      "sentenceSimplified",
    ]
  );

  const chengyuWordDataTraditional = restructureData(
    sourceData,
    ["word", "englishDefinition", "literalDefinition", "pinyin", "sentences"],
    [
      "traditional",
      "englishDefinition",
      "literalDefinition",
      "pinyin",
      "sentenceTraditional",
    ]
  );

  const chengyuCrosswordDataTraditional = restructureData(
    sourceData,
    ["question", "answer"],
    ["englishDefinition", "traditional"]
  );

  const chengyuCrosswordDataSimplified = restructureData(
    sourceData,
    ["question", "answer"],
    ["englishDefinition", "simplified"]
  );

  const chengyuPractice5DataTraditional = restructureData(
    sourceData,
    ["question", "answer"],
    ["sentenceTraditional", "traditional"]
  );

  const chengyuPractice5DataSimplified = restructureData(
    sourceData,
    ["question", "answer"],
    ["sentenceSimplified", "simplified"]
  );

  const chengyuPractice3DataSimplified = restructureData(
    sourceData,
    ["question", "answer"],
    ["simplified", "englishDefinition"]
  );

  const chengyuPractice3DataTraditional = restructureData(
    sourceData,
    ["question", "answer"],
    ["traditional", "englishDefinition"]
  );

  const chengyuPractice2DataSimplified = restructureData(
    sourceData,
    ["question", "answer"],
    ["simplified", "pinyin"]
  );

  const chengyuPractice2DataTraditional = restructureData(
    sourceData,
    ["question", "answer"],
    ["traditional", "pinyin"]
  );
  return {
    chengyuWordData: {
      traditional: chengyuWordDataTraditional,
      simplified: chengyuWordDataSimplified,
    },
    chengyuCrosswordData: {
      traditional: chengyuCrosswordDataTraditional,
      simplified: chengyuCrosswordDataSimplified,
    },
    chengyuPractice2Data: {
      traditional: chengyuPractice2DataTraditional,
      simplified: chengyuPractice2DataSimplified,
    },
    chengyuPractice3Data: {
      traditional: chengyuPractice3DataTraditional,
      simplified: chengyuPractice3DataSimplified,
    },
    chengyuPractice4Data: {
      traditional: chengyuCrosswordDataTraditional,
      simplified: chengyuCrosswordDataSimplified,
    },
    chengyuPractice5Data: {
      traditional: chengyuPractice5DataTraditional,
      simplified: chengyuPractice5DataSimplified,
    },
  };
};

const chengyuWordDataSimplified = restructureData(
  chengyu1,
  ["word", "englishDefinition", "literalDefinition", "pinyin", "sentences"],
  ["chengyu", "engdefinition", "chindefinition", "pinyin2", "sentences"]
);

const chengyuWordDataTraditional = restructureData(
  chengyu1,
  ["word", "englishDefinition", "literalDefinition", "pinyin", "sentences"],
  ["tradchengyu", "engdefinition", "chindefinition", "pinyin2", "tradsent"]
);

const chengyuCrosswordDataTraditional = restructureData(
  chengyu1,
  ["question", "answer"],
  ["engdefinition", "tradchengyu"]
);
const chengyuCrosswordDataSimplified = restructureData(
  chengyu1,
  ["question", "answer"],
  ["engdefinition", "chengyu"]
);

const chengyu2Data = generateData(chengyu2);
const chengyu3Data = generateData(chengyu3);
const chengyuData = {
  chengyuWordData: {
    traditional: chengyuWordDataTraditional,
    simplified: chengyuWordDataSimplified,
  },
  chengyuCrosswordData: {
    traditional: chengyuCrosswordDataTraditional,
    simplified: chengyuCrosswordDataSimplified,
  },
}

export default {
  chengyu2WordData: chengyu2Data.chengyuWordData,
  chengyuWordData: {
    traditional: chengyuWordDataTraditional,
    simplified: chengyuWordDataSimplified,
  },
  chengyu3WordData: chengyu3Data.chengyuWordData,
  chengyu2,
  chengyu3,
  chengyu1,
  chengyu2Data,
  chengyu3Data,
  chengyuData,
  chengyu: {
    1: {
      qaData: chengyuData,
      groupedData: chengyu1,
      wordData: chengyuData.chengyuWordData
    },
    2: {
      qaData: chengyu2Data,
      groupedData: chengyu2,
      wordData: chengyu2Data.chengyuWordData,
    },
    3: {
      qaData: chengyu3Data,
      groupedData: chengyu3,
      wordData: chengyu3Data.chengyuWordData,
    },
  },
  phrase: {
    triptochina: {
      qaData: chengyu3Data,
      groupedData: chengyu3,
      wordData: chengyu3Data.chengyuWordData,
    },
  },
};
