import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import data from "../../data/chengyu/normalized.js";
import PinyinFillInTheBlankPractice from "./Practices/PinyinFillBlankPractice.jsx";
import MultipleChoicePractice from "./Practices/MultipleChoicePractice.jsx";
import CharacterFillInTheBlankPractice from "./Practices/CharFillBlankPractice.jsx";
import CrosswordExercise from "./Practices/CrosswordExercise.jsx";
import Breadcrumb from "../Breadcrumb";
import { getBreadcrumbRoutes } from "../../helpers/breadcrumbRoutes.js";

class ChengyuPractices extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { groupId, practiceId, lesson } = this.props.match.params;
    const dataName = this.props.match.params[0];
    const language = this.props.language || "tradiitonal";
    const basePath = dataName;
    const practice2Problems =
      data[dataName][lesson].qaData.chengyuPractice2Data[language][groupId];
    const practice4Problems =
      data[dataName][lesson].qaData.chengyuPractice4Data[language][groupId];
    const practice3Problems =
      data[dataName][lesson].qaData.chengyuPractice3Data[language][groupId];
    const practice5Problems = data[dataName][
      lesson
    ].qaData.chengyuPractice5Data[language][groupId].map((q) => {
      const question = q.question.replace(q.answer, " _____ ");
      return { ...q, question };
    });

    const practices = {
      1: {
        title: "Crossword",
        component: (
          <CrosswordExercise
            language={language}
            returnRoute={`/${basePath}/${lesson}/group/${groupId}/practice`}
          />
        ),
      },
      2: {
        title: "Fill in the blank - Pinyin",
        component: (
          <PinyinFillInTheBlankPractice
            language={language}
            problemSet={practice2Problems}
            returnRoute={`/${basePath}/${lesson}/group/${groupId}/practice`}
          />
        ),
      },
      3: {
        title: "Multiple Choice - English Definition",
        component: (
          <MultipleChoicePractice
            language={language}
            problemSet={practice3Problems}
            returnRoute={`/${basePath}/${lesson}/group/${groupId}/practice`}
          />
        ),
      },
      4: {
        title: "Fill in the blank - Characters",
        component: (
          <CharacterFillInTheBlankPractice
            language={language}
            problemSet={practice4Problems}
            returnRoute={`/${basePath}/${lesson}/group/${groupId}/practice`}
          />
        ),
      },
      5: {
        title: "Sentences",
        component: (
          <MultipleChoicePractice
            language={language}
            problemSet={practice5Problems}
            returnRoute={`/${basePath}/${lesson}/group/${groupId}/practice`}
          />
        ),
      },
    };
    return (
      <div>
        <Breadcrumb routes={getBreadcrumbRoutes(this.props.match)} />
        <center>
          <h1 style={{ marginLeft: "10px" }}>{practices[practiceId].title}</h1>
        </center>

        <div style={{ margin: "20px" }}>{practices[practiceId].component}</div>
      </div>
    );
  }
}

export default withRouter(ChengyuPractices);
