import React, { Component } from "react";
import Button from "@material-ui/core/Button";

class Crossword extends Component {
  constructor(props) {
    super(props);
    let choices = this.props.choices || [...Array(16).keys()];
    let answerSize = this.props.answer.replace(" ", "").replace(",", "").length;
    this.state = {
      correct: null,
      copied: null,
      copiedIndex: null,
      showAnswer: false,
      choices: this.props.choices,
      displayCrossword: [],
      letters: choices.reduce(
        (o, key, i) =>
          Object.assign(o, {
            [i]: {
              selected: false,
              disabled: false,
              value: key,
              style: {},
              disabledStyle: { backgroundColor: "grey" },
              selctedStyle: { backgroundColor: "grey" },
            },
          }),
        {}
      ),
      answers: new Array(answerSize).fill(null).map((box) => {
        return {
          disabled: false,
          value: null,
        };
      }),
    };

    this.styles = {
      gridStyle: {
        display: "grid",
        width: "200px",
        gridTemplateColumns: "repeat(4, 1fr)",
        gridGap: "5px",
        gridAutoRows: "minMax(10px, auto)",
      },
      boxStyle: {
        border: "1px solid black",
        width: "50px",
        height: "50px",
        textAlign: "center",
        lineHeight: "50px",
        fontSize: "130%",
        fontWeight: "bold",
      },
    };
  }

  componentDidMount() {
    this.reset();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.language !== this.props.language ||
      prevProps.question !== this.props.question
    ) {
      this.reset();
    }
  }

  copy = (char, i) => {
    this.setState({ copied: null, copiedIndex: null });
    let update = {
      ...this.state.letters,
      [i]: {
        value: char,
        selected: true,
      },
    };
    this.setState({ letters: update, copied: char, copiedIndex: i });
  };

  paste = (i) => {
    this.setState({ showAnswer: false });
    if (this.state.copied !== null) {
      let updateAnswers = this.state.answers;
      updateAnswers[i].value = this.state.copied;
      updateAnswers[i].copiedIndex = this.state.copiedIndex;
      let updateLetters = {
        ...this.state.letters,
        [this.state.copiedIndex]: {
          disabled: true,
          value: this.state.copied,
          style: { backgroundColor: "grey" },
        },
      };
      this.setState({
        answers: updateAnswers,
        copied: null,
        copiedIndex: null,
        letters: updateLetters,
      });
    }
  };

  clearTile = (i) => {
    if (this.state.answers[i]) {
      let char = this.state.answers[i].value;
      let sourceIdx = this.state.answers[i].copiedIndex;

      this.setState({ copied: null, copiedIndex: null }, () => {
        let updateLetters = {
          ...this.state.letters,
          [sourceIdx]: { disabled: false, value: char, style: {} },
        };
        let updateAnswers = this.state.answers;
        updateAnswers[i].value = null;
        this.setState({
          letters: updateLetters,
          answers: updateAnswers,
        });
      });
    }
  };

  onAnswerTileClick = (i) => {
    if (this.state.showAnswer) {
      this.setState({ showAnswer: false, copiedIndex: null });
    }
    if (this.state.answers[i].value !== null) {
      this.clearTile(i);
    } else {
      this.paste(i);
    }
  };

  checkCorrect = () => {
    let { answer } = this.props;
    let submittedAnswer = this.state.answers.map((a) => a.value).join("");
    const correct =
      submittedAnswer === answer.replace(" ", "").replace(",", "");
    correct && this.props.updateScore && this.props.updateScore();
    correct && this.props.toggleNext && this.props.toggleNext();
    this.setState({ correct, showAnswer: true });
  };

  buildCrossword = () => {
    return (
      <div style={this.styles.gridStyle}>
        {this.props.choices.map((letter, i) => {
          let correctColor = this.props.answer.includes(letter)
            ? "green"
            : "black";
          let selectedColor =
            this.state.copiedIndex === i ? { backgroundColor: "#6fd9cb" } : {};
          let tstyle = {
            ...this.styles.boxStyle,
            ...this.state.letters[i].style,
            ...selectedColor,
          };

          return (
            <div
              key={`${i}-${letter}`}
              onClick={() => {
                !this.state.letters[i].disabled && this.copy(letter, i);
              }}
              style={tstyle}
            >
              {letter}
            </div>
          );
        })}
      </div>
    );
  };

  generateAnswerSection = () => {
    return (
      <div style={this.styles.gridStyle}>
        {this.state.answers.map((box, i) => {
          const { answers } = this.state;
          const answer = this.props.answer.replace(" ", "").replace(",", "");
          let correct = answer[i] && answers[i].value === answer[i];
          const ansStyle = {
            backgroundColor: correct ? "#85edab" : "rgba(255, 0, 0, 0.19)",
          };
          const style = this.state.showAnswer ? ansStyle : {};
          return (
            <div
              key={`answer-char${i}`}
              onClick={() => this.onAnswerTileClick(i)}
              style={{ ...this.styles.boxStyle, ...style }}
            >
              {this.state.answers[i].value}
            </div>
          );
        })}
      </div>
    );
  };

  reset = () => {
    const ansLen = this.props.answer.replace(" ", "").replace(",", "").length;

    this.setState({
      correct: null,
      showAnswer: false,
      copiedIndex: null,
      letters: this.props.choices.reduce(
        (o, key, i) =>
          Object.assign(o, {
            [i]: {
              selected: false,
              disabled: false,
              value: key,
              style: {},
              disabledStyle: { backgroundColor: "grey" },
              selctedStyle: { backgroundColor: "grey" },
            },
          }),
        {}
      ),
      answers: new Array(ansLen).fill(null).map((box) => {
        return {
          disabled: false,
          value: null,
        };
      }),
    });
  };

  goNext = () => {
    this.reset();
    this.props.onNext();
  };

  render() {
    const { question } = this.props;
    const grid = this.buildCrossword();
    const answers = this.generateAnswerSection();

    return (
      <center>
        <div>
          Click a character in the word bank, and then click an input box.
          Clicking an input box again will remove the character.
        </div>
        <div>
          <div>
            Find:
            <div style={styles.question}>{question}</div>
            <br />
            {grid}
            <br />
            {answers}
          </div>
        </div>
        <br />
        {this.state.correct === true ? (
          ""
        ) : this.state.correct === null ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={this.checkCorrect}
          >
            Check Answer
          </Button>
        ) : (
          <Button variant="contained" color="secondary" onClick={this.reset}>
            Retry
          </Button>
        )}
      </center>
    );
  }
}

export default Crossword;

const styles = {
  question: {
    border: "1px solid",
    width: "300px",
    height: "auto",
    textAlign: "center",
    padding: "5px",
  },
};
