import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import IncorrectIcon from "../../../icons/IncorrectIcon";
import CorrectIcon from "../../../icons/CorrectIcon";
import styled from "styled-components";

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 12px;
`;

class CharacterFillBlankProblem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      problem: null,
      displayProblems: [],
      submittedAnswers: [],
      currSubmittedAnswer: "",
      correct: null,
      questionNumber: 0,
    };
  }
  componentDidMount = () => {
    this.initializeProblem();
  };

  componentDidUpdate = (prevProps) => {
    const { question, answer } = this.props;
    if (prevProps.question !== question || prevProps.answer !== answer) {
      this.initializeProblem();
    }
  };

  initializeProblem = () => {
    this.setState({ correct: null });
  };
  checkAnswer = (e, questions, correctAnswer) => {
    e.preventDefault();
    const submittedAnswer = questions
      .map((ans, i) => {
        const blankVal =
          e.target[`char${i}`] && e.target[`char${i}`].value
            ? e.target[`char${i}`].value
            : " _ ";
        return ans.value ? ans.value : blankVal;
      })
      .join("");

    const correct = submittedAnswer === correctAnswer;
    correct && this.props.updateScore && this.props.updateScore();
    this.props.toggleNext && this.props.toggleNext();
    this.setState({ correct, currSubmittedAnswer: submittedAnswer });
  };

  generateQuestion = (question, answer) => {
    const answerChars = answer.split("");
    const indexes = new Set(
      [...Array(3).keys()].map(() => Math.floor(Math.random() * answer.length))
    );
    while (indexes.size < 2) {
      indexes.add(Math.floor(Math.random() * answer.length));
    }
    const withDisplay = answerChars.map((char, i) => {
      if (indexes.has(i)) {
        return {
          char: char,
          display: (
            <input
              type="text"
              name={`char${i}`}
              style={{ width: "20px" }}
              defaultValue=""
              style={styles.inputStyles}
            />
          ),
        };
      }
      return {
        char: char,
        value: char,
        display: (
          <span style={styles.question} value={char}>
            {char}
          </span>
        ),
      };
    });

    const display = withDisplay.map((char) => {
      return char.display;
    });

    const correctIcon =
      this.state.correct === true ? (
        <CorrectIcon />
      ) : this.state.correct === false ? (
        <IncorrectIcon />
      ) : (
        ""
      );

    return (
      <div>
        <h3>{question}</h3>
        {this.state.correct === null && (
          <form
            onSubmit={(e) => this.checkAnswer(e, withDisplay, answer)}
            style={{ margin: "25px" }}
          >
            <div style={{ height: "25px", padding: "10px" }}>{display}</div>
            <br />
            <button
              disabled={this.state.correct !== null}
              style={styles.checkButton}
            >
              Check Answer
            </button>
          </form>
        )}
        <div style={styles.scoreSection}>
          {this.state.correct === true ? (
            <div style={{ color: "green" }}>
              {" "}
              <IconContainer>{correctIcon}</IconContainer> CORRECT
            </div>
          ) : this.state.correct === false ? (
            <div style={{ color: "red" }}>
              {" "}
              <IconContainer>{correctIcon}</IconContainer>WRONG
            </div>
          ) : (
            ""
          )}
          {this.state.correct !== null && (
            <div>
              <div>Your answer: {this.state.currSubmittedAnswer}</div>
              <div style={{ margin: "20px" }}>Correct Answer: {answer} </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  render() {
    const { question, answer } = this.props;
    const { correct } = this.state;
    const problem = this.generateQuestion(question, answer);

    return <div>{problem}</div>;
  }
}

export default withRouter(CharacterFillBlankProblem);

const styles = {
  scoreSection: { margin: "20px", fontSize: "1.5em" },
  question: {
    height: 0,
    fontSize: "2em",
    wordBreak: "keep-all",
    marginLeft: "1px",
    marginRight: "1px",
  },
  inputStyles: {
    marginLeft: "1px",
    marginRight: "1px",
    height: "27px",
    width: "25px",
    fontSize: "25px",
    padding: "0px",
  },
  checkButton: {
    backgroundColor: "#f50057",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "5px 7px 5px 7px",
  },
};
