import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import IncorrectIcon from "../icons/IncorrectIcon";
import CorrectIcon from "../icons/CorrectIcon";
import OpenCC from "node-opencc";

const Header = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 height: 15%;
 @media (orientation: landscape) {
  max-width: 80vw;
 }
 @media (orientation: portrait) {
  height: 5%;
  margin-top: 0%:
  width: 90vw;
 }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 10px;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80%;
  width: 80vw;
  @media (orientation: portrait) {
    flex-direction: column;
    height: 50vw;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (orientation: landscape) {
    height: 40vh;
  }
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 25px;
  margin-left: 0;
  margin-right: 0;
  @media (orientation: portrait) {
    font-size: 15px;
  }
`;

const StyledAltQ = styled.h4`
  font-weight: 700;
  font-size: 50px;
  margin-left: 0;
  margin-right: 0;
  @media (orientation: portrait) {
    font-size: 5vw;
  }
`;

const RouteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const DirectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 25px;
  width: 30%;
  @media (orientation: portrait) {
    font-size: 15px;
    width: 100%;
  }
`;

const StyledImage = styled.img`
  @media (orientation: landscape) {
    height: 150;
    width: 50%;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
  margin: 5;
`;

/*
  This template can only handle questions with routes and SubRoutes.
  Extra implimentation is needed for greater nested routes.
*/
function Interactive({ question, choices, img, altQ, nextLink, simplified }) {
  const [current, setCurrent] = useState(0);
  const [containsSubRoutes, setContainsSubRoutes] = useState(false);
  const [subRouteSelected, setSubRouteSelected] = useState(false);
  const [subRoute, setSubRoute] = useState(0);
  const [routeSelected, setRouteSelected] = useState(false);
  const [route, setRoute] = useState(0);
  const [mouseOver, setMouseOver] = useState(false);
  const landscape =
    process.browser && window.matchMedia("(orientation: landscape)").matches;

  const reset = useCallback(() => {});

  useEffect(() => {
    reset();
    if (choices[0].routeData.subRouteNames) {
      setContainsSubRoutes(true);
    }
  }, []);

  const handleInitalRoute = (index) => {
    setRouteSelected(true);
    setRoute(index);
  };

  const handleSubRoute = (index) => {
    setSubRouteSelected(true);
    setSubRoute(index);
  };

  const handleMouseOver = () => {
    if (altQ !== undefined && !routeSelected) {
      setMouseOver(!mouseOver);
    }
  };

  const currQuestion = routeSelected
    ? question.length > 1
      ? question[route + 1]
      : choices[route].routeName
    : question[0];
  return (
    <React.Fragment>
      <Header
        // onMouseEnter={handleMouseOver}
        // onMouseLeave={handleMouseOver}
        onClick={!landscape ? handleMouseOver : null}
      >
        {!mouseOver || routeSelected ? (
          <StyledQuestion>
            {simplified
              ? currQuestion
              : OpenCC.traditionalToSimplified(currQuestion)}
          </StyledQuestion>
        ) : (
          <StyledAltQ>
            {simplified ? OpenCC.traditionalToSimplified(altQ) : altQ}
          </StyledAltQ>
        )}
      </Header>
      {!routeSelected ? (
        <Container>
          <StyledContainer>
            {choices.map((choice, index) => (
              <Button
                key={Math.random()}
                variant="contained"
                color="secondary"
                onClick={() => handleInitalRoute(index)}
              >
                {choice.routeName}
              </Button>
            ))}
          </StyledContainer>
        </Container>
      ) : routeSelected && !containsSubRoutes ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <RouteContainer>
            <DirectionContainer>
              <div style={{ fontWeight: "bold", textDecoration: "underline" }}>
                Directions:
              </div>
              {choices[route].routeData.directions.map((direction, index) => (
                <div>{direction}</div>
              ))}
            </DirectionContainer>
            <StyledImage alt="loading..." src={img[route]} />
          </RouteContainer>
          <ButtonContainer>
            <Button
              style={{ alignSelf: "center", marginTop: 10 }}
              variant="contained"
              color="secondary"
              onClick={() => setRouteSelected(false)}
            >
              Back
            </Button>
            <Button
              style={{ alignSelf: "center", marginTop: 10 }}
              variant="contained"
              color="secondary"
              onClick={() => {
                window.location.href = nextLink;
              }}
            >
              Next
            </Button>
          </ButtonContainer>
        </div>
      ) : null}
      {routeSelected && containsSubRoutes && !subRouteSelected ? (
        <Container>
          <StyledContainer>
            {choices[route].routeData.subRouteNames.map((routeName, index) => (
              <Button
                key={Math.random()}
                variant="contained"
                color="secondary"
                onClick={() => handleSubRoute(index)}
              >
                {routeName}
              </Button>
            ))}
          </StyledContainer>
          <Button
            style={{ alignSelf: "center", marginTop: 10 }}
            variant="contained"
            color="secondary"
            onClick={() => setRouteSelected(false)}
          >
            Back
          </Button>
        </Container>
      ) : routeSelected && containsSubRoutes && subRouteSelected ? (
        <StyledContainer style={{ flexDirection: "column" }}>
          <StyledImage
            alt="loading..."
            src={choices[route].routeData.routeImages[subRoute]}
          />
          <ButtonContainer>
            <Button
              style={{ alignSelf: "center", marginTop: 10 }}
              variant="contained"
              color="secondary"
              onClick={() => setSubRouteSelected(false)}
            >
              Back
            </Button>
            <Button
              style={{ alignSelf: "center", marginTop: 10 }}
              variant="contained"
              color="secondary"
              onClick={() => {
                window.location.href = nextLink;
              }}
            >
              Next
            </Button>
          </ButtonContainer>
        </StyledContainer>
      ) : null}
    </React.Fragment>
  );
}

export default Interactive;
