/* eslint-disable */
import React from "react";
import Grid from "@material-ui/core/Grid";
let CHENGYU = require("../data/chengyu");
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#3399ff",
      main: "#0073e6",
      dark: "#004d99",
      contrastText: "#fff",
    },
  },
});

let chengyu = CHENGYU["default"];

const topics_dict = {
  nature: ["自然", "自然"],
  numbers: ["数字", "數字"],
  animals: ["动物", "動物"],
  human: ["人、器官", "人、器官"],
  antonyms: ["反义词", "反義詞"],
  measurements: ["量词", "量詞"],
  pos_connotation: ["积极含意", "積極含意"],
  neg_connotation: ["消极含意", "消極含意"],
};

export default function ChengyuTopics({
  match: {
    params: { tradId },
  },
}) {
  const half = Math.floor(Object.keys(topics_dict).length / 2);

  return (
    <div className="module-menu">
      {tradId == "1" ? (
        <div
          style={{
            color: "#333333",
            fontFamily: "testFont,sans-serif",
            fontSize: "20px",
          }}
        >
          {" "}
          繁體 /{" "}
        </div>
      ) : (
        <div
          style={{
            color: "#333333",
            fontFamily: "testFont,sans-serif",
            fontSize: "20px",
          }}
        >
          {" "}
          简体 /{" "}
        </div>
      )}
      <br />

      <Grid container spacing={0}>
        <Grid item xs={4} />
        <Grid item xs={4} style={{ textAlign: "center" }}>
          {Object.keys(topics_dict).map((topic, index) => (
            <MuiThemeProvider theme={theme}>
              <Button
                href={"/chengyu/1/" + tradId + "/" + topic}
                variant="outlined"
                color="primary"
              >
                {tradId == 0 ? topics_dict[topic][0] : topics_dict[topic][1]}
              </Button>{" "}
              <br></br>
              <br></br>
            </MuiThemeProvider>
          ))}
        </Grid>

        <Grid item xs={4} />
      </Grid>
      <center>
        <MuiThemeProvider theme={theme}>
          <Button href="/chengyu" variant="outlined" color="primary">
            Back
          </Button>
        </MuiThemeProvider>
      </center>
    </div>
  );
}
