import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const CHENGYU = require("../data/chengyu");

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#3399ff",
      main: "#0073e6",
      dark: "#004d99",
      contrastText: "#fff",
    },
    secondary: {
      main: "#000000",
    },
  },
});
export default function TradChoicePage({ setNav }) {
  return (
    <div className="module-menu">
      <center>
        <Grid container spacing={0}>
          <Grid item xs={4} />

          <Grid item xs={4}>
            <br />
            <br />
            <br />
            <br />
            <MuiThemeProvider theme={theme}>
              <Button
                style={{ fontSize: "20px" }}
                href={"/chengyu/1/" + "1"}
                variant="outlined"
                color="primary"
              >
                繁體字
              </Button>
              <br />
              <br />
            </MuiThemeProvider>
            <MuiThemeProvider theme={theme}>
              <Button
                style={{ fontSize: "20px" }}
                href={"/chengyu/1/" + "0"}
                variant="outlined"
                color="primary"
              >
                简体字
              </Button>{" "}
              <br />
              <br />
            </MuiThemeProvider>
          </Grid>

          <Grid item xs={4} />
        </Grid>
      </center>
    </div>
  );
}
