import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

const StyledContainer = styled.div`
  display: inline-block;
font-size: 30px;
text-align : center;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

function Link({
  question,
  text,
  audio,
  link,
  help
}) {
  return (
    <React.Fragment>
      <StyledQuestion>
        {question}
      </StyledQuestion>
      <StyledContainer>
        {audio && <audio controls>
          <source src={audio} />
        </audio>}
        {text.map(item => (
          <div>
            <p>{item.text}</p>
            {help ? item.translation : ''}
          </div>
        ))
        }
        <a href={link}>Link</a>
      </StyledContainer>
    </React.Fragment>
  );
}

export default Link;
