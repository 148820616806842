import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Chinese from "chinese-s2t";
import SoundIcon from "../icons/SoundIcon";
import openCC from "node-opencc";
import PlayAudio from "../PlayAudio";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: space-around;
  justify-content: center;
  @media (orientation: landscape) {
    width: 95vw;
  }
  @media (orientation: portrait) {
    width: 95%;
  }
  padding: 5px;
`;

const DropInContainer = styled.div`
  border-width : 2px;
  width : 130px;
  height : 30px;
  font-size : 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  // border-radius: 15px;
  background-color : white;
  border-color : black;
  line-height : 1;
  padding : 10px;
  margin : 5px;
  text-align : center;
  display flex;
  justify-content : center;
  align-items : center;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

function GridAnswer({
  simplified,
  question,
  images,
  choices,
  showAnswer,
  answers,
}) {
  const [imagesCopy, setImagesCopy] = useState([]);
  const [choicesCopy, setChoicesCopy] = useState([]);
  const [answersCopy, setAnswersCopy] = useState([]);
  const [correct, setCorrect] = useState(false);
  const [selected, setSelected] = useState(0);
  const [currAnswer, setCurrentAnswer] = useState([]);
  const [show, setShow] = useState(false);

  const inputAnswer = (index) => {
    const temp = currAnswer;
    temp[index] = choices[selected];
    setCurrentAnswer(temp);
    setSelected("");
  };

  const shuffleArray = () => {
    const array = images;
    const array2 = answers;
    const array3 = choices;
    let i = array.length;
    let k;
    let temp;
    while (--i > 0) {
      k = Math.floor(Math.random() * (i + 1));
      temp = array[k];
      array[k] = array[i];
      array[i] = temp;
      temp = array2[k];
      array2[k] = array2[i];
      array2[i] = temp;
      k = Math.floor(Math.random() * (i + 1));
      temp = array3[k];
      array3[k] = array3[i];
      array3[i] = temp;
    }
    setImagesCopy(array);
    setAnswersCopy(array2);
    setChoicesCopy(array3);
  };

  const checkAnswer = () => {
    setShow(true);
    if (currAnswer.length === choices.length) {
      let correct = true;
      currAnswer.map((choice, index) => {
        if (choice.text !== answersCopy[index]) {
          correct = false;
        }
      });
      if (correct) {
        setCorrect(true);
        showAnswer();
      }
    }
  };

  const reset = () => {
    shuffleArray();
    setShow(false);
    setCurrentAnswer([]);
  };

  const play = (path) => {
    const audioFile = new Audio(path);
    audioFile.play();
  };

  useEffect(() => {
    setImagesCopy(images);
    setChoicesCopy(choices);
    setAnswersCopy(answers);
    shuffleArray();
  }, []);

  return (
    <React.Fragment>
      <StyledQuestion>
        {simplified ? Chinese.t2s(question) : question}
      </StyledQuestion>
      <StyledContainer>
        {choicesCopy.map((choice, index) => (
          <section
            style={{
              maxWidth: "50vmax",
              display: "flex",
              alignItems: "center",
              flexFlow: "row wrap",
            }}
          >
            <div>
              {choice.audio ? (
                <div
                  style={{
                    float: "left",
                  }}
                >
                  <PlayAudio path={choice.audio}></PlayAudio>
                </div>
              ) : null}
              <Button
                type="button"
                variant={selected === index ? "contained" : ""}
                color={selected === index ? "secondary" : ""}
                onClick={() => setSelected(index) && setShow(false)}
                onKeyDown={() => setSelected(index) && setShow(false)}
              >
                <span
                  style={{
                    fontSize: 25,
                    wordBreak: "keep-all",
                  }}
                >
                  {simplified
                    ? openCC.traditionalToSimplified(choice.text)
                    : choice.text}
                </span>
              </Button>
            </div>
          </section>
        ))}
      </StyledContainer>
      <StyledContainer>
        {imagesCopy.map((image, index) => {
          let correctColor = "black";
          if (currAnswer[index]) {
            correctColor =
              currAnswer[index].text === answersCopy[index] ? "green" : "red";
          }
          return (
            <button
              disabled={show}
              type="button"
              onClick={() => (selected !== "" ? inputAnswer(index) : "")}
              onKeyDown={(key) =>
                key === "13" && selected !== "" ? inputAnswer(index) : ""
              }
              style={{
                margin: "10px",
                display: "flex",
                flexDirection: "column",
                border: "none",
                backgroundColor: "Transparent",
                outline: "none",
              }}
            >
              <img
                style={{ width: 125, height: 125, alignSelf: "center" }}
                src={image}
                alt={index}
              />
              <DropInContainer
                style={{ color: !show ? "black" : correctColor }}
              >
                {currAnswer[index]
                  ? simplified
                    ? Chinese.t2s(currAnswer[index].text)
                    : currAnswer[index].text
                  : ""}
              </DropInContainer>
            </button>
          );
        })}
      </StyledContainer>
      {!show && !correct && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => checkAnswer()}
        >
          Check
        </Button>
      )}
      {show && !correct && (
        <Button variant="contained" color="secondary" onClick={() => reset()}>
          Retry
        </Button>
      )}
    </React.Fragment>
  );
}

export default GridAnswer;
