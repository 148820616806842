import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import OpenCC from "node-opencc";

/* eslint-disable no-unused-expressions */

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2.5vh;
  width: 50vw;
  justify-content: center;
  align-items: center;
  @media (orientation: landscape) {
    font-size: 5vw;
  }
  @media (orientation: portrait) {
    width: 50vw;
    font-size: 20vw;
    height: 50vh;
  }
  @media (orientation: portrait) and (min-height: 800px) {
    width: 50vw;
    font-size: 25vw;
    height: 50vh;
  }
`;

const StyledEmptyBox = styled.div`
  position: relative;
  border-style: solid;
  width: 100%;
  height: 100%;
  user-select: none;
`;

const StyledEmptyBoxCoverTopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  color: black;
  background-color: #87bdd8;
`;

const StyledEmptyBoxCoverTopLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  color: black;
  background-color: #b7d7e8;
`;

const StyledEmptyBoxCoverBottomRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  color: black;
  background-color: #b7d7e8;
`;

const StyledEmptyBoxCoverBottomLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 50%;
  color: black;
  background-color: #87bdd8;
`;

const StyledChoices = styled.span`
  cursor : pointer
  text-align: center;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  margin: .25vh;
  margin-top: 1vh;
  font-weight: 500;
  border-color: #3483eb;
  font-family: "Times New Roman", serif;
  @media (orientation: landscape) {
    font-size: 2vw;
  }
  @media (orientation: portrait) {
    font-size: 7.5vw;
  }
`;

const selectedStyle = {
  backgroundColor: "#3483eb",
  color: "whitesmoke",
};

const wrongAnswerStyle = {
  backgroundColor: "red",
  color: "whitesmoke",
};

const rightAnswerStyle = {
  backgroundColor: "green",
  color: "whitesmoke",
};

function WordBox({
  word,
  index,
  handleClick,
  status,
  answerKey,
  selectedAnswers,
  simplified,
}) {
  const [TopRight, setTopRight] = useState(true);
  const [TopLeft, setTopLeft] = useState(true);
  const [BottomRight, setBottomRight] = useState(true);
  const [BottomLeft, setBottomLeft] = useState(true);
  const [revealed, setRevealed] = useState(0);
  const [selectedAnswer, setselectedAnswer] = useState("");

  const reset = useCallback(() => {
    if (status === "Check") {
      setTopRight(true);
      setTopLeft(true);
      setBottomLeft(true);
      setBottomRight(true);
      setselectedAnswer("");
      setRevealed(0);
    } else if (status === "Next") {
      setTopRight(false);
      setTopLeft(false);
      setBottomLeft(false);
      setBottomRight(false);
    }
  });

  useEffect(() => reset(), [status, index]);

  return (
    <StyledContainer>
      <StyledEmptyBox>
        {simplified ? word.text : OpenCC.simplifiedToTraditional(word.text)}
        {TopRight ? (
          <StyledEmptyBoxCoverTopRight
            onClick={() =>
              revealed < 2
                ? [setTopRight(false), setRevealed(revealed + 1)]
                : {}
            }
          />
        ) : (
          <div />
        )}
        {TopLeft ? (
          <StyledEmptyBoxCoverTopLeft
            onClick={() =>
              revealed < 2 ? [setTopLeft(false), setRevealed(revealed + 1)] : {}
            }
          />
        ) : (
          <div />
        )}
        {BottomLeft ? (
          <StyledEmptyBoxCoverBottomLeft
            onClick={() =>
              revealed < 2
                ? [setBottomLeft(false), setRevealed(revealed + 1)]
                : {}
            }
          />
        ) : (
          <div />
        )}
        {BottomRight ? (
          <StyledEmptyBoxCoverBottomRight
            onClick={() =>
              revealed < 2
                ? [setBottomRight(false), setRevealed(revealed + 1)]
                : {}
            }
          />
        ) : (
          <div />
        )}
      </StyledEmptyBox>
      {word.choices.map((choice) => {
        let style;
        if (status === "Check" && selectedAnswers[index] === choice) {
          style = selectedStyle;
        }
        if (status === "Retry" || status === "Next") {
          if (choice !== answerKey[index]) style = {};
          if (choice === answerKey[index] && choice === selectedAnswer) {
            style = rightAnswerStyle;
          } else if (choice !== answerKey[index] && choice === selectedAnswer)
            style = wrongAnswerStyle;
        }
        return (
          <StyledChoices
            key={Math.random()}
            onClick={() => {
              handleClick(index, choice);
              status !== "Retry" ? setselectedAnswer(choice) : {};
            }}
            style={style}
          >
            {choice}
          </StyledChoices>
        );
      })}
    </StyledContainer>
  );
}

export default WordBox;
