import React from 'react';

export default function IncorrectIcon() {
  return (
    <svg viewBox="0 0 18 19" fill="#ff725b">
      <path
        fillRule="evenodd"
        d="M17.877 2.09L16.09.303l-7.06 7.09L1.94.303.151 2.09l7.09 7.06-7.09 7.09 1.787 1.787L9 10.937l7.09 7.09 1.787-1.787-7.09-7.06z"
      />
    </svg>
  );
}
