import React from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import { getBreadcrumbRoutes } from "../../helpers/breadcrumbRoutes.js";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import data from "../../data/chengyu/normalized.js";

const HomeContainer = styled.div({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "flex-start",
  alignContent: "center",
  "@media(max-width: 700px)": {
    justifyContent: "center",
  },
});

const GroupCard = styled.div({
  overflow: "hidden",
  margin: "25px",
  padding: "10px",
  minWidth: "50px",
  height: "130px",
  width: "20%",
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",
  alignContent: "center",
  border: ".5px solid lightgrey",
  borderRadius: "8px",
  position: "relative",
  backgroundColor: "rgba(60, 122, 216, 0.14)",
  "@media(max-width: 800px)": {
    margin: "25px 0px",
    minWidth: "50px",
    height: "130px",
    width: "230px",
  },
});

const ChengyuHome = props => {
  const classes = useStyles();
  const { lesson } = props.match.params;
  const dataName = props.match.params[0];
  const { basePath } = props;
  const lessonData = data[dataName][lesson].groupedData;
  const buttons = Object.keys(lessonData).map((key, i) => {
    const group = lessonData[key];
    const lessons = group[0].lesson && [
      group[0].lesson,
      group[group.length - 1].lesson,
    ];
    return (
      <GroupCard>
        <div className={classes.cardHeaderStyle}>
          <div className={classes.cardHeaderText}>
            Group {key}
            <span style={{ fontSize: "15px" }}>
              {lessons && ` ( Lessons ${lessons[0]} - ${lessons[1]} )`}
            </span>
          </div>
        </div>
        <div className={classes.cardContent}>
          <Link
            to={`/${basePath}/${lesson}/group/${key}/vocabulary`}
            style={{
              textDecoration: "none",
              color: "black",
              margin: "20 0 0 20",
            }}
          >
            View Vocabulary &#x02192;
          </Link>
          <br />
          <Link
            to={`/${basePath}/${lesson}/group/${key}/practice`}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            Practice &#x02192;
          </Link>
        </div>
      </GroupCard>
    );
  });

  return (
    <div>
      <Breadcrumb routes={getBreadcrumbRoutes(props.match)} />
      <div className={classes.buttonAlignment}>
        <Button className={classes.buttonSpacing} variant="contained" color="primary">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/${basePath}/${lesson}/vocabularylist`}
            style={{
              textDecoration: "none",
              color: "white",
            }}>View All Vocabulary</Link>
        </Button>
        <Button variant="contained" color="primary">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/${basePath}/${lesson}/bookmarks`}
            style={{
              textDecoration: "none",
              color: "white",
            }}>View Bookmarks</Link>
        </Button>
      </div>
      <center className={classes.cardContainerOuter}>
        <HomeContainer> {buttons}</HomeContainer>
      </center>
    </div>
  );
}

export default ChengyuHome;

const useStyles = makeStyles(theme => ({
  linkStyle: { textDecoration: "none", lineHeight: "200%" },
  hstyle: {
    textAlign: "left",
  },
  cardContent: {
    textAlign: "start",
    marginTop: "10%",
    fontSize: "2rem",
    width: "100%",
    [theme.breakpoints.down('lg')]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.1rem",
    },
  },

  cardHeaderText: {
    marginLeft: "10px",
    fontWeight: 600,
    fontSize: "1.5rem",
    [theme.breakpoints.down('md')]: {
      fontSize: "1rem",
    }
  },
  cardHeaderStyle: {
    display: "flex",
    flexFlow: "row wrap",
    alignContent: "center",
    backgroundColor: "rgba(60, 122, 216, 0.81)",
    width: "100%",
    height: "30%",
    top: 0,
    position: "absolute",
    overflow: "hidden",
  },
  cardStyle: {
    margin: "50px",
    width: 300,
  },
  cardContainer: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    textAlign: "center",
  },
  cardContainerOuter: {
    display: "flex",
    justifyContent: "center",
  },
  buttonAlignment: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: {
      margin: "auto",
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      margin: "auto",
      width: "55%",
    },
  },
  buttonSpacing: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    }
  }

}));
