import React, { Component } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import Button from "@material-ui/core/Button";
import { getBreadcrumbRoutes } from "../../helpers/breadcrumbRoutes.js";
import styled from "styled-components";

const LandingContainer = styled.div({
  display: "flex",
  flexFlow: "column wrap",
  justifyContent: "center",
  alignContent: "center",
  padding: "25px",
});

class ChengyuLanding extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Breadcrumb routes={getBreadcrumbRoutes(this.props.match)} />
        <center>
          <LandingContainer>
            {new Array(2).fill(null).map((o, i) => {
              return (
                <Link
                  to={`/chengyu/${i + 1}`}
                  style={{ textDecoration: "none", margin: "25px" }}
                >
                  <Button variant="contained" color="primary">
                    Chengyu {i + 1}
                  </Button>
                </Link>
              );
            })}
          </LandingContainer>
        </center>
      </div>
    );
  }
}

export default ChengyuLanding;
