import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  @media (orientation: portrait) {
    width: 90vw;
  }
`;

const StyledQuestion = styled.h4`
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

const AudioContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  height: 5vh;
  margin: 15px;
  @media (orientation: landscape) {
    width: 50vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 15vmax;
  }
`;

const StyledAudio = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 cursor: pointer
 max-width: 100px;
`;

const Video = styled.video`
  @media (orientation: landscape) {
    height: 18vw;
    width: 24vw;
  }
  @media (orientation: portrait) {
    height: 67.5vw;
    width: 90vw;
    padding: 15px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60vw;
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const DropTargetField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & :last-child {
    margin-left: auto;
  }
  @media (orientation: portrait) {
    width: 95vw;
  }
`;

const Question = styled.div`
  @media (orientation: landscape) {
    font-size: 1.5vw;
    padding: 5px;
  }
  @media (orientation: portrait) {
    font-size: 3.5vmax;
  }
`;

function GroceryShopping({
  question,
  choices,
  video,
  answerKey,
  nextLink,
  simplified,
}) {
  const [status, setStatus] = useState("Check");
  const [dragSelected, setDragSelected] = useState(0);
  const [dropZone, setDropZone] = useState(0);
  const [audioList, setAudioList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [showVideo, setShowvideo] = useState(false);
  const [currentAudio, setCurrentAudio] = useState();

  const reset = useCallback(() => {
    setAudioList(choices);
    setAnswerList([...answerKey].fill(""));
  });

  useEffect(() => {
    reset();
  }, []);

  /*
   * Pause any currenly playing audio if any
   * Creates a new Audio object to be played and saves it within CurrentAudio so it can be paused
   * @param {String} item - Audio File Path
   */
  const playAudio = (item) => {
    if (currentAudio !== undefined) currentAudio.pause();
    const sound = new Audio(item);
    setCurrentAudio(sound);
    sound.play();
  };

  /*
   ** Drag And Drop Function
   * Terms:
   *   dropZone: selected/droped destination
   *   dragSelected: selected Item for drop
   *   answerList: for checking final correctness
   *   audioList: Audio selection bar
   * If status === Check:
   *   If answerList[dropZone] === '' -> if its not populated:
   *     Map over old answerList, if index === dropZone index, overwrite the item in that index the one in audio
   *   If answerList[dropZone] is already populated:
   *     repopulate selection back into the Audio List
   */
  const handleDrop = () => {
    if (status === "Check") {
      if (answerList[dropZone] === "") {
        setAnswerList(
          [...answerList].map((item, i) =>
            i === dropZone ? audioList[dragSelected] : item
          )
        );
        setAudioList(
          [...audioList].map((item, i) => (i === dragSelected ? "" : item))
        );
      } else {
        const prevAudio = answerList[dropZone];
        setAnswerList(
          [...answerList].map((item, i) =>
            i === dropZone ? audioList[dragSelected] : item
          )
        );
        setAudioList(
          [...audioList].map((item, i) =>
            i === dragSelected
              ? ""
              : choices[i] === prevAudio
              ? prevAudio
              : item
          )
        );
      }
    }
  };

  /*
   * Clears entry of index
   * @param {int} index - index of element to be cleared and re-populated
   */
  const clearEntry = (index) => {
    if (status === "Check") {
      const deletedElement = answerList[index];
      setAnswerList(
        [...answerList].map((item, i) => (index === i ? "" : item))
      );
      setAudioList(
        [...audioList].map((item, i) =>
          choices[i] === deletedElement ? deletedElement : item
        )
      );
    }
  };

  const handleStatus = () => {
    switch (status) {
      case "Check":
        const temp = [];
        for (const answer in answerList) {
          temp[answer] = answerList[answer].price;
        }
        temp.join() === answerKey.join()
          ? setStatus("Next")
          : setStatus("Retry");
        break;
      case "Retry":
        reset();
        setStatus("Check");
        break;
      case "Next":
        window.location.href = nextLink;
        break;
      default:
        break;
    }
  };

  const landscape =
    process.browser && window.matchMedia("(orientation: landscape)").matches;

  return (
    <React.Fragment>
      <Header>
        <StyledQuestion>{question}</StyledQuestion>
        <ButtonContainer>
          {!landscape ? (
            <Button
              style={{ alignSelf: "center" }}
              variant="contained"
              color="secondary"
              onClick={() => setShowvideo(!showVideo)}
            >
              Video
            </Button>
          ) : null}
        </ButtonContainer>
      </Header>
      {showVideo ? (
        <Video controls preload="metadata">
          <source src={video.src} type="video/mp4" />
          <track
            default
            label="English"
            kind="subtitles"
            srcLang="en"
            src={video.captionSrc}
          />
        </Video>
      ) : null}
      <AudioContainer>
        {audioList.map((audio, index) => {
          if (audio.tax) {
            return null;
          }
          return audio !== "" ? (
            <DragDropContainer
              targetKey="audio"
              onDrag={() => setDragSelected(index)}
            >
              <StyledAudio onClick={() => playAudio(audio.audioSrc)}>
                <VolumeUpRoundedIcon key={Math.random()} fontSize="large" />
                {audio.price}
              </StyledAudio>
            </DragDropContainer>
          ) : null;
        })}
      </AudioContainer>
      <SubContainer>
        {landscape ? (
          <Video controls preload="metadata">
            <source src={video.src} type="video/mp4" />
            <track
              default
              label="English"
              kind="subtitles"
              srcLang="en"
              src={video.captionSrc}
            />
          </Video>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {answerList.map((audio, index) => {
            let textColor = "black";
            const style = simplified ? "simplified" : "traditional";
            if (status === "Retry" || status === "Next") {
              textColor = audio.price === answerKey[index] ? "green" : "red";
            }
            if (choices[index].tax) {
              return (
                <Question
                  style={{
                    borderBottom: "solid",
                    justifyContent: "space-around",
                    display: "flex",
                  }}
                >
                  <div>+ {choices[index].tax}</div>
                  <div>{choices[index].price}</div>
                </Question>
              );
            }
            return (
              <DropTarget
                targetKey="audio"
                onHit={() => handleDrop()}
                onDragEnter={() => setDropZone(index)}
              >
                <DropTargetField>
                  <Question>{choices[index][style]}</Question>
                  <TextField
                    id="bare"
                    margin="normal"
                    disabled
                    InputProps={{
                      style: {
                        width: "35vmin",
                        color: textColor,
                        fontSize: 25,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {audio !== "" ? (
                            <StyledAudio>
                              <VolumeUpRoundedIcon
                                key={Math.random()}
                                fontSize="large"
                                onClick={() => playAudio(audio.audioSrc)}
                              />
                              <div
                                onClick={() => clearEntry(index)}
                                onKeyDown={() => clearEntry(index)}
                              >
                                {audio.price}
                              </div>
                            </StyledAudio>
                          ) : null}
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </DropTargetField>
              </DropTarget>
            );
          })}
        </div>
      </SubContainer>
      <Button
        style={{ alignSelf: "center" }}
        variant="contained"
        color="secondary"
        onClick={() => handleStatus()}
      >
        {status}
      </Button>
    </React.Fragment>
  );
}

export default GroceryShopping;
