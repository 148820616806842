import React, { useState, useEffect } from "react";
import Breadcrumb from "../Breadcrumb";
import ChengyuTable from "./ChengyuTable";
import unsplitChengyu2Data from "../../data/chengyu/unsplitChengyu2.js";
import unsplitTTCData from "../../data/chengyu/unsplitTripToChina.js";
import { getBreadcrumbRoutes } from "../../helpers/breadcrumbRoutes.js";
import { Link } from "@material-ui/core";

import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import "./css/chengyutable.css";


const VocabListPage = props => {
  const url = window.location.pathname;
  const [chengyuData, setChengyuData] = useState([]);
  const [module, setModule] = useState("");
  const [bookmarks, setBookmarks] = useState([]);

  useEffect(() => {
    if (url.includes("chengyu")) {
      setModule("chengyu");
      setChengyuData(unsplitChengyu2Data);
    } else if (url.includes("triptochina")) {
      setModule("triptochina");
      setChengyuData(unsplitTTCData);
    }

    if (localStorage.getItem("bookmarks") !== null) {
      setBookmarks(JSON.parse(localStorage.getItem("bookmarks")));
    }

  }, [url]);

  const removeBookmark = item => {
    let newBookmarks = bookmarks.filter(curr => !curr[module].includes(item));

    localStorage.setItem("bookmarks", JSON.stringify(newBookmarks));
    setBookmarks(newBookmarks);
  }

  const addBookmark = (simplified, traditional, url) => {
    let newBookmarks = bookmarks;
    let newItem = {
      [module]: [simplified, traditional],
      url: url,
    };

    newBookmarks.push(newItem);

    localStorage.setItem("bookmarks", JSON.stringify(newBookmarks));
    setBookmarks([...bookmarks, newItem]);
  }
  
  const columnHeaders = [{
    Header: 'Simplified',
    accessor: 'simplified',
    Cell: ({ row }) => (<Link target="_blank" rel="noopener noreferrer" href={row.original.vocabLink}>{row.original.simplified}</Link>)
  },
  {
    Header: 'Traditional',
    accessor: 'traditional',
    Cell: ({ row }) => (<Link target="_blank" rel="noopener noreferrer" href={row.original.vocabLink}>{row.original.traditional}</Link>)
  },
  {
    Header: 'Pinyin',
    accessor: 'pinyin',
  },
  {
    Header: 'English Definition',
    accessor: 'englishDefinition',
  },
  {
    Header: 'Bookmark',
    Cell: ({ row }) => (
      bookmarks.map(curr => { return curr[module] && curr[module][0] === (row.original.simplified) }).indexOf(true) > -1 ?
        <div
          onClick={() => removeBookmark(row.original.simplified)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}>
          <BookmarkIcon className="bookmarkIcon" style={{ color: "#273b6f" }} />
        </div> :
        <div
          onClick={() => addBookmark(row.original.simplified, row.original.traditional, row.original.vocabLink)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}>
          <BookmarkBorderIcon className="bookmarkIcon" style={{ color: "#273b6f" }} />
        </div>
    )
  }
  ];

  return (
    <div>
      <Breadcrumb routes={getBreadcrumbRoutes(props.match)} />
      <div style={{ textAlign: 'center', padding: '0px 10px', marginBottom: '10px' }}>
        This is the list of all the idioms and phrases found in <em>{module === "chengyu" ? "Chengyu 2" : "A Trip to China"}</em>. Click on the table headings to sort the idioms and phrases by character, or alphabetically by their pinyin or English definition. Click on either the simplified or traditional word to be taken to their respective pages.
        There is also a search function to search for the idioms and phrases directly. You can type in the pinyin, English definition, or the traditional and simplified characters.
      </div>
      <ChengyuTable columnHeaders={columnHeaders} unsplitData={chengyuData} />
    </div>
  )
}

export default VocabListPage;