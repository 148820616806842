import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { buildQueryString } from "./helpers.js";
import Breadcrumb from "../Breadcrumb";
import { getBreadcrumbRoutes } from "../../helpers/breadcrumbRoutes.js";
import styled from "styled-components";

const PracticeButton = styled.div({
  backgroundColor: "rgba(60, 122, 216, 0.69)",
  width: "400px",
  height: "120px",
  margin: "25px",
  borderRadius: "15px",
  overflow: "hidden",
  "@media(max-width: 700px)": {
    width: "85vw",
  },
});

const PracticeContainer = styled.div({
  display: "flex",
  flexFlow: "row wrap",
  padding: "25px",
  justifyContent: "flex-start",
  alignContent: "center",
  "@media(max-width: 700px)": {
    justifyContent: "center",
  },
});

class ChengyuGroup extends Component {
  constructor(props) {
    super(props);
    this.state = { language: "traditional" };
  }

  render() {
    const query = buildQueryString({
      language: this.props.language,
    });
    const { groupId, lesson } = this.props.match.params;
    const basePath = this.props.match.params[0];

    const practices = [
      { number: 1, name: "Crossword Puzzles" },
      { number: 2, name: "Fill in the blank - Pinyin" },
      { number: 3, name: "Multiple choice - English Definition" },
      { number: 4, name: "Fill in the blank - Characters" },
      { number: 5, name: "Sentences" },
    ];

    const practiceButtons = practices.map((practice, i) => (
      <Link
        to={`/${basePath}/${lesson}/group/${groupId}/practice/${
          i + 1
        }/?${query}`}
        style={{ textDecoration: "none" }}
      >
        <PracticeButton>
          <div style={styles.practiceIconSection}>
            <CreateOutlinedIcon style={{ color: "black", fontSize: "70px" }} />
          </div>
          <div style={styles.practiceTitleSection}>
            <div style={styles.practiceTitleText}> Practice {i + 1}</div>
            <p style={styles.practiceSubText}>{practice.name}</p>
          </div>
        </PracticeButton>
      </Link>
    ));

    return (
      <div style={{ overflow: "hidden" }}>
        <Breadcrumb routes={getBreadcrumbRoutes(this.props.match)} />
        <PracticeContainer>{practiceButtons}</PracticeContainer>
      </div>
    );
  }
}

export default withRouter(ChengyuGroup);

const styles = {
  practiceIconSection: {
    height: "100%",
    width: "30%",
    float: "left",
    backgroundColor: "#aec6eb",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "15px 0px 0px 15px",
  },
  practiceTitleText: {
    fontSize: "30px",
    fontWeight: 600,
    marginLeft: "5%",
    color: "black",
  },
  practiceSubText: {
    fontSize: "17px",
    lineHeight: "24px",
    textAlign: "left",
    margin: 0,
    marginLeft: "5%",
    padding: "0px",
    width: "100%",
    color: "black",
  },
  practiceTitleSection: {
    height: "100%",
    width: "70%",
    display: "flex",
    flexFlow: "row wrap",
    alignContent: "center",
  },
};
