const basePath = "https://materials.chineseforall.org";
export default {
  0: {
    0: {
      q: "Which of the following were said in the video?",
      type: "multipleSound",
      choices: [
        {
          text: "我要一串牛肉串。",
          audio: basePath + "/assets/videos/module2/vendor/m1_vendor_1-1.mp3",
          isCorrect: true,
        },
        {
          text: "要辣椒吗？",
          audio: basePath + "/assets/videos/module2/vendor/m1_vendor_1-3.mp3",
          isCorrect: true,
        },
        {
          text: "多少钱？",
          audio: basePath + "/assets/videos/module2/vendor/m1_vendor_1-5.mp3",
          isCorrect: true,
        },
        {
          text: "给你五块。",
          audio: basePath + "/assets/videos/module2/vendor/m1_vendor_1-7.mp3",
          isCorrect: true,
        },
        {
          text: "谢谢你。",
          audio: basePath + "/assets/videos/module2/vendor/m1_vendor_1-6.mp3",
        },
        {
          text: "我要一串鸡肉串。",
          audio: basePath + "/assets/videos/module2/vendor/m1_vendor_1-2.mp3",
        },
        {
          text: "两块。",
          audio: basePath + "/assets/videos/module2/vendor/m1_vendor_1-4.mp3",
        },
      ],
      answerKey: [true, true, true, true, false, false, false],
    },
    1: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question: "What does the customer want?",
          mainAudio:
            basePath + "/assets/videos/module2/vendor/m1_vendor_2-1.mp3",
          subChoices: [
            {
              text: "牛肉串",
              isCorrect: true,
            },
            {
              text: "鸡肉串",
            },
            {
              text: "羊肉串",
            },
          ],
        },
        {
          question: "What does the vendor offer?",
          mainAudio:
            basePath + "/assets/videos/module2/vendor/m1_vendor_2-2.mp3",
          subChoices: [
            {
              text: "盐",
            },
            {
              text: "糖",
            },
            {
              text: "辣椒",
              isCorrect: true,
            },
          ],
        },
        {
          question: "How much does it cost?",
          mainAudio:
            basePath + "/assets/videos/module2/vendor/m1_vendor_2-3.mp3",
          subChoices: [
            {
              text: "$3",
            },
            {
              text: "$5",
            },
            {
              text: "$2",
              isCorrect: true,
            },
          ],
        },
      ],
    },
    2: {
      q: "Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "牛肉",
          textS: "牛肉",
          audio: basePath + "/assets/audio/module2/vendor/m1_vendor_3-1.mp3",
        },
        {
          text: "羊肉",
          textS: "羊肉",
          audio: basePath + "/assets/audio/module2/vendor/m1_vendor_3-4.mp3",
        },
        {
          text: "雞肉",
          textS: "鸡肉",
          audio: basePath + "/assets/audio/module2/vendor/m1_vendor_3-2.mp3",
        },
        {
          text: "辣椒",
          textS: "辣椒",
          audio: basePath + "/assets/audio/module2/vendor/m1_vendor_3-3.mp3",
        },
        {
          text: "鹽",
          textS: "盐",
          audio: basePath + "/assets/audio/module2/vendor/m1_vendor_3-5.mp3",
        },
        {
          text: "糖",
          textS: "糖",
          audio: basePath + "/assets/audio/module2/vendor/m1_vendor_3-6.mp3",
        },
      ],
      answers: ["牛肉", "羊肉", "雞肉", "辣椒", "鹽", "糖"],
      answersS: ["牛肉", "羊肉", "鸡肉", "辣椒", "盐", "糖"],
      images: [
        basePath + "/assets/videos/module2/vendor/task/beef.png",
        basePath + "/assets/videos/module2/vendor/task/lamb.png",
        basePath + "/assets/videos/module2/vendor/task/chicken.png",
        basePath + "/assets/videos/module2/vendor/task/hotsauce.png",
        basePath + "/assets/videos/module2/vendor/task/salt.png",
        basePath + "/assets/videos/module2/vendor/task/sugar.png",
      ],
    },
    3: {
      q: "Vendor - Shish Kebab",
      type: "literacy",
      audio: basePath + "/assets/sound/ven_v1/paragraph.mp3",
      choices: [
        {
          學生: "*老闆*， 我*要**一串**牛肉串*。",
          学生S: "*老板*， 我*要**一串**牛肉串*。",
          Tooltip: [
            "yào,want",
            "yí chuàn, one",
            "niú ròu chuàn, beef Shish Kebab",
          ],
          English: "Hi, I want a beef shish kebab.",
          Pinyin: "lǎo bǎn, wǒ yào yí chuàn niú ròu chuàn",
        },
        {
          老闆: "*好的*, 要*辣椒*嗎？",
          老板S: "*好的*, 要*辣椒*吗？",
          Tooltip: ["hǎo de, ok", "là jiāo, hot pepper"],
          English: "Do you want hot pepper?",
          Pinyin: "hǎo de, yào là jiāo ma",
        },
        {
          學生: "要。 *多少**錢*？",
          学生S: "要。 *多少**钱*？",
          Tooltip: ["duō shǎo, how much", "qián, money"],
          English: "Yes. How much?",
          Pinyin: "yào, duō shǎo qián",
        },
        {
          老闆: "一*塊*錢。",
          老板S: "一*块*钱。",
          Tooltip: ["kuài, measure word for money"],
          English: "One dollar.",
          Pinyin: "yí kuài qián",
        },
        {
          學生: "*給你*五塊。",
          学生S: "*给你*五块。",
          Tooltip: [
            "gěi nǐ, ‘give-you’ Here is (said when handing something to someone)",
          ],
          English: "Here is $5.00.",
          Pinyin: "gěi nǐ wǔ kuài",
        },
        {
          老闆: "好的。",
          老板S: "好的。",
          Tooltip: [],
          English: "Ok.",
          Pinyin: "hǎo de",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/ven_v1/s1.mp3",
          basePath + "/assets/sound/ven_v1/s2.mp3",
          basePath + "/assets/sound/ven_v1/s3.mp3",
          basePath + "/assets/sound/ven_v1/s4.mp3",
          basePath + "/assets/sound/ven_v1/s5.mp3",
          basePath + "/assets/sound/ven_v1/s6.mp3",
          basePath + "/assets/sound/ven_v1/s7.mp3",
          basePath + "/assets/sound/ven_v1/s8.mp3",
          basePath + "/assets/sound/ven_v1/s9.mp3",
          basePath + "/assets/sound/ven_v1/s10.mp3",
        ],
      },
    },
  },
  1: {
    0: {
      q: "Which of the following sentences are true?",
      type: "single",
      choices: [
        {
          text: "The customer wants hot peppers on the kebabs.",
        },
        {
          text: "The customer wants chicken and beef kebabs.",
        },
        {
          text: "Each kebab costs two dollars.",
        },
        {
          text: "The customer wants two kebabs.",
          isCorrect: true,
        },
      ],
    },
    1: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question: "1. What does the customer want?",
          mainAudio: basePath + "/assets/audio/module2/vendor/m1_vendor_5.mp3",
          subChoices: [
            {
              text: "羊肉串",
              audio:
                basePath + "/assets/videos/module2/vendor/m1_vendor_5-2.mp3",
            },
            {
              text: "熱狗",
              isCorrect: true,
              audio:
                basePath + "/assets/videos/module2/vendor/m1_vendor_5-1.mp3",
            },
            {
              text: "辣椒",
              audio:
                basePath + "/assets/audio/module2/vendor/m1_vendor_3-3.mp3",
            },
          ],
        },
        {
          question: "2. What does the vendor offer?",
          mainAudio: basePath + "/assets/audio/module2/vendor/m1_vendor_5.mp3",
          subChoices: [
            {
              text: "鹽",
              audio:
                basePath + "/assets/audio/module2/vendor/m1_vendor_3-5.mp3",
            },
            {
              text: "糖",
              audio:
                basePath + "/assets/audio/module2/vendor/m1_vendor_3-6.mp3",
            },
            {
              text: "芥末",
              audio:
                basePath + "/assets/audio/module2/vendor/m1_vendor_5-3.mp3",
              isCorrect: true,
            },
          ],
        },
        {
          question: "3. How much does it cost?",
          mainAudio: basePath + "/assets/audio/module2/vendor/m1_vendor_5.mp3",
          subChoices: [
            {
              text: "$3",
              audio:
                basePath + "/assets/audio/module2/vendor/m1_vendor_5-5.mp3",
              isCorrect: true,
            },
            {
              text: "$1",
              audio:
                basePath + "/assets/audio/module2/vendor/m1_vendor_5-4.mp3",
            },
            {
              text: "$5",
              audio:
                basePath + "/assets/audio/module2/vendor/m1_vendor_5-6.mp3",
            },
          ],
        },
      ],
    },
    2: {
      q: "Leave a voicemail message for your instructor indicating that you would like to order a beef shish kabob with hot sauce.",
      type: "recording",
      choices: [],
    },
    3: {
      q: "Vendor 2 - Shish Kebab",
      type: "literacy",
      audio: basePath + "/assets/sound/ven_v2/paragraph.mp3",
      choices: [
        {
          學生: "老闆，我要一串*雞肉*串，一串*羊肉*串。",
          学生S: "老板，我要一串*鸡肉*串，一串*羊肉*串。",
          Tooltip: ["jī ròu, chicken (meat)", "yáng ròu, lamb (meat)"],
          English: "Hi, I want a chicken and a lamb kebab",
          Pinyin:
            "lǎo bǎn, wǒ yào yí chuàn jī ròu chuàn, yí chuàn yáng ròu chuàn",
        },
        {
          老闆: "好的",
          老板S: "好的",
          Tooltip: ["hǎo de, ok", "là jiāo, hot pepper"],
          English: "OK.",
          Pinyin: "hǎo de",
        },
        {
          學生: "我的雞肉串要*一點點**辣*，羊肉串要*撒*一點*孜然粉*。",
          学生S: "我的鸡肉串要*一点点**辣*，羊肉串要*撒*一点*孜然粉*。",
          Tooltip: [
            "yì diǎn diǎn, a little bit",
            "là, spicy",
            "sǎ, to spread (powdery objects such as sugar, salt, pepper or water, seeds)",
            "zī rán fěn, cumin seeds (powder)",
          ],
          English:
            "For chicken, I want you to add a bit hot sauce. For lamb, spread some cumin seed powder.",
          Pinyin:
            "wǒ de jī ròu chuàn yào yì diǎn diǎn là, yáng ròu chuàn yào sǎ yì diǎn zī rán fěn",
        },
        {
          老闆: "*好勒*。好了，兩塊。",
          老板S: "*好勒*。好了，兩块。",
          Tooltip: [
            "hǎo lèi, same as 好的. OK (The sound lèi 勒 is used for emphasis)",
          ],
          English: "OK.  It's done. Two dollars.",
          Pinyin: "hǎo lèi, hǎo le, liǎng kuài",
        },
        {
          學生: "兩塊給你。",
          学生S: " 兩块给你。",
          Tooltip: [],
          English: "Here is $2.00",
          Pinyin: "liǎng kuài gěi nǐ",
        },
        {
          老闆: "谢谢啊。",
          老板S: "謝謝啊。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie a",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/ven_v2/s1.mp3",
          basePath + "/assets/sound/ven_v2/s2.mp3",
          basePath + "/assets/sound/ven_v2/s3.mp3",
          basePath + "/assets/sound/ven_v2/s4.mp3",
          basePath + "/assets/sound/ven_v2/s5.mp3",
          basePath + "/assets/sound/ven_v2/s6.mp3",
          basePath + "/assets/sound/ven_v2/s7.mp3",
        ],
      },
    },
    4: {
      q: "Vendor 2 - Key Words",
      type: "keywords",
      choices: [
        {
          english: "Xingjiang (Uygur Autonomous Region)",
          traditional: "新疆",
          pinyin: "xīn jiāng",
        },
        {
          english: "Authentic",
          traditional: "正宗",
          pinyin: "zhèng zōng",
        },
        {
          english: "Roast meat, BBQ",
          traditional: "烤肉",
          pinyin: "kǎo ròu",
        },
        {
          english: "Lamb (meat)",
          traditional: "羊肉",
          pinyin: "yáng ròu",
        },
        {
          english: "Lamb kebab",
          traditional: "羊肉串",
          pinyin: "yáng ròu chuàn",
        },
        {
          english: "style and flavor (said of food, attire, song)",
          traditional: "風味",
          pinyin: "fēng wèi",
        },
      ],
    },
    5: {
      q: "Match each word with its meaning.",
      type: "line matching",
      choices: [
        {
          english: "Xingjiang",
          simplified: "新疆",
          traditional: "新疆",
        },
        {
          english: "Authentic",
          simplified: "正宗",
          traditional: "正宗",
        },
        {
          english: "Roast meat, BBQ",
          simplified: "烤肉",
          traditional: "烤肉",
        },
        {
          english: "Lamb (meat)",
          simplified: "羊肉",
          traditional: "羊肉",
        },
        {
          english: "Lamb kebab",
          simplified: "羊肉串",
          traditional: "羊肉串",
        },
        {
          english: "Style and flavor",
          simplified: "风味",
          traditional: "風味",
        },
      ],
    },
    6: {
      q: "Match each word with its pinyin.",
      type: "line matching",
      choices: [
        {
          english: "xīn jiāng",
          simplified: "新疆",
          traditional: "新疆",
        },
        {
          english: "zhèng zōng",
          simplified: "正宗",
          traditional: "正宗",
        },
        {
          english: "kǎo ròu",
          simplified: "烤肉",
          traditional: "烤肉",
        },
        {
          english: "yáng ròu",
          simplified: "羊肉",
          traditional: "羊肉",
        },
        {
          english: "yáng ròu chuàn",
          simplified: "羊肉串",
          traditional: "羊肉串",
        },
        {
          english: "fēng wèi",
          simplified: "风味",
          traditional: "風味",
        },
      ],
    },
    7: {
      q: "Study the character's meaning, pinyin, radical, strokes, and compound word.",
      type: "analysis",
      choices: [
        {
          traditional: "新",
          simplified: "新",
          pinyin: "xin1",
          meaning: "new",
          strokesT: "13",
          strokesS: "13",
          radicalT: "斤 jin1, catty (axe)",
          radicalS: "斤 jin1, catty (axe)",
          wordT: "新年 xin1nian2, new year",
          wordS: "新年 xin1nian2, new year",
          etymology:
            "The original meaning of 新 is firewood. The meaning component is 斤 jīn axe. The sound component is 亲 qīn.",
          strokeOrderT: basePath + "/assets/gifs/vendor_2/vendor2_1.gif",
          strokeOrderS: basePath + "/assets/gifs/vendor_2/vendor2_1.gif",
          audio: basePath + "/assets/travel_literacy_activity3/xin1.mp3",
          seal:
            basePath +
            "/assets/images/vendor2_literacy_activity_2/seal_1.png",
        },
        {
          traditional: "疆",
          simplified: "疆",
          pinyin: "jiang1",
          meaning: "border",
          strokesT: "19",
          strokesS: "19",
          radicalT: "田 tian2, field",
          radicalS: "田 tian2, field",
          wordT: "邊疆 bian1 jiang1, border area",
          wordS: "邊疆 bian1 jiang1, border area",
          // etymology: 'Pictograph of an eye, turned sideways.',
          strokeOrderT: basePath + "/assets/gifs/vendor_2/vendor2_2.gif",
          strokeOrderS: basePath + "/assets/gifs/vendor_2/vendor2_2.gif",
          audio: basePath + "/assets/travel_literacy_activity3/jiang1.mp3",
          // seal: '/assets/images/bubbleTea2_literacy_activity_2/seal_2.png',
        },
        {
          traditional: "羊",
          simplified: "羊",
          pinyin: "yang2",
          meaning: "goat",
          strokesT: "6",
          strokesS: "6",
          radicalT: "羊 yang2, goat",
          radicalS: "羊 yang2, goat",
          wordT: "羊毛 yang2 mao2, wool",
          wordS: "羊毛 yang2 mao2, wool",
          // etymology: 'The meaning component is 女 nǚ woman. The sound component is 乃 nǎi.',
          strokeOrderT: basePath + "/assets/gifs/vendor_2/vendor2_3.gif",
          strokeOrderS: basePath + "/assets/gifs/vendor_2/vendor2_3.gif",
          audio: basePath + "/assets/travel_literacy_activity3/yang2.mp3",
          // seal: '/assets/images/bubbleTea2_literacy_activity_2/seal_3.png',
        },
        {
          traditional: "雞",
          simplified: "鸡",
          pinyin: "ji1",
          meaning: "chicken",
          strokesT: "18",
          strokesS: "7",
          radicalT: "隹 zhui1, short-tailed bird",
          radicalS: "鸟 niao3, bird",
          wordT: "雞蛋 ji1dan4, (chicken) egg",
          wordS: "鸡蛋 ji1dan4, (chicken) egg",
          // etymology: 'The original form of 冰 is 冫bīng. The radical component is 水 shuǐ water. The component is also 冫bīng.',
          strokeOrderT:
            basePath + "/assets/gifs/vendor_2/vendor2_4_traditional.gif",
          strokeOrderS:
            basePath + "/assets/gifs/vendor_2/vendor2_4_simplified.gif",
          audio: basePath + "/assets/travel_literacy_activity3/ji1.mp3",
          // seal: '/assets/images/bubbleTea2_literacy_activity_2/seal_4.png',
        },
        {
          traditional: "牛",
          simplified: "牛",
          pinyin: "niu2",
          meaning: "ox",
          strokesT: "4",
          strokesS: "4",
          radicalT: "牛 niu2, ox",
          radicalS: "牛 niu2, ox",
          wordT: "牛排 niu2pai2, beefsteak",
          wordS: "牛排 niu2pai2, beefsteak",
          etymology: "Pictograph of an ox.",
          strokeOrderT: basePath + "/assets/gifs/vendor_2/vendor2_5.gif",
          strokeOrderS: basePath + "/assets/gifs/vendor_2/vendor2_5.gif",
          audio: basePath + "/assets/travel_literacy_activity3/niu2.mp3",
          seal:
            basePath + "/assets/images/vendor2_literacy_activity_2/seal_5.png",
        },
        {
          traditional: "串",
          simplified: "串",
          pinyin: "chuan4",
          meaning: "to string together",
          strokesT: "7",
          strokesS: "7",
          radicalT: "丨 gun3, line",
          radicalS: "丨 gun3, line",
          wordT: "串燒 chuan4 shao1, to cook on a skewer",
          wordS: "串燒 chuan4 shao1, to cook on a skewer",
          // etymology: 'The meaning component is 雨 yǔ rain. The sound component is 彗 huì.',
          strokeOrderT: basePath + "/assets/gifs/vendor_2/vendor2_6.gif",
          strokeOrderS: basePath + "/assets/gifs/vendor_2/vendor2_6.gif",
          audio: basePath + "/assets/travel_literacy_activity3/chuan4.mp3",
          // seal: '/assets/images/bubbleTea2_literacy_activity_2/seal_6.png',
        },
        {
          traditional: "肉",
          simplified: "肉",
          pinyin: "rou4",
          meaning: "flesh; meat",
          strokesT: "6",
          strokesS: "6",
          radicalT: "肉 rou4, flesh",
          radicalS: "肉 rou4, flesh",
          wordT: "肉食 rou4shi2, meat products",
          wordS: "肉食 rou4shi2, meat products",
          etymology: "Pictograph of a piece of meat.",
          strokeOrderT: basePath + "/assets/gifs/vendor_2/vendor2_7.gif",
          strokeOrderS: basePath + "/assets/gifs/vendor_2/vendor2_7.gif",
          audio: basePath + "/assets/travel_literacy_activity3/rou4.mp3",
          seal:
            basePath + "/assets/images/vendor2_literacy_activity_2/seal_7.png",
        },
        {
          traditional: "烤",
          simplified: "烤",
          pinyin: "kao3",
          meaning: "bake; roast",
          strokesT: "10",
          strokesS: "10",
          radicalT: "火 huo3, fire",
          radicalS: "火 huo3, fire",
          wordT: "烤肉 kao3rou4, barbecue",
          wordS: "烤肉 kao3rou4, barbecue",
          // etymology: 'Pictograph of water currents in a river or stream.',
          strokeOrderT: basePath + "/assets/gifs/vendor_2/vendor2_8.gif",
          strokeOrderS: basePath + "/assets/gifs/vendor_2/vendor2_8.gif",
          audio: basePath + "/assets/travel_literacy_activity3/kao3.mp3",
          // seal: '/assets/images/bubbleTea2_literacy_activity_2/seal_7.png',
        },
        {
          traditional: "正",
          simplified: "正",
          pinyin: "zheng4",
          meaning: "right; straight",
          strokesT: "5",
          strokesS: "5",
          radicalT: "止 zhi3, stop",
          radicalS: "止 zhi3, stop",
          wordT: "正好 zheng4hao3, just right",
          wordS: "正好 zheng4hao3, just right",
          etymology:
            "The original meaning of 正 is proper. The meaning is represented by 止 zhǐ stop and symbol 一 a line symbolizing a limit or boundary.",
          strokeOrderT: basePath + "/assets/gifs/vendor_2/vendor2_9.gif",
          strokeOrderS: basePath + "/assets/gifs/vendor_2/vendor2_9.gif",
          audio: basePath + "/assets/travel_literacy_activity3/zheng4.mp3",
          seal:
            basePath + "/assets/images/vendor2_literacy_activity_2/seal_9.png",
        },
        {
          traditional: "宗",
          simplified: "宗",
          pinyin: "zong1",
          meaning: "ancestor; principal",
          strokesT: "8",
          strokesS: "8",
          radicalT: "宀 mian2, house",
          radicalS: "宀 mian2, house",
          wordT: "宗教 zong1jiao4, religion",
          wordS: "宗教 zong1jiao4, religion",
          etymology:
            "The meaning of ancestor is represented by 示 shì and 宀 mian house.",
          strokeOrderT: basePath + "/assets/gifs/vendor_2/vendor2_10.gif",
          strokeOrderS: basePath + "/assets/gifs/vendor_2/vendor2_10.gif",
          audio: basePath + "/assets/travel_literacy_activity3/zong1.mp3",
          seal:
            basePath + "/assets/images/vendor2_literacy_activity_2/seal_10.png",
        },
        {
          traditional: "風",
          simplified: "风",
          pinyin: "feng1",
          meaning: "wind",
          strokesT: "9",
          strokesS: "4",
          radicalT: "風 feng1, wind",
          radicalS: "风 feng1, wind",
          wordT: "風水 feng1shui3, geomancy",
          wordS: "风水 feng1shui3, geomancy",
          etymology:
            "The meaning component is 穴 xué cave. The sound component is 凡 fán.",
          strokeOrderT:
            basePath + "/assets/gifs/vendor_2/vendor2_11_traditional.gif",
          strokeOrderS:
            basePath + "/assets/gifs/vendor_2/vendor2_11_simplified.gif",
          audio: basePath + "/assets/travel_literacy_activity3/feng1.mp3",
          seal:
            basePath + "/assets/images/vendor2_literacy_activity_2/seal_11.png",
        },
        {
          traditional: "味",
          simplified: "味",
          pinyin: "wei4",
          meaning: "taste; smell",
          strokesT: "8",
          strokesS: "8",
          radicalT: "口 kou3, mouth",
          radicalS: "口 kou3, mouth",
          wordT: "味道 wei4dao4, taste",
          wordS: "味道 wei4dao4, taste",
          etymology:
            "The meaning component is 口 kǒu mouth. The sound component is 未 wèi.",
          strokeOrderT: basePath + "/assets/gifs/vendor_2/vendor2_12.gif",
          strokeOrderS: basePath + "/assets/gifs/vendor_2/vendor2_12.gif",
          audio: basePath + "/assets/travel_literacy_activity3/wei4.mp3",
          seal:
            basePath + "/assets/images/vendor2_literacy_activity_2/seal_12.png",
        },
      ],
    },
    8: {
      q: "Fill in the table by dragging and dropping the answers below into the corresponding boxes.",
      type: "analysis-learning",
      choices: [
        {
          traditional: "新",
          simplified: "新",
          pinyin: "xin1",
          meaning: "new",
          strokesT: "13",
          strokesS: "13",
          radicalT: "斤 jin1, catty (axe)",
          radicalS: "斤 jin1, catty (axe)",
        },
        {
          traditional: "風",
          simplified: "风",
          pinyin: "feng1",
          meaning: "wind",
          strokesT: "9",
          strokesS: "4",
          radicalT: "風 feng1, wind",
          radicalS: "风 feng1, wind",
        },
        {
          traditional: "味",
          simplified: "味",
          pinyin: "wei4",
          meaning: "taste; smell",
          strokesT: "8",
          strokesS: "8",
          radicalT: "口 kou3, mouth",
          radicalS: "口 kou3, mouth",
        },
        {
          traditional: "烤",
          simplified: "烤",
          pinyin: "kao3",
          meaning: "bake; roast",
          strokesT: "10",
          strokesS: "10",
          radicalT: "火 huo3, fire",
          radicalS: "火 huo3, fire",
        },
        {
          traditional: "正",
          simplified: "正",
          pinyin: "zheng4",
          meaning: "right; straight",
          strokesT: "5",
          strokesS: "5",
          radicalT: "止 zhi3, stop",
          radicalS: "止 zhi3, stop",
        },
      ],
    },
    9: {
      q: "Combine components to form characters",
      type: "rearrange chinese",
      choices: [
        {
          pinyin: ["xīn", ""],
          simplified: ["亲", "斤"],
          traditional: ["亲", "斤"],
        },
        {
          pinyin: ["jī", ""],
          simplified: ["又", "鸟"],
          traditional: ["奚", "隹"],
        },
        {
          pinyin: ["kǎo", ""],
          simplified: ["火", "考"],
          traditional: ["火", "考"],
        },
        {
          pinyin: ["zōng", ""],
          simplified: ["宀", "示"],
          traditional: ["宀", "示"],
        },
        {
          pinyin: ["wèi", ""],
          simplified: ["肉", "未"],
          traditional: ["肉", "未"],
        },
      ],
      taskSpecificParams: {
        layout: "mobile",
      },
    },
    10: {
      q: "Combine two characters into meaningful compound words by dragging the character to the corresponding box.",
      type: "rearrange chinese",
      choices: [
        {
          pinyin: ["xīn", "jiāng"],
          simplified: ["新", "疆"],
          traditional: ["新", "疆"],
        },
        {
          pinyin: ["zhèng", "zōng"],
          simplified: ["正", "宗"],
          traditional: ["正", "宗"],
        },
        {
          pinyin: ["kǎo", "ròu"],
          simplified: ["火", "肉"],
          traditional: ["火", "肉"],
        },
        {
          pinyin: ["fēng", "wèi"],
          simplified: ["风", "味"],
          traditional: ["風", "味"],
        },
        {
          pinyin: ["ròu", "chuàn"],
          simplified: ["肉", "串"],
          traditional: ["肉", "串"],
        },
        {
          pinyin: ["yáng", "ròu"],
          simplified: ["羊", "肉"],
          traditional: ["羊", "肉"],
        },
        {
          pinyin: ["niú", "ròu"],
          simplified: ["牛", "肉"],
          traditional: ["牛", "肉"],
        },
        {
          pinyin: ["jī", "ròu"],
          simplified: ["鸡", "肉"],
          traditional: ["雞", "肉"],
        },
      ],
      taskSpecificParams: {
        layout: "mobile",
      },
    },
    11: {
      q: "Rearrange the following characters from the word bank into the correct sentence order.",
      type: "rearrange chinese",
      choices: [
        {
          pinyin: [
            "lǎo",
            "bǎn",
            "wǒ",
            "yào",
            "yí",
            "chuàn",
            "niú",
            "ròu",
            "chuàn",
          ],
          simplified: ["老", "板", "我", "要", "一", "串", "牛", "肉", "串"],
          traditional: ["老", "闆", "我", "要", "一", "串", "牛", "肉", "串"],
        },
        {
          pinyin: ["yào", "là", "jiāo"],
          simplified: ["要", "辣", "椒"],
          traditional: ["要", "辣", "椒"],
        },
        {
          pinyin: ["duō", "shǎo", "qián"],
          simplified: ["多", "少", "钱"],
          traditional: ["多", "少", "錢"],
        },
        {
          pinyin: ["yí", "kuài", "qián"],
          simplified: ["一", "块", "钱"],
          traditional: ["一", "塊", "錢"],
        },
        {
          pinyin: ["gěi", "nǐ", "wǔ", "kuài"],
          simplified: ["给", "你", "五", "块"],
          traditional: ["給", "你", "五", "塊"],
        },
      ],
    },
    12: {
      q: "",
      type: "multi-single",
      img: basePath + "/assets/images/food/vendor2/vl_2_11.png",
      choices: [
        {
          question: "1. What kind of food does this vendor sell",
          subChoices: [
            {
              text: "BBQ",
              isCorrect: true,
            },
            {
              text: "bread",
            },
            {
              text: "soda",
            },
          ],
        },
        {
          question: "2. What is the cooking style of the food?",
          subChoices: [
            {
              text: "Mongolia",
              isCorrect: true,
            },
            {
              text: "Xinjiang",
            },
            {
              text: "Hong Kong",
            },
          ],
        },
      ],
    },
  },
  2: {
    0: {
      q: "What is the customer trying to buy?",
      type: "single",
      choices: [
        {
          text: "冰的珍珠咖啡",
        },
        {
          text: "热的珍珠奶茶",
        },
        {
          text: "冰的珍珠奶茶",
          isCorrect: true,
        },
        {
          text: "热的珍珠咖啡",
        },
      ],
    },
    1: {
      q: "Which of the following did the customer say during the transaction?",
      type: "multiple",
      choices: [
        {
          text: "我要",
          isCorrect: true,
        },
        {
          text: "请问多少钱？",
          isCorrect: true,
        },
        {
          text: "请问几块钱？",
        },
        {
          text: "给我来一杯",
        },
      ],
    },
    2: {
      q: "Listen to the individual audio clips, then put them in the correct order by rearranging them.",
      type: "arrange-audio-clips",
      choices: [
        {
          id: 0,
          audio:
            basePath + "/assets/audio/module2/bubbletea/1-3/bubbletea1-3a.mp3",
        },
        {
          id: 1,
          audio:
            basePath + "/assets/audio/module2/bubbletea/1-3/bubbletea1-3b.mp3",
        },
        {
          id: 2,
          audio:
            basePath + "/assets/audio/module2/bubbletea/1-3/bubbletea1-3c.mp3",
        },
        {
          id: 3,
          audio:
            basePath + "/assets/audio/module2/bubbletea/1-3/bubbletea1-3d.mp3",
        },
      ],
      answerKey: [0, 1, 2, 3],
    },
    3: {
      q: "Bubble Tea",
      type: "literacy",
      audio: basePath + "/assets/sound/tea_v1/paragraph.mp3",
      choices: [
        {
          客人: "你好，我要一杯大杯的珍珠奶茶。",
          客人S: "你好，我要一杯大杯的珍珠奶茶。",
          Tooltip: [],
          English: "Hello, I want a large bubble tea.",
          Pinyin: "Nǐ hǎo, wǒ yào yī bēi dà bēi de zhēn zhū nǎi chá.",
        },
        {
          服務員: "你要*冰的**還是*要*熱的*?",
          服务员S: "你要*冰的**还是*要*热的*?",
          Tooltip: [
            "bīng de, cold (drink)",
            "hái shì, or (here used in a choice-type question)",
            "rè de, hot (drink)",
          ],
          English: "You want it cold or hot?.",
          Pinyin: "Nǐ yào bīng de hái shì yào rè de",
        },
        {
          客人: "我要冰的。",
          客人S: "我要冰的。",
          Tooltip: [],
          English: "I want a cold.",
          Pinyin: "Wǒ yào bīng de.",
        },
        {
          服務員: "這是你的大杯珍奶。",
          服务员S: "这是你的大杯珍奶。",
          Tooltip: [],
          English: "This is your large bubble tea.",
          Pinyin: "Zhè shì nǐ de dà bēi zhēn nǎi.",
        },
        {
          客人: "謝謝，請問多少錢？",
          客人S: "谢谢，请问多少钱？",
          Tooltip: [],
          English: "Thank you. How much?",
          Pinyin: "Xièxiè, qǐng wèn duō shǎo qián?",
        },
        {
          服務員: "三塊七毛五。",
          服务员S: "三块七毛五。",
          Tooltip: [],
          English: "$3.75",
          Pinyin: "Sān kuài qī máo wǔ.",
        },
        {
          客人: "你們*收**信用卡*嗎？",
          客人S: "你们*收**信用卡*吗？",
          Tooltip: [
            "shōu, accept",
            "xìn yòng kǎ, ‘trust-use-card’ credit card",
          ],
          English: "Do you take credit card?",
          Pinyin: "Nǐ men shōu xìn yòng kǎ ma?",
        },
        {
          服務員: "我們*只*收*現金*。",
          服务员S: "我们*只*收*現金*。",
          Tooltip: ["zhǐ, only", "xiàn jīn, ‘current-money’ cash"],
          English: "We only accept cash.",
          Pinyin: "Wǒ men zhǐ shōu xiàn jīn.",
        },
        {
          客人: "這裡是五塊。",
          客人S: " 这里是五块。",
          Tooltip: [],
          English: "Here is $5.",
          Pinyin: "Zhèlǐ shì wǔ kuài.",
        },
        {
          服務員:
            "好，謝謝，這是*找**給*你的*零錢*，*然後**吸管*在那邊，*還有**紙巾*。",
          服务员S:
            "好，谢谢，这是*找**给*你的*零钱*，*然后**吸管*在那边，*还有**紙巾*。",
          Tooltip: [
            "zhǎo qián, ‘look for-money’ to give change",
            "gěi, to give something to someone",
            "líng qián, ‘odd-money’ change (singles and coins)",
            "rán hòu, then (said when adding additional information)",
            "xī guǎn, ‘inhale-tube’ straws",
            "hái yǒu, and",
            "zhǐ jīn, ‘paper-towel’ napkins",
          ],
          English:
            "OK. Thank you. Here is your change. Straws and napkins are over there.",
          Pinyin:
            "Hǎo, xiè xiè, zhè shì zhǎo gěi nǐ de líng qián, rán hòu xī guǎn zài nà biān, hái yǒu zhǐ jīn.",
        },
        {
          客人: "好, 謝謝。",
          客人S: "好, 谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "Hǎo xiè xie",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/tea_v1/s1.mp3",
          basePath + "/assets/sound/tea_v1/s2.mp3",
          basePath + "/assets/sound/tea_v1/s3.mp3",
          basePath + "/assets/sound/tea_v1/s4.mp3",
          basePath + "/assets/sound/tea_v1/s5.mp3",
          basePath + "/assets/sound/tea_v1/s6.mp3",
          basePath + "/assets/sound/tea_v1/s7.mp3",
          basePath + "/assets/sound/tea_v1/s8.mp3",
          basePath + "/assets/sound/tea_v1/s9.mp3",
          basePath + "/assets/sound/tea_v1/s10.mp3",
          basePath + "/assets/sound/tea_v1/s11.mp3",
          basePath + "/assets/sound/tea_v1/s12.mp3",
          basePath + "/assets/sound/tea_v1/s13.mp3",
          basePath + "/assets/sound/tea_v1/s14.mp3",
        ],
      },
    },
  },
  3: {
    0: {
      q: "Recreate the customer's order below by selecting the appropriate options for the drink.  珍珠奶茶",
      type: "multiple",
      choices: [
        {
          text: "without ice",
          isCorrect: true,
        },
        {
          text: "with ice",
        },
        {
          text: "big",
          isCorrect: true,
        },
        {
          text: "small",
        },
        {
          text: "hot",
        },
        {
          text: "cold",
          isCorrect: true,
        },
      ],
    },
    1: {
      q: "Recreate the customer's order below by selecting the appropriate options for the drink.  鴛鴦奶茶",
      type: "multiple",
      choices: [
        {
          text: "without ice",
        },
        {
          text: "with ice",
        },
        {
          text: "big",
        },
        {
          text: "small",
          isCorrect: true,
        },
        {
          text: "hot",
          isCorrect: true,
        },
        {
          text: "cold",
        },
      ],
    },
    2: {
      q: "Listen to the audio and choose the correct phrase.",
      type: "Audio MultipleChoice",
      choices: [
        {
          text: "请问要喝点什么？",
          audioSrc:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_6-1.mp3",
        },
        {
          text: "你要大杯还是小杯？",
          audioSrc:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_6-2.mp3",
        },
        {
          text: "一共是",
          audioSrc:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_6-3.mp3",
        },
        {
          text: "请问你要袋子吗？",
          audioSrc:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_6-4.mp3",
        },
      ],
    },
    3: {
      q: "Bubble Tea - 2",
      type: "literacy",
      audio: basePath + "/assets/sound/tea_v2/paragraph.mp3",
      choices: [
        {
          服務員: "*小姐*，你好，請問要喝點什麼？",
          服务员S: "*小姐*，你好，请问要喝点什么？",
          Tooltip: ["xiǎo jiě, Miss"],
          English: "Hello, miss. What do you like to drink?",
          Pinyin: "Xiǎo jiě, nǐ hǎo, qǐng wèn yào hē diǎn shén me?",
        },
        {
          客人: "我要一杯冰的珍珠奶茶，一個熱的*鴛鴦奶茶*。",
          客人S: "我要一杯冰的珍珠奶茶，一个热的*鸳鸯奶茶*。",
          Tooltip: [
            "yuān yāng nǎi chá, ‘Mandarin duck-milk-tea’ a tea and coffee mixed drink",
          ],
          English: "I want a cold bubble tea and a hot yuan-yang milk tea.",
          Pinyin:
            "Wǒ yào yībēi bīng de zhēn zhū nǎi chá, yīgè rè de yuān yāng nǎi chá.",
        },
        {
          服務員: "請問冰的珍奶你要大杯還是要小杯。",
          服务员S: "请问冰的珍奶你要大杯还是要小杯。",
          Tooltip: [],
          English:
            "For the cold bubble tea, would you like a large or a small cup?",
          Pinyin:
            "Qǐng wèn bīng de zhēn nǎi nǐ yào dà bēi hái shì yào xiǎo bēi.",
        },
        {
          客人: "冰的珍奶大杯，*然後*熱的鴛鴦小杯。然後，冰的珍奶不要加冰塊。",
          客人S: "冰的珍奶大杯，*然后*热的鸳鸯小杯。然后，冰的珍奶不要加冰块。",
          Tooltip: ["rán hòu, and (said when adding additional information)"],
          English:
            "The cold will be large, hot will be small. Also, do not put ice in my cold bubble tea.",
          Pinyin:
            "Bīng de zhēn nǎi dà bēi, rán hòu rè de yuān yāng xiǎo bēi. Rán hòu, bīng de zhēn nǎi bùyào jiā bīng kuài.",
        },
        {
          服務員: "好。冰的，沒有*加**冰塊*的珍奶。然後這是鴛鴦。",
          服务员S: "好。冰的，沒有*加**冰块*的珍奶。然后这是鸳鸯。",
          Tooltip: ["jiā, to add", "bīng kuài, ‘ice-lump’ ice cubes"],
          English:
            "Sure. Here is the cold bubble tea with no ice. Here is the yuan-yang tea.",
          Pinyin:
            "Hǎo. Bīng de, méi yǒu jiā bīng kuài de zhēn nǎi. Rán hòu zhè shì yuān yāng.",
        },
        {
          客人: "*總共*多少錢？",
          客人S: "*总共*多少钱？",
          Tooltip: ["zǒng gòng, altogether"],
          English: "How much is it together?",
          Pinyin: "Zǒng gòng duō shǎo qián?",
        },
        {
          服務員:
            "*一共*是六塊五毛。謝謝，*收*七塊錢。*找*您的*零錢*。請問你要*袋子**拿*嗎？",
          服务员S:
            "*一共*是六块五毛。谢谢，*收*七块钱。*找*您的*零钱*。请问你要*袋子**拿*吗？",
          Tooltip: [
            "yí gòng, total",
            "shōu, to receive",
            "zhǎo qián, to give change",
            "líng qián, change",
            "dài zi, bag",
            "ná, hold",
          ],
          English:
            "That will be $6.50. Thank you. I am receiving $7.00 from you. Here is your change. Would you like a bag?",
          Pinyin:
            "Yī gòng shì liù kuài wǔ máo. Xiè xiè, shōu qī kuài qián. Zhǎo nín de líng qián. Qǐng wèn nǐ yào dài zi ná ma?",
        },
        {
          客人: "*不用了*，謝謝。",
          客人S: "*不用了*，谢谢。",
          Tooltip: ["bú yòng le, do not need"],
          English: "No, thank you.",
          Pinyin: "Bù yòng le, xiè xiè.",
        },
        {
          服務員: "*吸管*在*紙巾*都在那邊。謝謝，*歡迎**再來*，謝謝。",
          服务员S: "*吸管*在*紙巾*都在那边。谢谢，*欢迎**再來*，谢谢。",
          Tooltip: [
            "xī guǎn, ‘inhale-tube’ straws",
            "zhǐ jīn, ‘paper-towel’ napkins",
            "huān yíng, welcome",
            "zài lái, come again (in the future)",
          ],
          English:
            "Straws and napkins are over there. Thank you. Come again. Thank you.",
          Pinyin:
            "Xī guǎn zài zhǐ jīn dōu zài nà biān. Xiè xiè, huān yíng zài lái, xiè xiè.",
        },
        {
          客人: "好，謝謝。",
          客人S: "好，谢谢。",
          Tooltip: [],
          English: "OK, thanks.",
          Pinyin: "Hǎo, xiè xiè.",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/tea_v2/s1.mp3",
          basePath + "/assets/sound/tea_v2/s2.mp3",
          basePath + "/assets/sound/tea_v2/s3.mp3",
          basePath + "/assets/sound/tea_v2/s4.mp3",
          basePath + "/assets/sound/tea_v2/s5.mp3",
          basePath + "/assets/sound/tea_v2/s6.mp3",
          basePath + "/assets/sound/tea_v2/s7.mp3",
          basePath + "/assets/sound/tea_v2/s8.mp3",
          basePath + "/assets/sound/tea_v2/s9.mp3",
          basePath + "/assets/sound/tea_v2/s10.mp3",
          basePath + "/assets/sound/tea_v2/s11.mp3",
          basePath + "/assets/sound/tea_v2/s12.mp3",
          basePath + "/assets/sound/tea_v2/s13.mp3",
          basePath + "/assets/sound/tea_v2/s14.mp3",
          basePath + "/assets/sound/tea_v2/s15.mp3",
          basePath + "/assets/sound/tea_v2/s16.mp3",
          basePath + "/assets/sound/tea_v2/s17.mp3",
        ],
      },
    },
    4: {
      q: "Bubble Tea - Key Words",
      type: "keywords",
      choices: [
        {
          english: "Price list",
          traditional: "價目表",
          pinyin: "jià mù biǎo",
        },
        {
          english: "Milk tea category",
          traditional: "奶茶類",
          pinyin: "nǎi chá lèi",
        },
        {
          english: "Smoothie",
          traditional: "冰沙",
          pinyin: "bīng shā",
        },
        {
          english: "Frothy black tea",
          traditional: "泡沫紅茶",
          pinyin: "pào mò hóng chá",
        },
        {
          english: "Slushy and milk shake",
          traditional: "雪泡奶昔",
          pinyin: "xuě pào nǎi xí",
        },
        {
          english: "Soda",
          traditional: "汽水",
          pinyin: "qì shuǐ",
        },
        {
          english: "Juice",
          traditional: "果汁",
          pinyin: "guǒ zhī",
        },
        {
          english: "Drinks",
          traditional: "飲料",
          pinyin: "yǐn liào",
        },
      ],
    },
    5: {
      q: "Match each word with its meaning.",
      type: "line matching",
      choices: [
        {
          english: "Price list",
          simplified: "价目表",
          traditional: "價目表",
        },
        {
          english: "Milk tea category",
          simplified: "奶茶类",
          traditional: "奶茶類",
        },
        {
          english: "Smoothie",
          simplified: "冰沙",
          traditional: "冰沙",
        },
        {
          english: "Frothy black tea",
          simplified: "泡沫红茶",
          traditional: "泡沫紅茶",
        },
        {
          english: "Slushy and milk shake",
          simplified: "雪泡奶昔",
          traditional: "雪泡奶昔",
        },
        {
          english: "Soda",
          simplified: "汽水",
          traditional: "汽水",
        },
        {
          english: "Juice",
          simplified: "果汁",
          traditional: "果汁",
        },
        {
          english: "Drinks",
          simplified: "饮料",
          traditional: "飲料",
        },
      ],
    },
    6: {
      q: "Match each word with its pinyin.",
      type: "line matching",
      choices: [
        {
          english: "jià mù biǎo",
          simplified: "价目表",
          traditional: "價目表",
        },
        {
          english: "nǎi chá lèi",
          simplified: "奶茶类",
          traditional: "奶茶類",
        },
        {
          english: "bīng shā",
          simplified: "冰沙",
          traditional: "冰沙",
        },
        {
          english: "pào mò hóng chá",
          simplified: "泡沫红茶",
          traditional: "泡沫紅茶",
        },
        {
          english: "xuě pào nǎi xī",
          simplified: "雪泡奶昔",
          traditional: "雪泡奶昔",
        },
        {
          english: "qì shuǐ",
          simplified: "汽水",
          traditional: "汽水",
        },
        {
          english: "guǒ zhī",
          simplified: "果汁",
          traditional: "果汁",
        },
        {
          english: "yǐn liào",
          simplified: "饮料",
          traditional: "飲料",
        },
      ],
    },
    7: {
      q: "Study the character's meaning, pinyin, radical, strokes, and compound word.",
      type: "analysis",
      choices: [
        {
          traditional: "價",
          simplified: "价",
          pinyin: "jia4",
          meaning: "price",
          strokesT: "15",
          strokesS: "6",
          radicalT: "人 ren2, person",
          radicalS: "人 ren2, person",
          wordT: "物價 wu4jia4, commodity price",
          wordS: "物价 wu4jia4, commodity price",
          etymology:
            "The meaning component is 亻rén person. The sound component is 賈 jiǎ.",
          strokeOrderT:
            basePath + "/assets/gifs/trav_3/travel_traditional_6.gif",
          strokeOrderS:
            basePath + "/assets/gifs/trav_3/travel_simplified_6.gif",
          audio: basePath + "/assets/travel_literacy_activity3/jia4.mp3",
          seal:
            basePath + "/assets/images/travel_literacy_activity3/seal_6.png",
        },
        {
          traditional: "目",
          simplified: "目",
          pinyin: "mu4",
          meaning: "eye",
          strokesT: "5",
          strokesS: "5",
          radicalT: "目 mu4, eye",
          radicalS: "目 mu4, eye",
          wordT: "目前 mu4qian2, at present",
          wordS: "目前 mu4qian2, at present",
          etymology: "Pictograph of an eye, turned sideways.",
          strokeOrderT: basePath + "/assets/gifs/bubbletea_2/bubbletea_2.gif",
          strokeOrderS: basePath + "/assets/gifs/bubbletea_2/bubbletea_2.gif",
          audio: basePath + "/assets/travel_literacy_activity3/mu4.mp3",
          seal:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/seal_2.png",
        },
        {
          traditional: "奶",
          simplified: "奶",
          pinyin: "nai3",
          meaning: "milk",
          strokesT: "5",
          strokesS: "5",
          radicalT: "女 nv3, woman",
          radicalS: "女 nv3, woman",
          wordT: "牛奶 niu2nai3, cow's milk",
          wordS: "牛奶 niu2nai3, cow's milk",
          etymology:
            "The meaning component is 女 nǚ woman. The sound component is 乃 nǎi.",
          strokeOrderT: basePath + "/assets/gifs/bubbletea_2/bubbletea_3.gif",
          strokeOrderS: basePath + "/assets/gifs/bubbletea_2/bubbletea_3.gif",
          audio: basePath + "/assets/travel_literacy_activity3/nai3.mp3",
          seal:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/seal_3.png",
        },
        {
          traditional: "冰",
          simplified: "冰",
          pinyin: "bing1",
          meaning: "ice",
          strokesT: "6",
          strokesS: "6",
          radicalT: "冫bing1, ice",
          radicalS: "冫bing1, ice",
          wordT:
            "冰天雪地 bing1tian1xue3di4, a world of ice and snow as far as the eye can see.",
          wordS:
            "冰天雪地 bing1tian1xue3di4, a world of ice and snow as far as the eye can see.",
          etymology:
            "The original form of 冰 is 冫bīng. The radical component is 水 shuǐ water. The component is also 冫bīng.",
          strokeOrderT: basePath + "/assets/gifs/bubbletea_2/bubbletea_4.gif",
          strokeOrderS: basePath + "/assets/gifs/bubbletea_2/bubbletea_4.gif",
          audio: basePath + "/assets/travel_literacy_activity3/bing1.mp3",
          seal:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/seal_4.png",
        },
        {
          traditional: "沙",
          simplified: "沙",
          pinyin: "sha1",
          meaning: "sand",
          strokesT: "7",
          strokesS: "7",
          radicalT: "水 shui3, water",
          radicalS: "水 shui3, water",
          wordT: "沙子 sha1zi, sand",
          wordS: "沙子 sha1zi, sand",
          etymology:
            "The meaning of sand is represented by 水 shuǐ water and 少 shǎo less.",
          strokeOrderT: basePath + "/assets/gifs/bubbletea_2/bubbletea_5.gif",
          strokeOrderS: basePath + "/assets/gifs/bubbletea_2/bubbletea_5.gif",
          audio: basePath + "/assets/travel_literacy_activity3/sha1.mp3",
          seal:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/seal_5.png",
        },
        {
          traditional: "雪",
          simplified: "雪",
          pinyin: "xue3",
          meaning: "snow",
          strokesT: "11",
          strokesS: "11",
          radicalT: "雨 yu3, rain",
          radicalS: "雨 yu3, rain",
          wordT: "雪亮 xue3liang4, shining",
          wordS: "雪亮 xue3liang4, shining",
          etymology:
            "The meaning component is 雨 yǔ rain. The sound component is 彗 huì.",
          strokeOrderT: basePath + "/assets/gifs/bubbletea_2/bubbletea_6.gif",
          strokeOrderS: basePath + "/assets/gifs/bubbletea_2/bubbletea_6.gif",
          audio: basePath + "/assets/travel_literacy_activity3/xue3.mp3",
          seal:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/seal_6.png",
        },
        {
          traditional: "汽",
          simplified: "汽",
          pinyin: "qi4",
          meaning: "steam; bubble",
          strokesT: "7",
          strokesS: "7",
          radicalT: "水 shui3, water",
          radicalS: "水 shui3, water",
          wordT: "汽水 qi4shui3, soda",
          wordS: "汽水 qi4shui3, soda",
          etymology:
            "The original meaning of 汽 is water evaporates. The meaning component is 水 shuǐ water. The sound component is 气 qì.",
          strokeOrderT: basePath + "/assets/gifs/bubbletea_2/bubbletea_7.gif",
          strokeOrderS: basePath + "/assets/gifs/bubbletea_2/bubbletea_7.gif",
          audio: basePath + "/assets/travel_literacy_activity3/qi4.mp3",
          seal:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/seal_7.png",
        },
        {
          traditional: "水",
          simplified: "水",
          pinyin: "shui3",
          meaning: "water",
          strokesT: "4",
          strokesS: "4",
          radicalT: "水 shui3, water",
          radicalS: "水 shui3, water",
          wordT: "水手 shui3shou3, sailor",
          wordS: "水手 shui3shou3, sailor",
          etymology: "Pictograph of water currents in a river or stream.",
          strokeOrderT: basePath + "/assets/gifs/bubbletea_2/bubbletea_8.gif",
          strokeOrderS: basePath + "/assets/gifs/bubbletea_2/bubbletea_8.gif",
          audio: basePath + "/assets/travel_literacy_activity3/shui3.mp3",
          seal:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/seal_8.png",
        },
        {
          traditional: "果",
          simplified: "果",
          pinyin: "guo3",
          meaning: "fruit; result",
          strokesT: "8",
          strokesS: "8",
          radicalT: "木 mu4, wood",
          radicalS: "木 mu4, wood",
          wordT: "成果 cheng2guo3, achievements",
          wordS: "成果 cheng2guo3, achievements",
          etymology: "A pictograph of fruit in the branches of a tree.",
          strokeOrderT: basePath + "/assets/gifs/bubbletea_2/bubbletea_9.gif",
          strokeOrderS: basePath + "/assets/gifs/bubbletea_2/bubbletea_9.gif",
          audio: basePath + "/assets/travel_literacy_activity3/guo3.mp3",
          seal:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/seal_9.png",
        },
        {
          traditional: "飲",
          simplified: "饮",
          pinyin: "yin3",
          meaning: "to drink",
          strokesT: "12",
          strokesS: "7",
          radicalT: "食 shi2, food",
          radicalS: "食 shi2, food",
          wordT: "飲酒 yin3jiu3, to drink liquor",
          wordS: "饮酒 yin3jiu3, to drink liquor",
          etymology:
            "The original meaning of 飲 is to drink wine. The meaning is represented by 酉 yǒu (altered to 食 shí food in the modern form) and 欠 qiàn breathe (swallow).",
          strokeOrderT:
            basePath + "/assets/gifs/bubbletea_2/bubbletea_10_traditional.gif",
          strokeOrderS:
            basePath + "/assets/gifs/bubbletea_2/bubbletea_10_simplified.gif",
          audio: basePath + "/assets/travel_literacy_activity3/yin3.mp3",
          seal:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/seal_10.png",
        },
        {
          traditional: "料",
          simplified: "料",
          pinyin: "liao4",
          meaning: "material",
          strokesT: "10",
          strokesS: "10",
          radicalT: "斗 dou3, ten Chinese liters",
          radicalS: "斗 dou3, ten Chinese liters",
          wordT: "原料 yuan2liao4, raw materials",
          wordS: "原料 yuan2liao4, raw materials",
          etymology:
            "The meaning of material is represented by 米 mǐ rice and 斗 dòu grain measuring cup.",
          strokeOrderT: basePath + "/assets/gifs/bubbletea_2/bubbletea_11.gif",
          strokeOrderS: basePath + "/assets/gifs/bubbletea_2/bubbletea_11.gif",
          audio: basePath + "/assets/travel_literacy_activity3/liao4.mp3",
          seal:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/seal_11.png",
        },
      ],
    },
    8: {
      q: "Fill in the table by dragging and dropping the answers below into the corresponding boxes.",
      type: "analysis-learning",
      choices: [
        {
          traditional: "冰",
          simplified: "冰",
          pinyin: "bing1",
          meaning: "ice",
          strokesT: "6",
          strokesS: "6",
          radicalT: "冫bing1, ice",
          radicalS: "冫bing1, ice",
        },
        {
          traditional: "沙",
          simplified: "沙",
          pinyin: "sha1",
          meaning: "sand",
          strokesT: "7",
          strokesS: "7",
          radicalT: "水 shui3, water",
          radicalS: "水 shui3, water",
        },
        {
          traditional: "果",
          simplified: "果",
          pinyin: "guo3",
          meaning: "fruit; result",
          strokesT: "8",
          strokesS: "8",
          radicalT: "木 mu4, wood",
          radicalS: "木 mu4, wood",
        },
        {
          traditional: "目",
          simplified: "目",
          pinyin: "mu4",
          meaning: "eye",
          strokesT: "5",
          strokesS: "5",
          radicalT: "目 mu4, eye",
          radicalS: "目 mu4, eye",
        },
        {
          traditional: "雪",
          simplified: "雪",
          pinyin: "xue3",
          meaning: "snow",
          strokesT: "11",
          strokesS: "11",
          radicalT: "雨 yu3, rain",
          radicalS: "雨 yu3, rain",
        },
      ],
    },
    9: {
      q: "Combine components to form characters by dragging the component to the corresponding box.",
      type: "rearrange chinese",
      choices: [
        {
          pinyin: ["jià", ""],
          simplified: ["亻", "賈"],
          traditional: ["亻", "贾"],
        },
        {
          pinyin: ["nǎi", ""],
          simplified: ["女", "乃"],
          traditional: ["女", "乃"],
        },
        {
          pinyin: ["xuě", ""],
          simplified: ["雨", "彐"],
          traditional: ["雨", "彐"],
        },
        {
          pinyin: ["yǐn ", ""],
          simplified: ["食", "欠"],
          traditional: ["食", "欠"],
        },
        {
          pinyin: ["liào", ""],
          simplified: ["米", "斗"],
          traditional: ["米", "斗"],
        },
      ],
      taskSpecificParams: {
        layout: "mobile",
      },
    },
    10: {
      q: "Combine two characters into meaningful compound words",
      type: "rearrange chinese",
      choices: [
        {
          pinyin: ["jià", "mù"],
          simplified: ["价", "目"],
          traditional: ["價", "目"],
        },
        {
          pinyin: ["nǎi", "chá"],
          simplified: ["奶", "茶"],
          traditional: ["奶", "茶"],
        },
        {
          pinyin: ["bīng", "shā"],
          simplified: ["冰", "沙"],
          traditional: ["冰", "沙"],
        },
        {
          pinyin: ["xuě ", "pào"],
          simplified: ["雪", "泡"],
          traditional: ["雪", "泡"],
        },
        {
          pinyin: ["qì", "shuǐ"],
          simplified: ["汽", "水"],
          traditional: ["汽", "水"],
        },
        {
          pinyin: ["guǒ", "zhī"],
          simplified: ["果", "汁"],
          traditional: ["果", "汁"],
        },
        {
          pinyin: ["yǐn", "liào"],
          simplified: ["饮", "料"],
          traditional: ["飲", "料"],
        },
      ],
      taskSpecificParams: {
        layout: "mobile",
      },
    },
    11: {
      q: "Rearrange the following characters from the word bank into the correct sentence order.",
      type: "rearrange chinese",
      choices: [
        {
          pinyin: ["qǐng", "wèn", "yào", "hē", "diǎn", "shén", "me"],
          simplified: ["请", "问", "要", "喝", "点", "什", "么"],
          traditional: ["請", "問", "要", "喝", "點", "什", "麼"],
        },
        {
          pinyin: [
            "bīng",
            "de",
            "zhēn",
            "nǎi",
            "bú",
            "yào",
            "jiā",
            "bīng",
            "kuài",
          ],
          simplified: ["冰", "的", "珍", "奶", "不", "要", "加", "冰", "块"],
          traditional: ["冰", "的", "珍", "奶", "不", "要", "加", "冰", "塊"],
        },
        {
          pinyin: ["zǒng", "gòng", "duō", "shǎo", "qián"],
          simplified: ["总", "共", "多", "少", "钱"],
          traditional: ["總", "共", "多", "少", "錢"],
        },
        {
          pinyin: ["qǐng", "wèn", "nǐ", "yào", "dài", "zi", "ná", "ma"],
          simplified: ["请", "问", "你", "要", "袋", "子", "拿", "吗"],
          traditional: ["請", "問", "你", "要", "袋", "子", "拿", "嗎"],
        },
        {
          pinyin: ["xī", "guǎn", "zhǐ", "jīn", "dōu", "zài", "nà", "biān"],
          simplified: ["吸", "管", "纸", "巾", "都", "在", "那", "边"],
          traditional: ["吸", "管", "紙", "巾", "都", "在", "那", "邊"],
        },
      ],
    },
    12: {
      q: "Look at the following picture of a price list from a drink store. Then, design a poster for the store offering a discount to customers.",
      type: "recording",
      img:
        basePath +
        "/assets/images/food/bubbleTea2_literacy_activity_2/bl_2_7.png",
      choices: [],
    },
  },
  4: {
    0: {
      q: "Which of the following were said in the video?",
      type: "multiple",
      choices: [
        {
          text: "我要一杯大的珍珠奶茶。",
          isCorrect: true,
        },
        {
          text: "我要一杯冰的珍珠奶茶。",
        },
        {
          text: "我要一杯珍珠奶茶。",
        },
        {
          text: "我们分开来付。",
          isCorrect: true,
        },
        {
          text: "总共多少钱？",
        },
      ],
    },
    1: {
      q: "Select all of the following text that matches the audio",
      type: "multipleSound",
      choices: [
        {
          text: "他們倆都要喝珍珠咖啡。",
          audio:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_8-1.mp3",
        },
        {
          text: "他們倆都要喝大杯的奶茶。",
          audio:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_8-4.mp3",
        },
        {
          text: "他們倆都要喝珍珠奶茶。",
          audio:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_8-2.mp3",
        },
        {
          text: "女的想要请客。",
          audio:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_8-5.mp3",
        },
        {
          text: "男的喜歡喝甜的奶茶。",
          audio:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_8-3.mp3",
        },
      ],
      answerKey: [true, false, false, false, false],
    },
    2: {
      q: "Listen to the audio and choose the correct phrase.",
      type: "Audio MultipleChoice",
      choices: [
        {
          text: "你要大的还是小的？",
          audioSrc:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_9-1.mp3",
        },
        {
          text: "这是你的大杯珍奶。",
          audioSrc:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_9-2.mp3",
        },
        {
          text: "请问多少钱？",
          audioSrc:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_9-3.mp3",
        },
        {
          text: "两块七毛五。",
          audioSrc:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_9-4.mp3",
        },
      ],
    },
    3: {
      q: "Arrange the following sentences in the correct order by dragging the sentence",
      type: "arrange-audio-clips",
      choices: [
        {
          id: 0,
          text: "我要一杯咖啡。",
          textS: "我要一杯咖啡。",
        },
        {
          id: 1,
          text: "要冰的還是熱的？",
          textS: "要冰的还是热的？",
        },
        {
          id: 2,
          text: "我要冰的。",
          textS: "我要冰的。",
        },
        {
          id: 3,
          text: "這是您的咖啡。",
          textS: "这是您的咖啡。",
        },
        {
          id: 4,
          text: "謝謝，請問多少錢？",
          textS: "谢谢，请问多少钱？",
        },
        {
          id: 5,
          text: "两塊。",
          textS: "两块。",
        },
        {
          id: 6,
          text: "謝謝。",
          textS: "谢谢。",
        },
      ],
      answerKey: [0, 1, 2, 3, 4, 5, 6],
    },
    4: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question:
            "Listen to the question by clicking on the speaker icon, then click on the most appropriate answer.",
          mainAudio:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_11-1.mp3",
          subChoices: [
            {
              text: "冰咖啡。",
              isCorrect: true,
            },
            {
              text: "不要加糖。",
            },
          ],
        },
        {
          question:
            "Listen to the question by clicking on the speaker icon, then click on the most appropriate answer.",
          mainAudio:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_11-2.mp3",
          subChoices: [
            {
              text: "冰的，謝謝。",
              isCorrect: true,
            },
            {
              text: "一碗麵。",
            },
          ],
        },
        {
          question:
            "Listen to the question by clicking on the speaker icon, then click on the most appropriate answer.",
          mainAudio:
            basePath + "/assets/audio/module2/bubbletea/m2_bubbletea_11-3.mp3",
          subChoices: [
            {
              text: "两塊七毛五。",
            },
            {
              text: "請問多少錢？",
              isCorrect: true,
            },
          ],
        },
      ],
    },
    5: {
      q: "Arrange the following sentences in the correct order by dragging the sentence.",
      type: "arrange-audio-clips",
      choices: [
        {
          id: 0,
          text: "我要一杯紅茶。",
          textS: "我要一杯红茶。",
        },
        {
          id: 1,
          text: "要冰的還是熱的？",
          textS: "要冰的还是热的？",
        },
        {
          id: 2,
          text: "我要冰的。",
          textS: "我要冰的。",
        },
        {
          id: 3,
          text: "這是您的紅茶。",
          textS: "这是您的红茶。",
        },
        {
          id: 4,
          text: "謝謝請問多少錢？",
          textS: "谢谢请问多少钱？",
        },
        {
          id: 5,
          text: "一塊錢。",
          textS: "一块钱。",
        },
        {
          id: 6,
          text: "謝謝。",
          textS: "谢谢。",
        },
      ],
      answerKey: [0, 1, 2, 3, 4, 5, 6],
    },
    6: {
      q: "Please answer the questions that the assistant asks",
      img:
        basePath + "/assets/images/food/bubbleTea2_literacy_activity_2/6_c.png",
      type: "ordering",
      choices: [
        {
          text: "请问要喝点什么？",
          subChoices: ["珍珠奶茶", "鴛鴦奶茶"],
        },
        {
          text: "请问你要大杯还是小杯?",
          subChoices: ["大杯", "小杯"],
        },
        {
          text: "请问你要冰的还是热的?",
          subChoices: ["冰的", "热的"],
        },
        {
          text: "你点了一杯",
          subChoices: [],
        },
      ],
      altChoices: [
        {
          text: "请问要喝点什么？",
          subChoices: ["珍珠奶茶", "鴛鴦奶茶"],
        },
        {
          text: "请问你要大杯还是小杯?",
          subChoices: ["大杯", "小杯"],
        },
        {
          text: "请问你要冰的还是热的?",
          subChoices: ["冰的", "热的"],
        },
        {
          text: "你点了一杯",
          subChoices: [],
        },
      ],
    },
  },
  5: {
    0: {
      q: "Arrange the following sentences in the correct order by dragging the sentences",
      type: "arrange-audio-clips",
      choices: [
        {
          id: 0,
          text: "您好，歡迎光臨，請問要點什麼？",
          textS: "您好，欢迎光临，请问要点什么？",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_1-1.mp3",
        },
        {
          id: 1,
          text: "我要一個芥藍牛，大的，不要加味精。",
          textS: "我要一个芥蓝牛，大的，不要加味精。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_1-2.mp3",
        },
        {
          id: 2,
          text: "在這裡吃還是外帶？",
          textS: "在这里吃还是外带？",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_1-3.mp3",
        },
        {
          id: 3,
          text: "我要外帶。",
          textS: "我要外带。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_1-4.mp3",
        },
        {
          id: 4,
          text: "好，請稍等。",
          textS: "好，请稍等。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_1-5.mp3",
        },
        {
          id: 5,
          text: "這是你的芥藍牛。",
          textS: "这是你的芥蓝牛。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_1-6.mp3",
        },
        {
          id: 6,
          text: "多少錢？",
          textS: "多少钱？",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_1-7.mp3",
        },
        {
          id: 7,
          text: "總共十塊九毛五。",
          textS: "总共十块九毛五。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_1-8.mp3",
        },
        {
          id: 8,
          text: "好，找你的零錢。",
          textS: "好，找你的零钱。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_1-9.mp3",
        },
        {
          id: 9,
          text: "謝謝。",
          textS: "谢谢。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_1-10.mp3",
        },
        {
          id: 10,
          text: "歡迎下次再来。",
          textS: "欢迎下次再来。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_1-11.mp3",
        },
      ],
      answerKey: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    1: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question: "Question 1/3: What does the customer want to eat?",
          subChoices: [
            {
              text: "芥藍牛",
              audio:
                basePath + "/assets/audio/module2/restaurant/beef broccoli.mp3",
              isCorrect: true,
            },
            {
              text: "宫保雞丁",
              audio:
                basePath + "/assets/audio/module2/restaurant/spicy chicken.mp3",
            },
            {
              text: "三鮮炒麵",
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_2-6.mp3",
            },
          ],
        },
        {
          question:
            "Question 2/3: Does the customer have any special requests?",
          subChoices: [
            {
              text: "不要加味精",
              isCorrect: true,
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_2-3.mp3",
            },
            {
              text: "不要辣",
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_2-4.mp3",
            },
            {
              text: "不要放鹽",
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_2-2.mp3",
            },
          ],
        },
        {
          question: "Question 3/3: How much was the food?",
          subChoices: [
            {
              text: "總共十塊九毛五。",
              isCorrect: true,
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_2-5.mp3",
            },
            {
              text: "總共二十五塊。",
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_2-6.mp3",
            },
            {
              text: "總共九塊九毛五。",
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_2-7.mp3",
            },
          ],
        },
      ],
    },
    2: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question:
            "Listen to the following audio and decide what sentence comes next.",
          mainAudio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_3-1.mp3",
          mainAudioSubtext: "您好，歡迎光臨，請問要點什麼？",
          subChoices: [
            {
              text: "芥蓝雞",
              isCorrect: true,
            },
            {
              text: "我不想吃?",
            },
          ],
        },
        {
          question:
            "Listen to the following audio and decide what sentence comes next.",
          mainAudio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_3-2.mp3",
          mainAudioSubtext: "在這裡吃還是外帶？",
          subChoices: [
            {
              text: "外带",
              isCorrect: true,
            },
            {
              text: "谢谢?",
            },
          ],
        },
        {
          question:
            "Listen to the following audio and decide what sentence comes next.",
          mainAudio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_3-3.mp3",
          mainAudioSubtext: "這是你的芥藍雞",
          subChoices: [
            {
              text: "谢谢，多少钱?",
              isCorrect: true,
            },
            {
              text: "真好吃",
            },
          ],
        },
        {
          question:
            "Listen to the following audio and decide what sentence comes next.",
          mainAudio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_3-4.mp3",
          mainAudioSubtext: "十塊九毛五",
          subChoices: [
            {
              text: "谢谢",
              isCorrect: true,
            },
            {
              text: "谢谢，多少钱?",
            },
          ],
        },
      ],
    },
    3: {
      q: "Restaurant",
      type: "literacy",
      audio: basePath + "/assets/sound/res_v1/paragraph.mp3",
      choices: [
        {
          服務員: "您好，*歡迎光臨*，請問你要*點*什麽？",
          服务员S: "您好，*欢迎光临*，请问你要*点*什么？",
          Tooltip: [
            "huān yíng guāng lín, Welcome (to our store)",
            "diǎn, to order food",
          ],
          English: "Hello, welcome. May I ask what you would like to have?",
          Pinyin: "nín hǎo, huān yíng guāng lín, qǐng wèn nǐ yào diǎn shén me",
        },
        {
          學生: "我要一個*芥藍牛*, 大的，不加*味精*，然後要*白飯*和*可樂*。",
          学生S: "我要一个*芥蓝牛*，大的，不加*味精*，然后要*白饭*和*可乐*。",
          Tooltip: [
            "jiè lán niú, beef with broccoli",
            "wèi jīng, MSG",
            "bái fàn, white rice",
            "kě lè, coke",
          ],
          English:
            "I want a broccoli beef, large, no MSG. I also want white rice and a coke.",
          Pinyin:
            "wǒ yào yí ge jiè lán niú, dà de, bù jiā wèi jīng, rán hòu yào bái fàn hé kě lè",
        },
        {
          服務員: "你要*在這裡吃**還是**外帶*？",
          服务员S: "你要*在这里吃**还是**外带*？",
          Tooltip: [
            "zài zhè lǐ chī, Eat here.",
            "hái shì, or (for a choice-type question)",
            "wài dài, To go (ordering food)",
          ],
          English: "For here or to go?",
          Pinyin: "nǐ yào zài zhè lǐ chī hái shì wài dài",
        },
        {
          學生: "我要外帶。",
          学生S: "我要外带。",
          Tooltip: ["rán hòu, and (said when adding additional information)"],
          English: "To go.",
          Pinyin: "wǒ yào wài dài",
        },
        {
          服務員: "好，*請稍等*。",
          服务员S: "好，*请稍等*。",
          Tooltip: ["qǐng shāo děng, Please wait a while."],
          English: "OK. It will be just a few minutes.",
          Pinyin: "hǎo, qǐng shāo děng",
        },
        {
          服務員: "這是你的芥藍牛。",
          服务员S: "这是你的芥蓝牛。",
          Tooltip: ["zǒng gòng, altogether"],
          English: "Here is your broccoli beef.",
          Pinyin: "zhè shì nǐ de jiè lán niú",
        },
        {
          學生: "多少錢?",
          学生S: "多少钱?",
          Tooltip: [
            "yí gòng, total",
            "shōu, to receive",
            "zhǎo qián, to give change",
            "líng qián, change",
            "dài zi, bag",
            "ná, hold",
          ],
          English: "How much?",
          Pinyin: "duō shǎo qián",
        },
        {
          服務員: "*總共*是十塊九毛五。",
          服务员S: "*总共*是十块九毛五。",
          Tooltip: ["zǒng gòng, The total is…"],
          English: "The total is $10.50.",
          Pinyin: "zǒng gòng shì shí kuài jiǔ máo wǔ",
        },
        {
          服務員: "*找*你的*零錢*。",
          服务员S: "*找*你的*零钱*。",
          Tooltip: [
            "zhǎo qián, to give (a customer) change",
            "líng qián, change, coins",
          ],
          English: "Here is your change.",
          Pinyin: "zhǎo nǐ de líng qián",
        },
        {
          學生: "謝謝。",
          学生S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
        {
          服務員: "謝謝，歡迎下次再來。",
          服务员S: "谢谢，欢迎下次再来。",
          Tooltip: [],
          English: "Please come again.",
          Pinyin: "xiè xie, huān yíng xià cì zài lái",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/res_v1/s1.mp3",
          basePath + "/assets/sound/res_v1/s2.mp3",
          basePath + "/assets/sound/res_v1/s3.mp3",
          basePath + "/assets/sound/res_v1/s4.mp3",
          basePath + "/assets/sound/res_v1/s5.mp3",
          basePath + "/assets/sound/res_v1/s6.mp3",
          basePath + "/assets/sound/res_v1/s7.mp3",
          basePath + "/assets/sound/res_v1/s8.mp3",
          basePath + "/assets/sound/res_v1/s9.mp3",
          basePath + "/assets/sound/res_v1/s10.mp3",
          basePath + "/assets/sound/res_v1/s11.mp3",
          basePath + "/assets/sound/res_v1/s12.mp3",
          basePath + "/assets/sound/res_v1/s13.mp3",
        ],
      },
    },
  },
  6: {
    0: {
      q: "Match the image and audio to the proper dish name",
      type: "Audio MultipleChoice",
      choices: [
        {
          audioSrc:
            basePath + "/assets/audio/module2/restaurant/ma po bean curd.mp3",
          subImg:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/MapoTofu.PNG",
          text: "麻婆豆腐",
        },
        {
          audioSrc:
            basePath + "/assets/audio/module2/restaurant/garlic chicken.mp3",
          subImg:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Garlic Chicken.PNG",
          text: "蒜香鸡",
        },
        {
          audioSrc: basePath + "/assets/audio/module2/restaurant/fry rice.mp3",
          subImg:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Eggrice.PNG",
          text: "金银蛋炒饭",
        },
      ],
      taskSpecificParams: {
        imgSizing: "small",
      },
    },
    1: {
      q: "Select the three dishes that were mentioned in the video",
      type: "grid sound",
      choices: [
        {
          id: 0,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Eggrice.PNG",
          audio: basePath + "/assets/audio/module2/restaurant/fry rice.mp3",
        },
        {
          id: 1,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/kung pao chicken.PNG",
          audio:
            basePath + "/assets/audio/module2/restaurant/spicy chicken.mp3",
        },
        {
          id: 2,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Garlic Chicken.PNG",
          audio:
            basePath + "/assets/audio/module2/restaurant/garlic chicken.mp3",
        },
        {
          id: 3,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/walnut shrimp.PNG",
          audio:
            basePath + "/assets/audio/module2/restaurant/shrimp walnuts.mp3",
        },
        {
          id: 4,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Mapo.PNG",
          audio:
            basePath + "/assets/audio/module2/restaurant/ma po bean curd.mp3",
        },
        {
          id: 5,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/bean sprouts.PNG",
          audio: basePath + "/assets/audio/module2/restaurant/bean sprouts.mp3",
        },
      ],
      answerKey: [0, 2, 4],
    },
    2: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question:
            "Answer the following question about the video you just watched.",
          subChoices: [
            {
              text: "香片",
              isCorrect: true,
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_6-2.mp3",
            },
            {
              text: "烏龍茶",
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_6-3.mp3",
            },
            {
              text: "可樂",
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_6-1.mp3",
            },
          ],
        },
        {
          question:
            "Answer the following question about the video you just watched.",
          subChoices: [
            {
              text: "碗",
              isCorrect: true,
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_6-5.mp3",
            },
            {
              text: "盤子",
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_6-6.mp3",
            },
            {
              text: "勺子",
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_6-4.mp3",
            },
          ],
        },
        {
          question:
            "Answer the following question about the video you just watched.",
          subChoices: [
            {
              text: "一个人十八塊",
              isCorrect: true,
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_6-7.mp3",
            },
            {
              text: "一个人二十塊",
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_6-8.mp3",
            },
            {
              text: "一个人十五塊",
              audio:
                basePath +
                "/assets/audio/module2/restaurant/m3_restaurant_6-9.mp3",
            },
          ],
        },
      ],
      taskSpecificParams: {
        subQuestions: [
          "他們想喝什么？",
          "他們需要用什麼吃飯？",
          "他們一共花了多少錢？",
        ],
      },
    },
    3: {
      q: "Choose the dishes or drinks the person ordered.",
      img:
        basePath +
        "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/p1.PNG",
      type: "multipleSound",
      choices: [
        {
          text: "香片",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_6-2.mp3",
        },
        {
          text: "蒜香鸡",
          audio:
            basePath + "/assets/audio/module2/restaurant/garlic chicken.mp3",
        },
        {
          text: "麻婆豆腐",
          audio:
            basePath + "/assets/audio/module2/restaurant/ma po bean curd.mp3",
        },
        {
          text: "冰水",
          audio: basePath + "/assets/audio/module2/restaurant/ice water.mp3",
        },
        {
          text: "金银蛋炒饭",
          audio: basePath + "/assets/audio/module2/restaurant/fry rice.mp3",
        },
        {
          text: "乌龙茶",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_6-3.mp3",
        },
        {
          text: "芥蓝牛",
          audio:
            basePath + "/assets/audio/module2/restaurant/beef broccoli.mp3",
        },
      ],
      answerKey: [true, true, true, false, false, false, false],
    },
    4: {
      q: "Choose the dishes or drinks the person ordered.",
      img:
        basePath +
        "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/p2.PNG",
      type: "multipleSound",
      choices: [
        {
          text: "香片",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_6-2.mp3",
        },
        {
          text: "蒜香鸡",
          audio:
            basePath + "/assets/audio/module2/restaurant/garlic chicken.mp3",
        },
        {
          text: "麻婆豆腐",
          audio:
            basePath + "/assets/audio/module2/restaurant/ma po bean curd.mp3",
        },
        {
          text: "冰水",
          audio: basePath + "/assets/audio/module2/restaurant/ice water.mp3",
        },
        {
          text: "金银蛋炒饭",
          audio: basePath + "/assets/audio/module2/restaurant/fry rice.mp3",
        },
        {
          text: "乌龙茶",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_6-3.mp3",
        },
        {
          text: "芥蓝牛",
          audio:
            basePath + "/assets/audio/module2/restaurant/beef broccoli.mp3",
        },
      ],
      answerKey: [false, false, false, true, true, false, false],
    },
    5: {
      q: "",
      type: "recording",
      img:
        basePath +
        "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/res_2_5.png",
      choices: [],
    },
    6: {
      q: "Restaurant - 2 (Part 1) Seating",
      type: "literacy",
      audio: basePath + "/assets/sound/res_v2_1/paragraph.mp3",
      choices: [
        {
          服務員: "*幾位*？",
          服务员S: "*几位*？",
          Tooltip: ["jǐ wèi, How many?(customers)"],
          English: "How many?",
          Pinyin: "jǐ wèi",
        },
        {
          學生甲: "*两位*。",
          学生甲S: "*兩位*。",
          Tooltip: ["liǎng wèi, two (customers)"],
          English: "Two.",
          Pinyin: "liǎng wèi",
        },
        {
          服務員: "請跟我來。",
          服务员S: "请跟我来。",
          Tooltip: [],
          English: "Please follow me.",
          Pinyin: "qǐng gēn wǒ lái",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/res_v2_1/s1.mp3",
          basePath + "/assets/sound/res_v2_1/s2.mp3",
        ],
      },
    },
    7: {
      q: "Restaurant - 2 (Part 2) get menu and drinks",
      type: "literacy",
      audio: basePath + "/assets/sound/res_v2_2/paragraph.mp3",
      choices: [
        {
          服務員: "這是*菜單*，*請看一下*。",
          服务员S: "这是*菜单*，请看一下。",
          Tooltip: [
            "cài dān, menu",
            "qǐng kàn yí xià, Please take a look at it.",
          ],
          English: "Here's the menu, please take a look.",
          Pinyin: "zhè shì cài dān, qǐng kàn yí xià",
        },
        {
          學生甲: "謝謝。",
          学生甲S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
        {
          學生乙: "謝謝。",
          学生乙S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
        {
          服務員: "不客氣 。",
          服务员S: "不客气 。",
          Tooltip: [],
          English: "You're welcome.",
          Pinyin: "bú kè qì",
        },
        {
          服務員: "請問要*喝點*什麽？我們這邊有*果汁*、*可樂*、*還有**茶*。",
          服务员S: "请问要*喝点*什么？我们这边有*果汁*、*可乐*、*还有**茶*。",
          Tooltip: [
            "hē, to drink",
            "guǒ zhī, juice",
            "kě lè, coke",
            "hái yǒu, also have",
            "chá, tea",
          ],
          English:
            "May I please ask what do you want to drink? We have juice, soda, and tea.",
          Pinyin:
            "qǐng wèn yào hē diǎn shén me? wǒ men zhè biān yǒu guǒ zhī, kě lè, hái yǒu chá",
        },
        {
          學生甲: "請問你們有什麽茶？",
          学生甲S: "请问你们有什么茶？",
          Tooltip: [],
          English: "What kind of tea do you have?",
          Pinyin: "qǐng wèn nǐ men yǒu shén me chá",
        },
        {
          服務員: "我們這邊有*烏龍*茶*跟**香片*。",
          服务员S: "我们这边有*烏龙*茶*跟**香片*。",
          Tooltip: [
            "wū lóng, Woo-long tea",
            "hé, and",
            "xiāng piàn, jasmine tea",
          ],
          English: "We have woo-long and jasmine.",
          Pinyin: "wǒ men zhè biān yǒu wū lóng chá gēn xiāng piàn",
        },
        {
          學生甲: "那我們*來一壺*香片。",
          学生甲S: "那我们*来一壺*香片。",
          Tooltip: ["lái yì hú, give us a pot of"],
          English: "Please give us one pot of jasmine.",
          Pinyin: "nà wǒ men lái yì hú xiāng piàn",
        },
        {
          服務員: "好，沒問題。",
          服务员S: "好，沒问题。",
          Tooltip: [],
          English: "Sure, no problem.",
          Pinyin: "hǎo, méi wèn tí",
        },
        {
          學生乙: "那*給我*一杯*冰水*",
          学生乙S: "那*给我*一杯*冰水*",
          Tooltip: ["gěi wǒ, give me", "bīng shuǐ, ice water"],
          English: "Give me a cup of ice water.",
          Pinyin: "nà gěi wǒ yì bēi bīng shuǐ",
        },
        {
          服務員: "好，*沒問題*，*請稍等*。",
          服务员S: "好，*沒问题*，*请稍等*。",
          Tooltip: [
            "méi wèn tí, no problem",
            "qǐng shāo děng, Please wait for a moment.",
          ],
          English: "Sure, no problem, please wait a moment.",
          Pinyin: "hǎo, méi wèn tí, qǐng shāo děng",
        },
        {
          學生乙: "謝謝。",
          学生乙S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/res_v2_2/s1.mp3",
          basePath + "/assets/sound/res_v2_2/s2.mp3",
          basePath + "/assets/sound/res_v2_2/s3.mp3",
          basePath + "/assets/sound/res_v2_2/s5.mp3",
          basePath + "/assets/sound/res_v2_2/s4.mp3",
          basePath + "/assets/sound/res_v2_2/s6.mp3",
          basePath + "/assets/sound/res_v2_2/s7.mp3",
          basePath + "/assets/sound/res_v2_2/s8.mp3",
          basePath + "/assets/sound/res_v2_2/s9.mp3",
          basePath + "/assets/sound/res_v2_2/s10.mp3",
          basePath + "/assets/sound/res_v2_2/s11.mp3",
          basePath + "/assets/sound/res_v2_2/s12.mp3",
          basePath + "/assets/sound/res_v2_2/s13.mp3",
          basePath + "/assets/sound/res_v2_2/s14.mp3",
          basePath + "/assets/sound/res_v2_2/s15.mp3",
        ],
      },
    },
    8: {
      q: "Restaurant - 2 (Part 3) discuss what to order",
      type: "literacy",
      audio: basePath + "/assets/sound/res_v2_3/paragraph.mp3",
      choices: [
        {
          學生甲: "*這看起*來*好好吃*，*我來**點一道**蒜香雞*，好不好？",
          学生甲S: "*这這看*来*好好吃*，*我來**点一道**蒜香鸡*，好不好？",
          Tooltip: [
            "kàn qǐ lái, it looks",
            "hǎo hǎo chī, very tasty",
            "wǒ lái, let me…",
            "diǎn yí dào, order a dish",
            "suàn xiāng jī, garlic chicken",
          ],
          English:
            "This looks delicious, let me order a garlic chicken, what do you think?",
          Pinyin:
            "zhè kàn qǐ lái hǎo hǎo chī, wǒ lái diǎn yí dào suàn xiāng jī, hǎo bù hǎo",
        },
        {
          學生乙: "嗯，好。",
          学生乙S: "嗯，好。",
          Tooltip: [],
          English: "OK.",
          Pinyin: "ěn, hǎo",
        },
        {
          學生甲: "那*再一道**麻婆豆腐*。",
          学生甲S: "那*再一道**麻婆豆腐*。",
          Tooltip: [
            "zài yí dào, one more (dish)",
            "má pó dòu fǔ, Ma Po bean curd",
          ],
          English: "One more Ma Po bean curd.",
          Pinyin: "nà zài yí dào má pó dòu fǔ",
        },
        {
          學生乙: "嗯，好。那我想再*多點一個**金銀蛋炒飯*。",
          学生乙S: "嗯，好。那我想再*多点一个**金银蛋炒饭*。",
          Tooltip: [
            "duō diǎn yí ge. order one more (dish)",
            "jīn yín dàn chǎo fàn, golden-silver egg fry rice",
          ],
          English: "OK, I want to other a Golden-silver fired rice.",
          Pinyin:
            "ěn, hǎo, nà wǒ xiǎng zài duō diǎn yí ge jīn yín dàn chǎo fàn",
        },
        {
          學生甲: "好，那我們就點這三道菜。",
          学生甲S: "好，那我们就点这三道菜。",
          Tooltip: [],
          English: "OK, let's just order this three dishes.",
          Pinyin: "hǎo, nà wǒ men jiù diǎn zhè sān dào cài",
        },
        {
          學生乙: "嗯，好。",
          学生乙S: "嗯，好。",
          Tooltip: [],
          English: "OK.",
          Pinyin: "ěn, hǎo",
        },
        {
          服務員: "這是您的冰水。",
          服务员S: "这是您的冰水。",
          Tooltip: [],
          English: "Here is your ice water.",
          Pinyin: "zhè shì nín de bīng shuǐ",
        },
        {
          學生乙: "謝謝。",
          学生乙S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
        {
          服務員: "您的茶。",
          服务员S: "您的茶。",
          Tooltip: [],
          English: "Your tea.",
          Pinyin: "nín de chá",
        },
        {
          學生甲: "謝謝。",
          学生甲S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/res_v2_3/s1.mp3",
          basePath + "/assets/sound/res_v2_3/s2.mp3",
          basePath + "/assets/sound/res_v2_3/s3.mp3",
          basePath + "/assets/sound/res_v2_3/s4.mp3",
          basePath + "/assets/sound/res_v2_3/s5.mp3",
          basePath + "/assets/sound/res_v2_3/s6.mp3",
          basePath + "/assets/sound/res_v2_3/s7.mp3",
          basePath + "/assets/sound/res_v2_3/s8.mp3",
          basePath + "/assets/sound/res_v2_3/s9.mp3",
        ],
      },
    },
    9: {
      q: "Restaurant - 2 (Part 4) order food",
      type: "literacy",
      audio: basePath + "/assets/sound/res_v2_4/paragraph.mp3",
      choices: [
        {
          學生甲: "那我們*可以**點菜*了。",
          学生甲S: "那我们*可以**点菜*了。",
          Tooltip: ["kě yǐ, is ready to, can", "diǎn cài, to order food"],
          English: "We can order now.",
          Pinyin: "nà wǒ men kě yǐ diǎn cài le",
        },
        {
          服務員: "好，沒問題。",
          服务员S: " 好，沒问题。",
          Tooltip: [],
          English: "OK, no problem.",
          Pinyin: "hǎo, méi wèn tí",
        },
        {
          學生甲: "我們要一道蒜香雞。",
          学生甲S: "我们要一道蒜香鸡。",
          Tooltip: [],
          English: "We want a garlic chicken.",
          Pinyin: "wǒ men yào yí dào suàn xiāng jī",
        },
        {
          服務員: "蒜香雞",
          服务员S: "蒜香鸡",
          Tooltip: [],
          English: "Garlic chicken.",
          Pinyin: " suàn xiāng jī",
        },
        {
          學生甲: "一個麻婆豆腐。",
          学生甲S: "一個麻婆豆腐。",
          Tooltip: [],
          English: "A Ma Po bean curd.",
          Pinyin: "yí ge má pó dòu fǔ",
        },
        {
          服務員: "婆豆腐。",
          服务员S: "婆豆腐。",
          Tooltip: [],
          English: "Ma Po bean curd.",
          Pinyin: "má pó dòu fǔ",
        },
        {
          學生甲: "然後再來一道金銀蛋炒飯。",
          学生甲S: "然后再來一道金银蛋炒饭。",
          Tooltip: [],
          English: "Then another will be a Golden-silver fried rice.",
          Pinyin: "rán hòu zài yí dào jīn yín dàn chǎo fàn",
        },
        {
          服務員: "金銀蛋炒飯，好。所以您點的是麻婆豆腐、蒜香雞、金銀蛋炒飯。",
          服务员S: "金银蛋炒饭，好。所以您点的是麻婆豆腐、蒜香鸡、金银蛋炒饭。",
          Tooltip: [],
          English:
            "Golden-silver fried rice, OK. So you ordered Ma Po bean curd, garlic chicken, and golden-silver fired rice.",
          Pinyin:
            "jīn yín dàn chǎo fàn, hǎo, suǒ yǐ nín diǎn de shì má pó dòu fǔ, suàn xiāng jī, jīn yín dàn chǎo fàn",
        },
        {
          學生甲: "是。",
          学生甲S: "是。",
          Tooltip: [],
          English: "Yes",
          Pinyin: "shì",
        },
        {
          服務員: "好，*請稍等*，*馬上來*。",
          服务员S: "好，*请稍等*，*马上来*。",
          Tooltip: [
            "qǐng shāo děng, Please wait for a moment.",
            "mǎ shàng lái, (The dishes) will come right away.",
          ],
          English: "OK, please wait a moment, be right up.",
          Pinyin: "hǎo, qǐng shāo děng, mǎ shàng lái",
        },
        {
          學生甲乙: "謝謝。",
          学生甲乙S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
        {
          服務員: "不客氣。",
          服务员S: "不客气。",
          Tooltip: [],
          English: "You're welcome.",
          Pinyin: "bú kè qì",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/res_v2_4/s1.mp3",
          basePath + "/assets/sound/res_v2_4/s2.mp3",
          basePath + "/assets/sound/res_v2_4/s3.mp3",
          basePath + "/assets/sound/res_v2_4/s4.mp3",
        ],
      },
    },
    10: {
      q: "Restaurant - 2 (Part 5) order food",
      type: "literacy",
      audio: basePath + "/assets/sound/res_v2_5/paragraph.mp3",
      choices: [
        {
          服務員: "第一道菜，麻婆豆腐。",
          服务员S: "第一道菜，麻婆豆腐。",
          Tooltip: [],
          English: "First dish, Ma Po bean curd.",
          Pinyin: "dì yī dào cài, má pó dòu fǔ",
        },
        {
          學生甲乙: "謝謝。",
          学生甲乙S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
        {
          服務員: "不客氣。",
          服务员S: "不客气。",
          Tooltip: [],
          English: "You're welcome.",
          Pinyin: "bú kè qì",
        },
        {
          服務員: "*再來*金銀蛋炒飯。",
          服务员S: "*再來*金银蛋炒饭。",
          Tooltip: ["zài lái, secondly, it comes"],
          English: "And golden-silver fired rice.",
          Pinyin: "zài lái jīn yín dàn chǎo fàn",
        },
        {
          學生甲乙: "謝謝。",
          学生甲乙S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
        {
          服務員: "*最後*一道菜，蒜香雞。*請慢用*。",
          服务员S: "*最後*一道菜，蒜香鸡。*请慢用*。",
          Tooltip: [
            "zuì hòu, finally",
            "qǐng màn yòng, Please take your time to eat.",
          ],
          English: "Last dish, garlic chicken, enjoy.",
          Pinyin: "zuì hòu yí dào cài, suàn xiāng jī, qǐng màn yòng",
        },
        {
          學生甲乙: "謝謝。",
          学生甲乙S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/res_v2_5/s1.mp3",
          basePath + "/assets/sound/res_v2_5/s2.mp3",
          basePath + "/assets/sound/res_v2_5/s3.mp3",
        ],
      },
    },
    11: {
      q: "Restaurant - 2 (Part 6) additional request to the waiter",
      type: "literacy",
      audio: basePath + "/assets/sound/res_v2_6/paragraph.mp3",
      choices: [
        {
          學生甲: "那我們*先*來吃炒飯*吧*。",
          学生甲S: "那我们*先*来吃炒饭*吧*。",
          Tooltip: ["xiān, first", "ba, particle for suggestions"],
          English: "Let's eat the fired rice first.",
          Pinyin: "nà wǒ men xiān lái chī chǎo fàn ba",
        },
        {
          學生乙: "沒有*碗*。",
          学生乙S: "沒有*碗*。",
          Tooltip: ["wǎn, rice bowls"],
          English: "No bowls.",
          Pinyin: "méi yǒu wǎn",
        },
        {
          學生甲: "*不好意思*，*可以**再給我們*兩個碗嗎？",
          学生甲S: "*不好意思*，*可以**再給我们*两个碗吗？",
          Tooltip: [
            "bù hǎo yì si, Sorry to trouble you.",
            "ke yǐ ... ma, Could you…?",
            "zài gěi wǒ men, bring us more…",
          ],
          English: "Excuse me, may I have two bowls?",
          Pinyin: "bù hǎo yì si, kě yǐ zài gěi wǒ men liǎng ge wǎn ma",
        },
        {
          服務員: "好，沒問題。",
          服务员S: "好，沒问题。",
          Tooltip: [],
          English: "Sure, no problem",
          Pinyin: "hǎo, méi wèn tí",
        },
        {
          服務員: "您的碗。",
          服务员S: "您的碗。",
          Tooltip: [],
          English: "You bowls.",
          Pinyin: "nín de wǎn",
        },
        {
          學生甲乙: "謝謝。",
          学生甲乙S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
        {
          服務員: "不客氣。",
          服务员S: "不客气。",
          Tooltip: [],
          English: "You're welcome.",
          Pinyin: "bú kè qì",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/res_v2_6/s1.mp3",
          basePath + "/assets/sound/res_v2_6/s2.mp3",
          basePath + "/assets/sound/res_v2_6/s3.mp3",
          basePath + "/assets/sound/res_v2_6/s4.mp3",
          basePath + "/assets/sound/res_v2_6/s5.mp3",
          basePath + "/assets/sound/res_v2_6/s6.mp3",
        ],
      },
    },
    12: {
      q: "Restaurant - 2 (Part 7) getting the check and paying ",
      type: "literacy",
      audio: basePath + "/assets/sound/res_v2_7/paragraph.mp3",
      choices: [
        {
          學生甲: "*吃飽了*嗎？那我們*買單*好不好？",
          学生甲S: "*吃饱了*吗？那我们*买单*好不好？",
          Tooltip: [
            "chī bǎo le, to be full (after eating a meal)",
            "mǎi dān, ask for the bill",
          ],
          English: "Are you full? Then let's pay the bill.",
          Pinyin: "chī bǎo le ma, nà wǒ men mǎi dān hǎo bù hǎo",
        },
        {
          學生乙: "好。",
          学生乙S: "好。",
          Tooltip: [],
          English: "OK.",
          Pinyin: "hǎo",
        },
        {
          學生甲: "不好意思，*我們可以買單了*。",
          学生甲S: "不好意思，*我们可以买单了*。",
          Tooltip: ["wǒ men kě yǐ mǎi dān le, We are ready to pay"],
          English: "Excuse me, we are ready to pay.",
          Pinyin: "bù hǎo yì si, wǒ men kě yǐ mǎi dān le",
        },
        {
          服務員: "這是你們的*帳單*。",
          服务员S: "这是你们的*账单*。",
          Tooltip: ["zhàng dān, bill"],
          English: "Here is your bill.",
          Pinyin: "zhè shì nǐ men de zhàng dān",
        },
        {
          學生甲: "謝謝。",
          学生甲S: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xiè xie",
        },
        {
          服務員: "不客氣 。",
          服务员S: "不客气 。",
          Tooltip: [],
          English: "You're welcome.",
          Pinyin: "bú kè qì",
        },
        {
          學生甲:
            "*這樣**算起來*，我們再*加上**一成五*的*小費*，*我們一個人*十八塊。",
          学生甲S:
            "*这样**算起来*，我们再*加上**一成五*的*小费*，*我们一个人*十八块。",
          Tooltip: [
            "zhè yàng, this way",
            "suàn qǐ lái, calculate",
            "jiā shàng, to add",
            "yī chéng wǔ, 15 percent",
            "xiǎo fèi, tip",
            "wǒ men yí ge rén, each one of us",
          ],
          English:
            "If we calculate this way, and add 15% tips, 18 dollars per-person.",
          Pinyin:
            "zhè yàng suàn qǐ lái, wǒ men zài jiā shàng yī chéng wǔ de xiǎo fèi, wǒ men yí ge rén shí bā kuài qián",
        },
        {
          學生乙: "嗯，好。這是我的",
          学生乙S: "嗯，好。这是我的",
          Tooltip: [],
          English: "Ok. This is my share",
          Pinyin: "ěn, hǎo. zhè shì wǒ de",
        },
        {
          學生甲: "*走吧*",
          学生甲S: "*走吧*",
          Tooltip: ["zǒu ba, Let's go."],
          English: "Lets go",
          Pinyin: "zǒu ba",
        },
        {
          服務員: "謝謝，*歡迎**下次**再來*。",
          服务员S: "谢谢，*欢迎**下次**再来*。",
          Tooltip: [
            "huān yíng, welcome",
            "xià cì, next time",
            "zài lái, come again",
          ],
          English: "Thank you. Please come again.",
          Pinyin: "xiè xiè, huān yíng xià cì zài lái",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/res_v2_7/s1.mp3",
          basePath + "/assets/sound/res_v2_7/s2.mp3",
          basePath + "/assets/sound/res_v2_7/s3.mp3",
          basePath + "/assets/sound/res_v2_7/s4.mp3",
          basePath + "/assets/sound/res_v2_7/s5.mp3",
          basePath + "/assets/sound/res_v2_7/s6.mp3",
          basePath + "/assets/sound/res_v2_7/s7.mp3",
          basePath + "/assets/sound/res_v2_7/s8.mp3",
          basePath + "/assets/sound/res_v2_7/s9.mp3",
          basePath + "/assets/sound/res_v2_7/s10.mp3",
          basePath + "/assets/sound/res_v2_7/s11.mp3",
          basePath + "/assets/sound/res_v2_7/s12.mp3",
          basePath + "/assets/sound/res_v2_7/s13.mp3",
          basePath + "/assets/sound/res_v2_7/s14.mp3",
        ],
      },
    },
    13: {
      q: "Study the character and pronunciation for the following dishes",
      type: "grid sound",
      choices: [
        {
          img: basePath + "/assets/sound/res_act1/img/s1.png",
          audio: basePath + "/assets/sound/res_act1/s1.mp3",
          text: "小籠包",
          pinyin: "xiǎo lóng bāo",
          english: "steamed juicy",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s2.png",
          audio: basePath + "/assets/sound/res_act1/s2.mp3",
          text: "蒸餃",
          pinyin: "zhēng jiǎo",
          english: "steamed dumplings",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s3.png",
          audio: basePath + "/assets/sound/res_act1/s3.mp3",
          text: "鍋貼",
          pinyin: "guō tiē",
          english: "fried dumplings",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s4.png",
          audio: basePath + "/assets/sound/res_act1/s4.mp3",
          text: "水餃",
          pinyin: "shuǐ jiǎo",
          english: "boiled dumplings",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s5.png",
          audio: basePath + "/assets/sound/res_act1/s5.mp3",
          text: "春捲",
          pinyin: "chūn juǎn",
          english: "spring rolls",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s6.png",
          audio: basePath + "/assets/sound/res_act1/s6.mp3",
          text: "蔥油餅",
          pinyin: "cōng yóu bǐng",
          english: "scallion pancakes",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s7.png",
          audio: basePath + "/assets/sound/res_act1/s7.mp3",
          text: "牛肉麵",
          pinyin: "niú ròu miàn",
          english: "beef noodles",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s8.png",
          audio: basePath + "/assets/sound/res_act1/s8.mp3",
          text: "炸醬麵",
          pinyin: "zhá jiàng miàn",
          english: "noodles with brown sauce",
        },
      ],
      taskSpecificParams: {
        typeLearning: true,
      },
    },
    14: {
      q: "Study the character and pronunciation for the following dishes",
      type: "grid sound",
      choices: [
        {
          img: basePath + "/assets/sound/res_act1/img/s9.png",
          audio: basePath + "/assets/sound/res_act1/s9.mp3",
          text: "酸辣湯",
          pinyin: "suān là tāng",
          english: "sour and spicy soup",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s10.png",
          audio: basePath + "/assets/sound/res_act1/s10.mp3",
          text: "糖醋排骨",
          pinyin: "táng cù pái gǔ",
          english: "sweat and sour ribs",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s11.png",
          audio: basePath + "/assets/sound/res_act1/s11.mp3",
          text: "紅燒獅子頭",
          pinyin: "hóng shāo shī zi tóu",
          english: "braised large meat balls",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s12.png",
          audio: basePath + "/assets/sound/res_act1/s12.mp3",
          text: "宮保雞丁",
          pinyin: "gōng bǎo jī dīng",
          english: "Kung Pao chicken",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s13.png",
          audio: basePath + "/assets/sound/res_act1/s13.mp3",
          text: "魚香茄子",
          pinyin: "yú xiāng qié zi",
          english: "egg plant in garlic sauce",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s14.png",
          audio: basePath + "/assets/sound/res_act1/s14.mp3",
          text: "香酥雞",
          pinyin: "xiāng sū jī",
          english: "crispy duck",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s15.png",
          audio: basePath + "/assets/sound/res_act1/s15.mp3",
          text: "北京烤鴨",
          pinyin: "běi jīng kǎo yā",
          english: "Beijing roast duck",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s16.png",
          audio: basePath + "/assets/sound/res_act1/s16.mp3",
          text: "東坡肉",
          pinyin: "dōng pō ròu",
          english: "stewed pork in earthenware pot",
        },
      ],
      taskSpecificParams: {
        typeLearning: true,
      },
    },
    15: {
      q: "Study the character and pronunciation for the following dishes",
      type: "grid sound",
      choices: [
        {
          img: basePath + "/assets/sound/res_act1/img/s17.png",
          audio: basePath + "/assets/sound/res_act1/s17.mp3",
          text: "砂鍋魚頭",
          pinyin: "shā guō yú tóu",
          english: "stewed fish head in casserole",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s18.png",
          audio: basePath + "/assets/sound/res_act1/s18.mp3",
          text: "茄汁明蝦",
          pinyin: "qié zhī míng xiā",
          english: "jumbo shrimp in tomato sauce",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s19.png",
          audio: basePath + "/assets/sound/res_act1/s19.mp3",
          text: "清蒸龍利",
          pinyin: "qīng zhēng lóng lì",
          english: "steamed whole founder",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s20.png",
          audio: basePath + "/assets/sound/res_act1/s20.mp3",
          text: "蔥薑龍蝦",
          pinyin: "cōng jiāng lóng xiā",
          english: "lobster with scallion and ginger",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s21.png",
          audio: basePath + "/assets/sound/res_act1/s21.mp3",
          text: "開陽白菜",
          pinyin: "kāi yáng bái cài",
          english: "Chinese cabbage with dried shrimp",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s22.png",
          audio: basePath + "/assets/sound/res_act1/s22.mp3",
          text: "四川泡菜",
          pinyin: "sì chuān pào cài",
          english: "Sichuan pickles",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s23.png",
          audio: basePath + "/assets/sound/res_act1/s23.mp3",
          text: "涼拌海蜇皮",
          pinyin: "liáng bàn hǎi zhé pí",
          english: "cold jelly fish salad",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s24.png",
          audio: basePath + "/assets/sound/res_act1/s24.mp3",
          text: "家常豆腐",
          pinyin: "jiā cháng dòu fǔ",
          english: "home style tofu",
        },
      ],
      taskSpecificParams: {
        typeLearning: true,
      },
    },
    16: {
      q: "Study the character and pronunciation for the following dishes",
      type: "grid sound",
      choices: [
        {
          img: basePath + "/assets/sound/res_act1/img/s25.png",
          audio: basePath + "/assets/sound/res_act1/s25.mp3",
          text: "乾扁四季豆",
          pinyin: "gān biǎn sì jì dòu",
          english: "dried sauteed string beans",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s26.png",
          audio: basePath + "/assets/sound/res_act1/s26.mp3",
          text: "青椒牛肉",
          pinyin: "qīng jiāo niú ròu",
          english: "beef sauteed with green pepper",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s27.png",
          audio: basePath + "/assets/sound/res_act1/s27.mp3",
          text: "素什錦",
          pinyin: "sù shí jǐn",
          english: "ten vegetables sauteed in sesame oil",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s28.png",
          audio: basePath + "/assets/sound/res_act1/s28.mp3",
          text: "炒飯",
          pinyin: "chǎo fàn",
          english: "fried rice",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s29.png",
          audio: basePath + "/assets/sound/res_act1/s29.mp3",
          text: "年糕",
          pinyin: "nián gāo",
          english: "new year cake",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s30.png",
          audio: basePath + "/assets/sound/res_act1/s30.mp3",
          text: "八寶飯",
          pinyin: "bā bǎo fàn",
          english: "Shanghai rice pudding",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s31.png",
          audio: basePath + "/assets/sound/res_act1/s31.mp3",
          text: "豆沙酥餅",
          pinyin: "dòu shā sū bǐng",
          english: "red bean paste short-cake",
        },
        {
          img: basePath + "/assets/sound/res_act1/img/s32.png",
          audio: basePath + "/assets/sound/res_act1/s32.mp3",
          text: "芝麻湯圓",
          pinyin: "zhī má tāng yuán",
          english: "sesame rice balls in syrup",
        },
      ],
      taskSpecificParams: {
        typeLearning: true,
      },
    },
    17: {
      q: "Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "小籠包",
          textS: "小笼包",
        },
        {
          text: "家常豆腐",
          textS: "家常豆腐",
        },
        {
          text: "炒飯",
          textS: "炒饭",
        },
        {
          text: "宮保雞丁",
          textS: "宮保鸡丁",
        },
        {
          text: "糖醋排骨",
          textS: "糖醋排骨",
        },
        {
          text: "北京烤鴨",
          textS: "北京烤鸭",
        },
        {
          text: "牛肉麵",
          textS: "牛肉面",
        },
        {
          text: "芝麻湯圓",
          textS: "芝麻汤圆",
        },
      ],
      answers: [
        "小籠包",
        "家常豆腐",
        "炒飯",
        "宮保雞丁",
        "糖醋排骨",
        "北京烤鴨",
        "牛肉麵",
        "芝麻湯圓",
      ],
      answersS: [
        "小笼包",
        "家常豆腐",
        "炒饭",
        "宮保鸡丁",
        "糖醋排骨",
        "北京烤鸭",
        "牛肉面",
        "芝麻汤圆",
      ],
      images: [
        basePath + "/assets/sound/res_act1/img/s1.png",
        basePath + "/assets/sound/res_act1/img/s24.png",
        basePath + "/assets/sound/res_act1/img/s12.png",
        basePath + "/assets/sound/res_act1/img/s28.png",
        basePath + "/assets/sound/res_act1/img/s10.png",
        basePath + "/assets/sound/res_act1/img/s15.png",
        basePath + "/assets/sound/res_act1/img/s7.png",
        basePath + "/assets/sound/res_act1/img/s32.png",
      ],
    },
    18: {
      q: "Dish Categories",
      type: "keywords",
      choices: [
        {
          english: "Appetizes",
          traditional: "小菜",
          pinyin: "xiǎo cài",
          audio: basePath + "/assets/sound/res_act2/s1.mp3",
        },
        {
          english: "Main course",
          traditional: "主食",
          pinyin: "zhǔ shí",
          audio: basePath + "/assets/sound/res_act2/s2.mp3",
        },
        {
          english: "Rice",
          traditional: "米飯",
          pinyin: "mǐ fàn",
          audio: basePath + "/assets/sound/res_act2/s3.mp3",
        },
        {
          english: "Noodles",
          traditional: "麵食",
          pinyin: "miàn shí",
          audio: basePath + "/assets/sound/res_act2/s4.mp3",
        },
        {
          english: "Sea food",
          traditional: "海鮮",
          pinyin: "hǎi xiān",
          audio: basePath + "/assets/sound/res_act2/s5.mp3",
        },
        {
          english: "Soup",
          traditional: "湯",
          pinyin: "tāng",
          audio: basePath + "/assets/sound/res_act2/s6.mp3",
        },
        {
          english: "Beef",
          traditional: "牛肉",
          pinyin: "niú ròu",
          audio: basePath + "/assets/sound/res_act2/s7.mp3",
        },
        {
          english: "Pork",
          traditional: "豬肉",
          pinyin: "zhū ròu",
          audio: basePath + "/assets/sound/res_act2/s8.mp3",
        },
        {
          english: "Chicken",
          traditional: "雞肉",
          pinyin: "jī ròu",
          audio: basePath + "/assets/sound/res_act2/s9.mp3",
        },
        {
          english: "Vegetable dish category",
          traditional: "炒菜類",
          pinyin: "chǎo cài lèi",
          audio: basePath + "/assets/sound/res_act2/s10.mp3",
        },
        {
          english: "Hot pot",
          traditional: "火鍋類",
          pinyin: "huǒ guō lèi",
          audio: basePath + "/assets/sound/res_act2/s11.mp3",
        },
        {
          english: "Barbeque",
          traditional: "燒烤類",
          pinyin: "shāo kǎo lèi",
          audio: basePath + "/assets/sound/res_act2/s12.mp3",
        },
        {
          english: "Vegetarian",
          traditional: "素菜類",
          pinyin: "sù cài lèi",
          audio: basePath + "/assets/sound/res_act2/s13.mp3",
        },
        {
          english: "Non-Vegetarian",
          traditional: "葷菜類",
          pinyin: "hūn cài lèi",
          audio: basePath + "/assets/sound/res_act2/s14.mp3",
        },
        {
          english: "cold appetizers",
          traditional: "冷盤",
          pinyin: "lěng pán",
          audio: basePath + "/assets/sound/res_act2/s15.mp3",
        },
        {
          english: "Cold appetizers",
          traditional: "凉菜",
          pinyin: "liáng cài",
          audio: basePath + "/assets/sound/res_act2/s16.mp3",
        },
        {
          english: "Desserts",
          traditional: "甜點",
          pinyin: "tián diǎn",
          audio: basePath + "/assets/sound/res_act2/s17.mp3",
        },
      ],
    },
    19: {
      q: "Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "米飯",
          textS: "米饭",
        },
        {
          text: "麵食",
          textS: "面食",
        },
        {
          text: "豬肉",
          textS: "猪肉",
        },
        {
          text: "雞肉",
          textS: "鸡肉",
        },
        {
          text: "燒烤類",
          textS: "烧烤类",
        },
        {
          text: "素菜類",
          textS: "素菜类",
        },
        {
          text: "冷盤",
          textS: "冷盘",
        },
        {
          text: "甜點",
          textS: "甜点",
        },
      ],
      answers: [
        "米飯",
        "麵食",
        "豬肉",
        "雞肉",
        "燒烤類",
        "素菜類",
        "冷盤",
        "甜點",
      ],
      answersS: [
        "米饭",
        "面食",
        "猪肉",
        "鸡肉",
        "烧烤类",
        "素菜类",
        "冷盘",
        "甜点",
      ],
      images: [
        basePath + "/assets/sound/res_act1/img/s3.png",
        basePath + "/assets/sound/res_act1/img/s4.png",
        basePath + "/assets/sound/res_act1/img/s8.png",
        basePath + "/assets/sound/res_act1/img/s9.png",
        basePath + "/assets/sound/res_act1/img/s12.png",
        basePath + "/assets/sound/res_act1/img/s13.png",
        basePath + "/assets/sound/res_act1/img/s15.png",
        basePath + "/assets/sound/res_act1/img/s17.png",
      ],
    },
    20: {
      q: "Match each word with its pinyin.",
      type: "line matching",
      choices: [
        {
          english: "mǐ fàn",
          simplified: "米饭",
          traditional: "米飯",
        },
        {
          english: "miàn shí",
          simplified: "面食",
          traditional: "麵食",
        },
        {
          english: "zhū ròu",
          simplified: "猪肉",
          traditional: "豬肉",
        },
        {
          english: "jī ròu",
          simplified: "鸡肉",
          traditional: "雞肉",
        },
        {
          english: "shāo kǎo lèi",
          simplified: "烧烤类",
          traditional: "燒烤類",
        },
        {
          english: "sù cài lèi",
          simplified: "素菜类",
          traditional: "素菜類",
        },
        {
          english: "lěng pán",
          simplified: "冷盘",
          traditional: "冷盤",
        },
        {
          english: "tián diǎn",
          simplified: "甜点",
          traditional: "甜點",
        },
      ],
    },
    21: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question:
            "Click the characters that have the radical 'plant, grass'.",
          subChoices: [
            {
              text: "菜葷",
              isCorrect: true,
            },
            {
              text: "煎蒸煮炒炸炖烤烧",
            },
            {
              text: "湯海",
            },
            {
              text: "食飯",
            },
            {
              text: "魚鮮",
            },
            {
              text: "冷涼",
            },
          ],
        },
        {
          question: "Click the characters that have the radical 'fire'.",
          subChoices: [
            {
              text: "菜葷",
            },
            {
              text: "煎蒸煮炒炸炖烤烧",
              isCorrect: true,
            },
            {
              text: "湯海",
            },
            {
              text: "食飯",
            },
            {
              text: "魚鮮",
            },
            {
              text: "冷涼",
            },
          ],
        },
        {
          question: "Click the characters that have the radical 'water'.",
          subChoices: [
            {
              text: "菜葷",
            },
            {
              text: "煎蒸煮炒炸炖烤烧",
            },
            {
              text: "湯海",
              isCorrect: true,
            },
            {
              text: "食飯",
            },
            {
              text: "魚鮮",
            },
            {
              text: "冷涼",
            },
          ],
        },
        {
          question: "Click the characters that have the radical 'ice'.",
          subChoices: [
            {
              text: "菜葷",
            },
            {
              text: "煎蒸煮炒炸炖烤烧",
            },
            {
              text: "湯海",
            },
            {
              text: "食飯",
            },
            {
              text: "魚鮮",
            },
            {
              text: "冷涼",
              isCorrect: true,
            },
          ],
        },
        {
          question: "Click the characters that have the radical 'food'.",
          subChoices: [
            {
              text: "菜葷",
            },
            {
              text: "煎蒸煮炒炸炖烤烧",
            },
            {
              text: "湯海",
            },
            {
              text: "食飯",
              isCorrect: true,
            },
            {
              text: "魚鮮",
            },
            {
              text: "冷涼",
            },
          ],
        },
        {
          question: "Click the characters that have the radical 'fish'.",
          subChoices: [
            {
              text: "菜葷",
            },
            {
              text: "煎蒸煮炒炸炖烤烧",
            },
            {
              text: "湯海",
            },
            {
              text: "食飯",
            },
            {
              text: "魚鮮",
              isCorrect: true,
            },
            {
              text: "冷涼",
            },
          ],
        },
      ],
    },
    22: {
      q: "Fill in the correct radical for the following characters by clicking the radical and then clicking the corresponding box.",
      type: "dragradicals",
      audio: "",
      choices: [
        {
          question: "to fry  前 +",
          meaning: "jiān",
          blanks: 1,
        },
        {
          question: "deep fry  乍 +",
          meaning: "zhá",
          blanks: 1,
        },
        {
          question: "cold  令 +",
          meaning: "lěng",
          blanks: 1,
        },
        {
          question: "fresh; seafood  羊 +",
          meaning: "xiān",
          blanks: 1,
        },
        {
          question: "vegetables  采 +",
          meaning: "cài",
          blanks: 1,
        },
      ],
      answers: [["火, 灬"], ["火, 灬"], ["冫"], ["鱼"], ["艸"]],
      images: ["冫", "火, 灬", "鱼", "火, 灬", "艸"],
    },
  },
  7: {
    0: {
      q: "Select the phrases that include the name of a dish or a drink. Click the phrase",
      type: "multipleSound",
      choices: [
        {
          text: "我們有果汁，可樂，還有茶。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_8-5.mp3",
        },
        {
          text: "我們来點一道蒜香雞，好不好？",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-10.mp3",
        },
        {
          text: "我們這邊有烏龍和香片。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_8-7.mp3",
        },
        {
          text: "那再一道麻婆豆腐。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-11.mp3",
        },
        {
          text: "我們來一壺香片。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_8-8.mp3",
        },
        {
          text: "那我想再多點一個金銀蛋炒飯。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-12.mp3",
        },
        {
          text: "這是您的冰水。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-14.mp3",
        },
        {
          text: "不客氣。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_8-3.mp3",
        },
        {
          text: "請問要喝點什麼嗎？",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_8-4.mp3",
        },
        {
          text: "請問你麼有什麼茶？",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_8-6.mp3",
        },
        {
          text: "好，没問題，請稍等。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_8-9.mp3",
        },
        {
          text: "我麼可以點菜了。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-15.mp3",
        },
        {
          text: "那我們就點這三道菜。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-13.mp3",
        },
        {
          text: "好，没問題。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-16.mp3",
        },
        {
          text: "您的碗。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-18.mp3",
        },
        {
          text: "不好意思，可以再给我們兩個碗嗎？",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-17.mp3",
        },
        {
          text: "我們可以買單了。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-19.mp3",
        },
        {
          text: "你們的帳單。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-20.mp3",
        },
        {
          text: "謝謝。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_8-2.mp3",
        },
        {
          text: "再加一成五的小費，我們一個人十八塊錢。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-21.mp3",
        },
        {
          text: "謝謝，歡迎下次再来。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_8-22.mp3",
        },
        {
          text: "這是菜單，請看一下。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_8-1.mp3",
        },
      ],
      answerKey: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    },
    1: {
      q: "Arrange the following sentences in the correct order by dragging the sentences",
      type: "arrange-audio-clips",
      choices: [
        {
          id: 0,
          text: "我們第一次来，可不可以介紹一兩道菜。",
          textS: "我们第一次来，可不可以介绍一两道菜。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_9-1.mp3",
        },
        {
          id: 1,
          text: "我們的海鮮很不錯，可以試試看。",
          textS: "我们的海鲜很不错，可以试试看。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_9-2.mp3",
        },
        {
          id: 2,
          text: "那来一道核桃蝦仁好了。",
          textS: "那来一道核桃虾仁好了。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_9-3.mp3",
        },
        {
          id: 3,
          text: "吃雞嗎？我們的宫保雞丁也不錯。",
          textS: "吃鸡吗？我们的宫保鸡丁也不错。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_9-4.mp3",
        },
        {
          id: 4,
          text: "好啊，來一份。",
          textS: "好啊，来一份。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_9-5.mp3",
        },
        {
          id: 5,
          text: "我還要一個葱爆牛肉。",
          textS: "我还要一个葱爆牛肉。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_9-6.mp3",
        },
        {
          id: 6,
          text: "好，那要不要來個青菜？",
          textS: "好，那要不要来个青菜？",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_9-7.mp3",
        },
        {
          id: 7,
          text: "你們有什麼青菜？",
          textS: "你们有什么青菜？",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_9-8.mp3",
        },
        {
          id: 8,
          text: "我們有青江菜，空心菜，豆苗。",
          textS: "我们有青江菜，空心菜，豆苗。",
          audio:
            basePath + "/assets/audio/module2/restaurant/m3_restaurant_9-9.mp3",
        },
        {
          id: 9,
          text: "一個豆苗。",
          textS: "一个豆苗。",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_9-10.mp3",
        },
      ],
      answerKey: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    2: {
      q: "Select all the dishes that were mentioned in the video",
      type: "grid sound",
      choices: [
        {
          id: 0,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/scallion beef.PNG",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/fried beef with leek.mp3",
        },
        {
          id: 1,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/kung pao chicken.PNG",
          audio:
            basePath + "/assets/audio/module2/restaurant/spicy chicken.mp3",
        },
        {
          id: 2,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Garlic Chicken.PNG",
          audio:
            basePath + "/assets/audio/module2/restaurant/garlic chicken.mp3",
        },
        {
          id: 3,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/walnut shrimp.PNG",
          audio:
            basePath + "/assets/audio/module2/restaurant/shrimp walnuts.mp3",
        },
        {
          id: 4,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Mapo.PNG",
          audio:
            basePath + "/assets/audio/module2/restaurant/ma po bean curd.mp3",
        },
        {
          id: 5,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/bean sprouts.PNG",
          audio: basePath + "/assets/audio/module2/restaurant/bean sprouts.mp3",
        },
      ],
      answerKey: [0, 1, 3, 5],
    },
    3: {
      q: "Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "芥藍牛",
          textS: "芥蓝牛",
          audio:
            basePath + "/assets/audio/module2/restaurant/beef broccoli.mp3",
        },
        {
          text: "葱爆牛肉",
          textS: "葱爆牛肉",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/fried beef with leek.mp3",
        },
        {
          text: "宫保雞丁",
          textS: "宫保鸡丁",
          audio:
            basePath + "/assets/audio/module2/restaurant/spicy chicken.mp3",
        },
        {
          text: "蒜香雞",
          textS: "蒜香鸡",
          audio:
            basePath + "/assets/audio/module2/restaurant/garlic chicken.mp3",
        },
        {
          text: "核桃蝦球",
          textS: "核桃虾球",
          audio:
            basePath + "/assets/audio/module2/restaurant/shrimp walnuts.mp3",
        },
        {
          text: "金銀蛋炒飯",
          textS: "金银蛋炒饭",
          audio: basePath + "/assets/audio/module2/restaurant/fry rice.mp3",
        },
        {
          text: "麻婆豆腐",
          textS: "麻婆豆腐",
          audio:
            basePath + "/assets/audio/module2/restaurant/ma po bean curd.mp3",
        },
        {
          text: "豆苗",
          textS: "豆苗",
          audio: basePath + "/assets/audio/module2/restaurant/bean sprouts.mp3",
        },
      ],
      answers: [
        "芥藍牛",
        "葱爆牛肉",
        "宫保雞丁",
        "蒜香雞",
        "核桃蝦球",
        "金銀蛋炒飯",
        "麻婆豆腐",
        "豆苗",
      ],
      answersS: [
        "芥蓝牛",
        "葱爆牛肉",
        "宫保鸡丁",
        "蒜香鸡",
        "核桃虾球",
        "金银蛋炒饭",
        "麻婆豆腐",
        "豆苗",
      ],
      images: [
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/beef broccoli.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2//Restaurant2/scallion beef.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/kung pao chicken.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/garlic chicken.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/walnut shrimp.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Eggrice.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Mapo.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/bean sprouts.png",
      ],
    },
    4: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question:
            "Listen to the follow audio and decide what sentence comes next",
          mainAudio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_11-1.mp3",
          mainAudioSubtext: "我們的海鮮很不錯，可以試試看。",
          subChoices: [
            {
              text: "那来一道核桃蝦球好了。",
              isCorrect: true,
            },
            {
              text: "你們有什麼青菜？",
            },
          ],
        },
        {
          question:
            "Listen to the follow audio and decide what sentence comes next",
          mainAudio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_11-2.mp3",
          mainAudioSubtext: "那要不要來個青菜？",
          subChoices: [
            {
              text: "你們有什麼青菜？",
              isCorrect: true,
            },
            {
              text: "湯不用了，没關係。",
            },
          ],
        },
        {
          question:
            "Listen to the follow audio and decide what sentence comes next",
          mainAudio:
            basePath +
            "/assets/audio/module2/restaurant/m3_restaurant_11-3.mp3",
          mainAudioSubtext: "現在有幾道菜了？",
          subChoices: [
            {
              text: "總共四道菜。",
              isCorrect: true,
            },
            {
              text: "好啊，來一份。",
            },
          ],
        },
      ],
    },
    5: {
      q: "Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "芥藍牛",
          textS: "芥蓝牛",
          audio:
            basePath + "/assets/audio/module2/restaurant/beef broccoli.mp3",
        },
        {
          text: "冰水",
          textS: "冰水",
          audio: basePath + "/assets/audio/module2/restaurant/ice water.mp3",
        },
        {
          text: "宫保雞丁",
          textS: "宫保鸡丁",
          audio:
            basePath + "/assets/audio/module2/restaurant/spicy chicken.mp3",
        },
        {
          text: "蒜香雞",
          textS: "蒜香鸡",
          audio:
            basePath + "/assets/audio/module2/restaurant/garlic chicken.mp3",
        },
        {
          text: "核桃蝦球",
          textS: "核桃虾球",
          audio:
            basePath + "/assets/audio/module2/restaurant/shrimp walnuts.mp3",
        },
        {
          text: "金銀蛋炒飯",
          textS: "金银蛋炒饭",
          audio: basePath + "/assets/audio/module2/restaurant/fry rice.mp3",
        },
        {
          text: "麻婆豆腐",
          textS: "麻婆豆腐",
          audio:
            basePath + "/assets/audio/module2/restaurant/ma po bean curd.mp3",
        },
        {
          text: "豆苗",
          textS: "豆苗",
          audio: basePath + "/assets/audio/module2/restaurant/bean sprouts.mp3",
        },
      ],
      answers: [
        "芥藍牛",
        "冰水",
        "宫保雞丁",
        "蒜香雞",
        "核桃蝦球",
        "金銀蛋炒飯",
        "麻婆豆腐",
        "豆苗",
      ],
      answersS: [
        "芥蓝牛",
        "冰水",
        "宫保鸡丁",
        "蒜香鸡",
        "核桃虾球",
        "金银蛋炒饭",
        "麻婆豆腐",
        "豆苗",
      ],
      images: [
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/beef broccoli.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2//Restaurant2/ice water.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/kung pao chicken.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/garlic chicken.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/walnut shrimp.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Eggrice.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Mapo.png",
        basePath +
          "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/bean sprouts.png",
      ],
    },
    6: {
      q: "You and some friends are going to dinner together and they have asked you to arrange for the food the restaurant will serve. Please read the email below with the menu they would like. After that, select the images of the foods you will order.",
      type: "grid sound",
      img:
        basePath +
        "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/res_3_7.png",
      choices: [
        {
          id: 0,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/beef broccoli.png",
          audio:
            basePath + "/assets/audio/module2/restaurant/beef broccoli.mp3",
        },
        {
          id: 1,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2//Restaurant2/scallion beef.png",
          audio:
            basePath +
            "/assets/audio/module2/restaurant/fried beef with leek.mp3",
        },
        {
          id: 2,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/kung pao chicken.png",
          audio:
            basePath + "/assets/audio/module2/restaurant/spicy chicken.mp3",
        },
        {
          id: 3,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/garlic chicken.png",
          audio:
            basePath + "/assets/audio/module2/restaurant/garlic chicken.mp3",
        },
        {
          id: 4,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/walnut shrimp.png",
          audio:
            basePath + "/assets/audio/module2/restaurant/shrimp walnuts.mp3",
        },
        {
          id: 5,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Eggrice.png",
          audio: basePath + "/assets/audio/module2/restaurant/fry rice.mp3",
        },
        {
          id: 6,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Mapo.png",
          audio:
            basePath + "/assets/audio/module2/restaurant/ma po bean curd.mp3",
        },
        {
          id: 7,
          img:
            basePath +
            "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/bean sprouts.png",
          audio: basePath + "/assets/audio/module2/restaurant/bean sprouts.mp3",
        },
      ],
      answerKey: [0, 1, 5, 6],
    },
    7: {
      q: "Role Play: Practice the three restaurant scenarios with a partner. Take turns playing the waiter and customer. After practicing, choose one of the scenarios and record it. Email your recording to your instructor.",
      type: "recording",
      img:
        basePath +
        "/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/res_3_T8.png",
      choices: [],
    },
  },
};

// choices: [
//   {
//     id: 0,
//     text: '芥藍牛',
//     img: '/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/beef broccoli.png',
//     audio: '/assets/audio/module2/restaurant/beef broccoli.mp3',
//   },
//   {
//     id: 1,
//     text: '葱爆牛肉',
//     img: '/assets/images/food/bubbleTea2_literacy_activity_2//Restaurant2/scallion beef.png',
//     audio: '/assets/audio/module2/restaurant/fried beef with leek.mp3',
//   },
//   {
//     id: 2,
//     text: '宫保雞丁',
//     img: '/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/kung pao chicken.png',
//     audio: '/assets/audio/module2/restaurant/spicy chicken.mp3',
//   },
//   {
//     id: 3,
//     text: '蒜香雞',
//     img: '/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/garlic chicken.png',
//     audio: '/assets/audio/module2/restaurant/garlic chicken.mp3',
//   },
//   {
//     id: 4,
//     text: '核桃蝦球',
//     img: '/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/walnut shrimp.png',
//     audio: '/assets/audio/module2/restaurant/shrimp walnuts.mp3',
//   },
//   {
//     id: 5,
//     text: '金銀蛋炒飯',
//     img: '/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Eggrice.png',
//     audio: '/assets/audio/module2/restaurant/fry rice.mp3',
//   },
//   {
//     id: 6,
//     text: '麻婆豆腐',
//     img: '/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/Mapo.png',
//     audio: '/assets/audio/module2/restaurant/ma po bean curd.mp3',
//   },
//   {
//     id: 7,
//     text: '豆苗',
//     img: '/assets/images/food/bubbleTea2_literacy_activity_2/Restaurant2/bean sprouts.png',
//     audio: '/assets/audio/module2/restaurant/bean sprouts.mp3',
//   },
// ],
