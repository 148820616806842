import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import IncorrectIcon from "../../../icons/IncorrectIcon";
import CorrectIcon from "../../../icons/CorrectIcon";
import styled from "styled-components";

const IconContainer = styled.div`
  width: 36px;
  height: 36px;
  padding: 12px;
`;

class MultipleChoiceProblem extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedValue: null, showAnswer: false, correct: null };
  }

  componentDidUpdate(prevProps) {
    if (this.props.question != prevProps.question) {
      this.setState({ selectedValue: null, correct: null, showAnswer: false });
    }
  }
  onChangeValue = (e) => {
    this.setState({ selectedValue: e.target.value });
  };

  checkAnswer = () => {
    const correct = this.state.selectedValue === this.props.correctAnswer;
    correct && this.props.updateScore && this.props.updateScore();
    this.setState({
      correct,
      showAnswer: true,
    });
    this.props.toggleNext && this.props.toggleNext();
    return this.state.selectedValue === this.props.correctAnswer;
  };

  render() {
    const { question, correctAnswer, options } = this.props;

    let displayChoices = options.map((option, i) => (
      <div
        onChange={this.onChangeValue}
        style={{ textAlign: "left", fontSize: "1.5em" }}
      >
        <input
          type="radio"
          id={option}
          name={question}
          value={option}
          checked={option === this.state.selectedValue}
        />
        <label for={option} style={{ marginLeft: "5px" }}>
          {option}
        </label>
      </div>
    ));
    const correctIcon =
      this.state.correct === true ? <CorrectIcon /> : <IncorrectIcon />;
    return (
      <div>
        <h1 style={{ fontSize: "2em" }}>{question}</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div> {displayChoices}</div>
        </div>

        <br />
        <br />
        <div>
          {" "}
          <Button
            variant="contained"
            color="secondary"
            onClick={this.checkAnswer}
            disabled={this.state.correct !== null}
          >
            Check Answer
          </Button>
        </div>
        {this.state.showAnswer && <IconContainer>{correctIcon}</IconContainer>}
      </div>
    );
  }
}

export default withRouter(MultipleChoiceProblem);
