import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import data from "../../../../data/chengyu/normalized.js";
import Crossword from "./Crossword";
import ProblemSet from "../../Practices/ProblemSet.jsx";
import { getRandomSample, shuffleArray } from "../../helpers.js";
import OpenCC from "node-opencc";

/**
 * Will generate a list of crossword problems
 * Takes in an array of {question, answer} objects
 * Takes in returnRoute for back to practices page
 */
class CrosswordPractice extends Component {
  constructor(props) {
    super(props);
    this.practice = React.createRef();
    this.state = {
      problemSet: [],
      tilechoices: {},
    };
  }

  componentDidMount() {
    this.initializeCrossWords();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.updateChoicesLang();
    }
  }

  updateScore = () => {
    this.setState({ correctAnswers: this.state.correctAnswers + 1 });
  };
  toggleNext = () => {
    this.setState({ continue: !this.state.continue });
  };

  initializeCrossWords = () => {
    const { groupId, lesson } = this.props.match.params;
    const dataName = this.props.match.params[0];
    const { language } = this.props;
    const tiles = {};
    let crosswordProblemSet =
      this.props.crosswordProblemSet ||
      data[dataName][lesson].qaData.chengyuCrosswordData[language][groupId];
    const problemSet = crosswordProblemSet.map((q, i) => {
      const tilechoices = this.generateTileChoices(q.answer);
      tiles[i] = tilechoices;
      return (
        <div>
          <Crossword
            id={q.answer}
            question={q.question}
            answer={q.answer}
            choices={tilechoices.split("")}
            language={this.props.language}
            updateScore={this.practice.current.updateScore}
            toggleNext={this.practice.current.allowNext}
          />
        </div>
      );
    });

    this.setState({ problemSet, tilechoices: tiles });
  };

  // Looks the same except we dont generate random tile chocies on language switch
  updateChoicesLang = () => {
    const { language } = this.props;
    const { groupId, lesson } = this.props.match.params;
    const dataName = this.props.match.params[0];
    const tiles = {};
    let crosswordProblemSet =
      this.props.crosswordProblemSet ||
      data[dataName][lesson].qaData.chengyuCrosswordData[language][groupId];
    const problemSet = crosswordProblemSet.map((q, i) => {
      const prevChoices = this.state.tilechoices[i];
      const translated =
        language === "simplified"
          ? OpenCC.traditionalToSimplified(prevChoices)
          : OpenCC.simplifiedToTraditional(prevChoices);
      tiles[i] = translated;
      return (
        <div>
          <Crossword
            id={q.answer}
            question={q.question}
            answer={q.answer}
            choices={translated.split("")}
            language={this.props.language}
            updateScore={this.practice.current.updateScore}
            toggleNext={this.practice.current.allowNext}
          />
        </div>
      );
    });
    this.setState({ problemSet, tilechoices: tiles });
  };

  setProblemSet = () => {};

  generateTileChoices = (correctAnswer) => {
    const cleanAnswer = correctAnswer.replace(" ", "").replace(",", "");
    const { groupId, lesson } = this.props.match.params;
    const dataName = this.props.match.params[0];
    const { language } = this.props;
    const questions =
      this.props.allCrosswordData ||
      data[dataName][lesson].qaData.chengyuCrosswordData[language][groupId];
    const choices = questions
      .reduce(
        (acc, curr) => acc + curr.answer.replace(" ", "").replace(",", ""),
        ""
      )
      .split("")
      .filter((choice) => !cleanAnswer.includes(choice));

    let randomChoices = getRandomSample(choices, 12);
    randomChoices = [...randomChoices, ...cleanAnswer.split("")];
    const shuffled = shuffleArray(randomChoices);
    return shuffled.join("");
  };

  render() {
    return (
      <div>
        <ProblemSet
          allProblems={this.state.problemSet}
          ref={this.practice}
          returnRoute={this.props.returnRoute}
        />
      </div>
    );
  }
}

export default withRouter(CrosswordPractice);
