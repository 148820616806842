import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import OpenCC from "node-opencc";
/* eslint-disable no-unused-expressions */

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.35em;
  margin-left: 0;
  margin-right: 0;
`;

const StyledText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  font-weight: 400;
  width: 100%;
`;

const StyledError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin-top: 20px;
  font-size: 1.05em;
  font-weight: 600;
`;

const StyledNumbers = styled.div`
  font-size: 1.15em;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 15px;
`;

const StyledAudio = styled.audio`
  width: 250px;
  position: relative;
  left: 0%;
  margin-bottom: 15px;
`;

function ArrangeAudio({
  question,
  choices,
  nextLink,
  answerKey,
  mainaudio,
  simplified,
}) {
  const [choicesCopy, setChoicesCopy] = useState([]);
  const [status, setStatus] = useState("Check");
  const [errors, setErrors] = useState("");
  const [check, setCheck] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const grid = 6;
  const [currentAudio, setCurrentAudio] = useState();
  const landscape =
    process.browser && window.matchMedia("(orientation: landscape)").matches;

  useEffect(() => {
    for (let i = 0; i < choices.length; i++) {
      setNumbers((numbers) => [...numbers, i + 1]);
    }

    const array = shuffle(choices);
    setChoicesCopy(array);
  }, []);

  const getItemStyle = (isDragging, draggableStyle, index) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 5,
    margin: `0 0 ${grid}px 0`,
    borderRadius: 7,
    border:
      check === true
        ? choicesCopy[index].id === answerKey[index]
          ? "1px solid green"
          : "1px solid red"
        : "1px solid #424242",
    // change background colour if dragging
    background: isDragging ? "#4fc3f7" : check === true ? "white" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "transparent" : "transparent",
    padding: grid,
    display: "flex",
    flexDirection: "column",
    borderRadius: 7,
  });

  const getSimpleAudioStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "transparent" : "transparent",
    padding: grid,
    width: 200,
    borderRadius: 7,
  });

  const shuffle = (array) => {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      choicesCopy,
      result.source.index,
      result.destination.index
    );

    setChoicesCopy(items);
  };

  const playAudio = (item) => {
    if (currentAudio !== undefined) currentAudio.pause();
    const sound = new Audio(item);
    setCurrentAudio(sound);
    sound.play();
  };

  const handleButton = () => {
    switch (status) {
      case "Check":
        setCheck(true);
        const answers = [];

        // Check if answer array of IDs matches answerkey
        for (let i = 0; i < choicesCopy.length; i++) {
          answers.push(choicesCopy[i].id);
        }
        answers.join() === answerKey.join()
          ? setStatus("Next")
          : (setErrors("Incorrect order."), setStatus("Retry"));
        break;
      case "Retry":
        const array = shuffle(choices);
        setCheck(false);
        setChoicesCopy(array);
        setErrors("");
        setStatus("Check");
        break;
      case "Next":
        window.location.href = nextLink;
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <StyledQuestion>
        {simplified ? OpenCC.traditionalToSimplified(question) : question}
      </StyledQuestion>

      {mainaudio && (
        <StyledAudio controls controlsList="nodownload" src={mainaudio} />
      )}

      <div style={{ display: "flex", width: "90vw", justifyContent: "center" }}>
        <div>
          {numbers.map((item) => (
            <StyledNumbers>{item}.</StyledNumbers>
          ))}
        </div>
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={
                    choices[0].text
                      ? getListStyle(snapshot.isDraggingOver)
                      : getSimpleAudioStyle(snapshot.isDraggingOver)
                  }
                >
                  {choicesCopy.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={
                        errors ? item.id : item.audio ? item.audio : item.text
                      }
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            index
                          )}
                        >
                          <StyledText
                            style={
                              check
                                ? choicesCopy[index].id === answerKey[index]
                                  ? { color: "green" }
                                  : { color: "red" }
                                : null
                            }
                            onClick={() => {
                              item.audio ? playAudio(item.audio) : null;
                            }}
                          >
                            {item.textS
                              ? simplified
                                ? item.textS
                                : item.text
                              : "Audio"}
                            {item.audio && (
                              <VolumeUpRoundedIcon
                                style={{ marginTop: 7 }}
                                fontSize="large"
                              />
                            )}
                          </StyledText>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>

      <Button
        style={{ margin: 5, marginTop: 20 }}
        variant="contained"
        color="secondary"
        onClick={() => handleButton()}
      >
        {status}
      </Button>
    </React.Fragment>
  );
}

export default ArrangeAudio;
