import React from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import openCC from "node-opencc";
import Chinese from "chinese-s2t";

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.35rem;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  @media (orientation: landscape) {
    width: 75vw;
  }
`;

const StyledContent = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTask = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  display: flex;
  object-fit: contain;
  width: 90vw;
  @media (orientation: portrait) {
    width: 90vw;
    height: 60vh;
  }
  margin: 5;
`;

const Audio = styled.audio`
  width: 250px;
  position: relative;
  left: 0%;
  margin-bottom: 15px;
`;

const Video = styled.video`
  @media (orientation: landscape) {
    height: 18vw;
    width: 24vw;
  }
  @media (orientation: portrait) {
    height: 67.5vw;
    width: 90vw;
    padding: 15px;
  }
`;

function Recording({
  question,
  mainimg,
  nextLink,
  choices,
  mainaudio,
  video,
  simplified,
  altQ,
}) {
  const handleNext = () => {
    window.location.href = nextLink;
  };

  return (
    <React.Fragment>
      <StyledQuestion>
        {simplified ? Chinese.s2t(question) : question}
      </StyledQuestion>

      {mainaudio && (
        <Audio controls controlsList="nodownload" src={mainaudio} />
      )}

      {video ? (
        <Video controls preload="metadata">
          <source src={video.src} type="video/mp4" />
          <track
            default
            label="English"
            kind="subtitles"
            srcLang="en"
            src={video.captionSrc}
          />
        </Video>
      ) : null}

      {mainimg && <StyledImage alt="loading..." src={mainimg} />}

      {choices.map(({ content, task, q }, index) => (
        <div>
          {content && <StyledContent>{content}</StyledContent>}
          {task && <StyledTask>{task}</StyledTask>}
          {q && (
            <StyledTask>
              {index + 1}
              {". "}
              {simplified ? openCC.traditionalToSimplified(q) : q}
            </StyledTask>
          )}
        </div>
      ))}

      <div>{altQ && <StyledQuestion>{altQ}</StyledQuestion>}</div>

      <Button
        variant="contained"
        color="secondary"
        style={{ margin: 5, marginTop: 20 }}
        onClick={handleNext}
      >
        {" "}
        Next{" "}
      </Button>
    </React.Fragment>
  );
}

export default Recording;
