const basePath = "https://materials.chineseforall.org"
export default {
  0: {
    0: {
      q: "What is the person trying to find?",
      type: "single",
      choices: [
        {
          text: "銀行",
          img: basePath + "/assets/images/direction2/bank.png",
          audio: basePath + "/assets/audio/module3/1/1-3.mp3",
          isCorrect: true,
        },
        {
          text: "超級市場",
          img: basePath + "/assets/images/direction2/market.png",
          audio: basePath + "/assets/audio/module3/1/1-2.mp3",
        },
        {
          text: "咖啡店",
          img: basePath + "/assets/images/direction2/coffee.png",
          audio: basePath + "/assets/audio/module3/1/1-4.mp3",
        },
        {
          text: "公車站",
          img: basePath + "/assets/images/direction2/bus_station.png",
          audio: basePath + "/assets/audio/module3/1/1-1.mp3",
        },
      ],
    },
    1: {
      q: "Which audio matches",
      type: "multi-single",
      choices: [
        {
          question: "Which audio matches? ",
          subChoices: [
            {
              audio: basePath + "/assets/audio/module3/1/Task2_0.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_1.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_2.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_3.mp3",
              isCorrect: true,
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_4.mp3",
            },
          ],
        },
        {
          question: "Which audio matches ",
          subChoices: [
            {
              audio: basePath + "/assets/audio/module3/1/Task2_0.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_1.mp3",
              isCorrect: true,
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_2.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_3.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_4.mp3",
            },
          ],
        },
        {
          question: "Which audio matches: ",
          subChoices: [
            {
              audio: basePath + "/assets/audio/module3/1/Task2_0.mp3",
              isCorrect: true,
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_1.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_2.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_3.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_4.mp3",
            },
          ],
        },
        {
          question: "Which audio matches: ",
          subChoices: [
            {
              audio: basePath + "/assets/audio/module3/1/Task2_0.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_1.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_2.mp3",
              isCorrect: true,
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_3.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_4.mp3",
            },
          ],
        },
        {
          question: "Which audio matches: ",
          subChoices: [
            {
              audio: basePath + "/assets/audio/module3/1/Task2_0.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_1.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_2.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_3.mp3",
            },
            {
              audio: basePath + "/assets/audio/module3/1/Task2_4.mp3",
              isCorrect: true,
            },
          ],
        },
      ],
      taskSpecificParams: {
        subQuestions: [
          "1. 先生，請問匯豐銀行怎麼走啊？",
          "2. 你要到對街，坐二十路公車。",
          "3. 下一站一下車，你就會看到銀行了。",
          "4. 好的，謝謝。",
          "5. 不客氣。",
        ],
      },
    },
    2: {
      q: "Which of the following is correct?",
      type: "single",
      img: basePath + "/assets/module3_task2.png",
      simpImage: basePath + "/assets/module3_task2_sim.png",
      choices: [
        {
          text: "Red Route",
        },
        {
          text: "Green Route",
        },
        {
          text: "Blue Route",
          isCorrect: true,
        },
      ],
    },
    3: {
      q:
        "Listen to each audio and choose the one that correctly represents the instructions given in the video.",
      altQ: ["聽取語音，選擇一個最能代表影片中正確的指示。"],
      type: "single",
      choices: [
        {
          text: "過馬路，坐20路公車，在下一站下車。",
          audio: basePath + "/assets/audio/module3/1/1_4_2.mp3",
          isCorrect: true,
        },
        {
          text: "左轉，坐20路公車，坐兩站。",
          audio: basePath + "/assets/audio/module3/1/1_4_3.mp3",
        },
        {
          text: "右轉，在對街坐20路公車。",
          audio: basePath + "/assets/audio/module3/1/1_4_1.mp3",
        },
        {
          text: "直走，過兩條街。",
          audio: basePath + "/assets/audio/module3/1/1_4_4.mp3",
        },
      ],
    },
    4: {
      q:
        "Match the blanks. Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "公車",
          textS: "公车",
          audio: basePath + "/assets/audio/module3/1/1_5_0.mp3",
        },
        {
          text: "銀行",
          textS: "银行",
          audio: basePath + "/assets/audio/module3/1/1_5_1.mp3",
        },
        {
          text: "街道",
          textS: "街道",
          audio: basePath + "/assets/audio/module3/1/1_5_2.mp3",
        },
      ],
      answers: ["公車", "銀行", "街道"],
      answersS: ["公车", "银行", "街道"],
      images: [
        basePath + "/assets/images/direction2/bus.png",
        basePath + "/assets/images/direction2/bank.png",
        basePath + "/assets/images/direction2/street.png",
      ],
    },
    5: {
      q: "Click an underlined word to see the meaning and hear the sound.",
      type: "literacy",
      audio: basePath + "/assets/audio/module3/1/givedirection_begin.mp3",
      choices: [
        {
          A: "*哎*，*先生*，*請問**匯豐銀行**怎麼走啊*？",
          AS: "*哎*，*先生*，*请问**汇丰银行**怎么走啊*？",
          Tooltip: [
            "ai4, Hi",
            "xian1sheng, Mr.",
            "qing3wen4, May I ask?",
            "hui4 feng1 yin2 hang2, HSBC Bank",
            "zěnme zǒu a?, How to get to…(from here)?",
          ],
          English: "Mr. May I ask how to go to HNCB Bank from here?",
          Pinyin:
            "Ai4, xian1sheng, qing3wen4, hui4 feng1 yin2 hang2, zěnme zǒu a?",
        },
        {
          B:
            "這*附近*沒有，*你要**到**對街*，*坐**二十路公車*，*下一站**一**下車*，你就會*看到**銀行*了。",
          BS:
            "这*附近*没有，*你要**到**对街*，*坐**二十路公车*，*下一站**一**下车*，你就会*看到**银行*了。",
          Tooltip: [
            "fu4 jin4, around here; nearby",
            "ni3 yao4, you should…",
            "dao4, go to",
            "dui4 jie1, across the street",
            "zuo4 gong1 che1, take bus",
            "er4 shi2 lu4 gong1 che1, bus #20 (路 lu4, measure word for bus line number)",
            "xia4 yi2 zhan4, next stop (站, bus stop)",
            "一…就會…了  yi1…jiu4 hui4…le, as soon as… you will…",
            "下車 xia4 che1, get off the bus",
            "看到 kan4 dao4, see",
            "銀行 yin2 hang2, the bank",
          ],
          English:
            "There is no such bank around here. You should go across the street and take bus #20.  Get off at the next stop. As soon as you get off the bus, you will see the bank.",
          Pinyin:
            "Zhè fùjìn méiyǒu, nǐ yào dào duì jiē, zuò èrshí lù gōngchē, xià yí zhàn yī xiàchē, nǐ jiù huì kàn dào yínhángle.",
        },
        {
          A: "好的，謝謝。",
          AS: "好的，谢谢。",
          Tooltip: [],
          English: "Ok. Thank you.",
          Pinyin: "Hǎo de, xièxiè.",
        },
        {
          B: "*不客氣*。",
          BS: "*不客气*。",
          Tooltip: ["不客氣 bu2 ke4 qi, You are welcome."],
          English: "You are welcome.",
          Pinyin: "Bù kèqì.",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/dir3_v1/s1.mp3",
          basePath + "/assets/sound/dir3_v1/s2.mp3",
          basePath + "/assets/sound/dir3_v1/s3.mp3",
          basePath + "/assets/sound/dir3_v1/s4.mp3",
          basePath + "/assets/sound/dir3_v1/s5.mp3",
          basePath + "/assets/sound/dir3_v1/s6.mp3",
          basePath + "/assets/sound/dir3_v1/s7.mp3",
          basePath + "/assets/sound/dir3_v1/s8.mp3",
          basePath + "/assets/sound/dir3_v1/s9.mp3",
          basePath + "/assets/sound/dir3_v1/s10.mp3",
          basePath + "/assets/sound/dir3_v1/s11.mp3",
          basePath + "/assets/sound/dir3_v1/s12.mp3",
          basePath + "/assets/sound/dir3_v1/s13.mp3",
          basePath + "/assets/sound/dir3_v1/s14.mp3",
          basePath + "/assets/sound/dir3_v1/s15.mp3",
          basePath + "/assets/sound/dir3_v1/s16.mp3",
          basePath + "/assets/sound/dir3_v1/s17.mp3",
        ],
      },
    },
  },
  1: {
    0: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question: "1. 这個人想要找什麼？",
          subChoices: [
            {
              text: "地鐵站",
              img: basePath + "/assets/images/direction2/subway.png",
              audio: basePath + "/assets/audio/module3/2/2-1_2.mp3",
              isCorrect: true,
            },
            {
              text: "快餐廳",
              img: basePath + "/assets/images/direction2/restaurant.png",
              audio: basePath + "/assets/audio/module3/2/2-1_1.mp3",
            },
            {
              text: "公共電話",
              img: basePath + "/assets/images/direction2/phone.png",
              audio: basePath + "/assets/audio/module3/2/2-1_3.mp3",
            },
            {
              text: "面包店",
              img: basePath + "/assets/images/direction2/bread.png",
              audio: basePath + "/assets/audio/module3/2/2-1_4.mp3",
            },
          ],
        },
        {
          question: "2. 那個男人用什麼地標幫助那個女人認路？",
          subChoices: [
            {
              text: "快餐廳",
              img: basePath + "/assets/images/direction2/restaurant.png",
              audio: basePath + "/assets/audio/module3/2/2-1_1.mp3",
              isCorrect: true,
            },
            {
              text: "便利商店",
              img: basePath + "/assets/images/direction2/convenience.png",
              audio: basePath + "/assets/audio/module3/2/2-1-5.mp3",
            },
            {
              text: "銀行",
              img: basePath + "/assets/images/direction2/bank.png",
              audio: basePath + "/assets/audio/module3/1/1-3.mp3",
            },
            {
              text: "公用電話",
              img: basePath + "/assets/images/direction2/phone.png",
              audio: basePath + "/assets/audio/module3/2/2-1_3.mp3",
            },
          ],
        },
        {
          question: "3. 那個女人到達地標之後要往哪個方向走？",
          subChoices: [
            {
              text: "右轉",
              audio: basePath + "/assets/audio/module3/2/2-1-10.mp3",
              img: basePath + "/assets/images/direction2/right.png",
              isCorrect: true,
            },
            {
              text: "左轉",
              img: basePath + "/assets/images/direction2/left.png",
              audio: basePath + "/assets/audio/module3/2/2-1-9.mp3",
            },
            {
              text: "直走",
              img: basePath + "/assets/images/direction2/straight.png",
              audio: basePath + "/assets/audio/module3/2/2-1-11.mp3",
            },
          ],
        },
      ],
    },
    1: {
      q:
        "Arrange the phrases in the order that best represents the directions given.",
      type: "arrange-audio-clips",
      choices: [
        {
          id: 0,
          text: "繼續直走",
          textS: "继续直走",
        },
        {
          id: 1,
          text: "第一個路口右轉",
          textS: "第一个路口右转",
        },
        {
          id: 2,
          text: "繼續走",
          textS: "继续走",
        },
        {
          id: 3,
          text: "轉彎",
          textS: "转弯",
        },
      ],
      answerKey: [0, 1, 2, 3],
    },
    2: {
      q:
        "Listen to the audio then arrange the phrases in the order that best represents the directions given.",
      type: "arrange-audio-clips",
      choices: [
        {
          id: 0,
          audio: basePath + "/assets/audio/module3/2/audio0.mp3",
        },
        {
          id: 1,
          audio: basePath + "/assets/audio/module3/2/audio1.mp3",
        },
        {
          id: 2,
          audio: basePath + "/assets/audio/module3/2/audio2.mp3",
        },
        {
          id: 3,
          audio: basePath + "/assets/audio/module3/2/audio3.mp3",
        },
        {
          id: 4,
          audio: basePath + "/assets/audio/module3/2/audio4.mp3",
        },
        {
          id: 5,
          audio: basePath + "/assets/audio/module3/2/audio5.mp3",
        },
      ],
      answerKey: [0, 1, 2, 3, 4, 5],
    },
    3: {
      q: "Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "直走",
          textS: "直走",
          audio: basePath + "/assets/audio/module3/2/audio1_3.mp3",
        },
        {
          text: "左轉",
          textS: "左转",
          audio: basePath + "/assets/audio/module3/2/audio1_1.mp3",
        },
        {
          text: "右轉",
          textS: "右转",
          audio: basePath + "/assets/audio/module3/2/audio1_2.mp3",
        },
        {
          text: "快餐廳",
          textS: "快餐厅",
          audio: basePath + "/assets/audio/module3/2/audio1_4.mp3",
        },
        {
          text: "地鐵站",
          textS: "地铁站",
          audio: basePath + "/assets/audio/module3/2/audio1_0.mp3",
        },
      ],
      answers: ["直走", "左轉", "右轉", "快餐廳", "地鐵站"],
      answersS: ["直走", "左转", "右转", "快餐厅", "地铁站"],
      images: [
        basePath + "/assets/images/direction2/straight.png",
        basePath + "/assets/images/direction2/left.png",
        basePath + "/assets/images/direction2/right.png",
        basePath + "/assets/images/direction2/restaurant.png",
        basePath + "/assets/images/direction2/subway.png",
      ],
    },
    4: {
      q: {
        simplified: "Which choice matches the audio?",
        traditional: "Which choice matches the audio?",
      },
      type: "Audio MultipleChoice",
      choices: [
        {
          text: "Turn at the corner",
          audioSrc: basePath + "/assets/audio/module3/2/audio_5_5.mp3",
        },
        {
          text: "Make a left turn",
          audioSrc: basePath + "/assets/audio/module3/2/audio_5_3.mp3",
        },
        {
          text: "Make a right turn",
          audioSrc: basePath + "/assets/audio/module3/2/audio_5_2.mp3",
        },
        {
          text: "Go straight",
          audioSrc: basePath + "/assets/audio/module3/2/audio_5_1.mp3",
        },
        {
          text: "How do I get to",
          audioSrc: basePath + "/assets/audio/module3/2/audio_5_0.mp3",
        },
        {
          text: "Thank you",
          audioSrc: basePath + "/assets/audio/module3/2/audio_5_4.mp3",
        },
      ],
    },
    5: {
      type: "recording",
      img: basePath + "/assets/images/direction2/email.png",
      choices: [
        {
          task: "Record your message, and send it to your teacher.",
        },
      ],
    },
    6: {
      q: "Click an underlined word to see the meaning and hear the sound.",
      type: "literacy",
      audio: basePath + "/assets/audio/module3/2/givedirection_med.mp3",
      choices: [
        {
          A: "*先生*，*請問**地鐵站**怎麼走啊？*",
          AS: "*先生*，*请问**地铁站**怎么走啊？*",
          Tooltip: [
            "xian1 sheng, Mr.",
            "qing3 wen4, May I ask…?",
            "di4 tie3 zhan4, subway station",
            "How can I get to…?",
          ],
          English: "Mr. May I ask how to get to the subway station?",
          Pinyin: "Xiānshēng, qǐngwèn dìtiě zhàn zěnme zǒu a?",
        },
        {
          B:
            "*向*這個方向，*繼續**直走*，*第一個**路口**右轉*，你*會**看到**一家**快餐廳*，繼續走，*轉彎*，地鐵站*就**在*旁邊。",
          BS:
            "*向*这个方向，*继续**直走*，*第一个**路口**右转*，你*会**看到**一家**快餐厅*，继续走，*转弯*，地铁站*就**在*旁边。",
          Tooltip: [
            "xiang4…fang1xiang4, go toward …direction (方向, direction)",
            "ji4 xu4, continue to",
            "zhi2 zou4, walk straight",
            "di4 yi1 ge, the first",
            "lu4 kou3, intersection",
            "you4 zhuan3, make a right turn",
            "hui4, you will",
            "kan4 dao4, see",
            "yi4 jia1, a",
            "kuai4 can1 ting1, fast-food restaurant",
            "zhuan3 wan1, make a turn",
            "jiu4, right (there)",
            "zai4 pang2 bian1, beside",
          ],
          English:
            "The station is toward this direction. Go straight. Turn right at the first corner. You will see a fast food restaurant. Then you should continue to walk. Turn at the corner. The subway station is right beside it.",
          Pinyin:
            "Xiàng zhège fāngxiàng, jìxù zhí zǒu, dì yī gè lùkǒu yòu zhuǎn, nǐ huì kàn dào yījiā kuài cāntīng, jìxù zǒu, zhuǎnwān, dìtiě zhàn jiù zài pángbiān.",
        },
        {
          A: "*那*看到快餐店，*左轉**還是右轉呢*？",
          AS: "*那*看到快餐店，*左转**还是右转呢*？",
          Tooltip: [
            "na4, then",
            "zuo3 zhan3, make a left turn",
            "A 還是 B呢, A or B?",
          ],
          English:
            "When you see the fast food store, I should make a left turn or a right turn?",
          Pinyin: "Nà kàn dào kuàicān diàn, zuǒ zhuǎn háishì yòu zhuǎn ne?",
        },
        {
          B: "右轉。",
          BS: "右转。",
          Tooltip: [],
          English: "Make a right turn.",
          Pinyin: "Yòu zhuǎn",
        },
        {
          A: "嗯，我*知道了*，謝謝。",
          AS: "嗯，我*知道了*，谢谢。",
          Tooltip: ["zhi1 dao4, know"],
          English: "I see. Thank you.",
          Pinyin: "Ń, wǒ zhīdàole, xièxiè.",
        },
        {
          B: "*不太**遠**的*。",
          BS: "*不太**远**的*。",
          Tooltip: ["不太 bu2 tai4", "遠 yuan3, far", "的 de, not very"],
          English: "It’s not very far.",
          Pinyin: "不太遠的。",
        },
        {
          A: "謝謝。",
          AS: "谢谢。",
          Tooltip: [],
          English: "Thank you. Good bye.",
          Pinyin: "Xièxiè",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/dir3_v2/s1.mp3",
          basePath + "/assets/sound/dir3_v2/s2.mp3",
          basePath + "/assets/sound/dir3_v2/s3.mp3",
          basePath + "/assets/sound/dir3_v2/s4.mp3",
          basePath + "/assets/sound/dir3_v2/s5.mp3",
          basePath + "/assets/sound/dir3_v2/s6.mp3",
          basePath + "/assets/sound/dir3_v2/s7.mp3",
          basePath + "/assets/sound/dir3_v2/s8.mp3",
          basePath + "/assets/sound/dir3_v2/s9.mp3",
          basePath + "/assets/sound/dir3_v2/s10.mp3",
          basePath + "/assets/sound/dir3_v2/s11.mp3",
          basePath + "/assets/sound/dir3_v2/s12.mp3",
          basePath + "/assets/sound/dir3_v2/s13.mp3",
          basePath + "/assets/sound/dir3_v2/s14.mp3",
          basePath + "/assets/sound/dir3_v2/s15.mp3",
          basePath + "/assets/sound/dir3_v2/s16.mp3",
          basePath + "/assets/sound/dir3_v2/s17.mp3",
          basePath + "/assets/sound/dir3_v2/s18.mp3",
          basePath + "/assets/sound/dir3_v2/s19.mp3",
          basePath + "/assets/sound/dir3_v2/s20.mp3",
          basePath + "/assets/sound/dir3_v2/s21.mp3",
          basePath + "/assets/sound/dir3_v2/s22.mp3",
        ],
      },
    },
  },
  2: {
    0: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question: "1. 這個人想要找什麼？",
          subChoices: [
            {
              text: "電影院",
              img: basePath + "/assets/images/direction2/cinema.png",
              audio: basePath + "/assets/audio/module3/travel/3_1.mp3",
            },
            {
              text: "台北101",
              img: basePath + "/assets/images/direction2/Taipei101.png",
              audio: basePath + "/assets/audio/module3/travel/3_2.mp3",
              isCorrect: true,
            },
            {
              text: "博物館",
              img: basePath + "/assets/images/direction2/museum.png",
              audio: basePath + "/assets/audio/module3/travel/3_4.mp3",
            },
            {
              text: "火車站",
              img: basePath + "/assets/images/direction2/train_station.png",
              audio: basePath + "/assets/audio/module3/travel/3_3.mp3",
            },
          ],
        },
        {
          question: "2. 這個女人會坐什麼到她的目的地？",
          subChoices: [
            {
              text: "地鐵",
              img: basePath + "/assets/images/direction2/station.png",
              audio: basePath + "/assets/audio/module3/travel/3_6.mp3",
              isCorrect: true,
            },
            {
              text: "公車",
              img: basePath + "/assets/images/direction2/bus.png",
              audio: basePath + "/assets/audio/module3/travel/3_5.mp3",
            },
            {
              text: "出租車",
              img: basePath + "/assets/images/direction2/taxi.png",
              audio: basePath + "/assets/audio/module3/travel/3_7.mp3",
            },
          ],
        },
        {
          question: "3. 從她的宿舍到地鐵站，那個女人要往哪個方向走？",
          subChoices: [
            {
              text: "右轉",
              img: basePath + "/assets/images/direction2/right.png",
              audio: basePath + "/assets/audio/module3/travel/3-11.mp3",
              isCorrect: true,
            },
            {
              text: "左轉",
              img: basePath + "/assets/images/direction2/left.png",
              audio: basePath + "/assets/audio/module3/travel/3_8.mp3",
            },
            {
              text: "直走",
              img: basePath + "/assets/images/direction2/straight.png",
              audio: basePath + "/assets/audio/module3/travel/3-10.mp3",
            },
            {
              text: "對街",
              img: basePath + "/assets/images/direction2/across.png",
              audio: basePath + "/assets/audio/module3/travel/3_9.mp3",
            },
          ],
        },
      ],
    },
    1: {
      q: "拖曳下列句子重組。",
      altQ: ["Arrange the following sentences in the correct order."],
      type: "arrange-audio-clips",
      choices: [
        {
          text: "搭藍線地鐵到市政府站",
          textS: "搭蓝线地铁到市政府站",
          id: 0,
          audio:
            basePath + "/assets/audio/module3/travel/audio3_2_0.mp3",
        },
        {
          text: "在出口三出來",
          textS: "在出口三出来",
          id: 1,
          audio:
            basePath + "/assets/audio/module3/travel/audio3_2_1.mp3",
        },
        {
          text: "之後往松智路一直走",
          textS: "之後往松智路一直走",
          id: 2,
          audio:
            basePath + "/assets/audio/module3/travel/audio3_2_2.mp3",
        },
      ],
      answerKey: [0, 1, 2],
    },
    2: {
      q:
        "Make a recording to describe the directions in the video and submit it to your teacher.",
      type: "recording",
      choices: [],
    },
    3: {
      q:
        "Match the blanks. Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "出口",
          textS: "出口",
          audio: basePath + "/assets/audio/module3/travel/audio4_0.mp3",
        },
        {
          text: "宿舍",
          textS: "宿舍",
          audio: basePath + "/assets/audio/module3/travel/audio4_1.mp3",
        },
        {
          text: "大樓",
          textS: "大楼",
          audio: basePath + "/assets/audio/module3/travel/audio4_2.mp3",
        },
        {
          text: "路",
          textS: "路",
          audio: basePath + "/assets/audio/module3/travel/audio4_3.mp3",
        },
      ],
      answers: ["出口", "宿舍", "大樓", "路"],
      answersS: ["出口", "宿舍", "大楼", "路"],
      images: [
        basePath + "/assets/images/direction2/exit_sign.jpg",
        basePath + "/assets/images/direction2/dorm.jpg",
        basePath + "/assets/images/direction2/building.jpg",
        basePath + "/assets/images/direction2/road.jpg",
      ],
    },
    4: {
      q: "Click an underlined word to see the meaning and hear the sound.",
      type: "literacy",
      audio: basePath + "/assets/audio/module3/travel/direction_travel.mp3",
      choices: [
        {
          A: "你*要**出門*啊？",
          AS: "你*要**出门*啊？",
          Tooltip: ["yao4, are going to; about to", "chu1 men2, going out"],
          English: "Are you going out?",
          Pinyin: "Nǐ yào chūmén a",
        },
        {
          B: "*對啊*，我*想去**台北一 ○ 一**逛逛*。你*知道**要**怎麼走嗎*？",
          BS: "*对啊*，我*想去**台北一 ○ 一**逛逛*。你*知道**要**怎么走吗*？",
          Tooltip: [
            "dui4 ya, That’s right.",
            "xiang3 qu4, want to go to",
            "tai2 bei3 yi1 ling2 yi1, the Taipei 101 Building (台北, Taipei)",
            "guang4 guang, to visit (a place)",
            "zhi1 dao4, to know",
            "yao4, need to",
            "How to get there?",
          ],
          English:
            "That’s right. I want to go to visit Taipei 101 Building. Do you know how to get there?",
          Pinyin:
            "Duì a, wǒ xiǎng qù táiběi yī ￮ yī guàng guàng. Nǐ zhīdào yào zěnme zǒu ma?",
        },
        {
          A:
            "你*就**搭**藍線*地鐵*到**市政府*站，*在**出口三**出來*之後，*往**松智路**一直走*，*你就會**在右手邊**看到*台北一零一。",
          AS:
            "你*就**搭**蓝线*地铁*到**市政府*站，*在**出口三**出来*之後，*往**松智路**一直走*，*你就会**在右手边**看到*台北一零一。",
          Tooltip: [
            "jiu4, just",
            "da1 di4 tie3, take the subway",
            "lan2 xian4, the blue line",
            "dao4… zhan4, arrive… station",
            "shi4 zheng4 fu3, City Hall",
            "zai4…zhi1 hou4, after…",
            "chu1 kou3 san1, Exit 3",
            "chu1 lai2, go out",
            "wang3, toward",
            "song1 zhi4 lu4, Song Zhi road (name of the road)",
            "yi4 zhi2 zou3, go straight",
            "ni3 jiu4 hui4, then you will",
            "zai4 you4 shou3 bian1, on your right-hand side",
            "kan4 dao4, to see",
          ],
          English:
            "You just need to take the blue line subway to City Hall station. Go out from Exit #3. After you go out, you will walk along the Song Zhi road.",
          Pinyin:
            "Nǐ jiù dā lán xiàn dìtiě dào shì zhèngfǔ zhàn, zài chūkǒu sān chūlái zhīhòu, wǎng sōng zhì lù yīzhí zǒu, nǐ jiù huì zài yòushǒu biān kàn dào táiběi yī líng yī.",
        },
        {
          B: "*那*我知道了，謝謝。",
          BS: "*那*我知道了，谢谢。",
          Tooltip: ["na4, then"],
          English: "I see. Thank you.",
          Pinyin: "Nà wǒ zhīdàole, xièxiè.",
        },
        {
          A: "*不會*。",
          AS: "*不会*。",
          Tooltip: ["不會, You are welcome."],
          English: "You are welcome.",
          Pinyin: "Bù huì。",
        },
        {
          B: "你知道去*哪裡*搭地鐵嗎？",
          BS: "你知道去*哪里*搭地铁吗？",
          Tooltip: ["na3 li3, where"],
          English: "Do you know where I can take the subway?",
          Pinyin: "Nǐ zhīdào qù nǎlǐ dā dìtiě ma?",
        },
        {
          A: "*宿舍**下去**右轉*你就會看到一個地鐵站。",
          AS: "*宿舍**下去**右转*你就会看到一个地铁站。",
          Tooltip: [
            "su4 she4, dormitory",
            "xia4 qu4, go down",
            "you4 zhuan3, make a right turn",
          ],
          English:
            "Go downstairs from the dormitory. Make a right turn. You will see a subway station right there.",
          Pinyin: "Sùshè xiàqù yòu zhuǎn nǐ jiù huì kàn dào yīgè dìtiě zhàn.",
        },
        {
          B: "噢，好，謝謝。",
          BS: "噢，好，谢谢。",
          Tooltip: [],
          English: "OK. Thank you.",
          Pinyin: "Ō, hǎo, xièxiè.",
        },
        {
          A: "*不客氣*。",
          AS: "*不客气*。",
          Tooltip: ["不客氣 bu2 ke4 qi, You are welcome."],
          English: "You are welcome.",
          Pinyin: "Bù kèqì.",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/dir3_v3/s1.mp3",
          basePath + "/assets/sound/dir3_v3/s2.mp3",
          basePath + "/assets/sound/dir3_v3/s3.mp3",
          basePath + "/assets/sound/dir3_v3/s4.mp3",
          basePath + "/assets/sound/dir3_v3/s5.mp3",
          basePath + "/assets/sound/dir3_v3/s6.mp3",
          basePath + "/assets/sound/dir3_v3/s7.mp3",
          basePath + "/assets/sound/dir3_v3/s8.mp3",
          basePath + "/assets/sound/dir3_v3/s9.mp3",
          basePath + "/assets/sound/dir3_v3/s10.mp3",
          basePath + "/assets/sound/dir3_v3/s11.mp3",
          basePath + "/assets/sound/dir3_v3/s12.mp3",
          basePath + "/assets/sound/dir3_v3/s13.mp3",
          basePath + "/assets/sound/dir3_v3/s14.mp3",
          basePath + "/assets/sound/dir3_v3/s15.mp3",
          basePath + "/assets/sound/dir3_v3/s16.mp3",
          basePath + "/assets/sound/dir3_v3/s17.mp3",
          basePath + "/assets/sound/dir3_v3/s18.mp3",
          basePath + "/assets/sound/dir3_v3/s19.mp3",
          basePath + "/assets/sound/dir3_v3/s20.mp3",
          basePath + "/assets/sound/dir3_v3/s21.mp3",
          basePath + "/assets/sound/dir3_v3/s22.mp3",
          basePath + "/assets/sound/dir3_v3/s23.mp3",
          basePath + "/assets/sound/dir3_v3/s24.mp3",
          basePath + "/assets/sound/dir3_v3/s25.mp3",
          basePath + "/assets/sound/dir3_v3/s26.mp3",
          basePath + "/assets/sound/dir3_v3/s27.mp3",
          basePath + "/assets/sound/dir3_v3/s28.mp3",
          basePath + "/assets/sound/dir3_v3/s29.mp3",
          basePath + "/assets/sound/dir3_v3/s30.mp3",
        ],
      },
    },
    5: {
      q:
        "Find the compound words by dragging the character to the corresponding box.",
      type: "word puzzle",
      choices: [
        {
          english: "train ",
          simplified: "火车",
          traditional: "火車",
        },
        {
          english: "restaurant",
          simplified: "餐厅",
          traditional: "餐廳",
        },
        {
          english: "subway",
          simplified: "地铁",
          traditional: "地鐵",
        },
        {
          english: "Taibei",
          simplified: "台北",
          traditional: "台北",
        },
        {
          english: "City Hall",
          simplified: "市政府",
          traditional: "市政府",
        },
        {
          english: "movie",
          simplified: "电影",
          traditional: "電影",
        },
        {
          english: "across the street",
          simplified: "对面",
          traditional: "對面",
        },
        {
          english: "intersection",
          simplified: "路口",
          traditional: "路口",
        },
        {
          english: "to rotate around",
          simplified: "绕圈",
          traditional: "繞圈",
        },
        {
          simplified: "银行斜对面院站政",
          traditional: "銀行斜對面院站政",
        },
      ],
    },
    6: {
      q:
        "Study the character's meaning, pinyin, radical, strokes, and compound word.",
      type: "analysis",
      choices: [
        {
          traditional: "銀",
          simplified: "银",
          pinyin: "yin2",
          meaning: "silver",
          strokesT: "14",
          strokesS: "11",
          radicalT: "金 jin1, gold",
          radicalS: "金 jin1, gold",
          wordT: "銀行 yin2hang2, a bank",
          wordS: "银行 yin2hang2, a bank",
          etymology:
            "The meaning component is 金 jīn metal. The sound component is 艮 gěn.",
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_traditional_1.gif",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_simplified_1.gif",
          audio: basePath + "/assets/sound/characters/yin2.mp3",
          seal: basePath + "/assets/images/direction2_literacy_activity2/seal_1.png",
        },
        {
          traditional: "行",
          simplified: "行",
          pinyin: "hang2",
          meaning: "to walk; profession",
          strokesT: "6",
          strokesS: "6",
          radicalT: "行 hang2, walk",
          radicalS: "行 hang2, walk",
          wordT: "行為 xing2wei2, behavior",
          wordS: "行为 xing2wei2, behavior",
          etymology:
            "The meaning of walk is suggested by a pictograph of two footprints, side by side.",
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_2.gif",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_2.gif",
          audio: basePath + "/assets/sound/characters/hang2.mp3",
          seal: basePath + "/assets/images/direction2_literacy_activity2/seal_2.png",
        },
        {
          traditional: "火",
          simplified: "火",
          pinyin: "huo3",
          meaning: "fire",
          strokesT: "4",
          strokesS: "4",
          radicalT: "火 huo3, fire",
          radicalS: "火 huo3, fire",
          wordT: "水火不容 shui3huo3bu4rong2, incompatible",
          wordS: "水火不容 shui3huo3bu4rong2, incompatible",
          etymology: "Pictograph of flames.",
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_3.gif",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_3.gif",
          audio: basePath + "/assets/sound/characters/huo3.mp3",
          seal: basePath + "/assets/images/direction2_literacy_activity2/seal_3.png",
        },
        {
          traditional: "車",
          simplified: "车",
          pinyin: "che1",
          meaning: "vehicle",
          strokesT: "7",
          strokesS: "4",
          radicalT: "車 che1, vehicle",
          radicalS: "车 che1, vehicle",
          wordT: "公車 gong1che1, bus",
          wordS: "公车 gong1che1, bus",
          etymology: "Pictograph of a chariot, viewed from the top.",
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_traditional_4.gif",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_simplified_4.gif",
          audio: basePath + "/assets/sound/characters/che1.mp3",
          seal: basePath + "/assets/images/direction2_literacy_activity2/seal_4.png",
        },
        {
          traditional: "站",
          simplified: "站",
          pinyin: "zhan4",
          meaning: "to stand",
          strokesT: "10",
          strokesS: "10",
          radicalT: "立 li4, stand",
          radicalS: "立 li4, stand",
          wordT: "車站 che1zhan4, bus station",
          wordS: "车站 che1zhan4, bus station",
          etymology:
            "The meaning component is 立 lì stand. The sound component is 占 zhàn.",
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_5.gif",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_5.gif",
          audio: basePath + "/assets/sound/characters/zhan4.mp3",
          seal: basePath + "/assets/images/direction2_literacy_activity2/seal_5.png",
        },
        {
          traditional: "快",
          simplified: "快",
          pinyin: "kuai4",
          meaning: "fast",
          strokesT: "7",
          strokesS: "7",
          radicalT: "心 xin1, heart",
          radicalS: "心 xin1, heart",
          wordT: "快車 kuai4che1, express train or bus",
          wordS: "快车 kuai4che1, express train or bus",
          etymology:
            "The meaning component is 忄xīn heart. The sound component is 夬 jué (not a common character).",
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_6.gif",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_6.gif",
          audio: basePath + "/assets/sound/characters/kuai4.mp3",
          seal: basePath + "/assets/images/direction2_literacy_activity2/seal_6.png",
        },
        {
          traditional: "地",
          simplified: "地",
          pinyin: "di4",
          meaning: "earth; ground",
          strokesT: "6",
          strokesS: "6",
          radicalT: "土 tu3, earth",
          radicalS: "土 tu3, earth",
          wordT: "地方 di4fang1, place",
          wordS: "地方 di4fang1, place",
          etymology:
            "The meaning component is 土 tǔ. The sound component is 也 tā.",
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_7.gif",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_7.gif",
          audio: basePath + "/assets/sound/characters/di4.mp3",
          seal: basePath + "/assets/images/direction2_literacy_activity2/seal_7.png",
        },
        {
          traditional: "鐵",
          simplified: "铁",
          pinyin: "tie3",
          meaning: "iron",
          strokesT: "21",
          strokesS: "10",
          radicalT: "金 jin1, gold",
          radicalS: "金 jin1, gold",
          wordT: "鐵路 tie3lu4, railroad",
          wordS: "铁路 tie3lu4, railroad",
          etymology:
            "The meaning component is 金 jīn metal. The sound component is 𢧤 zhì.",
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_traditional_8.gif",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_simplified_8.gif",
          audio: basePath + "/assets/sound/characters/tie3.mp3",
          seal: basePath + "/assets/images/direction2_literacy_activity2/seal_8.png",
        },
        {
          traditional: "台",
          simplified: "台",
          pinyin: "tai2",
          meaning: "stage; platform",
          strokesT: "5",
          strokesS: "5",
          radicalT: "口 kou3, mouth",
          radicalS: "口 kou3, mouth",
          wordT: "電台 dian4tai2, television station",
          wordS: "电台 dian4tai2, television station",
          etymology:
            "The original form of 台 is 怡 yí. The meaning component is 口 kǒu. The sound component is 㠯 yǐ. In traditional form characters, 臺 is sometimes used in place of 台.",
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_9.gif",
          audio: basePath + "/assets/sound/characters/tai2.mp3",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_9.gif",
        },
        {
          traditional: "北",
          simplified: "北",
          pinyin: "bei3",
          meaning: "morth",
          strokesT: "5",
          strokesS: "5",
          radicalT: "匕 bi3, spoon",
          radicalS: "匕 bi3, spoon",
          wordT: "北方 bei3fang1, the north",
          wordS: "北方 bei3fang1, the north",
          etymology:
            "The original meaning of 北 is opposite. A pictograph of two people sitting back-to-back. Borrowed from north.",
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_10.gif",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_10.gif",
          audio: basePath + "/assets/sound/characters/bei3.mp3",
          seal: basePath + "/assets/images/direction2_literacy_activity2/seal_10.png",
        },
        {
          traditional: "市",
          simplified: "市",
          pinyin: "shi4",
          meaning: "market; city",
          strokesT: "5",
          strokesS: "5",
          radicalT: "巾 jin1, cloth",
          radicalS: "巾 jin1, cloth",
          wordT: "市長 shi4zhang3, mayor",
          wordS: "市长 shi4zhang3, mayor",
          etymology:
            'The meaning of marketplace is represented by 冂 jiōng border surrounding a symbol. "An enclosed space for buying, selling, and trading.',
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_11.gif",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_11.gif",
          audio: basePath + "/assets/sound/characters/shi4.mp3",
          seal: basePath + "/assets/images/direction2_literacy_activity2/seal_11.png",
        },
        {
          traditional: "政",
          simplified: "政",
          pinyin: "zheng4",
          meaning: "govern",
          strokesT: "10",
          strokesS: "10",
          radicalT: "攵 pu1, strike",
          radicalS: "攵 pu1, strike",
          wordT: "行政部門 xing2zheng4bu4men2, administrative department",
          wordS: "行政部门 xing2zheng4bu4men2, administrative department",
          etymology:
            "The meaning component is 攵 pū strike. The sound component is 正 zhèng.",
          strokeOrderT: basePath + "/assets/gifs/dir_2/directions2_12.gif",
          strokeOrderS: basePath + "/assets/gifs/dir_2/directions2_12.gif",
          audio: basePath + "/assets/sound/characters/zheng4.mp3",
          seal: basePath + "/assets/images/direction2_literacy_activity2/seal_12.png",
        },
      ],
    },
    7: {
      q:
        "Fill in the table by dragging and dropping the answers below into the corresponding boxes.",
      type: "analysis-learning",
      choices: [
        {
          traditional: "快",
          simplified: "快",
          pinyin: "kuai4",
          meaning: "fast",
          strokesT: "7",
          strokesS: "7",
          radicalT: "心 xin1, heart",
          radicalS: "心 xin1, heart",
        },
        {
          traditional: "銀",
          simplified: "银",
          pinyin: "yin2",
          meaning: "silver",
          strokesT: "14",
          strokesS: "11",
          radicalT: "金 jin1, gold",
          radicalS: "金 jin1, gold",
        },
        {
          traditional: "站",
          simplified: "站",
          pinyin: "zhan4",
          meaning: "to stand",
          strokesT: "10",
          strokesS: "10",
          radicalT: "立 li4, stand",
          radicalS: "立 li4, stand",
        },
        {
          traditional: "市",
          simplified: "市",
          pinyin: "shi4",
          meaning: "market; city",
          strokesT: "5",
          strokesS: "5",
          radicalT: "巾 jin1, cloth",
          radicalS: "巾 jin1, cloth",
        },
        {
          traditional: "車",
          simplified: "车",
          pinyin: "che1",
          meaning: "vehicle",
          strokesT: "7",
          strokesS: "4",
          radicalT: "車 che1, vehicle",
          radicalS: "车 che1, vehicle",
        },
      ],
    },
  },
  3: {
    0: {
      q: "What is the person trying to find?",
      type: "single",
      choices: [
        {
          text: "電影院",
          img: basePath + "/assets/images/direction2/cinema.jpg",
          audio: basePath + "/assets/audio/module3/car/1_2.mp3",
          isCorrect: true,
        },
        {
          text: "餐廳",
          img: basePath + "/assets/images/direction2/restaurant.png",
          audio: basePath + "/assets/audio/module3/car/1_1.mp3",
        },
        {
          text: "宿舍",
          img: basePath + "/assets/images/direction2/dorm_room.jpg",
          audio: basePath + "/assets/audio/module3/car/1_3.mp3",
        },
        {
          text: "體育館",
          img: basePath + "/assets/images/direction2/stadium.jpg",
          audio: basePath + "/assets/audio/module3/car/1_4.mp3",
        },
      ],
    },
    1: {
      q: "Which of the following is correct?",
      type: "single",
      img: basePath + "/assets/images/direction2/video4_task2.PNG",
      choices: [
        {
          text: "Red Route",
        },
        {
          text: "Green Route",
        },
        {
          text: "Blue Route",
          isCorrect: true,
        },
      ],
    },
    2: {
      q:
        "Listen to the following audio between a taxi driver and passenger in Chinese. After listening to the audio, record your reponse to each of the questions below and submit/e-mail to your instructor.",
      type: "recording",
      mainAudio: basePath + "/assets/audio/module3/car/taxi_3.mp3",
      choices: [
        {
          q: "Where is the customer?",
        },
        {
          q: "Within how many minutes does the customer need to arrive?",
        },
        {
          q: "How much is the fare?",
        },
        {
          q: "What is the driver's warning in the end?",
        },
      ],
    },
    3: {
      q:
        "You are a tourist in Taipei. You will tell the taxi driver the directions from Songshan train station to Taipei City Hall in Chinese. Make a recording of the directions and submit it to your teacher.",
      type: "recording",
      img:
        basePath + "/assets/images/module3/car/taipeiMap.png",
      choices: [],
    },
  },
};
