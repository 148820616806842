const replaceParamKeys = (routeParams, match, initialValue) =>
  routeParams
    .reduce(
      (accum, paramKey) =>
        accum.replace(`:${paramKey}`, match.params[paramKey]),
      initialValue
    )
    .replace("(chengyu|phrase)", match.params[0]);

export const getBreadcrumbRoutes = (match, customNames) => {
  const routeParams = Object.keys(match.params);
  customNames = customNames || [];

  let res = routes
    .filter(({ path }) => match.path.includes(path))
    .map(({ path, name, ...rest }, i) => {
      customNames[0] = routeNames[match.params[0]];
      return {
        path: routeParams.length
          ? replaceParamKeys(routeParams, match, path)
          : path,
        name: routeParams.length
          ? customNames && customNames[i]
            ? customNames[i]
            : routeNames[replaceParamKeys(routeParams, match, name)] ||
              replaceParamKeys(routeParams, match, name)
          : customNames && customNames[i]
          ? customNames[i]
          : name,
        ...rest,
      };
    });
  return res;
};

const routeNames = {
  triptochina: "Trip to China",
  chengyu: "Chengyu",
  phrase: "Phrase",
};

export const routes = [
  { path: "/(chengyu|phrase)/", name: "Chengyu" },
  { path: "/(chengyu|phrase)/:lesson", name: ":lesson" },
  {
    path: "/(chengyu|phrase)/:lesson/group/:groupId/",
    name: " Group :groupId",
  },
  {
    path: "/(chengyu|phrase)/:lesson/group/:groupId/practice",
    name: "Practice",
  },
  {
    path: "/(chengyu|phrase)/:lesson/group/:groupId/vocabulary",
    name: "Vocabulary",
  },
  {
    path: "/(chengyu|phrase)/:lesson/group/:groupId/vocabulary/:wordId",
    name: "Word :wordId",
  },
  {
    path: "/(chengyu|phrase)/:lesson/group/:groupId/practice/:practiceId",
    name: "# :practiceId",
  },
  {
    path: "/(chengyu|phrase)/:lesson/vocabularylist",
    name: "Vocabulary List",
  },
  {
    path: "/(chengyu|phrase)/:lesson/bookmarks",
    name: "Bookmarks",
  },
];
