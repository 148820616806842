import React from 'react';

export default function CorrectIcon() {
  return (
    <svg viewBox="0 0 26 21" fill="#23b26d">
      <path
        fillRule="evenodd"
        d="M8.313 16.055L2.218 9.96l-2.11 2.05 8.204 8.204L25.89 2.637 23.84.586z"
      />
    </svg>
  );
}
