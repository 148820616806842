import { useLocation } from "react-router";

export const getQueryParams = (searchParams) => {
  const language = searchParams.get("language");
  return { language };
};

export const buildQueryString = (params) => {
  const querysString = Object.entries(params).reduce((url, [key, val]) => {
    return val === undefined ? url : url + key + "=" + val + "&";
  }, "");
  return querysString.slice(0, -1);
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getRandomSample = (arr, n) => {
  let result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    let x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

export const generateChoices = (word, allOptions, amount) => {
  const copy = allOptions.slice(0);
  const correctAnswer = word.answer;
  let correctIndex = copy.indexOf(correctAnswer);
  copy.splice(correctIndex, 1);
  let choices = getRandomSample(copy, amount);
  let randomIndex = Math.floor(Math.random() * choices.length);
  choices.splice(randomIndex, 0, correctAnswer);
  return choices;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
