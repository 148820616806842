import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Chinese from "chinese-s2t";
import OpenCC from "node-opencc";

/* eslint-disable no-unused-expressions */

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column @media (orientation: landscape) {
    font-size: 1.25vw;
  }
  @media (orientation: portrait) {
    font-size: 3.5vw;
  }
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 25px;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 25px;
`;

const StyledImage = styled.img`
  position: relative;
  width: 100%;
  z-index: 0;
`;

const ImageContainer = styled.div`
  display: flex;
  position: relative;
  @media (orientation: landscape) {
    height: 300;
    width: 40vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 60vw;
  }
`;

const StyledText = styled.div`
  display: flex;
  position: absolute;
  top: 24%;
  left: 2%;
  z-index: 10;
  @media (orientation: landscape) {
    font-size: 1.25vw;
  }
  @media (orientation: portrait) {
    font-size: 3vmax;
  }
`;

const AnswerChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  @media (orientation: landscape) {
    font-size: 1.25vw;
  }
  @media (orientation: portrait) {
    font-size: 3vmax;
  }
`;

function Ordering({ question, choices, mainimg, nextLink, simplified }) {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState("Next");
  const [answerList, setAnswerList] = useState("");

  /*
   * gets selected answer value based on radio click
   * @param {HTML element} e
   */
  const checkAnswer = (e) => {
    setSelectedAnswer(e.target.value);
  };

  const handleStatus = () => {
    switch (status) {
      case "Next":
        if (current + 1 < choices.length) {
          setCurrent(current + 1);
          setSelectedAnswer("");
          const temp = choices[current].subChoices[selectedAnswer] + answerList;
          setAnswerList(temp);
        } else {
          window.location.href = nextLink;
        }
        break;
      default:
        break;
    }
  };

  let ImageText = choices[current].text;
  if (current === choices.length - 1) {
    ImageText += answerList;
  }

  return (
    <React.Fragment>
      <QuestionContainer>
        <StyledQuestion>{question}</StyledQuestion>
      </QuestionContainer>

      <ImageContainer>
        <StyledImage alt="loading..." src={mainimg} />
        <StyledText>
          {simplified
            ? choices[current].text
            : OpenCC.simplifiedToTraditional(choices[current].text)}
          <div style={{ color: "red" }}>
            {current === choices.length - 1 ? answerList : null}
          </div>
        </StyledText>
      </ImageContainer>
      <StyledContainer>
        <RadioGroup
          value={selectedAnswer.toString()}
          onChange={(e) => checkAnswer(e)}
        >
          {choices[current].subChoices.map((choice, index) => (
            <FormControlLabel
              key={index}
              value={index.toString()}
              control={<Radio />}
              label={
                <AnswerChoiceContainer>
                  {simplified ? choice : OpenCC.simplifiedToTraditional(choice)}
                </AnswerChoiceContainer>
              }
            />
          ))}
        </RadioGroup>
        <Button
          style={{ alignSelf: "center", marginTop: 10 }}
          variant="contained"
          color="secondary"
          onClick={() => {
            selectedAnswer === "" && choices.length - 1 !== current
              ? null
              : handleStatus();
          }}
        >
          {status}
        </Button>
      </StyledContainer>
    </React.Fragment>
  );
}

export default Ordering;
