import React, { Component } from "react";
import MultipleChoiceProblem from "../Problems/MultipleChoiceProblem";
import { withRouter } from "react-router-dom";
import { isEqual } from "lodash";
import ProblemSet from "./ProblemSet";
import { generateChoices } from "../helpers.js";

class MultipleChoiceProblemSet extends Component {
  constructor(props) {
    super(props);
    this.practice = React.createRef();
    this.state = {
      problemSet: [],
    };
  }
  componentDidMount = () => {
    this.initializeProblemSet();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.language !== this.props.language ||
      !isEqual(prevProps.problemSet, this.props.problemSet)
    ) {
      this.initializeProblemSet();
    }
  }

  initializeProblemSet = () => {
    const { groupId } = this.props.match.params;
    const { problemSet } = this.props;
    const allOptions = problemSet.map((qa) => qa.answer);
    this.setState({ problemSet, allOptions, continue: false });
  };

  render() {
    const { allOptions, problemSet } = this.state;
    const { groupId } = this.props.match.params;
    const { language, returnRoute } = this.props;

    const questionAndOptions = problemSet.map((qa) => {
      return {
        question: qa.question,
        options: generateChoices(qa, allOptions, 3),
        correctAnswer: qa.answer,
      };
    });

    const allProblems = questionAndOptions.map((q) => {
      return (
        <MultipleChoiceProblem
          {...q}
          updateScore={this.practice.current.updateScore}
          toggleNext={this.practice.current.allowNext}
        />
      );
    });

    const rootRoute = this.props.match.path.split("/")[1];
    return (
      <div>
        <ProblemSet
          allProblems={allProblems}
          ref={this.practice}
          returnRoute={returnRoute}
        />
      </div>
    );
  }
}

export default withRouter(MultipleChoiceProblemSet);
