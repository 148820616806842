import React, { Component } from "react";
import data from "../../../data/chengyu/normalized.js";
import { withRouter } from "react-router-dom";
import CharFillBlankProblem from "../Problems/CharFillBlankProblem";
import { isEqual } from "lodash";
import ProblemSet from "./ProblemSet.jsx";

class CharacterFillInTheBlankPractice extends Component {
  constructor(props) {
    super(props);
    this.practice = React.createRef();
    this.state = {
      displayProblems: [],
      submittedAnswers: [],
      correct: null,
      questionNumber: 0,
    };
  }
  componentDidMount = () => {
    this.generateAllQuestions();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.language !== this.props.language ||
      !isEqual(prevProps.problemSet, this.props.problemSet)
    ) {
      this.generateAllQuestions();
    }
  };

  generateAllQuestions = () => {
    const { groupId, lesson } = this.props.match.params;
    const dataName = this.props.match.params[0];
    const { language } = this.props;
    const problemSet =
      data[dataName][lesson].qaData.chengyuPractice4Data[language][groupId];
    const displayProblems = problemSet.map((qa) => {
      return (
        <CharFillBlankProblem
          question={qa.question}
          answer={qa.answer}
          updateScore={this.practice.current.updateScore}
          toggleNext={this.practice.current.allowNext}
        />
      );
    });
    this.setState({ displayProblems });
    return displayProblems;
  };
  render() {
    const { displayProblems } = this.state;
    const { groupId } = this.props.match.params;
    const { language, returnRoute } = this.props;
    const rootRoute = this.props.match.path.split("/")[1];
    return (
      <div>
        <ProblemSet
          allProblems={displayProblems}
          ref={this.practice}
          returnRoute={returnRoute}
        />
      </div>
    );
  }
}

export default withRouter(CharacterFillInTheBlankPractice);
