import React, { useCallback, useState } from 'react';

const VideoPlayer = ({ src, captionSrc }) => (
  <div className="content-video">
    <React.Fragment key={src}>
      <video
        controls
        preload="metadata"
      >
        <source
          src={src}
          type="video/mp4"
        />
        <track
          default
          label="English"
          kind="subtitles"
          srcLang="en"
          src={captionSrc}
        />
        <track
          default
          label="English"
          kind="captions"
          srcLang="en"
          src={captionSrc}
        />
      </video>
    </React.Fragment>
  </div>
);

export default VideoPlayer;
