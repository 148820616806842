import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Timer from "react-compound-timer";
import { shuffleArray } from "../helpers.js";
import FillBlankProblem from "../Problems/FillBlankProblem";

class PinyinFillInTheBlankPractice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      problemSet: this.props.problemSet,
      showAnswers: false,
      quiz: [],
      score: 0,
    };
  }
  componentDidMount() {
    this.initializeForm();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.initializeForm();
    }
  }
  initializeForm = () => {
    const problemSet = shuffleArray(this.props.problemSet);
    this.setState({ problemSet });
  };

  checkAll = () => this.setState({ showAnswers: true });

  resetQuiz = () => {
    this.initializeForm();
    this.setState({ showAnswers: false });
  };
  updateScore = () => {
    this.setState({ score: this.state.score + 1 });
  };

  generateQuiz = () => {
    return this.state.problemSet.map((qa, i) => {
      return (
        <FillBlankProblem
          key={i}
          question={qa.question}
          answer={qa.answer}
          showAnswer={this.state.showAnswers}
          updateScore={this.updateScore}
        />
      );
    });
  };
  render() {
    const quiz = this.generateQuiz();

    return (
      <div style={{ width: "100vw" }}>
        <div>{quiz}</div>
        <Timer initialTime={0}>
          {({ stop, reset, start }) => (
            <React.Fragment>
              <div style={styles.scoreSection}>
                <div style={{ marginTop: "30px" }}>
                  {this.props.returnRoute && (
                    <Link to={this.props.returnRoute}>
                      <Button
                        variant="contained"
                        style={{
                          ...styles.submitButton,
                          backgroundColor: "blue",
                          opacity: "0.6",
                          transition: "0.3s",
                        }}
                      >
                        Back to practices
                      </Button>
                    </Link>
                  )}
                </div>
                <div style={styles.score}>
                  Score:{" "}
                  {this.state.showAnswers &&
                    `${(this.state.score / quiz.length) * 100}%`}
                </div>
                <div style={styles.time}>
                  <div>
                    Timer : <Timer.Minutes /> min <Timer.Seconds /> s
                  </div>
                  <div>
                    Date {"&"} Time:{" "}
                    {this.state.showAnswers && new Date().toLocaleString()}
                  </div>
                </div>

                <div style={{ marginTop: "30px" }}>
                  {this.state.showAnswers ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={styles.submitButton}
                      onClick={() => {
                        this.resetQuiz();
                        reset();
                        start();
                      }}
                    >
                      Retry
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={styles.submitButton}
                      onClick={() => {
                        this.checkAll();
                        stop();
                      }}
                    >
                      Check All
                    </Button>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </Timer>
      </div>
    );
  }
}

export default withRouter(PinyinFillInTheBlankPractice);

const styles = {
  quizOuter: {
    display: "flex",
    padding: "10px",
    justifyContent: "center",
    overflow: "none",
  },
  quiz: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  score: {
    fontSize: "50px",
    color: "#1abebb",
    fontWeight: "bold",
    display: "inline-block",
    verticalAlign: "bottom",
  },
  time: { fontSize: "30px", textAlign: "left", color: "#1abebb" },
  scoreSection: {
    margin: "50px 10% 100px 10%",
    display: "flex",
    flexFlow: "wrap-reverse",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "85%",
  },
  number: {
    fontSize: "2em",
    width: "20px",
    marginRight: "10px",
  },
  question: {
    fontSize: "2em",
    lineHeight: "28px",
    wordBreak: "keep-all",
    width: "200px",
  },
  inputStyles: {
    lineHeight: "2em",
    height: "2em",
    marginLeft: "15px",
    marginRight: "30px",
    minWidth: "300px",
  },
  answerIcon: {
    lineHeight: "35px",
    height: "25px",
    width: "50px",
  },
  answer: {
    lineHeight: "35px",
    height: "25px",
    width: "210px",
  },
  submitButton: {
    border: "none",
    color: "white",
    padding: "16px 32px",
    textAlign: "center",
    fontSize: "16px",
    display: "inline-block",
    textDecoration: "none",
    cursor: "pointer",
  },
};
