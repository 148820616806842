import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { useEffect } from "react";
import Chinese from "chinese-s2t";
import PlayAudio from "../PlayAudio";
import openCC from "node-opencc";
const StyledQuestion = styled.h4`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media (orientation: landscape) {
    width: 80%;
  }
  @media (orientation: portrait) {
    width: 100%;
  }
`;
const IconContainer = styled.div`
  width: 12px;
  height: 12px;
  padding: 5px;
`;

const DropInContainer = styled.div`
  border-width : 2px;
  @media (orientation: landscape) {
    width: '20vw'
    height : 100px;
    font-size : 20px;
  }
  @media (orientation: portrait) {
    width : 150px;
    height : 50px;
    font-size : 15px;
  }
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 15px;
  background-color : white;
  border-color : black;
  line-height : 1;
  padding : 20px;
  margin : 2px;
  text-align : center;
  display flex;
  justify-content : center;
  align-items : center;
`;

const DragAndDrop = ({
  question,
  choices,
  nextLink,
  simplified,
  mainAudio,
}) => {
  const [status, setStatus] = useState("Check");
  const [dragSelected, setDragSelected] = useState(0);
  const [dropZone, setDropZone] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [current, setCurrent] = useState([]);

  useEffect(() => {
    reset();
  }, [choices]);

  const handleDrop = () => {
    if (status === "Check" && !answers[dragSelected].chosen) {
      clearEntry(dropZone);
      setCurrent(
        [...current].map((item, index) =>
          index === dropZone ? dragSelected : item
        )
      );
      setAnswers(
        [...answers].map((item, index) => {
          if (index === dragSelected) {
            const temp = item;
            temp.chosen = true;
            return temp;
          }
          return item;
        })
      );
    }
  };

  const randomize = (array) => {
    const tempArray = array;
    let i = array.length;
    let k;
    let temp;
    while (--i > 0) {
      k = Math.floor(Math.random() * (i + 1));
      temp = tempArray[k];
      tempArray[k] = tempArray[i];
      tempArray[i] = temp;
    }
    return tempArray;
  };

  const clearEntry = (index) => {
    if (status === "Check") {
      const clearedEntry = current[index];
      setCurrent([...current].map((item, i) => (index === i ? "" : item)));
      setAnswers(
        [...answers].map((item, index) => {
          if (index === clearedEntry) {
            const temp = item;
            temp.chosen = false;
            return temp;
          }
          return item;
        })
      );
    }
  };

  const reset = useCallback(() => {
    setAnswers(
      randomize(
        choices.map((item) => ({
          text: item.text,
          chosen: false,
          audio: item.audio,
          audioL: item.audioL,
        }))
      )
    );
    setCurrent([...choices].fill(""));
  });

  const handleButton = () => {
    switch (status) {
      case "Check":
        let correct = true;
        choices.map((choice, index) => {
          if (!answers[current[index]]) correct = false;
          else
            correct =
              choice.text === answers[current[index]].text ? correct : false;
        });
        correct ? setStatus("Next") : setStatus("Retry");
        break;
      case "Retry":
        reset();
        setStatus("Check");
        break;
      case "Next":
        window.location.href = nextLink;
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <StyledQuestion>
        {question}
        {mainAudio && (
          <audio controls>
            <source src={mainAudio} type="audio/mp3" />
          </audio>
        )}
      </StyledQuestion>
      <ChoiceContainer>
        <VerticalContainer>
          {answers.map((item, index) => {
            const { chosen } = item;
            const version = simplified
              ? openCC.traditionalToSimplified(item.text)
              : item.text;
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <DragDropContainer
                  targetKey="foo"
                  noDragging={status !== "Check"}
                  onDrag={() => setDragSelected(index)}
                >
                  <DropInContainer
                    style={{
                      borderStyle: "solid",
                      color:
                        chosen && status === "Check" ? "transparent" : "black",
                    }}
                  >
                    <div>
                      {status !== "Check"
                        ? choices[index].translation
                        : version}{" "}
                    </div>
                  </DropInContainer>
                </DragDropContainer>
                {item.audio && <PlayAudio path={item.audio} />}
              </div>
            );
          })}
        </VerticalContainer>
        <VerticalContainer>
          {choices.map((item, index) => {
            let correctColor = "gray";
            if (`${current[index]}`) correctColor = "black";
            if (status !== "Check" && answers[current[index]])
              correctColor =
                item.text === answers[current[index]].text ? "Green" : "Red";
            return (
              <VerticalContainer>
                <span
                  style={{
                    width: "40vw",
                    fontWeight: "bold",
                    fontSize: "20px",
                    lineHeight: "1",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.audioL && <PlayAudio path={item.audioL} />}
                  {item.representative && simplified
                    ? openCC.traditionalToSimplified(item.representative)
                    : item.representative}
                </span>
                <DropTarget
                  targetKey="foo"
                  onHit={() => handleDrop()}
                  onDragEnter={() => setDropZone(index)}
                >
                  <DropInContainer
                    style={{
                      color: correctColor,
                      borderStyle:
                        current[index] || current[index] === 0
                          ? "solid"
                          : "dotted",
                      borderWidth: "2px",
                    }}
                    onClick={() => clearEntry(index)}
                  >
                    <span>
                      {answers[current[index]]
                        ? simplified
                          ? openCC.traditionalToSimplified(
                              answers[current[index]].text
                            )
                          : answers[current[index]].text
                        : simplified
                        ? openCC.traditionalToSimplified(item.translation)
                        : item.translation}
                    </span>
                  </DropInContainer>
                </DropTarget>
              </VerticalContainer>
            );
          })}
        </VerticalContainer>
      </ChoiceContainer>
      <Button
        style={{ margin: "5px" }}
        variant="contained"
        color="secondary"
        onClick={() => handleButton()}
      >
        {status}
      </Button>
    </React.Fragment>
  );
};
export default DragAndDrop;
