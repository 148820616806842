import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledQuestion = styled.h4`
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const SubContainer = styled.div`
  display: flex;
  margin: 10px;
  width: 80vw;
  @media (orientation: portrait) {
    flex-direction: column-reverse;
  }
`;

const PinyinContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
`;

const PinyinChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & :last-child {
    font-family: "Times New Roman";
  }
  @media (orientation: landscape) {
    margin-left: 25px;
  }
  @media (orientation: portrait) {
    margin: 2.5px;
    margin-top: 10px;
  }
`;

const ChineseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (orientation: landscape) {
    font-size: 25px;
    width: 50vw;
  }
  @media (orientation: portrait) {
    font-size: 5vw;
  }
`;

const WordBox = styled.div`
  border: 1px solid black;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1 0;
  @media (orientation: landscape) {
    width: 25vw;
    height: 21vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
`;

const ChineseChoiceContainer = styled.div`
  text-align: center;
  @media (orientation: landscape) {
    font-size: 25px;
    width: 25%;
  }
  @media (orientation: portrait) {
    font-size: 5vw;
    width: 25%;
  }
`;

function RearrangeChinese({
  question,
  choices,
  nextLink,
  simplified,
  taskSpecificParams,
}) {
  const [status, setStatus] = useState("Check");
  const [dragSelected, setDragSelected] = useState(0);
  const [dropZone, setDropZone] = useState(0);
  const [answerList, setAnswerList] = useState([]);
  const [wordBank, setWordBank] = useState([]);
  const [choiceKey, setChoiceKey] = useState([]);
  const [current, setCurrent] = useState(0);
  const [mobileMode, setMobileMode] = useState(false);

  const landscape =
    process.browser && window.matchMedia("(orientation: landscape)").matches;

  /*
   * resets:
   * answerList = [..., choices.length]
   * choiceKey = [..., choices.length]
   * calls generateWordBank function
   */
  const reset = useCallback(() => {
    const emptyAnswerList = [...choices].fill("");
    for (const index in emptyAnswerList) {
      emptyAnswerList[index] = [...choices[index].simplified].fill("");
    }
    setAnswerList(emptyAnswerList);
    setChoiceKey(emptyAnswerList);
    generateWordBank();
  });

  /*
   * resets for mobile mode:
   * maps over answerList/choiceKey and resets only the index corresponding to current
   * calls generateWordBank function
   */
  const resetCurrent = useCallback(() => {
    setAnswerList(
      [...answerList].map((choice, index) =>
        choice.map((item, i) => (index === current ? "" : item))
      )
    );
    setChoiceKey(
      [...choiceKey].map((choice, index) =>
        choice.map((item, i) => (index === current ? "" : item))
      )
    );
    generateWordBank();
  });

  /*
   * useEffect:
   *   If taskSpecificParams.layout === 'mobile':
   *     force mobile layout.
   */
  useEffect(() => {
    reset();
    if (taskSpecificParams !== undefined) {
      if (taskSpecificParams.layout === "mobile") {
        setMobileMode(true);
      }
    }
  }, []);

  /*
   * generateWordBank:
   *   Maps all simplified words to their respective traditional words like such after randomly suffling:
   *     wordBank = [simplified:traditional, simplified:traditional, ...]
   */
  const generateWordBank = () => {
    const words = [];
    const simplifiedBank = [];
    const traditionalBank = [];
    choices.map((choice) => {
      choice.simplified.map((simplifiedWord, i) => {
        simplifiedBank.push(simplifiedWord);
        traditionalBank.push(choice.traditional[i]);
      });
    });
    shuffle(simplifiedBank, traditionalBank);
    for (const i in simplifiedBank) {
      words[simplifiedBank[i]] = traditionalBank[i];
    }
    setWordBank(words);
  };

  /*
   * shuffle:
   *   Randomly shuffles two arrays while maintaining order:
   * @param {array} (a,b) -> arrays to be shuffled
   */
  const shuffle = (a, b) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
      [b[i], b[j]] = [b[j], b[i]];
    }
  };

  /*
   ** Drag And Drop Function
   * Terms:
   *   dropZone: selected/droped destination
   *   dragSelected: selected Item for drop
   *   choiceKey: for checking final correctness
   *   audioList: Audio selection bar
   * If status === Check:
   *   If answerList[dropZone] === '' -> if its not populated:
   *     Map over old answerList, if index === dropZone[0] (row) and i === dropZone[1] (index) we will map over the selected element
   */
  const handleDrop = () => {
    if (status === "Check") {
      setAnswerList(
        [...answerList].map((choice, index) =>
          choice.map((item, i) =>
            index === dropZone[0]
              ? i === dropZone[1]
                ? dragSelected
                : item
              : item
          )
        )
      );
      setChoiceKey(
        [...choiceKey].map((choice, index) =>
          choice.map((item, i) =>
            index === dropZone[0]
              ? i === dropZone[1]
                ? dragSelected[0]
                : item
              : item
          )
        )
      );
    }
  };

  /*
   ** clear index function
   * Clears a certain entry baseed on row and index
   * @param {int} row -> row selected
   * @param {int} number -> index within selected row to be cleared
   */
  const clearEntry = (row, number) => {
    if (status === "Check") {
      setAnswerList(
        [...answerList].map((choice, index) =>
          choice.map((item, i) =>
            index === row ? (i === number ? "" : item) : item
          )
        )
      );
    }
  };

  const handleStatus = () => {
    switch (status) {
      case "Check":
        if (landscape && !mobileMode) {
          const answerKey = [];
          for (const x in choices) {
            answerKey.push(choices[x].simplified.join().replace(/,/g, ""));
          }
          const temp = [];
          for (const x in choiceKey) {
            temp.push(choiceKey[x].join().replace(/,/g, ""));
          }
          temp.join() === answerKey.join()
            ? setStatus("Next")
            : setStatus("Retry");
        } else {
          choices[current].simplified.join() === choiceKey[current].join()
            ? setStatus("Next")
            : setStatus("Retry");
        }
        break;
      case "Retry":
        landscape ? reset() : resetCurrent();
        setStatus("Check");
        break;
      case "Next":
        if ((!landscape || mobileMode) && current + 1 < choices.length) {
          setCurrent(current + 1);
          setStatus("Check");
        } else {
          window.location.href = nextLink;
        }
        break;
      default:
        break;
    }
  };

  return (
    /*
      Code below may seem redundant. There is a bug where a phantom div appears when you try to conditionally render landscape in one go.
    */
    <React.Fragment>
      <Header>
        <StyledQuestion>{question}</StyledQuestion>
      </Header>
      <Container>
        {landscape && !mobileMode ? (
          <PinyinContainer>
            {choices.map((choice, index) => (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {choice.pinyin.map((pinyin, i) => {
                  let textColor = "black";
                  let word = "";
                  if (answerList.length > 0) {
                    word = simplified
                      ? answerList[index][i][0]
                      : answerList[index][i][1];
                    if (status !== "Check") {
                      textColor =
                        answerList[index][i][0] === choices[index].simplified[i]
                          ? "green"
                          : "red";
                    }
                  }
                  return (
                    <PinyinChoiceContainer>
                      <DropTarget
                        targetKey="foo"
                        onHit={() => handleDrop()}
                        onDragEnter={() => setDropZone([index, i])}
                      >
                        <TextField
                          margin="none"
                          disabled
                          InputProps={{
                            style: {
                              width: 45,
                              color: textColor,
                              fontSize: 25,
                            },
                            startAdornment: (
                              <div onClick={() => clearEntry(index, i)}>
                                {word}
                              </div>
                            ),
                          }}
                          variant="outlined"
                        />
                      </DropTarget>
                      <div>{pinyin}</div>
                    </PinyinChoiceContainer>
                  );
                })}
              </div>
            ))}
          </PinyinContainer>
        ) : null}
        {landscape && !mobileMode ? (
          <ChineseContainer style={{ borderLeft: "1px solid black" }}>
            Word Bank
            <WordBox>
              {Object.entries(wordBank).map(([key, value]) => {
                const word = simplified ? key : value;
                return (
                  <ChineseChoiceContainer>
                    <DragDropContainer
                      targetKey="foo"
                      onDrag={() => setDragSelected([key, value])}
                    >
                      {word}
                    </DragDropContainer>
                  </ChineseChoiceContainer>
                );
              })}
            </WordBox>
          </ChineseContainer>
        ) : null}
      </Container>
      <div>
        {!landscape || mobileMode ? (
          <ChineseContainer>
            Word Bank
            <WordBox>
              {Object.entries(wordBank).map(([key, value]) => {
                const word = simplified ? key : value;
                return (
                  <ChineseChoiceContainer>
                    <DragDropContainer
                      targetKey="foo"
                      onDrag={() => setDragSelected([key, value])}
                    >
                      {word}
                    </DragDropContainer>
                  </ChineseChoiceContainer>
                );
              })}
            </WordBox>
          </ChineseContainer>
        ) : null}
        {!landscape || mobileMode ? (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {`Question: ${current + 1}/${choices.length}: ${choices[
                current
              ].pinyin.join("  -  ")}`}{" "}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {choices[current].pinyin.map((pinyin, i) => {
                let textColor = "black";
                let word = "";
                if (answerList.length > 0) {
                  word = simplified
                    ? answerList[current][i][0]
                    : answerList[current][i][1];
                  if (status !== "Check") {
                    textColor =
                      answerList[current][i][0] ===
                      choices[current].simplified[i]
                        ? "green"
                        : "red";
                  }
                }
                return (
                  <PinyinChoiceContainer>
                    <DropTarget
                      targetKey="foo"
                      onHit={() => handleDrop()}
                      onDragEnter={() => setDropZone([current, i])}
                    >
                      <TextField
                        margin="none"
                        disabled
                        InputProps={{
                          style: {
                            width: "7vw",
                            height: "10vw",
                            color: textColor,
                            fontSize: "5vw",
                          },
                          startAdornment: (
                            <div onClick={() => clearEntry(current, i)}>
                              {word}
                            </div>
                          ),
                        }}
                        variant="outlined"
                      />
                    </DropTarget>
                    <div>Component {i + 1}</div>
                  </PinyinChoiceContainer>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
      <Button
        style={{ alignSelf: "center", marginTop: 10 }}
        variant="contained"
        color="secondary"
        onClick={() => handleStatus()}
      >
        {status}
      </Button>
    </React.Fragment>
  );
}

export default RearrangeChinese;
