import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const BreadcrumbLink = styled.span`
  cursor: pointer;
  font-weight: 800;
  font-size: 3.5vmin;
  color: #273b6f;
  &:hover {
    color: purple;
  }
`;
const Breadcrumb = ({ routes, style = {} }) => (
  <div style={styles.breadcrumbContainer}>
    {routes.map((route, i) => (
      <div key={i}>
        {window.location.href.includes("phrase") && i === 0 ? null :
          <>
            <Link to={route.path} style={{ textDecoration: "none" }}>
              <BreadcrumbLink> {route.name}</BreadcrumbLink>
            </Link>
            <span style={{ ...styles.breadcrumbSeparator, ...styles.breadcrumb }}>
              {i < routes.length - 1 ? ">" : ""}
            </span>
          </>
        }
      </div>
    ))}
  </div>
);

export default Breadcrumb;

const styles = {
  breadcrumb: {
    fontWeight: "800",
    fontSize: "3.7vmin",
    color: "#273B6F",
    whiteSpace: "nowrap",
  },
  breadcrumbContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: "25px",
    alignItems: "center",
    marginLeft: "10px",
  },
  breadcrumbSeparator: { margin: "0px 10px", justifyContent: "center" },
};
