import React from "react";
import styled from "styled-components";
import PlayAudio from "./PlayAudio";
import IncorrectIcon from "./icons/IncorrectIcon";
import CorrectIcon from "./icons/CorrectIcon";

const MainContainer = styled.span`
  @media (orientation: landscape) {
    width: 70%;
  }

  @media (orientation: portrait) {
    width: 100%;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const TextContainer = styled.span`
  display: flex;
  justify-content: center;
  width: 90%;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  margin: 0.5vw;
  font-size: 3vh;
  font-weight: 500;
  border-color: #3483eb;
  padding: 10px;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 12px;
`;

const selectedStyle = {
  backgroundColor: "#3483eb",
  color: "whitesmoke",
};

const Choice = ({ handleClick, selected, text, audio, errors }) => {
  let icon;
  switch (errors) {
    case true:
      icon = <CorrectIcon />;
      break;
    case false:
      icon = <IncorrectIcon />;
      break;
    default:
      icon = <></>;
      break;
  }

  return (
    <MainContainer>
      <IconContainer>{icon}</IconContainer>
      <TextContainer
        onClick={() => handleClick()}
        style={selected ? selectedStyle : {}}
      >
        {text}
      </TextContainer>
      {audio && <PlayAudio path={audio} />}
    </MainContainer>
  );
};

export default Choice;
