import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {
  Select, FormControl, MenuItem,
} from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Chinese from 'chinese-s2t';
import CorrectIcon from '../icons/CorrectIcon';
import IncorrectIcon from '../icons/IncorrectIcon';


const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 12px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction : column;
  flex-wrap : wrap;
  align-items : space-around;
  justify-content : center;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;

`;

function DropDown({
  question,
  choices,
  showAnswer,
  answers,
  simplified,
}) {
  const [value, setValue] = useState('0');
  const [currArray, setCurrArray] = useState([]);
  const [errors, setErrors] = useState(false);

  const answerstemp = currArray;
  const handleChange = index => ({ target }) => {
    answerstemp[index] = target.value;
    setCurrArray(answerstemp);
    setValue(`${index} ${target.value}`);
  };

  const checkAnswer = () => {
    setErrors(true);
    if (currArray.join() === answers.join()) {
      showAnswer();
    }
  };

  const retry = () => {
    setErrors(false);
    setCurrArray([]);
  };
  return (
    <React.Fragment>
      <StyledQuestion>
        {question}
      </StyledQuestion>
      <StyledContainer>
        {choices.map((choice, index) => (
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <IconContainer>
              {errors && (currArray[index] === answers[index]
                ? <CorrectIcon /> : <IncorrectIcon />)}
            </IconContainer>
            <div>{`${choice.question} :`}</div>
            <FormControl style={{ minWidth: 160, margin: 5 }} disabled={errors}>
              <Select
                value={currArray[index]}
                onChange={handleChange(index)}
                input={(
                  <OutlinedInput
                    labelWidth={120}
                    name="age"
                    id="outlined-age-simple"
                  />
                )}
              >
                {choice.options.map(ele => (
                  <MenuItem value={ele}>{simplified ? Chinese.t2s(ele) : ele}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ))}
        <div key={value} />
        <br />
      </StyledContainer>
      {errors ? <Button variant="contained" color="secondary" onClick={() => retry()}>Retry</Button>
        : <Button variant="contained" color="secondary" onClick={() => checkAnswer()}>Check</Button>}

    </React.Fragment>
  );
}

export default DropDown;
