const unsplitTTCdata = [
  {
    lesson: "1",
    simplified: "对我来说",
    traditional: "對我來說",
    pinyin: "dui4 wo3 lai2 shuo1",
    englishDefinition: "to me",
    sentenceSimplified: "对我来说，只要天天练习中文，中文一点也不难。",
    sentenceTraditional: "對我來說，只要天天練習中文，中文一點也不難。",
    vocabLink: "/phrase/triptochina/group/1/vocabulary/0",
  },
  {
    lesson: "1",
    simplified: "国际机场",
    traditional: "國際機場",
    pinyin: "guo2 ji4 ji1 chang3",
    englishDefinition: "international airport",
    sentenceSimplified: "我没去过北京国际机场，但是我去过广州机场。",
    sentenceTraditional: "我沒去過北京國際機場，但是我去過廣州機場。",
    vocabLink: "/phrase/triptochina/group/1/vocabulary/1",
  },
  {
    lesson: "1",
    simplified: "通过海关",
    traditional: "通過海關",
    pinyin: "tong1 guo4 hai3 guan1",
    englishDefinition: "pass through customs",
    sentenceSimplified: "我们通过海关的时候很顺利。",
    sentenceTraditional: "我們通過海關的時候很順利。",
    vocabLink: "/phrase/triptochina/group/1/vocabulary/2",
  },
  {
    lesson: "1",
    simplified: "飞机晚点",
    traditional: "飛機晚點",
    pinyin: "fei1 ji1 wan3 dian3",
    englishDefinition: "flight delayed",
    sentenceSimplified: "因为飞机晚点，所以我们等了一个多钟头才上飞机。",
    sentenceTraditional: "因為飛機晚點，所以我們等了一個多鍾頭才上飛機。",
    vocabLink: "/phrase/triptochina/group/1/vocabulary/3",
  },
  {
    lesson: "1",
    simplified: "不好意思",
    traditional: "不好意思",
    pinyin: "bu4 hao3 yi4 si",
    englishDefinition: "feel embarrassed",
    sentenceSimplified: "我不记得他的名字，所以觉得不好意思。",
    sentenceTraditional: "我不記得他的名字，所以覺得不好意思。",
    vocabLink: "/phrase/triptochina/group/1/vocabulary/4",
  },
  {
    lesson: "1",
    simplified: "出租汽车",
    traditional: "出租汽車",
    pinyin: "chu1 zu1 qi4 che1",
    englishDefinition: "taxi",
    sentenceSimplified: "坐出租汽车非常方便，但是很贵。",
    sentenceTraditional: "坐出租汽車非常方便，但是很貴。",
    vocabLink: "/phrase/triptochina/group/1/vocabulary/5",
  },
  {
    lesson: "2",
    simplified: "公共厕所",
    traditional: "公共廁所",
    pinyin: "gong1 gong4 ce4 suo3",
    englishDefinition: "public restroom",
    sentenceSimplified: "一般来说，中国的公共厕所都没有卫生纸。",
    sentenceTraditional: "一般來說，中國的公共廁所都沒有衛生紙。",
    vocabLink: "/phrase/triptochina/group/1/vocabulary/6",
  },
  {
    lesson: "2",
    simplified: "随身携带",
    traditional: "隨身攜帶",
    pinyin: "sui2 shen1 xie2 dai4",
    englishDefinition: "carry with oneself",
    sentenceSimplified: "出门的时候，我会随身携带钱包。",
    sentenceTraditional: "出門的時候，我會隨身攜帶錢包。",
    vocabLink: "/phrase/triptochina/group/1/vocabulary/7",
  },
  {
    lesson: "2",
    simplified: "全天供应",
    traditional: "全天供應",
    pinyin: "quan2 tian1 gong1 ying4",
    englishDefinition: "supply all day",
    sentenceSimplified: "旅馆的热水是全天供应的。",
    sentenceTraditional: "旅館的熱水是全天供應的。",
    vocabLink: "/phrase/triptochina/group/1/vocabulary/8",
  },
  {
    lesson: "3",
    simplified: "报到注册",
    traditional: "報到註冊",
    pinyin: "bao4 dao4 zhu4 ce4",
    englishDefinition: "report one's arrival and registration",
    sentenceSimplified: "你什么时候去报到注册？",
    sentenceTraditional: "你到了北京大學，打算什麽時候去報到注冊？",
    vocabLink: "/phrase/triptochina/group/1/vocabulary/9",
  },
  {
    lesson: "3",
    simplified: "不必担心",
    traditional: "不必擔心",
    pinyin: "bu4 bi4 dan1 xin1",
    englishDefinition: "no need to worry",
    sentenceSimplified: "去中国旅游很安全，不必担心。",
    sentenceTraditional: "去中國旅遊很安全，你不必擔心。",
    vocabLink: "/phrase/triptochina/group/2/vocabulary/0",
  },
  {
    lesson: "3",
    simplified: "愿意帮忙",
    traditional: "願意幫忙",
    pinyin: "yuan4 yi4 bang1 mang2",
    englishDefinition: "willing to help",
    sentenceSimplified: "这里的人都很热心，很愿意帮忙。",
    sentenceTraditional: "這裏的人都很熱心，很願意幫忙。",
    vocabLink: "/phrase/triptochina/group/2/vocabulary/1",
  },
  {
    lesson: "4",
    simplified: "红墙绿瓦",
    traditional: "紅牆綠瓦",
    pinyin: "hong2 qiang2 lv4 wa3",
    englishDefinition: "red wall and green tiles",
    sentenceSimplified: "红墙绿瓦的中式建筑很常见。",
    sentenceTraditional: "紅牆綠瓦的中式建築現在很少看到。",
    vocabLink: "/phrase/triptochina/group/2/vocabulary/2",
  },
  {
    lesson: "4",
    simplified: "中式建筑",
    traditional: "中式建築",
    pinyin: "zhong1 shi4 jian4 zhu4",
    englishDefinition: "Chinese architecture",
    sentenceSimplified: "四合院是中式建筑。",
    sentenceTraditional: "四合院是中式建築。",
    vocabLink: "/phrase/triptochina/group/2/vocabulary/3",
  },
  {
    lesson: "4",
    simplified: "西式建筑",
    traditional: "西式建築",
    pinyin: "xi1 shi4 jian4 zhu4",
    englishDefinition: "western architecture",
    sentenceSimplified: "亨特大学的校舍是西式建筑风格。",
    sentenceTraditional: "亨特大學的校舍是西式建築風格。",
    vocabLink: "/phrase/triptochina/group/2/vocabulary/4",
  },
  {
    lesson: "5",
    simplified: "讨价还价",
    traditional: "討價還價",
    pinyin: "tao3 jia4 huan2 jia4",
    englishDefinition: "bargain",
    sentenceSimplified: "如果不想上当受骗就要讨价还价。",
    sentenceTraditional: "如果不想上當受騙就要討價還價。",
    vocabLink: "/phrase/triptochina/group/2/vocabulary/5",
  },
  {
    lesson: "5",
    simplified: "平等待遇",
    traditional: "平等待遇",
    pinyin: "ping2 deng3 dai4 yu4",
    englishDefinition: "equal treatment",
    sentenceSimplified: "每个人都应该受到平等待遇。",
    sentenceTraditional: "每個人都應該受到平等待遇。",
    vocabLink: "/phrase/triptochina/group/2/vocabulary/6",
  },
  {
    lesson: "5",
    simplified: "公平待遇",
    traditional: "公平待遇",
    pinyin: "gong1 ping2 dai4 yu4",
    englishDefinition: "fair treatment",
    sentenceSimplified: "面对不公平待遇该怎么做？",
    sentenceTraditional: "面對不公平待遇該怎麽做？",
    vocabLink: "/phrase/triptochina/group/2/vocabulary/7",
  },
  {
    lesson: "5",
    simplified: "绝大部分",
    traditional: "絕大部分",
    pinyin: "jue2 da4 bu4 fen4",
    englishDefinition: "majority of",
    sentenceSimplified: "绝大部分的人都受到影响。",
    sentenceTraditional: "絕大部分的人都喜歡買價廉物美的東西。",
    vocabLink: "/phrase/triptochina/group/2/vocabulary/8",
  },
  {
    lesson: "5",
    simplified: "奢侈浪费",
    traditional: "奢侈浪費",
    pinyin: "she1 chi3 lang4 fei4",
    englishDefinition: "extravagant and wasteful",
    sentenceSimplified: "奢侈浪费的行为不可取。",
    sentenceTraditional: "花三千塊錢買一個手機是奢侈浪費的行為。",
    vocabLink: "/phrase/triptochina/group/2/vocabulary/9",
      
  },
  {
    lesson: "6",
    simplified: "不干不净,吃了没病",
    traditional: "不乾不淨,吃了沒病",
    pinyin: "bu4 gan1 bu4 jing4， chi1 le mei2 bing4",
    englishDefinition: "not clean not dirty, won't get sick after eating",
    sentenceSimplified: "你同意不干不净吃了没病的说法吗？",
    sentenceTraditional: "你同意不幹不淨吃了沒病的說法嗎？",
    vocabLink: "/phrase/triptochina/group/3/vocabulary/0",
  },
  {
    lesson: "7",
    simplified: "价廉物美",
    traditional: "價廉物美",
    pinyin: "jia4 lian2 wu4 mei3",
    englishDefinition: "excellent quality at low prices",
    sentenceSimplified: "中国有很多价廉物美的东西。",
    sentenceTraditional: "中國有很多價廉物美的東西。",
    vocabLink: "/phrase/triptochina/group/3/vocabulary/1",
  },
  {
    lesson: "8",
    simplified: "孝顺父母",
    traditional: "孝順父母",
    pinyin: "xiao4 shun4 fu4 mu3",
    englishDefinition: "show filial obedience to one's parents",
    sentenceSimplified: "我们要多关心父母，孝顺父母。",
    sentenceTraditional: "我們要多關心父母，孝順父母。",
    vocabLink: "/phrase/triptochina/group/3/vocabulary/2",
  },
  {
    lesson: "8",
    simplified: "尊敬师长",
    traditional: "尊敬師長",
    pinyin: "zun1 jing4 shi1 zhang3",
    englishDefinition: "respect teachers and elderly",
    sentenceSimplified: "中国人都很尊敬师长。",
    sentenceTraditional: "中國小學生都很尊敬師長。",
    vocabLink: "/phrase/triptochina/group/3/vocabulary/3",
  },
  {
    lesson: "8",
    simplified: "拥抱接吻",
    traditional: "擁抱接吻",
    pinyin: "yong1 bao4 jie1 wen3",
    englishDefinition: "hugging and kissing",
    sentenceSimplified: "中国人不习惯在公共场合拥抱接吻",
    sentenceTraditional: "中國人不習慣在公共場合擁抱接吻",
    vocabLink: "/phrase/triptochina/group/3/vocabulary/4",
  },
  {
    lesson: "9",
    simplified: "生活环境",
    traditional: "生活環境",
    pinyin: "sheng1 huo2 huan2 jing4",
    englishDefinition: "living environment",
    sentenceSimplified: "这里的生活环境很好，空气很清新。",
    sentenceTraditional: "這裏的生活環境很好，沒有空氣汙染。",
    vocabLink: "/phrase/triptochina/group/3/vocabulary/5",
  },
  {
    lesson: "9",
    simplified: "工作环境",
    traditional: "工作環境",
    pinyin: "gong1 zuo4 huan2 jing4",
    englishDefinition: "working environment",
    sentenceSimplified: "他的工作环境压力很大。",
    sentenceTraditional: "這裏的工作環境很好，你一定要來申請這裏的工作。",
    vocabLink: "/phrase/triptochina/group/3/vocabulary/6",
  },
  {
    lesson: "10",
    simplified: "争先恐后",
    traditional: "爭先恐後",
    pinyin: "zheng1 xian1 kong3 hou4",
    englishDefinition: "strive to be ahead of others and fear to be behind",
    sentenceSimplified: "在中国常常看到大家争先恐后上公车。",
    sentenceTraditional: "在中國常常看到大家上公車的時候爭先恐後。",
    vocabLink: "/phrase/triptochina/group/3/vocabulary/7",
  },
  {
    lesson: "10",
    simplified: "公共场合",
    traditional: "公共場合",
    pinyin: "gong1 gong4 chang3 he2",
    englishDefinition: "public areas",
    sentenceSimplified: "在公共场合不要大声喧哗。",
    sentenceTraditional: "在公共場合不要抽煙。",
    vocabLink: "/phrase/triptochina/group/3/vocabulary/8",
  },
  {
    lesson: "10",
    simplified: "人口过多",
    traditional: "人口過多",
    pinyin: "ren2 kou3 guo4 duo1",
    englishDefinition: "overpopulation",
    sentenceSimplified: "中国一直有人口过多的问题。",
    sentenceTraditional: "中國一直有人口過多的問題。",
    vocabLink: "/phrase/triptochina/group/3/vocabulary/9",
  },
  {
    lesson: "10",
    simplified: "设备有限",
    traditional: "設備有限",
    pinyin: "she4 bei4 you3 xian4",
    englishDefinition: "limited equipment",
    sentenceSimplified: "学校的设备有限，要登记才能用电脑教室。",
    sentenceTraditional: "學校的設備有限，要先登記才能用電腦教室。",
    vocabLink: "/phrase/triptochina/group/4/vocabulary/0",
  },
  {
    lesson: "10",
    simplified: "相当重要",
    traditional: "相當重要",
    pinyin: "xiang1 dang1 zhong4 yao4",
    englishDefinition: "extremely important",
    sentenceSimplified: "人生的理想、信念和追求是相当重要的。",
    sentenceTraditional: "對學生來說，選擇一個好的專業是相當重要的。",
    vocabLink: "/phrase/triptochina/group/4/vocabulary/1",
  },
  {
    lesson: "11",
    simplified: "日常会话",
    traditional: "日常會話",
    pinyin: "ri4 chang2 hui4 hua4",
    englishDefinition: "daily conversation topics",
    sentenceSimplified: "可以通过边听边读真实场景中的日常会话，练习中文。",
    sentenceTraditional: "美國學生可以通過看電視lian'xi日常會話。",
    vocabLink: "/phrase/triptochina/group/4/vocabulary/2",
  },
  {
    lesson: "11",
    simplified: "平常话题",
    traditional: "平常話題",
    pinyin: "ping2 chang2 hua4 ti2",
    englishDefinition: "ordinary / usual topic",
    sentenceSimplified: "聊吃的是常见的平常话题。",
    sentenceTraditional: "聊吃的是常見的平常話題。",
    vocabLink: "/phrase/triptochina/group/4/vocabulary/3",
  },
  {
    lesson: "11",
    simplified: "表示关心",
    traditional: "表示關心",
    pinyin: "biao3 shi4 guan1 xin1",
    englishDefinition: "express concern",
    sentenceSimplified: "要对父母经常表示关心。",
    sentenceTraditional: "子女要對父母經常表示關心。",
    vocabLink: "/phrase/triptochina/group/4/vocabulary/4",
  },
  {
    lesson: "11",
    simplified: "经济情况",
    traditional: "經濟情況",
    pinyin: "jing1 ji4 qing2 kuang4",
    englishDefinition: "economic situation",
    sentenceSimplified:
      "由于他家(的)经济情况不好，他得做三份工作才能维持生活。",
    sentenceTraditional: "由于他的經濟情況不好，他得工作三年以後才能結婚。",
    vocabLink: "/phrase/triptochina/group/4/vocabulary/5",
  },
  {
    lesson: "11",
    simplified: "个人隐私",
    traditional: "個人隱私",
    pinyin: "ge4 ren2 yin3 si1",
    englishDefinition: "personal privacy",
    sentenceSimplified: "在美国，没有男女朋友是个人隐私。",
    sentenceTraditional: "在美國，有沒有男女朋友是個人隱私。",
    vocabLink: "/phrase/triptochina/group/4/vocabulary/6",
  },
  {
    lesson: "11",
    simplified: "好管闲事",
    traditional: "好管閒事",
    pinyin: "hao4 guan3 xian2 shi4",
    englishDefinition: "be nosy",
    sentenceSimplified: "那是他们两个人的事，你不要好管闲事。",
    sentenceTraditional: "他們什麽時候結婚是他們兩個人的事，你不要多管閑事。",
    vocabLink: "/phrase/triptochina/group/4/vocabulary/7",
  },
  {
    lesson: "11",
    simplified: "隐私观念",
    traditional: "隱私觀念",
    pinyin: "yin3 si1 guan1 nian4",
    englishDefinition: "privacy concept",
    sentenceSimplified: "中国人的隐私观念和美国人的隐私观念不同。",
    sentenceTraditional: "中國人的隱私觀念和美國人的隱私觀念不同。",
    vocabLink: "/phrase/triptochina/group/4/vocabulary/8",
  },
  {
    lesson: "11",
    simplified: "社会制度",
    traditional: "社會制度",
    pinyin: "she4 hui4 zhi4 du4",
    englishDefinition: "social system",
    sentenceSimplified: "因为社会制度不同，所以隐私观念也不同。",
    sentenceTraditional: "因為社會制度不同，所以隱私觀念也不同。",
    vocabLink: "/phrase/triptochina/group/4/vocabulary/9",
  },
  {
    lesson: "12",
    simplified: "相对来说",
    traditional: "相對來說",
    pinyin: "xiang1 dui4 lai2 shuo1",
    englishDefinition: "relatively speaking",
    sentenceSimplified:
      "不是所有中国人都走后们，但是相对来说，这种情形中国比美国多一些。",
    sentenceTraditional:
      "不是所有中國人都走後們，但是相對來說，這種情形在中國比美國多一些。",
      vocabLink: "/phrase/triptochina/group/5/vocabulary/0",
  },
  {
    lesson: "12",
    simplified: "引以为耻",
    traditional: "引以為恥",
    pinyin: "yin3 yi3 wei2 chi3",
    englishDefinition: "ashamed of",
    sentenceSimplified: "浪费是一种不好的行为，我们应该引以为耻。",
    sentenceTraditional: "浪費是一種不好的行為，我們應該引以為恥。",
    vocabLink: "/phrase/triptochina/group/5/vocabulary/1",
  },
  {
    lesson: "12",
    simplified: "引以为荣",
    traditional: "引以為榮",
    pinyin: "yin3 yi3 wei2 rong2",
    englishDefinition: "proud of",
    sentenceSimplified: "老师对学生的成就引以为荣。",
    sentenceTraditional: "老師對學生的成就引以為榮。",
    vocabLink: "/phrase/triptochina/group/5/vocabulary/2",
  },
  {
    lesson: "12",
    simplified: "社会关系",
    traditional: "社會關係",
    pinyin: "she4 hui4 guan1 xi4",
    englishDefinition: "social connections",
    sentenceSimplified: "好的社会关系可以为你的生活提供方便。",
    sentenceTraditional: "好的社會關系可以為你的生活提供方便。",
    vocabLink: "/phrase/triptochina/group/5/vocabulary/3",
  },
  {
    lesson: "13",
    simplified: "上当受骗",
    traditional: "上當受騙",
    pinyin: "shang4 dang4 shou4 pian4",
    englishDefinition: "deceived",
    sentenceSimplified: "在国外旅游很容易上当受骗。",
    sentenceTraditional: "在國外旅遊很容易上當受騙。",
    vocabLink: "/phrase/triptochina/group/5/vocabulary/4",
  },
  {
    lesson: "13",
    simplified: "排队等候",
    traditional: "排隊等候",
    pinyin: "pai2 dui4 deng3 hou4",
    englishDefinition: "wait in line",
    sentenceSimplified: "很多人排队等候购买最新的iPhone。",
    sentenceTraditional: "很多人排隊等候購買最新的iPhone。",
    vocabLink: "/phrase/triptochina/group/5/vocabulary/5",
  },
  {
    lesson: "13",
    simplified: "不给面子",
    traditional: "不給面子",
    pinyin: "bu4 gei3 mian4 zi",
    englishDefinition: "made me look bad",
    sentenceSimplified: "他在很多人面前不客气地拒绝我的帮忙，很不给面子。",
    sentenceTraditional: "他在很多人面前不客氣地拒絕我的幫忙，很不給面子。",
    vocabLink: "/phrase/triptochina/group/5/vocabulary/6",
  },
  {
    lesson: "13",
    simplified: "受到优待",
    traditional: "受到優待",
    pinyin: "shou4 dao4 you1 dai4",
    englishDefinition: "receive prferential treatment",
    sentenceSimplified: "外国人在中国常常会受到优待。",
    sentenceTraditional: "外國人在中國常常會受到優待。",
    vocabLink: "/phrase/triptochina/group/5/vocabulary/7",
  },
  {
    lesson: "14",
    simplified: "印象深刻",
    traditional: "印象深刻",
    pinyin: "yin4 xiang4 shen1 ke4",
    englishDefinition: "deep impression",
    sentenceSimplified: "我对上海的高楼印象深刻。",
    sentenceTraditional: "我對上海的高樓印象深刻。",
    vocabLink: "/phrase/triptochina/group/5/vocabulary/8",
  },
  {
    lesson: "14",
    simplified: "新鲜事物",
    traditional: "新鮮事物",
    pinyin: "xin1 xian1 shi4 wu4",
    englishDefinition: "new things",
    sentenceSimplified: "我对所有的新鲜事物都感到好奇。",
    sentenceTraditional: "我對所有的新鮮事物都感到好奇。",
    vocabLink: "/phrase/triptochina/group/5/vocabulary/9",
  },
  {
    lesson: "15",
    simplified: "闷热潮湿",
    traditional: "悶熱潮濕",
    pinyin: "men1 re4 chao2 shi1",
    englishDefinition: "hot and humid",
    sentenceSimplified: "台湾的气候闷热潮湿，因此我每天都得洗澡。",
    sentenceTraditional: "台灣的氣候悶熱潮濕，因此我每天都得洗澡。",
    vocabLink: "/phrase/triptochina/group/6/vocabulary/0",
  },
  {
    lesson: "16",
    simplified: "日常生活",
    traditional: "日常生活",
    pinyin: "ri4 chang2 sheng1 huo2",
    englishDefinition: "daily life",
    sentenceSimplified: "日常生活难免会有不顺利的事。",
    sentenceTraditional: "日常生活難免會有不順利的事。",
    vocabLink: "/phrase/triptochina/group/6/vocabulary/1",
  },
  {
    lesson: "16",
    simplified: "不可避免",
    traditional: "不可避免",
    pinyin: "bu4 ke3 bi4 mian3",
    englishDefinition: "unavoidable",
    sentenceSimplified: "学外语犯错是不可避免的。",
    sentenceTraditional: "學外語犯錯是不可避免的。",
    vocabLink: "/phrase/triptochina/group/6/vocabulary/2",
  },
  {
    lesson: "16",
    simplified: "交通堵塞",
    traditional: "交通堵塞",
    pinyin: "jiao1 tong1 du3 se4",
    englishDefinition: "traffic jam",
    sentenceSimplified: "交通堵塞是每个大城市的问题。",
    sentenceTraditional: "交通堵塞是每個大城市的問題。",
    vocabLink: "/phrase/triptochina/group/6/vocabulary/3",
  },
  {
    lesson: "16",
    simplified: "节省能源",
    traditional: "節省能源",
    pinyin: "jie2 sheng3 neng2 yuan2",
    englishDefinition: "save energy",
    sentenceSimplified: "为了保护环境，我们应该节省能源，减少浪费。",
    sentenceTraditional: "為了保護環境，我們應該節省能源，減少浪費。",
    vocabLink: "/phrase/triptochina/group/6/vocabulary/4",
  },
  {
    lesson: "16",
    simplified: "造成污染",
    traditional: "造成污染",
    pinyin: "zao4 cheng2 wu1 ran3",
    englishDefinition: "cause pollution",
    sentenceSimplified: "大量使用塑料会对环境造成污染。",
    sentenceTraditional: "大量使用塑料會對環境造成汙染。",
    vocabLink: "/phrase/triptochina/group/6/vocabulary/5",
  },
  {
    lesson: "17",
    simplified: "国家大事",
    traditional: "國家大事",
    pinyin: "guo2 jia1 da4 shi4",
    englishDefinition: "national affairs",
    sentenceSimplified: "他对国家大事一点也不关心。",
    sentenceTraditional: "他對國家大事一點也不關心。",
    vocabLink: "/phrase/triptochina/group/6/vocabulary/6",
  },
  {
    lesson: "17",
    simplified: "社会治安",
    traditional: "社會治安",
    pinyin: "she4 hui4 zhi4 an1",
    englishDefinition: "public safety, public security",
    sentenceSimplified: "纽约的社会治安越来越差。",
    sentenceTraditional: "紐約的社會治安越來越差。",
    vocabLink: "/phrase/triptochina/group/6/vocabulary/7",
  },
  {
    lesson: "17",
    simplified: "竞争激烈",
    traditional: "競爭激烈",
    pinyin: "jing4 zheng1 ji1 lie4",
    englishDefinition: "competitive",
    sentenceSimplified: "他喜欢竞争激烈的工作环境。",
    sentenceTraditional: "他喜歡競爭激烈的工作環境。",
    vocabLink: "/phrase/triptochina/group/6/vocabulary/8",
  },
  {
    lesson: "17",
    simplified: "中美关系",
    traditional: "中美關係",
    pinyin: "zhong1 mei3 guan1 xi4",
    englishDefinition: "Sino-US relations",
    sentenceSimplified: "中美关系越来越紧张。",
    sentenceTraditional: "中美關系越來越緊張。",
    vocabLink: "/phrase/triptochina/group/6/vocabulary/9",
  },
  {
    lesson: "17",
    simplified: "发表意见",
    traditional: "發表意見",
    pinyin: "fa1 biao3 yi4 jian4",
    englishDefinition: "give an opinion",
    sentenceSimplified: "他很愿意对国家大事发表意见。",
    sentenceTraditional: "他很願意對國家大事發表意見。",
    vocabLink: "/phrase/triptochina/group/7/vocabulary/0",
  },
  {
    lesson: "18",
    simplified: "盗版商品",
    traditional: "盜版商品",
    pinyin: "dao4 ban3 shang1 pin3",
    englishDefinition: "pirated commerical products",
    sentenceSimplified: "一般来说，盗版商品都很便宜。",
    sentenceTraditional: "一般來說，盜版商品都很便宜。",
    vocabLink: "/phrase/triptochina/group/7/vocabulary/1",
  },
  {
    lesson: "18",
    simplified: "正版商品",
    traditional: "正版商品",
    pinyin: "zheng4 ban3 shang1 pin3",
    englishDefinition: "legalcopy, official edition",
    sentenceSimplified: "购买正版商品才能获得售后服务。",
    sentenceTraditional: "購買正版商品才能獲得售後服務。",
    vocabLink: "/phrase/triptochina/group/7/vocabulary/2",
  },
  {
    lesson: "18",
    simplified: "廉价商品",
    traditional: "廉價商品",
    pinyin: "lian2 jia4 shang1 pin3",
    englishDefinition: "cheap goods",
    sentenceSimplified: "廉价商品不代表商品的质量差。",
    sentenceTraditional: "廉價商品不代表商品的質量差。",
    vocabLink: "/phrase/triptochina/group/7/vocabulary/3",
  },
  {
    lesson: "18",
    simplified: "过街天桥",
    traditional: "過街天橋",
    pinyin: "guo4 jie1 tian1 qiao2",
    englishDefinition: "pedestrian bridge that goes across a street",
    sentenceSimplified: "过街天桥有很多小摊小贩。",
    sentenceTraditional: "過街天橋有很多小攤販。",
    vocabLink: "/phrase/triptochina/group/7/vocabulary/4",
  },
  {
    lesson: "18",
    simplified: "侵害权益",
    traditional: "侵害權益",
    pinyin: "qin1 hai4 quan2 yi4",
    englishDefinition: "infringement of rights",
    sentenceSimplified: "公司以侵害权益为理由起诉他。",
    sentenceTraditional: "公司以侵害權益為理由起訴他。",
    vocabLink: "/phrase/triptochina/group/7/vocabulary/5",
  },
  {
    lesson: "20",
    simplified: "高级享受",
    traditional: "高級享受",
    pinyin: "gao1 ji2 xiang3 shou4",
    englishDefinition: "high class enjoyment",
    sentenceSimplified: "住五星级酒店是一种高级享受。",
    sentenceTraditional: "住五星級酒店是一種高級享受。",
    vocabLink: "/phrase/triptochina/group/7/vocabulary/6",
  },
  {
    lesson: "20",
    simplified: "效率很高",
    traditional: "效率很高",
    pinyin: "xiao4 lv4 hen3 gao1",
    englishDefinition: "high efficiency",
    sentenceSimplified: "星巴克(Starbucks)的效率很高，做一杯咖啡只要三分钟。",
    sentenceTraditional: "星巴克(Starbucks)的效率很高，做一杯咖啡只要三分鍾。",
    vocabLink: "/phrase/triptochina/group/7/vocabulary/7",
  },
  {
    lesson: "20",
    simplified: "世界各国",
    traditional: "世界各國",
    pinyin: "shi4 jie4 ge4 guo2",
    englishDefinition: "all countries in the world",
    sentenceSimplified: "世界各国都应该重视气候变化问题。",
    sentenceTraditional: "世界各國都應該重視氣候變化問題。",
    vocabLink: "/phrase/triptochina/group/7/vocabulary/8",
  },
  {
    lesson: "21",
    simplified: "有害健康",
    traditional: "有害健康",
    pinyin: "you3 hai4 jian4 kang1",
    englishDefinition: "harmful to health",
    sentenceSimplified: "吸烟有害健康。",
    sentenceTraditional: "吸煙有害健康。",
    vocabLink: "/phrase/triptochina/group/7/vocabulary/9",
  },
  {
    lesson: "21",
    simplified: "个人问题",
    traditional: "個人問題",
    pinyin: "ge4 ren2 wen4 ti2",
    englishDefinition: "personal issues",
    sentenceSimplified: "结不结婚是个人问题，别人管不着。",
    sentenceTraditional: "結不結婚是個人問題，別人管不著。",
    vocabLink: "/phrase/triptochina/group/8/vocabulary/0",
  },
  {
    lesson: "21",
    simplified: "社会问题",
    traditional: "社會問題",
    pinyin: "she4 hui4 wen4 ti2",
    englishDefinition: "social issues",
    sentenceSimplified: "人口过多是中国最主要的社会问题之一。",
    sentenceTraditional: "人口過多是中國最主要的社會問題之一。",
    vocabLink: "/phrase/triptochina/group/8/vocabulary/1",
  },
  {
    lesson: "21",
    simplified: "主要原因",
    traditional: "主要原因",
    pinyin: "zhu3 yao4 yuan2 yin1",
    englishDefinition: "main reason",
    sentenceSimplified: "抽烟是引起肺癌的主要原因之一。",
    sentenceTraditional: "抽煙是引起肺癌的主要原因之一。",
    vocabLink: "/phrase/triptochina/group/8/vocabulary/2",
  },
  {
    lesson: "22",
    simplified: "电视转播",
    traditional: "電視轉播",
    pinyin: "dian4 shi4 zhuan3 bo1",
    englishDefinition: "TV broadcast",
    sentenceSimplified: "如果你没时间去现场看比赛，可以看电视转播。",
    sentenceTraditional: "如果你沒時間去現場看比賽，可以看電視轉播。",
    vocabLink: "/phrase/triptochina/group/8/vocabulary/3",
  },
  {
    lesson: "22",
    simplified: "体育比赛",
    traditional: "體育比賽",
    pinyin: "ti3 yu4 bi3 sai4",
    englishDefinition: "sports competition",
    sentenceSimplified: "有的人喜欢看体育比赛，有的人不喜欢看体育比赛。",
    sentenceTraditional: "有的人喜歡看體育比賽，有的人不喜歡看體育比賽。",
    vocabLink: "/phrase/triptochina/group/8/vocabulary/4",
  },
  {
    lesson: "22",
    simplified: "民族主义",
    traditional: "民族主義",
    pinyin: "min2 zu2 zhu3 yi4",
    englishDefinition: "nationalism",
    sentenceSimplified: "强烈的名族主义让一些人把体育比赛当成打仗。",
    sentenceTraditional: "強烈的民族主義讓一些人把體育比賽當成打仗。",
    vocabLink: "/phrase/triptochina/group/8/vocabulary/5",
  },
  {
    lesson: "22",
    simplified: "全国各地",
    traditional: "全國各地",
    pinyin: "quan2 guo2 ge4 di4",
    englishDefinition: "all over the country",
    sentenceSimplified: "各国各地都有把体育比赛当成打仗的人。",
    sentenceTraditional: "各國各地都有把體育比賽當成打仗的人。",
    vocabLink: "/phrase/triptochina/group/8/vocabulary/6",
  },
  {
    lesson: "22",
    simplified: "言论自由",
    traditional: "言論自由",
    pinyin: "yan2 lun4 zi4 you2",
    englishDefinition: "freedom of speech",
    sentenceSimplified: "一个民主国家应该有言论自由。",
    sentenceTraditional: "一個民主國家應該有言論自由。",
    vocabLink: "/phrase/triptochina/group/8/vocabulary/7",
  },
  {
    lesson: "22",
    simplified: "帝国主义",
    traditional: "帝國主義",
    pinyin: "di4 guo2 zhu3 yi4",
    englishDefinition: "imperialism",
    sentenceSimplified: "中国一百多年来都受帝国主义欺负。",
    sentenceTraditional: "中國一百多年來都受帝國主義欺負。",
    vocabLink: "/phrase/triptochina/group/8/vocabulary/8",
  },
  {
    lesson: "22",
    simplified: "游行示威",
    traditional: "遊行示威",
    pinyin: "you2 xing2 shi4 wei1",
    englishDefinition: "parade to protest",
    sentenceSimplified: "如果对政府不满意，可以去游行示威。",
    sentenceTraditional: "如果對政府不滿意，可以去遊行示威。",
    vocabLink: "/phrase/triptochina/group/8/vocabulary/9",
  },
  {
    lesson: "22",
    simplified: "努力学习",
    traditional: "努力學習",
    pinyin: "nu3 li4 xue2 xi2",
    englishDefinition: "study hard",
    sentenceSimplified: "学生都应该努力学习。",
    sentenceTraditional: "學生都應該努力學習。",
    vocabLink: "/phrase/triptochina/group/9/vocabulary/0",
  },
  {
    lesson: "23",
    simplified: "产生偏见",
    traditional: "產生偏見",
    pinyin: "chan3 sheng1 pian1 jian4",
    englishDefinition: "create bias",
    sentenceSimplified: "产生偏见很容易，克服偏见很难。",
    sentenceTraditional: "産生偏見很容易，克服偏見很難。",
    vocabLink: "/phrase/triptochina/group/9/vocabulary/1",
  },
  {
    lesson: "23",
    simplified: "实际情形",
    traditional: "實際情形",
    pinyin: "shi2 ji4 qing2 xing2",
    englishDefinition: "actual situation",
    sentenceSimplified: "他说的不是实际情形。",
    sentenceTraditional: "他說的不是實際情形。",
    vocabLink: "/phrase/triptochina/group/9/vocabulary/2",
  },
  {
    lesson: "23",
    simplified: "种族主义",
    traditional: "種族主義",
    pinyin: "zhong3 zu2 zhu3 yi4",
    englishDefinition: "racism",
    sentenceSimplified: "我希望你不是一个种族主义者。",
    sentenceTraditional: "我希望你不是一個種族主義者。",
    vocabLink: "/phrase/triptochina/group/9/vocabulary/3",
  },
  {
    lesson: "23",
    simplified: "独立思考",
    traditional: "獨立思考",
    pinyin: "du2 li4 si1 kao3",
    englishDefinition: "independent thinking",
    sentenceSimplified: "一个人要有独立思考的能力。",
    sentenceTraditional: "一個人要有獨立思考的能力。",
    vocabLink: "/phrase/triptochina/group/9/vocabulary/4",
  },
  {
    lesson: "24",
    simplified: "杂技表演",
    traditional: "雜技表演",
    pinyin: "za2 ji4 biao3 yan3",
    englishDefinition: "acrobatics",
    sentenceSimplified: "杂技表演很精彩。",
    sentenceTraditional: "雜技表演很精彩。",
    vocabLink: "/phrase/triptochina/group/9/vocabulary/5",
  },
  {
    lesson: "24",
    simplified: "杂技训练",
    traditional: "雜技訓練",
    pinyin: "za2 ji4 xun4 lian4",
    englishDefinition: "acrobatic training",
    sentenceSimplified: "杂技训练很辛苦。",
    sentenceTraditional: "雜技訓練很辛苦。",
    vocabLink: "/phrase/triptochina/group/9/vocabulary/6",
  },
  {
    lesson: "24",
    simplified: "虐待孩子",
    traditional: "虐待孩子",
    pinyin: "nue4 dai4 hai2 zi",
    englishDefinition: "child abuse",
    sentenceSimplified: "有些父母会虐待自己的孩子。",
    sentenceTraditional: "有些父母會虐待自己的孩子。",
    vocabLink: "/phrase/triptochina/group/9/vocabulary/7",
  },
  {
    lesson: "24",
    simplified: "家常便饭",
    traditional: "家常便飯",
    pinyin: "jia1 chang2 bian4 fan4",
    englishDefinition: "routine, simple meal",
    sentenceSimplified: "对练习杂技的孩子来说，受伤是家常便饭。",
    sentenceTraditional: "對練習雜技的孩子來說，受傷是家常便飯。",
    vocabLink: "/phrase/triptochina/group/9/vocabulary/8",
  },
  {
    lesson: "24",
    simplified: "一技之长",
    traditional: "一技之長",
    pinyin: "yi2 ji4 zhi1 chang2",
    englishDefinition: "(saying) skill or proficiency",
    sentenceSimplified: "一个人在社会上要有一技之长。",
    sentenceTraditional: "一個人在社會上要有一技之長。",
    vocabLink: "/phrase/triptochina/group/9/vocabulary/9",
  },
  {
    lesson: "25",
    simplified: "据我所知",
    traditional: "據我所知",
    pinyin: "ju4 wo3 suo3 zhi1",
    englishDefinition: "as far as I know",
    sentenceSimplified: "据我所知，现在很多中国太太的收入比先生高。",
    sentenceTraditional: "據我所知，現在很多中國太太的收入比先生高。",
    vocabLink: "/phrase/triptochina/group/10/vocabulary/0",
  },
  {
    lesson: "25",
    simplified: "社会地位",
    traditional: "社會地位",
    pinyin: "she4 hui4 di4 wei4",
    englishDefinition: "social status",
    sentenceSimplified: "以前，中国妇女的社会地位很低。",
    sentenceTraditional: "以前，中國婦女的社會地位很低。",
    vocabLink: "/phrase/triptochina/group/10/vocabulary/1",
  },
  {
    lesson: "25",
    simplified: "贞节观念",
    traditional: "貞節觀念",
    pinyin: "zhen1 jie2 guan1 nian4",
    englishDefinition: "chastity concept",
    sentenceSimplified: "历史上的贞洁观念是妇女收到压迫的证明。",
    sentenceTraditional: "曆史上的貞潔觀念是婦女收到壓迫的證明。",
    vocabLink: "/phrase/triptochina/group/10/vocabulary/2",
  },
  {
    lesson: "25",
    simplified: "各行各业",
    traditional: "各行各業",
    pinyin: "ge4 hang2 ge4 ye4",
    englishDefinition: "each and every profession",
    sentenceSimplified: "来自各行各业的人都参加了这个活动。",
    sentenceTraditional: "來自各行各業的人都參加了這個活動。",
    vocabLink: "/phrase/triptochina/group/10/vocabulary/3",
  },
  {
    lesson: "26",
    simplified: "传统社会",
    traditional: "傳統社會",
    pinyin: "chuan2 tong3 she4 hui4",
    englishDefinition: "traditional society",
    sentenceSimplified: "传统社会都很重视家庭。",
    sentenceTraditional: "傳統社會都很重視家庭。",
    vocabLink: "/phrase/triptochina/group/10/vocabulary/4",
  },
  {
    lesson: "26",
    simplified: "传统观念",
    traditional: "傳統觀念",
    pinyin: "chuan2 tong3 guan1 nian4",
    englishDefinition: "traditional concept",
    sentenceSimplified: "一些传统观念其实是不对的。",
    sentenceTraditional: "一些傳統觀念其實是不對的。",
    vocabLink: "/phrase/triptochina/group/10/vocabulary/5",
  },
  {
    lesson: "26",
    simplified: "嫁鸡随鸡，嫁狗随狗",
    traditional: "嫁雞隨雞，嫁狗隨狗",
    pinyin: "jia4 ji1 sui2 ji1，jia4 gou3 sui2 gou3",
    englishDefinition: "a woman shares the fate of the man she marries",
    sentenceSimplified: "中国的妇女以前有“嫁鸡随鸡嫁狗随狗”的观念。",
    sentenceTraditional: "中國的婦女以前有“嫁雞隨雞嫁狗隨狗”的觀念。",
    vocabLink: "/phrase/triptochina/group/10/vocabulary/6",
  },
  {
    lesson: "26",
    simplified: "婚姻观念",
    traditional: "婚姻觀念",
    pinyin: "hun1 yin1 guan1 nian4",
    englishDefinition: "marriage concept",
    sentenceSimplified: "“嫁鸡随鸡嫁狗随狗”的婚姻观念在慢慢改变。",
    sentenceTraditional: "嫁雞隨雞嫁狗隨狗”的婚姻觀念在慢慢改變。",
    vocabLink: "/phrase/triptochina/group/10/vocabulary/7",
  },
  {
    lesson: "26",
    simplified: "过一辈子",
    traditional: "過一輩子",
    pinyin: "guò yí bèi zi",
    englishDefinition: "spend a lifetime",
    sentenceSimplified: "以前的妇女都觉得嫁一个人就得跟那个人过一辈子。",
    sentenceTraditional: "以前的婦女都覺得嫁一個人就得跟那個人過一輩子。",
    vocabLink: "/phrase/triptochina/group/10/vocabulary/8",
  },
  {
    lesson: "26",
    simplified: "婚姻自由",
    traditional: "婚姻自由",
    pinyin: "hun1 yin1 zi4 you2",
    englishDefinition: "freedom of marriage",
    sentenceSimplified: "现代人应该有婚姻自由。",
    sentenceTraditional: "現代人應該有婚姻自由。",
    vocabLink: "/phrase/triptochina/group/10/vocabulary/9",
  },
  {
    lesson: "26",
    simplified: "信仰自由",
    traditional: "信仰自由",
    pinyin: "xin4 yang3 zi4 you2",
    englishDefinition: "freedom of religion",
    sentenceSimplified: "在一些国家没有信仰自由。",
    sentenceTraditional: "在一些國家沒有信仰自由。",
    vocabLink: "/phrase/triptochina/group/11/vocabulary/0",
  },
  {
    lesson: "26",
    simplified: "个人自由",
    traditional: "個人自由",
    pinyin: "ge4 ren2 zi4 you2",
    englishDefinition: "individual freedom",
    sentenceSimplified: "我想做什么就做什么，这是我的个人自由。",
    sentenceTraditional: "我想做什麽就做什麽，這是我的個人自由。",
    vocabLink: "/phrase/triptochina/group/11/vocabulary/1",
  },
  {
    lesson: "26",
    simplified: "追求自由",
    traditional: "追求自由",
    pinyin: "zhui1 qiu2 zi4 you2",
    englishDefinition: "pursue freedom",
    sentenceSimplified: "每个人都有追求自由的权利。",
    sentenceTraditional: "每個人都有追求自由的權利。",
    vocabLink: "/phrase/triptochina/group/11/vocabulary/2",
  },
  {
    lesson: "26",
    simplified: "西方文化",
    traditional: "西方文化",
    pinyin: "xi1 fang1 wen2 hua4",
    englishDefinition: "western culture",
    sentenceSimplified: "西方文化慢慢影响着很多中国人。",
    sentenceTraditional: "西方文化慢慢影響著很多中國人。",
    vocabLink: "/phrase/triptochina/group/11/vocabulary/3",
  },
  {
    lesson: "26",
    simplified: "争取权利",
    traditional: "爭取權利",
    pinyin: "zheng1 qu3 quan2 li4",
    englishDefinition: "strive for rights",
    sentenceSimplified: "每一个人都应该学会争取自己应该有的权利。",
    sentenceTraditional: "每一個人都應該學會爭取自己應該有的權利。",
    vocabLink: "/phrase/triptochina/group/11/vocabulary/4",
  },
  {
    lesson: "26",
    simplified: "种种原因",
    traditional: "種種原因",
    pinyin: "zhong3 zhong3 yuan2 yin1",
    englishDefinition: "various reasons",
    sentenceSimplified: "因為種種原因，他決定離開紐約。",
    sentenceTraditional: "因為種種原因，他決定離開紐約。",
    vocabLink: "/phrase/triptochina/group/11/vocabulary/5",
  },
  {
    lesson: "26",
    simplified: "维持生活",
    traditional: "維持生活",
    pinyin: "wei2 chi2 sheng1 huo2",
    englishDefinition: "maintain lifehood",
    sentenceSimplified: "為了維持生活，他打算開出租車。",
    sentenceTraditional: "為了維持生活，他打算開出租車。",
    vocabLink: "/phrase/triptochina/group/11/vocabulary/6",
  },
  {
    lesson: "27",
    simplified: "独生子女",
    traditional: "獨生子女",
    pinyin: "du2 sheng1 zi3 nv3",
    englishDefinition: "only-child",
    sentenceSimplified: "中國的獨生子女政策，造成了男女比例不均的問題。",
    sentenceTraditional: "中國的獨生子女政策，造成了男女比例不均的問題。",
    vocabLink: "/phrase/triptochina/group/11/vocabulary/7",
  },
  {
    lesson: "27",
    simplified: "人口政策",
    traditional: "人口政策",
    pinyin: "ren2 kou3 zheng4 ce4",
    englishDefinition: "population policy",
    sentenceSimplified: "每個國家的人口政策都不太一樣。",
    sentenceTraditional: "每個國家的人口政策都不太一樣。",
    vocabLink: "/phrase/triptochina/group/11/vocabulary/8",
  },
  {
    lesson: "27",
    simplified: "快速增加",
    traditional: "快速增加",
    pinyin: "kuai4 su4 zeng1 jia1",
    englishDefinition: "increase rapidly",
    sentenceSimplified: "這幾年使用手機付錢的現象快速增加。",
    sentenceTraditional: "這幾年使用手機付錢的現象快速增加。",
    vocabLink: "/phrase/triptochina/group/11/vocabulary/9",
  },
  {
    lesson: "27",
    simplified: "严格推行",
    traditional: "嚴格推行",
    pinyin: "yan2 ge2 tui1 xing2",
    englishDefinition: "strictly enforce",
    sentenceSimplified: "紐約應該嚴格推行戴口罩的政策。",
    sentenceTraditional: "紐約應該嚴格推行戴口罩的政策。",
    vocabLink: "/phrase/triptochina/group/12/vocabulary/0",
  },
  {
    lesson: "27",
    simplified: "控制人口",
    traditional: "控制人口",
    pinyin: "kong4 zhi4 ren2 kou3",
    englishDefinition: "population control",
    sentenceSimplified:
      "為了控制人口數量的增加，中國政府實施了嚴格的人口政策。",
    sentenceTraditional:
      "為了控制人口數量的增加，中國政府實施了嚴格的人口政策。",
      vocabLink: "/phrase/triptochina/group/12/vocabulary/1",
  },
  {
    lesson: "27",
    simplified: "无所不能",
    traditional: "無所不能",
    pinyin: "wu2 suo3 bu4 neng2",
    englishDefinition: "omnipotent, all-mighty",
    sentenceSimplified: "她是個無所不能的企業家。",
    sentenceTraditional: "她是個無所不能的企業家。",
    vocabLink: "/phrase/triptochina/group/12/vocabulary/2",
  },
  {
    lesson: "27",
    simplified: "基本人权",
    traditional: "基本人權",
    pinyin: "ji1 ben3 ren2 quan2",
    englishDefinition: "basic human rights",
    sentenceSimplified: "投票是基本人權，不能因為性別和種族而受到限制。",
    sentenceTraditional: "投票是基本人權，不能因為性別和種族而受到限制。",
    vocabLink: "/phrase/triptochina/group/12/vocabulary/3",
  },
  {
    lesson: "27",
    simplified: "绝对不是",
    traditional: "絕對不是",
    pinyin: "jue2 dui4 bu2 shi4",
    englishDefinition: "definitely not",
    sentenceSimplified: "這件事絕對不是我做的。",
    sentenceTraditional: "這件事絕對不是我做的。",
    vocabLink: "/phrase/triptochina/group/12/vocabulary/4",
  },
  {
    lesson: "28",
    simplified: "重视教育",
    traditional: "重視教育",
    pinyin: "zhong4 shi4 jiao4 yu4",
    englishDefinition: "value education",
    sentenceSimplified: "很多華人家庭都很重視教育。",
    sentenceTraditional: "很多華人家庭都很重視教育。",
    vocabLink: "/phrase/triptochina/group/12/vocabulary/5",
  },
  {
    lesson: "28",
    simplified: "贫困家庭",
    traditional: "貧困家庭",
    pinyin: "pin2 kun4 jia1 ting2",
    englishDefinition: "poor family",
    sentenceSimplified: "疫情發生後，貧困家庭受到了很大的衝擊。",
    sentenceTraditional: "疫情發生後，貧困家庭受到了很大的衝擊。",
    vocabLink: "/phrase/triptochina/group/12/vocabulary/6",
  },
  {
    lesson: "28",
    simplified: "没有捷径",
    traditional: "沒有捷徑",
    pinyin: "mei2 you3 jie2 jing4",
    englishDefinition: "no shortcuts",
    sentenceSimplified: "學語言沒有捷徑，只能不斷練習。",
    sentenceTraditional: "學語言沒有捷徑，只能不斷練習。",
    vocabLink: "/phrase/triptochina/group/12/vocabulary/7",
  },
  {
    lesson: "28",
    simplified: "走向成功",
    traditional: "走向成功",
    pinyin: "zou3 xiang4 cheng2 gong1",
    englishDefinition: "go toward success",
    sentenceSimplified: "唯有不斷練習，才能走向成功。",
    sentenceTraditional: "唯有不斷練習，才能走向成功。",
    vocabLink: "/phrase/triptochina/group/12/vocabulary/8",
  },
  {
    lesson: "28",
    simplified: "考试制度",
    traditional: "考試制度",
    pinyin: "kao3 shi4 zhi4 du4",
    englishDefinition: "examination system",
    sentenceSimplified: "中國古代的考試制度十分保守。",
    sentenceTraditional: "中國古代的考試制度十分保守。",
    vocabLink: "/phrase/triptochina/group/12/vocabulary/9",
  },
  {
    lesson: "28",
    simplified: "入学考试",
    traditional: "入學考試",
    pinyin: "ru4 xue2 kao3 shi4",
    englishDefinition: "entrance exam",
    sentenceSimplified:
      "有些國家只有一次大學入學考試，因此每個學生的壓力都很大。",
    sentenceTraditional:
      "有些國家只有一次大學入學考試，因此每個學生的壓力都很大。",
      vocabLink: "/phrase/triptochina/group/13/vocabulary/0",
  },
  {
    lesson: "28",
    simplified: "知识分子",
    traditional: "知識份子",
    pinyin: "zhi1 shi2 fen4 zi3",
    englishDefinition: "intellectual",
    sentenceSimplified: "知識份子需要懂得自己的社會責任。",
    sentenceTraditional: "知識份子需要懂得自己的社會責任。",
    vocabLink: "/phrase/triptochina/group/13/vocabulary/1",
  },
  {
    lesson: "28",
    simplified: "出国留学",
    traditional: "出國留學",
    pinyin: "chu1 guo2 liu2 xue2",
    englishDefinition: "study abroad",
    sentenceSimplified: "出國留學能夠培養國際觀。",
    sentenceTraditional: "出國留學能夠培養國際觀。",
    vocabLink: "/phrase/triptochina/group/13/vocabulary/2",
  },
  {
    lesson: "28",
    simplified: "改善生活",
    traditional: "改善生活",
    pinyin: "gai3 shan4 sheng1 huo2",
    englishDefinition: "improve life",
    sentenceSimplified: "為了改善生活品質，他每天都打掃房間。",
    sentenceTraditional: "為了改善生活品質，他每天都打掃房間。",
    vocabLink: "/phrase/triptochina/group/13/vocabulary/3",
  },
  {
    lesson: "29",
    simplified: "名胜古迹",
    traditional: "名勝古蹟",
    pinyin: "ming2 sheng4 gu3 ji4",
    englishDefinition: "historical sites",
    sentenceSimplified: "中國有許多古老的名勝古蹟。",
    sentenceTraditional: "中國有許多古老的名勝古蹟。",
    vocabLink: "/phrase/triptochina/group/13/vocabulary/4",
  },
  {
    lesson: "29",
    simplified: "旅游名胜",
    traditional: "旅遊名勝",
    pinyin: "lv3 you2 ming2 sheng4",
    englishDefinition: "tourist attractions",
    sentenceSimplified: "法國出名的旅遊名勝是巴黎鐵塔。",
    sentenceTraditional: "法國出名的旅遊名勝是巴黎鐵塔。",
    vocabLink: "/phrase/triptochina/group/13/vocabulary/5",
  },
  {
    lesson: "29",
    simplified: "风景名胜",
    traditional: "風景名勝",
    pinyin: "feng1 jing3 ming2 sheng4",
    englishDefinition: "tourist attractions",
    sentenceSimplified: "你知道西班牙有什麼值得一遊的風景名勝嗎？",
    sentenceTraditional: "你知道西班牙有什麼值得一遊的風景名勝嗎？",
    vocabLink: "/phrase/triptochina/group/13/vocabulary/6",
  },
  {
    lesson: "29",
    simplified: "随地吐痰",
    traditional: "隨地吐痰",
    pinyin: "sui2 di4 tu3 tan2",
    englishDefinition: "spitting everywhere",
    sentenceSimplified: "紐約的人特別喜歡隨地吐痰，因此造成市容不佳。",
    sentenceTraditional: "紐約的人特別喜歡隨地吐痰，因此造成市容不佳。",
    vocabLink: "/phrase/triptochina/group/13/vocabulary/7",
  },
  {
    lesson: "29",
    simplified: "到处都是",
    traditional: "到處都是",
    pinyin: "dao4 chu4 dou1 shi4",
    englishDefinition: "everywhere",
    sentenceSimplified: "有錢的人到處都是，但很可惜我不是。",
    sentenceTraditional: "有錢的人到處都是，但很可惜我不是。",
    vocabLink: "/phrase/triptochina/group/13/vocabulary/8",
  },
  {
    lesson: "29",
    simplified: "经济发展",
    traditional: "經濟發展",
    pinyin: "jing1 ji4 fa1 zhan3",
    englishDefinition: "economic development",
    sentenceSimplified: "開發中的國家往往把經濟發展視為最優先的事。",
    sentenceTraditional: "開發中的國家往往把經濟發展視為最優先的事。",
    vocabLink: "/phrase/triptochina/group/13/vocabulary/9",
  },
  {
    lesson: "29",
    simplified: "环境污染",
    traditional: "環境污染",
    pinyin: "huan2 jing4 wu1 ran3",
    englishDefinition: "environmental pollution",
    sentenceSimplified: "過量的開發會造成環境污染。",
    sentenceTraditional: "過量的開發會造成環境汙染。",
    vocabLink: "/phrase/triptochina/group/14/vocabulary/0",
  },
  {
    lesson: "29",
    simplified: "人山人海",
    traditional: "人山人海",
    pinyin: "ren2 shan1 ren2 hai3",
    englishDefinition: "a sea of people",
    sentenceSimplified: "紐約時代廣場到了新年前夕總是人山人海。",
    sentenceTraditional: "紐約時代廣場到了新年前夕總是人山人海。",
    vocabLink: "/phrase/triptochina/group/14/vocabulary/1",
  },
  {
    lesson: "30",
    simplified: "保存文物",
    traditional: "保存文物",
    pinyin: "bao3 cun2 wen2 wu4",
    englishDefinition: "preservation of cultural relics",
    sentenceSimplified: "大都會博物館保存了許多古老的文物。",
    sentenceTraditional: "大都會博物館保存了許多古老的文物。",
    vocabLink: "/phrase/triptochina/group/14/vocabulary/2",
  },
  {
    lesson: "30",
    simplified: "古老建筑",
    traditional: "古老建築",
    pinyin: "gu3 lao3 jian4 zhu4",
    englishDefinition: "ancient building",
    sentenceSimplified: "古老建築得花很多金錢維修。",
    sentenceTraditional: "古老建築得花很多金錢維修。",
    vocabLink: "/phrase/triptochina/group/14/vocabulary/3",
  },
  {
    lesson: "30",
    simplified: "高速公路",
    traditional: "高速公路",
    pinyin: "gao1 su4 gong1 lu4",
    englishDefinition: "highway",
    sentenceSimplified: "我不喜歡在高速公路上開車。",
    sentenceTraditional: "我不喜歡在高速公路上開車。",
    vocabLink: "/phrase/triptochina/group/14/vocabulary/4",
  },
  {
    lesson: "30",
    simplified: "生活水平",
    traditional: "生活水平",
    pinyin: "sheng1 huo2 shui3 ping2",
    englishDefinition: "standard of living",
    sentenceSimplified: "美國人的生活水平真是一代不如一代。",
    sentenceTraditional: "美國人的生活水平真是一代不如一代。",
    vocabLink: "/phrase/triptochina/group/14/vocabulary/5",
  },
  {
    lesson: "30",
    simplified: "生活质量",
    traditional: "生活質量",
    pinyin: "sheng1 huo2 zhi4 liang4",
    englishDefinition: "quality of life",
    sentenceSimplified: "有錢人的生活質量未必比較好。",
    sentenceTraditional: "有錢人的生活質量未必比較好。",
    vocabLink: "/phrase/triptochina/group/14/vocabulary/6",
  },
  {
    lesson: "30",
    simplified: "物质条件",
    traditional: "物質條件",
    pinyin: "wu4 zhi4 tiao2 jian4",
    englishDefinition: "material condition",
    sentenceSimplified: "我不在意物質條件的好壞，只要心靈充實就好。",
    sentenceTraditional: "我不在意物質條件的好壞，只要心靈充實就好。",
    vocabLink: "/phrase/triptochina/group/14/vocabulary/7",
  },
  {
    lesson: "30",
    simplified: "实际需要",
    traditional: "實際需要",
    pinyin: "shi2 ji4 xu1 yao4",
    englishDefinition: "real needs",
    sentenceSimplified: "他的政見符合了市民的實際需要。",
    sentenceTraditional: "他的政見符合了市民的實際需要。",
    vocabLink: "/phrase/triptochina/group/14/vocabulary/8",
  },
  {
    lesson: "31",
    simplified: "一般来说",
    traditional: "一般來說",
    pinyin: "yi4 ban1 lai2 shuo1",
    englishDefinition: "generally speaking",
    sentenceSimplified: "一般來說，我放假的時候都會去運動。",
    sentenceTraditional: "一般來說，我放假的時候都會去運動。",
    vocabLink: "/phrase/triptochina/group/14/vocabulary/9",
  },
  {
    lesson: "31",
    simplified: "长江三峡",
    traditional: "長江三峽",
    pinyin: "chang2 jiang1 san1 xia2",
    englishDefinition: "Three Gorges of the Yangtze River",
    sentenceSimplified: "长江三峡风景优美。",
    sentenceTraditional: "長江三峽風景優美。",
    vocabLink: "/phrase/triptochina/group/15/vocabulary/0",
  },
  {
    lesson: "31",
    simplified: "三峡大坝",
    traditional: "三峽大壩",
    pinyin: "san1 xia2 da4 ba4",
    englishDefinition: "Three Gorges Dam",
    sentenceSimplified: "三峡大坝是世界上最大的水坝之一。 ",
    sentenceTraditional: "三峽大壩是世界上最大的水壩之一。",
    vocabLink: "/phrase/triptochina/group/15/vocabulary/1",
  },
  {
    lesson: "31",
    simplified: "历史古迹",
    traditional: "歷史古蹟",
    pinyin: "li4 shi3 gu3 ji1",
    englishDefinition: "historical landmark",
    sentenceSimplified: "中国的历史古迹数不胜数。",
    sentenceTraditional: "中國的曆史古迹數不勝數。",
    vocabLink: "/phrase/triptochina/group/15/vocabulary/2",
  },
  {
    lesson: "31",
    simplified: "长江流域",
    traditional: "長江流域",
    pinyin: "chang2 jiang1 liu2 yu4",
    englishDefinition: "Yangtze River basin",
    sentenceSimplified: "三峡大坝改善了长江流域的电力。",
    sentenceTraditional: "三峽大壩改善了長江流域的電力。",
    vocabLink: "/phrase/triptochina/group/15/vocabulary/3",
  },
  {
    lesson: "31",
    simplified: "自然景观",
    traditional: "自然景觀",
    pinyin: "zi4 ran2 jing3 guan1",
    englishDefinition: "natural landscape",
    sentenceSimplified: "三峡大坝破坏了长江流域的自然景观。",
    sentenceTraditional: "三峽大壩破壞了長江流域的自然景觀。",
    vocabLink: "/phrase/triptochina/group/15/vocabulary/4",
  },
  {
    lesson: "31",
    simplified: "生态环境",
    traditional: "生態環境",
    pinyin: "sheng1 tai4 huan2 jing4",
    englishDefinition: "ecosystem",
    sentenceSimplified: "三峡大坝破坏了长江流域的生态环境。",
    sentenceTraditional: "三峽大壩破壞了長江流域的生態環境。",
    vocabLink: "/phrase/triptochina/group/15/vocabulary/5",
  },
  {
    lesson: "31",
    simplified: "有利有弊",
    traditional: "有利有弊",
    pinyin: "you3 li4 you3 bi4",
    englishDefinition: "pros and cons",
    sentenceSimplified: "三峡大坝的建设有利有弊。",
    sentenceTraditional: "三峽大壩的建設有利有弊。",
    vocabLink: "/phrase/triptochina/group/15/vocabulary/6",
  },
  {
    lesson: "31",
    simplified: "不以为然",
    traditional: "不以為然",
    pinyin: "bu4 yi3 wei2 ran2",
    englishDefinition: "(I) don't think so; disagree",
    sentenceSimplified:
      "我对你的看法不以为然。在我看来，发展经济的同时也能保护环境。",
    sentenceTraditional:
      "我對你的看法不以為然。在我看來，發展經濟的同時也能保護環境。",
      vocabLink: "/phrase/triptochina/group/15/vocabulary/7",
  },
  {
    lesson: "31",
    simplified: "保护环境",
    traditional: "保護環境",
    pinyin: "bao3 hu4 huan2 jing4",
    englishDefinition: "protect environment",
    sentenceSimplified: "保护环境是每个人的责任。",
    sentenceTraditional: "保護環境是每個人的責任。",
    vocabLink: "/phrase/triptochina/group/15/vocabulary/8",
  },
  {
    lesson: "31",
    simplified: "破坏环境",
    traditional: "破壞環境",
    pinyin: "po4 huai4 huan2 jing4",
    englishDefinition: "destroy environment",
    sentenceSimplified:
      "经济发展一方面破坏环境，另一方面却也提高人民的生活水平。 ",
    sentenceTraditional:
      "經濟發展一方面破壞環境，另一方面卻也提高人民的生活水平。",
      vocabLink: "/phrase/triptochina/group/15/vocabulary/9",
  },
  {
    lesson: "31",
    simplified: "保持原始",
    traditional: "保持原始",
    pinyin: "bao3 chi2 yuan2 shi3",
    englishDefinition: "keep it original",
    sentenceSimplified: "保持原始并不等于保护环境。",
    sentenceTraditional: "保持原始並不等于保護環境。",
    vocabLink: "/phrase/triptochina/group/16/vocabulary/0",
  },
  {
    lesson: "31",
    simplified: "反对改变",
    traditional: "反對改變",
    pinyin: "fan3 dui4 gai3 bian4",
    englishDefinition: "against change",
    sentenceSimplified: "有些政府官员很保守，反对改变。",
    sentenceTraditional: "有些政府官員很保守，反對改變。",
    vocabLink: "/phrase/triptochina/group/16/vocabulary/1",
  },
  {
    lesson: "31",
    simplified: "电子邮件",
    traditional: "電子郵件",
    pinyin: "dian4 zi3 you2 jian4",
    englishDefinition: "e-mail",
    sentenceSimplified: "我每天都发很多封电子邮件。",
    sentenceTraditional: "我每天都發很多封電子郵件。",
    vocabLink: "/phrase/triptochina/group/16/vocabulary/2",
  },
  {
    lesson: "31",
    simplified: "迁离家园",
    traditional: "遷離家園",
    pinyin: "qian1 li2 jia1 yuan2",
    englishDefinition: "move away from home",
    sentenceSimplified: "大坝的建设造成许多人迁离家园。",
    sentenceTraditional: "大壩的建設造成許多人遷離家園。",
    vocabLink: "/phrase/triptochina/group/16/vocabulary/3",
  },
  {
    lesson: "31",
    simplified: "交通便利",
    traditional: "交通便利",
    pinyin: "jiao1 tong1 bian4 li4",
    englishDefinition: "convenient transportation",
    sentenceSimplified: "这个地方交通便利，是不错的选择。",
    sentenceTraditional: "這個地方交通便利，是買房子不錯的選擇。",
    vocabLink: "/phrase/triptochina/group/16/vocabulary/4",
  },
  {
    lesson: "32",
    simplified: "风景优美",
    traditional: "風景優美",
    pinyin: "feng1 jing3 you1 mei3",
    englishDefinition: "beautiful landscape",
    sentenceSimplified: "这个地方风景优美，物产丰富。",
    sentenceTraditional: "這個地方風景優美，物産豐富。",
    vocabLink: "/phrase/triptochina/group/16/vocabulary/5",
  },
  {
    lesson: "32",
    simplified: "物产丰富",
    traditional: "物產豐富",
    pinyin: "wu4 chan3 feng1 fu4",
    englishDefinition: "rich resources",
    sentenceSimplified: "苏州和杭州风景优美，物产丰富。",
    sentenceTraditional: "蘇州和杭州風景優美，物産豐富。",
    vocabLink: "/phrase/triptochina/group/16/vocabulary/6",
  },
  {
    lesson: "32",
    simplified: "上有天堂, 下有苏杭",
    traditional: "上有天堂, 下有蘇杭",
    pinyin: "shang4 you3 tian1 tang2, xia4 you3 su1 hang2",
    englishDefinition: "up there is heaven, down here is suhang",
    sentenceSimplified: "中国人常说：上有天堂，下有苏杭。",
    sentenceTraditional: "中國人常說：上有天堂，下有蘇杭。",
    vocabLink: "/phrase/triptochina/group/16/vocabulary/7",
  },
  {
    lesson: "32",
    simplified: "鱼米之乡",
    traditional: "魚米之鄉",
    pinyin: "yu2 mi3 zhi1 xiang1",
    englishDefinition: "a land of plenty",
    sentenceSimplified: "浙江省和江苏省也被叫做“鱼米之乡“。",
    sentenceTraditional: "浙江省和江蘇省也被叫做“魚米之鄉“。",
    vocabLink: "/phrase/triptochina/group/16/vocabulary/8",
  },
  {
    lesson: "32",
    simplified: "小桥流水",
    traditional: "小橋流水",
    pinyin: "xiao3 qiao2 liu2 shui3",
    englishDefinition: "little bridge and running water",
    sentenceSimplified: "在江南旅游时，到处都见得到“小桥流水”的景象。",
    sentenceTraditional: "在江南旅遊時，到處都見得到“小橋流水”的景象。",
    vocabLink: "/phrase/triptochina/group/16/vocabulary/9",
  },
  {
    lesson: "32",
    simplified: "审美标准",
    traditional: "審美標準",
    pinyin: "shen3 mei3 biao1 zhun3",
    englishDefinition: "aesthetic judgment standards",
    sentenceSimplified:
      "由于文化上的不同，中国人和美国人的审美标准也有些差异。",
    sentenceTraditional:
      "由于文化上的不同，中國人和美國人的審美標准也有些差異。",
      vocabLink: "/phrase/triptochina/group/17/vocabulary/0",
  },
  {
    lesson: "32",
    simplified: "杏花春雨",
    traditional: "杏花春雨",
    pinyin: "xing4 hua1 chun1 yu3",
    englishDefinition: "apricot blossom and spring rain",
    sentenceSimplified: "“小桥流水”，“杏花春雨”都是古代文人歌咏的对象。",
    sentenceTraditional: "“小橋流水”，“杏花春雨”都是古代文人歌詠的對象。",
    vocabLink: "/phrase/triptochina/group/17/vocabulary/1",
  },
  {
    lesson: "32",
    simplified: "黄土高原",
    traditional: "黃土高原",
    pinyin: "huang2 tu3 gao1 yuan2",
    englishDefinition: "loess plateau",
    sentenceSimplified: "我听说黄土高原的景色和江南的景色截然不同。",
    sentenceTraditional: "我聽說黃土高原的景色和江南的景色截然不同。",
    vocabLink: "/phrase/triptochina/group/17/vocabulary/2",
  },
  {
    lesson: "32",
    simplified: "佛教寺庙",
    traditional: "佛教寺廟",
    pinyin: "fo2 jiao4 si4 miao4",
    englishDefinition: "Buddhist temple",
    sentenceSimplified: "在杭州，到处都看得到佛教寺庙。",
    sentenceTraditional: "在杭州，到處都看得到佛教寺廟。",
    vocabLink: "/phrase/triptochina/group/17/vocabulary/3",
  },
  {
    lesson: "32",
    simplified: "政治中心",
    traditional: "政治中心",
    pinyin: "zheng4 zhi4 zhong1 xin1",
    englishDefinition: "political center",
    sentenceSimplified: "北京是中国的政治中心。",
    sentenceTraditional: "北京是中國的政治中心。",
    vocabLink: "/phrase/triptochina/group/17/vocabulary/4",
  },
  {
    lesson: "32",
    simplified: "文化中心",
    traditional: "文化中心",
    pinyin: "wen2 hua4 zhong1 xin1",
    englishDefinition: "cultural center",
    sentenceSimplified: "北京是中国的文化中心。",
    sentenceTraditional: "北京是中國的文化中心。",
    vocabLink: "/phrase/triptochina/group/17/vocabulary/5",
  },
  {
    lesson: "32",
    simplified: "经济中心",
    traditional: "經濟中心",
    pinyin: "jing1 ji4 zhong1 xin1",
    englishDefinition: "economic center",
    sentenceSimplified: "纽约是世界的经济中心。",
    sentenceTraditional: "紐約是世界的經濟中心。",
    vocabLink: "/phrase/triptochina/group/17/vocabulary/6",
  },
  {
    lesson: "32",
    simplified: "古代文人",
    traditional: "古代文人",
    pinyin: "gu3 dai4 wen2 ren2",
    englishDefinition: "ancient literati",
    sentenceSimplified: "古代文人的审美标准可能跟今天的我们有些不同。",
    sentenceTraditional: "古代文人的審美標准可能跟今天的我們有些不同。",
    vocabLink: "/phrase/triptochina/group/17/vocabulary/7",
  },
  {
    lesson: "32",
    simplified: "世界各地",
    traditional: "世界各地",
    pinyin: "shi4 jie4 ge4 di4",
    englishDefinition: "all over the world",
    sentenceSimplified: "在纽约一定找得到来自世界各地的朋友。",
    sentenceTraditional: "在紐約一定找得到來自世界各地的朋友。",
    vocabLink: "/phrase/triptochina/group/17/vocabulary/8",
  },
  {
    lesson: "33",
    simplified: "高楼大厦",
    traditional: "高樓大廈",
    pinyin: "gao1 lou2 da4 sha4",
    englishDefinition: "high-rise building",
    sentenceSimplified: "纽约到处都是高楼大厦。",
    sentenceTraditional: "紐約到處都是高樓大廈。",
    vocabLink: "/phrase/triptochina/group/17/vocabulary/9",
  },
  {
    lesson: "33",
    simplified: "进行改革",
    traditional: "進行改革",
    pinyin: "jin4 xing2 gai3 ge2",
    englishDefinition: "conduct reform",
    sentenceSimplified: "泰国学运：要求政府对学校教育进行改革。",
    sentenceTraditional: "泰國學運：要求政府對學校教育進行改革。",
    vocabLink: "/phrase/triptochina/group/18/vocabulary/0",
  },
  {
    lesson: "33",
    simplified: "衣食住行",
    traditional: "衣食住行",
    pinyin: "yi1 shi2 zhu4 xing2",
    englishDefinition: "food, clothing, dwelling and transportation",
    sentenceSimplified:
      "要看改革开放以来的最大变化,老百姓的衣食住行就是最好的例证。",
    sentenceTraditional:
      "要看改革開放以來的最大變化,老百姓的衣食住行就是最好的例證。",
      vocabLink: "/phrase/triptochina/group/18/vocabulary/1",
  },
  {
    lesson: "33",
    simplified: "通货膨胀",
    traditional: "通貨膨脹",
    pinyin: "tong1 huo4 peng2 zhang4",
    englishDefinition: "inflation",
    sentenceSimplified: "当前我国商品充足，物价稳定，不必担心通货膨胀。",
    sentenceTraditional: "當前我國商品充足，物價穩定，不必擔心通貨膨脹。",
    vocabLink: "/phrase/triptochina/group/18/vocabulary/2",
  },
  {
    lesson: "33",
    simplified: "通货紧缩",
    traditional: "通貨緊縮",
    pinyin: "tong1 huo4 jin3 suo1",
    englishDefinition: "deflation",
    sentenceSimplified:
      "人口减少时，过度投资的后果将逐渐严重，形成通货紧缩的循环。",
    sentenceTraditional:
      "人口減少時，過度投資的後果將逐漸嚴重，形成通貨緊縮的循環。",
      vocabLink: "/phrase/triptochina/group/18/vocabulary/3",
  },
  {
    lesson: "33",
    simplified: "社会道德",
    traditional: "社會道德",
    pinyin: "she4 hui4 dao4 de2",
    englishDefinition: "social morality",
    sentenceSimplified:
      "形成良好的社会道德风尚,是社会主义精神文明建设的重要内容。",
    sentenceTraditional:
      "形成良好的社會道德風尚,是社會主義精神文明建設的重要內容。",
      vocabLink: "/phrase/triptochina/group/18/vocabulary/4",
  },
  {
    lesson: "33",
    simplified: "偷窃抢劫",
    traditional: "偷竊搶劫",
    pinyin: "tou1 qie4 qiang3 jie2",
    englishDefinition: "theft and robbery",
    sentenceSimplified: "社会治安不好的国家常常发生偷窃抢劫。",
    sentenceTraditional: "社會治安不好的國家常常發生偷竊搶劫。",
    vocabLink: "/phrase/triptochina/group/18/vocabulary/5",
  },
  {
    lesson: "34",
    simplified: "海峡两岸",
    traditional: "海峽兩岸",
    pinyin: "hai3 xia2 liang3 an4",
    englishDefinition: "both sides of the strait",
    sentenceSimplified: "海峡两岸的作家就创作问题开诚布公地交换了看法。",
    sentenceTraditional: "海峽兩岸的作家就創作問題開誠布公地交換了看法。",
    vocabLink: "/phrase/triptochina/group/18/vocabulary/6",
  },
  {
    lesson: "34",
    simplified: "中日战争",
    traditional: "中日戰爭",
    pinyin: "zhong1 ri4 zhan4 zheng1",
    englishDefinition: "Sino-Japanese war",
    sentenceSimplified: "1937年中日战争爆发。",
    sentenceTraditional: "1937年中日戰爭爆發。",
    vocabLink: "/phrase/triptochina/group/18/vocabulary/7",
  },
  {
    lesson: "34",
    simplified: "中国大陆",
    traditional: "中國大陸",
    pinyin: "zhong1 guo2 da4 lu4",
    englishDefinition: "mainland",
    sentenceSimplified:
      "自从中国大陆对外开放后，旅游业便迅速发展，至今方兴未艾。",
    sentenceTraditional:
      "自從中國大陸對外開放後，旅遊業便迅速發展，至今方興未艾。",
      vocabLink: "/phrase/triptochina/group/18/vocabulary/8",
  },
  {
    lesson: "34",
    simplified: "行政区域",
    traditional: "行政區域",
    pinyin: "xing2 zheng4 qu1 yu4",
    englishDefinition: "administrative regions",
    sentenceSimplified: "纽约市由五个行政区域组成。",
    sentenceTraditional: "紐約市由五個行政區域組成。",
    vocabLink: "/phrase/triptochina/group/18/vocabulary/9",
  },
  {
    lesson: "34",
    simplified: "明朝末年",
    traditional: "明朝末年",
    pinyin: "ming2 chao2 mo4 nian2",
    englishDefinition: "late Ming dynasty",
    sentenceSimplified: "明朝末年暴发了李自成领导的农民起义。",
    sentenceTraditional: "明朝末年暴發了李自成領導的農民起義。",
    vocabLink: "/phrase/triptochina/group/19/vocabulary/0",
  },
  {
    lesson: "34",
    simplified: "剧烈冲突",
    traditional: "劇烈衝突",
    pinyin: "ju4 lie4 chong1 tu1",
    englishDefinition: "violent conflict",
    sentenceSimplified: "两人在思想观念上发生了剧烈冲突。",
    sentenceTraditional: "兩人在思想觀念上發生了劇烈沖突。",
    vocabLink: "/phrase/triptochina/group/19/vocabulary/1",
  },
  {
    lesson: "34",
    simplified: "促进交流",
    traditional: "促進交流",
    pinyin: "cu4 jin4 jiao1 liu2",
    englishDefinition: "promote communication",
    sentenceSimplified: "两国同意促进交流和贸易。",
    sentenceTraditional: "兩國同意促進交流和貿易。",
    vocabLink: "/phrase/triptochina/group/19/vocabulary/2",
  },
  {
    lesson: "34",
    simplified: "增进了解",
    traditional: "增進了解",
    pinyin: "zeng1 jin4 liao3 jie3",
    englishDefinition: "improve understanding",
    sentenceSimplified: "这次访美的主要目的是增进了解。",
    sentenceTraditional: "這次訪美的主要目的是增進了解。",
    vocabLink: "/phrase/triptochina/group/19/vocabulary/3",
  },
  {
    lesson: "35",
    simplified: "完全不同",
    traditional: "完全不同",
    pinyin: "wan2 quan2 bu4 tong2",
    englishDefinition: "completely different",
    sentenceSimplified: "面对压力，我们每个人的反应都完全不同。",
    sentenceTraditional: "面對壓力，我們每個人的反應都完全不同。",
    vocabLink: "/phrase/triptochina/group/19/vocabulary/4",
  },
  {
    lesson: "35",
    simplified: "中国人民",
    traditional: "中國人民",
    pinyin: "zhong1 guo2 ren2 min2",
    englishDefinition: "Chinese people",
    sentenceSimplified:
      "我国外交部的这一项声明,充分表达了中国人民维护国家主权的强烈愿望。",
    sentenceTraditional:
      "我國外交部的這一項聲明,充分表達了中國人民維護國家主權的強烈願望。",
      vocabLink: "/phrase/triptochina/group/19/vocabulary/5",
  },
  {
    lesson: "35",
    simplified: "文学作品",
    traditional: "文學作品",
    pinyin: "wen2 xue2 zuo4 pin3",
    englishDefinition: "literature",
    sentenceSimplified: "优秀的文学作品都具有深刻的思想内容和较高的艺术水准。",
    sentenceTraditional: "優秀的文學作品都具有深刻的思想內容和較高的藝術水准。",
    vocabLink: "/phrase/triptochina/group/19/vocabulary/6",
  },
  {
    lesson: "35",
    simplified: "共同语言",
    traditional: "共同語言",
    pinyin: "gong4 tong2 yu3 yan2",
    englishDefinition: "common language",
    sentenceSimplified: "真正能理解自己的心意，有共同语言的人才能称得上知音。",
    sentenceTraditional: "真正能理解自己的心意，有共同語言的人才能稱得上知音。",
    vocabLink: "/phrase/triptochina/group/19/vocabulary/7",
  },
  {
    lesson: "35",
    simplified: "大众媒体",
    traditional: "大眾媒體",
    pinyin: "da4 zhong4 mei2 ti3",
    englishDefinition: "mass media",
    sentenceSimplified: "这门课程结合了对大众媒体的历史和评论性分析。",
    sentenceTraditional: "這門課程結合了對大衆媒體的曆史和評論性分析。",
    vocabLink: "/phrase/triptochina/group/19/vocabulary/8",
  },
  {
    lesson: "35",
    simplified: "偏远地区",
    traditional: "偏遠地區",
    pinyin: "pian1 yuan3 di4 qu1",
    englishDefinition: "remote regions",
    sentenceSimplified: "如今，偏远地区的农民也过上了丰衣足食的好日子。",
    sentenceTraditional: "如今，偏遠地區的農民也過上了豐衣足食的好日子。",
    vocabLink: "/phrase/triptochina/group/19/vocabulary/9",
  },
  {
    lesson: "36",
    simplified: "从此以后",
    traditional: "從此以後",
    pinyin: "cong2 ci3 yi3 hou4",
    englishDefinition: "from then on",
    sentenceSimplified: "父母对孩子的教育，孩子都记在心里，从此以后变得懂事。",
    sentenceTraditional: "父母對孩子的教育，孩子都記在心裏，從此以後變得懂事。",
    vocabLink: "/phrase/triptochina/group/20/vocabulary/0",
  },
  {
    lesson: "36",
    simplified: "不切实际",
    traditional: "不切實際",
    pinyin: "bu4 qie4 shi2 ji4",
    englishDefinition: "unrealistic, impractical",
    sentenceSimplified: "让我们避免不切实际的想法。",
    sentenceTraditional: "讓我們避免不切實際的想法。",
    vocabLink: "/phrase/triptochina/group/20/vocabulary/1",
  },
  {
    lesson: "36",
    simplified: "自然演进",
    traditional: "自然演進",
    pinyin: "zi4 ran2 yan3 jin4",
    englishDefinition: "natural evolution",
    sentenceSimplified: "这是一个自然演进的过程。",
    sentenceTraditional: "這是一個自然演進的過程。",
    vocabLink: "/phrase/triptochina/group/20/vocabulary/2",
  },
  {
    lesson: "36",
    simplified: "阻碍发展",
    traditional: "阻礙發展",
    pinyin: "zu3 ai4 fa1 zhan3",
    englishDefinition: "impede development",
    sentenceSimplified: "高度关注之下的感情一定是有压力的，反而会阻碍发展。",
    sentenceTraditional: "高度關注之下的感情一定是有壓力的，反而會阻礙發展。",
    vocabLink: "/phrase/triptochina/group/20/vocabulary/3",
  },
  {
    lesson: "36",
    simplified: "历史潮流",
    traditional: "歷史潮流",
    pinyin: "li4 shi3 chao2 liu2",
    englishDefinition: "historical trend",
    sentenceSimplified: "改革开放是一股不可抗拒的历史潮流。",
    sentenceTraditional: "改革開放是一股不可抗拒的曆史潮流。",
    vocabLink: "/phrase/triptochina/group/20/vocabulary/4",
  },
  {
    lesson: "36",
    simplified: "过分简化",
    traditional: "過份簡化",
    pinyin: "guo4 fen4 jian3 hua4",
    englishDefinition: "oversimplification",
    sentenceSimplified: "过分简化的结果往往是扭曲现实。 ",
    sentenceTraditional: "過分簡化的結果往往是扭曲現實。",
    vocabLink: "/phrase/triptochina/group/20/vocabulary/5",
  },
  {
    lesson: "36",
    simplified: "主观判断",
    traditional: "主觀判斷",
    pinyin: "zhu3 guan1 pan4 duan4",
    englishDefinition: "Subjective judgement",
    sentenceSimplified: "求职信中应避免自己的主观判断性的语言和评论性的话语。",
    sentenceTraditional: "求職信中應避免自己的主觀判斷性的語言和評論性的話語。",
    vocabLink: "/phrase/triptochina/group/20/vocabulary/6",
  },
  {
    lesson: "36",
    simplified: "客观标准",
    traditional: "客觀標準",
    pinyin: "ke4 guan1 biao1 zhun3",
    englishDefinition: "objective standard",
    sentenceSimplified: "需要有一个客观标准来评判。",
    sentenceTraditional: "需要有一個客觀標准來評判。",
    vocabLink: "/phrase/triptochina/group/20/vocabulary/7",
  },
  {
    lesson: "36",
    simplified: "基本标准",
    traditional: "基本標準",
    pinyin: "ji1 ben3 biao1 zhun3",
    englishDefinition: "basic standard",
    sentenceSimplified: "道德是是做人的基本标准。",
    sentenceTraditional: "遵守道德是做人的基本標准。",
    vocabLink: "/phrase/triptochina/group/20/vocabulary/8",
  },
  {
    lesson: "36",
    simplified: "文字改革",
    traditional: "文字改革",
    pinyin: "wen2 zi4 gai3 ge2",
    englishDefinition: "reform of the Chinese writing system",
    sentenceSimplified: "文字改革的目的是为了便于大众掌握。",
    sentenceTraditional: "文字改革的目的是為了便于大衆掌握。",
    vocabLink: "/phrase/triptochina/group/20/vocabulary/9",
  },
];

export default unsplitTTCdata;
