import React, { useMemo, useState } from 'react';
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table';
import { Paper, IconButton, InputBase, Divider } from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';

import { makeStyles } from '@material-ui/core/styles';
import "./css/chengyutable.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
  },
  rowHeader: {
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "bold",
    [theme.breakpoints.down('sm')]: {
      fontSize: "12px"
    },
    "& #simplified": {
      width: "15vw",
      border: "1px solid black",
      borderRight: 0,
      paddingLeft: "5px",
      [theme.breakpoints.down('sm')]: {
        padding: "0 5px 0 2px",
      }
    },
    "& #traditional": {
      width: "15vw",
      border: "1px solid black",
      borderRight: 0,
      paddingLeft: "5px",
      [theme.breakpoints.down('sm')]: {
        padding: "0 5px 0 2px",
      }
    },
    "& #pinyin": {
      width: "15vw",
      border: "1px solid black",
      borderRight: 0,
      paddingLeft: "5px",
      [theme.breakpoints.down('sm')]: {
        width: "20vw",
        padding: "0 5px 0 2px",
      }
    },
    "& #english_definition": {
      width: "25vw",
      border: "1px solid black",
      paddingLeft: "5px",
      [theme.breakpoints.down('sm')]: {
        width: "20vw",
        padding: "0 5px 0 2px",
      }
    }, 
    "& #bookmark": {
      border: "1px solid black",
      borderLeft: 0,
      padding: "0 10px 0 5px",
      [theme.breakpoints.down('sm')]: {
        padding: "0 5px 0 2px",
      }
    }

  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    width: '75%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontFamily: 'testFont, sans-serif',
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const searchClass = useStyles();
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <Paper className={searchClass.search}>
      <InputBase
        className={searchClass.input}
        placeholder={`Search for a phrase or idiom in ${count} records...`}
        inputProps={{ 'aria-label': 'search for a phrase or idiom' }}
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      <Divider className={searchClass.divider} orientation="vertical" />
      <IconButton className={searchClass.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

const ChengyuTable = ({ columnHeaders, unsplitData }) => {
  const classes = useStyles();
  const data = useMemo(() => unsplitData.map(dataPoint => { return dataPoint }), [unsplitData]);
  const columns = useMemo(() => columnHeaders, [columnHeaders]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy)

  return (
    <div className={classes.root}>
      <table {...getTableProps()} style={{ borderSpacing: '0px' }}>
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
              <br />
            </th>
          </tr>
          {// Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()} className={classes.rowHeader}>
                {// Loop over the headers in each row
                  headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))} id={column.render('Header').toLowerCase().replace(/ /g, "_")}>
                      {// Render the header
                        column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
              </tr>
            ))}
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
            rows.map(row => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {// Loop over the rows cells
                    row.cells.map((cell, index) => {
                      var currRow = `col${index.toString()}`;
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className={currRow}>
                          {// Render the cell contents
                            cell.render('Cell')}
                        </td>
                      )
                    })}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default ChengyuTable;