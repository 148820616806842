import React, { Component } from "react";
import { Route, Switch, Link, Redirect, withRouter } from "react-router-dom";
import { get } from "lodash";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Switchs from "@material-ui/core/Switch";
import cookie from "js-cookie";
import NotFoundPage from "./components/NotFound";
import Home from "./components/Home";
import VideoPage from "./components/VideoPage";
import ModuleMenu from "./components/ModuleMenu";
import TaskPage from "./components/TaskPage";
import ChengyuMenu from "./components/ChengyuTopics";
import ChengyuDefinition from "./components/ChengyuDefinition";
import ChengyuTopicPage from "./components/ChengyuTopicPage";
import TradChoice from "./components/TradChoicePage";
import ExercisePage from "./components/ExercisePage";
import ChengyuHome from "./components/Chengyu/Home";
import VocabListPage from "./components/Chengyu/VocabListPage";
import BookmarkPage from "./components/Chengyu/BookmarkPage";

import ChengyuGroup from "./components/Chengyu/Group";
import ChengyuPractices from "./components/Chengyu/Practices";
import Vocabulary from "./components/Chengyu/Vocabulary";
import CrosswordExercise from "./components/Chengyu/Practices/CrosswordExercise";
import ChengyuLanding from "./components/Chengyu/ChengyuLanding";

const modelsb = require("./models/index");

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      simplified: false,
      open: false,
      redirect: false,
      route: "/",
      models: modelsb,
      showLanguageSwitcher: true,
      version: "traditional",
      items: [
        {
          name: "Directions 1",
          namel: "directions1",
          route: "/modules/directions1",
        },
        {
          name: "Food",
          namel: "food",
          route: "/modules/food",
        },
        {
          name: "Directions 2",
          namel: "directions2",
          route: "/modules/directions2",
        },
        {
          name: "Shopping",
          namel: "shopping",
          route: "/modules/shopping",
        },
        {
          name: "Travel",
          namel: "travel",
          route: "/modules/travel",
        },
      ],
      videos: [],
      navName: "Video Modules",
      navRoute: "/",
      currentList: [],
    };

    this.lists = {
      modules: {
        list: this.state.items,
        navName: "Video Modules",
        navRoute: "/",
      },
      phrase: {
        triptochina: {
          list: Array(20)
            .fill(null)
            .map((el, i) => ({
              name: `Group ${i + 1}`,
              namel: `group${i + 1}`,
              route: `/phrase/triptochina/group/${i + 1}/vocabulary`,
            })),
          navName: "Phrases",
          navRoute: "/phrase/triptochina",
        },
      },
      chengyu: {
        0: {
          list: Array(3)
            .fill(null)
            .map((el, i) => ({
              name: `Chengyu ${i + 1}`,
              namel: `chengyu${i + 1}`,
              route: `/chengyu/${i + 1}/`,
            })),
          navName: "Chengyu",
          navRoute: "/chengyu",
        },
        1: {
          list: [
            {
              name: "Nature",
              namel: "nature",
              route: "/chengyu/1/0/nature/",
            },
            {
              name: "Numbers",
              namel: "numbers",
              route: "/chengyu/1/0/numbers",
            },
            {
              name: "Animals",
              namel: "animals",
              route: "/chengyu/1/0/animals",
            },
            {
              name: "Human",
              namel: "animals",
              route: "/chengyu/1/0/human",
            },
            {
              name: "Antonyms",
              namel: "antonyms",
              route: "/chengyu/1/0/antonyms",
            },
            {
              name: "Measurements",
              namel: "antonyms",
              route: "/chengyu/1/0/measurements",
            },
            {
              name: "Positive Connotation",
              namel: "positive connotation",
              route: "/chengyu/1/0/pos_connotation",
            },
            {
              name: "Negative Connotation",
              namel: "negative connotation",
              route: "/chengyu/1/0/neg_connotation",
            },
            {
              name: "Chengyu Home",
              namel: "home",
              route: "/chengyu/",
            },
          ],
          navName: "Chengyu 1",
          navRoute: "/chengyu/1",
        },
        2: {
          list: Array(5)
            .fill(null)
            .map((el, i) => ({
              name: `Group ${i + 1}`,
              namel: `group${i + 1}`,
              route: `/chengyu/2/group/${i + 1}/vocabulary`,
            })),
          navName: "Chengyu 2",
          navRoute: "/chengyu/2",
        },
        3: {
          list: Array(20)
            .fill(null)
            .map((el, i) => ({
              name: `Group ${i + 1}`,
              namel: `group${i + 1}`,
              route: `/chengyu/3/group/${i + 1}/vocabulary`,
            })),
          navName: "Chengyu 3",
          navRoute: "/chengyu/3",
        },
      },
    };
  }

  componentDidMount = () => {
    this.setNavbar();
    this.setState({
      showLanguageSwitcher: window.location.pathname.split("/")[2] !== "1",
    });

    const simplified = cookie.get("simplified") === "true";
    if (simplified !== undefined) {
      this.setState({ simplified });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.pathChange(prevProps)) {
      this.setNavbar();
    }
  }

  pathChange = (prevProps) => {
    const prevPath = prevProps.location.pathname.split("/");
    const currPath = this.props.location.pathname.split("/");
    return currPath[1] !== prevPath[1] || currPath[2] !== prevPath[2];
  };

  setNavbar = () => {
    const dataLocation = this.props.location.pathname.split("/");
    const root = dataLocation[1];
    const lesson = dataLocation[2] || 0;
    const currentList = get(
      this.lists,
      `${root}.${lesson}.list`,
      this.lists.modules.list
    );
    const navName = get(
      this.lists,
      `${root}.${lesson}.navName`,
      this.lists.modules.navName
    );
    const navRoute = get(
      this.lists,
      `${root}.${lesson}.navRoute`,
      this.lists.modules.navRoute
    );
    this.setState({ currentList, navName, navRoute });
  };

  handleDrawerOpen = () => {
    const { items, models } = this.state;
    this.setNavbar();

    this.setState({
      open: true,
    });

    // reset video list if on homepage
    if (window.location.pathname === "/") {
      this.setState({
        videos: [],
      });
    } else {
      // must now be on a module or one of its subpages
      // for each module, check which module current page belongs to
      // when found, set videos to the videos of the module (from models/index)
      // render redirect buttons using this new video array in taskbar
      for (let i = 0; i < items.length; i++) {
        if (window.location.pathname.search(items[i].route) !== -1) {
          const shortname = items[i].namel;
          this.setState({
            videos: models.default[shortname],
          });
        }
      }
    }
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  setRedirect = (rt) => {
    this.setState({
      redirect: true,
      route: rt,
      open: false,
    });
  };

  renderRedirect = () => {
    const { redirect, route } = this.state;

    if (!redirect) {
      return null;
    }

    this.setState({
      redirect: false,
    });

    return <Redirect to={route} />;
  };

  handleCheck = (event) => {
    cookie.set("simplified", !this.state.simplified);
    this.setState({
      simplified: !this.state.simplified,
    });
  };

  render() {
    const {
      open,
      items,
      videos,
      currentList,
      showLanguageSwitcher,
      navName,
      navRoute,
    } = this.state;
    return (
      <div className="root-container">
        <AppBar position="relative" className="app-bar">
          <Toolbar disableGutters={!open}>
            {!open ? (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            ) : null}
            <section style={{ display: "contents" }}>
              {
                <h3>
                  <Link
                    to={navRoute}
                    style={{
                      textDecoration: "none",
                      color: "#ffffff",
                    }}
                  >
                    {navName}
                  </Link>
                </h3>
              }
              &nbsp;&nbsp;&nbsp;
              {showLanguageSwitcher && (
                <React.Fragment>
                  <label
                    style={{
                      color: this.state.simplified ? "white" : "#79CFF2",
                    }}
                  >
                    Traditional
                  </label>
                  <Switchs
                    color="default"
                    onChange={this.handleCheck}
                    checked={this.state.simplified}
                  />
                  <label
                    style={{
                      color: this.state.simplified ? "#79CFF2" : "white",
                    }}
                  >
                    Simplified
                  </label>{" "}
                </React.Fragment>
              )}
            </section>
          </Toolbar>
        </AppBar>
        <Drawer variant="persistent" anchor="left" open={open}>
          <div>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {currentList !== undefined ? (
            <List>
              {this.renderRedirect()}
              {currentList.map((obj, index) => (
                <ListItem
                  button
                  key={obj.name}
                  onClick={() => this.setRedirect(obj.route)}
                >
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={obj.name} />
                </ListItem>
              ))}
            </List>
          ) : (
            <List />
          )}

          <Divider />

          {items !== undefined ? (
            <List>
              {videos.map((obj, index) => (
                <ListItem
                  button
                  key={obj.title}
                  onClick={() => this.setRedirect(obj.startUrl)}
                >
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={obj.title} />
                </ListItem>
              ))}
            </List>
          ) : (
            <List />
          )}
        </Drawer>
        <main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/modules/:moduleId/:videoId/:taskId"
              render={(matchProps) => {
                return (
                  <TaskPage
                    {...matchProps}
                    // {...this.props}
                    simplified={this.state.simplified}
                  />
                );
              }}
            />
            <Route
              exact
              path="/modules/:moduleId/:videoId"
              render={(matchProps) => (
                <VideoPage
                  {...matchProps}
                  // {...this.props}
                  simplified={this.state.simplified}
                />
              )}
            />
            <Route path="/modules/:moduleId" component={ModuleMenu} />
            <Route exact path="/chengyu/" component={ChengyuLanding} />
            <Route exact path="/chengyu/1/" component={TradChoice} />

            <Route exact path="/chengyu/1/:tradId" component={ChengyuMenu} />

            <Route
              exact
              path="/chengyu/1/:tradId/:chengyuTopic"
              component={ChengyuTopicPage}
            />

            <Route
              exact
              path="/chengyu/1/:tradId/:chengyuTopic/exercises"
              component={ExercisePage}
            />

            <Route
              exact
              path="/(chengyu|phrase)/1/group/:groupId/vocabulary/:wordId/:tradId"
              render={(props) => (
                <ChengyuDefinition
                  {...props}
                  language={props.tradId === 0 ? "simplified" : "traditional"}
                />
              )}
            />

            <Route
              exact
              path="/crossword/:groupId/:tradId?/:problemChunkStart?"
              render={(props) => (
                <CrosswordExercise
                  {...props}
                  language={
                    this.state.simplified ? "simplified" : "traditional"
                  }
                  dataName={"chengyu"}
                  chunkBy={4}
                />
              )}
            />

            <Route
              exact
              path="/(chengyu|phrase)/:lesson"
              render={(props) => (
                <ChengyuHome
                  {...props}
                  basePath={
                    props.location.pathname.includes("chengyu")
                      ? "chengyu"
                      : "phrase"
                  }
                />
              )}
            />
            <Route
              exact
              path={[
                "/(chengyu|phrase)/:lesson/group/:groupId/",
                "/(chengyu|phrase)/:lesson/group/:groupId/vocabulary/",
              ]}
              render={(props) => (
                <Vocabulary
                  {...props}
                  language={
                    this.state.simplified ? "simplified" : "traditional"
                  }
                />
              )}
            />

            <Route
              exact
              path="/(chengyu|phrase)/:lesson/group/:groupId/practice"
              component={ChengyuGroup}
            />

            <Route
              exact
              path="/(chengyu|phrase)/:lesson/group/:groupId/practice/:practiceId/"
              render={(props) => (
                <ChengyuPractices
                  {...props}
                  dataName="chengyu3Data"
                  language={
                    this.state.simplified ? "simplified" : "traditional"
                  }
                />
              )}
            />

            <Route
              exact
              path="/(chengyu|phrase)/:lesson/group/:groupId/vocabulary/:wordId/"
              render={(props) => (
                <ChengyuDefinition
                  {...props}
                  language={
                    this.state.simplified ? "simplified" : "traditional"
                  }
                />
              )}
            />

            <Route exact path="/(chengyu|phrase)/:lesson/vocabularylist/" render={props => (<VocabListPage {...props}
              language={this.state.simplified ? "simplified" : "traditional"} />)} />
              
            <Route exact path="/(chengyu|phrase)/:lesson/bookmarks/" render={props => (<BookmarkPage {...props}
              language={this.state.simplified ? "simplified" : "traditional"} />)} />

            <Route component={NotFoundPage} />
          </Switch>
        </main>
      </div>
    );
  }
}

export default withRouter(App);
