import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Chinese from "chinese-s2t";
import PlayAudio from "../PlayAudio";

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

const CharacterView = styled.div`
  text-align: center;
  margin: 5px;
  padding: 4px;
  min-height: 50px;
  @media (orientation: landscape) {
    font-size: 4vw;
    width: 75px;
  }

  @media (orientation: portrait) {
    font-size: 25px;
    width: 30px;
  }
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
`;

export default function DragRadicals({
  question,
  choices,
  answers,
  images,
  showAnswer,
  simplified,
  audio,
  taskSpecificParams,
}) {
  const [currAnswer, setCurrAnswer] = useState([]);
  const [correct, setCorrect] = useState(false);
  const [errors, setErrors] = useState(false);
  const [selected, setSelected] = useState(0);
  const [hasMeaning, setHasMeaning] = useState(false);

  const inputAnswer = (index, i) => {
    const imgSel = selected !== "None" ? images[selected] : "";
    const newAns = currAnswer.slice(0);
    newAns[index] = [imgSel];
    setCurrAnswer(newAns);
    setSelected("None");
  };

  const checkAnswer = () => {
    setErrors(true);
    const localCorrect = JSON.stringify(currAnswer) === JSON.stringify(answers);
    if (localCorrect) showAnswer();
    setCorrect(localCorrect);
  };

  const retry = () => {
    setErrors(false);
    setCurrAnswer([]);
  };

  if (taskSpecificParams !== undefined) {
    if (taskSpecificParams.hasMeaning) {
      setHasMeaning(true);
    }
  }
  return (
    <React.Fragment>
      <StyledQuestion>
        {question} {("this is", simplified)}
      </StyledQuestion>

      {audio && (
        <audio controls>
          <source src={audio} type="audio/mp3" />
        </audio>
      )}
      <StyledContainer>
        <div
          style={{
            padding: "5px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80%",
            justifyContent: "center",
          }}
        >
          {images.map((choice, index) => (
            <section>
              <Button
                disabled={errors}
                type="button"
                variant={selected === index ? "contained" : ""}
                color={selected === index ? "secondary" : ""}
                onClick={() => setSelected(index)}
                onKeyDown={() => setSelected(index)}
              >
                <span style={{ fontSize: 30 }}>
                  {simplified ? Chinese.t2s(choice) : choice}
                </span>
              </Button>
            </section>
          ))}
        </div>

        {choices.map((choice, index) => {
          const blanksArray = [];
          for (let i = 0; i < choice.blanks; i++) {
            let correctColor = "White";
            if (errors && currAnswer[index]) {
              correctColor =
                currAnswer[index][i] === answers[index][i] ? "green" : "red";
            } else if (currAnswer[index] && currAnswer[index][i])
              correctColor = "black";
            const displayChar =
              currAnswer[index] && currAnswer[index][i]
                ? currAnswer[index][i]
                : "";
            blanksArray.push(
              <Button
                disabled={errors}
                onClick={() => inputAnswer(index, i)}
                style={{ borderStyle: "solid", color: correctColor }}
              >
                <CharacterView>
                  {(simplified
                    ? Chinese.t2s(currAnswer[index])
                    : currAnswer[index]) || "."}
                </CharacterView>
              </Button>
            );
          }
          return (
            <div
              style={{
                width: "40vw",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "2vmax",
              }}
            >
              {<div>{choice.meaning}</div>}
              {choice.question}
              {blanksArray}
            </div>
          );
        })}
      </StyledContainer>
      {!errors && !correct && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => checkAnswer()}
        >
          Check
        </Button>
      )}
      {errors && !correct && (
        <Button variant="contained" color="secondary" onClick={() => retry()}>
          Retry
        </Button>
      )}
    </React.Fragment>
  );
}
