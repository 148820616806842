import React, { useState, useEffect } from "react";
import BookmarkTable from "./BookmarkTable";
import Breadcrumb from "../Breadcrumb";
import { getBreadcrumbRoutes } from "../../helpers/breadcrumbRoutes.js";
import DeleteIcon from '@material-ui/icons/Delete';

const BookmarkPage = props => {
  const url = window.location.pathname;
  const [tradId, setTradId] = useState(1);
  const [module, setModule] = useState("");
  const [bookmarks, setBookmarks] = useState([]);
  const [allBookmarks, setAllBookmarks] = useState([]);

  const removeBookmark = url => {
    let newBookmark = allBookmarks.filter(curr => curr.url !== url);

    localStorage.setItem("bookmarks", JSON.stringify(newBookmark));
    setAllBookmarks(newBookmark);
    setBookmarks(localStorage.getItem("bookmarks") ? JSON.parse(localStorage.getItem("bookmarks")).filter(curr => Boolean(curr[module])) : []);
  }

  useEffect(() => {
    if (url.includes("chengyu")) {
      setModule("chengyu");
    } else if (url.includes("triptochina")) {
      setModule("triptochina");
    }

    if (props.language === "traditional") {
      setTradId(1);
    } else {
      setTradId(0);
    }

    setAllBookmarks(localStorage.getItem("bookmarks") ? JSON.parse(localStorage.getItem("bookmarks")) : []);
    setBookmarks(localStorage.getItem("bookmarks") ? JSON.parse(localStorage.getItem("bookmarks")).filter(curr => Boolean(curr[module])) : []);
  }, [url, module, props.language]);

  return (
    <div>
      <Breadcrumb routes={getBreadcrumbRoutes(props.match)} />
      <div style={{
        textAlign: 'center',
        padding: '0px 10px',
        marginBottom: '20px',
      }}>
        Here are your bookmarks from <em>{module === "chengyu" ? "Chengyu 2" : "A Trip to China"}</em>.
        This page is a quick access to idioms and phrases you want to use for future reference.
        Click on the slider in the toolbar to switch between traditional and simplified Chinese.
        Click on the idiom or phrase to be taken to the respective page. Click on the
        <DeleteIcon fontSize="small" style={{position: 'relative', top: '5px', left: '2px'}}/> icon to remove the bookmark.
        Please note, bookmark data is stored locally, so bookmarks on your current browser will not show up on another browser.
      </div>
      <BookmarkTable module={module} simptrad={tradId} bookmarks={bookmarks} removeBookmark={removeBookmark} />
    </div>
  )
}

export default BookmarkPage;