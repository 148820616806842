import React from "react";
import Grid from "@material-ui/core/Grid";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/blue";
import amber from "@material-ui/core/colors/amber";
import teal from "@material-ui/core/colors/cyan";
import ModuleCard from "./ModuleCard";

export default class Home extends React.PureComponent {
  render() {
    return (
      <div
        style={{
          margin: "25px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <ModuleCard
              route="/modules/directions1"
              avatar="D"
              avatarColor={red[500]}
              activeStep={2}
              nbSteps={4}
              img="/assets/directions.jpg"
              title="Directions 1"
              subTitle="Learn to ask for directions"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ModuleCard
              route="/modules/food"
              avatar="F"
              avatarColor={orange[500]}
              activeStep={0}
              nbSteps={6}
              img="/assets/food.jpg"
              title="Food"
              subTitle="Learn how to order food"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ModuleCard
              route="/modules/directions2"
              avatar="D"
              avatarColor={blue[500]}
              activeStep={3}
              nbSteps={4}
              img="/assets/directions2.jpg"
              title="Directions 2"
              subTitle="Learn to give directions"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ModuleCard
              route="/modules/shopping"
              avatar="S"
              avatarColor={amber[500]}
              activeStep={0}
              nbSteps={4}
              img="/assets/shopping.jpg"
              title="Shopping"
              subTitle="Communicating while shopping"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ModuleCard
              route="/modules/travel"
              avatar="T"
              avatarColor={teal[500]}
              activeStep={0}
              nbSteps={5}
              img="/assets/traveling.jpg"
              title="Travel"
              subTitle="Communicating while traveling"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
