import React, { Component } from "react";
import IncorrectIcon from "../../../icons/IncorrectIcon";
import CorrectIcon from "../../../icons/CorrectIcon";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 12px;
`;

class FillBlankProblem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted_answer: "",
      showAnswer: false,
      isCorrect: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showAnswer !== this.props.showAnswer) {
      this.setState({ showAnswer: this.props.showAnswer });
      if (this.props.showAnswer) {
        this.state.submitted_answer === this.props.answer &&
          this.props.updateScore();
      }
    }
    if (prevProps.showAnswer && !this.props.showAnswer) {
      this.setState({ submitted_answer: "" });
    }
  }
  checkAnswer = () => {
    const { answer } = this.props;
    const { submitted_answer } = this.state;
    let correct = answer === submitted_answer;
    correct && this.props.updateScore();
    this.setState({ showAnswer: true, isCorrect: correct });
  };

  onAnswer = (e) => {
    this.setState({ submitted_answer: e.target.value });
  };
  render() {
    const { question, answer } = this.props;
    const { submitted_answer } = this.state;
    const { showAnswer } = this.state;
    let correct = answer === submitted_answer;
    let correctColor = correct ? "green" : "red";
    let correctIcon = correct ? <CorrectIcon /> : <IncorrectIcon />;

    let correctStyle = {
      color: correctColor,
      float: "left",
      marginRight: "2px",
    };
    return (
      <div>
        <div style={styles.quiz}>
          <div style={styles.question}>{question}</div>
          <div>
            <input
              type="text"
              value={this.state.submitted_answer}
              style={styles.inputStyles}
              placeholder="Answer"
              name="submitted_answer"
              disabled={showAnswer}
              onChange={(e) => {
                e.preventDefault();
                this.onAnswer(e);
              }}
            />
          </div>

          <div style={styles.answerIcon}>
            {showAnswer && <IconContainer>{correctIcon}</IconContainer>}
          </div>
          <div style={{ ...styles.answer, ...correctStyle }}>
            {showAnswer && <div>{answer}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FillBlankProblem);

const styles = {
  quizOuter: {
    display: "flex",
    padding: "10px",
    justifyContent: "center",
  },
  quiz: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "5px",
  },
  score: {
    fontSize: "50px",
    color: "#1abebb",
    fontWeight: "bold",
  },
  time: { fontSize: "30px", textAlign: "left", color: "#1abebb" },
  scoreSection: {
    height: "5vh",
    margin: "50px 10% 100px 10%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "65%",
  },
  number: {
    fontSize: "2em",
    width: "20px",
    marginRight: "10px",
  },
  question: {
    fontSize: "2em",
    lineHeight: "28px",
    wordBreak: "keep-all",
    width: "200px",
  },
  inputStyles: {
    lineHeight: "2em",
    height: "2em",
    marginLeft: "15px",
    marginRight: "30px",
    minWidth: "300px",
  },
  answerIcon: {
    lineHeight: "35px",
    height: "25px",
    width: "50px",
  },
  answer: {
    lineHeight: "35px",
    height: "25px",
    width: "210px",
  },
  submitButton: {
    backgroundColor: "blue",
    border: "none",
    color: "white",
    padding: "16px 32px",
    textAlign: "center",
    fontSize: "16px",
    opacity: "0.6",
    transition: "0.3s",
    display: "inline-block",
    textDecoration: "none",
    cursor: "pointer",
  },
};
