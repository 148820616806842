import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded";
import Chinese from "chinese-s2t";
import PlayAudio from "../PlayAudio";
import IncorrectIcon from "../icons/IncorrectIcon";
import CorrectIcon from "../icons/CorrectIcon";
import OpenCC from "node-opencc";
import { translate } from "../../helpers/translate";
import { cloneDeep } from "lodash";

/* eslint-disable no-unused-expressions */

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 25px;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 25px;
`;

const AnswerChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 20px;
`;

const AudioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-size: 20px;
  flex-direction: column;
`;

const AudioFile = styled.audio`
  width: 250px;
  position: relative;
  left: 0%;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 12px;
`;

const StyledSubQuestion = styled.div`
  font-size: 1.20em;
  font-weight 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledImage = styled.img`
  @media (orientation: landscape) {
    height: 300;
    width: 40vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
  margin: 5;
`;

const StyledImageSmall = styled.img`
  @media (orientation: landscape) {
    height: 300;
    width: 20vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
  margin: 5;
`;

function MultiSingle({
  question,
  choices,
  mainaudio,
  mainimg,
  // text,
  nextLink,
  simplified,
  mainAudioSubtext,
  taskSpecificParams,
}) {
  const [toggleImage, setToggleImage] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState("Check");
  const [shuffled, setShuffled] = useState([]);
  const [currentAudio, setCurrentAudio] = useState();
  const [helpMe, setHelpMe] = useState(false);

  const reset = useCallback(() => {
    const shuffledChoices = [];
    for (const index in choices) {
      const temp = cloneDeep(choices[index].subChoices);
      shuffledChoices.push([...temp].sort(() => Math.random() - 0.5));
    }
    setShuffled(shuffledChoices);
    setShowAnswer(false);
    setSelectedAnswer("");
  });

  useEffect(() => {
    reset();
  }, []);

  const checkAnswer = (e) => {
    setSelectedAnswer(e.target.value);
  };

  const playAudio = (item) => {
    if (currentAudio !== undefined) currentAudio.pause();
    const sound = new Audio(item);
    setCurrentAudio(sound);
    sound.play();
  };

  const handleStatus = () => {
    switch (status) {
      case "Check":
        setShowAnswer(true);
        shuffled[current][selectedAnswer].isCorrect
          ? setStatus("Next")
          : setStatus("Retry");
        break;
      case "Retry":
        reset();
        setStatus("Check");
        break;
      case "Next":
        if (current + 1 < choices.length) {
          setCurrent(current + 1);
          reset();
          setStatus("Check");
          setHelpMe(false);
        } else {
          window.location.href = nextLink;
        }
        break;
      default:
        break;
    }
  };

  const labelClass = showAnswer ? "lbl-answer" : "";
  let subQuestion = null;
  if (taskSpecificParams !== undefined) {
    if (taskSpecificParams.subQuestions) {
      subQuestion = simplified
        ? OpenCC.traditionalToSimplified(
            taskSpecificParams.subQuestions[current]
          )
        : taskSpecificParams.subQuestions[current];
    }
  }

  return (
    <React.Fragment>
      <QuestionContainer>
        <StyledQuestion>
          {simplified
            ? OpenCC.traditionalToSimplified(choices[current].question)
            : choices[current].question}
        </StyledQuestion>
        {subQuestion}
      </QuestionContainer>

      <AudioContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          {`${current + 1}/${choices.length}:  `}
          {choices[current].mainAudio && (
            <AudioFile
              controls
              controlsList="nodownload"
              src={choices[current].mainAudio}
            />
          )}
        </div>
        {simplified
          ? Chinese.t2s(choices[current].mainAudioSubtext)
          : choices[current].mainAudioSubtext}
        {!choices[current].mainAudio ? (
          choices[current].audioSrc ? (
            <AudioFile
              controls
              controlsList="nodownload"
              src={choices[current].audioSrc}
            />
          ) : null
        ) : null}
      </AudioContainer>

      {mainimg && !toggleImage && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleImage((toggleImage) => !toggleImage)}
        >
          Show Image
        </Button>
      )}
      {mainimg && toggleImage && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleImage((toggleImage) => !toggleImage)}
        >
          Hide Image
        </Button>
      )}
      {mainimg && toggleImage && <StyledImage alt="loading..." src={mainimg} />}

      {choices[current].img && !toggleImage && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleImage((toggleImage) => !toggleImage)}
        >
          Show Image
        </Button>
      )}
      {choices[current].img && toggleImage && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleImage((toggleImage) => !toggleImage)}
        >
          Hide Image
        </Button>
      )}
      {choices[current].img && toggleImage && (
        <StyledImage alt="loading..." src={choices[current].img} />
      )}

      {choices[0].subChoices[0].help || choices[0].help ? (
        <Button
          style={{ margin: 5 }}
          variant="contained"
          color="secondary"
          onClick={() => setHelpMe(!helpMe)}
        >
          Help!!!
        </Button>
      ) : null}
      {choices[current].help && helpMe ? (
        <StyledSubQuestion>{choices[current].help}</StyledSubQuestion>
      ) : null}

      <StyledContainer>
        <RadioGroup
          value={selectedAnswer.toString()}
          onChange={(e) => checkAnswer(e)}
        >
          {shuffled.length >= 1
            ? shuffled[current].map(
                ({ text, img, isCorrect, traditional, audio, help }, index) => {
                  let iconContainer;
                  iconContainer = (
                    <IconContainer>
                      {showAnswer &&
                        selectedAnswer === index.toString() &&
                        (shuffled[current][index].isCorrect ? (
                          <CorrectIcon />
                        ) : (
                          <IncorrectIcon />
                        ))}
                    </IconContainer>
                  );
                  return (
                    <FormControlLabel
                      key={index}
                      value={index.toString()}
                      className={labelClass}
                      control={showAnswer ? iconContainer : <Radio />}
                      label={
                        <div>
                          <AnswerChoiceContainer>
                            {img && <img alt={text} src={img} />}
                            <span className="label">
                              {translate(text, simplified)}
                            </span>
                            {audio ? (
                              <VolumeUpRoundedIcon
                                onClick={() => playAudio(audio)}
                                key={Math.random()}
                                fontSize="medium"
                              />
                            ) : null}
                          </AnswerChoiceContainer>
                          <div>{helpMe ? help : null}</div>
                        </div>
                      }
                    />
                  );
                }
              )
            : null}
        </RadioGroup>

        <Button
          style={{ alignSelf: "center", marginTop: 10 }}
          variant="contained"
          color="secondary"
          onClick={() => {
            selectedAnswer === "" ? null : handleStatus();
          }}
        >
          {status}
        </Button>
      </StyledContainer>
    </React.Fragment>
  );
}

export default MultiSingle;
