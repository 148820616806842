import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Chinese from "chinese-s2t";
import OpenCC from "node-opencc";
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  flex-wrap: wrap;
  @media (orientation: portrait) {
    width: 90vw;
  }
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  text-align: center;
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.5vw;
  @media (orientation: portrait) {
    font-size: 5vw;
  }
`;

const StyledImage = styled.img`
  @media (orientation: landscape) {
    height: 25vw;
    width: 25vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
  margin: 5;
`;

const ChoiceContainer = styled.div`
  flex: 1 0 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5vw;
  margin-top: 10px;
  @media (orientation: portrait) {
    flex: 1 0 50%;
    font-size: 5vw;
  }
`;

const StyledChoiceImage = styled.img`
  border: ${(props) =>
    props.isSelected ? "3px solid light-blue" : "3px solid #eeeeee"};
  width: 15vw;
  height: 10vw;
  margin: 5;
  border-radius: 1.5px;
  @media (orientation: portrait) {
    width: 40vw;
    height: 25vw;
  }
  &:hover {
    border: 3px solid gray;
  }
`;

const StyledSelectedChoiceImage = styled.img`
  border: 3px solid #87ceeb;
  width: 15vw;
  height: 10vw;
  margin: 5;
  border-radius: 1.5px;
  @media (orientation: portrait) {
    width: 40vw;
    height: 25vw;
  }
`;

const StyledWrongChoiceImage = styled.img`
  border: 3px solid red;
  width: 15vw;
  height: 10vw;
  margin: 5;
  border-radius: 1.5px;
  @media (orientation: portrait) {
    width: 40vw;
    height: 25vw;
  }
`;

const StyledAudio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer
  background-color: white;
  width: calc(15vw + 3px);
  height: 3vw;
  flex-warp: warp;
  @media (orientation: portrait) {
    width: calc(40vw + 3px);
    height: 7.5vw;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 1vw;
  @media (orientation: portrait) {
    width: 90vw;
    margin-bottom: 5vw;
  }
`;

const ChoiceImage = ({ onClick, isSelected, alt, src }) => (
  <StyledChoiceImage
    onClick={onClick}
    isSelected={isSelected}
    alt={alt}
    src={src}
  />
);

function GridSound({
  question,
  choices,
  mainimg,
  nextLink,
  simplified,
  answerKey,
  taskSpecificParams,
}) {
  const [toggleImage, setToggleImage] = useState(false);
  const [status, setStatus] = useState("Check");
  const [shuffled, setShuffled] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [typeLearning, setTypeLearning] = useState(false);
  const [toggleEnglish, setToggleEnglish] = useState(false);
  const [togglePinyin, setTogglePinyin] = useState(false);
  const [currentAudio, setCurrentAudio] = useState();

  const reset = useCallback(() => {
    setShuffled([...choices].sort(() => Math.random() - 0.5));
    setSelected([...choices].fill(false));
    setSelectedKey([]);
  });

  /*
   ** useEffect
   * If taskSpecificParams.typeLearning:
   *   It will set Status -> Next
   *   Unshuffle choices
   *   allow the problem to proceed without a solution / answerkey
   */
  useEffect(() => {
    reset();
    if (taskSpecificParams !== undefined) {
      if (taskSpecificParams.typeLearning) {
        setStatus("Next");
        setShuffled(choices);
        setTypeLearning(true);
      }
    }
  }, []);

  /*
   ** handle action on grid clicked
   *  Will add new index to selectedKey(used for final solution check)
   *  Maps over selected which will change border color based on selected or not
   */
  const handleClicked = (index) => {
    const newKey = selectedKey;
    newKey.push(shuffled[index].id);
    setSelectedKey(newKey);
    setSelected(
      [...selected].map((choice, i) => (index === i ? !choice : choice))
    );
  };

  const playAudio = (item) => {
    if (currentAudio !== undefined) currentAudio.pause();
    const sound = new Audio(item);
    setCurrentAudio(sound);
    sound.play();
  };

  const handleStatus = () => {
    switch (status) {
      case "Check":
        selectedKey.sort().join() === answerKey.sort().join()
          ? setStatus("Next")
          : setStatus("Retry");
        break;
      case "Retry":
        reset();
        setStatus("Check");
        break;
      case "Next":
        window.location.href = nextLink;
        break;
      default:
        break;
    }
  };

  const handleToggle = (type) => {
    if (type === "english") {
      setToggleEnglish(!toggleEnglish);
      if (togglePinyin) {
        setTogglePinyin(false);
      }
    } else if (type === "pinyin") {
      setTogglePinyin(!togglePinyin);
      if (toggleEnglish) {
        setToggleEnglish(false);
      }
    }
  };

  return (
    <React.Fragment>
      <QuestionContainer>
        <StyledQuestion>
          {simplified ? OpenCC.traditionalToSimplified(question) : question}
        </StyledQuestion>
      </QuestionContainer>
      {!mainimg ? null : (
        <Button
          style={{ margin: 5 }}
          variant="contained"
          color="secondary"
          onClick={() => setToggleImage(!toggleImage)}
        >
          {toggleImage ? "Hide Image" : "Show Image"}
        </Button>
      )}
      {toggleImage ? <StyledImage alt="loading..." src={mainimg} /> : null}
      {typeLearning ? (
        <ButtonContainer>
          <Button
            style={{ margin: 5 }}
            variant="contained"
            color="secondary"
            onClick={() => handleToggle("pinyin")}
          >
            {togglePinyin ? "Hide Pinyin" : "Show Pinyin"}
          </Button>
          <Button
            style={{ margin: 5 }}
            variant="contained"
            color="secondary"
            onClick={() => handleToggle("english")}
          >
            {toggleEnglish ? "Hide English" : "Show English"}
          </Button>
        </ButtonContainer>
      ) : null}
      <StyledContainer>
        {shuffled.map((choice, index) => {
          const landscape =
            process.browser &&
            window.matchMedia("(orientation: landscape)").matches;
          let imageModule = (
            <StyledChoiceImage
              onClick={() => handleClicked(index)}
              alt="loading..."
              src={choice.img}
            />
          );
          if (status === "Check") {
            if (selected[index]) {
              imageModule = (
                <StyledSelectedChoiceImage
                  onClick={() => handleClicked(index)}
                  alt="loading..."
                  src={choice.img}
                />
              );
            }
          } else if (selected[index] && answerKey.includes(choice.id)) {
            imageModule = (
              <StyledSelectedChoiceImage alt="loading..." src={choice.img} />
            );
          } else if (selected[index] && !answerKey.includes(choice.id)) {
            imageModule = (
              <StyledWrongChoiceImage alt="loading..." src={choice.img} />
            );
          } else {
            imageModule = (
              <StyledChoiceImage alt="loading..." src={choice.img} />
            );
          }
          let textModule = "";
          if (!toggleEnglish && !togglePinyin) {
            textModule = (
              <div>
                {choice.text
                  ? simplified
                    ? OpenCC.traditionalToSimplified(choice.text)
                    : choice.text
                  : null}
              </div>
            );
          } else if (togglePinyin) {
            textModule = (
              <div style={{ fontFamily: "times new roman" }}>
                {choice.pinyin}
              </div>
            );
          } else if (toggleEnglish) {
            textModule = (
              <div style={{ fontSize: "1vw", textAlign: "center" }}>
                {choice.english}
              </div>
            );
          }
          return (
            <ChoiceContainer>
              {imageModule}
              {/* <ChoiceImage onClick={() => handleClicked(index)} isSelected={selected[index]} alt="loading..." src={choice.img} /> */}
              <StyledAudio onClick={() => playAudio(choice.audio)}>
                {/* {togglePinyin ?
                  <div style={{fontFamily: 'times new roman'}}>textModule</div>
                :
                  {textModule}
                } */}
                {textModule}
                <VolumeUpRoundedIcon
                  key={Math.random()}
                  fontSize={landscape ? "large" : "medium"}
                />
              </StyledAudio>
            </ChoiceContainer>
          );
        })}
      </StyledContainer>
      <Button
        style={{ alignSelf: "center", marginTop: 10 }}
        variant="contained"
        color="secondary"
        onClick={handleStatus}
      >
        {status}
      </Button>
    </React.Fragment>
  );
}

export default GridSound;
