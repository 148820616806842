import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import Chinese from "chinese-s2t";

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.55rem;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  @media (orientation: landscape) {
    width: 75vw;
  }
`;

const StyledText = styled.div`
  text-align: center;
  font-size: 1.75em;
  font-weight: 550;
`;

const StyledInput = styled.div`
  font-size: 2em;
  font-weight: 550;
  width: auto;
  @media (orientation: landscape) {
    flex: 1 1 18%;
  }
  @media (orientation: portrait) {
    flex: 1 1 50%;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  text-align: center;
`;

const DropInContainer = styled.div`
  border-width : 2px;
  width : 120px;
  height : 25px;
  font-size : 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  // border-radius: 15px;
  background-color : white;
  border-color : black;
  line-height : 1;
  padding : 10px;
  margin : 5px;
  text-align : center;
  display flex;
  justify-content : center;
  align-items : center;
  margin-bottom: 30px;
`;

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: space-around;
  justify-content: center;
  @media (orientation: landscape) {
    width: 1000px;
  }
  @media (orientation: portrait) {
    width: 95%;
  }
  padding: 5px;
`;

const StyledChoices = styled.div`
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
`;

function Numbers({ question, choices, nextLink, simplified }) {
  const [status, setStatus] = useState("Next");
  const [currentAudio, setCurrentAudio] = useState();
  const [current, setCurrent] = useState(0);
  const [audioArray, setAudioArray] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [dropZone, setDropZone] = useState(0);

  useEffect(() => {
    const init = () => {
      let temp = [];

      /*
        Initialize answer array, audio is left blank and will be populated by user.
      */
      shuffle(choices[1].subChoices);
      choices[1].subChoices.map((item) => {
        setAnswer((answer) => [
          ...answer,
          {
            text: item.text,
            audio: "",
          },
        ]);

        temp = [...temp, item.audio];
      });

      setAudioArray(shuffle(temp));
    };
    init();
  }, []);

  const shuffle = (array) => {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };

  const playAudio = (item) => {
    if (currentAudio !== undefined) currentAudio.pause();
    const sound = new Audio(item);
    setCurrentAudio(sound);
    sound.play();
  };

  const handleStatus = () => {
    switch (status) {
      case "Check":
        const correct = JSON.stringify(answer) === JSON.stringify(choices);
        correct ? setStatus("Next") : setStatus("Retry");
        break;
      case "Retry":
        reset();
        setStatus("Check");
        break;
      case "Next":
        if (current + 1 < choices.length) {
          setCurrent(current + 1);
          setStatus("Check");
        } else {
          window.location.href = nextLink;
        }
        break;
      default:
        break;
    }
  };

  const reset = () => {
    setAnswer([]);

    shuffle(choices[1].subChoices);
    shuffle(audioArray);

    choices[1].subChoices.map((item) => {
      setAnswer((answer) => [
        ...answer,
        {
          text: item.text,
          audio: "",
        },
      ]);
    });
  };

  const handleDrop = (e) => {
    const key = Object.keys(e.dragData).toString();
    const value = Object.values(e.dragData).toString();

    setAnswer([...answer], (answer[dropZone].audio = value));
  };

  const clearEntry = (index) => {
    setAnswer([...answer], (answer[index].audio = ""));
  };

  return (
    <React.Fragment>
      <StyledQuestion>
        {current === 0 ? choices[0].question : choices[1].question}
      </StyledQuestion>

      {current === 0 && (
        // Part 1 of Template, Learning the Numbers
        <StyledContainer>
          {choices[current].subChoices.map((item, index) => (
            <StyledInput key={index}>
              <VolumeUpRoundedIcon
                fontSize="large"
                onClick={() => playAudio(item.audio)}
              />
              {simplified ? Chinese.t2s(item.text) : item.text}
            </StyledInput>
          ))}
        </StyledContainer>
      )}

      {current === 1 && (
        // Part 2 of Template, Matching the blank
        <div>
          <div style={{ textAlign: "center" }}>
            {audioArray.map((item, index) =>
              item !== "" ? (
                <StyledChoices key={index}>
                  <DragDropContainer
                    targetKey="foo"
                    noDragging={status !== "Check"}
                    onDrag={
                      () => {}
                      // setDragSelected(index)
                    }
                    dragData={{ audio: item }}
                  >
                    <VolumeUpRoundedIcon
                      fontSize="large"
                      onClick={() => playAudio(item)}
                    />
                  </DragDropContainer>
                </StyledChoices>
              ) : null
            )}
          </div>

          <StyledMainContainer>
            {answer.map((item, index) => (
              <div>
                <StyledText>
                  {simplified ? Chinese.t2s(item.text) : item.text}
                </StyledText>
                <DropTarget
                  targetKey="foo"
                  onHit={(e) => handleDrop(e)}
                  onDragEnter={() => setDropZone(index)}
                >
                  <DropInContainer
                    style={{
                      color:
                        status !== "Check"
                          ? item.audio === choices[1].subChoices[index].audio
                            ? "Green"
                            : "Red"
                          : "Black",
                    }}
                    onClick={() => (item.audio ? clearEntry(index) : null)}
                  >
                    <div>
                      {item.audio ? (
                        <VolumeUpRoundedIcon fontSize="large" />
                      ) : null}
                    </div>
                  </DropInContainer>
                </DropTarget>
              </div>
            ))}
          </StyledMainContainer>
        </div>
      )}

      <Button
        style={{ margin: 5, marginTop: 20 }}
        variant="contained"
        color="secondary"
        onClick={() => handleStatus()}
      >
        {status}
      </Button>
    </React.Fragment>
  );
}

export default Numbers;
