/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { Button } from "@material-ui/core";
import { translate } from "../../helpers/translate.jsx";
import openCC from "node-opencc";

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

const RadicalContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (orientation: landscape) {
    width: 90%;
    font-size: 35px;
  }

  @media (orientation: portrait) {
    width: 100%;
    font-size: 20px;
  }
  justify-content: center;
  align-items: center;
`;

const SingleSection = styled.div`
  text-align: center;
  width: 20%;
`;

const FirstSection = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
`;

const SecondSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 25%;
`;

const CharacterView = styled.div`
  text-align: center;
  margin: 5px;
  padding: 4px;
  @media (orientation: landscape) {
    font-size: 2vw;
    width: 75px;
  }

  @media (orientation: portrait) {
    font-size: 20px;
    width: 30px;
  }
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
`;

export default function DragRadical({
  question,
  choices,
  answers,
  nextLink,
  simplified,
}) {
  const [currAnswer, setCurrAnswer] = useState([]);
  const [options, setOptions] = useState([]);
  const [status, setStatus] = useState("");

  const [dragSelected, setDragSelected] = useState(0);
  const [dropZone, setDropZone] = useState(0);

  useEffect(() => {
    retry();
  }, []);

  const handleDrop = () => {
    setCurrAnswer(
      [...currAnswer].map((item, index) =>
        index === dropZone ? options[dragSelected] : item
      )
    );
    setOptions([...options].filter((item, index) => dragSelected !== index));
  };

  const randomize = (array) => {
    const tempArray = array;
    let i = array.length;
    let k;
    let temp;
    while (--i > 0) {
      k = Math.floor(Math.random() * (i + 1));
      temp = tempArray[k];
      tempArray[k] = tempArray[i];
      tempArray[i] = temp;
    }
    return tempArray;
  };

  const handleButton = () => {
    switch (status) {
      case "Check":
        let correct = true;
        choices.map((choice, index) => {
          if (choice.answer !== currAnswer[index]) correct = false;
        });
        setStatus(correct ? "Next" : "Retry");
        break;
      case "Retry":
        retry();
        setStatus("Check");
        break;
      case "Next":
        window.location.href = nextLink;
        break;
      default:
        break;
    }
  };

  const clearEntry = (index) => {
    if (currAnswer[index] !== "." && status === "Check") {
      setOptions([...options, currAnswer[index]]);
      setCurrAnswer(
        [...currAnswer].map((item, i) => (i === index ? "." : item))
      );
    }
  };
  const retry = () => {
    setStatus("Check");
    setCurrAnswer([...answers].fill("."));
    setOptions(randomize(answers));
  };
  return (
    <React.Fragment>
      <StyledQuestion>{question}</StyledQuestion>
      <StyledContainer>
        {choices.map((choice, index) => {
          let correctColor = currAnswer[index] !== "." ? "black" : "white";
          const selectedAns = choices[index].answer;
          const isCorrect = currAnswer[index] === choices[index].answer;
          if (status !== "Check") correctColor = isCorrect ? "Green" : "Red";
          return (
            <RadicalContainer>
              <FirstSection>
                <SingleSection style={{ fontFamily: "san-serif" }}>
                  {choice.compound}
                </SingleSection>
                <SingleSection> = </SingleSection>
                <SingleSection>
                  {" "}
                  {simplified
                    ? openCC.traditionalToSimplified(choice.first)
                    : choice.first}
                </SingleSection>
                <SingleSection> + </SingleSection>
                <SingleSection>
                  <DropTarget
                    targetKey="foo"
                    onHit={() => handleDrop()}
                    onDragEnter={() => setDropZone(index)}
                  >
                    <CharacterView
                      style={{ color: correctColor }}
                      onClick={() => clearEntry(index)}
                    >
                      {simplified
                        ? openCC.traditionalToSimplified(currAnswer[index])
                        : currAnswer[index]}
                    </CharacterView>
                  </DropTarget>
                </SingleSection>
              </FirstSection>
              <SecondSection>
                <DragDropContainer
                  targetKey="foo"
                  onDrag={() => setDragSelected(index)}
                >
                  {options[index] && (
                    <CharacterView>
                      {simplified
                        ? openCC.traditionalToSimplified(options[index])
                        : options[index]}
                    </CharacterView>
                  )}
                </DragDropContainer>
              </SecondSection>
            </RadicalContainer>
          );
        })}
      </StyledContainer>
      <Button
        style={{ margin: "5px" }}
        variant="contained"
        color="secondary"
        onClick={() => handleButton()}
      >
        {status}
      </Button>
    </React.Fragment>
  );
}
