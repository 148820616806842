import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { useMediaQuery } from "react-responsive";

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.35em;
  margin-left: 0;
  margin-right: 0;
`;

const StyledInfo = styled.div`
    float: left
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 95px;
`;

const StyledSubInfo = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const StyledInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 3em;
`;

const DropInContainer = styled.div`
  border-width : 2px;
  @media (orientation: landscape) {
    width : 100px;
    height : 10px;
    font-size : 20px;
  }
  @media (orientation: portrait) {
    width : 100px;
    height : 10px;
    font-size : 15px;
  }
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 15px;
  background-color : white;
  border-color : black;
  line-height : 1;
  padding : 20px;
  margin : 5px;
  text-align : center;
  display flex;
  justify-content : center;
  align-items : center;
`;

const StyledChoices = styled.div`
  float: left;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 25px;
  font-weight: 500;
`;

function AnalysisLearning({ choices, nextLink, question, simplified }) {
  const [status, setStatus] = useState("Check");
  const [statusMobile, setStatusMobile] = useState("Check");
  const [dragSelected, setDragSelected] = useState(0);
  const [dropZone, setDropZone] = useState(0);
  const [simplifiedCopy, setSimplifiedCopy] = useState([]);
  const [traditionalCopy, setTraditionalCopy] = useState([]);

  const [simpPinyin, setSimpPinyin] = useState([]);
  const [simpMeaning, setSimpMeaning] = useState([]);
  const [simpRadical, setSimpRadical] = useState([]);
  const [simpStroke, setSimpStroke] = useState([]);

  const [tradPinyin, setTradPinyin] = useState([]);
  const [tradMeaning, setTradMeaning] = useState([]);
  const [tradRadical, setTradRadical] = useState([]);
  const [tradStroke, setTradStroke] = useState([]);

  const [wait, setWait] = useState(true);

  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

  const [pos, setPos] = useState(0);

  useEffect(() => {
    /*
        This function will initialize the answer array as well as the word bank. 
        Each row of the word bank is shuffled.
        Wait state serves to let useEffect finish before rendering mobile view.
    */
    const init = () => {
      shuffle(choices);

      let sPinyin = [];
      let sMeaning = [];
      let sRadical = [];
      let sStroke = [];

      for (let i = 0; i < choices.length; i++) {
        setSimplifiedCopy((simplifiedCopy) => [
          ...simplifiedCopy,
          {
            traditional: choices[i].traditional,
            simplified: choices[i].simplified,
            pinyin: " ",
            meaning: " ",
            strokesT: choices[i].strokesT,
            strokesS: " ",
            radicalT: choices[i].radicalT,
            radicalS: " ",
          },
        ]);
        sPinyin = [...sPinyin, choices[i].pinyin];
        sMeaning = [...sMeaning, choices[i].meaning];
        sRadical = [...sRadical, choices[i].radicalS];
        sStroke = [...sStroke, choices[i].strokesS];
      }

      let tPinyin = [];
      let tMeaning = [];
      let tRadical = [];
      let tStroke = [];

      for (let i = 0; i < choices.length; i++) {
        setTraditionalCopy((traditionalCopy) => [
          ...traditionalCopy,
          {
            traditional: choices[i].traditional,
            simplified: choices[i].simplified,
            pinyin: " ",
            meaning: " ",
            strokesT: " ",
            strokesS: choices[i].strokesS,
            radicalT: " ",
            radicalS: choices[i].radicalS,
          },
        ]);
        tPinyin = [...tPinyin, choices[i].pinyin];
        tMeaning = [...tMeaning, choices[i].meaning];
        tRadical = [...tRadical, choices[i].radicalT];
        tStroke = [...tStroke, choices[i].strokesT];
      }

      setSimpPinyin(shuffle(sPinyin));
      setSimpMeaning(shuffle(sMeaning));
      setSimpRadical(shuffle(sRadical));
      setSimpStroke(shuffle(sStroke));

      setTradPinyin(shuffle(tPinyin));
      setTradMeaning(shuffle(tMeaning));
      setTradRadical(shuffle(tRadical));
      setTradStroke(shuffle(tStroke));

      setWait(false);
    };
    init();
  }, []);

  const shuffle = (array) => {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };

  const retryMobile = () => {
    setStatusMobile("Check");
    if (simplified) {
      setSimplifiedCopy(
        [...simplifiedCopy],
        (simplifiedCopy[dropZone].pinyin = " ")
      );
      setSimplifiedCopy(
        [...simplifiedCopy],
        (simplifiedCopy[dropZone].meaning = " ")
      );
      setSimplifiedCopy(
        [...simplifiedCopy],
        (simplifiedCopy[dropZone].radicalS = " ")
      );
      setSimplifiedCopy(
        [...simplifiedCopy],
        (simplifiedCopy[dropZone].strokesS = " ")
      );
    } else {
      setTraditionalCopy(
        [...traditionalCopy],
        (traditionalCopy[pos].pinyin = " ")
      );
      setTraditionalCopy(
        [...traditionalCopy],
        (traditionalCopy[pos].meaning = " ")
      );
      setTraditionalCopy(
        [...traditionalCopy],
        (traditionalCopy[pos].radicalT = " ")
      );
      setTraditionalCopy(
        [...traditionalCopy],
        (traditionalCopy[pos].strokesT = " ")
      );
    }
  };
  const reset = () => {
    setStatus("Check");
    setTraditionalCopy([]);
    setSimplifiedCopy([]);

    shuffle(choices);

    shuffle(simpPinyin);
    shuffle(simpMeaning);
    shuffle(simpRadical);
    shuffle(simpStroke);

    shuffle(tradPinyin);
    shuffle(tradMeaning);
    shuffle(tradRadical);
    shuffle(tradStroke);

    for (let i = 0; i < choices.length; i++) {
      setSimplifiedCopy((simplifiedCopy) => [
        ...simplifiedCopy,
        {
          traditional: choices[i].traditional,
          simplified: choices[i].simplified,
          pinyin: " ",
          meaning: " ",
          strokesT: choices[i].strokesT,
          strokesS: " ",
          radicalT: choices[i].radicalT,
          radicalS: " ",
        },
      ]);
    }
    for (let i = 0; i < choices.length; i++) {
      setTraditionalCopy((traditionalCopy) => [
        ...traditionalCopy,
        {
          traditional: choices[i].traditional,
          simplified: choices[i].simplified,
          pinyin: " ",
          meaning: " ",
          strokesT: " ",
          strokesS: choices[i].strokesS,
          radicalT: " ",
          radicalS: choices[i].radicalS,
        },
      ]);
    }
  };

  const handleDrop = (e, index) => {
    const key = Object.keys(e.dragData).toString();
    const value = Object.values(e.dragData).toString();

    if (simplified) {
      switch (key) {
        case "pinyin":
          setSimplifiedCopy(
            [...simplifiedCopy],
            (simplifiedCopy[dropZone].pinyin = value)
          );
          break;
        case "meaning":
          setSimplifiedCopy(
            [...simplifiedCopy],
            (simplifiedCopy[dropZone].meaning = value)
          );
          break;
        case "radicalS":
          setSimplifiedCopy(
            [...simplifiedCopy],
            (simplifiedCopy[dropZone].radicalS = value)
          );
          break;
        case "strokesS":
          setSimplifiedCopy(
            [...simplifiedCopy],
            (simplifiedCopy[dropZone].strokesS = value)
          );
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case "pinyin":
          setTraditionalCopy(
            [...traditionalCopy],
            (traditionalCopy[dropZone].pinyin = value)
          );
          break;
        case "meaning":
          setTraditionalCopy(
            [...traditionalCopy],
            (traditionalCopy[dropZone].meaning = value)
          );
          break;
        case "radicalT":
          setTraditionalCopy(
            [...traditionalCopy],
            (traditionalCopy[dropZone].radicalT = value)
          );
          break;
        case "strokesT":
          setTraditionalCopy(
            [...traditionalCopy],
            (traditionalCopy[dropZone].strokesT = value)
          );
          break;
        default:
          break;
      }
    }
  };

  const handleStatus = () => {
    switch (status) {
      case "Check":
        if (simplified) {
          const correct =
            JSON.stringify(choices) === JSON.stringify(simplifiedCopy);
          correct ? setStatus("Next") : setStatus("Retry");
        } else {
          const correct =
            JSON.stringify(choices) === JSON.stringify(traditionalCopy);
          correct ? setStatus("Next") : setStatus("Retry");
        }
        break;
      case "Retry":
        reset();
        setStatus("Check");
        break;
      case "Next":
        window.location.href = nextLink;
        break;
      default:
        break;
    }
  };

  const handleStatusMobile = () => {
    switch (statusMobile) {
      case "Check":
        if (simplified) {
          const correct =
            JSON.stringify(choices[pos]) ===
            JSON.stringify(simplifiedCopy[pos]);
          correct ? setStatusMobile("Next") : setStatusMobile("Retry");
        } else {
          const correct =
            JSON.stringify(choices[pos]) ===
            JSON.stringify(traditionalCopy[pos]);
          correct ? setStatusMobile("Next") : setStatusMobile("Retry");
        }
        break;
      case "Retry":
        retryMobile();
        break;
      case "Next":
        if (pos + 1 < choices.length) {
          setPos(pos + 1);
          setStatusMobile("Check");
        } else {
          window.location.href = nextLink;
        }
        break;
      default:
        break;
    }
  };

  const clearEntry = (index, type) => {
    if (status === "Check" && simplified) {
      switch (type) {
        case "pinyin":
          setSimplifiedCopy(
            [...simplifiedCopy],
            (simplifiedCopy[index].pinyin = " ")
          );
          break;
        case "meaning":
          setSimplifiedCopy(
            [...simplifiedCopy],
            (simplifiedCopy[index].meaning = " ")
          );
          break;
        case "radicalS":
          setSimplifiedCopy(
            [...simplifiedCopy],
            (simplifiedCopy[index].radicalS = " ")
          );
          break;
        case "strokesS":
          setSimplifiedCopy(
            [...simplifiedCopy],
            (simplifiedCopy[index].strokesS = " ")
          );
          break;
        default:
          break;
      }
    } else if (status === "Check" && !simplified) {
      switch (type) {
        case "pinyin":
          setTraditionalCopy(
            [...traditionalCopy],
            (traditionalCopy[index].pinyin = " ")
          );
          break;
        case "meaning":
          setTraditionalCopy(
            [...traditionalCopy],
            (traditionalCopy[index].meaning = " ")
          );
          break;
        case "radicalT":
          setTraditionalCopy(
            [...traditionalCopy],
            (traditionalCopy[index].radicalT = " ")
          );
          break;
        case "strokesT":
          setTraditionalCopy(
            [...traditionalCopy],
            (traditionalCopy[index].strokesT = " ")
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    <React.Fragment>
      <StyledQuestion>{question}</StyledQuestion>
      {simplified ? (
        // Simplified Section
        !isPortrait ? (
          <div>
            <StyledInfo>
              <StyledSubInfo>Pinyin: </StyledSubInfo>
              <StyledSubInfo>Meaning: </StyledSubInfo>
              <StyledSubInfo>Radical: </StyledSubInfo>
              <StyledSubInfo>Strokes: </StyledSubInfo>
            </StyledInfo>

            {simplifiedCopy.map((item, index) => (
              <div key={index} style={{ float: "left" }}>
                <StyledInput>{item.simplified}</StyledInput>
                <DropTarget
                  targetKey="foo"
                  onHit={(e) => handleDrop(e)}
                  onDragEnter={() => setDropZone(index)}
                >
                  <DropInContainer
                    style={{
                      color:
                        status !== "Check"
                          ? item.pinyin === choices[index].pinyin
                            ? "Green"
                            : "Red"
                          : "gray",
                      borderStyle: "dotted",
                      borderWidth: "2px",
                    }}
                    onClick={() =>
                      item.pinyin === " " ? null : clearEntry(index, "pinyin")
                    }
                  >
                    <div>{item.pinyin}</div>
                  </DropInContainer>
                </DropTarget>
                <DropTarget
                  targetKey="foo"
                  onHit={(e) => handleDrop(e)}
                  onDragEnter={() => setDropZone(index)}
                >
                  <DropInContainer
                    style={{
                      color:
                        status !== "Check"
                          ? item.meaning === choices[index].meaning
                            ? "Green"
                            : "Red"
                          : "gray",
                      borderStyle: "dotted",
                      borderWidth: "2px",
                    }}
                    onClick={() =>
                      item.meaning === " " ? null : clearEntry(index, "meaning")
                    }
                  >
                    <div>{item.meaning}</div>
                  </DropInContainer>
                </DropTarget>
                <DropTarget
                  targetKey="foo"
                  onHit={(e) => handleDrop(e)}
                  onDragEnter={() => setDropZone(index)}
                >
                  <DropInContainer
                    style={{
                      fontSize: 30,
                      color:
                        status !== "Check"
                          ? item.radicalS === choices[index].radicalS
                            ? "Green"
                            : "Red"
                          : "gray",
                      borderStyle: "dotted",
                      borderWidth: "2px",
                    }}
                    onClick={() =>
                      item.radicalS === " "
                        ? null
                        : clearEntry(index, "radicalS")
                    }
                  >
                    <div>{item.radicalS.substring(0, 1)}</div>
                  </DropInContainer>
                </DropTarget>
                <DropTarget
                  targetKey="foo"
                  onHit={(e) => handleDrop(e)}
                  onDragEnter={() => setDropZone(index)}
                >
                  <DropInContainer
                    style={{
                      color:
                        status !== "Check"
                          ? item.strokesS === choices[index].strokesS
                            ? "Green"
                            : "Red"
                          : "gray",
                      borderStyle: "dotted",
                      borderWidth: "2px",
                    }}
                    onClick={() =>
                      item.strokesS === " "
                        ? null
                        : clearEntry(index, "strokesS")
                    }
                  >
                    <div>{item.strokesS}</div>
                  </DropInContainer>
                </DropTarget>
              </div>
            ))}
          </div>
        ) : (
          // Mobile Simplified Section
          <div>
            <StyledInfo>
              <StyledSubInfo>Pinyin: </StyledSubInfo>
              <StyledSubInfo>Meaning: </StyledSubInfo>
              <StyledSubInfo>Radical: </StyledSubInfo>
              <StyledSubInfo>Strokes: </StyledSubInfo>
            </StyledInfo>
            {!wait && (
              <div style={{ float: "left" }}>
                <StyledInput>{simplifiedCopy[pos].traditional}</StyledInput>
                <DropTarget
                  targetKey="foo"
                  onHit={(e) => handleDrop(e)}
                  onDragEnter={() => setDropZone(pos)}
                >
                  <DropInContainer
                    style={{
                      color:
                        statusMobile !== "Check"
                          ? simplifiedCopy[pos].pinyin === choices[pos].pinyin
                            ? "Green"
                            : "Red"
                          : "gray",
                      borderStyle: "dotted",
                      borderWidth: "2px",
                    }}
                    onClick={() =>
                      simplifiedCopy[pos].pinyin === " "
                        ? null
                        : clearEntry(pos, "pinyin")
                    }
                  >
                    <div>{simplifiedCopy[pos].pinyin}</div>
                  </DropInContainer>
                </DropTarget>
                <DropTarget
                  targetKey="foo"
                  onHit={(e) => handleDrop(e)}
                  onDragEnter={() => setDropZone(pos)}
                >
                  <DropInContainer
                    style={{
                      color:
                        statusMobile !== "Check"
                          ? simplifiedCopy[pos].meaning === choices[pos].meaning
                            ? "Green"
                            : "Red"
                          : "gray",
                      borderStyle: "dotted",
                      borderWidth: "2px",
                    }}
                    onClick={() =>
                      simplifiedCopy[pos].meaning === " "
                        ? null
                        : clearEntry(pos, "meaning")
                    }
                  >
                    <div>{simplifiedCopy[pos].meaning}</div>
                  </DropInContainer>
                </DropTarget>
                <DropTarget
                  targetKey="foo"
                  onHit={(e) => handleDrop(e)}
                  onDragEnter={() => setDropZone(pos)}
                >
                  <DropInContainer
                    style={{
                      fontSize: 30,
                      color:
                        statusMobile !== "Check"
                          ? simplifiedCopy[pos].radicalT ===
                            choices[pos].radicalT
                            ? "Green"
                            : "Red"
                          : "gray",
                      borderStyle: "dotted",
                      borderWidth: "2px",
                    }}
                    onClick={() =>
                      simplifiedCopy[pos].radicalS === " "
                        ? null
                        : clearEntry(pos, "radicalS")
                    }
                  >
                    <div>{simplifiedCopy[pos].radicalS.substring(0, 1)}</div>
                  </DropInContainer>
                </DropTarget>
                <DropTarget
                  targetKey="foo"
                  onHit={(e) => handleDrop(e)}
                  onDragEnter={() => setDropZone(pos)}
                >
                  <DropInContainer
                    style={{
                      color:
                        statusMobile !== "Check"
                          ? simplifiedCopy[pos].strokesT ===
                            choices[pos].strokesT
                            ? "Green"
                            : "Red"
                          : "gray",
                      borderStyle: "dotted",
                      borderWidth: "2px",
                    }}
                    onClick={() =>
                      simplifiedCopy[pos].strokesS === " "
                        ? null
                        : clearEntry(pos, "strokesS")
                    }
                  >
                    <div>{simplifiedCopy[pos].strokesS}</div>
                  </DropInContainer>
                </DropTarget>
              </div>
            )}
          </div>
        )
      ) : // Traditional Section
      !isPortrait ? (
        <div>
          <StyledInfo>
            <StyledSubInfo>Pinyin: </StyledSubInfo>
            <StyledSubInfo>Meaning: </StyledSubInfo>
            <StyledSubInfo>Radical: </StyledSubInfo>
            <StyledSubInfo>Strokes: </StyledSubInfo>
          </StyledInfo>

          {traditionalCopy.map((item, index) => (
            <div key={index} style={{ float: "left" }}>
              <StyledInput>{item.traditional}</StyledInput>
              <DropTarget
                targetKey="foo"
                onHit={(e) => handleDrop(e)}
                onDragEnter={() => setDropZone(index)}
              >
                <DropInContainer
                  style={{
                    color:
                      status !== "Check"
                        ? item.pinyin === choices[index].pinyin
                          ? "Green"
                          : "Red"
                        : "gray",
                    borderStyle: "dotted",
                    borderWidth: "2px",
                  }}
                  onClick={() =>
                    item.pinyin === " " ? null : clearEntry(index, "pinyin")
                  }
                >
                  <div>{item.pinyin}</div>
                </DropInContainer>
              </DropTarget>
              <DropTarget
                targetKey="foo"
                onHit={(e) => handleDrop(e)}
                onDragEnter={() => setDropZone(index)}
              >
                <DropInContainer
                  style={{
                    color:
                      status !== "Check"
                        ? item.meaning === choices[index].meaning
                          ? "Green"
                          : "Red"
                        : "gray",
                    borderStyle: "dotted",
                    borderWidth: "2px",
                  }}
                  onClick={() =>
                    item.meaning === " " ? null : clearEntry(index, "meaning")
                  }
                >
                  <div>{item.meaning}</div>
                </DropInContainer>
              </DropTarget>
              <DropTarget
                targetKey="foo"
                onHit={(e) => handleDrop(e)}
                onDragEnter={() => setDropZone(index)}
              >
                <DropInContainer
                  style={{
                    fontSize: 30,
                    color:
                      status !== "Check"
                        ? item.radicalT === choices[index].radicalT
                          ? "Green"
                          : "Red"
                        : "gray",
                    borderStyle: "dotted",
                    borderWidth: "2px",
                  }}
                  onClick={() =>
                    item.radicalT === " " ? null : clearEntry(index, "radicalT")
                  }
                >
                  <div>{item.radicalT.substring(0, 1)}</div>
                </DropInContainer>
              </DropTarget>
              <DropTarget
                targetKey="foo"
                onHit={(e) => handleDrop(e)}
                onDragEnter={() => setDropZone(index)}
              >
                <DropInContainer
                  style={{
                    color:
                      status !== "Check"
                        ? item.strokesT === choices[index].strokesT
                          ? "Green"
                          : "Red"
                        : "gray",
                    borderStyle: "dotted",
                    borderWidth: "2px",
                  }}
                  onClick={() =>
                    item.strokesT === " " ? null : clearEntry(index, "strokesT")
                  }
                >
                  <div>{item.strokesT}</div>
                </DropInContainer>
              </DropTarget>
            </div>
          ))}
        </div>
      ) : (
        // Mobile Traditional Section
        <div>
          <StyledInfo>
            <StyledSubInfo>Pinyin: </StyledSubInfo>
            <StyledSubInfo>Meaning: </StyledSubInfo>
            <StyledSubInfo>Radical: </StyledSubInfo>
            <StyledSubInfo>Strokes: </StyledSubInfo>
          </StyledInfo>
          {!wait && (
            <div style={{ float: "left" }}>
              <StyledInput>{traditionalCopy[pos].traditional}</StyledInput>
              <DropTarget
                targetKey="foo"
                onHit={(e) => handleDrop(e)}
                onDragEnter={() => setDropZone(pos)}
              >
                <DropInContainer
                  style={{
                    color:
                      statusMobile !== "Check"
                        ? traditionalCopy[pos].pinyin === choices[pos].pinyin
                          ? "Green"
                          : "Red"
                        : "gray",
                    borderStyle: "dotted",
                    borderWidth: "2px",
                  }}
                  onClick={() =>
                    traditionalCopy[pos].pinyin === " "
                      ? null
                      : clearEntry(pos, "pinyin")
                  }
                >
                  <div>{traditionalCopy[pos].pinyin}</div>
                </DropInContainer>
              </DropTarget>
              <DropTarget
                targetKey="foo"
                onHit={(e) => handleDrop(e)}
                onDragEnter={() => setDropZone(pos)}
              >
                <DropInContainer
                  style={{
                    color:
                      statusMobile !== "Check"
                        ? traditionalCopy[pos].meaning === choices[pos].meaning
                          ? "Green"
                          : "Red"
                        : "gray",
                    borderStyle: "dotted",
                    borderWidth: "2px",
                  }}
                  onClick={() =>
                    traditionalCopy[pos].meaning === " "
                      ? null
                      : clearEntry(pos, "meaning")
                  }
                >
                  <div>{traditionalCopy[pos].meaning}</div>
                </DropInContainer>
              </DropTarget>
              <DropTarget
                targetKey="foo"
                onHit={(e) => handleDrop(e)}
                onDragEnter={() => setDropZone(pos)}
              >
                <DropInContainer
                  style={{
                    fontSize: 30,
                    color:
                      statusMobile !== "Check"
                        ? traditionalCopy[pos].radicalT ===
                          choices[pos].radicalT
                          ? "Green"
                          : "Red"
                        : "gray",
                    borderStyle: "dotted",
                    borderWidth: "2px",
                  }}
                  onClick={() =>
                    traditionalCopy[pos].radicalT === " "
                      ? null
                      : clearEntry(pos, "radicalT")
                  }
                >
                  <div>{traditionalCopy[pos].radicalT.substring(0, 1)}</div>
                </DropInContainer>
              </DropTarget>
              <DropTarget
                targetKey="foo"
                onHit={(e) => handleDrop(e)}
                onDragEnter={() => setDropZone(pos)}
              >
                <DropInContainer
                  style={{
                    color:
                      statusMobile !== "Check"
                        ? traditionalCopy[pos].strokesT ===
                          choices[pos].strokesT
                          ? "Green"
                          : "Red"
                        : "gray",
                    borderStyle: "dotted",
                    borderWidth: "2px",
                  }}
                  onClick={() =>
                    traditionalCopy[pos].strokesT === " "
                      ? null
                      : clearEntry(pos, "strokesT")
                  }
                >
                  <div>{traditionalCopy[pos].strokesT}</div>
                </DropInContainer>
              </DropTarget>
            </div>
          )}
        </div>
      )}

      <div style={{ margin: 5, border: "1px solid black" }}>
        {simplified ? (
          <div
            style={{
              display: "grid",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <div>
              {simpPinyin.map((item, index) => (
                <StyledChoices>
                  <DragDropContainer
                    targetKey="foo"
                    noDragging={status !== "Check"}
                    onDrag={() => setDragSelected(index)}
                    dragData={{ pinyin: item }}
                  >
                    <div>{item}</div>
                  </DragDropContainer>
                </StyledChoices>
              ))}
            </div>
            <div>
              {simpMeaning.map((item, index) => (
                <StyledChoices>
                  <DragDropContainer
                    targetKey="foo"
                    noDragging={status !== "Check"}
                    onDrag={() => setDragSelected(index)}
                    dragData={{ meaning: item }}
                  >
                    <div>{item}</div>
                  </DragDropContainer>
                </StyledChoices>
              ))}
            </div>
            <div>
              {simpRadical.map((item, index) => (
                <StyledChoices style={{ fontSize: 35 }}>
                  <div>
                    <DragDropContainer
                      targetKey="foo"
                      noDragging={status !== "Check"}
                      onDrag={() => setDragSelected(index)}
                      dragData={{ radicalS: item }}
                    >
                      <div>{item.substring(0, 1)}</div>
                    </DragDropContainer>
                  </div>
                </StyledChoices>
              ))}
            </div>
            <div>
              {simpStroke.map((item, index) => (
                <StyledChoices>
                  <div>
                    <DragDropContainer
                      targetKey="foo"
                      noDragging={status !== "Check"}
                      onDrag={() => setDragSelected(index)}
                      dragData={{ strokesS: item }}
                    >
                      <div>{item}</div>
                    </DragDropContainer>
                  </div>
                </StyledChoices>
              ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <div>
              {tradPinyin.map((item, index) => (
                <StyledChoices>
                  <DragDropContainer
                    targetKey="foo"
                    noDragging={status !== "Check"}
                    onDrag={() => setDragSelected(index)}
                    dragData={{ pinyin: item }}
                  >
                    <div>{item}</div>
                  </DragDropContainer>
                </StyledChoices>
              ))}
            </div>
            <div>
              {tradMeaning.map((item, index) => (
                <StyledChoices>
                  <DragDropContainer
                    targetKey="foo"
                    noDragging={status !== "Check"}
                    onDrag={() => setDragSelected(index)}
                    dragData={{ meaning: item }}
                  >
                    <div>{item}</div>
                  </DragDropContainer>
                </StyledChoices>
              ))}
            </div>
            <div>
              {tradRadical.map((item, index) => (
                <StyledChoices style={{ fontSize: 35 }}>
                  <div>
                    <DragDropContainer
                      targetKey="foo"
                      noDragging={status !== "Check"}
                      onDrag={() => setDragSelected(index)}
                      dragData={{ radicalT: item }}
                    >
                      <div>{item.substring(0, 1)}</div>
                    </DragDropContainer>
                  </div>
                </StyledChoices>
              ))}
            </div>
            <div>
              {tradStroke.map((item, index) => (
                <StyledChoices>
                  <div>
                    <DragDropContainer
                      targetKey="foo"
                      noDragging={status !== "Check"}
                      onDrag={() => setDragSelected(index)}
                      dragData={{ strokesT: item }}
                    >
                      <div>{item}</div>
                    </DragDropContainer>
                  </div>
                </StyledChoices>
              ))}
            </div>
          </div>
        )}
      </div>
      {!isPortrait ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <Button
            style={{ marginTop: 10 }}
            variant="contained"
            color="secondary"
            onClick={() => handleStatus()}
          >
            {status}
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <Button
            style={{ margin: 5, marginTop: 10 }}
            variant="contained"
            color="secondary"
            onClick={() => handleStatusMobile()}
          >
            {statusMobile}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
}

export default AnalysisLearning;
