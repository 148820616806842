import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Chinese from "chinese-s2t";
import OpenCC from "node-opencc";
import PlayAudio2 from "../PlayAudio";
import IncorrectIcon from "../icons/IncorrectIcon";
import CorrectIcon from "../icons/CorrectIcon";

/* eslint-disable no-unused-expressions */

const StyledContainer = styled.div``;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 30px;
  margin-left: 0;
  margin-right: 0;
`;

const AnswerChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 25px;
`;

const Audio = styled.audio`
  width: 250px;
  position: relative;
  left: 0%;
  margin-bottom: 15px;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 12px;
`;

const StyledSubQuestion = styled.div`
  font-size: 1.40em;
  font-weight 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledImage = styled.img`
  @media (orientation: landscape) {
    height: 300;
    width: 40vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
  margin: 5;
`;

function MultipleChoice({
  question,
  choices,
  mainaudio,
  audio,
  mainimg,
  text,
  simplified,
  nextLink,
  audioD,
}) {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);
  const [toggleImage, setToggleImage] = useState(false);
  const [style, setStyle] = useState("simplified");
  const [status, setStatus] = useState("Check");

  const addLineBreaks = (string) =>
    string.split("\n").map((text) => (
      <React.Fragment>
        {simplified ? OpenCC.traditionalToSimplified(text) : text}
        <br />
      </React.Fragment>
    ));

  // useEffect(() => {
  //   reset();
  // }, []);

  const handleStyle = () => {
    if (style === "simplified") setStyle("traditional");
    if (style === "traditional") setStyle("simplified");
  };

  const shuffle = (array) => {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };

  const reset = () => {
    choices = shuffle(choices);
    setShowAnswer(false);
    setSelectedAnswer("");
  };

  const handleStatus = () => {
    switch (status) {
      case "Check":
        setShowAnswer(true);
        choices[selectedAnswer].isCorrect !== undefined
          ? setStatus("Next")
          : setStatus("Retry");
        break;
      case "Retry":
        reset();
        setStatus("Check");
        break;
      case "Next":
        window.location.href = nextLink;
        break;
      default:
        break;
    }
  };

  const labelClass = showAnswer ? "lbl-answer" : "";

  return (
    <React.Fragment>
      <StyledQuestion>
        {question[style]
          ? addLineBreaks(question[style])
          : addLineBreaks(question)}
      </StyledQuestion>
      {question[style] ? (
        <Button
          style={{ alignSelf: "center", marginBottom: 10 }}
          variant="contained"
          color="secondary"
          onClick={() => handleStyle()}
        >
          {style}
        </Button>
      ) : null}
      {mainaudio && (
        <Audio controls controlsList="nodownload" src={mainaudio} />
      )}
      {mainimg && !toggleImage && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleImage((toggleImage) => !toggleImage)}
        >
          Show Image
        </Button>
      )}
      {mainimg && toggleImage && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleImage((toggleImage) => !toggleImage)}
        >
          Hide Image
        </Button>
      )}
      {mainimg && toggleImage && <StyledImage alt="loading..." src={mainimg} />}

      {text && (
        <StyledSubQuestion>
          {simplified ? Chinese.t2s(text) : text}
        </StyledSubQuestion>
      )}

      <StyledContainer>
        <RadioGroup
          value={selectedAnswer.toString()}
          onChange={(event) => setSelectedAnswer(event.target.value)}
        >
          {choices.map(
            ({ text, img, isCorrect, traditional, link, audio }, index) => (
              <FormControlLabel
                key={index}
                value={index.toString()}
                className={labelClass}
                control={
                  showAnswer ? (
                    <IconContainer>
                      {showAnswer &&
                        selectedAnswer === index.toString() &&
                        (isCorrect ? <CorrectIcon /> : <IncorrectIcon />)}
                    </IconContainer>
                  ) : (
                    <Radio />
                  )
                }
                label={
                  <AnswerChoiceContainer>
                    {img && (
                      <img
                        style={{ width: 150, height: 150, marginRight: 5 }}
                        alt={text}
                        src={img}
                      />
                    )}
                    <span className="label">{text}</span>
                    {audio && <PlayAudio2 path={audio} />}
                    {link && (
                      <a href={link}>
                        {simplified ? Chinese.t2s("宣傳單") : "宣傳單"}
                      </a>
                    )}
                  </AnswerChoiceContainer>
                }
              />
            )
          )}
        </RadioGroup>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <Button
            style={{ marginTop: 10 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              selectedAnswer === "" ? null : handleStatus();
            }}
          >
            {status}
          </Button>
        </div>
      </StyledContainer>
    </React.Fragment>
  );
}

export default MultipleChoice;
