import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import WordBox from "./WordBox";

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  @media (orientation: landscape) {
    width: 80vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledQuestion = styled.h4`
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

const Body = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function WordRecognition({
  question,
  choices,
  nextLink,
  answerKey,
  simplified,
}) {
  const [status, setStatus] = useState("Check");
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [current, setCurrent] = useState(0);

  const handleClick = (index, choice) => {
    const updatedAnswersArray = [...selectedAnswers];
    updatedAnswersArray[index] = choice;
    setSelectedAnswers(updatedAnswersArray);
  };

  const reset = useCallback(() => {
    setSelectedAnswers([]);
  });

  useEffect(() => reset(), [choices]);

  const landscape =
    process.browser && window.matchMedia("(orientation: landscape)").matches;
  const handleButton = () => {
    switch (status) {
      case "Check":
        const arr = [...selectedAnswers];
        arr.join() === answerKey.join()
          ? setStatus("Next")
          : setStatus("Retry");
        if (landscape) {
          selectedAnswers.join() === answerKey.join()
            ? setStatus("Next")
            : setStatus("Retry");
        } else if (selectedAnswers[current] === answerKey[current]) {
          setStatus("Next");
        } else {
          setStatus("Retry");
        }
        break;
      case "Retry":
        reset();
        setStatus("Check");
        break;
      case "Next":
        if (!landscape && current + 1 < answerKey.length) {
          setCurrent(current + 1);
          setStatus("Check");
        } else {
          window.location.href = nextLink;
        }
        break;
      default:
        break;
    }
  };
  console.log(
    process.browser && window.matchMedia("(orientation: landscape)").matches
  );

  return (
    <React.Fragment>
      <div>
        <StyledQuestion>{question}</StyledQuestion>
      </div>
      {process.browser &&
      window.matchMedia("(orientation: landscape)").matches ? (
        <Body>
          <StyledContainer>
            {choices.map((word, i) => (
              <WordBox
                word={word}
                index={i}
                handleClick={handleClick}
                status={status}
                answerKey={answerKey}
                selectedAnswers={selectedAnswers}
                simplified={simplified}
              />
            ))}
          </StyledContainer>
          <Button
            style={{ margin: "5px" }}
            variant="contained"
            color="secondary"
            onClick={() => handleButton()}
          >
            {status}
          </Button>
        </Body>
      ) : (
        <Body>
          <StyledContainer>
            <WordBox
              word={choices[current]}
              index={current}
              handleClick={handleClick}
              status={status}
              answerKey={answerKey}
              selectedAnswers={selectedAnswers}
              simplified={simplified}
            />
          </StyledContainer>
          <div style={{ flexDirection: "column" }}>
            {/* {current > 0 ? <Button style={{ margin: '5px' }} variant="contained" color="secondary" onClick={() => setCurrent(current - 1)}>Prev</Button> : null} */}
            <Button
              style={{ margin: "5px" }}
              variant="contained"
              color="secondary"
              onClick={() => handleButton()}
            >
              {status}
            </Button>
          </div>
        </Body>
      )}
    </React.Fragment>
  );
}

export default WordRecognition;
