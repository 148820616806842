const basePath = "https://materials.chineseforall.org";
export default {
  0: {
    0: {
      q: "What is the person trying to find?",
      type: "single",
      choices: [
        {
          text: "廁所",
          img: basePath + "/assets/d1-t1-img-4.png",
          isCorrect: true,
        },
        {
          text: "電影院",

          img: basePath + "/assets/d1-t1-img-3.png",
        },
        {
          text: "飯館",

          img: basePath + "/assets/d1-t1-img-1.png",
        },
        {
          text: "旅館",

          img: basePath + "/assets/d1-t1-img-2.png",
        },
      ],
    },
    1: {
      // q: {
      //   simplified: "点击喇叭图示听取语音，拖曳喇叭图示到正确的方格",
      //   english: "Listen to the audio and choose the correct text"],
      // },
      q: "点击喇叭图示听取语音，拖曳喇叭图示到正确的方格",
      altQ: ["Listen to the audio and choose the correct text"],
      type: "Audio MultipleChoice",
      help: "",
      choices: [
        {
          audioSrc:
            "http://chineseforall.org/ch_2009/audio/module1/restroom/audio3.mp3",
          text: "你好小姐，我想问一下厕所在哪儿？",
          traditional: "你好小姐，我想問一下廁所在哪兒？",
        },
        {
          audioSrc:
            "http://chineseforall.org/ch_2009/audio/module1/restroom/audio2.mp3",
          text: "在公园门口。",
          traditional: "在公園門口。",
        },
        {
          audioSrc:
            "http://chineseforall.org/ch_2009/audio/module1/restroom/audio0.mp3",
          text: "请问我该怎么走？",
          traditional: "請問我該怎麼走?",
        },
        {
          audioSrc:
            "http://chineseforall.org/ch_2009/audio/module1/restroom/audio1.mp3",
          text: "一直往前走，到头向右拐就到了。",
          traditional: "一直往前走，到頭向右拐就到了。",
        },
      ],
    },
    2: {
      q: "Listen to the individual audio clips, then put them in the correct order by rearranging them.",
      type: "arrange-audio-clips",
      choices: [
        {
          id: 0,
          audio: basePath + "/assets/audio/module1/restroom/audio0.mp3",
        },
        {
          id: 1,
          audio: basePath + "/assets/audio/module1/restroom/audio1.mp3",
        },
        {
          id: 2,
          audio: basePath + "/assets/audio/module1/restroom/audio2.mp3",
        },
        {
          id: 3,
          audio: basePath + "/assets/audio/module1/restroom/audio3.mp3",
        },
      ],
      answerKey: [3, 2, 0, 1],
    },
    3: {
      q: "Listen to the audio and then choose the correct route.",
      type: "Audio MultipleChoice",
      choices: [
        {
          text: "Route on the right",
          isCorrect: true,
        },
        {
          text: "Route in the middle",
        },
        {
          text: "Route on the left",
        },
      ],
      mainAudio: basePath + "/assets/audio/module1/restroom/audio1.mp3",
      img: basePath + "/assets/images/direction1/task4.jpg",
    },
    4: {
      q: "Match each word with its meaning.",
      type: "line matching",
      choices: [
        {
          english: "park",
          simplified: "公园",
          traditional: "公園",
        },
        {
          english: "bathroom",
          simplified: "厕所",
          traditional: "廁所",
        },
        {
          english: "straight",
          simplified: "一直",
          traditional: "一直",
        },
        {
          english: "to move forward",
          simplified: "往前",
          traditional: "往前",
        },
        {
          english: "to the right",
          simplified: "向右",
          traditional: "向右",
        },
        {
          english: "to the end",
          simplified: "到头",
          traditional: "到頭",
        },
        {
          english: "entrance",
          simplified: "门口",
          traditional: "門口",
        },
        {
          english: "should",
          simplified: "该",
          traditional: "該",
        },
        {
          english: "to turn",
          simplified: "拐",
          traditional: "拐",
        },
        {
          english: "has arrived",
          simplified: "就到了",
          traditional: "就到了",
        },
      ],
    },
    5: {
      q: "Match each word with its meaning.",
      type: "line matching",
      choices: [
        {
          english: "gōng yuán",
          simplified: "公园",
          traditional: "公園",
        },
        {
          english: "cè suǒ",
          simplified: "厕所",
          traditional: "廁所",
        },
        {
          english: "yì zhí",
          simplified: "一直",
          traditional: "一直",
        },
        {
          english: "wǎng qián",
          simplified: "往前",
          traditional: "往前",
        },
        {
          english: "xiàng yòu",
          simplified: "向右",
          traditional: "向右",
        },
        {
          english: "dào tóu",
          simplified: "到头",
          traditional: "到頭",
        },
        {
          english: "mén kǒu",
          simplified: "门口",
          traditional: "門口",
        },
        {
          english: "gāi",
          simplified: "该",
          traditional: "該",
        },
        {
          english: "guǎi",
          simplified: "拐",
          traditional: "拐",
        },
        {
          english: "jiù dào le",
          simplified: "就到了",
          traditional: "就到了",
        },
      ],
    },
    6: {
      q: "Find the compound words by dragging the character to the corresponding box",
      type: "word puzzle",
      choices: [
        {
          english: "park",
          simplified: "公园",
          traditional: "公園",
        },
        {
          english: "bathroom",
          simplified: "厕所",
          traditional: "廁所",
        },
        {
          english: "straight",
          simplified: "一直",
          traditional: "一直",
        },
        {
          english: "to move forward",
          simplified: "往前",
          traditional: "往前",
        },
        {
          english: "to the right",
          simplified: "向右",
          traditional: "向右",
        },
        {
          english: "to the end",
          simplified: "到头",
          traditional: "到頭",
        },
        {
          english: "entrance",
          simplified: "门口",
          traditional: "門口",
        },
        {
          simplified: "左对面走马路顺着这条字",
          traditional: "左對面走馬路順著這條字",
        },
      ],
    },
    7: {
      q: "Click on any segment, it will reveal parts of the character for you to determine what character it is. You can only reveal two segments.",
      type: "word recognition",
      choices: [
        {
          text: "厕",
          choices: ["cè", "zhí", "qián"],
        },
        {
          text: "直",
          choices: ["zhí", "cè", "yuán"],
        },
        {
          text: "前",
          choices: ["zhí", "qián", "yuán "],
        },
        {
          text: "园",
          choices: ["yuán", "cè", "zhí"],
        },
        {
          text: "到",
          choices: ["qián", "dào", "cè"],
        },
      ],
      answerKey: ["cè", "zhí", "qián", "yuán", "dào"],
    },
  },
  1: {
    0: {
      q: "What is the person trying to find?",
      type: "single",
      choices: [
        {
          text: "廁所",
          img: basePath + "/assets/d1-t1-img-4.png",
        },
        {
          text: "電影院",
          img: basePath + "/assets/d1-t1-img-3.png",
          isCorrect: true,
        },
        {
          text: "飯館",
          img: basePath + "/assets/d1-t1-img-1.png",
        },
        {
          text: "旅館",
          img: basePath + "/assets/d1-t1-img-2.png",
        },
      ],
    },
    1: {
      q: "Listen to the audio and then choose the correct text.",
      altQ: ["聽取每個影片，將正確順序的數字拖曳到正確的空格內"],
      type: "Audio MultipleChoice",
      choices: [
        {
          text: "小姐,请问这附近有家电影院吗?",
          audioSrc: basePath + "/assets/audio/module1/theater/audio0.mp3",
        },
        {
          text: "啊,你指的是大光明电影院吗?",
          audioSrc: basePath + "/assets/audio/module1/theater/audio1.mp3",
        },
        {
          text: "对,我该怎么走?",
          audioSrc: basePath + "/assets/audio/module1/theater/audio2.mp3",
        },
        {
          text: "顺着这条路一直向前走,在第二个拐弯儿向左拐,在人民公园对面.",
          audioSrc: basePath + "/assets/audio/module1/theater/audio3.mp3",
        },
      ],
    },
    2: {
      q: 'Identify the phrase that means "Is there a _____ nearby"?',
      altQ: ['分辨哪一個句子是代表 "Is there a ____ nearby" ?'],
      type: "single",
      choices: [
        {
          text: "我該怎麼...?",
        },
        {
          text: "請問，這兒附近有...嗎？",
          isCorrect: true,
        },
        {
          text: "你指的是...?",
        },
      ],
    },
    3: {
      q: "Listen to the audio and then choose the correct route.",
      type: "Audio MultipleChoice",
      mainAudio: basePath + "/assets/audio/module1/theater/audio3.mp3",
      img: basePath + "/assets/d1theatremap.png",
      choices: [
        {
          text: "Route on the right",
        },
        {
          text: "Route in the middle",
          isCorrect: true,
        },
        {
          text: "Route on the left",
        },
      ],
    },
    4: {
      q: "Drag the most appropriate question to each response.",
      type: "dragtest",
      choices: [
        "嗯,你好小姐,我想問一下廁所在哪兒？",
        "小姐,請問這附近有家電影院嗎？",
        "對,我該怎麼走？",
      ],
      answers: [
        "啊，你指的是大光明電影院嗎？",
        "在公園門口",
        "顺著這條路一直向前走，在第二個拐 彎兒向左拐，在人民公園對面",
      ],
      answerKey: [
        "小姐,請問這附近有家電影院嗎？",
        "嗯,你好小姐,我想問一下廁所在哪兒？",
        "對,我該怎麼走？",
      ],
    },
    5: {
      q: "Someone sent you a text message. Read the text and submit a recording about the text message to your teacher. Then, complete the following task.",
      type: "recording",
      img: basePath + "/assets/images/direction1/theater_5_1.png",
      simpImage: basePath + "/assets/images/direction1/theater_5_1_s.jpg",
      choices: [],
    },
    6: {
      q: "Listen to the audio message and type the person's name, address, and the directions based on the audio and send this message to your teacher. ",
      type: "fill",
      mainAudio: basePath + "/assets/audio/module1/theater/partydirection.mp3",
      choices: [
        "李大同, 上海市浦東新區芳甸路一百九十七弄七號十二樓, 就在世紀公園門口的正對面",
      ],
      simplifiedChoices: [
        "李大同，上海市浦东新区芳甸路一百九十七弄七号十二楼，就在世纪公园门口的正对面",
      ],
    },
    7: {
      q: "Rearrange the following characters from the word bank into the correct sentence order.",
      type: "rearrange chinese",
      choices: [
        {
          pinyin: ["shùn", "zhe", "zhè", "tiáo", "lù", "yī", "zhí", "zǒu"],
          simplified: ["顺", "着", "这", "条", "路", "一", "直", "走"],
          traditional: ["順", "著", "這", "條", "路", "一", "直", "走"],
        },
        {
          pinyin: ["xiàng", "yòu", "guǎi"],
          simplified: ["向", "右", "拐"],
          traditional: ["向", "右", "拐"],
        },
        {
          pinyin: ["wǎng", "qián", "zǒu"],
          simplified: ["往", "前", "走"],
          traditional: ["往", "前", "走"],
        },
        {
          pinyin: ["guò", "shí", "guǎi", "lù", "kǒu"],
          simplified: ["过", "十", "字", "路", "口"],
          traditional: ["過", "十", "字", "路", "口"],
        },
        {
          pinyin: ["zài", "mǎ", "lù", "duì", "miàn"],
          simplified: ["在", "马", "路", "对", "面"],
          traditional: ["在", "馬", "路", "對", "面"],
        },
        {
          pinyin: ["zài", "gōng", "yuán", "xié", "duì", "miàn"],
          simplified: ["在", "公", "园", "斜", "对", "面"],
          traditional: ["在", "公", "園", "斜", "對", "面"],
        },
        {
          pinyin: ["zài", "diàn", "yǐng", "yuàn", "mén", "kǒu"],
          simplified: ["在", "电", "影", "院", "门", "口"],
          traditional: ["在", "電", "影", "院", "門", "口"],
        },
      ],
    },
  },
  2: {
    0: {
      q: ["Choose the correct set of directions to reach 王府井."],
      type: "directions",
      img: [
        basePath + "/assets/images/direction1/王府井1.png",
        basePath + "/assets/images/direction1/王府井2.png",
        basePath + "/assets/images/direction1/王府井3.png",
        basePath + "/assets/images/direction1/王府井4.png",
      ],
      choices: [
        {
          text: ["Route on the left", "Route in the middle"],
        },
        {
          text: ["Route in the middle", "Route on the right"],
        },
        {
          text: [
            "Route on the right",
            "Route in the middle",
            "Route on the left",
          ],
        },
      ],
      answerKey: [
        "Route in the middle",
        "Route in the middle",
        "Route on the left",
      ],
    },
    1: {
      q: "请将影片中所提供的指示，录音给你的老师",
      type: "recording",
      audio: {
        src: basePath + "/assets/videos/module1/iamlost/iamlost_all.mp4",
        captionSrc: basePath + "/assets/videos/module1/iamlost/iamlost_s.vtt",
        captionSrcT: basePath + "/assets/videos/module1/iamlost/iamlost_t.vtt",
      },
      choices: [],
    },
  },
  3: {
    0: {
      q: [
        [
          "What forms of transporation can be used to get to the museum? Note: More than one answer is correct, but choose one answer at each time.",
          "What bus should you take?",
          "How many stops will it be?",
          "Where is the bus station?",
        ],
        [
          "What forms of transporation can be used to get to the museum? Note: More than one answer is correct, but choose one answer at each time.",
          "How long will it take?",
          "According to the video, which is the correct route?",
        ],
      ],
      altQ: [
        [
          "这个人要如何去博物馆？  (注意：不只一个答案为正确。)",
          "应该坐什么公车？",
          "有几个站",
          "公车站在哪儿",
        ],
        [
          " 这个人要如何去博物馆？  (注意：不只一个答案为正确。)",
          "要花多久时间？",
          "根据影片，下列哪一个路线正确？",
        ],
      ],
      type: "directions",
      img: [
        [
          basePath + "/assets/images/direction1/beijing/beijing_task2_1.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_2.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_3.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_4.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_5.png",
        ],
        [
          basePath + "/assets/images/direction1/beijing/beijing_task2_1.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_6.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_7.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_8.png",
        ],
      ],
      simpImage: [
        [
          basePath + "/assets/images/direction1/beijing/beijing_task2_s/1.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_s/2.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_s/3.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_s/4.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_s/5.png",
        ],
        [
          basePath + "/assets/images/direction1/beijing/beijing_task2_s/1.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_s/6.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_s/7.png",
          basePath + "/assets/images/direction1/beijing/beijing_task2_s/8.png",
        ],
      ],
      audios: [
        [
          basePath + "/assets/audio/module1/museum/audio_5_all.mp3",
          basePath + "/assets/audio/module1/museum/audio_3.mp3",
          basePath + "/assets/audio/module1/museum/audio_3.mp3",
          basePath + "/assets/audio/module1/museum/audio_6.mp3",
        ],
        [
          basePath + "/assets/audio/module1/museum/audio_5_all.mp3",
          basePath + "/assets/audio/module1/museum/audio_2.mp3",
          basePath + "/assets/audio/module1/museum/audio_1.mp3",
        ],
      ],
      choices: [
        [
          {
            text: ["坐公車", "坐地鐵", "走路"],
          },
          {
            text: ["Bus-23", "Bus-30", "Bus-37"],
          },
          {
            text: ["1", "2", "3"],
          },
          {
            text: ["A", "B", "C"],
          },
        ],
        [
          {
            text: ["坐公車", "坐地鐵", "走路"],
          },
          {
            text: ["5 min", "10 min", "15 min"],
          },
          {
            text: ["Route 1", "Route 2", "Route 3"],
          },
        ],
      ],
      answerKey: [
        ["坐公車", "Bus-23", "1", "B"],
        ["走路", "15 min", "Route 3"],
      ],
    },
    1: {
      q: [
        "Work with a partner to complete partial directions. Decide who is going to be Student A and Student B. Click on the buttons to get your directions for this exercise. Student A and Student B will give each other directions to start from the arrow (A) and reach to the destination (B) on the map on the next page. The two of you will make a recording of the directions in the correct order in Chinese and submit it to your teacher. Please write out the completed direction and submit to your instructor.",
      ],
      altQ: [
        "請和你的同伴合作一起完成不完整的指示，並錄音回答下列的問題給你的老師。请写出完整的指示，然后交给你的老师。",
        "學生A",
        "學生B",
      ],

      type: "interactive",
      img: [
        basePath + "/assets/images/direction1/beijing/beijing_task3_AB.png",
        basePath + "/assets/images/direction1/beijing/beijing_task3_AB.png",
      ],
      choices: [
        {
          routeName: "Student A",
          routeData: {
            directions: [
              "Step 2: Walk to 1st Ave then make a right turn.",
              "Step 4: At 2nd Ave then make a left turn.",
              "Step 6: At 3rd Ave make a left turn. The destination is on your left.",
            ],
          },
        },
        {
          routeName: "Student B",
          routeData: {
            directions: [
              "Step 1: Across 3rd Ave walk to 12th street.",
              "Step 3: At the first intersection, make a right turn.",
              "Step 5: At 14th Street make a left turn.",
            ],
          },
        },
      ],
      altChoices: [
        {
          routeName: "Student A",
          routeData: {
            directions: [
              "2. 走到一大道向右转",
              "4. 到二大道往左转",
              "6. 到三大道左转，就在你的左边",
            ],
          },
        },
        {
          routeName: "Student B",
          routeData: {
            directions: [
              "1. 穿过三大道往12街走",
              "3. 第一个十字路口向右转",
              "5. 到14街往右转",
            ],
          },
        },
      ],
    },
    2: {
      q: [
        "Select one of the following to complete a role-play exercise with your conversation partner",
        "Describe the map. You start from the arrow and make a recording on how to go to the restroom. Submit your recording to your teacher.",
        "Describe the map. You start from the arrow and make a recording on how to go to the museum. Submit your recording to your teacher.",
        "Describe the map. You start from the arrow and make a recording on how to go to the movie theater. Submit your recording to your teacher.",
      ],
      altQ: [
        "選擇下列一个和你的對話同伴做角色扮演。",
        "描述怎麼從箭頭的地方開始走到廁所。錄音寄给老師。",
        "描述怎麼從箭頭的地方開始走到博物館。錄音寄给老師。",
        "描述怎麼從箭頭的地方開始走到電影院。錄音寄给老師。",
      ],
      type: "interactive",
      choices: [
        {
          routeName: "Bathroom",
          routeData: {
            subRouteNames: ["Student", "Tutor"],
            routeImages: [
              basePath +
                "/assets/images/direction1/beijing/beijing_task4_1S.png",
              basePath +
                "/assets/images/direction1/beijing/beijing_task4_1T.png",
            ],
          },
        },
        {
          routeName: "Museum",
          routeData: {
            subRouteNames: ["Student", "Tutor"],
            routeImages: [
              basePath +
                "/assets/images/direction1/beijing/beijing_task4_2S.png",
              basePath +
                "/assets/images/direction1/beijing/beijing_task4_2T.png",
            ],
          },
        },
        {
          routeName: "Movie Theater",
          routeData: {
            subRouteNames: ["Student", "Tutor"],
            routeImages: [
              basePath +
                "/assets/images/direction1/beijing/beijing_task4_3S.png",
              basePath +
                "/assets/images/direction1/beijing/beijing_task4_3T.png",
            ],
          },
        },
      ],
    },
  },
};
