import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Chinese from "chinese-s2t";
import PlayAudio from "../PlayAudio";
import IncorrectIcon from "../icons/IncorrectIcon";
import CorrectIcon from "../icons/CorrectIcon";
import OpenCC from "node-opencc";

/* eslint-disable no-unused-expressions */

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 25px;
  margin-left: 0;
  margin-right: 0;
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 25px;
`;

const AnswerChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 20px;
`;

const AudioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-size: 20px;
  flex-direction: column;
`;

const Audio = styled.audio`
  width: 250px;
  position: relative;
  left: 0%;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 12px;
`;

const StyledSubQuestion = styled.div`
  font-size: 1.30em;
  font-weight 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledImage = styled.img`
  @media (orientation: landscape) {
    height: 300;
    width: 40vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
  margin: 5;
`;

const StyledImageSmall = styled.img`
  @media (orientation: landscape) {
    height: 300;
    width: 20vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
  margin: 5;
`;

function AudioMultipleChoice({
  question,
  choices,
  mainaudio,
  mainimg,
  text,
  nextLink,
  simplified,
  mainAudioSubtext,
  taskSpecificParams,
  help,
}) {
  const [toggleImage, setToggleImage] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState("Check");
  const [shuffled, setShuffled] = useState([]);
  const [hasIsCorrectProperty, setHasIsCorrectProperty] = useState(false);

  const reset = useCallback(() => {
    setShuffled([...choices].sort(() => Math.random() - 0.5));
    setShowAnswer(false);
    setSelectedAnswer("");
    for (const index in choices) {
      if (choices[index].isCorrect) {
        setHasIsCorrectProperty(true);
      }
    }
  });

  useEffect(() => {
    reset();
  }, []);

  const checkAnswer = (e) => {
    setSelectedAnswer(e.target.value);
  };

  const handleStatus = () => {
    switch (status) {
      case "Check":
        setShowAnswer(true);
        if (hasIsCorrectProperty) {
          shuffled[selectedAnswer].isCorrect
            ? setStatus("Next")
            : setStatus("Retry");
        } else {
          shuffled[selectedAnswer].text === choices[current].text
            ? setStatus("Next")
            : setStatus("Retry");
        }
        break;
      case "Retry":
        reset();
        setStatus("Check");
        break;
      case "Next":
        if (current + 1 < choices.length && choices[current].audioSrc) {
          reset();
          setCurrent(current + 1);
          setStatus("Check");
        } else {
          window.location.href = nextLink;
        }
        break;
      default:
        break;
    }
  };

  const labelClass = showAnswer ? "lbl-answer" : "";
  const style = simplified ? "simplified" : "traditional";
  let MainImageModule = <StyledImage alt="loading..." src={mainimg} />;
  let SubImageModule = (
    <StyledImage alt="loading..." src={choices[current].subImg} />
  );
  let subQuestion = null;
  if (taskSpecificParams !== undefined) {
    if (taskSpecificParams.imgSizing === "small") {
      MainImageModule = <StyledImageSmall alt="loading..." src={mainimg} />;
      SubImageModule = (
        <StyledImageSmall alt="loading..." src={choices[current].subImg} />
      );
    }
    if (taskSpecificParams.subQuestions) {
      subQuestion = simplified
        ? taskSpecificParams.subQuestions[current]
        : Chinese.s2t(taskSpecificParams.subQuestions[current]);
    }
  }
  let displayedQuestion = "";
  if (question.simplified) {
    displayedQuestion = simplified
      ? question.simplified
      : OpenCC.simplifiedToTraditional(question.simplified);
  } else {
    displayedQuestion = question;
  }
  return (
    <React.Fragment>
      <QuestionContainer>
        <StyledQuestion>{displayedQuestion}</StyledQuestion>
        {subQuestion}
      </QuestionContainer>

      <AudioContainer>
        {mainaudio && (
          <Audio controls controlsList="nodownload" src={mainaudio} />
        )}
        {simplified ? mainAudioSubtext : Chinese.s2t(mainAudioSubtext)}
        <div style={{ display: "flex", alignItems: "center" }}>
          {`${current + 1}/${choices.length}`}
          {!mainaudio ? (
            choices[current].audioSrc ? (
              <Audio
                controls
                controlsList="nodownload"
                src={choices[current].audioSrc}
              />
            ) : null
          ) : null}
        </div>
      </AudioContainer>

      {(mainimg || choices[current].subImg) && !toggleImage && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleImage((toggleImage) => !toggleImage)}
        >
          Show Image
        </Button>
      )}
      {(mainimg || choices[current].subImg) && toggleImage && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleImage((toggleImage) => !toggleImage)}
        >
          Hide Image
        </Button>
      )}
      {mainimg && toggleImage && MainImageModule}
      {!mainimg && toggleImage && choices[current].subImg && SubImageModule}

      {text && <StyledSubQuestion>{text}</StyledSubQuestion>}

      <StyledContainer>
        <RadioGroup
          value={selectedAnswer.toString()}
          onChange={(e) => checkAnswer(e)}
        >
          {shuffled.map(
            ({ text, img, isCorrect, traditional, audio }, index) => {
              let iconContainer;
              if (hasIsCorrectProperty) {
                iconContainer = (
                  <IconContainer>
                    {showAnswer &&
                      selectedAnswer === index.toString() &&
                      (shuffled[index].isCorrect ? (
                        <CorrectIcon />
                      ) : (
                        <IncorrectIcon />
                      ))}
                  </IconContainer>
                );
              } else {
                iconContainer = (
                  <IconContainer>
                    {showAnswer &&
                      selectedAnswer === index.toString() &&
                      (shuffled[index].text === choices[current].text ? (
                        <CorrectIcon />
                      ) : (
                        <IncorrectIcon />
                      ))}
                  </IconContainer>
                );
              }
              return (
                <FormControlLabel
                  key={index}
                  value={index.toString()}
                  className={labelClass}
                  control={showAnswer ? iconContainer : <Radio />}
                  label={
                    <AnswerChoiceContainer>
                      {img && <img alt={text} src={img} />}
                      {hasIsCorrectProperty ? (
                        <span className="label">
                          {simplified ? text : Chinese.s2t(text)}
                        </span>
                      ) : (
                        <span className="label">
                          {simplified ? text : Chinese.s2t(text)}
                        </span>
                      )}
                      {audio && <PlayAudio path={audio} />}
                    </AnswerChoiceContainer>
                  }
                />
              );
            }
          )}
        </RadioGroup>
        <Button
          style={{ alignSelf: "center", marginTop: 10 }}
          variant="contained"
          color="secondary"
          onClick={() => {
            selectedAnswer === "" ? null : handleStatus();
          }}
        >
          {status}
        </Button>
      </StyledContainer>
    </React.Fragment>
  );
}

export default AudioMultipleChoice;
