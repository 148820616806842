import { combineReducers } from 'redux';
import modules from './modules';
import page from './page';
import user from './user';

export default combineReducers({
  page,
  modules,
  user,
});
