import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import data from "../../../data/chengyu/normalized.js";
import CrosswordPractice from "../Problems/CrosswordExercise/CrosswordPractice";
import { chunk } from "lodash";

class CrosswordExercise extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      groupId,
      problemChunkStart,
      tradId,
      lesson = 1,
    } = this.props.match.params;
    const routeLang =
      tradId === undefined
        ? tradId
        : tradId === 1
        ? "traditional"
        : "simplified";
    const language = routeLang || this.props.language || "traditional";
    const dataName = this.props.match.params[0] || this.props.dataName;
    const crosswordData =
      data[dataName][lesson].qaData["chengyuCrosswordData"][language][groupId];
    const chunkBy = this.props.chunkBy;
    const crosswordProblemSet =
      problemChunkStart && chunkBy
        ? chunk(crosswordData, chunkBy)[problemChunkStart]
        : crosswordData;
    const returnRoute =
      this.props.returnRoute ||
      `/chengyu/1/${language === "traditional" ? 1 : 0}/${groupId}/exercises`;
    return (
      <div>
        <CrosswordPractice
          crosswordProblemSet={crosswordProblemSet}
          allCrosswordData={crosswordData}
          language={language}
          returnRoute={returnRoute}
        />
      </div>
    );
  }
}

export default withRouter(CrosswordExercise);
