import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import CorrectIcon from "../icons/CorrectIcon";
import IncorrectIcon from "../icons/IncorrectIcon";
import Chinese from "chinese-s2t";

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
  maxwidth: 500px;
`;

const IconContainer = styled.div`
  min-width: 24px;
  min-height: 24px;
`;

const StyledChoices = styled.div`
  display: flex;
  @media (orientation: landscape) {
    flex-direction: row;
  }

  @media (orientation: portrait) {
    flex-direction: column;
  }
  width: 90%;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5rem;
`;

const NumberPad = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 250px;
  justify-content: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function DragTest({
  question,
  choices,
  answers,
  answerKey,
  audio,
  help,
  simplified,
  links,
}) {
  const [errors, setErrors] = useState(false);

  const [dragSelected, setDragSelected] = useState(0);
  const [dropZone, setDropZone] = useState(0);

  const [choicesList, setChoicesList] = useState([]);
  const [answersList, setAnswersList] = useState([]);

  useEffect(() => {
    setChoicesList(choices);
    setAnswersList([...answers].fill(""));
  }, []);

  const handleDrop = () => {
    if (!errors && choicesList[dragSelected]) {
      setAnswersList(
        [...answersList].map((item, index) =>
          index === dropZone ? choicesList[dragSelected] : item
        )
      );
    }
  };

  const checkAnswer = () => {
    setErrors(true);
  };

  const retry = () => {
    setErrors(false);
    setAnswersList([...answers].fill(""));
    setChoicesList(choices);
  };

  const play = (path) => {
    const audioFile = new Audio(path);
    audioFile.play();
  };

  const clearEntry = (index) => {
    // const deletedElement = answersList[index];
    // let newChoices = [];
    // for (let i = 0; i < choicesList.length; i++) {
    //   if (choices[i] === deletedElement) newChoices.push(choices[i])
    //   else {
    //     newChoices.push(choicesList[i])
    //   }
    // }
    // setChoicesList(newChoices);
    setAnswersList(
      [...answersList].map((item, i) => (index === i ? "" : item))
    );
  };

  return (
    <React.Fragment>
      <StyledQuestion>
        {simplified ? Chinese.t2s(question) : question}
      </StyledQuestion>
      {audio && (
        <p>
          <audio controls>
            <source src={audio} />
          </audio>
        </p>
      )}
      <StyledChoices>
        <NumberPad>
          {choicesList.map((item, index) => (
            <DragDropContainer
              targetKey="foo"
              onDrag={() => setDragSelected(index)}
            >
              <Button
                variant="contained"
                color="blue"
                style={{ margin: 5, fontSize: "20px" }}
                onClick={() => {
                  play(links[item]);
                }}
              >
                {item || ""}
              </Button>
            </DragDropContainer>
          ))}
        </NumberPad>
        <TextContainer>
          {answersList.map((item, index) => {
            const icon =
              answersList[index] === answerKey[index] ? (
                <CorrectIcon />
              ) : (
                <IncorrectIcon />
              );
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  lineHeight: 1,
                }}
              >
                <IconContainer>{errors && icon}</IconContainer>
                <DropTarget
                  targetKey="foo"
                  onHit={() => {
                    handleDrop();
                  }}
                  onDragEnter={() => setDropZone(index)}
                >
                  <Button
                    variant="contained"
                    color="blue"
                    style={{ margin: 5, height: "90px", width: "150px" }}
                    onClick={() => clearEntry(index)}
                  >
                    <span>{item}</span>
                  </Button>
                </DropTarget>
                <div>{answers[index]}</div>
              </div>
            );
          })}
        </TextContainer>
      </StyledChoices>
      <div style={{ margin: "5px" }}>
        {!errors && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => checkAnswer()}
          >
            Check
          </Button>
        )}
        {errors && (
          <Button variant="contained" color="secondary" onClick={() => retry()}>
            Retry
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}
