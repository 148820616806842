const unsplitChengyu2Data = [
  {
    traditional: "背道而馳",
    simplified: "背道而驰",
    pinyin: "bei4 dao4 er2 chi2",
    literalDefinition: "opposite-road-to-drive",
    englishDefinition:
      "to run in the opposite direction (idiom); to run counter to",
    sentenceTraditional: "他自己的行爲在實質上都與他的原則背道而馳 。",
    sentenceSimplified: "他自己的行为在实质上都与他的原则背道而驰 。",
    vocabLink: '/chengyu/2/group/1/vocabulary/0',
  },
  {
    traditional: "不可或缺",
    simplified: "不可或缺",
    pinyin: "bu4 ke3 huo4 que1",
    literalDefinition: "not-able-lack of",
    englishDefinition: "necessary / must have",
    sentenceTraditional:
      "大多數的奢侈品和許多所謂的生活便利品，不僅不是不可或缺的，而且對人類的進步是一種阻礙。",
    sentenceSimplified:
      "大多数的奢侈品和许多所谓的生活便利品，不仅不是不可或缺的，而且对人类的进步是一种阻碍。",
    vocabLink: '/chengyu/2/group/1/vocabulary/1',
  },
  {
    traditional: "不勞而獲",
    simplified: "不劳而获",
    pinyin: "bu4 lao2 er2 huo4",
    literalDefinition: "not-labor-but-obtain",
    englishDefinition: "to reap without sowing ",
    sentenceTraditional: "這個世界沒有不勞而獲的事 ， 想得到就得有付出 ！",
    sentenceSimplified: "这个世界没有不劳而获的事 ， 想得到就得有付出 ！",
    vocabLink: '/chengyu/2/group/1/vocabulary/2',
  },
  {
    traditional: "不切實際",
    simplified: "不切实际",
    pinyin: "bu4 qie1 shi2 ji4",
    literalDefinition: "not-match-reality",
    englishDefinition: "unrealistic / impractical",
    sentenceTraditional:
      "在確定銷售計劃之前，應先進行市場調查，以免做出不切實際的預測。",
    sentenceSimplified:
      "在确定销售计划之前，应先进行市场调查，以免做出不切实际的预测。",
    vocabLink: '/chengyu/2/group/1/vocabulary/3',
  },
  {
    traditional: "不以爲然",
    simplified: "不以为然",
    pinyin: "bu4 yi3 wei2 ran2",
    literalDefinition: "not-think-so",
    englishDefinition: "not to accept as correct ",
    sentenceTraditional: "他對我的觀點不以爲然，還是堅持己見。",
    sentenceSimplified: "他对我的观点不以为然，还是坚持己见。",
    vocabLink: '/chengyu/2/group/1/vocabulary/4',
  },
  {
    traditional: "不遠千里",
    simplified: "不远千里",
    pinyin: "bu4 yuan3 qian1 li3",
    literalDefinition: "not-far-thousand-miles",
    englishDefinition: "make light of traveling a thousand li ",
    sentenceTraditional:
      "學生們爲了找到合適的工作，甚至不遠千里去參加外省市的人才招聘會。",
    sentenceSimplified:
      "学生们为了找到合适的工作，甚至不远千里去参加外省市的人才招聘会。",
    vocabLink: '/chengyu/2/group/1/vocabulary/5',
  },
  {
    traditional: "不折不扣",
    simplified: "不折不扣",
    pinyin: "bu4 zhe1 bu4 kou4",
    literalDefinition: "not-bend-not-reduce",
    englishDefinition: "a hundred percent / to the letter / out-and-out",
    sentenceTraditional: "破壞地球的環境是一種不折不扣的自殺行為。",
    sentenceSimplified: "破坏地球的环境是一种不折不扣的自杀行为。",
    vocabLink: '/chengyu/2/group/1/vocabulary/6',
  },
  {
    traditional: "參差不齊",
    simplified: "参差不齐",
    pinyin: "can1 cha4 bu4 qi2",
    literalDefinition: "different-not-even",
    englishDefinition: "variable / uneven / irregular",
    sentenceTraditional:
      "參加這次比賽的隊伍，水準可說是近年來最參差不齊的一次。",
    sentenceSimplified:
      "参加这次比赛的队伍，水準可说是近年来最参差不齐的一次。",
    vocabLink: '/chengyu/2/group/1/vocabulary/7',
  },
  {
    traditional: "暢所欲言",
    simplified: "畅所欲言",
    pinyin: "chang4 suo3 yu4 yan2",
    literalDefinition: "fluent-for-desire-talk",
    englishDefinition:
      "to preach freely on one's favorite topic / to hold forth to one's heart's content",
    sentenceTraditional: "同學們對這個問題暢所欲言，進行了熱烈的討論。",
    sentenceSimplified: "同学们对这个问题畅所欲言，进行了热烈的讨论。",
    vocabLink: '/chengyu/2/group/1/vocabulary/8',
  },
  {
    traditional: "大同小異",
    simplified: "大同小异",
    pinyin: "da4 tong2 xiao3 yi4",
    literalDefinition: "big-similarity-small-difference",
    englishDefinition: "virtually the same / differing only on small points",
    sentenceTraditional:
      "有一位外國作家說過：幸福的家庭都大同小異， 不幸的家庭則各不相同 。",
    sentenceSimplified:
      "有一位外国作家说过：幸福的家庭都大同小异， 不幸的家庭则各不相同 。",
    vocabLink: '/chengyu/2/group/1/vocabulary/9',
  },
  {
    traditional: "各持己見",
    simplified: "各持己见",
    pinyin: "ge4 chi2 ji3 jian4",
    literalDefinition: "each-hold-self-opinion",
    englishDefinition:
      "each sticks to his own opinion (idiom); chacun son gout",
    sentenceTraditional: "我們常常各持己見 ，但我們的爭論常使雙方獲益匪淺 。",
    sentenceSimplified: "我们常常各持己见 ，但我们的争论常使双方获益匪浅 。",
    vocabLink: '/chengyu/2/group/2/vocabulary/0',
  },
  {
    traditional: "功成名就",
    simplified: "功成名就",
    pinyin: "gong1 cheng2 ming2 jiu4",
    literalDefinition: "achivement-succeed-fame-achieved",
    englishDefinition: "to win success and recognition",
    sentenceTraditional:
      "很多人功成名就之後就開始自我膨脹,不能保持平和謙虛的心態。",
    sentenceSimplified:
      "很多人功成名就之后就开始自我膨胀,不能保持平和谦虚的心态。",
    vocabLink: '/chengyu/2/group/2/vocabulary/1',
  },
  {
    traditional: "歸心似箭",
    simplified: "归心似箭",
    pinyin: "gui1 xin1 si4 jian4",
    literalDefinition: "return-heart-like-arrow",
    englishDefinition: "with one's heart set on speeding home",
    sentenceTraditional:
      "春節就要到了，出門在外的人們都歸心似箭，盼望與家人團聚。",
    sentenceSimplified:
      "春节就要到了，出门在外的人们都归心似箭，盼望与家人团聚。",
    vocabLink: '/chengyu/2/group/2/vocabulary/2',
  },
  {
    traditional: "海枯石爛",
    simplified: "海枯石烂",
    pinyin: "hai3 ku1 shi2 lan4",
    literalDefinition: "sea-dry-stone-rotten",
    englishDefinition: "forever",
    sentenceTraditional: "海枯石爛，至死不渝的愛情一直爲青年朋友們所向嚮往。",
    sentenceSimplified: "海枯石烂，至死不渝的爱情一直为青年朋友们所向往。",
    vocabLink: '/chengyu/2/group/2/vocabulary/3',
  },
  {
    traditional: "畫蛇添足",
    simplified: "画蛇添足",
    pinyin: "hua4 she2 tian1 zu2",
    literalDefinition: "draw-snake-add-foot",
    englishDefinition:
      "to ruin the effect by adding sth superfluous / to overdo it",
    sentenceTraditional: "多寫上這一句，不但沒增強表現力，反而成了畫蛇添足。",
    sentenceSimplified: "多写上这一句，不但没增强表现力，反而成了画蛇添足。",
    vocabLink: '/chengyu/2/group/2/vocabulary/4',
  },
  {
    traditional: "價廉物美",
    simplified: "价廉物美",
    pinyin: "jia4 lian2 wu4 mei3",
    literalDefinition: "price-cheap-thing-good",
    englishDefinition: "inexpensive and of good quality",
    sentenceTraditional: "這種洗衣粉價廉物美,很受大衆歡迎。",
    sentenceSimplified: "这种洗衣粉价廉物美,很受大众欢迎。",
    vocabLink: '/chengyu/2/group/2/vocabulary/5',
  },
  {
    traditional: "見仁見智",
    simplified: "见仁见智",
    pinyin: "jian4 ren2 jian4 zhi4",
    literalDefinition: "see-kindness-see-intelligence",
    englishDefinition: "opinions differ",
    sentenceTraditional: "對這個問題的看法,大家是見仁見智。",
    sentenceSimplified: "对这个问题的看法,大家是见仁见智。",
    vocabLink: '/chengyu/2/group/2/vocabulary/6',
  },
  {
    traditional: "截然不同",
    simplified: "截然不同",
    pinyin: "jie2 ran2 bu4 tong2",
    literalDefinition: "entirely-different",
    englishDefinition: "entirely different / different as black and white",
    sentenceTraditional:
      "被動的學習態度與主動的學習態度是截然不同的，而得出來的結果也是截然不同的！",
    sentenceSimplified:
      "被动的学习态度与主动的学习态度是截然不同的，而得出来的结果也是截然不同的！",
    vocabLink: '/chengyu/2/group/2/vocabulary/7',
  },
  {
    traditional: "井底之蛙",
    simplified: "井底之蛙",
    pinyin: "jing3 di3 zhi1 wa1",
    literalDefinition: "well-bottom-of-frog",
    englishDefinition: "the frog at the bottom of the well",
    sentenceTraditional: "我們應該到各地去走走，好增廣見聞，免得成爲井底之蛙。",
    sentenceSimplified: "我们应该到各地去走走，好增广见闻，免得成为井底之蛙。",
    vocabLink: '/chengyu/2/group/2/vocabulary/8',
  },
  {
    traditional: "刻不容緩",
    simplified: "刻不容缓",
    pinyin: "ke4 bu4 rong2 huan3",
    literalDefinition: "moment-not-allow-slow",
    englishDefinition: "to brook no delay / to demand immediate action",
    sentenceTraditional: "全球環境污染日趨嚴重，保護環境刻不容緩。",
    sentenceSimplified: "全球环境污染日趋严重，保护环境刻不容缓。",
    vocabLink: '/chengyu/2/group/2/vocabulary/9',
  },
  {
    traditional: "品學兼優",
    simplified: "品学兼优",
    pinyin: "pin3 xue2 jian1 you1",
    literalDefinition: "moral-learning-both-superior",
    englishDefinition:
      "excelling both in morals and studies (idiom); top marks for studies and for behavior (at school)",
    sentenceTraditional:
      "這些捐款將全部捐給希望工程北京捐助中心，用以資助那些家庭貧困 、 品學兼優的中小學生 。",
    sentenceSimplified:
      "这些捐款将全部捐给希望工程北京捐助中心，用以资助那些家庭贫困 、 品学兼优的中小学生 。",
    vocabLink: '/chengyu/2/group/3/vocabulary/0',
  },
  {
    traditional: "平心而論",
    simplified: "平心而论",
    pinyin: "ping2 xin1 er2 lun4",
    literalDefinition: "peace-heart-discuss",
    englishDefinition: "to be honest, ...",
    sentenceTraditional: "她工作很慢,但平心而論,她確實非常努力",
    sentenceSimplified: "她工作很慢,但平心而论,她确实非常努力",
    vocabLink: '/chengyu/2/group/3/vocabulary/1',
  },
  {
    traditional: "三教九流",
    simplified: "三教九流",
    pinyin: "san1 jiao4 jiu3 liu2",
    literalDefinition: "three-religion-nine-schools",
    englishDefinition: "people from all trades (often derog.)",
    sentenceTraditional:
      "他的創作題材非常廣泛  ，塑造的人物形象更是三教九流 ，無所不包 。",
    sentenceSimplified:
      "他 的创作题材非常广泛 ，塑造的人物形象更是三教九流 ，无所不包 。",
    vocabLink: '/chengyu/2/group/3/vocabulary/2',
  },
  {
    traditional: "適得其反",
    simplified: "适得其反",
    pinyin: "shi4 de2 qi2 fan3",
    literalDefinition: "just-obtain-its-opposite",
    englishDefinition: "to produce the opposite of the desired result",
    sentenceTraditional: "父母對子女期望值過高所導致的結果，往往是適得其反。",
    sentenceSimplified: "父母对子女期望值过高所导致的结果，往往是适得其反。",
    vocabLink: '/chengyu/2/group/3/vocabulary/3',
  },
  {
    traditional: "視而不見",
    simplified: "视而不见",
    pinyin: "shi4 er2 bu4 jian4",
    literalDefinition: "look-but-not-see",
    englishDefinition: "to turn a blind eye to / to ignore",
    sentenceTraditional:
      "當人們全身心地 投入在某個活動中時 ，他們常對周圍的事物視而不見 。",
    sentenceSimplified:
      "当人们 全身心地投入在 某个活动中时 ，他们常对周围的事物视而不见 。",
    vocabLink: '/chengyu/2/group/3/vocabulary/4',
  },
  {
    traditional: "唯利是圖",
    simplified: "唯利是图",
    pinyin: "wei2 li4 shi4 tu2",
    literalDefinition: "only-benefit-is-desire",
    englishDefinition: "to seek nothing but profit",
    sentenceTraditional: "他這個人唯利是圖 , 我不知道他有什麼無私的行爲 。",
    sentenceSimplified: "他这个人唯利是图 , 我不知道他有什么无私的行为 。",
    vocabLink: '/chengyu/2/group/3/vocabulary/5',
  },
  {
    traditional: "爲人師表",
    simplified: "为人师表",
    pinyin: "wei2 ren2 shi1 biao3",
    literalDefinition: "be-people-teacher-model",
    englishDefinition:
      "to serve as a model for others (idiom) / to be a worthy teacher",
    sentenceTraditional:
      "教師,重在爲人師表,其言行對學生的世界觀、人生觀和價值觀的形成是十分重要的。",
    sentenceSimplified:
      "教师,重在为人师表,其言行对学生的世界观、人生观和价值观的形成是十分重要的。",
    vocabLink: '/chengyu/2/group/3/vocabulary/6',
  },
  {
    traditional: "未雨綢繆",
    simplified: "未雨绸缪",
    pinyin: "wei4 yu3 chou2 mou2",
    literalDefinition: "not-rain-prepared",
    englishDefinition: "to plan ahead / to prepare for a rainy day",
    sentenceTraditional:
      "如何預測這些變化，未雨綢繆，取得市場的先機，對企業的未來發展有著重大的影響。",
    sentenceSimplified:
      "如何预测这些变化，未雨绸缪，取得市场的先机，对企业的未来发展有着重大的影响。",
    vocabLink: '/chengyu/2/group/3/vocabulary/7',
  },
  {
    traditional: "無話不談",
    simplified: "无话不谈",
    pinyin: "wu2 hua4 bu4 tan2",
    literalDefinition: "no-words-not-talk",
    englishDefinition: "not to hold anything back ",
    sentenceTraditional: "我跟他剛認識就成了無話不談的好朋友。",
    sentenceSimplified: "我跟他刚认识就成了无话不谈的好朋友。",
    vocabLink: '/chengyu/2/group/3/vocabulary/8',
  },
  {
    traditional: "無所不能",
    simplified: "无所不能",
    pinyin: "wu2 suo3 bu4 neng2",
    literalDefinition: "no-not-capable",
    englishDefinition: "omnipotent",
    sentenceTraditional:
      "小時候，總覺得爸爸是無所不能的超人，好像永遠都不會老。",
    sentenceSimplified:
      "小时候，总觉得爸爸是无所不能的超人，好像永远都不会老。",
    vocabLink: '/chengyu/2/group/3/vocabulary/9',
  },
  {
    traditional: "無微不至",
    simplified: "无微不至",
    pinyin: "wu2 wei1 bu4 zhi4",
    literalDefinition: "not-tiny-not-arrive",
    englishDefinition: "in every possible way",
    sentenceTraditional:
      "如果沒有母親無微不至的關懷，沒有父親默默無私的奉獻，哪裏會有這麼溫暖幸福的家。",
    sentenceSimplified:
      "如果没有母亲无微不至的关怀，没有父亲默默无私的奉献，哪里会有这么温暖幸福的家。",
    vocabLink: '/chengyu/2/group/4/vocabulary/0',
  },
  {
    traditional: "喜出望外",
    simplified: "喜出望外",
    pinyin: "xi3 chu1 wang4 wai4",
    literalDefinition: "joy-out-expectation-beyond",
    englishDefinition: "to be pleased beyond one's expectations",
    sentenceTraditional: "她聽到這個消息喜出望外。",
    sentenceSimplified: "她听到这个消息喜出望外。",
    vocabLink: '/chengyu/2/group/4/vocabulary/1',
  },
  {
    traditional: "信口開河",
    simplified: "信口开河",
    pinyin: "xin4 kou3 kai1 he2",
    literalDefinition: "casual-mouth-open-river",
    englishDefinition: "to speak without thinking",
    sentenceTraditional: "別聽他信口開河 , 你得自己去弄清楚。",
    sentenceSimplified: "别听他信口开河 , 你得自己去弄清楚。",
    vocabLink: '/chengyu/2/group/4/vocabulary/2',
  },
  {
    traditional: "軒然大波",
    simplified: "轩然大波",
    pinyin: "xuan1 ran2 da4 bo1",
    literalDefinition: "huge-big-wave",
    englishDefinition: "ruckus / controversy / sensation",
    sentenceTraditional:
      "學費調漲的消息一經披露，便引起軒然大波，家長與學生無不叫苦連天，怨聲載道。",
    sentenceSimplified:
      "学费调涨的消息一经披露，便引起轩然大波，家长与学生无不叫苦连天，怨声载道。",
    vocabLink: '/chengyu/2/group/4/vocabulary/3',
  },
  {
    traditional: "訓練有素",
    simplified: "训练有素",
    pinyin: "xun4 lian4 you3 su4",
    literalDefinition: "traing-have-quality",
    englishDefinition: "well-trained",
    sentenceTraditional: "帶領學生成爲訓練有素的專業人員，是一件很值得的事！",
    sentenceSimplified: "带领学生成为训练有素的专业人员，是一件很值得的事！ ",
    vocabLink: '/chengyu/2/group/4/vocabulary/4',
  },
  {
    traditional: "一舉兩得",
    simplified: "一举两得",
    pinyin: "yi1 ju3 liang3 de2",
    literalDefinition: "one-action-two-gains",
    englishDefinition: "one move, two gains",
    sentenceTraditional:
      "北京附近好玩的地方也不少，去京郊 ，既放鬆了心情 ， 又不會太累 ， 可謂是一舉兩得。",
    sentenceSimplified:
      "北京附近好玩的地方也不少，去京郊 ，既放松了心情 ， 又不会太累 ， 可谓是一举两得。",
    vocabLink: '/chengyu/2/group/4/vocabulary/5',
  },
  {
    traditional: "衣食住行",
    simplified: "衣食住行",
    pinyin: "yi1 shi2 zhu4 xing2",
    literalDefinition: "clothing-food-dweling-transportation",
    englishDefinition: "clothing, food, housing and transport ",
    sentenceTraditional:
      "改革以前 ，大多數農民是處在非常貧困的狀況 ，衣食住行都非常困難 。",
    sentenceSimplified:
      "改革以前 ，大多数农民是处在非常贫困的状况 ，衣食住行都非常困难 。",
    vocabLink: '/chengyu/2/group/4/vocabulary/6',
  },
  {
    traditional: "一視同仁",
    simplified: "一视同仁",
    pinyin: "yi1 shi4 tong2 ren2",
    literalDefinition: "one-look-same-people",
    englishDefinition: "to treat everyone equally favorably",
    sentenceTraditional:
      "政府經辦的學校必須做到對所有公民一視同仁，不分民族、宗教背景、性別、或是否有殘疾。",
    sentenceSimplified:
      "政府经办的学校必须做到对所有公民一视同仁，不分民族、宗教背景、性别、或是否有残疾。",
    vocabLink: '/chengyu/2/group/4/vocabulary/7',
  },
  {
    traditional: "因材施教",
    simplified: "因材施教",
    pinyin: "yin1 cai2 shi1 jiao4",
    literalDefinition: "according to-quality-give-teaching",
    englishDefinition: "to teach in line with the student's ability",
    sentenceTraditional: "教師應因材施教 , 不應千篇一律 。",
    sentenceSimplified: "教师应因材施教 , 不应千篇一律 。",
    vocabLink: '/chengyu/2/group/4/vocabulary/8',
  },
  {
    traditional: "有利可圖",
    simplified: "有利可图",
    pinyin: "you3 li4 ke3 tu2",
    literalDefinition: "have-profit-able-desire",
    englishDefinition: "profitable",
    sentenceTraditional:
      "因爲有利可圖才與你結爲朋友的人，也會因爲有利可圖而與你絕交。",
    sentenceSimplified:
      "因为有利可图才与你结为朋友的人，也会因为有利可图而与你绝交。",
    vocabLink: '/chengyu/2/group/4/vocabulary/9',
  },
  {
    traditional: "振振有詞",
    simplified: "振振有词",
    pinyin: "zhen4 zhen4 you3 ci2",
    literalDefinition: "forceful-have-words",
    englishDefinition: "to speak forcefully and with justice ",
    sentenceTraditional:
      "最近一段時間，司機到站竟然不停，還振振有詞說，現在交通局不讓隨便停車。",
    sentenceSimplified:
      "最近一段时间，司机到站竟然不停，还振振有词说，现在交通局不让随便停车。",
    vocabLink: '/chengyu/2/group/5/vocabulary/0',
  },
  {
    traditional: "爭先恐後",
    simplified: "争先恐后",
    pinyin: "zheng1 xian1 kong3 hou4",
    literalDefinition: "strive-first-fear-behind",
    englishDefinition: "striving to be first and fearing to be last",
    sentenceTraditional: "食物短缺的謠言使人們爭先恐後地購買食物。",
    sentenceSimplified: "食物短缺的谣言使人们争先恐后地购买食物。",
    vocabLink: '/chengyu/2/group/5/vocabulary/1',
  },
  {
    traditional: "知法犯法",
    simplified: "知法犯法",
    pinyin: "zhi1 fa3 fan4 fa3",
    literalDefinition: "know-law-violate-law",
    englishDefinition: "to know the law and break it ",
    sentenceTraditional: "公務員知法犯法，應該要加重處罰。",
    sentenceSimplified: "公务员知法犯法，应该要加重处罚。",
    vocabLink: '/chengyu/2/group/5/vocabulary/2',
  },
  {
    traditional: "知識淵博",
    simplified: "知识渊博",
    pinyin: "zhi1 shi2 yuan1 bo2",
    literalDefinition: "knowledge-profound",
    englishDefinition: "knowledgeable",
    sentenceTraditional: "要做一個知識淵博的人，就必須博覽羣書。",
    sentenceSimplified: "要做一个知识渊博的人，就必须博览群书。",
    vocabLink: '/chengyu/2/group/5/vocabulary/3',
  },
  {
    traditional: "指鹿爲馬",
    simplified: "指鹿为马",
    pinyin: "zhi3 lu4 wei2 ma3",
    literalDefinition: "point-deer-to be-horse",
    englishDefinition: "making a deer out to be a horse",
    sentenceTraditional:
      "社會上多的是顛倒黑白，指鹿爲馬的人，媒體報導不可不慎。",
    sentenceSimplified:
      "社会上多的是颠倒黑白，指鹿为马的人，媒体报导不可不慎。",
    vocabLink: '/chengyu/2/group/5/vocabulary/4',
  },
  {
    traditional: "衆所周知",
    simplified: "众所周知",
    pinyin: "zhong4 suo3 zhou1 zhi1",
    literalDefinition: "mass-all-know",
    englishDefinition: "as everyone knows ",
    sentenceTraditional: "衆所周知，眼睛號稱”心靈的窗戶“，我們理應保護它才是。",
    sentenceSimplified: "众所周知，眼睛号称”心灵的窗户“，我们理应保护它才是。",
    vocabLink: '/chengyu/2/group/5/vocabulary/5',
  },
  {
    traditional: "自食其力",
    simplified: "自食其力",
    pinyin: "zi4 shi2 qi2 li4",
    literalDefinition: "self-eat-its-strength",
    englishDefinition: "to stand on one's own feet ",
    sentenceTraditional:
      "爲了減輕家裏的負擔，他自食其力，半工半讀完成大學學業。",
    sentenceSimplified:
      "为了减轻家里的负担，他自食其力，半工半读完成大学学业。",
    vocabLink: '/chengyu/2/group/5/vocabulary/6',
  },
  {
    traditional: "自相矛盾",
    simplified: "自相矛盾",
    pinyin: "zi4 xiang1 mao2 dun4",
    literalDefinition: "self-mutual-contradict",
    englishDefinition:
      "to contradict oneself / self-contradictory / inconsistent",
    sentenceTraditional: "那律師分析證詞 ，以顯示證人們自相矛盾之處 。",
    sentenceSimplified: "那律师分析证词 ，以显示证人们自相矛盾之处 。",
    vocabLink: '/chengyu/2/group/5/vocabulary/7',
  },
  {
    traditional: "尊師重道",
    simplified: "尊师重道",
    pinyin: "zun1 shi1 zhong4 dao4",
    literalDefinition: "respect-teacher-value-moral",
    englishDefinition: "respect for teachers",
    sentenceTraditional: "尊師重道是中華民族傳統的美德。",
    sentenceSimplified: "尊师重道是中华民族传统的美德。",
    vocabLink: '/chengyu/2/group/5/vocabulary/8',
  },
];

export default unsplitChengyu2Data;