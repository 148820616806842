import directions1 from './directions1';
import directions2 from './directions2';
import food from './food';
import travel from './travel';
import shopping from './shopping';

export default {
  // module name -> video number -> task number
  directions1,
  food,
  directions2,
  travel,
  shopping,
};
