const basePath = "https://materials.chineseforall.org"

export default {
  0: {
    0: {
      q: "What is the man trying to do?",
      type: "single",
      choices: [
        {
          text: "購買去中國的機票",
          audio: basePath + "/assets/audio/module5/movie1/activity1/1-1_3.mp3",
          isCorrect: true,
        },
        {
          text: "詢問北京旅遊行程",
          audio: basePath + "/assets/audio/module5/movie1/activity1/1-1_1.mp3",
        },
        {
          text: "更改预定旅遊路线",
          audio: basePath + "/assets/audio/module5/movie1/activity1/1-1_2.mp3",
        },
        {
          text: "規劃公車旅遊",
          audio: basePath + "/assets/audio/module5/movie1/activity1/1-1_0.mp3",
        },
      ],
    },
    1: {
      q: "下列哪些是正確的叙述？（點選所有正確的答案）",
      type: "multipleSound",
      choices: [
        {
          text: "The round trip ticket is $800.",
        },
        {
          text: "The woman is able to offer a student discount.",
        },
        {
          text: "The man is paying for the flight by credit card.",
        },
        {
          text: "The total flight time is 13 hours and 20 minuters.",
        },
        {
          text: "Air China offers non-stop flights.",
        },
        {
          text: "The man wants to spend one month in China.",
        },
        {
          text: "The ticket will be mailed to the student within two weeks.",
        },
        {
          text: "The man wants to leave in October.",
        },
      ],
      answerKey: [true, true, true, true, true, false, false, false],
    },
    2: {
      q:
        "What's the man's telephone number? Type in the blank below. \n (e.g xxx-xxx-xxxx)",
      type: "fill",
      choices: ["718-333-6666"],
    },
    3: {
      q: "Select the matching answers.",
      type: "dropdown",
      choices: [
        {
          question: "From",
          options: ["紐約", "台北", "墨西哥", "東京", "上海", "北京", "廣東"],
        },
        {
          question: "To",
          options: ["紐約", "台北", "墨西哥", "東京", "上海", "北京", "廣東"],
        },
        {
          question: "Departure",
          options: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
          ],
        },
        {
          question: "Return",
          options: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
          ],
        },
        {
          question: "Departure Date",
          options: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
          ],
        },
        {
          question: "Return Date",
          options: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
          ],
        },
      ],
      answers: ["紐約", "北京", "九月", "九月", 1, 10],
    },
    4: {
      q:
        "Match the blanks. Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "行李轉盤",
          textS: "行李转盘",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-5.mp3",
        },
        {
          text: "走道座位",
          textS: "走道座位",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-3.mp3",
        },
        {
          text: "航空公司櫃台",
          textS: "航空公司柜台",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-0.mp3",
        },
        {
          text: "商務艙",
          textS: "商务舱",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-8.mp3",
        },
        {
          text: "護照",
          textS: "护照",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-2.mp3",
        },
        {
          text: "機票",
          textS: "机票",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-7.mp3",
        },
        {
          text: "登機門",
          textS: "登机门",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-6.mp3",
        },
        {
          text: "隨身行李",
          textS: "随身行李",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-9.mp3",
        },
        {
          text: "抵達",
          textS: "抵达",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-4.mp3",
        },
        {
          text: "飛機",
          textS: "飞机",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-1.mp3",
        },
      ],
      answers: [
        "隨身行李",
        "走道座位",
        "商務艙",
        "護照",
        "登機門",
        "航空公司櫃台",
        "行李轉盤",
        "飛機",
        "機票",
        "抵達",
      ],
      images: [
        basePath + "/assets/travel/travel_0_4_1.png",
        basePath + "/assets/travel/travel_0_4_2.png",
        basePath + "/assets/travel/travel_0_4_3.png",
        basePath + "/assets/travel/travel_0_4_4.png",
        basePath + "/assets/travel/travel_0_4_5.png",
        basePath + "/assets/travel/travel_0_4_6.png",
        basePath + "/assets/travel/travel_0_4_7.png",
        basePath + "/assets/travel/travel_0_4_8.png",
        basePath + "/assets/travel/travel_0_4_9.png",
        basePath + "/assets/travel/travel_0_4_10.png",
      ],
    },
    5: {
      q:
        "Match the blanks. Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "機組人員",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_2.mp3",
        },
        {
          text: "空服人員",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_7.mp3",
        },
        {
          text: "頭等艙",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_6.mp3",
        },
        {
          text: "駕駛艙",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_1.mp3",
        },
        {
          text: "國內航線",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_5.mp3",
        },
        {
          text: "經濟艙",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_0.mp3",
        },
        {
          text: "海關",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_3.mp3",
        },
        {
          text: "起飛",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_4.mp3",
        },
        {
          text: "國際航線",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_8.mp3",
        },
        {
          text: "行李",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_9.mp3",
        },
      ],
      answers: [
        "行李",
        "國際航線",
        "空服人員",
        "起飛",
        "機組人員",
        "頭等艙",
        "駕駛艙",
        "海關",
        "經濟艙",
        "國內航線",
      ],
      images: [
        basePath + "/assets/travel/travel_0_5_1.png",
        basePath + "/assets/travel/travel_0_5_2.png",
        basePath + "/assets/travel/travel_0_5_3.png",
        basePath + "/assets/travel/travel_0_5_4.png",
        basePath + "/assets/travel/travel_0_5_5.png",
        basePath + "/assets/travel/travel_0_5_6.png",
        basePath + "/assets/travel/travel_0_5_7.png",
        basePath + "/assets/travel/travel_0_5_8.png",
        basePath + "/assets/travel/travel_0_5_9.png",
        basePath + "/assets/travel/travel_0_5_10.png",
      ],
    },
    6: {
      q: "Re-arrange",
      type: "rearrange",
      choices: [
        {
          translation:
            "The agent is asking if the man has any prederence for airline.",
          text: "有沒有想要坐哪一 家航空公司？",
          key: 1,
        },
        {
          translation: "The customer doesn't have experience before.",
          text: "你有什麼好建議嗎, 因為我第一次搭。",
          key: 2,
        },
        {
          translation: "The clerk is asking when the man would like to leave.",
          text:
            "到北京, 目前的話, 中國民航是 non-stop. 他每天都有飛，你大概是幾號要出發？",
          textS:
            "到北京, 目前的话, 中国民航是 non-stop. 他每天都有飞，你大概是幾号要出发？",
          key: 3,
        },
        {
          translation: "The customer is answer when he will leave.",
          text: "我大概是九月一號要出發。",
          key: 4,
        },
        {
          translation: "The clerk is asking when is the man come back.",
          text: "九月一號，好，什麼時候回來？",
          key: 5,
        },
        {
          translation: "The customer is answer the date he will back.",
          text: "九月十號。",
          key: 6,
        },
        {
          translation:
            "The clerk is explaining the round trip schedule and telling the man price of fight ticket.",
          text:
            "目前九月一號出發的話, 是下午四點五十，到北京的時間是二號的下午六點十分......",
          key: 7,
        },
        {
          translation: "The  customer is asking for a discount.",
          text: "八百塊? 可以再便宜一點嗎？因為我是學生。",
          key: 8,
        },
        {
          translation: "The clerk agree to give discount.",
          text: "好，我們少十塊錢，七百九好不好？",
          key: 9,
        },
        {
          translation: "The customer thanks the agent.",
          text: "喔，好，謝謝。",
          key: 10,
        },
        {
          translation: "The clerk is asking how would the man likes to pay.",
          text: "請問你用什麼付款？",
          key: 11,
        },
        {
          translation: "The customer likes to pay by credit card.",
          text: "我用信用卡。",
          key: 12,
        },
        {
          translation: "The clerk accept customer pay by credit card.",
          text: "好，我們收Visa, Master, 跟American Express",
          key: 13,
        },
        {
          translation: "The customer thanks agent.",
          text: "好，謝謝你。",
          key: 14,
        },
      ],
    },
    7: {
      q:
        "Match the blanks. Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "登機門",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-6.mp3",
        },
        {
          text: "航空公司櫃台",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-0.mp3",
        },
        {
          text: "走道座位",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-3.mp3",
        },
        {
          text: "護照",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-2.mp3",
        },
        {
          text: "行李轉盤",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-5.mp3",
        },
        {
          text: "隨身行李",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-9.mp3",
        },
        {
          text: "飛機",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-1.mp3",
        },
        {
          text: "抵達",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-4.mp3",
        },
        {
          text: "機票",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-7.mp3",
        },
        {
          text: "商務艙",
          audio: basePath + "/assets/audio/module5/movie1/activity2/2-8.mp3",
        },
      ],
      answers: [
        "登機門",
        "航空公司櫃台",
        "走道座位",
        "護照",
        "行李轉盤",
        "隨身行李",
        "飛機",
        "抵達",
        "機票",
        "商務艙",
      ],
      images: [
        basePath + "/assets/travel/travel_0_4_1.png",
        basePath + "/assets/travel/travel_0_4_2.png",
        basePath + "/assets/travel/travel_0_4_3.png",
        basePath + "/assets/travel/travel_0_4_4.png",
        basePath + "/assets/travel/travel_0_4_5.png",
        basePath + "/assets/travel/travel_0_4_6.png",
        basePath + "/assets/travel/travel_0_4_7.png",
        basePath + "/assets/travel/travel_0_4_8.png",
        basePath + "/assets/travel/travel_0_4_9.png",
        basePath + "/assets/travel/travel_0_4_10.png",
      ],
    },
    8: {
      q:
        "Match the blanks. Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "行李",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_9.mp3",
        },
        {
          text: "經濟艙",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_0.mp3",
        },
        {
          text: "空服人員",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_7.mp3",
        },
        {
          text: "駕駛艙",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_1.mp3",
        },
        {
          text: "海關",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_3.mp3",
        },
        {
          text: "頭等艙",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_6.mp3",
        },
        {
          text: "國內航線",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_5.mp3",
        },
        {
          text: "國際航線",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_8.mp3",
        },
        {
          text: "機組人員",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_2.mp3",
        },
        {
          text: "起飛",
          audio: basePath + "/assets/audio/module5/movie1/activity3/3_4.mp3",
        },
      ],
      answers: [
        "行李",
        "經濟艙",
        "空服人員",
        "駕駛艙",
        "海關",
        "頭等艙",
        "國內航線",
        "國際航線",
        "機組人員",
        "起飛",
      ],
      images: [
        basePath + "/assets/travel/travel_0_5_1.png",
        basePath + "/assets/travel/travel_0_5_2.png",
        basePath + "/assets/travel/travel_0_5_3.png",
        basePath + "/assets/travel/travel_0_5_4.png",
        basePath + "/assets/travel/travel_0_5_5.png",
        basePath + "/assets/travel/travel_0_5_6.png",
        basePath + "/assets/travel/travel_0_5_7.png",
        basePath + "/assets/travel/travel_0_5_8.png",
        basePath + "/assets/travel/travel_0_5_9.png",
        basePath + "/assets/travel/travel_0_5_10.png",
      ],
    },
    9: {
      q:
        "Click the icons to hear a short conversation between two people and then identify their name",
      type: "dragradicals",
      audio: basePath + "/assets/audio/module5/movie1/activity7/task7.mp3",
      choices: [
        {
          question: "man's name",
          blanks: 3,
        },
        {
          question: "wife's name",
          blanks: 2,
        },
        {
          question: "children's name",
          blanks: 2,
        },
      ],
      answers: [
        ["李", "大", "同"],
        ["黃", "月"],
        ["李", "歡"],
      ],
      images: [
        "李",
        "大",
        "同",
        "黃",
        "月",
        "李",
        "歡",
        "吳",
        "亮",
        "喜",
        "天",
        "明",
        "木",
        "子",
        "小",
        "日",
      ],
    },
    10: {
      q:
        "Click the icons to hear a short conversation between two people and then identify their name",
      type: "dragradicals",
      audio: basePath + "/assets/audio/module5/movie1/activity7/task7.mp3",
      choices: [
        {
          question: "man's last name ",
          blanks: 1,
        },
        {
          question: "man's last name __ = __ + __",
          blanks: 2,
        },
        {
          question: "wife's last name",
          blanks: 1,
        },
        {
          question: "wife last name's radical is",
          blanks: 1,
        },
        {
          question: "their child's last name",
          blanks: 1,
        },
      ],
      answers: [["李"], ["木", "子"], ["黃"], ["艹"], ["李"]],
      images: [
        "李",
        "木",
        "子",
        "黃",
        "艹",
        "李",
        "吳",
        "日",
        "杍",
        "小",
        "目",
        "喜",
        "歡",
        "天",
        "亮",
      ],
    },
    11: {
      q: "(Phone Booking) Arranging a trip with the help of a travel agent ",
      type: "literacy",
      audio: basePath + "/assets/travel/literacy_sound/paragraph1.mp3",
      path: basePath + "/assets/travel/literacy_sound/",
      choices: [
        {
          Student: "Student: 小姐你好，*我想請問一下**有關於**到北京的行程*？",
          Tooltip: [
            "Wǒ xiǎng qǐngwèn yīxià,I would like to ask",
            "Yǒu guānyú, concerning",
            "Dào .... de xíngchéng, to tour to (place)",
          ],
          English:
            "I would like to know what tour packages you have for Beijing.",
          Pinyin:
            "Xiǎojiě nǐ hǎo,wǒ xiǎng qǐngwèn yīxià yǒu guānyú dào běijīng de xíngchéng",
        },
        {
          Agent: "Agent: 在北京的話，你有幾天的行程？",
          Tooltip: [],
          English: "Beijing, for how many days?",
          Pinyin: "Zài běijīng dehuà, nǐ yǒu jǐ tiān de xíngchéng?",
        },
        {
          Student: "Student: *大概*一個禮拜*左右*。",
          Tooltip: ["Dàgài,Probably", "zuǒyòu,About"],
          English: "About a week.",
          Pinyin: "Dàgài yīgè lǐbài zuǒyòu",
        },
        {
          Agent:
            "Agent: 一個禮拜，我們有一個行程，是這個，*四天三夜*，貍面*包括**長城*，*故宮*，*頤和園*，跟*天壇*。 那*另外一個*妁話是七天，七天的話是七百八，四天三夜的話是四百八十，這個每天都可以*出發*，那我們住的*旅館*是這家，天倫王朝，是*五星級*的旅館。",
          Tooltip: [
            "Sì tiān sān yè, Four days three nights",
            "bāokuò, Include",
            "chángchéng, The Great Wall",
            "gùgōng, the Forbidden City",
            "yíhéyuán, Summer palace",
            "tiāntán, the Haven Temple",
            "lìngwài yīgè, other",
            "chūfā, to set out on a journey",
            "lǚguǎn, hotel",
            "wǔ xīng jí, five-star class",
          ],
          English:
            "One week. We have one trip for four days three nights. It includes the Great Wall, Forbidden City, Summer Palace, and the Temple of Heaven. Another one is for seven days. $780. If you go for four days three nights, it would be $480. This tour runs every day. The hotel we stay is this one, Tian Lun Wang Chao Hotel. It is a five-star hotel.",
          Pinyin:
            "Yīgè lǐbài, wǒmen yǒu yīgè xíngchéng, shì zhège, sì tiān sān yè , lí miàn bāokuò chángchéng , gùgōng , yíhéyuán , gēn tiāntán . Nà lìngwài yīgè shuò huà shì qītiān, qītiān dehuà shì qībǎi bā, sì tiān sān yè dehuà shì sìbǎi bāshí, zhège měitiān dū kěyǐ chūfā , nà wǒmen zhù de lǚguǎn shì zhè jiā, tiānlún wángcháo, shì wǔ xīng jí de lǚguǎn",
        },
        {
          Student: "Student: 我可以把*資料*拿回去*參考*嗎？",
          Tooltip: [
            "zīliào, reference material (brochure)",
            "cānkǎo, for reference",
          ],
          English: "May I bring take this brochure for reference?",
          Pinyin: "Wǒ kěyǐ bǎ zīliào ná huíqù cānkǎo ma",
        },
        {
          Agent: "Agent: 可以，給你帶回去參考。",
          Tooltip: [],
          English: "Yes, you can take it for reference.",
          Pinyin: "Kěyǐ, gěi nǐ dài huíqù cānkǎo",
        },
        {
          Student: "Student:  謝謝。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xièxiè",
        },
        {
          Agent: "Agent: 那有什麼問題的話，可以打電話給我，好不好，謝謝。",
          Tooltip: [],
          English:
            "If you have any questions, you can call me. OK?  Thank you.",
          Pinyin:
            "Nà yǒu shé me wèntí dehuà, kěyǐ dǎ diànhuà gěi wǒ, hǎobù hǎo, xièxiè.",
        },
        {
          Student: "Student: 謝謝。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "xièxiè",
        },
      ],
    },
  },
  1: {
    0: {
      q: "1. 為什麼那個男人去找那個女人？",
      task: 1,
      type: "single",
      choices: [
        {
          text: "預定旅遊行程",
          isCorrect: true,
        },
        {
          text: "預訂機票",
        },
        {
          text: "預定飯店",
        },
        {
          text: "預定租車",
        },
      ],
    },
    1: {
      q: "2. 這個男人想去哪個城市旅遊？",
      type: "single",
      task: 1,
      choices: [
        {
          text: "北京",
          isCorrect: true,
        },
        {
          text: "上海",
        },
        {
          text: "深圳",
        },
        {
          text: "香港",
        },
      ],
    },
    2: {
      q: "3. 那個男人計畫在北京待多久？",
      type: "single",
      task: 1,
      choices: [
        {
          text: "一個禮拜左右",
          isCorrect: true,
        },
        {
          text: "一個月左右",
        },
        {
          text: "一天左右",
        },
        {
          text: "一年左右",
        },
      ],
    },
    3: {
      q: "4. 那個顧客在考慮哪兩種套裝行程？",
      type: "single",
      task: 1,
      choices: [
        {
          text: "七天六夜",
          isCorrect: true,
        },
        {
          text: "四天三夜",
        },
        {
          text: "五天四夜",
        },
        {
          text: "三天兩夜",
        },
      ],
    },
    4: {
      q: "5. 時間較短的套裝行程多少錢？",
      type: "single",
      task: 1,
      choices: [
        {
          text: "$480",
          isCorrect: true,
        },
        {
          text: "$440",
        },
        {
          text: "$520",
        },
        {
          text: "$580",
        },
      ],
    },
    5: {
      q: "6. 時間較長的套裝行程多少錢？",
      type: "single",
      task: 1,
      choices: [
        {
          text: "$780",
          isCorrect: true,
        },
        {
          text: "$770",
        },
        {
          text: "$807",
        },
        {
          text: "$870",
        },
      ],
    },
    6: {
      q: "7. 根據以下提供的匯率。這些美金為多少等值的人民幣？3278.4 CNY ~= ",
      type: "single",
      task: 1,
      choices: [
        {
          text: "$480",
          isCorrect: true,
        },
        {
          text: "$440",
        },
        {
          text: "$520",
        },
        {
          text: "$580",
        },
      ],
    },
    7: {
      q: "8. 較短的套裝行程包括哪些景點？點選所有可能的答案：",
      type: "multipleSound",
      task: 1,
      choices: [
        {
          text: "長城",
        },
        {
          text: "故宮",
        },
        {
          text: "頤和園",
        },
        {
          text: "天壇",
        },
        {
          text: "三峽大壩",
        },
        {
          text: "石林",
        },
        {
          text: "兵馬俑",
        },
        {
          text: "上海灘",
        },
        {
          text: "天安門廣場",
        },
        {
          text: "胡同",
        },
      ],
      answerKey: [
        true,
        true,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    },
    8: {
      q: "9. 哪些是正確的？點選所有可能的答案：",
      task: 1,
      type: "multipleSound",
      choices: [
        {
          text: "兩種行程都住五星級旅館",
        },
        {
          text: "七天六夜一天的行程比四天三夜一天的行程便宜",
        },
        {
          text: "兩種行程都包括長城，天壇，故宮和頤和園。",
        },
        {
          text: "七天六夜的行程只在星期一出發",
        },
        {
          text: "四天三夜的行程不能住五星級旅館",
        },
        {
          text: "如果今天預定有優惠活動",
        },
      ],
      answerKey: [true, true, true, false, false, false],
    },
    9: {
      q:
        "你需要用航空公司的自動語音服務系統預定機票。撥電話後，你會聽到以下資訊。拖曳號碼到正確的空格。",
      task: 2,
      type: "dragtest",
      help:
        "You need to use the airline's interactive voice system to reschedule your flight. After calling the airline, you hear the following message. Click on the buttons below to hear each option. Drag and drop the correct number into the space below.",
      audio: basePath + "/assets/audio/module5/movie3/activity4/3_4_all.mp3",
      choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, "*", "0", "#"],
      answers: [
        "Check flight status.",
        "Schedule a flight.",
        "Cancel or change a flight.",
        "V.I.P service.",
        "Speak with customer service representative.",
        "Repeat the options.",
      ],
      answerKey: [1, 2, 3, 4, 9, "0"],
      links: {
        1: basePath + "/assets/audio/module5/movie3/activity4/3_4_0.mp3",
        2: basePath + "/assets/audio/module5/movie3/activity4/3_4_1.mp3",
        3: basePath + "/assets/audio/module5/movie3/activity4/3_4_2.mp3",
        4: basePath + "/assets/audio/module5/movie3/activity4/3_4_3.mp3",
        9: basePath + "/assets/audio/module5/movie3/activity4/3_4_4.mp3",
        0: basePath + "/assets/audio/module5/movie3/activity4/3_4_5.mp3",
      },
    },
    10: {
      q:
        "你正在幫你的同學計畫周末的旅遊，他們已經寄電子郵件給你，讓你知道他們想去哪種的旅遊行程。點擊每一個喇叭圖案聽旅遊景點。然後选择正確的旅遊景點。",
      help:
        "You are helping your classmates plan some weekend travel and they have written you emails letting you know what types of activities they would like to do. Drag and drop the most appropriate tourist site to each student's email.",
      task: 3,
      type: "rearrange",
      choices: [
        {
          translation:
            "小王，　我想週末的時候去爬長城。你知道在哪兒可以找到計程車去長城嗎？謝謝。　古迪",
          text: "（北京市）長城",
          audio: basePath + "/assets/audio/module5/movie3/activity5/5_1.mp3",
          key: 1,
        },
        {
          translation:
            "小王，　你好。你上次去三峽玩得怎麼樣？我聽說三峽的風景很美，你能給我介紹一下嗎？有照片的話，寄給我看看吧。　致理",
          text: "（湖北）三峽大壩",
          audio: basePath + "/assets/audio/module5/movie3/activity5/5_5.mp3",
          key: 2,
        },
        {
          translation:
            "小文，　到頤和園怎麼去？頤和園離我們學校遠嗎？我們一起去，好不好？　小王",
          text: "（北京市）頤和園",
          audio: basePath + "/assets/audio/module5/movie3/activity5/5_0.mp3",
          key: 3,
        },
        {
          translation:
            "李老師，　這個周末，幾個同學想在北京參觀天安門廣場。你想跟我們一起去嗎？我的電話是二五一八，五六七九。　你的學生　心好",
          audio: basePath + "/assets/audio/module5/movie3/activity5/5_3.mp3",
          text: "（北京市）天安門廣場",
          key: 4,
        },
        {
          translation:
            "李老師，　這個周末，幾個同學想在北京參觀故宮。你想跟我們一起去嗎？我的電話是二五六三，五一七七。　你的學生　淑華",
          audio: basePath + "/assets/audio/module5/movie3/activity5/5_2.mp3",
          text: "（北京市）故宮",
          key: 5,
        },
        {
          translation:
            "黃老師，　這個周末，幾個同學想在北京參觀天壇。你想跟我們一起去嗎？我的電話是三九六三，四二一七。　你的學生　蘇珊",
          audio: basePath + "/assets/audio/module5/movie3/activity5/5_8.mp3",
          text: "（北京市）天壇",
          key: 6,
        },
        {
          translation:
            "古老師，　這個周末，幾個同學想在北京參觀胡同。你想跟我們一起去嗎？我的電話是三九六三，四二一七。　你的學生　佩玲",
          audio: basePath + "/assets/audio/module5/movie3/activity5/5_7.mp3",
          text: "（北京市）胡同",
          key: 7,
        },
        {
          translation:
            "張老師，　下個星期我們學校放春假，我想去西安參觀兵馬俑。現在訂得到從北京到西安的機票嗎？你可以教我怎麼訂機票嗎？　孔文",
          audio: basePath + "/assets/audio/module5/movie3/activity5/5_6.mp3",
          text: "（西安市）兵馬俑",
          key: 8,
        },
        {
          translation:
            "中國旅行社，您好：　我想去上海玩幾天。要是從北京去上海，坐火車便宜還是坐飛機便宜？外灘附近的飯店，一晚多少錢？謝謝。　李小平",
          audio: basePath + "/assets/audio/module5/movie3/activity5/5_4.mp3",
          text: "（上海）上海外灘",
          key: 9,
        },
      ],
    },
    11: {
      q:
        "你將要到中國旅遊 , 而且需要填寫一份學生簽證的申請表。 遵照下列連結提供的獲得指示 , 用中文完成填寫學生簽證的表格。",
      task: 4,
      type: "link",
      help:
        "You will be traveling to China and need to fill out a student visa application. Follow this link providing instructions for how to obtain a student visa and fill out the application in Chinese. ",
      links:
        "http://chineseforall.org/ch_2009/content/module5/movie2_travelAgency/visa.pdf",
      text: [
        {
          text: "將完成的表格寄給你的老師",
          translation: "Mail the completed application to your instructor.",
        },
      ],
      choices: [],
    },
    12: {
      q: "Travel: Buy Flight Ticket with Travel Agency",
      task: 5,
      type: "literacy",
      audio: basePath + "/assets/travel/literacy_sound/literacy2/travel_video2.mp3",
      choices: [
        {
          Student: "學生: *請問一下*就是*九月初*我要到北京。",
          Tooltip: ["qǐng wèn yí xià, May I ask…", "jiǔ yuè chū, the beginning of September"],
        },
        {
          Agent: "旅行社職員：有沒有想要坐哪一 家*航空公司*",
          Tooltip: ["háng kōng gōng sī, airline company"],
        },
        {
          Student: "學生: 你有什麼好*建議*嗎, 因為我第一次搭。",
          Tooltip: ["jiàn yì, suggestion"],
        },
        {
          Agent: "旅行社職員:  到北京, *目前的話*, *中國民航*是 non-stop. 他 每天都有飛，你*大概*是幾號要*出發*？",
          Tooltip: ["mù qián de huà, at present/ right now", " zhōng guó mín háng, Air China", "dà gài, probably/ approximately", "chū fā, to set out on a journey"],
        },
        {
          Student: "學生: 我大概是九月一號要出發。",
          Tooltip: [],
        },
        {
          Angent: "旅行社職員: 什麼時候回來？",
          Tooltip: [],
        },
        {
          Student: "學生: 九月十號。",
          Tooltip: [],
        },
        {
          Angent: "旅行社職員: 目前九月一號出發的話, 是下午 四點五十，到北京的時間是二號的下午六點十分，* 回程*九月十號， 北京*起飛*一點零五，到紐約是下午兩點二十。 那中間是不停。* 直飛*。 十三個鐘頭二十分鐘。回來是十三個鐘頭十五分鐘。",
          Tooltip: ["huí chéng, returning trip","qǐ fēi, take off (airplane)","zhí fēi, non-stop flight"],
        },
        {
          Student: "學生: 好。",
          Tooltip: [],
        },
        {
          Angent: "旅行社職員: 這張票現在的*票價*是八百塊。(For this ticket, it is now $800.)",
          Tooltip: ["piào jià, airfare"],
        },
        {
          Student: "學生: 八百塊? *可以再便宜一點嗎*？因為我是學生。",
          Tooltip: ["kě yǐ zài pián yí yì diǎn ma, Can you give me a discount?"],
        },
        {
          Angent: "旅行社職員: 我們少十塊錢給你，七百九？ 好不好？",
          Tooltip: [],
        },
        {
          Student: "學生: 好，謝謝。",
          Tooltip: [],
        },
        {
          Angent: "旅行社職員: 可以告訴我名字嗎？姓什麼？",
          Tooltip: [],
        },
        {
          Student: "學生: 王。",
          Tooltip: [],
        },
        {
          Angent: "旅行社職員: 怎麼拼？",
          Tooltip: [],
        },
        {
          Student: "學生: W.A.N.G。",
          Tooltip: [],
        },
        {
          Angent: "旅行社職員: 名字呢？",
          Tooltip: [],
        },
        {
          Student: "學生: 大偉。",
          Tooltip: [],
        },
        {
          Angent: "旅行社職員: 怎麼拼？",
          Tooltip: [],
        },
        {
          Student: "學生: D.A.V.I.D。",
          Tooltip: [],
        },
        {
          Angent: "旅行社職員: d-a-v-i-d 。你的電話？",
          Tooltip: [],
        },
        {
          Student: "學生: 7183336666。",
          Tooltip: [],
        },
        {
          Angent: "旅行社職員: 好，我們現在就*訂好*，九月一號去北京，九月十號回來，那機票的話我們可以給你七百九十塊錢。請問你用什麼*付款*？。",
          Tooltip: ["dìng hǎo, to complete the reservation", "fù kuǎn, to pay for (a bill)"],
        },
        {
          Student: "學生: 我用*信用卡*。",
          Tooltip: ["xìn yòng kǎ, a credit card"],
        },
        {
          Angent: "旅行社職員: 好，我們*收*Visa, Master, 跟American Express。",
          Tooltip: ["shōu, to accept"],
        },
        {
          Angent: "旅行社職員: 那我們就會*幫你**出票*，這張票的話，*一個禮拜之內*就可以*寄給你*。好不好？",
          Tooltip: ["bāng nǐ, to help you","chū piào, issue the ticket","yí ge lǐ bài zhī nèi, within a week","jì gěi nǐ, mail (it) to you"],
        },
        {
          Student: "學生: 好，謝謝。",
          Tooltip: [],
        },
        {
          Angent: "旅行社職員: 謝謝。",
          Tooltip: [],
        },
      ],
    },
    13: {
      q: "(Phone Booking) Arranging a trip with the help of a travel agent ",
      task: 5,
      type: "literacy",
      audio: basePath + "/assets/travel/literacy_sound/literacy2/paragraph.mp3",
      path: basePath + "/assets/travel/literacy_sound/literacy2/",
      choices: [
        {
          Student: "*頤和園**交通*：",
          Tooltip: ["yí hé yuán, summer palace", "jiāo tōng, transportation"],
        },
        {
          Agent: "新建宫門：*公交374，437，704路*；",
          Tooltip: ["gōng jiāo ... lù, public bus (line)"],
        },
        {
          Student: "東宫門：公交726，826，732，331，332，346，718路；",
          Tooltip: [],
        },
        {
          Agent: "北宫門：公交384，375，393，817，808，697路。",
          Tooltip: [],
        },
        {
          Student:
            "頤和園*門票*：20元（淡季），30元（*旺季*），*通票*50元（*淡季*），60元（旺季），*65周歲**及**以上**老年人**憑*本人*老年優待証*遊覽公園*免收門票費*，非京籍70周歲及以上老年人持本人老年優待証享受*半價門票*，*残疾人*免收門票。頤和園4月1日後實行旺季*票價*。",
          Tooltip: [
            "mén piào, admission",
            "wàng jì, hot season",
            "tōng piào, season ticket",
            "dàn jì, off season",
            "liù shí wǔ zhōu suì, 65 years old",
            "jí, and",
            "yǐ shàng, above",
            "lǎo nián rén, seniors",
            "píng, to show ID",
            "lǎo nián yōu dài zhèng, senior card",
            "miǎn shōu mén piào fèi, wavive admission",
            "bàn jià mén piào, admission half price",
            "cán jí rén, disabled people",
            "piào jià, ticket price",
          ],
        },
        {
          Student: "頤和園*電話*：*010-62881133转611或209*.",
          Tooltip: [
            "diàn huà, telephone",
            "zhuǎn, extenstion(number)",
            "huò, or",
          ],
        },
      ],
    },
    14: {
      q: "Match each character with its meaning.",
      task: 6,
      type: "line matching",
      choices: [
        {
          english: "hot season",
          traditional: "旺季",
          simplified: "旺季",
        },
        {
          english: "off season",
          traditional: "淡季",
          simplified: "淡季",
        },
        {
          english: "transportation",
          traditional: "交通",
          simplified: "交通",
        },
        {
          english: "admission",
          traditional: "門票",
          simplified: "门票",
        },
        {
          english: "telephone",
          traditional: "電話",
          simplified: "电话",
        },
        {
          english: "ticket price",
          traditional: "票價",
          simplified: "票价",
        },
        {
          english: "season ticket",
          traditional: "通票",
          simplified: "通票",
        },
        {
          english: "disabled people",
          traditional: "残疾人",
          simplified: "残疾人",
        },
        {
          english: "admission half price",
          traditional: "半價門票",
          simplified: "半价门票",
        },
        {
          english: "waive admission",
          traditional: "免收門票費",
          simplified: "免收门票费",
        },
      ],
    },
    15: {
      q: "Match each character with its meaning.",
      task: 7,
      type: "line matching",
      choices: [
        {
          english: "wàng jì",
          traditional: "旺季",
          simplified: "旺季",
        },
        {
          english: "dàn jì",
          traditional: "淡季",
          simplified: "淡季",
        },
        {
          english: "jiāo tōng",
          traditional: "淡季",
          simplified: "交通",
        },
        {
          english: "mén piào",
          traditional: "門票",
          simplified: "门票",
        },
        {
          english: "diàn huà",
          traditional: "電話",
          simplified: "电话",
        },
        {
          english: "piào jià",
          traditional: "票價",
          simplified: "票价",
        },
        {
          english: "tōng piào",
          traditional: "通票",
          simplified: "通票",
        },
        {
          english: "cán jí rén",
          traditional: "残疾人",
          simplified: "残疾人",
        },
        {
          english: "bàn jià mén piào",
          traditional: "半價門票",
          simplified: "半价门票",
        },
        {
          english: "miǎn shōu mén piào fèi",
          traditional: "免收門票費",
          simplified: "免收门票费",
        },
      ],
    },
    16: {
      q:
        "Study the character's meaning, pinyin, radical, strokes, and compound word.",
      task: 8,
      type: "analysis",
      choices: [
        {
          traditional: "優",
          simplified: "优",
          pinyin: "you1",
          meaning: "good",
          strokesT: "17",
          strokesS: "6",
          radicalT: "人 ren2, person",
          radicalS: "人 ren2, person",
          wordT: "優點 you1dian3, advantage",
          wordS: "优点 you1dian3, advantage",
          etymology:
            "The original meaning of 優 is abundant. The meaning of component 亻rén person. The sound component 憂 yōu.",
          strokeOrderT: basePath + "/assets/gifs/trav_3/travel_traditional_1.gif",
          strokeOrderS: basePath + "/assets/gifs/trav_3/travel_simplified_1.gif",
          audio: basePath + "/assets/travel_literacy_activity3/you1.mp3",
          seal: basePath + "/assets/images/travel_literacy_activity3/seal_1.png",
        },
        {
          traditional: "待",
          simplified: "待",
          pinyin: "dai4",
          meaning: "1. to wait 2. to stay",
          strokesT: "9",
          strokesS: "9",
          radicalT: "彳chi4, small steps",
          radicalS: "彳chi4, small steps",
          wordT: "等待 deng3dai4, to wait",
          wordS: "等待 deng3dai4, to wait",
          etymology:
            "The original meaning of 待  is to wait for someone. The meaning of component 彳chì small steps. The sound component is 寺 sì.",
          strokeOrderT: basePath + "/assets/gifs/trav_3/travel_2.gif",
          strokeOrderS: basePath + "/assets/gifs/trav_3/travel_2.gif",
          audio: basePath + "/assets/travel_literacy_activity3/dai4.mp3",
          seal: basePath + "/assets/images/travel_literacy_activity3/seal_2.png",
        },
        {
          traditional: "免",
          simplified: "免",
          pinyin: "mian3",
          meaning: "to avoid",
          strokesT: "7",
          strokesS: "7",
          radicalT: "儿 ren2, person",
          radicalS: "儿 ren2, person",
          wordT: "免費 mian3fei4, free of charge",
          wordS: "免费 mian3fei4, free of charge",
          etymology: "The meaning of avoid is represented by 免 tù rabbit.",
          strokeOrderT: basePath + "/assets/gifs/trav_3/travel_3.gif",
          strokeOrderS: basePath + "/assets/gifs/trav_3/travel_3.gif",
          audio: basePath + "/assets/travel_literacy_activity3/mian3.mp3",
          seal: basePath + "/assets/images/travel_literacy_activity3/seal_3.png",
        },
        {
          traditional: "收",
          simplified: "收",
          pinyin: "shou1",
          meaning: "to receive",
          strokesT: "6",
          strokesS: "6",
          radicalT: "攵 pu1l, strike",
          radicalS: "攵 pu1l, strike",
          wordT: "收入 shou1ru4, income",
          wordS: "收入 shou1ru4, income",
          etymology:
            "The original meaning of 收 is to make a criminal accept punishment. The meaning component is 攵 pū strike. The sound component is 丩 jiū tie with rope.",
          strokeOrderT: basePath + "/assets/gifs/trav_3/travel_4.gif",
          strokeOrderS: basePath + "/assets/gifs/trav_3/travel_4.gif",
          audio: basePath + "/assets/travel_literacy_activity3/shou1.mp3",
          seal: basePath + "/assets/images/travel_literacy_activity3/seal_4.png",
        },
        {
          traditional: "半",
          simplified: "半",
          pinyin: "ban4",
          meaning: "half",
          strokesT: "5",
          strokesS: "5",
          radicalT: "十 shi2, ten",
          radicalS: "十 shi2, ten",
          wordT: "半夜 ban4ye4, midnight",
          wordS: "半夜 ban4ye4, midnight",
          etymology:
            "The meaning of half is represented by a symbol with a line dividing it into to equal halves.",
          strokeOrderT: basePath + "/assets/gifs/trav_3/travel_5.gif",
          strokeOrderS: basePath + "/assets/gifs/trav_3/travel_5.gif",
          audio: basePath + "/assets/travel_literacy_activity3/ban4.mp3",
          seal: basePath + "/assets/images/travel_literacy_activity3/seal_5.png",
        },
        {
          traditional: "價",
          simplified: "价",
          pinyin: "jia4",
          meaning: "price",
          strokesT: "15",
          strokesS: "6",
          radicalT: "人 ren2, person",
          radicalS: "人 ren2, person",
          wordT: "物價 wu4jia4, commodity price",
          wordS: "物价 wu4jia4, commodity price",
          etymology:
            "The meaning component is 亻rén person. The sound component is 賈 jiǎ.",
          strokeOrderT: basePath + "/assets/gifs/trav_3/travel_traditional_6.gif",
          strokeOrderS: basePath + "/assets/gifs/trav_3/travel_simplified_6.gif",
          audio: basePath + "/assets/travel_literacy_activity3/jia4.mp3",
          seal: basePath + "/assets/images/travel_literacy_activity3/seal_6.png",
        },
        {
          traditional: "殘",
          simplified: "残",
          pinyin: "can2",
          meaning: "to harm; cruel",
          strokesT: "12",
          strokesS: "12",
          radicalT: "歹 dai3, bad",
          radicalS: "歹 dai3, bad",
          wordT: "殘害 can2hai4, cruelly injure or kill",
          wordS: "殘害 can2hai4, cruelly injure or kill",
          etymology:
            "The original meaning of 殘 is to injure. The meaning component is 歹 dǎi. The sound component is 戋 jiān.",
          strokeOrderT: basePath + "/assets/gifs/trav_3/travel_traditional_7.gif",
          strokeOrderS: basePath + "/assets/gifs/trav_3/travel_simplified_7.gif",
          audio: basePath + "/assets/travel_literacy_activity3/can2.mp3",
          seal: basePath + "/assets/images/travel_literacy_activity3/seal_7.png",
        },
      ],
    },
    17: {
      type: "dragRadical",
      task: 9,
      q: "Combine characters to form compound words indicated in Hanyu pinyin.",
      choices: [
        {
          compound: "miǎn shōu",
          first: "免",
          answer: "收",
        },
        {
          compound: "bàn jià",
          first: "半",
          answer: "价",
        },
        {
          compound: "cán jí",
          first: "残",
          answer: "疾",
        },
        {
          compound: "dàn jì",
          first: "淡",
          answer: "季",
        },
        {
          compound: "yōu dài",
          first: "优",
          answer: "待",
        },
      ],
      answers: ["收", "价", "疾", "季", "待"],
    },
    18: {
      task: 10,
      q: "Complete all of the questions.",
      type: "multi-single",
      choices: [
        {
          question: "頤和園的門票有幾種？",
          subChoices: [
            {
              text: "6",
              isCorrect: true,
            },
            {
              text: "4",
            },
            {
              text: "8",
            },
          ],
        },
        {
          question: "誰可以買優待門票？",
          subChoices: [
            {
              text: "非京籍70周岁及以上老人",
              isCorrect: true,
            },
            {
              text: "外国人",
            },
            {
              text: "成年人",
            },
          ],
        },
        {
          question: "誰可以不買門票",
          subChoices: [
            {
              text: "残疾人",
              isCorrect: true,
            },
            {
              text: "外国人",
            },
            {
              text: "成年人",
            },
          ],
        },
        {
          question: "可以坐地鐵還是公車去頤和園？",
          subChoices: [
            {
              text: "公车",
              isCorrect: true,
            },
            {
              text: "地铁",
            },
            {
              text: "都不可以",
            },
          ],
        },
        {
          question: "頤和園的旺季什麼時候開始？",
          subChoices: [
            {
              text: "4月1号",
              isCorrect: true,
            },
            {
              text: "3月1号",
            },
            {
              text: "2月1号",
            },
          ],
        },
        {
          question: "旺季的門票比淡季的門票贵多少？",
          subChoices: [
            {
              text: "10",
              isCorrect: true,
            },
            {
              text: "15",
            },
            {
              text: "20",
            },
          ],
        },
      ],
    },
  },
  2: {
    0: {
      q:
        "Match the blanks. Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "走道座位",
          audio: basePath + "/assets/audio/module5/movie3/activity1/3_1_3.mp3",
        },
        {
          text: "住房",
          audio: basePath + "/assets/audio/module5/movie3/activity1/3_1_2.mp3",
        },
        {
          text: "班機時刻表",
          audio: basePath + "/assets/audio/module5/movie3/activity1/3_1_8.mp3",
        },
        {
          text: "隨身行李",
          audio: basePath + "/assets/audio/module5/movie3/activity1/3_1_9.mp3",
        },
        {
          text: "機票",
          audio: basePath + "/assets/audio/module5/movie3/activity1/3_1_7.mp3",
        },
        {
          text: "雙人房",
          audio: basePath + "/assets/audio/module5/movie3/activity1/3_1_6.mp3",
        },
        {
          text: "宣傳單",
          audio: basePath + "/assets/audio/module5/movie3/activity1/3_1_0.mp3",
        },
        {
          text: "退房",
          audio: basePath + "/assets/audio/module5/movie3/activity1/3_1_4.mp3",
        },
        {
          text: "飛機",
          audio: basePath + "/assets/audio/module5/movie3/activity1/3_1_1.mp3",
        },
        {
          text: "行李轉盤",
          audio: basePath + "/assets/audio/module5/movie3/activity1/3_1_5.mp3",
        },
      ],
      answers: [
        "走道座位",
        "住房",
        "班機時刻表",
        "隨身行李",
        "機票",
        "雙人房",
        "宣傳單",
        "退房",
        "飛機",
        "行李轉盤",
      ],
      images: [
        basePath + "/assets/travel/travel_2_1_3.png",
        basePath + "/assets/travel/travel_2_1_5.png",
        basePath + "/assets/travel/travel_2_1_9.png",
        basePath + "/assets/travel/travel_2_1_1.png",
        basePath + "/assets/travel/travel_2_1_6.png",
        basePath + "/assets/travel/travel_2_1_4.png",
        basePath + "/assets/travel/travel_2_1_8.png",
        basePath + "/assets/travel/travel_2_1_2.png",
        basePath + "/assets/travel/travel_2_1_7.png",
        basePath + "/assets/travel/travel_2_1_0.png",
      ],
    },
    1: {
      q:
        "Match the blanks. Select a word and click the corresponding picture that matches.",
      type: "grid",
      // 客房服務
      // 飛機
      // 大廳
      // 鑰匙
      // 雙人房
      // 家管
      // 預定
      // 單人房
      // 吸煙房
      // 非吸煙房
      choices: [
        {
          text: "客房服務",
          audio: basePath + "/assets/audio/module5/movie3/activity2/3_2_6.mp3",
        },
        {
          text: "飛機",
          audio: basePath + "/assets/audio/module5/movie3/activity2/3_2_0.mp3",
        },
        {
          text: "大廳",
          audio: basePath + "/assets/audio/module5/movie3/activity2/3_2_3.mp3",
        },
        {
          text: "鑰匙",
          audio: basePath + "/assets/audio/module5/movie3/activity2/3_2_2.mp3",
        },
        {
          text: "雙人房",
          audio: basePath + "/assets/audio/module5/movie3/activity2/3_2_9.mp3",
        },
        {
          text: "家管",
          audio: basePath + "/assets/audio/module5/movie3/activity2/3_2_1.mp3",
        },
        {
          text: "預定",
          audio: basePath + "/assets/audio/module5/movie3/activity2/3_2_5.mp3",
        },
        {
          text: "單人房",
          audio: basePath + "/assets/audio/module5/movie3/activity2/3_2_7.mp3",
        },
        {
          text: "吸煙房",
          audio: basePath + "/assets/audio/module5/movie3/activity2/3_2_8.mp3",
        },
        {
          text: "非吸煙房",
          audio: basePath + "/assets/audio/module5/movie3/activity2/3_2_4.mp3",
        },
      ],
      answers: [
        "客房服務",
        "飛機",
        "大廳",
        "鑰匙",
        "雙人房",
        "家管",
        "預定",
        "單人房",
        "吸煙房",
        "非吸煙房",
      ],
      images: [
        basePath + "/assets/travel/travel_2_2_5.png",
        basePath + "/assets/travel/travel_2_2_6.png",
        basePath + "/assets/travel/travel_2_2_8.png",
        basePath + "/assets/travel/travel_2_2_9.png",
        basePath + "/assets/travel/travel_2_2_2.png",
        basePath + "/assets/travel/travel_2_2_7.png",
        basePath + "/assets/travel/travel_2_2_3.png",
        basePath + "/assets/travel/travel_2_2_1.png",
        basePath + "/assets/travel/travel_2_2_0.png",
        basePath + "/assets/travel/travel_2_2_4.png",
      ],
    },
    2: {
      q:
        "Listen to the audio below and select the words that DO have to do with checking into a hotel.",
      altQ: ["根據以下中文和語音，選擇在入住房間需要用到的詞彙。"],
      type: "multipleSound",
      task: 3,
      section: 1,
      choices: [
        {
          text: "雙人床",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_8.mp3",
        },
        {
          text: "單人床",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_20.mp3",
        },
        {
          text: "特大床",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_7.mp3",
        },
        {
          text: "隨身行李",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_12.mp3",
        },
        {
          text: "預定／預約",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_9.mp3",
        },
        {
          text: "海關",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_23.mp3",
        },
        {
          text: "窗戶座位",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_21.mp3",
        },
        {
          text: "航班資訊",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_17.mp3",
        },
      ],
      altChoices: [
        {
          text: "double bed",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_8.mp3",
        },
        {
          text: "single bed",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_20.mp3",
        },
        {
          text: "king bed",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_7.mp3",
        },
        {
          text: "carry-on baggage",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_12.mp3",
        },
        {
          text: "reservation",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_9.mp3",
        },
        {
          text: "customs",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_23.mp3",
        },
        {
          text: "window seat",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_21.mp3",
        },
        {
          text: "flight information",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_17.mp3",
        },
      ],
      answerKey: [true, true, true, true, true, false, false, false],
    },

    3: {
      q:
        "Listen to the audio below and select the words that DO have to do with checking into a hotel.",
      altQ: ["根據以下中文和語音，選擇在入住房間需要用到的詞彙。"],
      type: "multipleSound",
      task: 3,
      section: 2,
      mainAudio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
      choices: [
        {
          text: "住房",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_0.mp3",
        },
        {
          text: "單人房",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_3.mp3",
        },
        {
          text: "雙人房",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_6.mp3",
        },
        {
          text: "非吸煙房",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_19.mp3",
        },
        {
          text: "吸煙房",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_18.mp3",
        },
        {
          text: "走道座位",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_5.mp3",
        },
        {
          text: "飛行員",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_10.mp3",
        },
        {
          text: "飛機",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_14.mp3",
        },
      ],
       answerKey: [true, true, true, true, true, false, false, false],
    },
    4: {
      q:
        "Listen to the audio below and select the words that DO have to do with checking into a hotel.",
      altQ: ["根據以下中文和語音，選擇在入住房間需要用到的詞彙。"],
      type: "multipleSound",
      task: 3,
      section: 3,
      mainAudio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
      choices: [
        {
          text: "鑰匙",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_11.mp3",
        },
        {
          text: "退房",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_2.mp3",
        },
        {
          text: "客房服務",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_16.mp3",
        },
        {
          text: "大廳",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_15.mp3",
        },
        {
          text: "空服員　",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_13.mp3",
        },
        {
          text: "行李轉盤",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_1.mp3",
        },
        {
          text: "登機門",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_22.mp3",
        },
        {
          text: "租車",
          audio: basePath + "/assets/audio/module5/movie3/activity3/3_4.mp3",
        },
      ],
      answerKey: [true, true, true, true, true, false, false, false],
    },

    5: {
      q: "What is the hotel name?",
      altQ: ["這家旅館叫什麼名字？"],
      type: "single",
      task: 4,
      choices: [
        {
          text: "發達盛酒店",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
          isCorrect: true,
        },
        {
          text: "發達盛旅社",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-3.mp3",
        },
        {
          text: "法拉盛大飯店",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-3.mp3",
        },
        {
          text: "飛達聖旅館",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-5.mp3",
        },
      ],
    },
    6: {
      q: "這個男人想要做什麼？",
      task: 5,
      type: "single",
      choices: [
        {
          text: "預訂房間",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
          isCorrect: true,
        },
        {
          text: "更改預定時間",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-3.mp3",
        },
        {
          text: "更改預定項目",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-3.mp3",
        },
        {
          text: "取消預定房間",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-5.mp3",
        },
      ],
    },
    7: {
      q: '這個男人預定什麼時候的房間？',
      task: 6,
      type: 'single',
      choices: [
        {
          text: '十月二號到三號',
          audio: '/assets/audio/module5/movie3/activity7/3_0.mp3',
          isCorrect: true,
        },
        {
          text: '十月三號到四號',
          audio: '/assets/audio/module5/movie3/activity7/3_1.mp3',
        },
        {
          text: '十一月二號到三號',
          audio: '/assets/audio/module5/movie3/activity7/3_2.mp3',
        },
        {
          text: '十一月三號到四號',
          audio: '/assets/audio/module5/movie3/activity7/3_3.mp3',
        },
      ],
    },

    8: {
      q: "這個男人想要什麼樣子的房間？點選所有適當的選項：",
      task: 7,
      type: "multipleSound",
      mainAudio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
      choices: [
        {
          text: "雙人房",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
        },
        {
          text: "非吸煙房",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
        },
        {
          text: "單人房",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
        },
        {
          text: "吸煙房",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
        },
        {
          text: "蜜月套房",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
        },
      ],
      answerKey: [true, true, false, false, false],
    },
    9: {
      q: "這個男人的名字是什麼？",
      type: "single",
      task: 8,
      choices: [
        {
          text: "胡長",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
          isCorrect: true,
        },
        {
          text: "胡萇",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-3.mp3",
        },
        {
          text: "湖長",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-3.mp3",
        },
        {
          text: "蝴長",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-5.mp3",
        },
      ],
    },
    10: {
      q: "這個男人的信用卡的有效時間是什麼時候？",
      task: 9,
      type: "single",
      choices: [
        {
          text: "二零零九年十一月",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-1.mp3",
          isCorrect: true,
        },
        {
          text: "二零零九年十月",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-3.mp3",
        },
        {
          text: "二零一零年十一月",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-3.mp3",
        },
        {
          text: "二零一零年十月",
          audio: basePath + "/assets/module2/vendor/m1_vendor_1-5.mp3",
        },
      ],
    },
    11: {
      q: "輸入這個男人的確認號碼。",
      task: 10,
      type: "fill",
      choices: ["10880923"],
    },
    12: {
      q: "點擊左邊的句子和喇叭聽語音。拖曳適當的句子來回應旅館櫃檯小姐的問題。",
      help:
        "Click sentence on left and speaker to hear audio. Drag and drop the sentence.",
      task: 11,
      type: "rearrange",
      choices: [
        {
          translation:
            "The man is greeting, and tell her the date and type of room for reservation.",
          representative: "店員：發達盛酒店，你好。",
          text:
            "客人：小姐，你好，我要訂房。雙人房，兩天，十月二號，十月三號。",
          audioL: basePath + "/assets/audio/module5/movie3/activity8/3-8-0.mp3",
          key: 1,
        },
        {
          translation: "The customer is asking the price.",
          representative: "店員：好的，十月二號，三號。好，沒有問題。",
          audioL: basePath + "/assets/audio/module5/movie3/activity8/3-8-1.mp3",
          text: "客人：請問，這樣總共差不多多少錢？",
          key: 2,
        },
        {
          translation: "The customer agrees to make a reservation.",
          audioL: basePath + "/assets/audio/module5/movie3/activity8/3-8-2.mp3",
          representative: "客人：請問，這樣總共差不多多少錢？",
          text: "客人：嗯。",
          key: 3,
        },
        {
          translation: "The customer is telling his name.",
          representative: "店員：好，請問您的姓名？",
          audioL: basePath + "/assets/audio/module5/movie3/activity8/3-8-3.mp3",
          text:
            "客人：古，月，胡。長短的長。而且，我要，我要非吸煙室，非吸煙房。",
          key: 4,
        },
        {
          translation: "The customer is telling his credit catd number.",
          representative:
            "店員：好，您要的是非吸煙的雙人房一間。我們訂房的話還需要信用卡號，可以留一下您的信用卡號嗎？",
          audioL: basePath + "/assets/audio/module5/movie3/activity8/3-8-4.mp3",
          text: "客人：請先等一下，嗯，540…3…2222…1234…3849。",
          key: 5,
        },
        {
          translation: "The customer is telling his credit card expried date.",
          audioL: basePath + "/assets/audio/module5/movie3/activity8/3-8-5.mp3",
          representative: "客人：嗯，二零零九，十一月。",
          text: "客人：好的，謝謝你。",
          key: 6,
        },
        {
          translation: "The customer is asking to hold on.",
          audioL: basePath + "/assets/audio/module5/movie3/activity8/3-8-6.mp3",
          representative: "店員：好的，麻煩記一下您的確認號碼。",
          text: "客人：請等一下。好，你說。",
          key: 7,
        },
      ],
    },
    13: {
      task: 12,
      q: "點擊左邊的句子和喇叭聽語音。拖曳適當的句子來回應餐廳小姐的問題。",
      help:
        "Click sentence on left and speaker to hear audio. Drag and drop the appropriate responses to the restaurant..",
      type: "rearrange",
      mainAudio: basePath + "/assets/audio/module5/movie3/activity9/3-9.mp3",
      choices: [
        {
          translation:
            "The customer is calling the restaurant that he likes to make a appointment.",
          audioL: basePath + "/assets/audio/module5/movie3/activity9/9_0.mp3",
          representative: "店員：Friday's，您好。",
          text: "客人：小姐你好，我想訂位。",
          key: 1,
        },
        {
          translation: "The customer is telling what date and time.",
          representative: "店員：請問什麼時候？",
          audioL: basePath + "/assets/audio/module5/movie3/activity9/9_2.mp3",
          text: "客人：這個禮拜日晚上七點。",
          key: 2,
        },
        {
          translation: "The customer is telling the number of group people.",
          representative: "店員：請問有多少人？",
          audioL: basePath + "/assets/audio/module5/movie3/activity9/9_4.mp3",
          text: "有十個人。",
          key: 3,
        },
        {
          translation: "The customer is telling his name.",
          representative: "店員：請問先生貴姓？",
          audioL: basePath + "/assets/audio/module5/movie3/activity9/9_6.mp3",
          text: "客人：楊，木易楊。",
          key: 4,
        },
        {
          translation: "The customer is telling his phone number.",
          representative:
            "店員：好的，這樣就可以了。我們只能多預留十分鐘的位子，請不要遲到喔！",
          text: "5703-8597。我想要非吸煙區的位子。",
          audioL: basePath + "/assets/audio/module5/movie3/activity9/9_8.mp3",
          key: 5,
        },
        {
          translation: "The customer thanks to the restaurant represatative.",
          representative: "店員：謝謝。",
          audioL: basePath + "/assets/audio/module5/movie3/activity9/9_12.mp3",
          text: "客人：好的，謝謝你。",
          key: 6,
        },
      ],
    },
    14: {
      q:
        "你現在正需要使用旅館的自動語音服務系統取消預訂。當你打電話到飯店時會聽到以下資訊。點擊號碼聽每一個選項。拖曳號碼到正確的空格。",
      task: 13,
      type: "dragtest",
      help:
        "You need to use a hotel's automated menu to cancel your reservation. After calling the hotel, you hear the following message. Click on the buttons below to hear each option. Drage and drop the correct number into the space below.",
      audio: basePath + "/assets/audio/module5/movie3/activity10/3_10_all.mp3",
      choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, "*", " 0 ", "#"],
      answers: [
        "Make a reservation",
        "Change a reservation",
        "Cancel a reservation",
        "Inquire about rewards points",
        "Speak with customer service representative.",
        "More options",
      ],
      answerKey: [1, 2, 3, 4, 5, 6],
      links: {
        1: basePath + "/assets/audio/module5/movie3/activity10/3_10_0.mp3",
        2: basePath + "/assets/audio/module5/movie3/activity10/3_10_1.mp3",
        3: basePath + "/assets/audio/module5/movie3/activity10/3_10_2.mp3",
        4: basePath + "/assets/audio/module5/movie3/activity10/3_10_3.mp3",
        5: basePath + "/assets/audio/module5/movie3/activity10/3_10_4.mp3",
        6: basePath + "/assets/audio/module5/movie3/activity10/3_10_5.mp3",
      },
    },
    15: {
      q:
        "You need to make a reservation at a hotel, but you call the hotel and get an answering machine.",
      altQ: ["你必須要在一家飯店預訂房間，但是卻是留話機。"],
      task: 14,
      type: "recording",
      choices: [],
    },
    16: {
      q:
        "Leave a voicemail on the hotel's answering machine with your name, the dates of your stay, and what type of room you would like. Make sure to leave your phone number so that the hotel manager can call you back to confirm your reservation. Submit/e-mail your recording to your instructor.",
      altQ: ["留下你的姓名，入住日期，和你想要的房間形式的語音留言於留話機。並且確認也留下你的電話號碼，以便飯店經理可以回撥電話給你確認預約。"],
      type: "recording",
      task: 15,
      choices: [],
    },
  },
  3: {
    0: {
      q: "Why is the man upset?",
      altQ: ["為什麼這個男人不高興？"],
      type: "single",
      choices: [
        {
          text: "房間聞起來有煙味",
          isCorrect: true,
        },
        {
          text: "冷氣壞了",
        },
        {
          text: "房間很熱",
        },
        {
          text: "這不是一間雙人房",
        },
      ],
    },
    1: {
      q: "這個男人原來的房間號碼是？",
      type: "fill",
      choices: ["308"],
    },
    2: {
      q: "這個男人新的房間號碼是？",
      type: "fill",
      choices: ["508"],
    },
    3: {
      q: "這個男人該怎麼做才可以換房間？",
      type: "single",
      choices: [
        {
          text: "把舊的鑰匙拿到前台",
          audio: basePath + "/assets/audio/module5/movie4/activity1/4_3.mp3",
          isCorrect: true,
        },
        {
          text: "付更多錢",
          audio: basePath + "/assets/audio/module5/movie4/activity1/4_0.mp3",
        },
        {
          text: "等到明天",
          audio: basePath + "/assets/audio/module5/movie4/activity1/4_2.mp3",
        },
        {
          text: "客房服務會把鑰匙給那個男人",
          audio: basePath + "/assets/audio/travel5/movie4/activity1/4_1.mp3",
        },
      ],
    },
    4: {
      q: "聽一段對話，選擇正確的答案來回答店員的問題。",
      type: "single",
      mainAudio: basePath + "/assets/audio/module5/movie4/activity2/Q1.mp3",
      choices: [
        {
          text: "小姐你好，可以幫我換一個非吸煙的房間嗎？",
          audio: basePath + "/assets/audio/module5/movie4/activity2/A3.mp3",
          isCorrect: true,
        },
        {
          text: "嗯嗯。",
          audio: basePath + "/assets/audio/module5/movie4/activity2/A1.mp3",
        },
        {
          text: "好的，可以可以。",
          audio: basePath + "/assets/audio/module5/movie4/activity2/A2.mp3",
        },
      ],
    },
    5: {
      q: "聽一段對話，選擇正確的答案來回答店員的問題。",
      type: "single",
      mainAudio: basePath + "/assets/audio/module5/movie4/activity2/Q2.mp3",
      choices: [
        {
          text: "小姐你好，可以幫我換一個非吸煙的房間嗎？",
          audio: basePath + "/assets/audio/module5/movie4/activity2/A3.mp3",
        },
        {
          text: "嗯嗯。",
          audio: basePath + "/assets/audio/module5/movie4/activity2/A1.mp3",
          isCorrect: true,
        },
        {
          text: "好的，可以可以。",
          audio: basePath + "/assets/audio/module5/movie4/activity2/A2.mp3",
        },
      ],
    },
    6: {
      q: "聽一段對話，選擇正確的答案來回答店員的問題。",
      type: "single",
      mainAudio: basePath + "/assets/audio/module5/movie4/activity2/Q3.mp3",
      choices: [
        {
          text: "小姐你好，可以幫我換一個非吸煙的房間嗎？",
          audio: basePath + "/assets/audio/module5/movie4/activity2/A1.mp3",
        },
        {
          text: "嗯嗯。",
          audio: basePath + "/assets/audio/module5/movie4/activity2/A2.mp3",
        },
        {
          text: "好的，可以可以。",
          audio: basePath + "/assets/audio/module5/movie4/activity2/A3.mp3",
          isCorrect: true,
        },
      ],
    },
  },
  4: {
    0: {
      q: '再看一次影片 "Hotel Check in and Out Video" 選出在影片中有出現的詞彙',
      type: "multipleSound",
      choices: [
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_13.mp3",
          text: "確認號碼",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_4.mp3",
          text: "入住",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_5.mp3",
          text: "信用卡",
        },
        {
          audio: basePath + "/assets/audio/module5/video5/activity1/1_22.mp3",
          text: "退房",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_15.mp3",
          text: "鑰匙",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_3.mp3",
          text: "現金",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_11.mp3",
          text: "樓梯",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_9.mp3",
          text: "取消",
        },
      ],
      answerKey: [true, true, true, true, false, false, false, false],
    },
    1: {
      q: '再看一次影片 "Hotel Check in and Out Video" 選出在影片中有出現的詞彙',
      type: "multipleSound",
      choices: [
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_23.mp3",
          text: "簽名",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_17.mp3",
          text: "胡先生",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_10.mp3",
          text: "訂房",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_0.mp3",
          text: "305",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_16.mp3",
          text: "保險箱",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_19.mp3",
          text: "飛機",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_7.mp3",
          text: "會議中心",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_6.mp3",
          text: "健身房",
        },
      ],
      answerKey: [true, true, true, false, false, false, false, false],
    },
    2: {
      q: '再看一次影片 "Hotel Check in and Out Video" 選出在影片中有出現的詞彙',
      type: "multipleSound",
      choices: [
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_21.mp3",
          text: "證件",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_14.mp3",
          text: "5711",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_1.mp3",
          text: "左手邊",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_18.mp3",
          text: "航班資訊",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_12.mp3",
          text: "停車場",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_2.mp3",
          text: "右手邊",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_8.mp3",
          text: "折扣",
        },
        {
          audio: basePath + "/assets/audio/module5/movie5/activity1/1_20.mp3",
          text: "大廳",
        },
      ],
      answerKey: [true, true, true, false, false, false, false, false],
    },
    3: {
      q:
        "You need to make arrangements for your sister and mother to stay at a hotel near you in China. They have some specific requests.\n Your mother wants to make sure there is a workout room. \n You sister would prefer a non-smoking room. \n And they both will need a business center.\n Click on the following links to review some hotel brochures and choose the one that fits all of their needs.",
      type: "single",
      choices: [
        {
          text: "國際飯店",
          audio: basePath + "/assets/audio/module5/movie5/activity2/2-0.mp3",
          isCorrect: true,
          link:
            "http://www.chineseforall.org/ch_2009/image/module5/activity2/parkHotel.jpg",
        },
        {
          text: "四季酒店",
          audio: basePath + "/assets/audio/module5/movie5/activity2/2-1.mp3",
          link:
            "http://www.chineseforall.org/ch_2009/image/module5/activity2/fourSenson.jpg",
        },
        {
          text: "香格里拉大酒店",
          audio: basePath + "/assets/audio/module5/movie5/activity2/2-3.mp3",
          link:
            "http://www.chineseforall.org/ch_2009/image/module5/activity2/shongrila.jpg",
        },
        {
          text: "龍德豐國際大酒店",
          audio: basePath + "/assets/audio/module5/movie5/activity2/2-2.mp3",
          link:
            "http://www.chineseforall.org/ch_2009/image/module5/activity2/longda.jpg",
        },
      ],
    },
    4: {
      q: "什麼時間退房？",
      type: "single",
      help: "When is check-out?",
      choices: [
        {
          text: "中午十二點",
          audio: basePath + "/assets/audio/module5/movie5/activity3/3-1_3.mp3",
          isCorrect: true,
        },
        {
          text: "晚上六點",
          audio: basePath + "/assets/audio/module5/movie5/activity3/3-1_2.mp3",
        },
        {
          text: "早上十點",
          audio: basePath + "/assets/audio/module5/movie5/activity3/3-1_0.mp3",
        },
        {
          text: "下午一點",
          audio: basePath + "/assets/audio/module5/movie5/activity3/3-1_1.mp3",
        },
      ],
    },
    5: {
      q: "What is the man's confirmation number? Type in the blank below.",
      type: "fill",
      choices: ["5711"],
    },
    6: {
      q: "What is the man's room number? Type in the blank below.",
      type: "fill",
      choices: ["205"],
    },
    7: {
      q: "下列哪一個是錯誤的?",
      type: "single",
      choices: [
        {
          text: "這個男人不喜歡這家旅館",
          audio: basePath + "/assets/audio/module5/movie5/activity3/3-4_0.mp3",
          isCorrect: true,
        },
        {
          text: "這個男人姓胡",
          audio: basePath + "/assets/audio/module5/movie5/activity3/3-4_1.mp3",
        },
        {
          text: "這個男人有先預訂房間",
          audio: basePath + "/assets/audio/module5/movie5/activity3/3-4_3.mp3",
        },
        {
          text: "二O五房在二樓",
          audio: basePath + "/assets/audio/module5/movie5/activity3/3-4_2.mp3",
        },
      ],
    },
  },
};
