import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CorrectIcon from "../icons/CorrectIcon";
import IncorrectIcon from "../icons/IncorrectIcon";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: space-around;
  justify-content: center;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.35rem;
  margin-left: 0;
  margin-right: 0;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 12px;
`;

const StyledImage = styled.img`
  @media (orientation: landscape) {
    height: 200;
    width: 30vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
  margin: 5;
`;

const StyledInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 2em;
`;

const StyledSubInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.45em;
  font-family: times new roman;
`;

const StyledSubQuestion = styled.div`
  font-size: 1.30em;
  font-weight 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

function FillBlankLearning({
  question,
  choices,
  nextLink,
  simplified,
  mainimg,
}) {
  const [status, setStatus] = useState("Check");
  const [toggleImage, setToggleImage] = useState(false);
  const [toggleWords, setToggleWords] = useState(false);
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");

  const handleStatus = () => {
    switch (status) {
      case "Check":
        if (answer1 === "475" && answer2 === "48" && answer3 === "523") {
          setStatus("Next");
        } else {
          setStatus("Retry");
        }
        break;
      case "Retry":
        setAnswer1("");
        setAnswer2("");
        setAnswer3("");
        setStatus("Check");
        break;
      case "Next":
        window.location.href = nextLink;
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <StyledQuestion>{question}</StyledQuestion>

      {mainimg && !toggleImage && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleImage((toggleImage) => !toggleImage)}
        >
          Show Image
        </Button>
      )}
      {mainimg && toggleImage && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleImage((toggleImage) => !toggleImage)}
        >
          Hide Image
        </Button>
      )}
      {mainimg && toggleImage && <StyledImage alt="loading..." src={mainimg} />}

      {!toggleWords && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleWords((toggleWords) => !toggleWords)}
        >
          Show Words
        </Button>
      )}
      {toggleWords && (
        <Button
          style={{ margin: 5 }}
          variant="outlined"
          color="secondary"
          onClick={() => setToggleWords((toggleWords) => !toggleWords)}
        >
          Hide Words
        </Button>
      )}

      {toggleWords
        ? choices.map((item, index) => (
            <div key={index}>
              <StyledInput>
                {simplified ? item.simplified : item.traditional}:{" "}
              </StyledInput>
              <StyledSubInput>Pinyin: {item.pinyin}</StyledSubInput>
              <StyledSubInput>Meaning: {item.word}</StyledSubInput>
            </div>
          ))
        : null}

      <StyledSubQuestion>
        1. How much did the customer spend on food?
      </StyledSubQuestion>
      <StyledContainer>
        {status !== "Check" ? (
          <IconContainer>
            {answer1 === "475" ? <CorrectIcon /> : <IncorrectIcon />}
          </IconContainer>
        ) : (
          <></>
        )}

        <TextField
          variant="outlined"
          label="Answer"
          onChange={(e) => setAnswer1(e.target.value)}
          value={answer1}
        />
      </StyledContainer>
      <StyledSubQuestion>
        2. How much service charge was there?
      </StyledSubQuestion>
      <StyledContainer>
        {status !== "Check" ? (
          <IconContainer>
            {answer2 === "48" ? <CorrectIcon /> : <IncorrectIcon />}
          </IconContainer>
        ) : (
          <></>
        )}

        <TextField
          variant="outlined"
          label="Answer"
          onChange={(e) => setAnswer2(e.target.value)}
          value={answer2}
        />
      </StyledContainer>
      <StyledSubQuestion>3. How much did the customer pay?</StyledSubQuestion>
      <StyledContainer>
        {status !== "Check" ? (
          <IconContainer>
            {answer3 === "523" ? <CorrectIcon /> : <IncorrectIcon />}
          </IconContainer>
        ) : (
          <></>
        )}

        <TextField
          variant="outlined"
          label="Answer"
          onChange={(e) => setAnswer3(e.target.value)}
          value={answer3}
        />
      </StyledContainer>

      <Button
        style={{ margin: 5, marginTop: 20 }}
        variant="contained"
        color="secondary"
        onClick={() => handleStatus()}
      >
        {status}
      </Button>
    </React.Fragment>
  );
}

export default FillBlankLearning;
