const basePath = "https://materials.chineseforall.org";

export default {
  0: {
    0: {
      q: "What does the person want to buy? Choose all possible answers.",
      type: "multipleSound",
      choices: [
        {
          text: "方便面",
        },
        {
          text: "洗发水",
        },
        {
          text: "水",
        },
        {
          text: "茶",
        },
        {
          text: "防曬乳",
        },
      ],
      answerKey: [true, true, false, false, false],
    },
    1: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question: "Read the question below and choose the right answer.",
          subChoices: [
            {
              text: "店員：就在這裡",
              isCorrect: true,
            },
            {
              text: "店員：我們沒有賣",
            },
          ],
        },
        {
          question: "Read the question below and choose the right answer.",
          subChoices: [
            {
              text: "店員：就在這裡",
            },
            {
              text: "店員：在最後面",
              isCorrect: true,
            },
          ],
        },
      ],
      taskSpecificParams: {
        subQuestions: [
          "1. 学生：先生，不好意思，请问方便面在哪儿?",
          "2. 学生：那我知道了，不好意思，再请问一下洗发水在哪儿？",
        ],
      },
    },
    2: {
      q: "Match each word with its meaning.",
      type: "line matching",
      choices: [
        {
          english: "beer",
          simplified: "啤酒",
          traditional: "啤酒",
        },
        {
          english: "thermos",
          simplified: "保温杯",
          traditional: "保溫杯",
        },
        {
          english: "tea",
          simplified: "茶",
          traditional: "茶",
        },
        {
          english: "tableware",
          simplified: "餐具",
          traditional: "餐具",
        },
        {
          english: "cookie",
          simplified: "饼干",
          traditional: "餅乾",
        },
        {
          english: "shampoo",
          simplified: "洗发水",
          traditional: "洗髮水",
        },
        {
          english: "coffee",
          simplified: "咖啡",
          traditional: "咖啡",
        },
        {
          english: "water",
          simplified: "水",
          traditional: "水",
        },
        {
          english: "sun block",
          simplified: "防晒乳",
          traditional: "防曬乳",
        },
        {
          english: "instant noodles",
          simplified: "方便面",
          traditional: "方便麵",
        },
      ],
    },
    3: {
      q: "Watch the video and choose the correct amount for each item on the receipt.",
      type: "grocery shopping",
      mainAudio: {
        src: basePath + "/assets/videos/module4/grocery/grocery.mp4",
        captionSrc: basePath + "/assets/videos/module4/grocery/_s.vtt",
      },
      choices: [
        {
          audioSrc:
            basePath + "/assets/audio/module4/groceryShopping/4/4_0.mp3",
          price: "$10.98",
          simplified: "洗发水多少钱?",
          traditional: "洗髮水多少錢?",
        },
        {
          audioSrc:
            basePath + "/assets/audio/module4/groceryShopping/4/4_1.mp3",
          price: "$1.36",
          simplified: "两包方便面多少钱?",
          traditional: "兩包方便麵多少錢?",
        },
        {
          tax: "税",
          price: "$0.97",
        },
        {
          audioSrc:
            basePath + "/assets/audio/module4/groceryShopping/4/4_2.mp3",
          price: "$13.31",
          simplified: "总共多少钱?",
          traditional: "總共多少錢?",
        },
        {
          audioSrc:
            basePath + "/assets/audio/module4/groceryShopping/4/4_3.mp3",
          price: "$6.69",
          simplified: "找多少钱?",
          traditional: "找多少錢?",
        },
      ],
      answerKey: ["$10.98", "$1.36", "", "$13.31", "$6.69"],
    },
    4: {
      q: "Listen to the audio and then select all the merchandise the customer inquired about.",
      type: "multipleSound",
      mainAudio: basePath + "/assets/audio/module4/groceryShopping/5/5_all.mp3",
      choices: [
        {
          text: "洗髮水",
        },
        {
          text: "牛奶",
        },
        {
          text: "茶",
        },
        {
          text: "礦泉水",
        },
        {
          text: "可樂",
        },
        {
          text: "香水",
        },
        {
          text: "糖果",
        },
        {
          text: "水果",
        },
      ],
      answerKey: [true, true, true, true, true, false, false, false],
    },
    5: {
      q: "",
      type: "multi-single",
      img: basePath + "/assets/images/groceryShopping/grocery_6.png",
      choices: [
        {
          question:
            "1. Listen to the audio and choose where the shampoo is located.",
          mainAudio:
            basePath +
            "/assets/audio/module4/groceryShopping/6/6_item_location.mp3",
          subChoices: [
            {
              text: "Aisle 1",
            },
            {
              text: "Aisle 3",
            },
            {
              text: "Aisle 5",
            },
            {
              text: "Aisle 6 - Top Left",
              isCorrect: true,
            },
            {
              text: "Aisle 6 - Middle Right",
            },
          ],
        },
        {
          question:
            "2. Listen to the audio and choose where the tea is located.",
          mainAudio:
            basePath +
            "/assets/audio/module4/groceryShopping/6/6_item_location.mp3",
          subChoices: [
            {
              text: "Aisle 1",
            },
            {
              text: "Aisle 3",
            },
            {
              text: "Aisle 5",
            },
            {
              text: "Aisle 6 - Top Left",
            },
            {
              text: "Aisle 6 - Middle Right",
              isCorrect: true,
            },
          ],
        },
        {
          question:
            "3. Listen to the audio and choose where the milk is located.",
          mainAudio:
            basePath +
            "/assets/audio/module4/groceryShopping/6/6_item_location.mp3",
          subChoices: [
            {
              text: "Aisle 1",
            },
            {
              text: "Aisle 3",
            },
            {
              text: "Aisle 5",
              isCorrect: true,
            },
            {
              text: "Aisle 6 - Top Left",
            },
            {
              text: "Aisle 6 - Middle Right",
            },
          ],
        },
        {
          question:
            "4. Listen to the audio and choose where the water is located.",
          mainAudio:
            basePath +
            "/assets/audio/module4/groceryShopping/6/6_item_location.mp3",
          subChoices: [
            {
              text: "Aisle 1",
            },
            {
              text: "Aisle 3",
              isCorrect: true,
            },
            {
              text: "Aisle 5",
            },
            {
              text: "Aisle 6 - Top Left",
            },
            {
              text: "Aisle 6 - Middle Right",
            },
          ],
        },
        {
          question:
            "5. Listen to the audio and choose where the coke is located.",
          mainAudio:
            basePath +
            "/assets/audio/module4/groceryShopping/6/6_item_location.mp3",
          subChoices: [
            {
              text: "Aisle 1",
              isCorrect: true,
            },
            {
              text: "Aisle 3",
            },
            {
              text: "Aisle 5",
            },
            {
              text: "Aisle 6 - Top Left",
            },
            {
              text: "Aisle 6 - Middle Right",
            },
          ],
        },
      ],
    },
    6: {
      q: "Listen to the conversation and drag the correct product to the dialogue box.",
      type: "dialogue",
      mainAudio:
        basePath +
        "/assets/audio/module4/groceryShopping/6/6_item_location.mp3",
      choices: [
        {
          text: "Client: 不好意思，先生，請問1_在哪兒？",
        },
        {
          text: "Clerk: 哦，在最後面。",
        },
        {
          text: "Client: 那2_和3_在哪兒？",
        },
        {
          text: "Clerk: 哦，在五號走道，在洗髮水的對面。",
        },
        {
          text: "Client: 那4_在哪兒？",
        },
        {
          text: "Clerk: 在三號走道。",
        },
        {
          text: "Client: 最後請問5_放在哪兒？",
        },
        {
          text: "Clerk: 就在收銀台前面的冰箱。",
        },
        {
          text: "Client: 謝謝。",
        },
      ],
      answers: ["洗髮水", "牛奶", "茶", "礦泉水", "可樂"],
      answersS: ["洗发水", "牛奶", "茶", "矿泉水", "可乐"],
    },
    7: {
      q: "Listen to the conversation and drag the correct position to the dialogue box.",
      type: "dialogue",
      mainAudio:
        basePath +
        "/assets/audio/module4/groceryShopping/6/6_item_location.mp3",
      choices: [
        {
          text: "Client: 不好意思，先生，請問洗髮水在哪兒？",
        },
        {
          text: "Clerk: 哦！洗髮水在最1_。",
        },
        {
          text: "Client: 那牛奶和茶在哪裡？",
        },
        {
          text: "Clerk: 哦，牛奶在2_，茶在洗髮水的3_。",
        },
        {
          text: "Client: 那礦泉水在哪兒？",
        },
        {
          text: "Clerk: 在4_。",
        },
        {
          text: "Client: 最後請問可樂放在哪兒？",
        },
        {
          text: "Clerk: 就在5_6_的7_裡。",
        },
      ],
      answers: [
        "後面",
        "五號走道",
        "對面",
        "三號走道",
        "收銀台",
        "前方",
        "冰箱",
      ],
      answersS: [
        "后面",
        "五号走道",
        "对面",
        "三号走道",
        "收银台",
        "前方",
        "冰箱",
      ],
    },
    8: {
      q: "Select all the polite words used in the video.",
      type: "multipleSound",
      choices: [
        {
          text: "不客氣",
        },
        {
          text: "謝謝你",
        },
        {
          text: "那我知道了",
        },
        {
          text: "請問...在哪兒/在哪裡？",
        },
        {
          text: "不好意思，（...在哪兒/在哪裡）？",
        },
        {
          text: "對不起",
        },
        {
          text: "没關係",
        },
        {
          text: "可以打擾一下嗎？",
        },
      ],
      answerKey: [true, true, true, true, true, false, false, false],
    },
    9: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question:
            "See images of situations, and choose the right time to say the phrases.",
          img: basePath + "/assets/images/groceryShopping/bus_line.png",
          subChoices: [
            {
              text: "不好意思",
              isCorrect: true,
            },
            {
              text: "對不起",
            },
          ],
        },
        {
          question:
            "See images of situations, and choose the right time to say the phrases.",
          img: basePath + "/assets/images/groceryShopping/step_foot.png",
          subChoices: [
            {
              text: "不好意思",
            },
            {
              text: "對不起",
              isCorrect: true,
            },
          ],
        },
        {
          question:
            "See images of situations, and choose the right time to say the phrases.",
          img: basePath + "/assets/images/groceryShopping/front_counter.png",
          subChoices: [
            {
              text: "不好意思",
              isCorrect: true,
            },
            {
              text: "對不起",
            },
          ],
        },
        {
          question:
            "See images of situations, and choose the right time to say the phrases.",
          img: basePath + "/assets/images/groceryShopping/bump.png",
          subChoices: [
            {
              text: "不好意思",
            },
            {
              text: "對不起",
              isCorrect: true,
            },
          ],
        },
      ],
      taskSpecificParams: {
        subQuestions: [
          "1. Someone is standing in front of someone on a bus.",
          "2. Someone is stepping on another person's foot.",
          "3. Someone is in front of a counter.",
          "4. Two people are bumping into one another.",
        ],
      },
    },
    10: {
      q: "Click an underlined word to see the meaning and hear the sound.",
      type: "literacy",
      choices: [
        {
          Customer: "*先生*，*不好意思*，*請問**方便麵*在哪？",
          CustomerS: "*先生*，*不好意思*，*请问**方便面*在哪？",
          Tooltip: [
            "xian1sheng, Mr.",
            "bu4 hao3 yi4 si, excuse me",
            "qǐng wèn, may I ask",
            "fang1 bian4 mian4, instant noodle",
          ],
          English:
            "Hi, sir. Excuse me, could you tell me where the instant noodles are?",
          Pinyin: "Xiānshēng, bù hǎoyìsi, qǐngwèn fāngbiànmiàn zài nǎ?",
        },
        {
          Clerk: "就在這裡。",
          ClerkS: "就在这里。",
          Tooltip: [],
          English: "Right here.",
          Pinyin: "Jiù zài zhèlǐ.",
        },
        {
          Customer: "那我知道了，不好意思，*再*請問一下*洗髮水*在哪？",
          CustomerS: "那我知道了，不好意思，*再*請問一下*洗髮水*在哪？",
          Tooltip: ["zai4, again", "xi3 fa4 shui3, shampoo"],
          English: "Oh, I see. Sorry and where is shampoo?",
          Pinyin:
            "Nà wǒ zhīdàole, bù hǎoyìsi, zài qǐngwèn yīxià xǐ fàshuǐ zài nǎ?",
        },
        {
          Clerk: "在*最後面*。",
          ClerkS: "在*最後面*。",
          Tooltip: ["zuì hòu miàn, at the very end"],
          English: "At the very end.",
          Pinyin: "Zài zuìhòu miàn.",
        },
        {
          Customer: "那我*知道*了，謝謝。",
          CustomerS: "那我*知道*了，谢謝。",
          Tooltip: ["zhi1 dao4, know"],
          English: "I see. Thank you.",
          Pinyin: "Nà wǒ zhīdàole, xièxiè.",
        },
        {
          Clerk: "不客氣。",
          ClerkS: "不客气。",
          Tooltip: [],
          English: "You are welcome.",
          Pinyin: "Bù kèqì.",
        },
        {
          Customer: "你好。",
          CustomerS: "你好。",
          Tooltip: [],
          English: "Hello.",
          Pinyin: "你好。",
        },
        {
          Clerk:
            "洗髮水是十塊九毛八，*然後*，*兩包麵*，是一塊三毛六，*總共*是十三塊三毛一。",
          ClerkS:
            "洗发水是十块九毛八，*然後*，*两包面*，是一块三毛六，*总共*是十三块三毛一。",
          Tooltip: [
            "rán hòu, and then",
            "liǎng bāo miàn, two noodles",
            "zong3 gong4, in total",
          ],
          English:
            "Shampoo is $10.98. Then, two noodles, $1.36. Total is $13.31.",
          Pinyin:
            "Xǐ fàshuǐ shì shí kuài jiǔ máo bā, ránhòu, liǎng bāo miàn, shì yīkuài sān máo liù, zǒnggòng shì shísān kuài sān máo yī.",
        },
        {
          Clerk: "謝謝，二十塊。*找你*，七… 六塊六毛九。",
          ClerkS: "谢谢，二十块。*找你*，七… 六块六毛九。",
          Tooltip: ["zhǎo nǐ, give you change"],
          English: "Thank you. $20. $7..$6.69 is your change.",
          Pinyin: "Xièxiè, èrshí kuài. Zhǎo nǐ, qī… liù kuài liù máo jiǔ.",
        },
        {
          Customer: "謝謝。",
          CustomerS: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "Xièxiè.",
        },
        {
          Clerk: "謝謝。",
          ClerkS: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "Xièxiè.",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/grocery/s1.mp3",
          basePath + "/assets/sound/grocery/s2.mp3",
          basePath + "/assets/sound/grocery/s3.mp3",
          basePath + "/assets/sound/grocery/s4.mp3",
          basePath + "/assets/sound/grocery/s5.mp3",
          basePath + "/assets/sound/grocery/s6.mp3",
          basePath + "/assets/sound/grocery/s7.mp3",
          basePath + "/assets/sound/grocery/s8.mp3",
          basePath + "/assets/sound/grocery/s9.mp3",
          basePath + "/assets/sound/grocery/s10.mp3",
          basePath + "/assets/sound/grocery/s11.mp3",
          basePath + "/assets/sound/grocery/s12.mp3",
        ],
      },
    },
    11: {
      q: "Match each word with its pinyin.",
      type: "line matching",
      choices: [
        {
          english: "zhī dào",
          traditional: "知道",
          simplified: "知道",
        },
        {
          english: "rán hòu",
          traditional: "然後",
          simplified: "然后",
        },
        {
          english: "fāng biàn",
          traditional: "方便",
          simplified: "方便",
        },
        {
          english: "hòu miàn",
          traditional: "後面",
          simplified: "后面",
        },
        {
          english: "xǐ fǎ",
          traditional: "洗髮",
          simplified: "洗发",
        },
        {
          english: "xiān shēng",
          traditional: "先生",
          simplified: "先生",
        },
        {
          english: "qǐng wèn",
          traditional: "請問",
          simplified: "请问",
        },
        {
          english: "zǒng gòng",
          traditional: "總共",
          simplified: "总共",
        },
      ],
    },
    12: {
      q: "Match each word with its meaning.",
      type: "line matching",
      choices: [
        {
          english: "to know",
          traditional: "知道",
          simplified: "知道",
        },
        {
          english: "and then",
          traditional: "然後",
          simplified: "然后",
        },
        {
          english: "convenient",
          traditional: "方便",
          simplified: "方便",
        },
        {
          english: "behind",
          traditional: "後面",
          simplified: "后面",
        },
        {
          english: "wash hair",
          traditional: "洗髮",
          simplified: "洗发",
        },
        {
          english: "Mr.",
          traditional: "先生",
          simplified: "先生",
        },
        {
          english: "May I ask",
          traditional: "請問",
          simplified: "请问",
        },
        {
          english: "the total (is)",
          traditional: "總共",
          simplified: "总共",
        },
      ],
    },
    13: {
      q: "Study the character's meaning, pinyin, radical, strokes, and compound word.",
      type: "analysis",
      choices: [
        {
          traditional: "意",
          simplified: "意",
          pinyin: "yi4",
          meaning: "idea",
          strokesT: "13",
          strokesS: "13",
          radicalT: "心 xin1, heart",
          radicalS: "心 xin1, heart",
          wordT: "意見 yi4jian4, opinion",
          wordS: "意見 yi4jian4, opinion",
          etymology:
            "The original meaning of 意 is intention. The meaning is represented by 心 xīn heart and 音 yīn sound.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_1.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_1.gif",
          audio: basePath + "/assets/sound/characters/yi4.mp3",
          seal:
            basePath +
            "/assets/images/groceryShopping_literacy_activity_2/seal_1.png",
        },
        {
          traditional: "思",
          simplified: "思",
          pinyin: "si1",
          meaning: "think; thoughts",
          strokesT: "9",
          strokesS: "9",
          radicalT: "心 xin1, heart",
          radicalS: "心 xin1, heart",
          wordT: "思想 si1xiang3, thoughts",
          wordS: "思想 si1xiang3, thoughts",
          etymology:
            "The meaning of thought is represented by a combination of 心 xīn heart and 囟 xìn brain.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_2.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_2.gif",
          audio: basePath + "/assets/sound/characters/si1.mp3",
          seal:
            basePath +
            "/assets/images/groceryShopping_literacy_activity_2/seal_2.png",
        },
        {
          traditional: "便",
          simplified: "便",
          pinyin: "bian4, pian2",
          meaning: "1. convenience 2. inexpensive",
          strokesT: "9",
          strokesS: "9",
          radicalT: "人 ren2, person",
          radicalS: "人 ren2, person",
          wordT: "方便 fang1bian4, convenience",
          wordS: "方便 fang1bian4, convenience",
          etymology:
            "The meaning of convenience is represented by 人 rén person and 更 gèng to change.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_3.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_3.gif",
          audio: basePath + "/assets/sound/characters/bian4.mp3",
          seal:
            basePath +
            "/assets/images/groceryShopping_literacy_activity_2/seal_3.png",
        },
        {
          traditional: "再",
          simplified: "再",
          pinyin: "zai4",
          meaning: "again",
          strokesT: "6",
          strokesS: "6",
          radicalT: "冂 jiong, border",
          radicalS: "冂 jiong, border",
          wordT: "再三 zai4san1, repeatedly",
          wordS: "再三 zai4san1, repeatedly",
          etymology:
            "The original meaning of 收 is to make a criminal accept punishment. The meaning component is 攵 pū strike. The sound component is 丩 jiū tie with rope.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_4.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_4.gif",
          audio: basePath + "/assets/sound/characters/zai4.mp3",
          seal:
            basePath +
            "/assets/images/groceryShopping_literacy_activity_2/seal_4.png",
        },
        {
          traditional: "洗",
          simplified: "洗",
          pinyin: "xi3",
          meaning: "to wash",
          strokesT: "9",
          strokesS: "9",
          radicalT: "水 shui3, water",
          radicalS: "水 shui3, water",
          wordT: "洗相片 xi3xiang4pian4, to develop film",
          wordS: "洗相片 xi3xiang4pian4, to develop film",
          etymology:
            "The original meaning of 洗 is to wash feet. The meaning component is 水 shuǐ water. The sound component is 先 xiān.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_5.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_5.gif",
          audio: basePath + "/assets/sound/characters/xi3.mp3",
          seal:
            basePath +
            "/assets/images/groceryShopping_literacy_activity_2/seal_5.png",
        },
        {
          traditional: "最",
          simplified: "最",
          pinyin: "zui4",
          meaning: "most",
          strokesT: "12",
          strokesS: "12",
          radicalT: "曰 yue1, speak",
          radicalS: "曰 yue1, speak",
          wordT: "最後 zui4hou4, the last",
          wordS: "最後 zui4hou4, the last",
          etymology:
            "The meaning component is 冃 mào brave. The sound component is 取 qǔ.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_6.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_6.gif",
          audio: basePath + "/assets/sound/characters/zui4.mp3",
          seal:
            basePath +
            "/assets/images/groceryShopping_literacy_activity_2/seal_6.png",
        },
        {
          traditional: "道",
          simplified: "道",
          pinyin: "dao4",
          meaning: "way; path; say",
          strokesT: "13",
          strokesS: "13",
          radicalT: "辶 chuo4, movement",
          radicalS: "辶 chuo4, movement",
          wordT: "道理 dao4li3, reason",
          wordS: "道理 dao4li3, reason",
          etymology:
            "The meaning of 道 is represented by 辶 chuò movement and 首 shǒu head.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_7.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_7.gif",
          audio: basePath + "/assets/sound/characters/dao4.mp3",
          seal:
            basePath +
            "/assets/images/groceryShopping_literacy_activity_2/seal_7.png",
        },
        {
          traditional: "然",
          simplified: "然",
          pinyin: "ran2",
          meaning: "so; thus",
          strokesT: "12",
          strokesS: "12",
          radicalT: "火 huo3, fire",
          radicalS: "火 huo3, fire",
          wordT: "然後 ran2hou4, afterwards",
          wordS: "然後 ran2hou4, afterwards",
          etymology:
            "The original meaning of 然 is to burn. The meaning is represented by to roast animal meat (as a sacrifice). Borrowed for use as an adverb.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_8.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_8.gif",
          audio: basePath + "/assets/sound/characters/ran2.mp3",
          seal:
            basePath +
            "/assets/images/groceryShopping_literacy_activity_2/seal_8.png",
        },
        {
          traditional: "總",
          simplified: "总",
          pinyin: "zong3",
          meaning: "total; always",
          strokesT: "17",
          strokesS: "9",
          radicalT: "糸 si1, thread",
          radicalS: "心 xin1, heart",
          wordT: "總統 zong3tong3, president",
          wordS: "总统 zong3tong3, president",
          etymology:
            "The original meaning of 總 is to tie threads together. The meaning component is 糹mì thread. The sound component is 悤 zōng (not a common character).",
          strokeOrderT:
            basePath +
            "/assets/gifs/shopping-grocery_2/grocery_9_traditional.gif",
          strokeOrderS:
            basePath +
            "/assets/gifs/shopping-grocery_2/grocery_9_simplified.gif",
          audio: basePath + "/assets/sound/characters/zong3.mp3",
          seal:
            basePath +
            "/assets/images/groceryShopping_literacy_activity_2/seal_9.png",
        },
        {
          traditional: "共",
          simplified: "共",
          pinyin: "gong4",
          meaning: "together; total",
          strokesT: "6",
          strokesS: "6",
          radicalT: "八 ba1, eight",
          radicalS: "八 ba1, eight",
          wordT: "共同 gong4tong2, together",
          wordS: "共同 gong4tong2, together",
          etymology:
            "The meaning of together is represented by 廿 niàn twenty and 廾 gǒng holding hands.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_10.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_10.gif",
          audio: basePath + "/assets/sound/characters/gong4.mp3",
          seal:
            basePath +
            "/assets/images/groceryShopping_literacy_activity_2/seal_10.png",
        },
        {
          traditional: "找",
          simplified: "找 ",
          pinyin: "zhao3",
          meaning: "to find",
          strokesT: "7",
          strokesS: "7",
          radicalT: "手 shou3, hand",
          radicalS: "手 shou3, hand",
          wordT: "找工作 zhao3gong1zuo4, to look for a job",
          wordS: "找工作 zhao3gong1zuo4, to look for a job",
          etymology:
            "The meaning of to find is represented by 手 shǒu hand and 戈 gē spear.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_11.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-grocery_2/grocery_11.gif",
          audio: basePath + "/assets/sound/characters/zhao3.mp3",
          seal:
            basePath +
            "/assets/images/groceryShopping_literacy_activity_2/seal_11.png",
        },
      ],
    },
    14: {
      q: "Fill in the table by dragging and dropping the answers below into the corresponding boxes.",
      type: "analysis-learning",
      choices: [
        {
          traditional: "意",
          simplified: "意",
          pinyin: "yi4",
          meaning: "idea",
          strokesT: "13",
          strokesS: "13",
          radicalT: "心 xin1, heart",
          radicalS: "心 xin1, heart",
        },
        {
          traditional: "最",
          simplified: "最",
          pinyin: "zui4",
          meaning: "most",
          strokesT: "12",
          strokesS: "12",
          radicalT: "曰 yue1, speak",
          radicalS: "曰 yue1, speak",
        },
        {
          traditional: "便",
          simplified: "便",
          pinyin: "bian4, pian2",
          meaning: "convenience; inexpensive",
          strokesT: "9",
          strokesS: "9",
          radicalT: "人 ren2, person",
          radicalS: "人 ren2, person",
        },
        {
          traditional: "找",
          simplified: "找 ",
          pinyin: "zhao3",
          meaning: "to find",
          strokesT: "7",
          strokesS: "7",
          radicalT: "手 shou3, hand",
          radicalS: "手 shou3, hand",
        },
        {
          traditional: "再",
          simplified: "再",
          pinyin: "zai4",
          meaning: "again",
          strokesT: "6",
          strokesS: "6",
          radicalT: "冂 jiong, border",
          radicalS: "冂 jiong, border",
        },
      ],
    },
    15: {
      q: "Fill in missing components for each character.",
      type: "dragRadical",
      choices: [
        {
          compound: "total",
          first: "總",
          answer: "共",
        },
        {
          compound: "meaning",
          first: "意",
          answer: "思",
        },
        {
          compound: "to know",
          first: "知",
          answer: "道",
        },
        {
          compound: "the last",
          first: "最",
          answer: "後",
        },
        {
          compound: "wash hair",
          first: "洗",
          answer: "髮",
        },
        {
          compound: "convenient",
          first: "方",
          answer: "便",
        },
      ],
      answers: ["共", "思", "道", "後", "髮", "便"],
    },
    16: {
      q: "Rearrange phrases into correct order.",
      type: "arrange-audio-clips",
      choices: [
        {
          id: 0,
          text: "先生",
          textS: "先生",
        },
        {
          id: 1,
          text: "不好意思",
          textS: "不好意思",
        },
        {
          id: 2,
          text: "請問方便面在哪兒",
          textS: "请问方便面在哪儿",
        },
        {
          id: 3,
          text: "洗髮水是十塊九毛八",
          textS: "洗发水是十块九毛八",
        },
        {
          id: 4,
          text: "然後",
          textS: "然后",
        },
        {
          id: 5,
          text: "兩包麵",
          textS: "两包面",
        },
        {
          id: 6,
          text: "是一塊三毛六",
          textS: "是一块三毛六",
        },
        {
          id: 7,
          text: "總共是十三塊三毛一",
          textS: "总共是十三块三毛一",
        },
      ],
      answerKey: [0, 1, 2, 3, 4, 5, 6, 7],
    },
    17: {
      q: "Make a recording to read the store signs you see in the picture. Submit your recording to your teacher.",
      type: "recording",
      img: basePath + "/assets/images/groceryShopping/grocery_16.png",
      choices: [],
    },
    18: {
      q: "The following is a receipt from a restaurant. Study the words first then answer the questions.",
      type: "fill-literacy",
      img: basePath + "/assets/images/groceryShopping/receipt.png",
      choices: [
        {
          word: "subtotal",
          pinyin: "xiǎo jì",
          traditional: "小計",
          simplified: "小计",
        },
        {
          word: "service charge",
          pinyin: "fú wù fei",
          traditional: "服務費",
          simplified: "服务费",
        },
        {
          word: "grand total",
          pinyin: "hé jì",
          traditional: "合計",
          simplified: "合计",
        },
      ],
    },
    19: {
      q: "When you go to a  supermarket, you will see the signs on each isle. Study the words.",
      type: "keywords",
      choices: [
        {
          english: "(kitchen-tool) Kitchen utensiles",
          traditional: "廚具",
          pinyin: "chú jù",
        },
        {
          english: "(mix-flavor-item) sauce",
          traditional: "調味品",
          pinyin: "tiáo wèi pǐn",
        },
        {
          english: "(clean-clean category) cleaning items",
          traditional: "清潔類",
          pinyin: "qīng jié lèi",
        },
        {
          english: "(milk-produce-item-category) dairy product",
          traditional: "奶製品類",
          pinyin: "nǎi zhì pǐn lèi",
        },
        {
          english: "(raw-flesh-area) fresh food",
          traditional: "生鲜區",
          pinyin: "shēng xiān qū",
        },
        {
          english: "(water-category) water",
          traditional: "水類",
          pinyin: "shuǐ lèi",
        },
        {
          english: "(crop-oil-area) rice, flour, and oil",
          traditional: "糧油區",
          pinyin: "liáng yóu qū",
        },
        {
          english: "(wine-category) liquor",
          traditional: "酒類",
          pinyin: "jiǔ lèi",
        },
        {
          english: "(drink-material-category) beverages",
          traditional: "飲料類",
          pinyin: "yǐn liào lèi",
        },
        {
          english: "(eat-item-category) food",
          traditional: "食品類",
          pinyin: "shí pǐn lèi",
        },
        {
          english: "(rest-leisure-eat-item) snacks",
          traditional: "休閒食品",
          pinyin: "xiū xián shí pǐn",
        },
      ],
    },
    20: {
      q: "Choose the meaning for the following characters.",
      type: "line matching",
      choices: [
        {
          english: "material",
          traditional: "料",
          simplified: "料",
        },
        {
          english: "item",
          traditional: "品",
          simplified: "品",
        },
        {
          english: "tool",
          traditional: "具",
          simplified: "具",
        },
        {
          english: "category",
          traditional: "類",
          simplified: "类",
        },
        {
          english: "area",
          traditional: "區",
          simplified: "区",
        },
      ],
    },
    21: {
      q: "Choose the meaning for the following characters.",
      type: "line matching",
      choices: [
        {
          english: "beverages",
          traditional: "飲料",
          simplified: "饮料",
        },
        {
          english: "sauce",
          traditional: "調味料",
          simplified: "调味料",
        },
        {
          english: "food",
          traditional: "食品",
          simplified: "食品",
        },
        {
          english: "oil",
          traditional: "食油",
          simplified: "食油",
        },
      ],
    },
    22: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question:
            "Study the label of a Chinese instant noodle, then answer the following questions.",
          img: basePath + "/assets/images/groceryShopping/noodle.png",
          subChoices: [
            {
              text: "方便麵",
              isCorrect: true,
            },
            {
              text: "飲料",
            },
          ],
        },
        {
          question:
            "Study the label of a Chinese instant noodle, then answer the following questions.",
          img: basePath + "/assets/images/groceryShopping/noodle.png",
          subChoices: [
            {
              text: "康師傅",
              isCorrect: true,
            },
            {
              text: "增量",
            },
          ],
        },
        {
          question:
            "Study the label of a Chinese instant noodle, then answer the following questions.",
          img: basePath + "/assets/images/groceryShopping/noodle.png",
          subChoices: [
            {
              text: "紅燒牛肉",
              isCorrect: true,
            },
            {
              text: "青菜",
            },
          ],
        },
      ],
      taskSpecificParams: {
        subQuestions: [
          "1. What kind of food is this?",
          "2. What brand is it?",
          "3. What flavor does it have?",
        ],
      },
    },
    23: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question:
            "Study the label of a shampoo, then answer the following questions.",
          img: basePath + "/assets/images/groceryShopping/shampoo.png",
          subChoices: [
            {
              text: "洗髮精",
              isCorrect: true,
            },
            {
              text: "洗髮水",
            },
          ],
        },
        {
          question:
            "Study the label of a shampoo, then answer the following questions.",
          img: basePath + "/assets/images/groceryShopping/shampoo.png",
          subChoices: [
            {
              text: "蘆薈 aloe",
              isCorrect: true,
            },
            {
              text: "茶 tea",
            },
          ],
        },
      ],
      taskSpecificParams: {
        subQuestions: [
          "1. What is this item called?",
          "2. What ingredients can be found in the shampoo?",
        ],
      },
    },
  },
  1: {
    0: {
      q: "",
      type: "multi-select",
      choices: [
        {
          question: "1. What item does she want to buy?",
          subChoices: [
            {
              text: "手機",
              isCorrect: true,
            },
            {
              text: "相機",
            },
          ],
        },
        {
          question:
            "2. Which characteristics/ features is she looking for? (mark all that apply)",
          subChoices: [
            {
              text: "便宜的",
              isCorrect: true,
            },
            {
              text: "貴的",
            },
            {
              text: "輕的",
            },
            {
              text: "簡單的",
              isCorrect: true,
            },
            {
              text: "小的",
            },
          ],
        },
        {
          question: "3. How much does the cell phone cost?",
          subChoices: [
            {
              text: "$60",
            },
            {
              text: "$140",
              isCorrect: true,
            },
            {
              text: "$70",
            },
          ],
        },
        {
          question: "4. What colors are available? (Select all that apply)",
          subChoices: [
            {
              text: "black",
              isCorrect: true,
            },
            {
              text: "red",
            },
            {
              text: "grey",
            },
            {
              text: "blue",
              isCorrect: true,
            },
          ],
        },
        {
          question: "5. How does she want to pay?",
          subChoices: [
            {
              text: "信用卡",
              isCorrect: true,
            },
            {
              text: "現金",
            },
          ],
        },
      ],
    },
    1: {
      q: "Match the blanks. Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "五十塊",
          textS: "五十块",
          audio: basePath + "/assets/audio/module4/cellphone2/2/3_2_7.mp3",
        },
        {
          text: "紅色",
          textS: "红色",
          audio: basePath + "/assets/audio/module4/cellphone2/2/3_2_4.mp3",
        },
        {
          text: "藍色",
          textS: "蓝色",
          audio: basePath + "/assets/audio/module4/cellphone2/2/3_2_5.mp3",
        },
        {
          text: "黑色",
          textS: "黑色",
          audio: basePath + "/assets/audio/module4/cellphone2/2/3_2_6.mp3",
        },
        {
          text: "便宜的",
          textS: "便宜的",
          audio: basePath + "/assets/audio/module4/cellphone2/2/3_2_3.mp3",
        },
        {
          text: "貴的",
          textS: "贵的",
          audio: basePath + "/assets/audio/module4/cellphone2/2/3_2_2.mp3",
        },
        {
          text: "手機",
          textS: "手机",
          audio: basePath + "/assets/audio/module4/cellphone2/2/3_2_0.mp3",
        },
        {
          text: "相機",
          textS: "相机",
          audio: basePath + "/assets/audio/module4/cellphone2/2/3_2_1.mp3",
        },
        {
          text: "信用卡",
          textS: "信用卡",
          audio: basePath + "/assets/audio/module4/cellphone2/2/3_2_9.mp3",
        },
        {
          text: "現金",
          textS: "现金",
          audio: basePath + "/assets/audio/module4/cellphone2/2/3_2_8.mp3",
        },
      ],
      answers: [
        "五十塊",
        "紅色",
        "藍色",
        "黑色",
        "便宜的",
        "貴的",
        "手機",
        "相機",
        "信用卡",
        "現金",
      ],
      answersS: [
        "五十块",
        "红色",
        "蓝色",
        "黑色",
        "便宜的",
        "贵的",
        "手机",
        "相机",
        "信用卡",
        "现金",
      ],
      images: [
        basePath + "/assets/images/groceryShopping/50.jpg",
        basePath + "/assets/images/groceryShopping/red.png",
        basePath + "/assets/images/groceryShopping/blue.png",
        basePath + "/assets/images/groceryShopping/black.jpg",
        basePath + "/assets/images/groceryShopping/cheap.png",
        basePath + "/assets/images/groceryShopping/expensive.png",
        basePath + "/assets/images/groceryShopping/cellphone.jpg",
        basePath + "/assets/images/groceryShopping/camera.jpg",
        basePath + "/assets/images/groceryShopping/card.jpg",
        basePath + "/assets/images/groceryShopping/cash.jpg",
      ],
    },
    2: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question:
            "1. 聽影片中片段的對話，從下列選擇最好的回答。聽到beep聲後作答。",
          mainAudio: basePath + "/assets/audio/module4/cellphone2/4/q1.mp3",
          subChoices: [
            {
              text: "我想要一隻性能簡單點，價格便宜點的手機就好。",
              help: "I am just looking for a simple, inexpensive cell.",
              isCorrect: true,
            },
            {
              text: "那我想要藍色这只。",
              help: "Then, I want this blue one.",
            },
            {
              text: "那這隻能拍照的手機，照相品質怎麼樣?",
              help: "For this one that can take photo, what is the quality of it?",
            },
          ],
        },
        {
          question: "2. 點選喇叭聽語音，拖曳喇叭圖示和中文到對應的圖片空格。",
          mainAudio: basePath + "/assets/audio/module4/cellphone2/4/q2.mp3",
          subChoices: [
            {
              text: "那這隻能拍照的手機，照相品質怎麼樣?",
              help: "For this one that can take photo, what is the quality of it?",
              isCorrect: true,
            },
            {
              text: "那我想要藍色这隻。",
              help: "Then, I want this blue one.",
            },
            {
              text: "我想要一隻性能簡單點，價格便宜點的手機就好。",
              help: "I am just looking for a simple, inexpensive cell.",
            },
          ],
        },
        {
          question: `3. 點選喇叭聽語音，拖曳喇叭圖示和中文到對應的圖片空格。`,
          mainAudio: basePath + "/assets/audio/module4/cellphone2/4/q3.mp3",
          subChoices: [
            {
              text: "那我想要藍色这隻。",
              help: "Then, I want this blue one.",
              isCorrect: true,
            },
            {
              text: "我想要一隻性能簡單點，價格便宜點的手機就好。",
              help: "I am just looking for a simple, inexpensive cell.",
            },
            {
              text: "那這隻能拍照的手機，照相品質怎麼樣?",
              help: "For this one that can take photo, what is the quality of it?",
            },
          ],
        },
      ],
    },
    3: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question:
            "1. 聽影片中片段的對話，從下列選擇最好的回答。聽到beep聲後作答。",
          mainAudio: basePath + "/assets/audio/module4/cellphone2/5/q1.mp3",
          subChoices: [
            {
              text: "什麼優惠?",
              isCorrect: true,
            },
            {
              text: "都只有照相功能而已。",
            },
            {
              text: "是嗎！不好意思我只有信用卡，那先不用了，謝謝！",
            },
          ],
        },
        {
          question: "2. 點選喇叭聽語音，拖曳喇叭圖示和中文到對應的圖片空格",
          mainAudio: basePath + "/assets/audio/module4/cellphone2/5/q2.mp3",
          subChoices: [
            {
              text: "都只有照相功能而已。",
              isCorrect: true,
            },
            {
              text: "什麼優惠?",
            },
            {
              text: "是嗎！不好意思我只有信用卡，那先不用了，謝謝！",
            },
          ],
        },
        {
          question: "3. 點選喇叭聽語音，拖曳喇叭圖示和中文到對應的圖片空格",
          mainAudio: basePath + "/assets/audio/module4/cellphone2/5/q3.mp3",
          subChoices: [
            {
              text: "是嗎！不好意思我只有信用卡，那先不用了，謝謝！",
              isCorrect: true,
            },
            {
              text: "什麼優惠?",
            },
            {
              text: "都只有照相功能而已。",
            },
          ],
        },
      ],
    },
    4: {
      q: "將手機圖示拖曳到對應的功能空格上",
      type: "grid",
      choices: [
        {
          text: "這一隻手機，除了拍照上網，還可以聽音樂。",
          textS: "这一只手机，除了拍照上网，还可以听音乐。",
          audio: basePath + "/assets/audio/module4/cellphone2/6/6_0.mp3",
        },
        {
          text: "這隻手機除了拍照，還可以上網。",
          textS: "这只手机除了拍照，还可以上网。",
          audio: basePath + "/assets/audio/module4/cellphone2/6/6_1.mp3",
        },
        {
          text: "這隻手機可以拍照。",
          textS: "这只手机可以拍照。",
          audio: basePath + "/assets/audio/module4/cellphone2/6/6_2.mp3",
        },
      ],
      answers: [
        "這一隻手機，除了拍照上網，還可以聽音樂。",
        "這隻手機除了拍照，還可以上網。",
        "這隻手機可以拍照。",
      ],
      answersS: [
        "这一只手机，除了拍照上网，还可以听音乐。",
        "这只手机除了拍照，还可以上网。",
        "这只手机可以拍照。",
      ],
      images: [
        basePath + "/assets/images/groceryShopping/cellphone_1.png",
        basePath + "/assets/images/groceryShopping/cellphone_2.png",
        basePath + "/assets/images/groceryShopping/cellphone_3.png",
      ],
    },
    5: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question:
            "1. 聽一段完整的對話，選擇正確的答案來回答店员的問題。完成之後请將答案錄音給你的老師。",
          mainAudio: basePath + "/assets/audio/module4/cellphone2/8/q1.mp3",
          help: "Clerk: Hi, may I help you?",
          subChoices: [
            {
              text: "你好，我想要買手機。",
              isCorrect: true,
            },
            {
              text: "你好，我想買一些辣的麵條。",
            },
          ],
        },
        {
          question:
            "2. 聽一段完整的對話，選擇正確的答案來回答店员的問題。完成之後请將答案錄音給你的老師。",
          mainAudio: basePath + "/assets/audio/module4/cellphone2/8/q2.mp3",
          help: "2. Clerk: What type of phone are you looking for?",
          subChoices: [
            {
              text: "我想買一隻貴一點，而且好用的。",
              isCorrect: true,
            },
            {
              text: "我想買一隻便宜一點，而且好用的。",
            },
          ],
        },
        {
          question:
            "3. 聽一段完整的對話，選擇正確的答案來回答店员的問題。完成之後请將答案錄音給你的老師。",
          mainAudio: basePath + "/assets/audio/module4/cellphone2/8/q3.mp3",
          help: "Clerk: This phone is $400, it is the best phone in the market.",
          subChoices: [
            {
              text: "可是對我來說，有點太貴了。",
              isCorrect: true,
            },
            {
              text: "我喜歡這隻，你們接受信用卡嗎？",
            },
          ],
        },
        {
          question:
            "4. 聽一段完整的對話，選擇正確的答案來回答店员的問題。完成之後请將答案錄音給你的老師。",
          mainAudio: basePath + "/assets/audio/module4/cellphone2/8/q4.mp3",
          help: "Clerk: We have these two phone which are $100.",
          subChoices: [
            {
              text: "那有什麼功能？",
              isCorrect: true,
            },
            {
              text: "這隻手機有藍色的嗎？",
            },
          ],
        },
      ],
    },
  },
  2: {
    0: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question: "1. What does she want to buy?",
          subChoices: [
            {
              text: "A Nokia phone",
              isCorrect: true,
            },
            {
              text: "A Nokia camera",
            },
          ],
        },
        {
          question: "2. How much does it cost?",
          subChoices: [
            {
              text: "$140",
              isCorrect: true,
            },
            {
              text: "$150",
            },
          ],
        },
        {
          question: "3. Why didn't she buy it?",
          subChoices: [
            {
              text: "She doesn't like it.",
            },
            {
              text: "It's too expensive.",
              isCorrect: true,
            },
          ],
        },
      ],
    },
    1: {
      q: "Click sentence on left and speaker to hear audio. Drag and drop the sentence.",
      type: "rearrange",
      choices: [
        {
          translation: "The customer is asking for the price.",
          text: "這手機現在多少錢？",
          key: 1,
          audio: basePath + "/assets/audio/module4/cellphone1/2/2_0.mp3",
        },
        {
          translation:
            "The clerk is telling the customer the customer the price.",
          text: "我們賣一百四十塊。",
          key: 2,
          audio: basePath + "/assets/audio/module4/cellphone1/2/2_1.mp3",
        },
        {
          translation: "Ther customer is asking for a duscount.",
          text: "有没有優惠或折扣活動呢？",
          key: 3,
          audio: basePath + "/assets/audio/module4/cellphone1/2/2_2.mp3",
        },
        {
          translation: "The clerk is refusing to give a discount.",
          text: "不好意思，這已經是最低的價格了。",
          key: 4,
          audio: basePath + "/assets/audio/module4/cellphone1/2/2_3.mp3",
        },
        {
          translation: "The customer is asking for a discount again.",
          text: "就不能再便宜點了。",
          key: 1,
          audio: basePath + "/assets/audio/module4/cellphone1/2/2_4.mp3",
        },
        {
          translation: "The clerk is providing another way for discount.",
          text: "簽約的話，可能會有些優惠。",
          key: 2,
          audio: basePath + "/assets/audio/module4/cellphone1/2/2_5.mp3",
        },
        {
          translation: "The customer is asking for a discount again.",
          text: "不能再優惠一點嗎？",
          key: 3,
          audio: basePath + "/assets/audio/module4/cellphone1/2/2_6.mp3",
        },
        {
          translation: "The clerk is refusing to give a discount.",
          text: "没有了，不好意思。",
          key: 4,
          audio: basePath + "/assets/audio/module4/cellphone1/2/2_7.mp3",
        },
      ],
    },
    2: {
      q: "Click an underlined word to see the meaning and hear the sound.",
      type: "literacy",
      choices: [
        {
          Customer: "先生，你好，*我想* *買*一*隻**手機*。",
          CustomerS: "先生，你好，*我想* *买*一*只**手机*。",
          Tooltip: [
            "wo3 xiang3, I’d like to",
            "mai3, to buy",
            "zhi1, measure word for cell phone (both 支 and隻 are measure word for cell phone)",
            "shou3 ji1, cell phone",
          ],
          English: "Hi, sir. I want to buy a cell phone.",
          Pinyin: "Xiānshēng, nǐ hǎo, wǒ xiǎng mǎi yī zhī shǒujī.",
        },
        {
          Clerk: "你想要*什麼樣子*的？",
          ClerkS: "你想要*什麽样子*的？",
          Tooltip: ["shen2 me yang4 zi, What kind of"],
          English: "What kind of cell phone do you want to get?",
          Pinyin: "Nǐ xiǎng yào shénme yàngzi de?",
        },
        {
          Customer: "我想要一隻*性能**簡單*點，*價格**便宜*點的手機*就好*。",
          CustomerS: "我想要一只*性能**简单*点，*价格**便宜*点的手机*就好*。",
          Tooltip: [
            "xing4 neng2, performance of a product",
            "jian3 dan1, simple",
            "jia4 ge2, price",
            "pian2 yi, cheap",
            "jiu4 hao3, that would be enough",
          ],
          English: "I am just looking for a simple cell phone at a low price.",
          Pinyin:
            "Wǒ xiǎng yào yī zhī xìngnéng jiǎndān diǎn, jiàgé piányí diǎn de shǒujī jiù hǎo.",
        },
        {
          Clerk:
            "可以，這三隻手機都是五十塊 。這隻手機*可以**拍照*。這隻手機*除了拍照，還可以*上網。這一隻手機，除了拍照*上網*，還可以*聽音樂*。",
          ClerkS:
            "可以，这三只手机都是五十块 。这只手机*可以**拍照*。这只手机*除了拍照，还可以*上网。这一只手机，除了拍照*上网*，还可以*听音乐*。",
          Tooltip: [
            "ke3 yi3, can",
            "pai1 zhao4, to take photos",
            "chu2 le… hai2 ke3 yi3, besides…it can also",
            "shang4 wang3, to go online",
            "ting1 yin1 yue4, to listen to music",
          ],
          English:
            "Ok. These three are all $50 each. This cell phone can take pictures. With this one, in addition to taking pictures you can also use it to go online. With this one in addition to taking pictures and going online, you can also listen to music.",
          Pinyin:
            "Kěyǐ, zhè sānzhīshǒujī dōu shì wǔshí kuài. Zhè zhī shǒujī kěyǐ pāizhào. Zhè zhī shǒujī chúle pāizhào, hái kěyǐ shàngwǎng. Zhè yī zhī shǒu jī, chúle pāizhào shàngwǎng, hái kěyǐ tīng yīnyuè.",
        },
        {
          Customer: "那這隻能拍照的手機，照相*品質**怎麼樣*？",
          CustomerS: "那这只能拍照的手机，照相*品质**怎麽样*？",
          Tooltip: ["pin3 zhi2/zhi4, quality", "zen3 me yang4, How is…?"],
          English:
            "How is the picture quality for this one that can take pictures?",
          Pinyin:
            "Nà zhè zhī néng pāizhào de shǒujī, zhàoxiàng pǐnzhí zěnme yàng?",
        },
        {
          Clerk: "*它*在*同價格*的手機裡面，*質量**算**不錯*。",
          ClerkS: "*它*在*同价格*的手机里面，*质量**算**不错*。",
          Tooltip: [
            "ta1, it (cell phone)",
            "tong2 jia4 ge2, same price",
            "zhi2/zhi4 liang4, quality",
            "suan4, to consider",
            "bu2 cuo4, not bad.",
          ],
          English:
            "The quality is not bad when considering the other cell phones with the same price.",
          Pinyin: "Tā zài tóng jiàgé de shǒujī lǐmiàn, zhìliàng suàn bùcuò.",
        },
        {
          Customer: "嗯，那我就要這隻手機，有沒有什麼*顏色*？",
          CustomerS: "嗯，那我就要这只手机，有没有什麽*颜色*？",
          Tooltip: ["yan2 se4, colors"],
          English: "OK. I’ll take this one then. Are there other colors?",
          Pinyin: "Ń, nà wǒ jiù yào zhè zhī shǒujī, yǒu méiyǒu shé me yánsè?",
        },
        {
          Clerk: "它*現在*有*藍色*和*黑色*。",
          ClerkS: "它*现在*有*蓝色*和*黑色*。",
          Tooltip: ["xian4 zai4, now", "lan2 se4, blue", "hei1 se4, black"],
          English: "It now comes in blue and black.",
          Pinyin: "Tā xiànzài yǒu lán sè hé hēisè.",
        },
        {
          Customer: "那我想要藍色這隻。",
          CustomerS: "那我想要蓝色这只。",
          Tooltip: [],
          English: "I’ll take blue then.",
          Pinyin: "Nà wǒ xiǎng yào lán sè zhè zhī.",
        },
        {
          Clerk: "可以。",
          ClerkS: "可以。",
          Tooltip: [],
          English: "OK.",
          Pinyin: "Kěyǐ.",
        },
        {
          Customer: "可以*刷卡*嗎？",
          CustomerS: "可以*刷卡*吗？",
          Tooltip: ["shua1 ka3, use a credit card"],
          English: "Can I use a credit card?",
          Pinyin: "Kěyǐ shuākǎ ma?",
        },
        {
          Clerk: "可以啊，謝謝。",
          ClerkS: "可以啊，谢谢。",
          Tooltip: [],
          English: "Yes. Thank you.",
          Pinyin: "Kěyǐ a, xièxiè.",
        },
        {
          Customer: "謝謝。",
          CustomerS: "谢谢。",
          Tooltip: [],
          English: "Thank you.",
          Pinyin: "Xièxiè.",
        },
      ],
      taskSpecificParams: {
        audioFiles: [
          basePath + "/assets/sound/buy_v2/s1.mp3",
          basePath + "/assets/sound/buy_v2/s2.mp3",
          basePath + "/assets/sound/buy_v2/s3.mp3",
          basePath + "/assets/sound/buy_v2/s4.mp3",
          basePath + "/assets/sound/buy_v2/s5.mp3",
          basePath + "/assets/sound/buy_v2/s6.mp3",
          basePath + "/assets/sound/buy_v2/s7.mp3",
          basePath + "/assets/sound/buy_v2/s8.mp3",
          basePath + "/assets/sound/buy_v2/s9.mp3",
          basePath + "/assets/sound/buy_v2/s10.mp3",
          basePath + "/assets/sound/buy_v2/s11.mp3",
          basePath + "/assets/sound/buy_v2/s12.mp3",
          basePath + "/assets/sound/buy_v2/s13.mp3",
          basePath + "/assets/sound/buy_v2/s14.mp3",
          basePath + "/assets/sound/buy_v2/s15.mp3",
          basePath + "/assets/sound/buy_v2/s16.mp3",
          basePath + "/assets/sound/buy_v2/s17.mp3",
          basePath + "/assets/sound/buy_v2/s18.mp3",
          basePath + "/assets/sound/buy_v2/s19.mp3",
          basePath + "/assets/sound/buy_v2/s20.mp3",
          basePath + "/assets/sound/buy_v2/s21.mp3",
          basePath + "/assets/sound/buy_v2/s22.mp3",
          basePath + "/assets/sound/buy_v2/s23.mp3",
          basePath + "/assets/sound/buy_v2/s24.mp3",
          basePath + "/assets/sound/buy_v2/s25.mp3",
          basePath + "/assets/sound/buy_v2/s26.mp3",
          basePath + "/assets/sound/buy_v2/s27.mp3",
        ],
      },
    },
    3: {
      q: "Match each word with its meaning.",
      type: "line matching",
      choices: [
        {
          english: "color",
          traditional: "顏色",
          simplified: "颜色",
        },
        {
          english: "to listen to music",
          traditional: "聽音樂",
          simplified: "听音乐",
        },
        {
          english: "cheap",
          traditional: "便宜",
          simplified: "便宜",
        },
        {
          english: "quality",
          traditional: "質量",
          simplified: "质量",
        },
        {
          english: "to take photos",
          traditional: "拍照",
          simplified: "拍照",
        },
        {
          english: "quality",
          traditional: "品質",
          simplified: "品质",
        },
        {
          english: "quality",
          traditional: "性能",
          simplified: "性能",
        },
        {
          english: "to go online",
          traditional: "上網",
          simplified: "上网",
        },
        {
          english: "simple",
          traditional: "簡單",
          simplified: "简单",
        },
        {
          english: "price",
          traditional: "價格",
          simplified: "价格",
        },
      ],
    },
    4: {
      q: "Match each word with its pinyin.",
      type: "line matching",
      choices: [
        {
          english: "yán sè",
          traditional: "顏色",
          simplified: "颜色",
        },
        {
          english: "tīng yīn yuè",
          traditional: "聽音樂",
          simplified: "听音乐",
        },
        {
          english: "pián yí",
          traditional: "便宜",
          simplified: "便宜",
        },
        {
          english: "zhì liàng",
          traditional: "質量",
          simplified: "质量",
        },
        {
          english: "pāi zhào",
          traditional: "拍照",
          simplified: "拍照",
        },
        {
          english: "pǐn zhì",
          traditional: "品質",
          simplified: "品质",
        },
        {
          english: "xìng néng",
          traditional: "性能",
          simplified: "性能",
        },
        {
          english: "shàng wǎng",
          traditional: "上網",
          simplified: "上网",
        },
        {
          english: "jiǎn dān",
          traditional: "簡單",
          simplified: "简单",
        },
        {
          english: "jià gé",
          traditional: "價格",
          simplified: "价格",
        },
      ],
    },
    5: {
      q: "Study the character's meaning, pinyin, radical, strokes, and compound word.",
      type: "analysis",
      choices: [
        {
          traditional: "簡",
          simplified: "简",
          pinyin: "jian3",
          meaning: "simple",
          strokesT: "18",
          strokesS: "13",
          radicalT: "竹 zhu2, banboo",
          radicalS: "竹 zhu2, banboo",
          wordT: "簡單 jian3dan1, simple",
          wordS: "简单 jian3dan1, simple",
          etymology:
            "The original meaning of 簡 is bamboo strips used for writing. The meaning component is 竹 zhú bamboo. The sound component is 間 jiān.",
          strokeOrderT:
            basePath +
            "/assets/gifs/shopping-cellphone_2/vendor2_1_traditional.gif",
          strokeOrderS:
            basePath +
            "/assets/gifs/shopping-cellphone_2/vendor2_1_simplified.gif",
          audio: basePath + "/assets/sound/characters/jian3.mp3",
          seal:
            basePath +
            "/assets/images/cellShopping_literacy_activity_2/seal_1.png",
        },
        {
          traditional: "單",
          simplified: "单",
          pinyin: "dan1",
          meaning: "single",
          strokesT: "12",
          strokesS: "8",
          radicalT: "口 kou3, mouth",
          radicalS: "口 kou3, mouth",
          wordT: "單位 dan1wei4, work unit",
          wordS: "单位 dan1wei4, work unit",
          etymology:
            "The original meaning of 單 is big. The meaning is represented by a pictogrpah of a large wine cup. Borrowed for single.",
          strokeOrderT:
            basePath +
            "/assets/gifs/shopping-cellphone_2/vendor2_2_traditional.gif",
          strokeOrderS:
            basePath +
            "/assets/gifs/shopping-cellphone_2/vendor2_2_simplified.gif",
          audio: basePath + "/assets/sound/characters/dan1.mp3",
          seal:
            basePath +
            "/assets/images/cellShopping_literacy_activity_2/seal_2.png",
        },
        {
          traditional: "價",
          simplified: "价",
          pinyin: "jia4",
          meaning: "price",
          strokesT: "15",
          strokesS: "6",
          radicalT: "人 ren2, person",
          radicalS: "人 ren2, person",
          wordT: "物價 wu4jia4, commodity price",
          wordS: "物价 wu4jia4, commodity price",
          etymology:
            "The meaning component is 亻rén person. The sound component is 賈 jiǎ.",
          strokeOrderT:
            basePath + "/assets/gifs/trav_3/travel_traditional_6.gif",
          strokeOrderS:
            basePath + "/assets/gifs/trav_3/travel_simplified_6.gif",
          audio: basePath + "/assets/travel_literacy_activity3/jia4.mp3",
          seal:
            basePath + "/assets/images/travel_literacy_activity3/seal_6.png",
        },
        {
          traditional: "格",
          simplified: "格",
          pinyin: "ge2",
          meaning: "grid; standard",
          strokesT: "10",
          strokesS: "10",
          radicalT: "木 mu4, wood",
          radicalS: "木 mu4, wood",
          wordT: "價格 jia4ge2, price",
          wordS: "價格 jia4ge2, price",
          etymology:
            "The original meaning of 格 is a tree that is straight. The meaning of component is 木 mù wood. The sound component is 各 gè.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_4.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_4.gif",
          audio: basePath + "/assets/sound/characters/ge2.mp3",
          seal:
            basePath +
            "/assets/images/cellShopping_literacy_activity_2/seal_4.png",
        },
        {
          traditional: "拍",
          simplified: "拍",
          pinyin: "pai1",
          meaning: "to clap; to tap",
          strokesT: "8",
          strokesS: "8",
          radicalT: "手 shou3, hand",
          radicalS: "手 shou3, hand",
          wordT: "拍手 pai1shou3, clap hands",
          wordS: "拍手 pai1shou3, clap hands",
          etymology:
            "The meaning component is 手 shǒu hand. The sound component is 白 bái.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_5.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_5.gif",
          audio: basePath + "/assets/sound/characters/pai1.mp3",
          seal:
            basePath +
            "/assets/images/cellShopping_literacy_activity_2/seal_5.png",
        },
        {
          traditional: "照",
          simplified: "照",
          pinyin: "zhao4",
          meaning: "to reflect",
          strokesT: "13",
          strokesS: "13",
          radicalT: "火 huo3, fire",
          radicalS: "火 huo3, fire",
          wordT: "照相 zhao4xiang4, to take a photo",
          wordS: "照相 zhao4xiang4, to take a photo",
          etymology:
            "The meaning component is  灬 huǒ fire. The sound component is 昭 zhāo.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_6.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_6.gif",
          audio: basePath + "/assets/sound/characters/zhao4.mp3",
          seal:
            basePath +
            "/assets/images/cellShopping_literacy_activity_2/seal_6.png",
        },
        {
          traditional: "聽",
          simplified: "听",
          pinyin: "ting1",
          meaning: "to hear; to listen",
          strokesT: "22",
          strokesS: "7",
          radicalT: "耳 er3, ear",
          radicalS: "口 kou3, mouth",
          wordT: "聽說 ting1shuo1, (it is) said that",
          wordS: "听说 ting1shuo1, (it is) said that",
          etymology:
            "The meaning components are 耳 ěr ear and 㥁 dé virtue. The sound component is 𡈼, pronounced tǐng.",
          strokeOrderT:
            basePath +
            "/assets/gifs/shopping-cellphone_2/vendor2_7_traditional.gif",
          strokeOrderS:
            basePath +
            "/assets/gifs/shopping-cellphone_2/vendor2_7_simplified.gif",
          audio: basePath + "/assets/sound/characters/ting1.mp3",
          seal:
            basePath +
            "/assets/images/cellShopping_literacy_activity_2/seal_7.png",
        },
        {
          traditional: "音",
          simplified: "音",
          pinyin: "yin1",
          meaning: "sound",
          strokesT: "9",
          strokesS: "9",
          radicalT: "音 yin1, sound",
          radicalS: "音 yin1, sound",
          wordT: "聲音 sheng1yin1, voice",
          wordS: "聲音 sheng1yin1, voice",
          etymology:
            "The meaning of sound is represented by 言 yán speech (strokes altered) and the symbol 一 indicating the sound of the words.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_8.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_8.gif",
          audio: basePath + "/assets/sound/characters/yin1.mp3",
          seal:
            basePath +
            "/assets/images/cellShopping_literacy_activity_2/seal_8.png",
        },
        {
          traditional: "樂",
          simplified: "乐",
          pinyin: "yue4",
          meaning: "1. happy 2. music",
          strokesT: "15",
          strokesS: "5",
          radicalT: "木 mu4, wood",
          radicalS: "丿 pie3, left stroke",
          wordT: "快樂 kuai4le4, happy",
          wordS: "快乐 kuai4le4, happy",
          etymology:
            "The meaning of music is represented by a pictograph of a musical instrument on top of a wooden stand. 白 bái in the center represents a thumb, used for strumming the strings of the instrument.",
          strokeOrderT:
            basePath +
            "/assets/gifs/shopping-cellphone_2/vendor2_9_traditional.gif",
          strokeOrderS:
            basePath +
            "/assets/gifs/shopping-cellphone_2/vendor2_9_simplified.gif",
          audio: basePath + "/assets/sound/characters/yue4.mp3",
          seal:
            basePath +
            "/assets/images/cellShopping_literacy_activity_2/seal_9.png",
        },
        {
          traditional: "品",
          simplified: "品",
          pinyin: "pin3",
          meaning: "things; items; products",
          strokesT: "9",
          strokesS: "9",
          radicalT: "口 kou3, mouth",
          radicalS: "口 kou3, mouth",
          wordT: "產品 chan3pin3, products",
          wordS: "產品 chan3pin3, products",
          etymology:
            "The meaning of things, items is represented by a group of three identical symbols.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_10.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_10.gif",
          audio: basePath + "/assets/sound/characters/pin3.mp3",
          seal:
            basePath +
            "/assets/images/cellShopping_literacy_activity_2/seal_10.png",
        },
        {
          traditional: "質",
          simplified: "质",
          pinyin: "zhi4",
          meaning: "quality; nature",
          strokesT: "15",
          strokesS: "8",
          radicalT: "貝 bei4, shell",
          radicalS: "贝 bei4, shell",
          wordT: "品質 pin3zhi2, quality",
          wordS: "品质 pin3zhi2, quality",
          etymology:
            "The original meaning of 質 is pawn or mortgage. The meaning component is 貝 bèi money. The two 斤斤 symbolize the equality of an item and its price.",
          strokeOrderT:
            basePath +
            "/assets/gifs/shopping-cellphone_2/vendor2_11_traditional.gif",
          strokeOrderS:
            basePath +
            "/assets/gifs/shopping-cellphone_2/vendor2_11_simplified.gif",
          audio: basePath + "/assets/sound/characters/zhao3.mp3",
          seal:
            basePath +
            "/assets/images/cellShopping_literacy_activity_2/seal_11.png",
        },
        {
          traditional: "量",
          simplified: "量",
          pinyin: "liang4",
          meaning: "1. quantity 2. to measure",
          strokesT: "12",
          strokesS: "12",
          radicalT: "里 li3, village",
          radicalS: "里 li3, village",
          wordT: "品質 pin3zhi2, quality",
          wordS: "品質 pin3zhi2, quality",
          etymology:
            "An old form of the character 良 liáng, 量 is a pictograph of a measuring scale. The character also may be a combination of 良 and 重 zhòng.",
          strokeOrderT:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_12.gif",
          strokeOrderS:
            basePath + "/assets/gifs/shopping-cellphone_2/vendor2_12.gif",
          audio: basePath + "/assets/sound/characters/liang4.mp3",
          seal:
            basePath +
            "/assets/images/cellShopping_literacy_activity_2/seal_12.png",
        },
      ],
    },
    6: {
      q: "Fill in missing components for each character.",
      type: "dragRadical",
      choices: [
        {
          compound: "reflect",
          first: "昭",
          answer: "火",
        },
        {
          compound: "color",
          first: "彦",
          answer: "頁",
        },
        {
          compound: "sound",
          first: "立",
          answer: "日",
        },
        {
          compound: "quality",
          first: "斤",
          answer: "貝",
        },
        {
          compound: "grid",
          first: "木",
          answer: "各",
        },
        {
          compound: "tap",
          first: "手",
          answer: "白",
        },
        {
          compound: "price",
          first: "人",
          answer: "賈",
        },
        {
          compound: "simple",
          first: "竹",
          answer: "間",
        },
      ],
      answers: ["火", "頁", "日", "貝", "各", "白", "賈", "間"],
    },
    7: {
      q: "Combine characters to form compound words indicated in Hanyu pinyin.",
      type: "dragRadical",
      choices: [
        {
          compound: "jià gé",
          first: "價",
          answer: "格",
        },
        {
          compound: "zhì liàng",
          first: "質",
          answer: "量",
        },
        {
          compound: "pǐn zhì",
          first: "品",
          answer: "質",
        },
        {
          compound: "yīn yuè",
          first: "音",
          answer: "樂",
        },
        {
          compound: "pāi zhào",
          first: "拍",
          answer: "照",
        },
        {
          compound: "yán sè",
          first: "顏",
          answer: "色",
        },
        {
          compound: "shàng wǎng",
          first: "上",
          answer: "網",
        },
      ],
      answers: ["格", "量", "質", "樂", "照", "色", "網"],
    },
    8: {
      q: "Insert words into the correct space.",
      type: "dialogue",
      choices: [
        {
          text: "這三隻手機都是五十塊。",
        },
        {
          text: "这隻手機1_拍照。",
        },
        {
          text: "这隻手機2_拍照, 3_上網。",
        },
        {
          text: "這隻手機，4_拍照上網，5_聽音樂。",
        },
      ],
      answers: ["可以", "除了", "還可以", "除了", "還可以"],
      answersS: ["可以", "除了", "还可以", "除了", "还可以"],
    },
    9: {
      q: "Part 1: Answer questions in Chinese.",
      altQ: [
        "Part 2: You are going to introduce a cell phone you just bought. Write up a description of 10 sentences for this cell phone. In your description, be sure to include at least three topics: features, color, and price.",
      ],
      type: "recording",
      choices: [
        {
          q: "你的手機有什麼性能？（可以A，除了A以外，還可以B）",
        },
        {
          q: "你的手機是什麼顏色？",
        },
        {
          q: "你覺得你的手機的品質好不好？為什麼？",
        },
        {
          q: "要是你要買一隻新手機，你最想要有哪些性能？為什麼？",
        },
      ],
    },
  },
  3: {
    0: {
      q: "",
      type: "multi-single",
      choices: [
        {
          question: "1. How much does the ornament cost?",
          subChoices: [
            {
              text: "$8",
              isCorrect: true,
            },
            {
              text: "$9",
            },
          ],
        },
        {
          question: "2. How can she get a discount?",
          subChoices: [
            {
              text: "If she buys several",
              isCorrect: true,
            },
            {
              text: "If she is a student",
            },
          ],
        },
        {
          question: "3. How much would that be if she buys all three?",
          subChoices: [
            {
              text: "$21",
              isCorrect: true,
            },
            {
              text: "$20",
            },
          ],
        },
        {
          question: "4. What is the final offer for them?",
          subChoices: [
            {
              text: "$20",
              isCorrect: true,
            },
            {
              text: "$21",
            },
          ],
        },
      ],
    },
    1: {
      q: "",
      type: "numbers",
      choices: [
        {
          question:
            "Get to know the numbers in Chinese. Click on the speaker to hear each number in Chinese.",
          subChoices: [
            {
              text: "一 (1)",
              audio: basePath + "/assets/audio/module4/gift/2/2_0.mp3",
            },
            {
              text: "二 (2)",
              audio: basePath + "/assets/audio/module4/gift/2/2_1.mp3",
            },
            {
              text: "三 (3)",
              audio: basePath + "/assets/audio/module4/gift/2/2_2.mp3",
            },
            {
              text: "四 (4)",
              audio: basePath + "/assets/audio/module4/gift/2/2_3.mp3",
            },
            {
              text: "五 (5)",
              audio: basePath + "/assets/audio/module4/gift/2/2_4.mp3",
            },
            {
              text: "六 (6)",
              audio: basePath + "/assets/audio/module4/gift/2/2_5.mp3",
            },
            {
              text: "七 (7)",
              audio: basePath + "/assets/audio/module4/gift/2/2_6.mp3",
            },
            {
              text: "八 (8)",
              audio: basePath + "/assets/audio/module4/gift/2/2_7.mp3",
            },
            {
              text: "九 (9)",
              audio: basePath + "/assets/audio/module4/gift/2/2_8.mp3",
            },
            {
              text: "零 (0)",
              audio: basePath + "/assets/audio/module4/gift/2/2_9.mp3",
            },
            {
              text: "十",
              audio: basePath + "/assets/audio/module4/gift/2/2_10.mp3",
            },
            {
              text: "三十",
              audio: basePath + "/assets/audio/module4/gift/2/2_11.mp3",
            },
            {
              text: "百",
              audio: basePath + "/assets/audio/module4/gift/2/2_12.mp3",
            },
            {
              text: "千",
              audio: basePath + "/assets/audio/module4/gift/2/2_13.mp3",
            },
            {
              text: "萬",
              audio: basePath + "/assets/audio/module4/gift/2/2_14.mp3",
            },
            {
              text: "二十五 (25)",
              audio: basePath + "/assets/audio/module4/gift/2/2_15.mp3",
            },
            {
              text: "四百零三 (403)",
              audio: basePath + "/assets/audio/module4/gift/2/2_17.mp3",
            },
            {
              text: "一千五百七十八 (1578)",
              audio: basePath + "/assets/audio/module4/gift/2/2_18.mp3",
            },
            {
              text: "一萬零五 (10005)",
              audio: basePath + "/assets/audio/module4/gift/2/2_19.mp3",
            },
            {
              text: "一千零六十九 (1069)",
              audio: basePath + "/assets/audio/module4/gift/2/2_16.mp3",
            },
          ],
        },
        {
          question:
            "Match the blanks. Click on the speaker to hear each number in Chinese then drag each audio to the corresponding price.",
          subChoices: [
            {
              text: "五塊",
              audio: basePath + "/assets/audio/module4/gift/2/2_2_0.mp3",
            },
            {
              text: "十塊九毛九",
              audio: basePath + "/assets/audio/module4/gift/2/2_2_6.mp3",
            },
            {
              text: "七塊六毛七",
              audio: basePath + "/assets/audio/module4/gift/2/2_2_2.mp3",
            },
            {
              text: "六千零八",
              audio: basePath + "/assets/audio/module4/gift/2/2_2_9.mp3",
            },
            {
              text: "一萬零八",
              audio: basePath + "/assets/audio/module4/gift/2/2_2_8.mp3",
            },
            {
              text: "$104.30",
              audio: basePath + "/assets/audio/module4/gift/2/2_2_1.mp3",
            },
            {
              text: "$8,060.79",
              audio: basePath + "/assets/audio/module4/gift/2/2_2_5.mp3",
            },
            {
              text: "$930.40",
              audio: basePath + "/assets/audio/module4/gift/2/2_2_7.mp3",
            },
            {
              text: "$1,022",
              audio: basePath + "/assets/audio/module4/gift/2/2_2_3.mp3",
            },
            {
              text: "$10.50",
              audio: basePath + "/assets/audio/module4/gift/2/2_2_4.mp3",
            },
          ],
        },
      ],
    },
    2: {
      q: "Put the following sentences from the dialogue in the correct order.",
      type: "rearrange",
      choices: [
        {
          translation: "The customer is asking for the price.",
          text: "老板，請問一下，這個多少錢？",
          key: 1,
        },
        {
          translation: "The clerk is answering for the price.",
          text: "你好，這一個八塊錢。",
          key: 2,
        },
        {
          translation: "Ther customer is asking for a discount.",
          text: "八塊錢，那可以算我便宜一點嗎？",
          key: 3,
        },
        {
          translation: "The clerk is refusing to give a discount.",
          text: "這價錢已經很公道了，而且手工非常细。",
          key: 4,
        },
        {
          translation:
            "The customer is asking for a discount if she buys several.",
          text: "那我一次買三個的話？",
          key: 1,
        },
        {
          translation: "The clerk is agreeing to give a discount.",
          text: "三個的話，可以，可以少算一點，算便宜一點。",
          key: 2,
        },
        {
          translation: "The customer is asking for the price after discount.",
          text: "那是？",
          key: 3,
        },
        {
          translation: "The clerk is answering the discount price.",
          text: "三個的話，二十四塊，二十一塊給你啦。",
          key: 4,
        },
      ],
    },
    3: {
      q: "Things in a gift shop. look at the picture of the gift shop and identify some of the gifts in it. Match the names of the gift to their picture.",
      type: "grid",
      choices: [
        {
          text: "春聯",
          textS: "春联",
          audio: basePath + "/assets/audio/module4/gift/4/4-4-4-3.mp3",
        },
        {
          text: "陶瓷娃娃",
          textS: "陶瓷娃娃",
          audio: basePath + "/assets/audio/module4/gift/4/4-4-4-2.mp3",
        },
        {
          text: "中國結",
          textS: "中国结",
          audio: basePath + "/assets/audio/module4/gift/4/4-4-4-0.mp3",
        },
        {
          text: "旗袍",
          textS: "旗袍",
          audio: basePath + "/assets/audio/module4/gift/4/4-4-4-4.mp3",
        },
        {
          text: "明信片",
          textS: "明信片",
          audio: basePath + "/assets/audio/module4/gift/4/4-4-4-1.mp3",
        },
      ],
      answers: ["春聯", "陶瓷娃娃", "中國結", "旗袍", "明信片"],
      answersS: ["春联", "陶瓷娃娃", "中国结", "旗袍", "明信片"],
      images: [
        basePath + "/assets/images/groceryShopping/spring_couplets.jpg",
        basePath + "/assets/images/groceryShopping/ceramic_doll.jpg",
        basePath + "/assets/images/groceryShopping/chinese_knots.jpg",
        basePath + "/assets/images/groceryShopping/cheongsam.jpg",
        basePath + "/assets/images/groceryShopping/postcard.jpg",
      ],
    },
    4: {
      q: "What FOUR sentences does the client use to negotiate a cheaper price?",
      type: "multipleSound",
      choices: [
        {
          text: "那可以算我便宜一點嗎？",
        },
        {
          text: "可是前面攤子說可以賣我三個二十塊。",
        },
        {
          text: "再便宜一點嘛！",
        },
        {
          text: "那我一次買三個的話？",
        },
        {
          text: "其實價格已經打過折了，很便宜。",
        },
        {
          text: "三個的話，二十四塊，二十一塊給你。",
        },
        {
          text: "那好啦，就二十塊算给你。",
        },
        {
          text: "老板，請問一下，這個多少錢？",
        },
      ],
      answerKey: [true, true, true, true, false, false, false, false],
    },
    5: {
      q: "Match the blanks. Select a word and click the corresponding picture that matches.",
      type: "grid",
      choices: [
        {
          text: "一塊三角",
          textS: "一块三角",
          audio: basePath + "/assets/audio/module4/gift/6/6_1.mp3",
        },
        {
          text: "五塊五角五分",
          textS: "五块五角五分",
          audio: basePath + "/assets/audio/module4/gift/6/6_0.mp3",
        },
        {
          text: "一百零二塊",
          textS: "一百零二块",
          audio: basePath + "/assets/audio/module4/gift/6/6_3.mp3",
        },
        {
          text: "十六塊两分",
          textS: "十六块两分",
          audio: basePath + "/assets/audio/module4/gift/6/6_2.mp3",
        },
      ],
      answers: ["一塊三角", "五塊五角五分", "一百零二塊", "十六塊两分"],
      answersS: ["一块三角", "五块五角五分", "一百零二块", "十六块两分"],
      images: [
        basePath + "/assets/images/groceryShopping/6_3.png",
        basePath + "/assets/images/groceryShopping/6_0.png",
        basePath + "/assets/images/groceryShopping/6_1.png",
        basePath + "/assets/images/groceryShopping/6_2.png",
      ],
    },
  },
};
