import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import IncorrectIcon from "../icons/IncorrectIcon";
import CorrectIcon from "../icons/CorrectIcon";
import OpenCC from "node-opencc";

/* eslint-disable no-unused-expressions */

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 25px;
  margin-left: 0;
  margin-right: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  @media (orientation: portrait) {
    width: 90vw;
  }
`;

const AudioAndImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  @media (orientation: portrait) {
    width: 90vw;
    flex-direction: column;
  }
`;

const AnswerChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
`;

const Audio = styled.audio`
  width: 250px;
  position: relative;
  left: 0%;
  margin-bottom: 15px;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 12px;
`;

const StyledImage = styled.img`
  @media (orientation: landscape) {
    height: 18vw;
    width: 25vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
  margin: 5;
`;

function Directions({
  question,
  choices,
  img,
  audios,
  answerKey,
  nextLink,
  simplified,
}) {
  const [toggleImage, setToggleImage] = useState(true);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState("Check");
  const [showFinalImage, setShowFinalImage] = useState(false);
  const [useRoutes, setUseRoutes] = useState(false);
  const [route, setRoute] = useState(0);

  const reset = useCallback(() => {
    setShowAnswer(false);
    setSelectedAnswer("");
  });

  useEffect(() => {
    reset();
    if (question.length > 1) {
      setUseRoutes(true);
    }
  }, []);

  const checkAnswer = (e) => {
    setSelectedAnswer(e.target.value);
  };

  const handleStatus = () => {
    switch (status) {
      case "Check":
        setShowAnswer(true);
        if (question.length > 1) {
          if (current === 0) {
            const selection = choices[route][current].text[selectedAnswer];
            if (selection === answerKey[0][current]) {
              setStatus("Next");
            } else if (selection === answerKey[1][current]) {
              setRoute(1);
              setStatus("Next");
            } else {
              setStatus("Retry");
            }
          } else if (
            choices[route][current].text[selectedAnswer] ===
            answerKey[route][current]
          ) {
            if (current + 1 === choices[route].length) {
              setToggleImage(false);
              setShowFinalImage(true);
            }
            setStatus("Next");
          } else {
            setStatus("Retry");
          }
        } else if (
          choices[current].text[selectedAnswer] === answerKey[current]
        ) {
          if (current + 1 === choices.length) {
            setToggleImage(false);
            setShowFinalImage(true);
          }
          setStatus("Next");
        } else {
          setStatus("Retry");
        }
        break;
      case "Retry":
        reset();
        setStatus("Check");
        break;
      case "Next":
        if (question.length > 1) {
          if (current + 1 < choices[route].length) {
            reset();
            setCurrent(current + 1);
            setStatus("Check");
          } else {
            window.location.href = nextLink;
          }
        } else if (current + 1 < choices.length) {
          reset();
          setCurrent(current + 1);
          setStatus("Check");
        } else {
          window.location.href = nextLink;
        }
        break;
      default:
        break;
    }
  };

  const labelClass = showAnswer ? "lbl-answer" : "";

  const currQuestion =
    question.length > 1 ? question[route][current] : (Array.isArray(question) ? question[0] : question);

  return (
    <React.Fragment>
      <Header>
        <StyledQuestion>
          {simplified
            ? currQuestion
            : OpenCC.simplifiedToTraditional(currQuestion)}
        </StyledQuestion>
        {audios && (
          <Audio
            controls
            controlsList="nodownload"
            src={audios[route][current]}
          />
        )}
        <ButtonContainer>
          {showFinalImage ? null : (
            <Button
              style={{ alignSelf: "center" }}
              variant="contained"
              color="secondary"
              onClick={() => setToggleImage(!toggleImage)}
            >
              {toggleImage ? "Hide Image" : "Show Image"}
            </Button>
          )}
        </ButtonContainer>
      </Header>
      <AudioAndImage>
        {toggleImage ? (
          useRoutes ? (
            <StyledImage alt="loading..." src={img[route][current]} />
          ) : (
            <StyledImage alt="loading..." src={img[current]} />
          )
        ) : null}
        {showFinalImage ? (
          useRoutes ? (
            <StyledImage alt="loading..." src={img[route][current + 1]} />
          ) : (
            <StyledImage alt="loading..." src={img[current + 1]} />
          )
        ) : null}
      </AudioAndImage>
      <StyledContainer>
        <RadioGroup
          value={selectedAnswer.toString()}
          onChange={(e) => checkAnswer(e)}
        >
          {useRoutes && !showFinalImage
            ? choices[route][current].text.map((text, index) => (
                <FormControlLabel
                  key={index}
                  value={index.toString()}
                  className={labelClass}
                  control={
                    showAnswer ? (
                      <IconContainer>
                        {showAnswer &&
                          selectedAnswer === index.toString() &&
                          (text === answerKey[route][current] ? (
                            <CorrectIcon />
                          ) : (
                            <IncorrectIcon />
                          ))}
                      </IconContainer>
                    ) : (
                      <Radio />
                    )
                  }
                  label={
                    <AnswerChoiceContainer>
                      <div className="label">
                        {simplified
                          ? OpenCC.traditionalToSimplified(text)
                          : text}
                      </div>
                    </AnswerChoiceContainer>
                  }
                />
              ))
            : null}
          {question.length === 1 && !showFinalImage
            ? choices[current].text.map((text, index) => (
                <FormControlLabel
                  key={index}
                  value={index.toString()}
                  className={labelClass}
                  control={
                    showAnswer ? (
                      <IconContainer>
                        {showAnswer &&
                          selectedAnswer === index.toString() &&
                          (text === answerKey[current] ? (
                            <CorrectIcon />
                          ) : (
                            <IncorrectIcon />
                          ))}
                      </IconContainer>
                    ) : (
                      <Radio />
                    )
                  }
                  label={
                    <AnswerChoiceContainer>
                      <div className="label">
                        {simplified
                          ? OpenCC.traditionalToSimplified(text)
                          : text}
                      </div>
                    </AnswerChoiceContainer>
                  }
                />
              ))
            : null}
        </RadioGroup>
        <Button
          style={{ alignSelf: "center", marginTop: 10 }}
          variant="contained"
          color="secondary"
          onClick={() => {
            selectedAnswer === "" ? null : handleStatus();
          }}
        >
          {status}
        </Button>
      </StyledContainer>
    </React.Fragment>
  );
}

export default Directions;
