import React from "react";
import Grid from "@material-ui/core/Grid";
import MODULES from "../models";
import ModuleMenuCard from "./ModuleMenuCard";

export default function ModuleMenu({
  match: {
    params: { moduleId },
  },
}) {
  const { [moduleId]: topics } = MODULES;

  return (
    <div className="module-menu">
      <Grid container spacing={2}>
        {topics.map(({ title, img, startUrl }) => (
          <Grid item xs={12} sm={6} md={4} key={title}>
            <ModuleMenuCard title={title} img={img} startUrl={startUrl} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
