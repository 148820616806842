import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { Button, Icon } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IncorrectIcon from "../icons/IncorrectIcon";
import CorrectIcon from "../icons/CorrectIcon";

const Header = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 height: 15%;
 @media (orientation: landscape) {
  max-width: 80vw;
 }
 @media (orientation: portrait) {
  height: 5%;
  margin-top: 0%:
  width: 90vw;
 }
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 25px;
  margin-left: 0;
  margin-right: 0;
  @media (orientation: portrait) {
    font-size: 5vw;
  }
`;

const IconContainer = styled.div`
  width: 24px;
  margin-left: 6px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const DropTargetContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WordBank = styled.div`
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (orientation: landscape) {
    width: 25vw;
    height: 25vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
`;

const WordBankContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  font-size: 25px;
  @media (orientation: landscape) {
    border-left: 1px solid black;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ChoicesContainer = styled.div`
  display: flex;
  flex: 1;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30vw;
  @media (orientation: portrait) {
    margin-top: 1.5vw;
  }
`;

const Choice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (orientation: landscape) {
    font-size: 25px;
    margin: 5px;
    width: 100%;
  }
  @media (orientation: landscape) and (max-width: 1415px) {
    width: 500px;
  }
  @media (orientation: portrait) {
    font-size: 5vw;
    width: 95vw;
    margin: 2.5px;
  }
`;

const WordContainer = styled.div`
  text-align: center;
  @media (orientation: landscape) {
    font-size: 25px;
    width: 20%;
  }
  @media (orientation: portrait) {
    font-size: 5vw;
    width: 20%;
  }
`;

function WordPuzzle({ question, choices, nextLink, simplified }) {
  const [status, setStatus] = useState("Check");
  const [dragSelected, setDragSelected] = useState(0);
  const [dropZone, setDropZone] = useState(0);
  const [wordBank, setWordBank] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [choiceKey, setChoiceKey] = useState([]);
  const [current, setCurrent] = useState(0);

  const landscape =
    process.browser && window.matchMedia("(orientation: landscape)").matches;

  /*
   * resets:
   * answerList = [..., choices.length]
   * choiceKey = [..., choices.length]
   * calls generateWordBank function
   */
  const reset = useCallback(() => {
    const emptyAnswerList = [...choices].fill("");
    for (const index in emptyAnswerList) {
      emptyAnswerList[index] = [""];
    }
    emptyAnswerList.pop();
    setAnswerList(emptyAnswerList);
    setChoiceKey(emptyAnswerList);
    generateWordBank();
  });

  useEffect(() => {
    reset();
  }, []);

  /*
   * resets for mobile mode:
   * maps over answerList/choiceKey and resets only the index corresponding to current
   * calls generateWordBank function
   */
  const resetCurrent = useCallback(() => {
    setAnswerList(
      [...answerList].map((choice, index) =>
        choice.map((item, i) => (index === current ? "" : item))
      )
    );
    setChoiceKey(
      [...choiceKey].map((choice, index) =>
        choice.map((item, i) => (index === current ? "" : item))
      )
    );
    generateWordBank();
  });

  /*
   * generateWordBank:
   *   Maps all simplified words to their respective traditional words like such after randomly suffling:
   *     wordBank = [simplified:traditional, simplified:traditional, ...]
   */
  const generateWordBank = () => {
    const words = [];
    const simplifiedBank = [];
    const traditionalBank = [];
    choices.map((choice) => {
      [...choice.simplified].map((simplified, i) => {
        simplifiedBank.push(simplified);
        traditionalBank.push(choice.traditional[i]);
      });
    });
    shuffle(simplifiedBank, traditionalBank);
    for (const i in simplifiedBank) {
      words[simplifiedBank[i]] = traditionalBank[i];
    }
    setWordBank(words);
  };

  /*
   * shuffle:
   *   Randomly shuffles two arrays while maintaining order:
   * @param {array} (a,b) -> arrays to be shuffled
   */
  const shuffle = (a, b) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
      [b[i], b[j]] = [b[j], b[i]];
    }
  };

  /*
   ** Drag And Drop Function
   * Terms:
   *   dropZone: selected/droped destination
   *   dragSelected: selected Item for drop
   *   choiceKey: for checking final correctness
   *   audioList: Audio selection bar
   * If status === Check:
   *   If answerList[dropZone] === '' -> if its not populated:
   *     Map over old answerList, if index === dropZone[0] (row) and i === dropZone[1] (index) we will map over the selected element
   */
  const handleDrop = () => {
    if (status === "Check") {
      setAnswerList(
        [...answerList].map((choice, index) =>
          choice.map((item, i) =>
            index === dropZone ? [...item, dragSelected] : item
          )
        )
      );
      setChoiceKey(
        [...choiceKey].map((choice, index) =>
          choice.map((item, i) =>
            index === dropZone ? [...item, dragSelected[0]] : item
          )
        )
      );
    }
  };

  /*
   ** clear index function
   * Clears a certain entry based on row
   * @param {int} row -> row selected
   */
  const clearEntry = (row) => {
    if (status === "Check") {
      setAnswerList(
        [...answerList].map((choice, index) =>
          choice.map((item, i) => (index === row ? "" : item))
        )
      );
      setChoiceKey(
        [...choiceKey].map((choice, index) =>
          choice.map((item, i) => (index === row ? "" : item))
        )
      );
    }
  };

  const handleStatus = () => {
    switch (status) {
      case "Check":
        if (landscape) {
          const answerKey = [];
          for (const x in choices) {
            answerKey.push(choices[x].simplified);
          }
          answerKey.pop();
          const temp = [];
          for (const x in choiceKey) {
            temp.push(choiceKey[x].join().replace(/,/g, ""));
          }
          temp.join() === answerKey.join()
            ? setStatus("Next")
            : setStatus("Retry");
        } else if (
          choiceKey[current].join().replace(/,/g, "") ===
          choices[current].simplified
        ) {
          setStatus("Next");
        } else {
          setStatus("Retry");
        }
        break;
      case "Retry":
        landscape ? reset() : resetCurrent();
        setStatus("Check");
        break;
      case "Next":
        if (!landscape && current + 1 < choices.length - 1) {
          setCurrent(current + 1);
          setStatus("Check");
        } else {
          window.location.href = nextLink;
        }
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Header>
        <StyledQuestion>{question}</StyledQuestion>
      </Header>
      <Container>
        <ChoicesContainer>
          {landscape ? (
            <Wrap>
              {choices.map((choice, index) => {
                if (index != choices.length - 1) {
                  let word = "";
                  let textColor = "black";
                  if (answerList.length > 0) {
                    let traditionalWord = "";
                    let simplifiedWord = "";
                    for (const i in answerList[index][0]) {
                      simplifiedWord += answerList[index][0][i][0];
                      traditionalWord += answerList[index][0][i][1];
                    }
                    word = simplified ? simplifiedWord : traditionalWord;
                    if (status !== "Check") {
                      textColor =
                        simplifiedWord === choices[index].simplified
                          ? "green"
                          : "red";
                    }
                  }
                  return (
                    <Choice>
                      <div>{choice.english}</div>
                      <DropTarget
                        targetKey="foo"
                        onHit={() => handleDrop()}
                        onDragEnter={() => setDropZone(index)}
                      >
                        <DropTargetContainer>
                          <TextField
                            onClick={() => clearEntry(index)}
                            margin="none"
                            disabled
                            value={word}
                            InputProps={{
                              style: {
                                width: 200,
                                color: textColor,
                                fontSize: 25,
                              },
                            }}
                            variant="outlined"
                          />
                          <IconContainer>
                            {status !== "Check" ? (
                              textColor === "green" ? (
                                <CorrectIcon />
                              ) : (
                                <IncorrectIcon />
                              )
                            ) : null}
                          </IconContainer>
                        </DropTargetContainer>
                      </DropTarget>
                    </Choice>
                  );
                }
              })}
            </Wrap>
          ) : null}
        </ChoicesContainer>
        <WordBankContainer>
          Word Bank
          <WordBank>
            {Object.entries(wordBank).map(([key, value]) => {
              const word = simplified ? key : value;
              return (
                <WordContainer>
                  <DragDropContainer
                    targetKey="foo"
                    onDrag={() => setDragSelected([key, value])}
                  >
                    {word}
                  </DragDropContainer>
                </WordContainer>
              );
            })}
          </WordBank>
          {!landscape ? (
            <Wrap>
              <div
                style={{
                  display: "flex",
                  width: "90vw",
                  justifyContent: "center",
                  fontSize: "5vw",
                }}
              >
                {`Current Progress: ${current + 1}/${choices.length - 1}`}
              </div>
              {choices.map((choice, index) => {
                if (index === current) {
                  let word = "";
                  let textColor = "black";
                  if (answerList.length > 0) {
                    let traditionalWord = "";
                    let simplifiedWord = "";
                    for (const i in answerList[index][0]) {
                      simplifiedWord += answerList[index][0][i][0];
                      traditionalWord += answerList[index][0][i][1];
                    }
                    word = simplified ? simplifiedWord : traditionalWord;
                    if (status !== "Check") {
                      textColor =
                        simplifiedWord === choices[index].simplified
                          ? "green"
                          : "red";
                    }
                  }
                  return (
                    <Choice>
                      <div>{choice.english}</div>
                      <DropTarget
                        targetKey="foo"
                        onHit={() => handleDrop()}
                        onDragEnter={() => setDropZone(index)}
                      >
                        <DropTargetContainer>
                          <TextField
                            onClick={() => clearEntry(index)}
                            margin="none"
                            disabled
                            value={word}
                            InputProps={{
                              style: {
                                height: "15vw",
                                width: "50vw",
                                color: textColor,
                                fontSize: "5vw",
                              },
                            }}
                            variant="outlined"
                          />
                          <IconContainer>
                            {status !== "Check" ? (
                              textColor === "green" ? (
                                <CorrectIcon />
                              ) : (
                                <IncorrectIcon />
                              )
                            ) : null}
                          </IconContainer>
                        </DropTargetContainer>
                      </DropTarget>
                    </Choice>
                  );
                }
              })}
            </Wrap>
          ) : null}
        </WordBankContainer>
      </Container>
      <Button
        style={{ alignSelf: "center", marginTop: 10 }}
        variant="contained"
        color="secondary"
        onClick={() => handleStatus()}
      >
        {status}
      </Button>
    </React.Fragment>
  );
}

export default WordPuzzle;
