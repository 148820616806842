import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import openCC from "node-opencc";

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.35em;
  margin-left: 0;
  margin-right: 0;
`;

const StyledInfo = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 1.6em;
`;

const StyledChoices = styled.div`
  float: left;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 25px;
  font-weight: 500;
`;

const DropInContainer = styled.div`
  border-width : 2px;
  @media (orientation: landscape) {
    width : 100px;
    height : 10px;
    font-size : 20px;
  }
  @media (orientation: portrait) {
    width : 100px;
    height : 10px;
    font-size : 15px;
  }
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 15px;
  background-color : white;
  border-color : black;
  line-height : 1;
  display flex;
  justify-content : center;
  align-items : center;
  padding : 15px;
  margin : 5px;
  text-align : center;
`;

const Audio = styled.audio`
  width: 250px;
  position: relative;
  left: 0%;
  margin-bottom: 15px;
`;

function DialogueDrag({
  question,
  simplified,
  choices,
  answers,
  answersS,
  mainaudio,
  nextLink,
}) {
  const [status, setStatus] = useState("Check");
  const [selection, setSelection] = useState([]);
  const [dragSelected, setDragSelected] = useState(0);
  const [dropZone, setDropZone] = useState(0);
  const [answer, setAnswer] = useState([]);

  useEffect(() => {
    const init = () => {
      let temp = [];

      /*
        Initialize the answer array and fill with '_'. User will replace '_' with their answer.
        Choice selection is also initialized and shuffled.
      */
      for (let i = 0; i < answers.length; i++) {
        temp = [...temp, answers[i]];
        setAnswer((answer) => [...answer, "_"]);
      }

      setSelection(shuffle(temp));
    };
    init();
  }, []);

  const shuffle = (array) => {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };

  const handleDrop = (e, index) => {
    // Uses number before entry box as a way to set the answer
    const pos = parseInt(index) - 1;

    setAnswer((answer) => {
      const update = [...answer];
      update[pos] = e.dragData.data;
      return update;
    });
  };

  const clearEntry = (index) => {
    // Uses number before entry box as a way to remove the answer
    const pos = parseInt(index) - 1;

    setAnswer((answer) => {
      const update = [...answer];
      update[pos] = "_";
      return update;
    });
  };

  const handleStatus = () => {
    switch (status) {
      case "Check":
        if (simplified) {
          answer.join() === answersS.join()
            ? setStatus("Next")
            : setStatus("Retry");
        } else {
          answer.join() === answers.join()
            ? setStatus("Next")
            : setStatus("Retry");
        }
        break;
      case "Retry":
        setAnswer([]);
        for (let i = 0; i < answers.length; i++) {
          setAnswer((answer) => [...answer, "_"]);
        }
        shuffle(selection);
        setStatus("Check");
        break;
      case "Next":
        window.location.href = nextLink;
        break;
      default:
        break;
    }
  };

  const parseText = (data, index) => {
    const builder = [];
    let start = "";

    for (let i = 0; i < data.length; i++) {
      const pos = data[i - 1];
      if (data[i] === "_") {
        builder.push(
          <StyledInfo style={{ float: "left" }}>
            {simplified ? openCC.traditionalToSimplified(start) : start}
          </StyledInfo>
        );
        builder.push(
          <DropTarget
            targetKey="foo"
            onHit={(e) => handleDrop(e, pos)}
            onDragEnter={() => setDropZone([index, i])}
          >
            <DropInContainer
              style={{
                color:
                  status !== "Check"
                    ? simplified
                      ? answer[parseInt(pos) - 1] ===
                        answersS[parseInt(pos) - 1]
                        ? "Green"
                        : "Red"
                      : answer[parseInt(pos) - 1] === answers[parseInt(pos) - 1]
                      ? "Green"
                      : "Red"
                    : "gray",
                borderStyle: "dotted",
                borderWidth: "2px",
                float: "left",
              }}
              onClick={() =>
                answer[parseInt(pos) - 1] === "_" ? null : clearEntry(pos)
              }
            >
              <div>
                {answer[parseInt(pos) - 1] === "_"
                  ? null
                  : answer[parseInt(pos) - 1]}
              </div>
            </DropInContainer>
          </DropTarget>
        );
        start = "";
      } else {
        // If number, skip over
        if (data[i] >= "0" && data[i] <= "9") {
          continue;
        } else {
          start += data[i];
        }
      }
    }
    builder.push(
      <StyledInfo style={{ float: "left" }}>
        {simplified ? openCC.traditionalToSimplified(start) : start}
      </StyledInfo>
    );
    return (
      <div key={index} style={{ clear: "both" }}>
        {builder}
      </div>
    );
  };

  return (
    <React.Fragment>
      <StyledQuestion>{question}</StyledQuestion>

      {mainaudio && (
        <Audio controls controlsList="nodownload" src={mainaudio} />
      )}

      <div style={{ margin: 5, border: "1px solid black" }}>
        <div style={{ float: "left" }}>
          {selection.map((item, index) => (
            <StyledChoices>
              <DragDropContainer
                targetKey="foo"
                noDragging={status !== "Check"}
                onDrag={() => setDragSelected(index)}
                dragData={{
                  data: simplified
                    ? openCC.traditionalToSimplified(item)
                    : item,
                }}
              >
                <div>
                  {simplified ? openCC.traditionalToSimplified(item) : item}
                </div>
              </DragDropContainer>
            </StyledChoices>
          ))}
        </div>
      </div>

      <div>{choices.map((item, index) => parseText(item.text, index))}</div>

      <Button
        style={{ marginTop: 10 }}
        variant="contained"
        color="secondary"
        onClick={() => handleStatus()}
      >
        {status}
      </Button>
    </React.Fragment>
  );
}

export default DialogueDrag;
