import OpenCC from "node-opencc";

// Check if input is simplified or traditional and just returns it convereted to the opposite
// For ambiguous input.

// is simplified if text converting to trad res in different text or if trying to convert to simp returns itself
export const translate = (text, wantSimplified) => {
  // Handle endge case, For one problem, where we are passing in an array of arrays for DIRECTIONS1/3/0
  if (Array.isArray(text)) {
    const translatedStrings = text.map((innerArray) =>
      innerArray.map((s) => translateString(s, wantSimplified))
    );
    return translatedStrings;
  } else {
    return translateString(text, wantSimplified);
  }
};

const translateString = (text, wantSimplified) => {
  if (!text) {
    return text;
  }
  const convertedS2T = OpenCC.simplifiedToTraditional(text);
  const converetedT2S = OpenCC.traditionalToSimplified(text);
  const isSimplified = text !== convertedS2T || text === converetedT2S;

  if (wantSimplified) {
    return isSimplified ? text : converetedT2S;
  } else {
    return isSimplified ? convertedS2T : text;
  }
};
