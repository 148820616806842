import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Chinese from "chinese-s2t";
import OpenCC from "node-opencc";
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded";

const StyledQuestion = styled.h4`
  font-weight: 700;
  text-align: center;
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.5vw;
  @media (orientation: portrait) {
    font-size: 5vw;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 1vw;
  @media (orientation: portrait) {
    width: 90vw;
    margin-bottom: 5vw;
  }
`;

const StyledImage = styled.img`
  @media (orientation: landscape) {
    height: 25vw;
    width: 25vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 90vw;
  }
  margin: 5;
`;

const KeyWord = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 calc(25% - 1vw);
  padding-left: 0.25vw;
  padding-right: 0.25vw;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
  @media (orientation: portrait) {
    justify-content: center;
    text-align: center;
    padding-top: 5vw;
    padding-bottom: 5vw;
    width: 90vw;
  }
`;
const English = styled.div`
  display: flex;
  flex: 0 0 calc(45% - 1vw);
  padding-left: 0.25vw;
  padding-right: 0.25vw;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
  @media (orientation: portrait) {
    flex-warp: warp;
    justify-content: center;
    text-align: center;
    padding-top: 2.5vw;
    padding-bottom: 2.5vw;
    width: 90vw;
    background: lightblue;
  }
`;

const KeyWordContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80vw;
  font-size: 2vmax;
  @media (orientation: landscape) {
    ${KeyWord}:nth-child(odd) {
      background: lightblue;
      border-radius: 0.25vw;
    }

    ${KeyWord}:nth-child(even) {
    }
  }
  @media (orientation: portrait) {
    width: 90vw;
    text-align: center;
    font-size: 5.5vmax;
    flex-direction: column;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  flex-wrap: wrap;
  @media (orientation: portrait) {
    width: 100vw;
  }
`;

function KeyWords({ question, choices, mainimg, nextLink, simplified }) {
  const [toggleImage, setToggleImage] = useState(false);
  const [toggleEnglish, setToggleEnglish] = useState(false);
  const [togglePinyin, setTogglePinyin] = useState(false);
  const [current, setCurrent] = useState(0);
  const [currentAudio, setCurrentAudio] = useState();

  const landscape =
    process.browser && window.matchMedia("(orientation: landscape)").matches;

  /*
   * If mobilemode:
   *   we will just render next word
   * else :
   *   go to next task
   */
  const handleNext = () => {
    if (landscape || current === choices.length - 1) {
      window.location.href = nextLink;
    } else {
      setCurrent(current + 1);
    }
  };

  const playAudio = (item) => {
    if (currentAudio !== undefined) currentAudio.pause();
    const sound = new Audio(item);
    setCurrentAudio(sound);
    sound.play();
  };

  return (
    <React.Fragment>
      <QuestionContainer>
        <StyledQuestion>
          {simplified ? OpenCC.traditionalToSimplified(question) : question}
        </StyledQuestion>
      </QuestionContainer>
      <ButtonContainer>
        <Button
          style={{ margin: 5 }}
          variant="contained"
          color="secondary"
          onClick={() => setTogglePinyin(!togglePinyin)}
        >
          {togglePinyin ? "Hide Pinyin" : "Show Pinyin"}
        </Button>
        <Button
          style={{ margin: 5 }}
          variant="contained"
          color="secondary"
          onClick={() => setToggleEnglish(!toggleEnglish)}
        >
          {toggleEnglish ? "Hide English" : "Show English"}
        </Button>
      </ButtonContainer>
      {!mainimg ? null : (
        <Button
          style={{ margin: 5 }}
          variant="contained"
          color="secondary"
          onClick={() => setToggleImage(!toggleImage)}
        >
          {toggleImage ? "Hide Image" : "Show Image"}
        </Button>
      )}
      {toggleImage ? <StyledImage alt="loading..." src={mainimg} /> : null}
      <StyledContainer>
        {choices.map((choice, index) => {
          if (landscape) {
            return (
              <KeyWordContainer>
                <KeyWord onClick={() => playAudio(choice.audio)}>
                  {simplified
                    ? OpenCC.traditionalToSimplified(choice.traditional)
                    : choice.traditional}
                  {choice.audio ? (
                    <VolumeUpRoundedIcon key={Math.random()} fontSize="large" />
                  ) : null}
                </KeyWord>
                {togglePinyin ? (
                  <KeyWord style={{ fontFamily: "times new roman" }}>
                    {choice.pinyin}
                  </KeyWord>
                ) : (
                  <KeyWord />
                )}
                {toggleEnglish ? (
                  <English>{choice.english}</English>
                ) : (
                  <English />
                )}
              </KeyWordContainer>
            );
          }
          if (current === index && !landscape) {
            return (
              <KeyWordContainer>
                <KeyWord
                  style={{ background: "lightblue" }}
                  onClick={() => playAudio(choice.audio)}
                >
                  {simplified
                    ? OpenCC.traditionalToSimplified(choice.traditional)
                    : choice.traditional}
                  {choice.audio ? (
                    <VolumeUpRoundedIcon
                      key={Math.random()}
                      fontSize="medium"
                    />
                  ) : null}
                </KeyWord>
                {togglePinyin ? (
                  <KeyWord style={{ fontFamily: "times new roman" }}>
                    {choice.pinyin}
                  </KeyWord>
                ) : null}
                {toggleEnglish ? <English>{choice.english}</English> : null}
              </KeyWordContainer>
            );
          }
        })}
      </StyledContainer>
      <Button
        style={{ alignSelf: "center", marginTop: 10 }}
        variant="contained"
        color="secondary"
        onClick={() => handleNext()}
      >
        Next
      </Button>
    </React.Fragment>
  );
}

export default KeyWords;
