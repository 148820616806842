import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import MODULES from "../models";
import VideoPlayer from "./VideoPlayer";
import styled from "styled-components";

const VideoContainer = styled.video`
  width: 50vw;
  @media (orientation: portrait) {
    width: 300px;
  }
`;

export default function VideoPage({
  match: {
    params: { moduleId, videoId },
  },
  simplified,
}) {
  const {
    [moduleId]: {
      [videoId]: {
        title,
        video: { src, captionSrc, captionSrcT },
        tasks: {
          0: { url },
        },
      },
    },
  } = MODULES;
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <div className="content-title">
        <h4>{title}</h4>
      </div>
      <div className="content-video">
        <React.Fragment key={src}>
          <div>
            {" "}
            <VideoContainer controls preload="metadata" height="auto">
              <source src={src} type="video/mp4" />
              <track
                default
                label="English"
                kind="subtitles"
                srcLang="en"
                src={simplified ? captionSrc : captionSrcT}
              />
              <track
                default
                label="English"
                kind="captions"
                srcLang="en"
                src={simplified ? captionSrc : captionSrcT}
              />
            </VideoContainer>
          </div>
        </React.Fragment>
      </div>
      <Link
        to={url}
        moduleId={moduleId}
        style={{
          textDecoration: "none",
        }}
      >
        <Button variant="contained" color="primary">
          Go to tasks
        </Button>
      </Link>
    </div>
  );
}
