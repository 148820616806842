/* eslint-disable camelcase */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const CHENGYU = require("../data/chengyu");

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#3399ff",
      main: "#0073e6",
      dark: "#004d99",
      contrastText: "#fff",
    },
  },
});

export default function ChengyuTopicPage({
  match: {
    params: { tradId, chengyuTopic },
  },
}) {
  const phrases = CHENGYU.default[chengyuTopic];

  const half = Math.floor(CHENGYU.default[chengyuTopic].length / 2);

  const topics_dict = {
    nature: ["自然", "自然"],
    numbers: ["数字", "數字"],
    animals: ["动物", "動物"],
    human: ["人、器官", "人、器官"],
    antonyms: ["反义词", "反義詞"],
    measurements: ["量词", "量詞"],
    pos_connotation: ["积极含意", "積極含意"],
    neg_connotation: ["消极含意", "消極含意"],
  };

  return (
    <div className="module-menu">
      {tradId === "1" ? (
        <div
          style={{
            color: "#333333",
            fontFamily: "testFont,sans-serif",
            fontSize: "20px",
          }}
        >
          繁體 / {topics_dict[chengyuTopic][1]}{" "}
        </div>
      ) : (
        <div
          style={{
            color: "#333333",
            fontFamily: "testFont,sans-serif",
            fontSize: "20px",
          }}
        >
          简体 / {topics_dict[chengyuTopic][0]}
        </div>
      )}
      <center>
        <Grid container spacing={0}>
          <Grid item xs={2} />
          <Grid item xs={2} />
          <Grid item xs={2}>
            <MuiThemeProvider theme={theme}>
              {Object.keys(phrases).map(
                (phrase, index) =>
                  index <= half && (
                    <div>
                      <Link
                        style={{ marginLeft: "20px", textDecoration: "none" }}
                        to={`/chengyu/1/group/${chengyuTopic}/vocabulary/${index}/${tradId}`}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ marginLeft: "20px" }}
                        >
                          {tradId == 0
                            ? phrases[index].chengyu
                            : phrases[index].tradchengyu}
                        </Button>
                      </Link>

                      <br />
                      <br />
                    </div>
                  )
              )}
            </MuiThemeProvider>
          </Grid>

          <Grid item xs={2}>
            <MuiThemeProvider theme={theme}>
              {Object.keys(phrases).map(
                (phrase, index) =>
                  index > half && (
                    <div>
                      <Link
                        style={{ marginLeft: "20px", textDecoration: "none" }}
                        to={`/chengyu/1/group/${chengyuTopic}/vocabulary/${index}/${tradId}`}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ marginLeft: "20px" }}
                        >
                          {tradId === 0
                            ? phrases[index].chengyu
                            : phrases[index].tradchengyu}
                        </Button>
                      </Link>

                      <br />
                      <br />
                    </div>
                  )
              )}
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2} />
        </Grid>

        <MuiThemeProvider theme={theme}>
          <Button
            href={`/chengyu/1/${tradId}/${chengyuTopic}/exercises`}
            variant="outlined"
            color="primary"
          >
            Exercises
          </Button>{" "}
          <br /> <br />
          <Button
            href={`/chengyu/1/${tradId}`}
            variant="outlined"
            color="primary"
          >
            Back Home
          </Button>
        </MuiThemeProvider>
      </center>
    </div>
  );
}
