import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

class StepBar extends React.PureComponent {
  static getNumericLabels(n) {
    let i;
    const labels = [];

    if (!n) {
      return labels;
    }

    for (i = 0; i < n; i++) {
      labels.push(i);
    }

    return labels;
  }

  render() {
    const {
      activeStep = 0,
      nbSteps = activeStep,
    } = this.props;

    if (!nbSteps) {
      return null;
    }

    const steps = StepBar.getNumericLabels(nbSteps);

    return (
      <div
        style={{
          width: '90%',
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
}

export default StepBar;
