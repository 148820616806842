import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import Choice from "../Choice";
import OpenCC from "node-opencc";

const StyledQuestion = styled.h1`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  @media (orientation: portrait) {
    font-size: 5vw;
  }
`;

const AnswerChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1;
  text-align: center;
`;

const StyledImage = styled.img`
  display: flex;
  object-fit: contain;
  width: 20vw;
  @media (orientation: portrait) {
    width: 90vw;
    height: 60vh;
  }
  margin: 5;
`;

function MultipleChoiceSound({
  question,
  choices,
  answerKey,
  nextLink,
  mainAudio,
  mainimg,
  simplified,
  section,
}) {
  const [answerArray, setAnswerArray] = useState([]);
  const [selectedArray, setSelectedArray] = useState([]);
  const [key, setKey] = useState([]);
  const [status, setStatus] = useState("Check");

  const reset = useCallback(() => {
    shuffle();
    setSelectedArray([...choices].fill(false));
  });

  useEffect(() => reset(), [choices]);

  const shuffle = () => {
    const array = choices;
    const array2 = answerKey;
    let i = array.length;
    let k;
    let temp;
    while (--i > 0) {
      k = Math.floor(Math.random() * (i + 1));
      temp = array[k];
      array[k] = array[i];
      array[i] = temp;
      temp = array2[k];
      array2[k] = array2[i];
      array2[i] = temp;
    }
    setAnswerArray(array);
    setKey(array2);
  };
  const addLineBreaks = (string) =>
    string.split("\n").map((text) => (
      <React.Fragment key={Math.random()}>
        {simplified ? OpenCC.traditionalToSimplified(text) : text}
        <br />
      </React.Fragment>
    ));

  const handleClick = (index) => {
    if (status !== "Retry")
      setSelectedArray(
        [...selectedArray].map((answer, i) => (i === index ? !answer : answer))
      );
  };

  const handleButton = () => {
    switch (status) {
      case "Check":
        answerKey.join() === selectedArray.join()
          ? setStatus("Next")
          : setStatus("Retry");
        break;
      case "Retry":
        reset();
        setStatus("Check");
        break;
      case "Next":
        window.location.href = nextLink;
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <h2></h2>
      <StyledQuestion>
        {section && `Section ${section}: `}
        {addLineBreaks(question)}
      </StyledQuestion>
      <AnswerChoiceContainer
        style={{
          width:
            process.browser &&
            window.matchMedia("(orientation: landscape)").matches
              ? "50%"
              : "100%",
        }}
      >
        {mainimg && <StyledImage alt="loading..." src={mainimg} />}
        {mainAudio && (
          <audio controls style={{ padding: "5px" }}>
            <source src={mainAudio} />
          </audio>
        )}
        {answerArray.map((choice, index) => {
          const { text, audio } = choice;
          return (
            <Choice
              key={index}
              errors={
                status !== "Check" && !selectedArray.filter((x) => !!x).length
                  ? false
                  : status !== "Check"
                  ? selectedArray[index] === answerKey[index]
                  : "none"
              }
              text={text}
              selected={selectedArray[index] === true}
              audio={audio}
              handleClick={() => handleClick(index)}
            />
          );
        })}
        <Button
          style={{ margin: "5px" }}
          variant="contained"
          color="secondary"
          onClick={() => handleButton()}
        >
          {status}
        </Button>
      </AnswerChoiceContainer>
    </React.Fragment>
  );
}

export default MultipleChoiceSound;
