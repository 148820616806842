import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip, Button } from "@material-ui/core";
import Chinese from "chinese-s2t";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
  font-size: 20px;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

function Literacy({
  question,
  choices,
  audio,
  simplified,
  nextLink,
  path,
  taskSpecificParams,
}) {
  const [selectedTip, setSelectedTip] = useState(null);
  const [english, setEnglish] = useState(false);
  const [pinyin, setPinyin] = useState(false);
  const [currentAudio, setCurrentAudio] = useState();
  let trackPlaying = 1;
  const play = (id) => {
    if (currentAudio !== undefined) currentAudio.pause();
    if (taskSpecificParams !== undefined) {
      if (taskSpecificParams.audioFiles) {
        
        const audioFile = new Audio(taskSpecificParams.audioFiles[id - 1]);
        console.log(audioFile)
        setCurrentAudio(audioFile);
        audioFile.play();
      }
    } else {
      const audioFile = new Audio(`${path}s${id}.mp3`);
      setCurrentAudio(audioFile);
      audioFile.play();
    }
  };

  const parseSentence = (sentence, tooltips) => {
    const elementArray = [];
    let stringBuilder = "";
    let open = false;
    let counter = 0;
    for (let i = 0; i < sentence.length; i++) {
      if (sentence[i] !== "*") {
        stringBuilder += sentence[i];
      } else {
        if (open) {
          const temp = trackPlaying;
          elementArray.push(
            <Tooltip
              title={
                <span style={{ fontSize: 15, fontFamily: "arial" }}>
                  {tooltips[counter]}
                </span>
              }
              open={selectedTip === temp}
              placement="right"
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button
                onClick={() => {
                  setSelectedTip(temp === selectedTip ? "" : temp);
                  play(temp);
                }}
              >
                <span style={{ textDecoration: "underline", fontSize: 19 }}>
                  {simplified ? Chinese.t2s(stringBuilder) : stringBuilder}
                </span>
              </Button>
            </Tooltip>
          );
          trackPlaying++;
          counter++;
        } else {
          elementArray.push(
            <span>
              {simplified ? Chinese.t2s(stringBuilder) : stringBuilder}
            </span>
          );
        }
        open = !open;
        stringBuilder = "";
      }
    }
    if (stringBuilder !== "") elementArray.push(<span>{stringBuilder}</span>);
    return elementArray;
  };

  return (
    <React.Fragment>
      <StyledQuestion>{question}</StyledQuestion>

      {audio && (
        <audio controls>
          <source src={audio} type="audio/mp3" />
        </audio>
      )}

      {choices[0]["English"] && (
        <p>
          <Button
            variant="contained"
            onClick={() => setEnglish(!english)}
            color="secondary"
          >
            English
          </Button>
          &nbsp; &nbsp; &nbsp;
          <Button
            variant="contained"
            onClick={() => setPinyin(!pinyin)}
            color="secondary"
          >
            Pinyin
          </Button>
          &nbsp; &nbsp; &nbsp;
        </p>
      )}

      <StyledContainer>
        {choices.map((choice, index) => (
          <div>
            <section
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 10,
                backgroundColor: index % 2 === 0 ? "lightblue" : "#eee",
                borderRadius: 10,
              }}
            >
              <span>
                {parseSentence(
                  Object.values(choice)[0],
                  Object.values(choice)[1]
                )}
                <br />
                {english && (
                  <span style={{ fontSize: 15 }}>{choice.English}</span>
                )}
                {english && <br />}
                {pinyin && (
                  <span style={{ fontSize: 15, fontFamily: "times new roman" }}>
                    {choice.Pinyin}
                  </span>
                )}
              </span>
            </section>
          </div>
        ))}
      </StyledContainer>

      <Button
        style={{ margin: 5, marginTop: 10 }}
        variant="contained"
        color="secondary"
        onClick={() => (window.location.href = nextLink)}
      >
        Next
      </Button>
    </React.Fragment>
  );
}

export default Literacy;
