import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Modal, Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down('sm')]: {
      width: 200,
    }
  },
  centerItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  spaceRight: {
    marginRight: theme.spacing(1),
  },
  bookmarkLink: {
    fontWeight: 'normal',
    fontSize: "1.75rem",
    textDecoration: 'none',
    color: 'black',
    "&:hover": {
      color: "blue",
      textDecoration: 'none',
    }
  },
  deleteIcon: {
    color: 'black',
    '&:hover': {
      cursor: 'pointer',
      color: 'red',
    }
  }
}));

const BookmarkTable = ({ module, simptrad, bookmarks, removeBookmark }) => {
  const classes = useStyles();
  const [open, setOpen] = useState([]);

  useEffect(() => {
    setOpen(Array(bookmarks.length).fill(false));
  }, [bookmarks.length]);

  const handleOpen = index => {
    let newArr = [...open];
    newArr[index] = true;
    setOpen(newArr);
  };

  const handleClose = index => {
    let newArr = [...open];
    newArr[index] = false;
    setOpen(newArr);
  };


  return (
    <div className={classes.root}>
      {bookmarks.length > 0 ?
        <table style={{ borderSpacing: "0px" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid black", borderRight: "0px" }}>&nbsp;</th>
              <th style={{ border: "1px solid black", borderRight: "0px", fontSize: '1.1rem' }}>{module === "chengyu" ? "Chengyu 2" : "A Trip to China"}</th>
              <th style={{ border: "1px solid black" }}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {
              bookmarks.map((cell, index) => {
                return (
                  <tr key={index}>
                    <th style={{ padding: "0px 10px", border: "1px solid black", borderRight: "0px", borderTop: "0px" }}><span className={classes.centerItems}>{index + 1}.</span></th>
                    <th style={{ padding: "0px 20px", border: "1px solid black", borderRight: "0px", borderTop: "0px" }}>
                      <Link className={classes.bookmarkLink} target="_blank" rel="noopener noreferrer" href={cell.url}>{cell[module][simptrad]}</Link>
                    </th>
                    <th style={{ padding: "0px 10px", border: "1px solid black", borderTop: "0px" }}>
                      <Modal
                        className={classes.modal}
                        open={open[index]}
                        onClose={() => handleClose(index)}
                        aria-labelledby="delete-modal-title"
                      >
                        <div className={classes.paper}>
                          <h3 id="delete-modal-title">Confirm Delete?</h3>
                          <Button className={classes.spaceRight} variant="contained" color="secondary" onClick={() => removeBookmark(cell.url)}>Yes</Button>
                          <Button variant="contained" onClick={() => handleClose(index)}>No</Button>
                        </div>
                      </Modal>
                      <DeleteIcon className={`${classes.centerItems} ${classes.deleteIcon}`} onClick={() => handleOpen(index)} />
                    </th>
                  </tr>
                )
              })
            }
          </tbody>
        </table> : 
        <div style={{fontSize: "1.5rem", fontWeight: "bold"}}>
          No Bookmarks Found
        </div>}
    </div>
  )
};

export default BookmarkTable;