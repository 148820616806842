import React, { useCallback, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import MultipleChoice from "./templates/MultipleChoice";
import Questions from "../data/questions";
import MultipleAnsMultipleChoice from "./MultipleAnsMultipleChoice";
import Rearrange from "./templates/Rearrange";
import GridAnswer from "./templates/GridAnswer";
import FillBlank from "./templates/FillBlank";
import DropDown from "./templates/DropDown";
import Literacy from "./templates/Literacy";
import Links from "./templates/Link";
import DragRadicals from "./templates/ClickRadicals";
import DragTest from "./templates/DragNumerPad";
import WordRecognition from "./WordRecognition";
import Analysis from "./templates/Analysis";
import MultipleChoiceSound from "./templates/MultipleChoiceSound";
import ArrangeAudio from "./templates/ArrangeAudio";
import DragRadical from "./templates/DragRadical";
import LineMatching from "./templates/LineMatching";
import GroceryShopping from "./templates/GroceryShopping";
import Recording from "./templates/Recording";
import RearrangeChinese from "./templates/RearrangeChinese";
import AudioMultipleChoice from "./templates/AudioMultipleChoice";
import AnalysisLearning from "./templates/AnalysisLearning";
import Directions from "./templates/Directions";
import Interactive from "./templates/Interactive";
import WordPuzzle from "./templates/WordPuzzle";
import DialogueDrag from "./templates/DialogueDrag";
import MODULES from "../models";
import VideoPlayer from "./VideoPlayer";
import MultiSingle from "./templates/MultiSingle";
import MultiSelect from "./templates/MultiSelect";
import GridSound from "./templates/GridSound";
import KeyWords from "./templates/KeyWords";
import FillBlankLiteracy from "./templates/FillBlankLiteracy";
import Numbers from "./templates/LearnNumbers";
import Ordering from "./templates/Ordering";
import { translate } from "../helpers/translate.jsx";
import { transform, isObject, cloneDeep } from "lodash";
import Chinese from "chinese-s2t";
import { simplifiedToHongKong } from "node-opencc";

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  .btn-retry {
    margin-right: 25px;
  }
  align-items: center;
`;

const ErrorContainer = styled.div`
  color: red;
  font-size: 20px;
`;

const ClickableText = styled.div`
  color: #2b758a;
  font-size: 15px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
`;

const Path = styled.div`
  align-self: baseline;
`;
// ANY TYPE OF QUESTION IN A TASK, WITH AMOUNT OF QUESTION

export default function TaskPage({
  match: {
    params: { moduleId, videoId, taskId },
  },
  simplified,
}) {
  const {
    [moduleId]: {
      [videoId]: {
        [taskId]: {
          q,
          altQ,
          altChoices,
          type,
          help,
          choices,
          simplifiedChoices,
          img,
          simpImage,
          audio,
          links,
          text,
          path,
          audios,
          mainAudio,
          task,
          audioD,
          images,
          answers,
          answersS,
          answerKey,
          mainAudioSubtext,
          taskSpecificParams,
          section,
        },
      },
    },
  } = Questions;
  const {
    [moduleId]: {
      [videoId]: {
        title,
        video: { src, captionSrc, captionSrcT },
        tasks: {
          0: { url },
        },
      },
    },
  } = MODULES;

  const tempChecks = [];
  for (let i = 0; i < choices.length; i++) {
    tempChecks[i] = false;
  }

  const answerArray = [];
  if (type !== "dragtest" && type !== "analysis")
    choices.map((item) =>
      answerArray.push(item.isCorrect ? item.isCorrect : false)
    );

  const [correct, setCorrect] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showAltQ, setShowAltQ] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [buttonMsg, setButtonMsg] = useState("Check");
  const [errorMsg, setErrorMsg] = useState("");
  const [video, setVideo] = useState(false);
  const [helpMe, setHelpMe] = useState(false);
  const [transChoices, setTransChoices] = useState(choices);

  const onShowAnswer = useCallback(
    (event, selectedChoice) => {
      setSelectedAnswer(selectedChoice);
      if (answerArray[selectedChoice]) setCorrect(true);
      setShowAnswer(true);
    },
    [setSelectedAnswer, setShowAnswer]
  );

  const onRetry = useCallback(() => {
    window.location.reload();
    setButtonMsg("Check");
  });

  const handleChange = useCallback(({ index }) => {
    tempChecks[index] = !tempChecks[index];
  });

  const [error] = useState("");

  const checkAnswer = () => {
    if (answerArray.join() === tempChecks.join()) {
      setShowAnswer(true);
      setCorrect(true);
      setErrorMsg("");
    } else {
      setErrorMsg("Please select all of the correct answers.");
      setButtonMsg("Retry");
    }
  };

  function refreshPage() {
    window.location.reload();
  }

  const handleAnswer = () => {
    if (buttonMsg === "Check") {
      checkAnswer();
    } else {
      refreshPage();
    }
  };

  let nextLink = "";
  const lenQuestions = Object.keys(Questions[moduleId][videoId]).length;
  if (taskId < lenQuestions - 1) {
    nextLink = `/modules/${moduleId}/${videoId}/${parseInt(taskId, 10) + 1}`;
  } else {
    // check if there are videos left to do in this module
    // assume each video has at least one task
    const lenVideos = Object.keys(Questions[moduleId]).length;
    // go to next video if it exists
    if (videoId < lenVideos - 1)
      nextLink = `/modules/${moduleId}/${parseInt(videoId, 10) + 1}`;
    // if its the last video in the module, go to module page (or homepage)
    else nextLink = `/modules/${moduleId}`; // or just '/', either makes sense
  }

  let moduleName = "";
  if (parseInt(moduleId[moduleId.length - 1])) {
    moduleName = `${moduleId[0].toUpperCase() + moduleId.substring(1, moduleId.length - 1)
      } ${parseInt(moduleId[moduleId.length - 1])}`;
  } else {
    moduleName = `${moduleId[0].toUpperCase() + moduleId.substring(1)}`;
  }

  /*translate function only accepts string,
   if first element is a nested array, then the rest of the elements are also nested 
  */
  var transAltQ = "";

  if (altQ) {
    transAltQ = altQ.length === 1 ? translate(altQ[0], simplified) :
      (Array.isArray(altQ[0]) ?
        altQ.map(subarray => subarray.map(item => translate(item, simplified)))
        : (altQ ? altQ.map(item => translate(item, simplified)) : null));
  } else {
    transAltQ = null;
  }

  const transQ = simplified ? Chinese.s2t(q) : q;
  const displayQuestion = altQ ? (showAltQ ? transAltQ : transQ) : q;
  const displayChoices = showAltQ && altChoices ? altChoices : choices;
  const displayImg = simplified && simpImage ? simpImage : img;

  useEffect(() => {
    setTransChoices(
      choices.map((choice) => {
        const trans = cloneDeep(choice);
        if (choice.text) {
          if (Array.isArray(choice.text) && choice.text.length > 1) {
            trans.text = choice.text.map(item => translate(item, simplified));
          } else if (Array.isArray(choice.text) && choice[text].length === 1) {
            trans.text = translate(choice.text[0], simplified);
          } else {
            trans.text = translate(choice.text, simplified);
          }
        }
        if (choice.subChoices) {
          trans.subChoices = choice.subChoices.map((sub) =>
            typeof sub === "string"
              ? translate(sub, simplified)
              : translate(sub.text, simplified)
          );
        }
        return trans;
      })
    );
  }, [simplified]);

  let module = <div />;
  switch (type) {
    case "single":
      module = (
        <MultipleChoice
          simplified={simplified}
          choices={transChoices}
          question={displayQuestion}
          mainaudio={mainAudio}
          audio={audio}
          mainimg={displayImg}
          text={text}
          nextLink={nextLink}
          audioD={audioD}
        />
      );
      break;
    case "multiple":
      module = (
        <MultipleAnsMultipleChoice
          simplified={simplified}
          showAnswer={showAnswer}
          choices={transChoices}
          question={translate(q, simplified)}
          handleChange={handleChange}
          checks={tempChecks}
          error={error}
          img={img}
          mainaudio={mainAudio}
        />
      );
      break;
    case "rearrange":
      module = (
        <Rearrange
          choices={choices}
          question={q}
          nextLink={nextLink}
          simplified={simplified}
          mainAudio={mainAudio}
        />
      );
      break;
    case "fill":
      module = (
        <FillBlank
          choices={
            simplified && simplifiedChoices ? simplifiedChoices : choices
          }
          question={q}
          showAnswer={() => setCorrect(true)}
          simplified={simplified}
          mainaudio={mainAudio}
        />
      );
      break;
    case "dropdown":
      module = (
        <DropDown
          simplified={simplified}
          choices={choices}
          question={q}
          showAnswer={() => setCorrect(true)}
          answers={answers}
          answersS={answersS}
        />
      );
      break;
    case "literacy":
      module = (
        <Literacy
          choices={choices}
          question={q}
          audio={audio}
          simplified={simplified}
          nextLink={nextLink}
          path={path}
          taskSpecificParams={taskSpecificParams}
        />
      );
      break;
    case "grid":
      module = (
        <GridAnswer
          simplified={simplified}
          choices={choices}
          question={q}
          images={images}
          answers={answers}
          showAnswer={() => setCorrect(true)}
        />
      );
      break;
    case "dragradicals":
      module = (
        <DragRadicals
          simplified={simplified}
          choices={choices}
          question={q}
          images={images}
          answers={answers}
          audio={audio}
          showAnswer={() => setCorrect(true)}
          taskSpecificParams={taskSpecificParams}
        />
      );
      break;
    case "dragtest":
      module = (
        <DragTest
          question={q}
          choices={choices}
          answers={answers}
          answerKey={answerKey}
          audio={audio}
          help={help}
          simplified={simplified}
          links={links}
        />
      );
      break;
    case "link":
      module = (
        <Links
          choices={choices}
          question={q}
          text={text}
          audio={audio}
          simplified={simplified}
          link={links}
          help={helpMe}
        />
      );
      break;
    case "word recognition":
      module = (
        <WordRecognition
          showAnswer={showAnswer}
          choices={choices}
          question={q}
          answerKey={answerKey}
          nextLink={nextLink}
          simplified={simplified}
        />
      );
      break;
    case "analysis":
      module = (
        <Analysis
          choices={choices}
          question={q}
          nextLink={nextLink}
          simplified={simplified}
        />
      );
      break;
    case "multipleSound":
      module = (
        <MultipleChoiceSound
          simplified={simplified}
          question={displayQuestion}
          choices={displayChoices}
          answerKey={answerKey}
          nextLink={nextLink}
          mainAudio={mainAudio}
          mainimg={img}
          section={section}
        />
      );
      break;
    case "arrange-audio-clips":
      module = (
        <ArrangeAudio
          question={displayQuestion}
          choices={choices}
          answerKey={answerKey}
          mainaudio={mainAudio}
          nextLink={nextLink}
          simplified={simplified}
        />
      );
      break;
    case "dragRadical":
      module = (
        <DragRadical
          question={q}
          choices={choices}
          answers={answers}
          simplified={simplified}
        />
      );
      break;
    case "line matching":
      module = (
        <LineMatching
          question={q}
          choices={choices}
          nextLink={nextLink}
          language={simplified ? "simplified" : "traditional"}
        />
      );
      break;
    case "grocery shopping":
      module = (
        <GroceryShopping
          question={q}
          video={mainAudio}
          choices={choices}
          answerKey={answerKey}
          nextLink={nextLink}
          simplified={simplified}
        />
      );
      break;
    case "recording":
      module = (
        <Recording
          question={displayQuestion}
          choices={choices}
          mainimg={displayImg}
          mainaudio={mainAudio}
          nextLink={nextLink}
          video={audio}
          simplified={simplified}
        // altQ={altQ}
        />
      );
      break;
    case "rearrange chinese":
      module = (
        <RearrangeChinese
          question={q}
          choices={choices}
          mainAudio={mainAudio}
          nextLink={nextLink}
          simplified={simplified}
          taskSpecificParams={taskSpecificParams}
        />
      );
      break;
    case "Audio MultipleChoice":
      module = (
        <AudioMultipleChoice
          choices={choices}
          question={displayQuestion}
          mainaudio={mainAudio}
          mainimg={img}
          text={text}
          nextLink={nextLink}
          simplified={simplified}
          mainAudioSubtext={mainAudioSubtext}
          taskSpecificParams={taskSpecificParams}
          help={helpMe}
        />
      );
      break;
    case "analysis-learning":
      module = (
        <AnalysisLearning
          choices={choices}
          question={q}
          nextLink={nextLink}
          simplified={simplified}
        />
      );
      break;
    case "directions":
      module = (
        <Directions
          choices={choices}
          audios={audios}
          question={displayQuestion}
          img={displayImg}
          answerKey={answerKey}
          nextLink={nextLink}
          simplified={simplified}
        />
      );
      break;
    case "interactive":
      module = (
        <Interactive
          question={displayQuestion}
          choices={displayChoices}
          img={img}
          altQ={altQ}
          nextLink={nextLink}
          simplified={simplified}
        />
      );
      break;
    case "word puzzle":
      module = (
        <WordPuzzle
          question={q}
          simplified={simplified}
          choices={choices}
          nextLink={nextLink}
        />
      );
      break;
    case "dialogue":
      module = (
        <DialogueDrag
          question={q}
          mainaudio={mainAudio}
          simplified={simplified}
          choices={choices}
          answers={answers}
          answersS={answersS}
          nextLink={nextLink}
        />
      );
      break;
    case "multi-single":
      module = (
        <MultiSingle
          choices={choices}
          question={displayQuestion}
          mainaudio={mainAudio}
          mainimg={img}
          text={text}
          nextLink={nextLink}
          simplified={simplified}
          taskSpecificParams={taskSpecificParams}
        />
      );
      break;
    case "multi-select":
      module = (
        <MultiSelect
          choices={choices}
          question={displayQuestion}
          mainaudio={mainAudio}
          mainimg={img}
          text={text}
          nextLink={nextLink}
          simplified={simplified}
          taskSpecificParams={taskSpecificParams}
        />
      );
      break;
    case "grid sound":
      module = (
        <GridSound
          choices={choices}
          question={q}
          mainimg={img}
          nextLink={nextLink}
          simplified={simplified}
          answerKey={answerKey}
          taskSpecificParams={taskSpecificParams}
        />
      );
      break;
    case "keywords":
      module = (
        <KeyWords
          choices={choices}
          question={q}
          mainimg={img}
          nextLink={nextLink}
          simplified={simplified}
        />
      );
      break;
    case "fill-literacy":
      module = (
        <FillBlankLiteracy
          choices={choices}
          question={q}
          mainimg={img}
          nextLink={nextLink}
          simplified={simplified}
        />
      );
      break;
    case "numbers":
      module = (
        <Numbers
          choices={choices}
          question={q}
          nextLink={nextLink}
          simplified={simplified}
        />
      );
      break;
    case "ordering":
      module = (
        <Ordering
          choices={transChoices}
          question={q}
          mainimg={img}
          nextLink={nextLink}
          simplified={simplified}
        />
      );
      break;
    default:
      module = null;
      break;
  }
  return (
    <QuestionContainer>
      <Path>
        {`${moduleName}/ Video ${parseInt(videoId) + 1}/ Task ${task ? task : parseInt(taskId) + 1
          }`}
      </Path>

      <div>
        {help && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setHelpMe(!helpMe)}
          >
            {helpMe ? "Hide" : "Show"} Help
          </Button>
        )}
        &nbsp;
        <Button
          variant="contained"
          color="primary"
          onClick={() => setVideo(!video)}
        >
          {video ? "Hide" : "Show"} Video
        </Button>
      </div>
      {altQ && (
        <ClickableText
          onClick={() => {
            setShowAltQ(!showAltQ);
          }}
        >
          View in different language
        </ClickableText>
      )}

      {video ? (
        <p>
          <VideoPlayer
            src={src}
            captionSrc={simplified ? captionSrc : captionSrcT}
          />
        </p>
      ) : (
        <></>
      )}
      {helpMe ? <div>{help}</div> : <></>}
      {module}
      {errorMsg && <ErrorContainer> {errorMsg}</ErrorContainer>}
      {!showAnswer && type === "multiple" && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleAnswer()}
        >
          {" "}
          {buttonMsg}{" "}
        </Button>
      )}
      {!correct && showAnswer && type === "single" && (
        <Button onClick={() => onRetry()}>Retry</Button>
      )}
      {correct && type !== "multipleSound" && (
        <a href={nextLink} style={{ textDecoration: "none" }}>
          <Button variant="contained" color="secondary">
            {" "}
            Next{" "}
          </Button>
        </a>
      )}
    </QuestionContainer>
  );
}
