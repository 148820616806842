import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

class ProblemSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionNumber: 0,
      problemSet: [],
      correctAnswers: 0,
      showScore: false,
      continue: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.correctAnswers !== this.props.correctAnswers) {
      this.setState({ correctAnswers: this.props.correctAnswers });
    }
  }
  allowNext = () => {
    this.setState({ continue: true });
  };
  showScore = () => {
    this.setState({ showScore: true });
  };

  updateScore = () => {
    this.setState({ correctAnswers: this.state.correctAnswers + 1 });
  };
  goNext = () => {
    const { questionNumber } = this.state;
    const { allProblems } = this.props;
    if (questionNumber + 1 < allProblems.length) {
      this.setState({ questionNumber: questionNumber + 1 });
    }
    this.setState({ continue: false });
  };
  goPrevious = () => {
    const { questionNumber } = this.state;
    if (questionNumber - 1 >= 0) {
      this.setState({ questionNumber: questionNumber - 1 });
    }
  };

  render() {
    const { allProblems } = this.props;
    // const { groupId, lesson } = this.props.match.params;
    // const returnRoute = `/chengyu/${lesson}/group/${groupId}/practice`;
    const currentQuestion = allProblems[this.state.questionNumber];

    return (
      <div>
        {this.state.showScore === true ? (
          <center style={styles.score}>
            You answered {this.state.correctAnswers}/ {allProblems.length}{" "}
            questions correctly.
          </center>
        ) : (
          <center>
            <h4 style={{ textAlign: "center" }}>
              Question {this.state.questionNumber + 1}/ {allProblems.length}
            </h4>
            {currentQuestion}
            {this.props.allowPrevious && (
              <button onClick={this.goPrevious}>Previous</button>
            )}
            {this.state.continue && (
              <div style={{ padding: "8px" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.goNext}
                >
                  Next
                </Button>
              </div>
            )}
            {this.state.questionNumber + 1 === allProblems.length ? (
              <button
                style={{ ...styles.submitButton, backgroundColor: "green" }}
                onClick={this.showScore}
              >
                View Score
              </button>
            ) : (
              <div></div>
            )}
          </center>
        )}
        <center style={{ marginTop: "20px" }}>
          {this.props.returnRoute && (
            <Link to={this.props.returnRoute}>
              <Button variant="contained" style={styles.submitButton}>
                Back to practices
              </Button>
            </Link>
          )}
        </center>
      </div>
    );
  }
}

export default ProblemSet;

const styles = {
  score: {
    fontSize: "3em",
    color: "#273B6F",
    fontWeight: 600,
  },
  submitButton: {
    backgroundColor: "blue",
    color: "white",
    opacity: "0.6",
    transition: "0.3s",
  },
};
