import React from 'react';
import {
  Link,
} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

export default function ModuleMenu({
  title,
  img,
  startUrl,
}) {
  return (
    <React.Fragment>
      <Card>
        <CardHeader
          title={(
            <Link
              to={startUrl}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                fontWeight: 'bold',
                fontSize: '0.8em',
              }}
            >
              {title}
            </Link>
          )}
        />
        <Link
          to={startUrl}
          style={{
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <CardMedia
            style={{
              paddingTop: '56.25%',
            }}
            image={img}
            title={title}
          />
        </Link>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link
              to={startUrl}
              style={{
                textDecoration: 'none',
              }}
            >
              <Button
                variant="contained"
                color="primary"
              >
              Start
              </Button>
            </Link>
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
