import React, { Component, useState } from "react";
import styled from "styled-components";
import SoundIcon from "./icons/SoundIcon";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";

const IconContainer = styled.div`
  padding: 12px;
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
`;

// stop all other audios
// I can make it pause and restart.
class PlayAudio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      audioFile: new Audio(this.props.path),
    };
  }

  play = () => {
    const { playing, audioFile } = this.state;
    if (audioFile.ended || !playing) {
      this.setState({ playing: true });
      audioFile.play();
    } else {
      audioFile.pause();
      audioFile.currentTime = 0; // We want to restart on click again
      this.setState({ playing: false });
    }
  };

  render() {
    return (
      <IconContainer onClick={this.play} style={this.props.audioStyle}>
        <VolumeUpIcon
          style={{
            fontSize: this.props.fontSize || 24,
          }}
        ></VolumeUpIcon>
      </IconContainer>
    );
  }
}

export default PlayAudio;
