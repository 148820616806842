import React from "react";
import styled from "styled-components";
import Label from "./Label";

const StyledContainer = styled.div`
  display: inline-block;
`;

const StyledQuestion = styled.h4`
  text-align-last: center;
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

const Audio = styled.audio`
  width: 200px;
  position: relative;
  left: 0%;
`;

const addLineBreaks = (string) =>
  string.split("\n").map((text) => (
    <React.Fragment>
      {text}
      <br />
    </React.Fragment>
  ));

function MultipleAnsMultipleChoice({
  question,
  mainaudio,
  choices,
  audio,
  checks,
  handleChange,
  showAnswer,
  img,
  simplified,
}) {
  return (
    <React.Fragment>
      <StyledQuestion>
        {addLineBreaks(question)}
        {mainaudio && (
          <Audio controls controlsList="nodownload" src={mainaudio} />
        )}
        {img && (
          <div>
            <br /> <br />
            <img alt="question depiction" src={img} />
          </div>
        )}
      </StyledQuestion>

      <StyledContainer>
        {choices.map((choice, index) => (
          <Label
            choice={choice}
            handleChange={handleChange}
            showAnswer={showAnswer}
            index={index}
            check={checks[index]}
            audio={audio}
          />
        ))}
      </StyledContainer>
    </React.Fragment>
  );
}

export default MultipleAnsMultipleChoice;
