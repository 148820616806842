import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded";
import styled from "styled-components";

const StyledContainer = styled.div`
  margin: 1.5em;
  color: #1e88e5;
  border: 3px solid #1e88e5;
  border-radius: 5px;
`;

const StyledText = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 7.25rem;
`;

const StyledSub = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.15rem;
`;

const StyledMainInfo = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 1.25rem;
  flex-direction: row;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.35em;
  margin-left: 0;
  margin-right: 0;
`;

const TextList = styled.li`
  display: flex;
  flex-wrap: wrap;
  font-size: 1.35rem;
  justify-content: center;
  align-items: center;
  font-family: times new roman;
`;

function Analysis({ question, choices, nextLink, simplified }) {
  const [index, setIndex] = useState(0);
  const [toggleStrokes, setStrokes] = useState(false);
  const [toggleRadical, setRadical] = useState(false);
  const [toggleKeyWord, setKeyWord] = useState(false);
  const [toggleEtymology, setEtymology] = useState(false);

  // const html = `
  // <html>
  //   <head>
  //       <title></title>
  //       <script src="https://cdn.jsdelivr.net/npm/hanzi-writer@2.0.2/dist/hanzi-writer.min.js"> </script>
  //   </head>
  //   <body>
  //       <div id="character-target-div"></div>
  //       <script type="application/javascript">
  //               var writer = HanziWriter.create('character-target-div', '味', {
  //                       width: 125,
  //                       height: 125,
  //                       padding: 5
  //               });
  //               writer.loopCharacterAnimation();
  //       </script>
  //   </body>
  //   <html>
  // `

  const handleNext = () => {
    if (index + 1 < choices.length) {
      setIndex(index + 1);
      setKeyWord(false);
      setRadical(false);
      setStrokes(false);
      setEtymology(false);
    } else {
      window.location.href = nextLink;
    }
  };

  const handlePrev = () => {
    if (index - 1 >= 0) {
      setIndex(index - 1);
    }
  };

  const playAudio = () => {
    const sound = new Audio(choices[index].audio);
    sound.play();
  };

  const handleStroke = () => setStrokes(!toggleStrokes);

  const handleRadical = () => setRadical(!toggleRadical);

  const handleKeyWord = () => setKeyWord(!toggleKeyWord);

  const handleEtymology = () => setEtymology(!toggleEtymology);

  const loadTraditionalKeyWord = () => {
    const keyWord =
      choices[index].wordT.charAt(0) + choices[index].wordT.charAt(1);
    const array = choices[index].wordT.substring(3).split(",");
    return (
      <StyledContainer>
        <StyledText>{keyWord}</StyledText>
        <StyledSub>{array[0]}</StyledSub>
        <StyledSub>{array[1].substring(1)}</StyledSub>
      </StyledContainer>
    );
  };

  const loadSimplifiedKeyWord = () => {
    const keyWord =
      choices[index].wordS.charAt(0) + choices[index].wordS.charAt(1);
    const array = choices[index].wordS.substring(3).split(",");
    return (
      <StyledContainer>
        <StyledText>{keyWord}</StyledText>
        <StyledSub>{array[0]}</StyledSub>
        <StyledSub>{array[1].substring(1)}</StyledSub>
      </StyledContainer>
    );
  };

  const loadEtymology = () => {
    const array = choices[index].etymology.split(".");
    array.pop();
    return (
      <ul>
        {array.map((item) => (
          <TextList>{item}.</TextList>
        ))}
      </ul>
    );
  };

  return (
    <React.Fragment>
      {/* <div key={index} dangerouslySetInnerHTML={{ __html: html }} /> */}
      <StyledQuestion>{question}</StyledQuestion>

      {simplified ? (
        // Simplified Section
        <div>
          <StyledText>{choices[index].simplified}</StyledText>

          <StyledSub>
            Pinyin: {choices[index].pinyin}
            {choices[index].audio ? (
              <VolumeUpRoundedIcon fontSize="large" onClick={playAudio} />
            ) : (
              <div />
            )}
          </StyledSub>
          <StyledSub>Meaning: {choices[index].meaning}</StyledSub>

          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
          >
            <Button
              style={{ margin: 5 }}
              variant="outlined"
              color="secondary"
              onClick={handleStroke}
            >
              Strokes
            </Button>
            {toggleStrokes ? (
              <div>
                <img
                  style={{ marginBottom: -20 }}
                  src={choices[index].strokeOrderS}
                  alt="loading..."
                />
                <StyledMainInfo>
                  Strokes: {choices[index].strokesS}
                </StyledMainInfo>
              </div>
            ) : (
              <div />
            )}

            <Button
              style={{ margin: 5 }}
              variant="outlined"
              color="secondary"
              onClick={handleRadical}
            >
              Radical
            </Button>
            {toggleRadical ? (
              <StyledMainInfo>{choices[index].radicalS}</StyledMainInfo>
            ) : (
              <div />
            )}
            <Button
              style={{ margin: 5 }}
              variant="outlined"
              color="secondary"
              onClick={handleKeyWord}
            >
              Key Word
            </Button>
            {toggleKeyWord ? loadSimplifiedKeyWord() : <div />}
            {choices[index].etymology && (
              <Button
                style={{ margin: 5 }}
                variant="outlined"
                color="secondary"
                onClick={handleEtymology}
              >
                Etymology
              </Button>
            )}
            {toggleEtymology ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {loadEtymology()}
                {choices[index].seal ? (
                  <img src={choices[index].seal} alt="loading..." />
                ) : (
                  <div />
                )}
              </div>
            ) : (
              <div />
            )}
          </Grid>
        </div>
      ) : (
        // Traditional Section
        <div>
          <StyledText>{choices[index].traditional}</StyledText>

          <StyledSub>
            Pinyin: {choices[index].pinyin}
            {choices[index].audio ? (
              <VolumeUpRoundedIcon fontSize="large" onClick={playAudio} />
            ) : (
              <div />
            )}
          </StyledSub>
          <StyledSub>Meaning: {choices[index].meaning}</StyledSub>

          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
          >
            <Button
              style={{ margin: 5 }}
              variant="outlined"
              color="secondary"
              onClick={handleStroke}
            >
              Strokes
            </Button>
            {toggleStrokes ? (
              <div>
                <img
                  style={{ marginBottom: -20 }}
                  src={choices[index].strokeOrderT}
                  alt="loading..."
                />
                <StyledMainInfo>
                  Strokes: {choices[index].strokesT}
                </StyledMainInfo>
              </div>
            ) : (
              <div />
            )}

            <Button
              style={{ margin: 5 }}
              variant="outlined"
              color="secondary"
              onClick={handleRadical}
            >
              Radical
            </Button>
            {toggleRadical ? (
              <StyledMainInfo>{choices[index].radicalT}</StyledMainInfo>
            ) : (
              <div />
            )}
            <Button
              style={{ margin: 5 }}
              variant="outlined"
              color="secondary"
              onClick={handleKeyWord}
            >
              Key Word
            </Button>
            {toggleKeyWord ? loadTraditionalKeyWord() : <div />}
            {choices[index].etymology && (
              <Button
                style={{ margin: 5 }}
                variant="outlined"
                color="secondary"
                onClick={handleEtymology}
              >
                Etymology
              </Button>
            )}
            {toggleEtymology ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {loadEtymology()}
                {choices[index].seal ? (
                  <img src={choices[index].seal} alt="loading..." />
                ) : (
                  <div />
                )}
              </div>
            ) : (
              <div />
            )}
          </Grid>
        </div>
      )}

      <div>
        {index - 1 < 0 ? (
          <div />
        ) : (
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: 5, marginTop: 20 }}
            onClick={handlePrev}
          >
            {" "}
            Previous{" "}
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          style={{ margin: 5, marginTop: 20 }}
          onClick={handleNext}
        >
          {" "}
          Next{" "}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default Analysis;
