export default {
  nature: [
    {
      chengyu: "种瓜得瓜",
      tradchengyu: "種瓜得瓜",
      pinyin: "zhong4 gua1 de2 gua1",
      pinyin2: "zhòng guā dé guā",
      chindefinition: "to plant melons and get melons",
      engdefinition: "to reap what one sows",
      sentences:
        "种瓜得瓜是一种自然规律，就像我们求学问一样，如果始终努力，将来一定有成就。",
      tradsent:
        "種瓜得瓜是一種自然規律，就像我們求學問一樣，如果始終努力，將來一定有成就。",
    },
    {
      chengyu: "胸有成竹",
      tradchengyu: "胸有成竹",
      pinyin: "xiong1 you3 cheng2 zhu2",
      pinyin2: "xiōng yǒu chéng zhú",
      chindefinition: "an image of bamboo in the mind",
      engdefinition:
        "to possess preconceived notions; to have had ready plans or designs in one's mind (in coping with a matter, situation, etc.)",
      sentences: "明天就考试了，你看他胸有成竹的样子，似乎是一点都不在乎。",
    },
    {
      chengyu: "青出于蓝",
      tradchengyu: "青出於藍",
      pinyin: "qing1 chu1 yu2 lan2",
      pinyin2: "qīng chū yú lán",
      chindefinition: "blue outshines indigo",
      engdefinition:
        "used figuratively in certain circumstances; to surpass ones master or teacher in learning",
      sentences:
        "如果别人称赞我的学生青出于蓝，我一点也不难过，因为可以证明我做老师是成功的。",
      tradsent: "明天就考試了，你看他胸有成竹的樣子，似乎是一點都不在乎",
    },
    {
      chengyu: "开门见山",
      tradchengyu: "開門見山",
      pinyin: "kai1 men2 jian4 shan1",
      pinyin2: "kāi mén jiàn shān",
      chindefinition: "opening the door, seeing the mountain",
      engdefinition:
        "frank and honest; straightforward; to speak or write straight to the point",
      sentences: "女主角对男主角说，你有什么话就开门见山地说，不要绕圈子。",
      tradsent: "女主角對男主角說，你有什麼話就開門見山地說，不要繞圈子",
    },
    {
      chengyu: "未雨绸缪",
      tradchengyu: "未雨綢繆",
      pinyin: "wei4 yu3 chou2 mou2",
      pinyin2: "wèi yǔ chóu móu",
      chindefinition: "to thatch a roof with mulberry root before it rains",
      engdefinition:
        "to prepare for a rainy day; to take protective measures in advance",
      sentences:
        "为了怕石油再度涨价，造成经济恐慌，世界各国都未雨绸缪，作了些必要的准备。",
      tradsent:
        "為了怕石油再度漲價，造成經濟恐慌，世界各國都未雨綢繆，作了些必要的準備。",
    },
    {
      chengyu: "听天由命",
      tradchengyu: "聽天由命",
      pinyin: "ting1 tian1 you2 ming4",
      pinyin2: "tīng tiān yóu mìng",
      chindefinition: "to submit to the will of heaven and one's fate",
      engdefinition: "resign oneself to the inevitable",
      sentences:
        "请最好的大夫，用最好的药给他治病，要是还治不好，那就只好听天由命了。",
      tradsent:
        "請最好的大夫，用最好的藥給他治病，要是還治不好，那就只好聽天由命了。",
    },
    {
      chengyu: "过河拆桥",
      tradchengyu: "過河拆橋",
      pinyin: "guo4 he2 chai1 qiao2",
      pinyin2: "guò hé chāi qiáo",
      chindefinition: "to destory the bridge after one has crossed the river",
      engdefinition:
        "very ungrateful; to discard a person after he has outlasted his usefulness",
      sentences:
        "以前他请我替他找工作的时候对我非常客气，后来替他找到了马上就过河拆桥不理我了。",
      tradsent:
        "以前他請我替他找工作的時候對我非常客氣，後來替他找到了馬上就過河拆橋不理我了。",
    },
    {
      chengyu: "人山人海",
      tradchengyu: "人山人海",
      pinyin: "ren2 shan1 ren2 hai3",
      pinyin2: "rén shān rén hǎi",
      chindefinition: "seas and mountains of people",
      engdefinition: "a mass of humanity; a large crowd; crowded conditions",
      sentences: "每到周末，电影院前总是人山人海。",
      tradsent: "每到週末，電影院前總是人山人海。",
    },
    {
      chengyu: "指桑骂槐",
      tradchengyu: "指桑罵槐",
      pinyin: "zhi3 sang1 ma4 huai2",
      pinyin2: "zhǐ sāng mà huái",
      chindefinition: "to point at the mulberry and course the locust tree",
      engdefinition:
        "to curse A but mean B; indirect abuse of a person; to make insinuations",
      sentences:
        "今天我又来晚了，老师看了我一眼就继续地跟我的同学说话，说他不应该起那么晚。我想老师很可能是在指桑骂槐，给我一点教训。",
      tradsent:
        "今天我又來晚了，老師看了我一眼就繼續地跟我的同學說話，說他不應該起那麼晚。我想老師很可能是在指桑罵槐，給我一點教訓。",
    },
  ],

  numbers: [
    {
      chengyu: "一举两得",
      tradchengyu: "一舉兩得",
      pinyin: "yi4 ju3 liang3 de2",
      pinyin2: "yì jǔ liǎng dé",
      chindefinition: "to acquire two by one lift",
      engdefinition:
        "to attain two objectives or gain two advantages by a single move; a double advantage",
      sentences:
        "我在台湾一面学习中文，一面了解中国的风俗习惯，可以说是一举两得。",
      tradsent:
        "我在台灣一面學習中文，一面了解中國的風俗習慣，可以說是一舉兩得。",
    },
    {
      chengyu: "一鸣惊人",
      tradchengyu: "一鳴驚人",
      pinyin: "yi4 ming2 jing1 ren2",
      pinyin2: "yì jǔ liǎng dé",
      chindefinition: "to startle people with one sound",
      engdefinition:
        "to become famous overnight; to achieve enormous success on the very first day",
      sentences:
        "王先生不爱说话，可是昨天有一鸣惊人的表现，一参加演讲比赛就得了第一。",
      tradsent:
        "王先生不愛說話，可是昨天有一鳴驚人的表現，一參加演講比賽就得了第一。",
    },
    {
      chengyu: "一劳永逸",
      tradchengyu: "一勞永逸",
      pinyin: "yi1 lao2 yong3 yi4",
      pinyin2: "yī láo yǒng yì",
      chindefinition:
        "to put forth great effort now in order to avoid future trouble",
      engdefinition:
        "to put forth great effort now in order to avoid future trouble",
      sentences:
        "你的胃病越来越严重，光打针吃药不是办法，最好是去动手术，才能一劳永逸。",
      tradsent:
        "你的胃病越來越嚴重，光打針吃藥不是辦法，最好是去動手術，才能一勞永逸。",
    },
    {
      chengyu: "一言为定",
      tradchengyu: "一言為定",
      pinyin: "yi1 yan2 wei2 ding4",
      pinyin2: "yī yán wéi dìng",
      chindefinition: "settled with one word",
      engdefinition:
        "to reach a binding agreement verbally; I give you my word of honor; it's a deal",
      sentences: "甲：我们下礼拜三晚上七点钟在这里见面，好不好。乙：一言为定",
      tradsent: "甲：我們下禮拜三晚上七點鐘在這裡見面，好不好。乙：一言為定",
    },
    {
      chengyu: "一目了然",
      tradchengyu: "一目了然",
      pinyin: "yi2 mu4 liao3 ran2",
      pinyin2: "yí mù liǎo rán ",
      chindefinition: "to understand fully at a glance",
      engdefinition: "said of clarity in presentation",
      sentences: "图表的作用，就是把复杂的事情让看图表的人一目了然。",
      tradsent: "圖表的作用，就是把複雜的事情讓看圖表的人一目了然。",
    },
    {
      chengyu: "十全十美",
      tradchengyu: "十全十美",
      pinyin: "shi2 quan2 shi2 mei3",
      pinyin2: "shí quán shí měi",
      chindefinition: "ten perfections and ten beauties",
      engdefinition: "one hundred percent perfect; flawless",
      sentences: "本来我以为这本字典是十全十美的，没想到还是发现了几个小错误。",
      tradsent: "本來我以為這本字典是十全十美的，沒想到還是發現了幾個小錯誤。",
    },
    {
      chengyu: "一见如故",
      tradchengyu: "一見如故",
      pinyin: "yi2 jian4 ru2 gu4",
      pinyin2: "yí jiàn rú gù ",
      chindefinition: "from first glance to be like old friends",
      engdefinition:
        "to become good friends from the first meeting; love at first sight",
      sentences: "我跟张先生一见如故，立刻就成了知己朋友。",
      tradsent: "我跟張先生一見如故，立刻就成了知己朋友。",
    },
    {
      chengyu: "一毛不拔",
      tradchengyu: "一毛不拔",
      pinyin: "yi4 mao2 bu4 ba2",
      pinyin2: "yì máo bù bá",
      chindefinition:
        "unwilling to pluck even a single hair (for the sake of others)",
      engdefinition:
        "extreme selfishness; without sympathy for others; parsimonious",
      sentences: "我相信她不会捐钱可别找他，他是有名的一毛不拔。",
      tradsent: "我相信她不會捐錢可別找他，他是有名的一毛不拔。",
    },
    {
      chengyu: "一塌糊涂",
      tradchengyu: "一塌糊塗",
      pinyin: "yi2 ta4 hu2 tu2",
      pinyin2: "yí tà hú tú ",
      chindefinition: "the whole bed is confused",
      engdefinition:
        "in a great mess; topsy-turvy; in disorder; etc. (often used to describe shoddy work, poor writing, etc.)",
      sentences:
        "1）屋子几天没整理就乱得一塌糊涂！2）最近忙得一塌糊涂，差一点就把签证到期的事给忘了。",
      tradsent:
        "1）屋子幾天沒整理就亂得一塌糊塗！ 2）最近忙得一塌糊塗，差一點就把簽證到期的事給忘了。",
    },
    {
      chengyu: "一窍不通",
      tradchengyu: "一竅不通",
      pinyin: "yi2 qiao4 bu4 tong1",
      pinyin2: "yí qiào bù tōng",
      chindefinition: "not one pore is permeabale",
      engdefinition:
        "completely ignorant; utterly stupid; dullness; very poor(writing)",
      sentences:
        "1) 中国菜我很爱吃，可是谈到做菜，那我就一窍不通了。2）你会画中国画吗？我会欣赏中国画，但是画画我可一窍不通。",
      tradsent:
        "1) 中國菜我很愛吃，可是談到做菜，那我就一竅不通了。 2）你會畫中國畫嗎？我會欣賞中國畫，但是畫畫我可一竅不通。",
    },
    {
      chengyu: "千篇一律",
      tradchengyu: "千篇一律",
      pinyin: "qian1 pian1 yi2 lv4",
      pinyin2: "qiān piān yí lv4",
      chindefinition: "play the same tune a thousand times",
      engdefinition:
        "to harp on a single theme; without variation; dull; sameness; monotonous (said of a composition)",
      sentences:
        "自从公司的经理贪污的消息传出来以后，媒体几乎没有人表示同情，反而千篇一律地批评公司在管理上的失败。",
      tradsent:
        "自從公司的經理貪污的消息傳出來以後，媒體幾乎沒有人表示同情，反而千篇一律地批評公司在管理上的失敗。",
    },
    {
      chengyu: "胡说八道",
      tradchengyu: "胡說八道",
      pinyin: "hu2 shuo1 ba1 dao4",
      pinyin2: "hú shuō bā dào",
      chindefinition: "speak imprudently and talk wildly",
      engdefinition: "to talk nonsense; speak foolishly; lies",
      sentences:
        "我昨天根本没到学校来，可是你说我昨天拿走了图书馆的书，那不是胡说八道吗？",
      tradsent:
        "我昨天根本沒到學校來，可是你說我昨天拿走了圖書館的書，那不是胡說八道嗎？",
    },
    {
      chengyu: "接二连三",
      tradchengyu: "接二連三",
      pinyin: "jie1 er4 lian2 san1",
      pinyin: "jiē èr lián sān",
      chindefinition: "join the second and connect the third",
      engdefinition:
        "in quick succession; one after the other; continuously; repeatedly",
      sentences: "最近天气很不好，感冒的人很多，学校里接二连三的有人请病假。",
      tradsent: "最近天氣很不好，感冒的人很多，學校裡接二連三的有人請病假。",
    },
  ],
  animals: [
    {
      chengyu: "塞翁失马",
      tradchengyu: "塞翁失馬",
      pinyin: "sai4 weng1 shi1 ma3",
      pinyin2: "sài wēng shī mǎ",
      chindefinition: "the old man of the frontier lost his horse",
      engdefinition: "a blessing in disguise",
      sentences:
        "那个人刚买了一所房子还没办好保险，就被火烧了，真是非常不幸。可是后来他重建这所房子的时候，在地下挖出了一箱金子来。塞翁失马（焉知祸福），得到了一个证明。",
      tradsent:
        "那個人剛買了一所房子還沒辦好保險，就被火燒了，真是非常不幸。可是後來他重建這所房子的時候，在地下挖出了一箱金子來。塞翁失馬（焉知禍福），得到了一個證明。",
    },
    {
      chengyu: "走马看花",
      tradchengyu: "走馬看花",
      pinyin: "zou3 ma3 kan4 hua1",
      pinyin2: "zǒu mǎ kàn huā",
      chindefinition: "to view flowers from the back of a galloping horse",
      engdefinition:
        "to examine a thing hurriedly; to see things superficially; a general impression",
      sentences:
        "昨天我到故宫博物馆去参观，可惜时间太短，只能走马看花地看了一下。",
      tradsent:
        "昨天我到故宮博物館去參觀，可惜時間太短，只能走馬看花地看了一下。",
    },
    {
      chengyu: "狼狈为奸",
      tradchengyu: "狼狽為奸",
      pinyin: "lang2 bei4 wei2 jian1",
      pinyin2: "láng bèi wéi jiān",
      chindefinition: "lang and pei banded together for seditious purposes",
      engdefinition:
        "to gang up with somebody; to work hand in glove (for evil purposes)",
      sentences: "工人想偷懒，商人想赚钱，因此狼狈为奸，把那所房子盖得一塌糊涂",
      tradsent: "工人想偷懶，商人想賺錢，因此狼狽為奸，把那所房子蓋得一塌糊塗",
    },
    {
      chengyu: "对牛弹琴",
      tradchengyu: "對牛彈琴",
      pinyin: "dui4 niu2 tan2 qin2",
      pinyin2: "duì niú tán qín",
      chindefinition: "to play a lute before an ox",
      engdefinition:
        "to cast pearls before swine; to attempt to explain deep truths to someone totally incapable of comprehending",
      sentences:
        "我对于抽象派野兽派的画，一窍不通，这位画家跟我说了一大堆绘画理论，我还是不懂，他说跟我谈话就像是对牛弹琴。",
      tradsent:
        "我對於抽象派野獸派的畫，一竅不通，這位畫家跟我說了一大堆繪畫理論，我還是不懂，他說跟我談話就像是對牛彈琴。",
    },
    {
      chengyu: "画蛇添足",
      tradchengyu: "畫蛇添足",
      pinyin: "hua4 she2 tian1 zu2",
      pinyin2: "huà shé tiān zú",
      chindefinition: "to draw a snake and add feet",
      engdefinition:
        "to make unnecessary additions; superfluous; over-do something",
      sentences:
        "这个简单的事情用两句话说明就可以了，为了这件事写一篇一万字的论文，真是画蛇添足。",
      tradsent:
        "這個簡單的事情用兩句話說明就可以了，為了這件事寫一篇一萬字的論文，真是畫蛇添足。",
    },
  ],
  human: [
    {
      chengyu: "洗耳恭听",
      tradchengyu: "洗耳恭聽",
      pinyin: "xi3 er3 gong1 ting1",
      pinyin2: "xǐ ěr gōng tīng",
      chindefinition: "to wash one's ears and listen reverently",
      engdefinition: "to listen respectfully",
      sentences: "对这个问题，你一定有很多好意见，我愿意洗耳恭听。",
      tradsent: "對這個問題，你一定有很多好意見，我願意洗耳恭聽。",
    },
    {
      chengyu: "忠言逆耳",
      tradchengyu: "忠言逆耳",
      pinyin: "zhong1 yan2 ni4 er3",
      pinyin2: "zhōng yán nì ěr",
      chindefinition: "honest advice often grates on the ear",
      engdefinition:
        "sincere advice is unpleasant and hard to accept; truth seldom sounds pleasant",
      sentences:
        "我把做那件事的坏处告诉他，希望他不要做。但是忠言逆耳，他不但不感谢我反而很生气。",
      tradsent:
        "我把做那件事的壞處告訴他，希望他不要做。但是忠言逆耳，他不但不感謝我反而很生氣。",
    },
    {
      chengyu: "心不在焉",
      tradchengyu: "心不在焉",
      pinyin: "xin1 bu2 zai4 yan1",
      pinyin2: "xīn bú zài yān",
      chindefinition: "one's heart is not in it",
      engdefinition: "lack of concentation; absent-minded",
      sentences:
        "1)他天天计划着旅行的事，连上课的时候也心不在焉了。2）我刚才跟你说的话你听清楚了吗？怎么好像心不在焉似的。",
      tradsent:
        "1)他天天計劃著旅行的事，連上課的時候也心不在焉了。 2）我剛才跟你說的話你聽清楚了嗎？怎麼好像心不在焉似的。",
    },
    {
      chengyu: "勾心斗角",
      tradchengyu: "勾心鬥角",
      pinyin: "gou1 xin1 dou4 jiao3",
      pinyin2: "gōu xīn dòu jiǎo",
      chindefinition: "to search the heart and fight with horns",
      engdefinition: "competition of ideas; idological differences or dispute",
      sentences: "小王跟小美虽然没吵过架，可是常常勾心斗角不让对方占优势。",
      tradsent: "小王跟小美雖然沒吵過架，可是常常勾心鬥角不讓對方佔優勢。",
    },
    {
      chengyu: "首屈一指",
      tradchengyu: "首屈一指",
      pinyin: "shou3 qu1 yi4 zhi3",
      pinyin2: "shǒu qū yì zhǐ",
      chindefinition: "the first one counted among the fingers",
      engdefinition: "the foremost; second to none; the the best",
      sentences:
        "以收藏中国古代艺术品的收藏量和价值来说，故宫博物馆应当是首屈一指的。",
      tradsent:
        "以收藏中國古代藝術品的收藏量和價值來說，故宮博物館應當是首屈一指的。",
    },
    {
      chengyu: "口是心非",
      tradchengyu: "口是心非",
      pinyin: "kou3 shi4 xin1 fei1",
      pinyin2: "kǒu shì xīn fēi",
      chindefinition: "the mouth is true but the heart is false",
      engdefinition:
        "to mean something contrary to what is spoken; to say one thing and mean another",
      sentences: "你既然答应了就应该做到，要不然别人会批评你口是心非。",
      tradsent: "你既然答應了就應該做到，要不然別人會批評你口是心非。",
    },
    {
      chengyu: "问心无愧",
      tradchengyu: "問心無愧",
      pinyin: "wen4 xin1 wu2 kui4",
      pinyin2: "wèn xīn wú kuì",
      chindefinition: "to examine oneself and find nothing to be ashamed of",
      engdefinition: "to have a clear conscience",
      sentences: "为了解决这个问题我已经尽力了，虽然结果不理想，但我问心无愧。",
      tradsent: "為了解決這個問題我已經盡力了，雖然結果不理想，但我問心無愧。",
    },
    {
      chengyu: "以身作则",
      tradchengyu: "以身作則",
      pinyin: "yi3 shen1 zuo4 ze2",
      pinyin2: "yǐ shēn zuò zé",
      chindefinition: "to set an example by one's own actions",
      engdefinition:
        "said of peopel holding responsible positions or the head of a family",
      sentences:
        "1) 作领袖的人，行为一定要端正，因为他们应当以身作则。2）父母只有以身作则，才能把子女教育好。",
      tradsent:
        "1) 作領袖的人，行為一定要端正，因為他們應當以身作則。 2）父母只有以身作則，才能把子女教育好。 ",
    },
    {
      chengyu: "心平气和",
      tradchengyu: "心平氣和",
      pinyin: "xin1 ping2 qi4 he2",
      pinyin2: "xīn píng qì hé",
      chindefinition: "quiet mind and peaceful disposition",
      engdefinition:
        "to be perfectly calm; to be completely fair without involving one's personal feelings",
      sentences: "发生纠纷的时候，应该心平气和地去处理，才能顺利解决。",
      tradsent: "發生糾紛的時候，應該心平氣和地去處理，才能順利解決。",
    },
    {
      chengyu: "同病相怜",
      tradchengyu: "同病相憐",
      pinyin: "tong2 bing4 xiang1 lian2",
      pinyin2: "tóng bìng xiāng lián",
      chindefinition:
        "those suffering from the same disease feel pity for one another",
      engdefinition: "mutual sympathy",
      sentences:
        "老张和老李这两个好朋友最近运气不好，一个被小偷偷了，一个家里着了火，两个人因此同病相怜！",
      tradsent:
        "老張和老李這兩個好朋友最近運氣不好，一個被小偷偷了，一個家裡著了火，兩個人因此同病相憐！",
    },
    {
      chengyu: "自私自利",
      tradchengyu: "自私自利",
      pinyin: "zi4 si1 zi4 li4",
      pinyin2: "zì sī zì lì",
      chindefinition: "to seek private or personal advantage",
      engdefinition:
        "to strive for benefit at the expense of others; to be selfish; self-interest",
      sentences: "一个自私自利的人，会对帮助别人有兴趣吗？",
      tradsent: "一個自私自利的人，會對幫助別人有興趣嗎？",
    },
    {
      chengyu: "自作自受",
      tradchengyu: "自作自受",
      pinyin: "zi4 zuo4 zi4 shou4",
      pinyin2: "zì zuò zì shòu",
      chindefinition: "one reaps what he sows",
      engdefinition:
        "to suffer the consequences of one's own doing; to bring trouble on oneself",
      sentences:
        "学校不准学生骑摩托车，如果出了事受伤了是自作自受，不会有人同情的。",
      tradsent:
        "學校不准學生騎摩托車，如果出了事受傷了是自作自受，不會有人同情的。",
    },
    {
      chengyu: "自相矛盾",
      tradchengyu: "自相矛盾",
      pinyin: "zi4 xiang1 mao2 dun4",
      pinyin2: "zì xiāng máo dùn",
      chindefinition: "spear and shield both in the hands of the same man",
      engdefinition:
        "to contradict oneself; self-contradictory; inconsistent; paradoxical",
      sentences: "有些国家一面提倡和平，一面却又增加武力，那不是自相矛盾吗？ ",
      tradsent: "有些國家一面提倡和平，一面卻又增加武力，那不是自相矛盾嗎？",
    },
    {
      chengyu: "老生常谈",
      tradchengyu: "老生常談",
      pinyin: "lao3 sheng1 chang2 tan2",
      pinyin2: "lǎo shēng cháng tán",
      chindefinition: "the platitudes of an old scholar",
      engdefinition: "a threadbare argument or cliche",
      sentences:
        "忠言逆耳这句话虽然是老生常谈，但是可以提醒我们应当多尊重别人的意见。",
      tradsent:
        "忠言逆耳這句話雖然是老生常談，但是可以提醒我們應當多尊重別人的意見。",
    },
    {
      chengyu: "渔翁之利",
      tradchengyu: "漁翁之利",
      pinyin: "yu2 weng1 zhi1 li4",
      pinyin2: "yú wēng zhī lì",
      chindefinition:
        "while the heron and the calm are locked in battle, the fisherman snares them together",
      engdefinition:
        "while two dogs fight for a bone, a third runs away with it; the third party gets the profit",
      sentences:
        "俄国在中日甲午战争的时候，趁机把中国的东三省变成他们的势力范围，可说是坐享渔翁之利。",
      tradsent:
        "俄國在中日甲午戰爭的時候，趁機把中國的東三省變成他們的勢力範圍，可說是坐享漁翁之利。",
    },
  ],
  antonyms: [
    {
      chengyu: "啼笑皆非",
      tradchengyu: "啼笑皆非",
      pinyin: "ti2 xiao4 jie1 fei1",
      pinyin2: "tí xiào jiē fēi",
      chindefinition: "neither tears nor laughter",
      engdefinition: "unable to cry or laugh; to be greatly embarrassed",
      sentences:
        "昨天晚上王先生请我们到他家去，说是有重要的事，我们去了以后才知道是让我们看他旅行的照片弄得我们啼笑皆非。",
      tradsent:
        "昨天晚上王先生請我們到他家去，說是有重要的事，我們去了以後才知道是讓我們看他旅行的照片弄得我們啼笑皆非。",
    },
    {
      chengyu: "得不偿失",
      tradchengyu: "得不償失",
      pinyin: "de2 bu4 chang2 shi1",
      pinyin2: "dé bù cháng shī",
      chindefinition: "what one gains cannot offset the losses",
      engdefinition: "not worth the effort",
      sentences:
        "老王请我吃饭，我吃了很多好东西，没想到得不偿失，把肚子吃坏了，花了不少钱看病。",
      tradsent:
        "老王請我吃飯，我吃了很多好東西，沒想到得不償失，把肚子吃壞了，花了不少錢看病。",
    },
    {
      chengyu: "争先恐后",
      tradchengyu: "爭先恐後",
      pinyin: "zheng1 xian1 kong3 hou4",
      pinyin2: "zhēng xiān kǒng hòu",
      chindefinition:
        "to contend for the first and be fearful of being left behind",
      engdefinition: "anxious to get ahead and afraid of falling behind others",
      sentences: "上公共汽车应该排队不要争先恐后。",
      tradsent: "上公共汽車應該排隊不要爭先恐後。",
    },
    {
      chengyu: "大同小异",
      tradchengyu: "大同小異",
      pinyin: "da4 tong2 xiao3 yi4",
      pinyin2: "dà tóng xiǎo yì",
      chindefinition: "general similar with differences in details only",
      engdefinition:
        "very similar;almost the same; with slight differences only",
      sentences:
        "1）这次书展除了比上次多了一家书局以外，展出的内容、数量跟上次都大同小异。2）英国话和美国话可以说是大同小异。",
      tradsent:
        "1）這次書展除了比上次多了一家書局以外，展出的內容、數量跟上次都大同小異。 2）英國話和美國話可以說是大同小異。",
    },
    {
      chengyu: "似是而非",
      tradchengyu: "似是而非",
      pinyin: "si4 shi4 er2 fei1",
      pinyin2: "sì shì ér fēi",
      chindefinition: "seemingly correct but really incorrect",
      engdefinition: "having the semblance of what it is not; deceiving",
      sentences: "他的研究没有什么科学根据，只得出了一些似是而非的推论。",
      tradsent: "他的研究沒有什麼科學根據，只得出了一些似是而非的推論。",
    },
    {
      chengyu: "有名无实",
      tradchengyu: "有名無實",
      pinyin: "you3 ming2 wu2 shi2",
      pinyin2: "yǒu míng wú shí",
      chindefinition: "name without reality",
      engdefinition: "to exist in name only; merely nominal; empty profession",
      sentences: "那位大医生连伤风都治不好，我看他是有名无实。",
      tradsent: "那位大医生连伤风都治不好，我看他是有名无实。",
    },
    {
      chengyu: "舍近求远",
      tradchengyu: "捨近求遠",
      pinyin: "she3 jin4 qiu2 yuan3",
      pinyin2: "shě jìn qiú yuǎn",
      chindefinition:
        "to reject what is near at hand and seek what is far away",
      engdefinition: "foolish",
      sentences: "家里就有电脑，要是你坐车到学校去用电脑，那就是舍近求远了。",
      tradsent: "家裡就有電腦，要是你坐車到學校去用電腦，那就是捨近求遠了。",
    },
    {
      chengyu: "答非所问",
      tradchengyu: "答非所問",
      pinyin: "da2 fei1 suo3 wen4",
      pinyin2: "dá fēi suǒ wèn",
      chindefinition: "to answer what is not asked",
      engdefinition:
        "you are not answer in my question; to give a irrelevent answer",
      sentences:
        "我问他最近忙不忙，他回答说这两天天气不错，这种答非所问的毛病，他总是改不了。",
      tradsent:
        "我問他最近忙不忙，他回答說這兩天天氣不錯，這種答非所問的毛病，他總是改不了。",
    },
    {
      chengyu: "忘恩负义",
      tradchengyu: "忘恩負義",
      pinyin: "wang4 en1 fu4 yi4",
      pinyin2: "wàng ēn fù yì",
      chindefinition: "to forget kindness and act against decorum",
      engdefinition:
        "to be ungrateful; to be forgetful of all favors one has been given; insensibility to kindness",
      sentences:
        "从前他有困难的时候，李先生总是帮助他，现在李先生有事求他了，他却不理，朋友们都骂他忘恩负义。",
      tradsent:
        "從前他有困難的時候，李先生總是幫助他，現在李先生有事求他了，他卻不理，朋友們都罵他忘恩負義。",
    },
    {
      chengyu: "进退两难",
      tradchengyu: "進退兩難",
      pinyin: "jin4 tui4 liang3 nan2",
      pinyin2: "jìn tuì liǎng nán",
      chindefinition: "difficult either to advance or retrent",
      engdefinition: "in a dilemma;in a jam; on a spot",
      sentences:
        "我说过我要帮他忙，可是现在我的能力不够，帮不了忙，不帮忙又不好意思，弄得我进退两难。",
      tradsent:
        "我說過我要幫他忙，可是現在我的能力不夠，幫不了忙，不幫忙又不好意思，弄得我進退兩難。",
    },
    {
      chengyu: "弄巧成拙",
      tradchengyu: "弄巧成拙",
      pinyin: "nong4 qiao3 cheng2 zhuo2",
      pinyin2: "nòng qiǎo chéng zhuó",
      chindefinition: "try to be cleever but showing stupidity",
      engdefinition:
        "to bungle a clever scheme; to get into trouble trying to take advantage of a situation",
      sentences: "一个喜欢表现自己很聪明的人，往往会弄巧成拙。",
      tradsent: "一個喜歡表現自己很聰明的人，往往會弄巧成拙。 ",
    },
    {
      chengyu: "损人利己",
      tradchengyu: "損人利己",
      pinyin: "sun3 ren2 li4 ji3",
      pinyin2: "sǔn rén lì jǐ",
      chindefinition: "to benefit oneself at the expense of others",
      engdefinition:
        "to injure others for the sake of one's own profit; selfish.",
      sentences:
        "小偷偷东西做的是损人利己的事；在街上乱扔垃圾，是不是损人不利己呢？",
      tradsent:
        "小偷偷東西做的是損人利己的事；在街上亂扔垃圾，是不是損人不利己呢？",
    },
    {
      chengyu: "假公济私",
      tradchengyu: "假公濟私",
      pinyin: "jia3 gong1 ji4 si1",
      pinyin2: "jiǎ gōng jì sī",
      chindefinition:
        "to attain private or personal ends in the name of official duties",
      engdefinition: "for selfish purposes",
      sentences: "公务员要是有假公济私的行为，就应当受法律制裁。",
      tradsent: "公務員要是有假公濟私的行為，就應當受法律制裁。",
    },
    {
      chengyu: "小题大作",
      tradchengyu: "小題大作",
      pinyin: "xiao3 ti2 da4 zuo4",
      pinyin2: "xiǎo tí dà zuò",
      chindefinition: "to write a big article on a small theme",
      engdefinition:
        "to make musch of a trifle; great fuss about a small matter; to make a mountain out of a mole-hill; much also about nothing",
      sentences: "那两个人为了争一点小东西，就打起架来了，实在是小题大作。",
      tradsent: "那兩個人為了爭一點小東西，就打起架來了，實在是小題大作。",
    },
    {
      chengyu: "因小失大",
      tradchengyu: "因小失大",
      pinyin: "yin1 xiao3 shi1 da4",
      pinyin2: "yīn xiǎo shī dà",
      chindefinition: "to lose the greater for the lesser",
      engdefinition:
        "to lose a big opportunity or large gain because of a trifle consideration",
      sentences:
        "为了省计程车钱而坐公共汽车到飞机场去，结果没赶上飞机，这种因小失大的事，你还想做第二次吗？",
      tradsent:
        "為了省計程車錢而坐公共汽車到飛機場去，結果沒趕上飛機，這種因小失大的事，你還想做第二次嗎？",
    },
    {
      chengyu: "有始有终",
      tradchengyu: "有始有終",
      pinyin: "you3 shi3 you3 zhong1",
      pinyin2: "yǒu shǐ yǒu zhōng",
      chindefinition: "having a beginning and an end",
      engdefinition:
        "to carry out an undertaking from start to finish, not giving up halfway; to be complete, having perserverance",
      sentences:
        "学中文的确是一件苦事，但是我既然决心要把中文学好，就应该不怕吃苦，有始有终不能半途而废。",
      tradsent:
        "學中文的確是一件苦事，但是我既然決心要把中文學好，就應該不怕吃苦，有始有終不能半途而廢。",
    },
    {
      chengyu: "供不应求",
      tradchengyu: "供不應求",
      pinyin: "gong1 bu2 ying4 qiu2",
      pinyin2: "gōng bú yìng qiú",
      chindefinition: "the supply is not equal to the demand",
      engdefinition: "not enough to meet the needs",
      sentences: "那家工厂的产品物美廉价，购买的人特别多，所以总是供不应求。",
      tradsent: "那家工廠的產品物美廉價，購買的人特別多，所以總是供不應求。",
    },
    {
      chengyu: "少见多怪",
      tradchengyu: "少見多怪",
      pinyin: "shao3 jian4 duo1 guai4",
      pinyin2: "shǎo jiàn duō guài",
      chindefinition: "to wonder much because one has seen little",
      engdefinition: "things seldom seen are strange; lack of experience",
      sentences:
        "昨天我穿了一件很流行的衣服去看电影，街上有很多人盯着我看，真是少见多怪。",
      tradsent:
        "昨天我穿了一件很流行的衣服去看電影，街上有很多人盯著我看，真是少見多怪。",
    },
  ],

  measurements: [
    {
      chengyu: "斤斤计较",
      tradchengyu: "斤斤計較",
      pinyin: "jin1 jin1 ji4 jiao4",
      pinyin2: "jīn jīn jì jiào",
      chindefinition: "to compare the weight catty by catty",
      engdefinition: "to be particular about every point, detail or trifle",
      sentences:
        "我看这件事情我们就让点步算了，如果双方都这么斤斤计较那就没完没了了。",
      tradsent:
        "我看這件事情我們就讓點步算了，如果雙方都這麼斤斤計較那就沒完沒了了。",
    },
    {
      chengyu: "得寸进尺",
      tradchengyu: "得寸進尺",
      pinyin: "de2 cun4 jin4 chi3",
      pinyin2: "dé cùn jìn chǐ",
      chindefinition: "to get an inch and then a foot",
      engdefinition:
        "to get something and want more; insatiable; covetous; the more one gets the more he want; small concessions lead to greater demands; never satisfied",
      sentences:
        "你昨天让我捐了一百块钱，我捐了。今天又让我再捐一千块钱，你真是得寸进尺。",
      tradsent:
        "你昨天讓我捐了一百塊錢，我捐了。今天又讓我再捐一千塊錢，你真是得寸進尺。",
    },
    {
      chengyu: "半斤八两",
      tradchengyu: "半斤八兩",
      pinyin: "ban4 jin1 ba1 liang3",
      pinyin2: "bàn jīn bā liǎng",
      chindefinition: "half a catty/eight ounces",
      engdefinition:
        "six of one and half a dozen of the other; negligible difference",
      sentences:
        "老王说你常迟到，你说老王比你更常迟到，我看你们两个人半斤八两，谁也别说谁了。",
      tradsent:
        "老王說你常遲到，你說老王比你更常遲到，我看你們兩個人半斤八兩，誰也別說誰了。",
    },
    {
      chengyu: "半途而废",
      tradchengyu: "半途而廢",
      pinyin: "ban4 tu2 er2 fei4",
      pinyin2: "bàn tú ér fèi",
      chindefinition: "to stop halfway",
      engdefinition: "to fail to complete a task",
      sentences:
        "1）研究学问要是半途而废就太可惜了。2）我以前学过两年医学，因为成绩差，就半途而废改学别的了。",
      tradsent:
        "1）研究學問要是半途而廢就太可惜了。 2）我以前學過兩年醫學，因為成績差，就半途而廢改學別的了。",
    },
    {
      chengyu: "说来话长",
      tradchengyu: "說來話長",
      pinyin: "shuo1 lai2 hua4 chang2",
      pinyin2: "shuō lái huà cháng",
      chindefinition: "to start telling it would make a long story",
      engdefinition: "it's a long story",
      sentences:
        "甲：最近没见到你，怎么这么瘦了呢？乙：唉！我失恋了。说来话长等有时间的时候再慢慢告诉你吧！",
      tradsent:
        "甲：最近沒見到你，怎麼這麼瘦了呢？乙：唉！我失戀了。說來話長等有時間的時候再慢慢告訴你吧！",
    },
    {
      chengyu: "事半功倍",
      tradchengyu: "事半功倍",
      pinyin: "shi4 ban4 gong1 bei4",
      pinyin2: "shì bàn gōng bèi",
      chindefinition: "Half the work with double the result",
      engdefinition:
        "to achieve maximum results with minimum effort; successful",
      sentences: "学语言如果方法好一定可以事半功倍。",
      tradsent: "學語言如果方法好一定可以事半功倍。",
    },
  ],
  pos_connotation: [
    {
      chengyu: "有条有理",
      tradchengyu: "有條有理",
      pinyin: "you3 tiao2 you3 li3",
      pinyin2: "yǒu tiáo yǒu lǐ",
      chindefinition: "in lines and well arranged",
      engdefinition: "systematic, in perfect order; logical",
      sentences:
        "这位老师讲起课来有条有理而且生动有趣，所以听课的学生从来没有睡觉的。",
      tradsent:
        "這位老師講起課來有條有理而且生動有趣，所以聽課的學生從來沒有睡覺的。",
    },
    {
      chengyu: "直截了当",
      tradchengyu: "直截了當",
      pinyin: "zhi2 jie2 liao3 dang4",
      pinyin2: "zhí jié liǎo dàng",
      chindefinition: "simple and direct",
      engdefinition: "straightforward; to the point",
      sentences:
        "要是你对一个人有不满意的地方，应该直截了当的告诉他，不要在背后随便批评。",
      tradsent:
        "要是你對一個人有不滿意的地方，應該直截了當的告訴他，不要在背後隨便批評。",
    },
    {
      chengyu: "轻而易举",
      tradchengyu: "輕而易舉",
      pinyin: "qing1 er2 yi4 ju3",
      pinyin2: "qīng ér yì jǔ",
      chindefinition: "light and easy to lift",
      engdefinition: "convenient; easy to accompolish",
      sentences: "按你的程度，做十分钟的国语讲演应该是轻而易举的事。",
      tradsent: "按你的程度，做十分鐘的國語講演應該是輕而易舉的事。",
    },
    {
      chengyu: "熟能生巧",
      tradchengyu: "熟能生巧",
      pinyin: "shu2 neng2 sheng1 qiao3",
      pinyin2: "shú néng shēng qiǎo",
      chindefinition: "dexterity is the product of long practice",
      engdefinition: "skill comes with long experience; practice makes perfect",
      sentences:
        "1）学成语虽然不容易，但是熟能生巧，只要常常用自然就记住了。2）他打字打得既快又好，那是因为他天天练习熟能生巧的缘故。",
      tradsent:
        "1）學成語雖然不容易，但是熟能生巧，只要常常用自然就記住了。 2）他打字打得既快又好，那是因為他天天練習熟能生巧的緣故。",
    },
    {
      chengyu: "随机应变",
      tradchengyu: "隨機應變",
      pinyin: "sui2 ji1 ying4 bian4",
      pinyin2: "suí jī yìng biàn",
      chindefinition: "to change with the circumstances",
      engdefinition:
        "to bend with the wind; to adapt oneself quickly to changing situations",
      sentences: "打球跟打仗一样，要注意对方的作战方法来随机应变。",
      tradsent: "打球跟打仗一樣，要注意對方的作戰方法來隨機應變。",
    },
    {
      chengyu: "先见之明",
      tradchengyu: "先見之明",
      pinyin: "xian1 jian4 zhi1 ming2",
      pinyin2: "xiān jiàn zhī míng",
      chindefinition: "able to see clearly from a distance",
      engdefinition: "insight; forethought; intelligence",
      sentences:
        "早上天气那么好，现在下起雨来了，要不是我有先见之明带了把伞，那就会淋成落汤鸡了。",
      tradsent:
        "早上天氣那麼好，現在下起雨來了，要不是我有先見之明帶了把傘，那就會淋成落湯雞了。",
    },
    {
      chengyu: "实事求是",
      tradchengyu: "實事求是",
      pinyin: "shi2 shi4 qiu2 shi4",
      pinyin2: "shí shì qiú shì",
      chindefinition: "by verification of the facts, to get at the truth",
      engdefinition:
        "to work conscientiously or seriously; to make a conscientious effort in doing things",
      sentences:
        "我那位朋友对事情总是先了解然后选择适当的方法去做一点也不马虎。他这种实事求是的精神，很让大家敬佩。",
      tradsent:
        "我那位朋友對事情總是先了解然後選擇適當的方法去做一點也不馬虎。他這種實事求是的精神，很讓大家敬佩。",
    },
    {
      chengyu: "各有所长",
      tradchengyu: "各有所長",
      pinyin: "ge4 you3 suo3 chang2",
      pinyin2: "gè yǒu suǒ cháng",
      chindefinition: "each has q unique merit",
      engdefinition: "everyone has his own gift; each with its good points",
      sentences: "李小姐会画画，王小姐会唱歌，她们两位各有所长。",
      tradsent: "李小姐會畫畫，王小姐會唱歌，她們兩位各有所長。",
    },
    {
      chengyu: "不约而同",
      tradchengyu: "不約而同",
      pinyin: "bu4 yue1 er2 tong2",
      pinyin2: "bù yuē ér tóng",
      chindefinition: "to be in accord without consulting each other",
      engdefinition: "unintended coincidence",
      sentences: "我的几位美国朋友，不约而同地都到台湾来学中文了。",
      tradsent: "我的幾位美國朋友，不約而同地都到台灣來學中文了。",
    },
    {
      chengyu: "应有尽有",
      tradchengyu: "應有盡有",
      pinyin: "ying1 you3 jin4 you3",
      pinyin2: "yīng yǒu jìn yǒu",
      chindefinition: "everything that has to be available is available",
      engdefinition: "lacking nothing; complete with everything",
      sentences: "台北有几家很大的百货公司，里面的东西可以算得上是应有尽有。",
      tradsent: "台北有幾家很大的百貨公司，裡面的東西可以算得上是應有盡有。",
    },
    {
      chengyu: "价廉物美",
      tradchengyu: "價廉物美",
      pinyin: "jia4 lian2 wu4 mei3",
      pinyin2: "jià lián wù měi",
      chindefinition: "low price and fine wares",
      engdefinition:
        "excellent quality at a reasonable price; a bargins; a good way",
      sentences:
        "1) 价廉物美的饭馆，生意一定好。2）我这件衣服才一百块钱，可以算价廉物美吧！",
      tradsent:
        "1) 價廉物美的飯館，生意一定好。 2）我這件衣服才一百塊錢，可以算價廉物美吧！",
    },
    {
      chengyu: "安居乐业",
      tradchengyu: "安居樂業",
      pinyin: "an1 ju1 le4 ye4",
      pinyin2: "ān jū lè yè",
      chindefinition: "to live in peace and be content in one's occupation",
      engdefinition: "said of people living under a good government",
      sentences:
        "一个国家能够各方面平衡发展，使老百姓人人安居乐业大概就算是成功了。",
      tradsent:
        "一個國家能夠各方面平衡發展，使老百姓人人安居樂業大概就算是成功了。",
    },
    {
      chengyu: "万事如意",
      tradchengyu: "萬事如意",
      pinyin: "wan4 shi4 ru2 yi4",
      pinyin2: "wàn shì rú yì",
      chindefinition: "everything as one wishes",
      engdefinition: 'prosperous; everything is "rosy"',
      sentences: "恭喜发财，万事如意！",
      tradsent: "恭喜发财，万事如意！",
    },
    {
      chengyu: "恍然大悟",
      tradchengyu: "恍然大悟",
      pinyin: "huang3 ran2 da4 wu4",
      pinyin2: "huǎng rán dà wù",
      chindefinition: "to awake from confusion",
      engdefinition: "come to a sudden understanding; to see the light",
      sentences:
        "我一直不明白中国人过年的时候为什么一定要吃鱼，昨天我学了年年有余这个成语以后，才恍然大悟。",
      tradsent:
        "我一直不明白中國人過年的時候為什麼一定要吃魚，昨天我學了年年有餘這個成語以後，才恍然大悟。",
    },
    {
      chengyu: "名正言顺",
      tradchengyu: "名正言順",
      pinyin: "ming2 zheng4 yan2 shun4",
      pinyin2: "míng zhèng yán shùn",
      chindefinition: "valid in name and in reasoning",
      engdefinition: "to have good reason; to be deserving; justified",
      sentences:
        "我觉得留学生买学生车票是名正言顺的。你不是学生却要买学生车票就名不正言不顺了。",
      tradsent:
        "我覺得留學生買學生車票是名正言順的。你不是學生卻要買學生車票就名不正言不順了。",
    },
    {
      chengyu: "新陈代谢",
      tradchengyu: "新陳代謝",
      pinyin: "xin1 chen2 dai4 xie4",
      pinyin2: "xīn chén dài xiè",
      chindefinition: "assimilation of the new and ejection of the old",
      engdefinition:
        "the old gives way to the new; (in biology) the process of metabolism",
      sentences:
        "近几年来政府机关鼓励年纪大的人退休，加快了职场上新陈代谢的速度。",
      tradsent:
        "近幾年來政府機關鼓勵年紀大的人退休，加快了職場上新陳代謝的速度。",
    },
    {
      chengyu: "名副其實",
      tradchengyu: "名副其實",
      pinyin: "ming2 fu4 qi2 shi2",
      pinyin2: "míng fù qí shí",
      chindefinition: "the reality is reflected in the name",
      engdefinition: "the title or name shows the real essence",
      sentences:
        "这位老师讲起课来有条有理而且生动有趣，所以听课的学生从来没有睡觉的。",
      tradsent:
        "這位老師講起課來有條有理而且生動有趣，所以聽課的學生從來沒有睡覺的。",
    },
  ],

  neg_connotation: [
    {
      chengyu: "敷衍了事",
      tradchengyu: "敷衍了事",
      pinyin: "fu1 yan3 liao3 shi4",
      pinyin2: "fū yǎn liǎo shì",
      chindefinition: "to handle a matter negligently",
      engdefinition:
        "to carry out a task in a prefunctory manner; without due care",
      sentences: "如果你做事切实负责，从来不敷衍了事，别人怎会不信任你呢？",
      tradsent: "如果你做事切實負責，從來不敷衍了事，別人怎會不信任你呢？",
    },
    {
      chengyu: "不劳而获",
      tradchengyu: "不勞而獲",
      pinyin: "bu4 lao2 er2 huo4",
      pinyin2: "bù láo ér huò",
      chindefinition: "obtained without effort",
      engdefinition: "to reap where one has not sown; to get undeserved credit",
      sentences:
        "买彩劵中了奖发了财，固然不错。可是这到底是不劳而获，不是正常现象",
      tradsent:
        "買彩劵中了獎發了財，固然不錯。可是這到底是不勞而獲，不是正常現象",
    },
    {
      chengyu: "明知故犯",
      tradchengyu: "明知故犯",
      pinyin: "ming2 zhi1 gu4 fan4",
      pinyin2: "míng zhī gù fàn",
      chindefinition: "to commit a crime intentionally",
      engdefinition:
        "wilful offence; to run a risk even though one knows what is it store",
      sentences:
        "他开车的时候很喜欢超速，如果被警察罚款了多次，可是他还是时常明知故犯。",
      tradsent:
        "他開車的時候很喜歡超速，如果被警察罰款了多次，可是他還是時常明知故犯。",
    },
    {
      chengyu: "明知故问",
      tradchengyu: "明知故問",
      pinyin: "ming2 zhi1 gu4 wen4",
      pinyin2: "míng zhī gù wèn",
      chindefinition: "to ask what one knows well already",
      engdefinition: "to feign ignorance; to draw a person out",
      sentences:
        "我上次考试不及格，还是他先知道的，可是现在又在这么多人面前明知故问：你上次考的怎么样？让我非常不好意思。",
      tradsent:
        "我上次考試不及格，還是他先知道的，可是現在又在這麼多人面前明知故問：你上次考的怎麼樣？讓我非常不好意思。",
    },
    {
      chengyu: "得意忘形",
      tradchengyu: "得意忘形",
      pinyin: "de2 yi4 wang4 xing2",
      pinyin2: "dé yì wàng xíng",
      chindefinition: "when in high spirits, to forget oneself",
      engdefinition:
        "to have one's head turned by success; to be too complacent to watch one's manners; to be carried away with oneself.",
      sentences:
        "他的法文的确不错因此我们常常称赞他。没想到他竟然得意忘形了，跟中文老师也说法文，是不是得了神经病了？",
      tradsent:
        "他的法文的確不錯因此我們常常稱讚他。沒想到他竟然得意忘形了，跟中文老師也說法文，是不是得了神經病了？",
    },
    {
      chengyu: "理直气壮",
      tradchengyu: "理直氣壯",
      pinyin: "li3 zhi2 qi4 zhuang4",
      pinyin2: "lǐ zhí qì zhuàng",
      chindefinition: "being within reason, one is bold in his speech",
      engdefinition:
        "(to speack, act, etc.) with confidence for one knows that he is in the right; to be fearless with the knowledge that one is on the side of justice.",
      sentences: "这件事是他做错的所以我可以理直气壮地跟他争辩。",
      tradsent: "這件事是他做錯的所以我可以理直氣壯地跟他爭辯。",
    },
    {
      chengyu: "言过其实",
      tradchengyu: "言過其實",
      pinyin: "yan2 guo4 qi2 shi2",
      pinyin2: "yán guò qí shí",
      chindefinition: "a statement that surpasses fact",
      engdefinition:
        "to over-state; to exaggerate; to boast or brag; to make a mountain out of a mole-hill",
      sentences:
        "尽管那个外国学生说的中文的确不错，可是你说他的中文跟中文老师一样好，是否有点言过其实？",
      tradsent:
        "儘管那個外國學生說的中文的確不錯，可是你說他的中文跟中文老師一樣好，是否有點言過其實？",
    },
    {
      chengyu: "幸灾乐祸",
      tradchengyu: "幸災樂禍",
      pinyin: "xing4 zai1 le4 huo4",
      pinyin2: "xìng zāi lè huò",
      chindefinition: "the enjoy diaster and rejoice in calamity",
      engdefinition:
        "to take pleasure in the misfortunes of others; to gloat over another's calamity",
      sentences: "别人失败的时候，我们应当去安慰他和鼓励他，千万不要幸灾乐祸。",
      tradsent: "別人失敗的時候，我們應當去安慰他和鼓勵他，千萬不要幸災樂禍。",
    },
    {
      chengyu: "火上加油",
      tradchengyu: "火上加油",
      pinyin: "huo3 shang4 jia1 you2",
      pinyin2: "huǒ shàng jiā yóu",
      chindefinition: "to pour oil on fire",
      engdefinition: "to aggravate matters; to make things worse",
      sentences:
        "这两天他正为了没申请到奖学金生气，要是你再去批评他的论文不够水准，那就成了火上加油了。",
      tradsent:
        "這兩天他正為了沒申請到獎學金生氣，要是你再去批評他的論文不夠水準，那就成了火上加油了。",
    },
    {
      chengyu: "断章取义",
      tradchengyu: "斷章取義",
      pinyin: "duan4 zhang1 qu3 yi4",
      pinyin2: "duàn zhāng qǔ yì",
      chindefinition: "to acquire an idea through broken sentences",
      engdefinition:
        "to take or quote a few sentences and assume to know what they mean; to take something out of context.",
      sentences:
        "甲：我听他说了几句话，我很不赞成。乙：只听了几句话就下结论，那是犯了断章取义的毛病。你应当把他的话全部听完了再发表意见。",
      tradsent:
        "甲：我聽他說了幾句話，我很不贊成。乙：只聽了幾句話就下結論，那是犯了斷章取義的毛病。你應當把他的話全部聽完了再發表意見。",
    },
    {
      chengyu: "岂有此理",
      tradchengyu: "豈有此理",
      pinyin: "qi2 you3 ci2 li3",
      pinyin2: "qí yǒu cí lǐ",
      chindefinition: "what kind of reasoning is that?",
      engdefinition: "totally unreasonable; how absurd; preposterous",
      sentences:
        "1) 他对他父亲说那么不客气的话，真是岂有此理。2）岂有此理，我们是老朋友了，他居然说不认识我。",
      tradsent:
        "1) 他對他父親說那麼不客氣的話，真是豈有此理。 2）豈有此理，我們是老朋友了，他居然說不認識我。",
    },
    {
      chengyu: "望尘莫及",
      tradchengyu: "望塵莫及",
      pinyin: "wang4 chen2 mo4 ji2",
      pinyin2: "wàng chén mò jí",
      chindefinition:
        "to see the dust raised by another, but be unable to catch up",
      engdefinition:
        "to gape at another's fast progress; to be left far behind; unable to compare with another's accomplishment, etc.",
      sentences: "王先生的英语很不错，可是跟李先生比，那就望尘莫及了。",
      tradsent: "王先生的英語很不錯，可是跟李先生比，那就望塵莫及了。",
    },
    {
      chengyu: "相提并论",
      tradchengyu: "相提並論",
      pinyin: "xiang1 ti2 bing4 lun4",
      pinyin2: "xiāng tí bìng lùn",
      chindefinition: "to mention two things in the same breath",
      engdefinition: "mentioned and discussed as related things",
      sentences:
        "他的中文程度很高已经达到优级水平，我的音调不准，用词又很普通，怎么能跟他相提并论呢？",
      tradsent:
        "他的中文程度很高已經達到優級水平，我的音調不准，用詞又很普通，怎麼能跟他相提並論呢？",
    },
    {
      chengyu: "忍无可忍",
      tradchengyu: "忍無可忍",
      pinyin: "ren3 wu2 ke3 ren3",
      pinyin2: "rěn wú kě rěn",
      chindefinition: "to forbear the unbearable",
      engdefinition:
        "beyond one's endurance; to have one's patience tested to excess; cannot stand (an insult or provocation) any longer; to be pushed too far",
      sentences: "因为富人不断地压迫穷人，穷人到了忍无可忍的地步，才起来反抗。",
      tradsent: "因為富人不斷地壓迫窮人，窮人到了忍無可忍的地步，才起來反抗。",
    },
    {
      chengyu: "无可奈何",
      tradchengyu: "無可奈何",
      pinyin: "wu2 ke3 nai4 he2",
      pinyin2: "wú kě nài hé",
      chindefinition: "there is no alternative",
      engdefinition: "powerless; it cannot be helped",
      sentences:
        "1）我最不喜欢做饭，可是我太太这几天病了，无可奈何我只好自己动手了。2）他以为不来学校，老师就对他无可奈何了，哪有这种事！",
      tradsent:
        "1）我最不喜歡做飯，可是我太太這幾天病了，無可奈何我只好自己動手了。 2）他以為不來學校，老師就對他無可奈何了，哪有這種事！",
    },
    {
      chengyu: "美中不足",
      tradchengyu: "美中不足",
      pinyin: "mei3 zhong1 bu4 zu2",
      pinyin2: "měi zhōng bù zú",
      chindefinition: "a flaw that mars perfection",
      engdefinition: "beautiful but incomplete",
      sentences:
        "1）这所房子地点好，价钱合理，我觉得还不错，只是院子不够大，有点美中不足。2）那个外国学生讲演的时候，国语标准，内容丰富，表情动人，可惜美中不足的是忘了一段词，停了半分钟没说话。",
      tradsent:
        "1）這所房子地點好，價錢合理，我覺得還不錯，只是院子不夠大，有點美中不足。 2）那個外國學生講演的時候，國語標準，內容豐富，表情動人，可惜美中不足的是忘了一段詞，停了半分鐘沒說話。",
    },
  ],
};
