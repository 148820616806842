import React, { Component } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import LineTo from "react-lineto";
import { isEqual } from "lodash";
import { Link } from "react-router-dom";

class LineMatching extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      showAnswer: false,
      left: [...this.props.choices].sort(() => Math.random() - 0.5),
      right: [...this.props.choices].sort(() => Math.random() - 0.5),
      to: null,
      from: null,
      lines: {},
      submitted: {},
      valueToClass: {},
    };
  }

  matchOrUnmatch = (className) => {
    const { from, to, lines } = this.state;
    console.log(from, to);
    //if already matched, remove line for pair of words
    if (lines[className]) {
      // Undo list radio select styling
      const fromRadio = document.getElementsByClassName(
        lines[className].from.className
      );
      const toRadio = document.getElementsByClassName(
        lines[className].to.className
      );
      console.log(fromRadio, toRadio);
      fromRadio[0].checked = false;
      toRadio[0].checked = false;

      const newLines = lines;
      const matchName =
        newLines[className].to.className === className
          ? newLines[className].from.className
          : newLines[className].to.className;
      delete newLines[matchName];
      delete newLines[className];
      this.setState({
        to: null,
        from: null,
        lines: newLines,
      });
    } else {
      //if unmatched, an pair vlaues stored, create line
      if (to && from) {
        this.setState(
          {
            lines: {
              ...lines,
              [from.className]: { to, from },
              [to.className]: { to, from },
            },
            to: null,
            from: null,
          },
          () => {
            // remove selected stile after line matched
            const fromRadio = document.getElementsByClassName(from.className);
            const toRadio = document.getElementsByClassName(to.className);
            fromRadio[0].checked = false;
            toRadio[0].checked = false;
          }
        );
      }
    }
  };

  checkAllAnswer = (correctAnswers) => {
    let submitted = {};
    Object.entries(this.state.lines)
      .filter((entry) => entry[0].includes("left"))
      .forEach((pair) => {
        submitted[pair[1].from.value] = pair[1].to.value;
      });
    this.setState({
      showAnswer: true,
      continue: isEqual(correctAnswers, submitted),
    });
  };
  reset = () => {
    this.setState({
      lines: {},
      showAnswer: false,
      from: null,
      to: null,
      continue: false,
    });
  };

  handleRadioClick = (e, value, key) => {
    const classN = e.target.className;

    this.setState({ [key]: { className: classN, value } }, () => {
      this.matchOrUnmatch(classN);
    });
  };

  render() {
    const { left, right } = this.state;

    const correctAnswers = {};
    this.props.choices.forEach((qa) => {
      correctAnswers[qa.traditional] = qa.english;
    });

    const leftChoices = left.map((choice, i) => {
      const className = `left-${i}`;
      const { language } = this.props;
      const linepair =
        this.state.lines[this.state.valueToClass[choice[language]]];
      const correct =
        linepair && linepair.to.value === correctAnswers[linepair.from.value];
      const radioColor = correct ? "green" : "red";

      return (
        <Question
          style={
            process.browser &&
            window.matchMedia("(orientation: landscape)").matches
              ? { width: "50%", marginLeft: "auto" }
              : null
          }
        >
          <div style={{ display: "flex", marginLeft: "auto" }}>
            <StyledChoices
              key={Math.random()}
              id={choice[language]}
              style={{
                textAlign: "right",
                marginRight: "10px",
              }}
            >
              {choice[language]}
            </StyledChoices>
            <Radio
              className={className}
              name={"leftRadio"}
              type="radio"
              style={{
                boxShadow: `0 0 0 2px ${
                  this.state.showAnswer ? radioColor : "white"
                }`,
              }}
              onClick={(e) => {
                this.handleRadioClick(e, choice[language], "from");
                this.setState({
                  valueToClass: {
                    ...this.state.valueToClass,
                    [choice[language]]: e.target.className,
                  },
                });
              }}
            />
          </div>
        </Question>
      );
    });

    const rightChoices = right.map((choice, i) => {
      const className = `right-${i}`;
      const linepair =
        this.state.lines[this.state.valueToClass[choice[this.props.language]]];
      const correct =
        linepair && linepair.to.value === correctAnswers[linepair.from.value];
      const radioColor = correct ? "green" : "red";
      return (
        <Question style={{ fontFamily: 'Times New Roman", serif' }}>
          <Radio
            className={className}
            name={"rightRadio"}
            type="radio"
            style={{
              boxShadow: `0 0 0 2px ${
                this.state.showAnswer ? radioColor : "white"
              }`,
            }}
            onClick={(e) => {
              this.handleRadioClick(e, choice.english, "to");
            }}
          />
          <StyledChoicesText
            key={i}
            id={choice.english}
            style={{ marginLeft: "10px" }}
          >
            {choice.english}
          </StyledChoicesText>
        </Question>
      );
    });

    const lines = Object.values(this.state.lines).map((line) => {
      const correct = line.to.value === correctAnswers[line.from.value];
      const correctColor = correct ? "green" : "red";
      return (
        <LineTo
          key={Math.random()}
          from={line.to.className}
          to={line.from.className}
          zIndex={-1}
          borderColor={this.state.showAnswer ? correctColor : "green"}
          borderWidth={
            process.browser &&
            window.matchMedia("(orientation: landscape)").matches
              ? 3
              : 2
          }
        />
      );
    });

    return (
      <div>
        <div>
          <Header>
            <StyledQuestion>{this.props.question}</StyledQuestion>
          </Header>
          <Container>
            <SubContainer>{leftChoices}</SubContainer>
            <SubContainer style={{ alignItems: "flex-start" }}>
              {rightChoices}
            </SubContainer>
          </Container>
          {lines}
        </div>

        <div style={{ textAlign: "center" }}>
          {this.state.showAnswer ? (
            <Button
              style={{ alignSelf: "center" }}
              variant="contained"
              color="secondary"
              onClick={() => this.reset()}
            >
              Retry
            </Button>
          ) : (
            <Button
              style={{
                alignSelf: "center",
              }}
              variant="contained"
              color="secondary"
              onClick={() => this.checkAllAnswer(correctAnswers)}
            >
              Check Answer
            </Button>
          )}
          {this.state.continue && (
            <Link to={this.props.nextLink}>
              <Button
                style={{ alignSelf: "center" }}
                variant="contained"
                color="secondary"
              >
                Next
              </Button>
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default LineMatching;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledQuestion = styled.h4`
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (orientation: landscape) {
    width: 90vw;
    justify-content: space-around;
  }
  @media (orientation: portrait) {
    width: 100vw;
    justify-content: space-between;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (orientation: landscape) {
    max-width: 45vw;
    width: 30vw;
  }
  @media (orientation: portrait) {
    width: 45vw;
  }
`;

const Question = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  @media (orientation: landscape) {
    height: 7vh;
  }
  @media (orientation: portrait) {
  }
`;

const Radio = styled.input`
  align-self: center;
  border-radius: 50%;
  border-style: solid;
  border-width: 1.5px;
  border-color: #3483eb;
  cursor: pointer;
  box-shadow: 0 0 0 2px gray;
  @media (orientation: landscape) {
    height: 1.25vw;
    width: 1.25vw;
  }
  @media (orientation: portrait) {
    height: 4vw;
    width: 4vw;
  }
`;

const StyledChoices = styled.div`
  user-select: none;
  text-align: left;
  word-wrap: break-word;
  border-color: #3483eb;
  background-color: #eeeeee;
  @media (orientation: landscape) {
    font-size: 25px;
  }
  @media (orientation: portrait) {
    font-size: 20px;
  }
`;

const StyledChoicesText = styled.div`
  user-select: none;
  text-align: left;
  word-wrap: break-word;
  border-color: #3483eb;
  background-color: #eeeeee;
  font-family: "Times New Roman", Times, serif;
  @media (orientation: landscape) {
    font-size: 25px;
  }
  @media (orientation: portrait) {
    font-size: 20px;
  }
`;
