import React from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ShareIcon from "@material-ui/icons/Share";
import StepBar from "./StepBar";

export default class ModuleCard extends React.PureComponent {
  render() {
    const {
      avatar,
      avatarColor,
      activeStep,
      nbSteps,
      img,
      title,
      subTitle,
      route = "",
    } = this.props;

    return (
      <Card>
        <CardHeader
          avatar={
            <Link
              to={route}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Avatar
                aria-label={title}
                style={{
                  backgroundColor: avatarColor,
                }}
              >
                {avatar}
              </Avatar>
            </Link>
          }
          action={
            <IconButton>
              <ShareIcon />
            </IconButton>
          }
          title={
            <Link
              to={route}
              style={{
                textDecoration: "none",
                color: "inherit",
                fontWeight: "bold",
              }}
            >
              {title}
            </Link>
          }
          subheader={subTitle}
        />
        <Link
          to={route}
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <CardMedia
            style={{
              paddingTop: "56.25%",
            }}
            image={img}
            title={title}
          />
        </Link>
        <CardContent></CardContent>
        <CardActions
          style={{
            display: "flex",
          }}
          disableSpacing
        >
          <StepBar activeStep={activeStep} nbSteps={nbSteps} />
        </CardActions>
      </Card>
    );
  }
}
