import React, { Component } from "react";
import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CorrectIcon from "./icons/CorrectIcon";
import PlayAudio from "./PlayAudio";

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 5px;
`;

const AnswerChoiceContainer = styled.div`
  display: flex;
  font-size: 25px;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 30px;
  border: 1px solid blue;
  width: 500px;
  justify-content: center;
  padding: 5px;
`;

const AnswerChoiceContainerSelected = styled.div`
  display: flex;
  padding: 5px;
  font-size: 25px;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 30px;
  border: 1px solid blue;
  background-color: blue;
  width: 500px;
  justify-content: center;
  color: white;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const checkStyle = {
  display: "None",
};

export default class Label extends Component {
  constructor(props) {
    super(props);
    this.state = { check: props.check };
  }

  toggle = () => {
    const { check } = this.state;
    this.setState({ check: !check });
  };

  render() {
    const { choice, showAnswer, handleChange, index } = this.props;
    const { img, isCorrect: correctAns } = choice;
    let isCorrect = correctAns;
    if (isCorrect !== true) {
      isCorrect = false;
    }

    const labelClass = showAnswer ? "lbl-answer" : "";
    const { check } = this.state;

    return (
      <div>
        <FormControlLabel
          key={choice.text}
          value={index}
          className={labelClass}
          control={
            showAnswer ? (
              <IconContainer>{isCorrect && <CorrectIcon />}</IconContainer>
            ) : (
              <Checkbox
                style={checkStyle}
                onChange={() => {
                  handleChange({ index });
                  this.toggle();
                }}
              />
            )
          }
          label={
            check ? (
              <AnswerChoiceContainerSelected>
                <img src={choice.img} alt="" />
                {check && choice.text} {!check && choice.text} &nbsp;&nbsp;
                {choice.audio && <PlayAudio path={choice.audio} />}
              </AnswerChoiceContainerSelected>
            ) : (
              <AnswerChoiceContainer>
                <img src={choice.img} alt="" />
                {check && choice.text} {!check && choice.text} &nbsp;&nbsp;
                {choice.audio && <PlayAudio path={choice.audio} />}
              </AnswerChoiceContainer>
            )
          }
        />
      </div>
    );
  }
}
