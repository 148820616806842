import React, { Component } from "react";
import data from "../../data/chengyu/normalized.js";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Breadcrumb from "../Breadcrumb";
import { getBreadcrumbRoutes } from "../../helpers/breadcrumbRoutes.js";

class Vocabulary extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const language = this.props.language || "traditional";
    const { groupId, lesson } = this.props.match.params;
    const dataName = this.props.match.params[0];
    const basePath = dataName;
    let words = data[dataName][lesson]["groupedData"][groupId].map(
      (word, i) => {
        return (
          <Link to={`/${basePath}/${lesson}/group/${groupId}/vocabulary/${i}`}>
            <button style={styles.vocabularyButton}>{word[language]}</button>
          </Link>
        );
      }
    );
    return (
      <div style={styles.container}>
        <Breadcrumb routes={getBreadcrumbRoutes(this.props.match)} />
        <center>{words}</center>
        <center>
          <Link to={`/${basePath}/${lesson}/group/${groupId}/practice`}>
            <Button variant="contained" style={styles.practiceButton}>
              Practice Problems {">"}
            </Button>
          </Link>
        </center>
      </div>
    );
  }
}

export default Vocabulary;

const styles = {
  vocabularyButton: {
    backgroundColor: "#E8EDFF",
    width: "230px",
    height: "100px",
    margin: "1vw",
    borderRadius: "15px",
    border: ".5px solid black",
    fontSize: "170%",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  container: {
    padding: "25px",
  },
  practiceButton: {
    margin: "10px",
    marginTop: "15vh",
    backgroundColor: "blue",
    border: "none",
    color: "white",
    padding: "16px 32px",
    textAlign: "center",
    fontSize: "16px",
    opacity: "0.6",
    transition: "0.3s",
    display: "inline-block",
    textDecoration: "none",
    cursor: "pointer",
  },
};
