class AssetStore {
  basePath = '';

  init(path) {
    this.basePath = path;
  }

  get(path) {
    return `${this.basePath}/${path}`;
  }
}

export default new AssetStore();
