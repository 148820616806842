/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
const CHENGYU = require("../data/chengyu");

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#3399ff",
      main: "#0073e6",
      dark: "#004d99",
      contrastText: "#fff",
    },
  },
});

const topics_dict = {
  nature: ["自然", "自然"],
  numbers: ["数字", "數字"],
  animals: ["动物", "動物"],
  human: ["人、器官", "人、器官"],
  antonyms: ["反义词", "反義詞"],
  measurements: ["量词", "量詞"],
  pos_connotation: ["积极含意", "積極含意"],
  neg_connotation: ["消极含意", "消極含意"],
};

export default function ExercisePage({
  match: {
    params: { tradId, chengyuTopic },
  },
}) {
  function renderExerciseButtons() {
    const quotient = Math.floor(CHENGYU.default[chengyuTopic].length / 4);
    const total =
      CHENGYU.default[chengyuTopic].length % 4 === 0 ? quotient : quotient + 1;
    const ret = [];
    for (let i = 0; i < total; i++) {
      ret.push(
        <div>
          <Link
            to={`/crossword/${chengyuTopic}/${tradId}/${i}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: "5px;" }}
            >
              Exercise {i + 1}
            </Button>
          </Link>
        </div>
      );
      ret.push(<br />);
      ret.push(<br />);
    }
    ret.push(<br />);
    return ret;
  }

  const buttons = renderExerciseButtons();

  return (
    <div>
      {tradId === "1" ? (
        <div
          style={{
            color: "#333333",
            fontFamily: "testFont,sans-serif",
            fontSize: "20px",
          }}
        >
          繁體 / {topics_dict[chengyuTopic][1]} / 練習
        </div>
      ) : (
        <div
          style={{
            color: "#333333",
            fontFamily: "testFont,sans-serif",
            fontSize: "20px",
          }}
        >
          简体 / {topics_dict[chengyuTopic][0]} / 练习
        </div>
      )}
      <center>
        <MuiThemeProvider theme={theme}>
          {buttons.map((button) => (
            <b> {button} </b>
          ))}

          <Button
            href={`/chengyu/1/${tradId}/${chengyuTopic}`}
            variant="outlined"
            color="primary"
          >
            Back
          </Button>
        </MuiThemeProvider>
      </center>
    </div>
  );
}
