import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CorrectIcon from "../icons/CorrectIcon";
import IncorrectIcon from "../icons/IncorrectIcon";
import OpenCC from "node-opencc";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: space-around;
  justify-content: center;
`;

const StyledQuestion = styled.h4`
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 12px;
`;

const Audio = styled.audio`
  width: 250px;
  position: relative;
  left: 0%;
  margin-bottom: 15px;
`;

function FillBlank({ question, choices, showAnswer, simplified, mainaudio }) {
  const [currAnswer, setCurrentAnswer] = useState("");
  const [show, setShow] = useState(false);
  const [correct, setCorrect] = useState(false);

  const checkAnswer = () => {
    setShow(true);
    if (currAnswer === choices[0]) showAnswer();
    setCorrect(currAnswer === choices[0]);
  };

  const retry = () => {
    setShow(false);
    setCurrentAnswer("");
  };
  const handleChange = (e) => {
    setCurrentAnswer(e.target.value);
  };

  const addLineBreaks = (string) =>
    string.split("\n").map((text) => (
      <React.Fragment>
        {simplified ? OpenCC.traditionalToSimplified(text) : text}
        <br />
      </React.Fragment>
    ));

  return (
    <React.Fragment>
      <StyledQuestion>{addLineBreaks(question)}</StyledQuestion>
      {mainaudio && (
        <Audio controls controlsList="nodownload" src={mainaudio} />
      )}
      <StyledContainer>
        {show ? (
          <IconContainer>
            {correct ? <CorrectIcon /> : <IncorrectIcon />}
          </IconContainer>
        ) : (
          <></>
        )}
        <TextField
          variant="outlined"
          label="Answer"
          onChange={handleChange}
          value={currAnswer}
        />
      </StyledContainer>
      <br />
      {!show && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => checkAnswer()}
        >
          Check
        </Button>
      )}
      {!correct && show && (
        <Button variant="contained" color="secondary" onClick={() => retry()}>
          Retry
        </Button>
      )}
    </React.Fragment>
  );
}

export default FillBlank;
