/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import chengyuData from "../data/chengyu/normalized.js";
import { Link } from "react-router-dom";
import PlayAudio from "./PlayAudio";
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumb from "./Breadcrumb";
import { getBreadcrumbRoutes } from "../helpers/breadcrumbRoutes.js";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import OpenCC from "node-opencc";

const useStyles = makeStyles(theme => ({
  bookmark: {
    "&:hover": {
      cursor: "pointer",
    }
  }
}))

const BackButton = styled.button`
  background-color: white;
  border-radius: 5px;
  color: #1976d2;
  font-size: 0.875rem;
  cursor: pointer;
  width: 100px;
  border: 1px solid #1976d2;
`;

const SlideShow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
`;

const FlashCard = styled.div`
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  width: 60vmin;
  min-height: 480px;
  max-height: 900px;
  padding: 10px 15px 10px 0px;
  @media (orientation: portrait) {
    min-height: 400px;
    max-height: 700px;
    width: 85vw;
  }
  h1 {
    font-size: 60px;
    margin-bottom: 0px;
    margin-top: 0px;
    @media (orientation: portrait) {
      font-size: 2em;
    }
  }
`;

const CardList = styled.ul`
  list-style-type: none;
  text-align: left;
  font-size: 0.5erm;
`;
const CardItem = styled.div`
  text-align: left;
  font-size: 16px;
  margin-top: 0;
  width: 100%;

  @media (orientation: portrait) {
    font-size: 1em;
    margin-bottom: 10px;
  }
  strong {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
    @media (orientation: portrait) {
      font-size: 1em;
    }
  }
`;

const ExpandTitle = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
`;
const MainAudio = styled.div`
  display: inline-block;
`;

const canClick = styled.div`
  cursor: pointer;
`;
const chengyuGroupAudios = Object.assign(
  ...Array(20)
    .fill(null)
    .map((el, i) => ({ [i + 1]: i * 10 + 1 }))
);

const chengyuTopicsDict = Object.assign(
  ...Array(20)
    .fill(null)
    .map((el, i) => ({ [i + 1]: [`Group ${i + 1}`] }))
);

const audioDict = {
  animals: 2,
  numbers: 9,
  human: 24,
  antonyms: 41,
  measurements: 61,
  pos_connotation: 68,
  neg_connotation: 87,
  nature: 105,
  ...chengyuGroupAudios,
};

export default function Definition(props) {
  const classes = useStyles();
  const { chengyuTopic, wordId, groupId, lesson = 1 } = props.match.params;
  const basePath = props.match.params[0];
  const groupName = chengyuTopic || groupId;
  const tradId =
    props.match.params.tradId || props.language === "traditional" ? 1 : 0;
  const chengyuPath = lesson > 1 ? `chengyu${lesson}` : "chengyu";
  console.log(chengyuPath);
  const return_route = window.location.href.includes("/chengyu/1/")
    ? "/chengyu/1/" + tradId + "/" + groupName
    : `/${basePath}/${lesson}/group/${groupId}/vocabulary`;

  const getWordInfo = (groupName) => {
    const { wordId } = props.match.params;
    const dataName = props.match.params[0];
    const data =
      chengyuData[dataName][lesson]["wordData"][props.language][groupName];
    return data[wordId];
  };

  const navigateNearby = (direction) => {
    const { lesson = 1 } = props.match.params;
    const wordId = parseInt(props.match.params.wordId);
    const groupId =
      parseInt(props.match.params.groupId) || props.match.params.groupId;
    const dataName = props.match.params[0];
    const groupLength =
      chengyuData[dataName][lesson].groupedData[groupId].length;
    const isWithinBounds =
      (direction === "Next" && wordId < groupLength - 1) ||
      (direction === "Previous" && wordId > 0);
    if (isWithinBounds) {
      const nearbyWordId = direction === "Next" ? wordId + 1 : wordId - 1;
      hideAllDefinition();
      props.history.push(
        `/${basePath}/${lesson}/group/${groupId}/vocabulary/${nearbyWordId}`
      );
    }
  };

  const [showPinyin, setShowPinyin] = useState(false);
  const [showChin, setShowChin] = useState(false);
  const [showEng, setShowEng] = useState(false);
  const [showSent, setShowSent] = useState(false);
  const [wordInfo, setWordInfo] = useState(getWordInfo(groupName));
  const [width, setWidth] = useState(window.innerWidth);
  const url = window.location.pathname;
  const [module, setModule] = useState("");

  const [bookmarks, setBookmarks] = useState([]);

  const removeBookmark = item => {
    let newBookmarks = bookmarks.filter(curr => !curr[module].includes(item));

    localStorage.setItem("bookmarks", JSON.stringify(newBookmarks));
    setBookmarks(newBookmarks);
  }

  const addBookmark = item => {
    let newBookmarks = bookmarks;
    let newItem = {
      [module]: [OpenCC.traditionalToSimplified(item), OpenCC.simplifiedToTraditional(item)],
      url: url,
    };

    newBookmarks.push(newItem);

    localStorage.setItem("bookmarks", JSON.stringify(newBookmarks));
    setBookmarks([...bookmarks, newItem]);
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  useEffect(() => {
    setWordInfo(getWordInfo(groupName));

    if (url.includes("chengyu")) {
      setModule("chengyu");
    } else if (url.includes("triptochina")){
      setModule("triptochina");
    }

    if (localStorage.getItem("bookmarks") !== null) {
      setBookmarks(JSON.parse(localStorage.getItem("bookmarks")));
    }
  }, [props.language, props.location]);

  const assetBasePath = "https://materials.chineseforall.org";
  var audioId = audioDict[groupName] + parseInt(wordId);

  const chengyuAudioPath =
    assetBasePath + `/assets/${chengyuPath}/audio/chengyu/` + audioId + ".mp3";
  const sentenceAudioPath =
    assetBasePath +
    `/assets/${chengyuPath}/audio/sentences/` +
    audioId +
    ".mp3";

  const hideAllDefinition = () => {
    setShowPinyin(false);
    setShowChin(false);
    setShowEng(false);
    setShowSent(false);
  };

  function togglePinyin() {
    setShowPinyin(!showPinyin);
  }

  function toggleChin() {
    setShowChin(!showChin);
  }

  function toggleEng() {
    setShowEng(!showEng);
  }

  function toggleSent() {
    setShowSent(!showSent);
  }

  function getPinyin() {
    return <div> {wordInfo["pinyin"]} </div>;
  }

  const topics_dict = {
    nature: ["自然", "自然"],
    numbers: ["数字", "數字"],
    animals: ["动物", "動物"],
    human: ["人、器官", "人、器官"],
    antonyms: ["反义词", "反義詞"],
    measurements: ["量词", "量詞"],
    pos_connotation: ["积极含意", "積極含意"],
    neg_connotation: ["消极含意", "消極含意"],
    ...chengyuTopicsDict,
  };

  const customChengyuBreadcrumb = [
    tradId ? "简体" : "繁體",
    topics_dict[groupId][0],
    wordInfo["word"],
  ];

  return (
    <div className="module-menu">
      {!lesson ? (
        <div
          style={{
            color: "#333333",
            fontFamily: "testFont,sans-serif",
            fontSize: "20px",
          }}
        >
          {tradId ? "简体" : "繁體"} / {topics_dict[groupId][0]} /{" "}
          {wordInfo["word"]}{" "}
        </div>
      ) : (
        <Breadcrumb
          routes={getBreadcrumbRoutes(props.match, [
            undefined,
            undefined,
            undefined,
            undefined,
            wordInfo["word"],
          ])}
        />
      )}

      <center>
        <SlideShow>
          {!isMobile && (
            <NavigateBeforeIcon
              fontSize={"inherit"}
              style={{ fontSize: "60px", margin: "20px", cursor: "pointer" }}
              onClick={() => navigateNearby("Previous")}
            />
          )}
          <FlashCard>
            <h1 style={{ marginRight: "-10px", textAlign: "center" }}>
              {wordInfo["word"]}{" "}
              {chengyuAudioPath && props.match.params[0] !== "phrase" && (
                <MainAudio>
                  <PlayAudio
                    path={chengyuAudioPath}
                    fontSize={"40px"}
                    audioStyle={{ fontSize: "40px", padding: 0 }}
                  ></PlayAudio>
                </MainAudio>
              )}
            </h1>
            <div className={classes.bookmark}>
              {bookmarks.map(curr => { return curr[module] && curr[module].includes(wordInfo["word"]) }).indexOf(true) > -1 ?
                <div onClick={() => removeBookmark(wordInfo["word"])} style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}>
                  <BookmarkIcon style={{ color: "#273b6f" }} />
                  <span>Remove Bookmark</span>
                </div>
                :
                <div onClick={() => addBookmark(wordInfo["word"])} style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}>
                  <BookmarkBorderIcon style={{ color: "#273b6f" }} />
                  <span>Add Bookmark</span>
                </div>

              }
            </div>
            <CardList>
              {wordInfo["literalDefinition"] && (
                <CardItem>
                  <strong> Literal Definition </strong>
                  <i onClick={toggleChin}>
                    {showChin ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </i>
                  <section>
                    {showChin ? wordInfo["literalDefinition"] : ""}
                  </section>
                </CardItem>
              )}
              {wordInfo["englishDefinition"] && (
                <CardItem>
                  <strong> English Definition </strong>
                  <i onClick={toggleEng}>
                    {showEng ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </i>
                  <section>
                    {showEng ? wordInfo["englishDefinition"] : ""}
                  </section>
                </CardItem>
              )}
              {getPinyin() && (
                <CardItem>
                  <strong>Pinyin </strong>
                  <i onClick={togglePinyin}>
                    {showPinyin ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </i>
                  <section>{showPinyin ? getPinyin() : ""}</section>
                </CardItem>
              )}
              {wordInfo["sentences"] && (
                <CardItem>
                  <ExpandTitle>
                    <div>
                      <strong>Sentences </strong>
                      <i onClick={toggleSent}>
                        {showSent ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </i>
                    </div>

                    <span>
                      {showSent && props.match.params[0] !== "phrase" && (
                        <PlayAudio
                          path={sentenceAudioPath}
                          audioStyle={{ padding: 0 }}
                        />
                      )}
                    </span>
                  </ExpandTitle>
                  <section style={{ fontSize: "1.5em", width: "90%" }}>
                    {showSent ? wordInfo["sentences"] : ""}
                  </section>
                </CardItem>
              )}
            </CardList>
          </FlashCard>
          {!isMobile && (
            <NavigateNextIcon
              fontSize={"inherit"}
              style={{ fontSize: "60px", margin: "20px", cursor: "pointer" }}
              onClick={() => {
                navigateNearby("Next");
              }}
            />
          )}
        </SlideShow>
        {isMobile && (
          <div>
            <NavigateBeforeIcon
              fontSize={"inherit"}
              style={{ fontSize: "60px", margin: "20px", cursor: "pointer" }}
              onClick={() => navigateNearby("Previous")}
            />
            <NavigateNextIcon
              fontSize={"inherit"}
              style={{ fontSize: "60px", margin: "20px", cursor: "pointer" }}
              onClick={() => {
                navigateNearby("Next");
              }}
            />
          </div>
        )}

        <br />
        <Link to={return_route}>
          <BackButton>Back to vocabulary</BackButton>
        </Link>
      </center>
    </div>
  );
}
